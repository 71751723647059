import React, { useState, useEffect, useRef } from "react";
import { Formik, Form as Formdata } from "formik";
import { object, string } from "yup";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
// import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { Col, Form, Row } from "react-bootstrap";
import { TextArea, TextField, TextSelect } from "utils/FormControls";
import { toast, ToastContainer } from "react-toastify";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import moment from "moment";
import ViewEvent from "utils/ViewEvent";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const BasicDetailsForm = ({ setActiveTab }: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const { setEventId, storeEventId, storeAccountId } = useEvent();
  const [isBasicDetailsData, setIsBasicDetailsData] = useState<any>([]);
  const [isStatus, setIsStatus] = useState();
  const [isEventType, setIsEventType] = useState();
  const [isEventCategoryList, setIsEventCategoryList] = useState();
  const [searchValue, setSearchValue] = useState<any>("");
  const [isUnique, setIsunique] = useState();
  const [isText, setIsText] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const StoredId = storeEventId === null ? false : true;
  const overlayBasic: any = useRef(null);
  const isViewEvent = ViewEvent();
  const [description, setDescriptionContent] = useState();
  const [updateDescription, setUpdateDescriptionContent] = useState();
  const [Id, setId] = useState();

  const axiosPrivate = useAxiosPrivate();
  let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));

      setId(eventId);
      isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent, isEditEvent]);

  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];
  const editorToolbar =
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help";

  const editorContentStyle =
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

  const editorAPIKey = process.env.REACT_APP_TINY_API_KEY;

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleGeneralEditorChange = (content: any) => {
    setUpdateDescriptionContent(content);
  };

  const BasicDetailsValidation = object({
    eventName: string()
      .required("Event name is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Company Name should not contain special characters"
      )
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(40, "Maximum of 40 aplhanumeric  characters of length"),
    eventShortCode: string()
      .required("Event short code is required")
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(25, "Maximum of 25 aplhanumeric  characters of length"),
    eventType: string().required("Event type is required"),
    eventStartDate: string().required("Event start date is required"),
    eventEndDate: string().required("Event end date is required"),
    registrationStartDate: string().required(
      "Registration start date is required"
    ),
    registrationEndDate: string().required("Registration end date is required"),
    raceCategoryChangeDate: string().required(
      "Last date to change category is required"
    ),
    location: string()
      .required("Location is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(25, "Maximum of 25 aplhanumeric  characters of length"),
    city: string()
      .required("City is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(25, "Maximum of 25 aplhanumeric  characters of length"),
    isProcessingFee: string(),
    isFullCoupon: string(),
    isBib: string(),
    isGstApplicable: string(),
    eventCategory: string().required("Event category is required"),
    taxFeeType: string(),
    taxFeeCategory: string(),
    taxFee: string(),
    processingFeeType: string(),
    processingFeeCategory: string(),
    processingFee: string(),
  });

  const BasicDetailsinitialValues = {
    eventName: isAddMode ? "" : isBasicDetailsData?.event_name,
    eventShortCode:
      (isAddMode ? "" : isBasicDetailsData?.event_slug?.trim()) || searchValue,
    eventType: isAddMode ? "" : isBasicDetailsData?.event_type_id,
    eventStartDate: isAddMode ? "" : isBasicDetailsData?.event_start_date,
    eventEndDate: isAddMode ? "" : isBasicDetailsData?.event_end_date,
    registrationStartDate: isAddMode
      ? ""
      : isBasicDetailsData?.registration_start,
    registrationEndDate: isAddMode
      ? ""
      : isBasicDetailsData?.registration_close,
    raceCategoryChangeDate: isAddMode
      ? ""
      : isBasicDetailsData?.ticket_category_change?.split(" ")[0],
    location: isAddMode ? "" : isBasicDetailsData?.location,
    city: isAddMode ? "" : isBasicDetailsData?.city,
    eventCategory: isAddMode ? "" : isBasicDetailsData?.event_category_id,
    isProcessingFee: isAddMode
      ? ""
      : isBasicDetailsData?.has_processing_fee === 0
      ? "0"
      : "1",

    isGstApplicable: isAddMode
      ? ""
      : isBasicDetailsData?.is_gst_on_race_applicable === 0
      ? "0"
      : "1",
  };

  const handleBasicDetailsSubmit = (values: any) => {
    if (updateDescription === null || updateDescription === "") {
      toast.error("Description is required");
      return;
    }
    const { isGstApplicable, isProcessingFee, ...rest } = values;

    let isProcessing;
    if (isGstApplicable === "1") {
      isProcessing = "1";
    } else {
      isProcessing = isProcessingFee;
    }

    setIsLoading(true);
    const apiData = {
      ...rest,
      eventId: null || Id || storeEventId,
      accountId: storeAccountId,
      description: updateDescription,
      isGstApplicable: isGstApplicable,
      isProcessingFee: isProcessing,
    };
    // console.log("data", JSON.stringify(apiData));
    // axios.post("/eventBasicDetails", apiData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/eventBasicDetails",
      data: apiData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          setEventId(res.data.eventId);
          setTimeout(() => {
            setActiveTab(2);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // axios.get("/eventCategoryList", {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/eventCategoryList",
      method: "GET",
    })
      .then((res) => {
        if (res.data.status) {
          setIsEventCategoryList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // axios.get("/eventTypes", {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/eventTypes",
      method: "GET",
    })
      .then((res) => {
        if (res.data.status) {
          setIsEventType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function getEventBasicDetails() {
    const EventId = Id || storeEventId;
    setIsLoading(true);
    // axios.post(
    //   "/getEventBasicDetails",
    //   {
    //     event_id: EventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventBasicDetails",
      data: {
        event_id: EventId,
      },
      method: "POST",
    })
      .then((res) => {
        setIsBasicDetailsData(res.data.data);
        setDescriptionContent(res.data.data.description);
        setUpdateDescriptionContent(res.data.data.description);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    {
      Id && getEventBasicDetails();
    }
  }, [Id]);

  let isMessage: boolean;

  const handleVerifySlugCode = () => {
    setIsLoading(true);
    // axios.post(
    //   "/eventSlugverify",
    //   {
    //     shortCode: `${overlayBasic.current.values.eventShortCode}`,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/eventSlugverify",
      data: {
        shortCode: `${overlayBasic.current.values.eventShortCode}`,
      },
      method: "POST",
    })
      .then((res) => {
        setIsStatus(res.data.status);
        if (res.data.status) {
          isMessage = res.data.message === "unique" ? true : false;
          setIsText(isMessage);
          setIsunique(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && <Loader />}
      <Formik
        innerRef={overlayBasic}
        initialValues={BasicDetailsinitialValues}
        validationSchema={BasicDetailsValidation}
        onSubmit={handleBasicDetailsSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, handleChange, handleBlur }) => (
          <Formdata noValidate>
            <Row className="my-md-3">
              <Col md={4} sm={12}>
                <TextField name="eventName" type="text" label="Event Name *" />
              </Col>
              <Col md={4} sm={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="w-75 mr-2">
                    <TextField
                      name="eventShortCode"
                      type="text"
                      label="Event Short Code *"
                      placeholder="Short code has to be unique"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary text-white fs-14 py-md-2 ms-1"
                    onClick={handleVerifySlugCode}
                  >
                    Verify&nbsp;code
                  </button>
                </div>
                <div
                  className="mt-2"
                  style={{ color: isText ? "green" : "red" }}
                >
                  {isUnique}
                </div>
              </Col>
              <Col md={4} sm={12}>
                <TextSelect
                  name="eventType"
                  options={isEventType}
                  label="Event Type *"
                  type="select"
                />
              </Col>
            </Row>

            <Row className="my-md-3">
              <Col md={4} sm={12}>
                <TextField
                  name="eventStartDate"
                  type="date"
                  label="Event Start Date *"
                  min={
                    isAddMode
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : moment(isBasicDetailsData?.event_start_date).format(
                          "YYYY-MM-DD"
                        )
                  }
                />
              </Col>
              <Col md={4} sm={12}>
                <TextField
                  name="eventEndDate"
                  type="date"
                  label="Event End Date *"
                  min={values.eventStartDate}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={4} sm={12}>
                <TextField
                  name="registrationStartDate"
                  type="datetime-local"
                  label="Registration Start Date *"
                  min={
                    isAddMode
                      ? moment(new Date()).format("YYYY-MM-DDTHH:mm")
                      : moment(isBasicDetailsData?.registration_start).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                  }
                  max={moment(values.eventStartDate).format("YYYY-MM-DDTHH:mm")}
                />
              </Col>
            </Row>
            <Row className="my-md-3">
              <Col md={4} sm={12}>
                <TextField
                  name="registrationEndDate"
                  type="datetime-local"
                  label="Registration End Date  *"
                  min={
                    isAddMode
                      ? moment(new Date()).format("YYYY-MM-DDTHH:mm")
                      : moment(isBasicDetailsData?.registration_close).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                  }
                  max={moment(values.eventStartDate).format("YYYY-MM-DDTHH:mm")}
                />
              </Col>
              <Col md={4} sm={12}>
                <TextField
                  name="raceCategoryChangeDate"
                  type="date"
                  label="Last Date to Change Category *"
                  min={moment(values.registrationStartDate).format(
                    "YYYY-MM-DD"
                  )}
                  max={moment(values.eventStartDate).format("YYYY-MM-DD")}
                />
              </Col>
              <Col md={4} sm={12}>
                <TextField name="location" type="text" label="Location *" />
              </Col>
            </Row>
            <Row className="my-md-3">
              <Col md={4} sm={12}>
                <TextField name="city" type="text" label="City *" />
              </Col>
              <Col md={4} sm={12}>
                <TextSelect
                  name="eventCategory"
                  options={isEventCategoryList}
                  label="Event Category *"
                  type="select"
                />
              </Col>
            </Row>
            <div>
              <Row className="">
                <Col md={12}>
                  <p className="text-darkgray fw-400 fs-16 my-3">
                    Description
                    <span className="text-primary ms-1">*</span>
                  </p>
                </Col>
              </Row>
              <Editor
                apiKey={editorAPIKey}
                disabled={isViewEvent}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={description}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: editorPlugins,
                  toolbar: editorToolbar,
                  content_style: editorContentStyle,
                  setup: (editor: {
                    on: (arg0: string, arg1: () => void) => void;
                    getContent: () => any;
                  }) => {
                    editor.on("change", () => {
                      handleGeneralEditorChange(editor.getContent());
                    });
                  },
                }}
              />
            </div>
            <Row className="my-md-3">
              <Col sm={12}>
                <Form.Label className="my-2">
                  How should the GST be collected from the participant?{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Group>
                  <Form.Check
                    inline
                    label="Inclusive"
                    name="isGstApplicable"
                    type="radio"
                    onChange={handleChange}
                    isInvalid={!!errors.isGstApplicable}
                    value="0"
                    checked={values.isGstApplicable === "0"}
                    disabled={isViewEvent}
                  />

                  <Form.Check
                    inline
                    label="Exclusive"
                    name="isGstApplicable"
                    type="radio"
                    onChange={handleChange}
                    isInvalid={!!errors.isGstApplicable}
                    value="1"
                    checked={values.isGstApplicable === "1"}
                    disabled={isViewEvent}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-md-3">
              <Col xs={12}>
                <Form.Label className="my-2">
                  How should the processing fee be collected from the
                  participant ?<span className="text-primary">*</span>
                </Form.Label>

                <Form.Group>
                  <Form.Group>
                    <Form.Check
                      inline
                      label="Inclusive "
                      name="isProcessingFee"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.isProcessingFee}
                      value="0"
                      checked={values.isProcessingFee === "0"}
                      disabled={isViewEvent || values.isGstApplicable === "1"}
                    />

                    <Form.Check
                      inline
                      label="Exclusive"
                      name="isProcessingFee"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.isProcessingFee}
                      value="1"
                      checked={values.isProcessingFee === "1"}
                      disabled={isViewEvent || values.isGstApplicable === "1"}
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-md-3 d-flex align-items-center justify-content-end">
              {!isViewEvent && (
                <Col className="d-flex justify-content-end">
                  <DefaultButton
                    type="submit"
                    variant="outline-primary"
                    className=" default-action-button"
                  >
                    Submit
                  </DefaultButton>
                </Col>
              )}
            </Row>
          </Formdata>
        )}
      </Formik>
    </div>
  );
};

export default BasicDetailsForm;
