import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import "assets/scss/tabs.scss";
import BasicDetailsForm from "components/Forms/OrganizerForms/BasicDetailsForm";
import ContactDetailsForm from "components/Forms/OrganizerForms/ContactDetailsForm";
import EmailForm from "components/Forms/OrganizerForms/EmailForm";
import LogoForm from "components/Forms/OrganizerForms/LogoForm";
import { useLocation } from "react-router-dom";

interface DetailsFormProps {
  handleSubmitNextHandler: () => void;
}

const DetailsForm: React.FC<DetailsFormProps> = ({
  handleSubmitNextHandler,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [isEditEvent, setIsEditEvent] = useState(false);
  const location = useLocation();
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("editEvents")) {
      setIsEditEvent(true);
    }
  }, []);
  return (
    <>
      <Container>
        <div className="mt-md-4">
          <h2 className="text-primary">
            {isEditEvent ? "Edit Event" : "Create Event"}
          </h2>
        </div>
        <Tabs
          defaultActiveKey={"basicDetails"}
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(Number(key))}
          id="event-tabs"
          className="event-nav-tabs"
        >
          <Tab
            eventKey={1}
            id="event-tabs-tab-basicDetails"
            title="Basic Details"
          >
            {activeTab === 1 && (
              <BasicDetailsForm setActiveTab={setActiveTab} />
            )}
          </Tab>

          {/* emailTemplates */}
          <Tab eventKey={2} title="Email Templates">
            {activeTab === 2 && <EmailForm setActiveTab={setActiveTab} />}
          </Tab>

          {/* contact schema */}
          <Tab eventKey={3} title="Contact Info">
            {activeTab === 3 && (
              <ContactDetailsForm setActiveTab={setActiveTab} />
            )}
          </Tab>

          {/* Logo tab */}
          <Tab eventKey={4} title="Logo">
            {activeTab === 4 && (
              <LogoForm
                setActiveTab={setActiveTab}
                handleSubmitNextHandler={handleSubmitNextHandler}
              />
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default DetailsForm;
