import React, { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "components/Icon";
import {
  decryptText,
  encryptText,
  itemsPerPageOptions,
  tableCustomStyles,
} from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import moment from "moment";
import { toast } from "react-toastify";
// import FormModal from "components/EventRegistration/FormModal";
import SpotRegistrationForm from "components/EventRegistration/SpotRegistrationForm";

const ExpoAdminEventPage = () => {
  const { id } = useParams();
  const urlid = decryptText(String(id));
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataCount, setDataCount] = useState([]);
  const [expoAdmins, setExpoAdmins] = useState([]);

  const types = [
    { id: "tshirt_collected", name: "Tshirt Collected" },
    { id: "bib_collected", name: "Bib Collected" },
    { id: "both_collected", name: "Both Collected" },
    { id: "not_collected_tshirt", name: "Not Collected Tshirt" },
    { id: "not_collected_bib", name: "Not Collected Bib" },
    { id: "not_collected_both", name: "Not Collected Both" },
  ];

  const [filedType, setFiledType] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  function viewExpoRepot() {
    axiosPrivate({
      url: "/viewExpoReports",
      data: {
        eventId: urlid,
      },
      method: "POST",
    })
      .then((response: any) => {
        const { status, message, data, token } = response.data;
        if (status) {
          setDataCount(data);
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function expoReportsData(selectFieldType: any) {
    axiosPrivate({
      method: "POST",
      url: "/expoReportsData",
      data: {
        eventId: decryptText(String(id)),
        report_type: selectFieldType,
      },
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          console.log(data);
          setExpoAdmins(data);
          toast.success(message);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      });
  }

  const expoAdminList = [
    {
      name: "Name",
      selector: (row: any) =>
        row.first_name
          ? row.first_name
          : "" + row.last_name
          ? row.last_name
          : "—",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: true,
      width: "300px",
    },
    {
      name: "Age Category",
      selector: (row: any) => (row.age_category ? row.age_category : "—"),
      sortable: true,
    },
    {
      name: "Approve Status",
      selector: (row: any) =>
        row.approve_status
          ? row.approve_status === "A"
            ? "Approved"
            : row.approve_status === "M"
            ? "More Info"
            : "—"
          : "—",
      sortable: true,
    },
    {
      name: "Bib Number",
      selector: (row: any) => (row.bib_number ? row.bib_number : "—"),
      sortable: true,
    },
    {
      name: "Charity Bib",
      selector: (row: any) => (row.charity_bib ? row.charity_bib : "—"),
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row: any) => (row.company_name ? row.company_name : "—"),
      sortable: true,
    },
    {
      name: "Coupon Code",
      selector: (row: any) => (row.coupon_code ? row.coupon_code : "—"),
      sortable: true,
    },
    {
      name: "DOB",
      selector: (row: any) => (row.date_of_birth ? row.date_of_birth : "—"),
      sortable: true,
    },
    {
      name: "Order Number",
      selector: (row: any) => (row.order_number ? row.order_number : "—"),
      sortable: true,
    },
    {
      name: "Is Elite",
      selector: (row: any) =>
        row.is_elite ? (row.is_elite === 1 ? "Yes" : "No") : "No",
      sortable: true,
    },
    {
      name: "Order Number",
      selector: (row: any) => (row.order_number ? row.order_number : "—"),
      sortable: true,
    },
    {
      name: "Race Name",
      selector: (row: any) => (row.race_category ? row.race_category : "—"),
      sortable: true,
    },
    {
      name: "TShirt",
      selector: (row: any) => (row.tshirt ? row.tshirt : "—"),
      sortable: true,
    },
    {
      name: "Is Bib Collected",
      selector: (row: any) =>
        row.is_bib_collected
          ? row.is_bib_collected === 1
            ? "Yes"
            : "No"
          : "No",
      sortable: true,
    },
    {
      name: "Is TShirt Collected",
      selector: (row: any) =>
        row.is_tshirt_collected
          ? row.is_tshirt_collected === 1
            ? "Yes"
            : "No"
          : "No",
      sortable: true,
    },
  ];
  // need to change this in ifinish2.0
  const exportToExcel = () => {
    const wsData = [
      [
        "First Name",
        "Last Name",
        "Age Category",
        "Approve Status",
        "Bib Number",
        "Charity Bib",
        "Company Name",
        "Coupon Code",
        "Date of Birth",
        "Email",
        "Order Number",
        "Race Category",
        "Tshirt",
        "Is Elite",
        "Is Bib Collected",
        "Is TShirt Collected",
      ],
      ...expoAdmins.map((item: any) => [
        item["first_name"],
        item["last_name"],
        item["age_category"],
        item["approve_status"] === "A"
          ? "Approved"
          : item["approve_status"] === "M"
          ? "More Info"
          : "—",
        item["bib_number"],
        item["charity_bib"],
        item["company_name"],
        item["coupon_code"],
        item["date_of_birth"],
        item["email"],
        item["order_number"],
        item["race_category"],
        item["tshirt"],
        item["is_elite"] === 1 ? "Yes" : "No",
        item["is_bib_collected"] === 1 ? "Yes" : "No",
        item["is_tshirt_collected"] === 1 ? "Yes" : "No",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`ExpoReport_${filedType}${moment(new Date()).format(
        "DD-MM-YYYY"
      )}.xlsx`}`
    );
  };

  useEffect(() => {
    viewExpoRepot();
  }, []);

  function handleAddNew() {
    setShowModal(true);
  }
  return (
    <>
      {isLoading && <Loader />}
      <Container className="mt-md-4">
        <Row className="mt-4 p-0">
          <Col className="p-0">
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Dashboard
              </span>
            </div>
            <h6 className="fs-24  mt-md-4 ">Expo Dashboard</h6>
          </Col>
          <Col className="p-0">
            <div className="d-flex justify-content-end">
              <DefaultButton
                className="default-action-button text-white me-md-3"
                variant="primary"
                type="button"
                onClick={() =>
                  navigate(`/support/registrationlist/${encryptText(urlid)}`)
                }
              >
                Registation List
              </DefaultButton>
              <DefaultButton
                className="default-action-button text-white me-md-3"
                variant="primary"
                type="button"
                onClick={() => handleAddNew()}
              >
                Spot Registration
              </DefaultButton>
              <DefaultButton
                className="default-action-button text-white"
                variant="primary"
                type="button"
                onClick={() =>
                  navigate(`/support/expouserlist/${encryptText(urlid)}`)
                }
              >
                Expo Users
              </DefaultButton>
              <DefaultButton
                className="default-action-button text-white ms-md-3"
                variant="primary"
                type="button"
                onClick={() =>
                  navigate(`/support/expobibcollection/${encryptText(urlid)}`)
                }
              >
                Bib Collection
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <Row className="my-md-3 p-0">
          <div className="my-md-3 p-0">
            <h3>T-Shirt and Bibs Collection Count</h3>
          </div>

          <table className="table table-bordered">
            <thead className="thead-dark ">
              <tr>
                <th scope="col">Race Name</th>
                <th scope="col" className="text-center">
                  T-shirt
                </th>
                <th scope="col" className="text-center">
                  Bibs
                </th>
                <th scope="col" className="text-center">
                  Both
                </th>
                <th scope="col" className="text-center">
                  None
                </th>
                <th scope="col" className="text-center">
                  Total Count
                </th>
              </tr>
            </thead>
            <tbody>
              {dataCount.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td className="text-center">{item.tshirt}</td>
                  <td className="text-center">{item.bibs}</td>
                  <td className="text-center">{item.both}</td>
                  <td className="text-center">{item.none}</td>
                  <td className="text-center">{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>

        <Row className="my-4 p-0">
          <Col className="p-0">
            <h6 className="fs-24  mt-md-4 ">Report</h6>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <div>
              <div className="form-floating input-bx mt-md-2 form-row w-100 form-control">
                <div className="col">
                  <select
                    required
                    onChange={(e) => {
                      setFiledType(e.target.value);
                      expoReportsData(e.target.value);
                    }}
                    defaultValue={"-1"}
                  >
                    <option key="" value=""></option>
                    {types?.map((option: any) => {
                      return (
                        <>
                          <option
                            key={option.id}
                            value={option.id}
                            disabled={option.status}
                            onChange={option.id}
                          >
                            {option.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span>Please select type*</span>
                </div>
              </div>
            </div>
          </Col>
          {filedType && (
            <Col xs={12} md={6}>
              <div className="d-flex justify-content-end mt-4">
                <DefaultButton
                  type="submit"
                  variant="primary"
                  className="px-md-5 bg-railBlue text-white"
                  onClick={() => {
                    exportToExcel();
                  }}
                >
                  Download Excel
                </DefaultButton>
              </div>
            </Col>
          )}
        </Row>

        <div className="my-md-5 d-flex justify-content-center align-items-center border">
          <div className="w-100">
            <DataTable
              pagination
              paginationRowsPerPageOptions={itemsPerPageOptions}
              columns={expoAdminList}
              data={expoAdmins}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>
      </Container>

      {showModal && (
        <SpotRegistrationForm show={showModal} onHide={handleCloseModal} />
      )}
    </>
  );
};

export default ExpoAdminEventPage;
