import { Contact } from "../types/Contact";

export const ContactData: Contact[] = [
  {
    id: 1,
    title: "Email",
    description:
      "We love hearing from our valued customers! Whether you have a question, need assistance, or just want to share your thoughts, we're here for you.",
    contactLogo: "/images/email.png",
    data: "info@ifinish.com",
    type: "email",
    phrase: "Get in Touch!",
    footerTitle: "Drop us a message at:",
  },
  {
    id: 2,
    title: "Phone",
    description:
      "We're just a call away and ready to assist you. Whether you have a question, need help, or simply want to connect, we're here to make it easy for you.",
    contactLogo: "/images/phone.png",
    data: "+1 (555) 000-0000",
    type: "phone",
    phrase: "Let's Chat!",
    footerTitle: "Call us at:",
  },
  {
    id: 3,
    title: "Office",
    description:
      "We'd love to see you in person! If you prefer face-to-face interaction or just want to drop by, our doors are open.    ",
    contactLogo: "/images/locationLogo.png",
    data: "Timing Technologies India Private Limited.",
    type: "address",
    phrase: "Visit Us!",
    footerTitle: "Find us at:",
  },
];
