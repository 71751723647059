import { useQuery } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

export const useEventList = () => {
  const axiosPublic = useAxiosPublic();
  return useQuery(
    "useEventList",
    async () => {
      try {
        const response = await axiosPublic({
          method: "GET",
          url: "/userEventList",
        });
        return response.data;
      } catch (error) {
        // toast.error("Error fetching events lists");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
