import { useQuery } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

export const useUserPaymentStatus = () => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    "useUserPaymentStatus",
    async () => {
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: "/userPaymentStatus",
        });

        return response.data;
      } catch (error) {
        // toast.error("Error fetching booking events");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
