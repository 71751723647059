import { useQuery } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

export const useUserParticipants = (bookingId: number) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["useUserParticipants", bookingId],
    async () => {
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: "/bookingParticipants",
          data: {
            bookingId: bookingId,
          },
        });
        return response.data;
      } catch (error) {
        // toast.error("Error fetching user participants");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
