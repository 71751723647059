import React from "react";
import styles from "./StepProgress.module.scss";
import { useLocation } from "react-router-dom";

import {
  StepStates,
  ProgressStep,
  StepProgressProps,
  ReducerAction,
} from "./types";

function stepsReducer(
  steps: ProgressStep[],
  action: ReducerAction
): ProgressStep[] {
  return steps.map(function (step, i) {
    if (i < action.payload.index) {
      step.state = StepStates.COMPLETED;
    } else if (i === action.payload.index) {
      step.state = action.payload.state;
    } else {
      step.state = StepStates.NOT_STARTED;
    }
    return step;
  });
}

function StepProgressBar(props: StepProgressProps): JSX.Element {
  const location = useLocation();

  const {
    steps,
    startingStep,
    showNextStepButton,
    allowNextStep,
    wrapperClass,
    progressClass,
    stepClass,
    labelClass,
    subtitleClass,
    contentClass,
    buttonWrapperClass,
    primaryBtnClass,
    secondaryBtnClass,
    submitBtnName,
    onSubmit,
    goNextStep,
    previousBtnName,
    nextBtnName,
  } = props;
  const [isEditEvent, setIsEditEvent] = React.useState(false);
  const [state, dispatch] = React.useReducer(stepsReducer, steps);
  const [currentIndex, setCurrentIndex] = React.useState(startingStep);
  const [validatedStep, setValidatedStep] = React.useState(
    isEditEvent ? steps.length : startingStep
  );

  React.useEffect(function () {
    dispatch({
      type: "init",
      payload: { index: currentIndex, state: StepStates.CURRENT },
    });
  }, []);

  React.useEffect(() => {
    if (allowNextStep.current) {
      nextHandler();
    }
  }, [goNextStep]);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");

    if (pathName.includes("editEvents") || pathName.includes("vieweventdata")) {
      setIsEditEvent(true);
      setValidatedStep(steps.length);
    } else {
      setIsEditEvent(false);
    }
  }, []);

  function submitHandler(): void {
    onSubmit();
  }

  function nextHandler(): void {
    if (!isEditEvent) {
      if (currentIndex === steps.length - 1) {
        return;
      }
      let isStateValid = true;

      dispatch({
        type: "next",
        payload: {
          index: isStateValid ? currentIndex + 1 : currentIndex,
          state: isStateValid ? StepStates.CURRENT : StepStates.ERROR,
        },
      });

      setValidatedStep(currentIndex + 1);

      if (isStateValid) {
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }

  // function prevHandler(): void {
  //   if (currentIndex === 0) {
  //     return;
  //   }

  //   dispatch({
  //     type: "previous",
  //     payload: {
  //       index: currentIndex - 1,
  //       state: StepStates.CURRENT,
  //     },
  //   });
  //   setCurrentIndex(currentIndex - 1);
  // }

  function stepHandler(index: number): void {
    if (isEditEvent) {
      setCurrentIndex(index);
    } else {
      if (index > validatedStep) {
        return;
      } else {
        dispatch({
          type: "step",
          payload: {
            index: index,
            state: StepStates.CURRENT,
          },
        });
        setCurrentIndex(index);
      }
    }
  }

  return (
    <div className={`${styles["progress-bar-wrapper"]} ${wrapperClass || ""}`}>
      <ul className={`${styles["step-progress-bar"]} ${progressClass || ""}`}>
        {state.map(function (step, i) {
          return (
            <li
              key={i}
              onClick={() => stepHandler(i)}
              className={`${styles["progress-step"]}${
                !isEditEvent && step.state === StepStates.COMPLETED
                  ? ` ${styles.completed}`
                  : ` ${isEditEvent && styles.completed}`
              }${
                !isEditEvent && step.state === StepStates.CURRENT
                  ? ` ${styles.current}`
                  : ""
              }${
                isEditEvent && currentIndex === i ? ` ${styles.current}` : ` `
              }${
                !isEditEvent && step.state === StepStates.ERROR
                  ? ` ${styles["has-error"]}`
                  : ""
              } ${stepClass || ""}`}
            >
              {(step.state === StepStates.COMPLETED ||
                (currentIndex <= validatedStep &&
                  isEditEvent &&
                  currentIndex !== i)) && (
                <span className={styles["step-icon"]}>
                  <svg
                    width="1.5rem"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.5L4.5 7.5L12 1"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </svg>
                </span>
              )}

              {step.state === StepStates.ERROR && (
                <span className={styles["step-icon"]}>!</span>
              )}
              {!isEditEvent &&
                step.state !== StepStates.COMPLETED &&
                step.state !== StepStates.ERROR && (
                  <span className={styles["step-index"]}>{i + 1}</span>
                )}
              {/* Edit event current step */}
              {isEditEvent && currentIndex === i && (
                <span className={`${styles["step-index"]}`}>{i + 1}</span>
              )}
              <div className={`${styles["step-label"]} ${labelClass || ""}`}>
                {step.label}
                {/* {step.subtitle && (
                  <div
                    className={`${styles["step-label-subtitle"]} ${
                      subtitleClass || ""
                    }`}
                  >
                    {step.subtitle}
                  </div>
                )} */}
              </div>
            </li>
          );
        })}
      </ul>

      <div className={`${styles["step-content"]} ${contentClass || ""}`}>
        {props.steps[currentIndex].content}
      </div>

      {/* <div
        className={`${styles["step-buttons"]} ${buttonWrapperClass || ""} ${
          styles.buttonAlignLast
        }`}
      > */}
      {/* <a
              className={`${styles["step-action-btn"]} ${
                styles["action-btn-secondary"]
              } ${currentIndex === 0 ? styles.disabled : ""} ${
                secondaryBtnClass || ""
              }`}
              onClick={prevHandler}
            >
              {previousBtnName ? previousBtnName : "Previous"}
            </a> */}

      {/* {showNextStepButton && (
          <>
            {currentIndex === state.length - 1 ? (
              <a
                className={`${styles["step-action-btn"]} ${
                  styles["action-btn-primary"]
                } ${primaryBtnClass || ""}`}
                onClick={submitHandler}
              >
                {submitBtnName || "Submit"}
              </a>
            ) : (
              <a
                className={`${styles["step-action-btn"]} ${
                  styles["action-btn-primary"]
                } ${primaryBtnClass || ""}`}
                onClick={nextHandler}
              >
                {nextBtnName ? nextBtnName : "Next"}
              </a>
            )}
          </>
        )} */}
      {/* </div> */}
    </div>
  );
}

export default StepProgressBar;
