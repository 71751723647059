/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import RadioInput from "components/Inputs/RadioInput";
import { useGetUserParticipantDetails } from "api/GetUserParticipantDetails";
import { useUpdateParticipantDetails } from "api/UpdateParticipantDetails";
import { toast } from "react-toastify";
import {
  CustomFieldOption,
  CustomFieldSection,
  DynamicParticipant,
} from "types/CustomTypesUpdateParticipant";
import {
  readFileAsBase64,
  replaceUnderScoreWithSpace,
  nameConversion,
} from "utils/utils";
import Loader from "components/Loader/Loader";
interface UpdateParticipantDetailsModalProps {
  show: boolean;
  onHide: () => void;
  raceName: string;
  participantId: number;
  bookingId: number;
}

const buildValidationSchema = (
  sections: CustomFieldSection[] | undefined
): Record<string, any> => {
  if (!sections || !Array.isArray(sections)) {
    return {};
  }

  const schema: Record<string, any> = {};

  sections.forEach((section) => {
    if (Array.isArray(section.re_fields)) {
      section.re_fields.forEach((field) => {
        if (typeof field.field_name === "string") {
          const fieldName = field.field_name;

          const isRequired = field.field_status === 0;

          switch (field.input_type) {
            case "textbox":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string();
              break;
            case "email":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .email("Invalid email format")
                    .required(`${field.label_name} is required`)
                : Yup.string().email("Invalid email format");
              break;
            case "number":
              schema[fieldName] = isRequired
                ? Yup.number().required(`${field.label_name} is required`)
                : Yup.number();
              break;
            case "dropdown":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string().nullable();
              break;
            case "date":
              schema[fieldName] = isRequired
                ? Yup.date().required(`${field.label_name} is required`)
                : Yup.date();
              break;
            case "radio":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .oneOf(
                      field.options || [],
                      `Please select a valid ${field.label_name}`
                    )
                    .required(`${field.label_name} is required`)
                : Yup.string().oneOf(field.options || []);
              break;
            case "file":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string();
              break;
            default:
              break;
          }
        }
      });
    }
  });

  return schema;
};

const UpdateParticipantDetailsModal: React.FC<
  UpdateParticipantDetailsModalProps
> = ({ show, onHide, raceName, participantId, bookingId }) => {
  const [dynamicFields, setDynamicFields] = useState({});
  console.log(participantId, bookingId);
  const {
    data: customFields,
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useGetUserParticipantDetails(bookingId, participantId);

  const updateParticipationDetailsMutation = useUpdateParticipantDetails();

  useEffect(() => {
    if (Array.isArray(customFields?.data)) {
      const fields: Record<string, string> = {};
      customFields?.data.forEach((section: CustomFieldSection) => {
        if (section && Array.isArray(section.re_fields)) {
          section.re_fields.forEach((field: CustomFieldOption) => {
            fields[field.field_name] = field.default_value;
          });
        }
      });
      setDynamicFields(fields);
    }
  }, [customFields?.data]);

  const handleSubmit = async (
    values: DynamicParticipant<CustomFieldOption>
  ) => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      const updatedParticipantDetails = {
        participantId: participantId,
        participantDetails: {
          ...values,
          first_name: nameConversion(values.first_name),
          last_name: nameConversion(values.last_name),
        },
      };

      console.log(updatedParticipantDetails, "Update Participant Details");

      const response = await updateParticipationDetailsMutation.mutateAsync({
        updatedParticipantDetails,
      });

      if (response?.status) {
        toast.success(response?.message);
        formik.resetForm();
        onHide();
      } else {
        toast.error(response?.message);
      }
    }
  };

  const formik = useFormik<DynamicParticipant<CustomFieldOption>>({
    enableReinitialize: true,
    initialValues: dynamicFields,
    validationSchema: Yup.object(buildValidationSchema(customFields?.data)),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setValues(formik.initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    formik.resetForm();
    onHide();
  };

  if (customFieldsError) {
    toast.error("Error loading event details. Please try again later.");
  }

  if (customFieldsLoading) {
    return <Loader />;
  }
  if (!Array.isArray(customFields?.data) || customFields?.data.length === 0) {
    return <div>Error: customFields is not an array or is empty</div>;
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Form
        onSubmit={formik.handleSubmit}
        className=""
        encType="multipart/form-data"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="formTitleInEventRegisteration mb-0">{raceName}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {customFields?.data.map(
            (section: CustomFieldSection) =>
              section.re_fields.length > 0 && (
                <div key={section.id} className="py-2">
                  <p className={`fw-600 ff-saira fs-20`}>
                    {replaceUnderScoreWithSpace(section.section_name)}
                  </p>
                  <Container className="row">
                    {section.re_fields.map((field: CustomFieldOption) => (
                      <div key={field.id} className="col-sm-6 mb-0 mb-sm-4">
                        {field.input_type === "textbox" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.label_name
                              }
                              type="text"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name as string] &&
                                !!formik.errors[field.field_name as string]
                              }
                              error={formik.errors[field.field_name as string]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 30
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "number" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="number"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 30
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "email" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="email"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 32
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "date" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="date"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 62
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "dropdown" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultDropdown
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              options={field?.options}
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 34
                                    : 34,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "radio" && (
                          <div className="mb-3 mb-sm-0">
                            <RadioInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.label_name
                              }
                              labelClassName="position-relative fs-14"
                              options={field.options || []}
                              selectedOption={formik.values[field.field_name]}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  field.field_name,
                                  e.target.value
                                )
                              }
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              helperContent={field?.help_content}
                              error={formik.errors[field.field_name]}
                            />
                          </div>
                        )}
                        {field.input_type === "file" && (
                          <div className="mb-3 mb-sm-0">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="file"
                              placeholder={field.label_name}
                              accept="image/png, image/heic, image/jpeg, image/jpg, application/pdf"
                              onChange={async (event: any) => {
                                const file = event.target.files[0];
                                const base64 = await readFileAsBase64(file);
                                formik.setFieldValue(field.field_name, base64);
                              }}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </Container>
                </div>
              )
          )}
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            type="button"
            className="default-action-button  fw-bold text-white ms-4"
            variant="primary"
            onClick={handleCancel}
          >
            Close
          </DefaultButton>
          <DefaultButton
            type="submit"
            className="default-action-button fw-bold text-white ms-4"
            variant="primary"
          >
            Update Details
          </DefaultButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateParticipantDetailsModal;
