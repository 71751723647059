import { useMutation } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

interface CreateRaceRegistrationsParams {
  participantData: any;
}

export const useCreateRegistration = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: CreateRaceRegistrationsParams) => {
    const { participantData } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/createRaceRegistrations",
        data: {
          participantData: participantData,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error creating Race Rgistration");
      throw error;
    }
  });
};
