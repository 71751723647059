import React from "react";
import OrganiserSignUpForm2 from "components/Forms/OrganizerForms/OrganiserSignUpForm2";
import {
  divStyle,
  formContainerStyle,
  transparentBoxStyle,
} from "utils/OrgSignupPageCss";
import { useNavigate } from "react-router-dom";

const OrgSignUpFormCompany = () => {
  const navigate = useNavigate();
  return (
    <div style={divStyle}>
      <div style={transparentBoxStyle}>
        <div style={formContainerStyle}>
          <h1 className="d-flex justify-content-center mt-md-3 text-uppercase">
            Organisation Sign Up Step-2
          </h1>

          <div>
            <OrganiserSignUpForm2 scrollbar="scroll" />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p>
              Already a event organiser? click here to
              <button
                className="btn text-primary"
                onClick={() => navigate("/org/login")}
              >
                Login
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgSignUpFormCompany;
