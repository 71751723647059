import React from "react";
import { EventTestimonial } from "../../types/EventTestimonial";
import styles from "./Testimonial.module.scss";

const Testimonial: React.FC<EventTestimonial> = ({
  name,
  designation,
  testimonialDescription,
}) => {
  return (
    <>
      <div
        className={`${styles.TestimonialLarge} container mx-auto d-none d-md-block`}
      >
        <p className={`${styles.title} ff-saira fw-bold fs-40`}>Testimonial</p>
        <div className={`${styles.testimonalBox}`}>
          <div className="container">
            <div className="row justify-content-between">
              <div className={`col-10 ${styles.testimonalContainer}`}>
                <div className={`container ${styles.containerPadding}`}>
                  <div className="row">
                    <div className="col-8">
                      <p
                        className={`${styles.testimonialDescription} ff-ns fs-24 text-black`}
                      >
                        {testimonialDescription}
                      </p>
                    </div>
                    <div className="col-4 position-relative">
                      <img
                        className={`${styles.testimonialImage} translate-middle-x`}
                        src="/images/girlathelete.png"
                        alt="athlete"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 d-flex flex-column justify-content-center">
                <div>
                  <p className="fs-20 fw-800 ff-mr mb-1 text-black">{name}</p>
                  <p style={{ color: "#4F4F4F" }} className="fs-14 ff-mr mb-0">
                    {designation}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.TestimonialSmall} d-block d-md-none`}>
        <p className="fs-24 ff-saira fw-bold mb-0">Testimonials</p>
        <p className="mb-4 mt-1 fs-14 ff-ns">
          Read what our customers have to say
        </p>
        <div className={`${styles.testimonialContainer}`}>
          <div className={`${styles.testimonialBox}`}>
            <p
              className={`${styles.testimonialDescription} fs-18 fs-saira mb-0`}
            >
              {testimonialDescription}
            </p>
            <div className={`${styles.nameContainer}`}>
              <p className="mb-1 fw-600 fs-saira">{name}</p>
              <p style={{ color: "#4F4F4F" }} className="mb-0 fs-14 ff-ns">
                {designation}
              </p>
            </div>
          </div>
          <img
            className={`${styles.testimonialImage}`}
            src="/images/girlathelete.png"
            alt="Athlete"
          />
        </div>
      </div>
    </>
  );
};

export default Testimonial;
