import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
// import Badge from "../../../components/Badge/Badge";
import EventPriceCard from "components/EventPriceCard/EventPriceCard";
import { eventDetails, filterOptions } from "../../../api/EventDetails";
import styles from "./PriceOptions.module.scss";
import { Race } from "types/Race";
import { EventDetails } from "types/EventDetails";

type PriceOptionsProps = {
  events: Race[];
  serverTime: string;
};
const PriceOptions: React.FC<PriceOptionsProps> = ({ events, serverTime }) => {
  // const [filterActive, setFilterActive] = useState<number>(0);

  // function handleSelectFilterActive(id: number) {
  //   setFilterActive(id);
  // }

  return (
    <div className={`${styles.priceOptions}`}>
      {/* <div
        className={`${styles.scrollContainer} d-none d-sm-flex justify-content-between align-items-center mt-3 mb-4 `}
      >
        {filterOptions.map((filter: string, index: number) => {
          const firstFilter = 0;
          const lastFilter = filterOptions.length;
          return (
            <Badge
              key={index}
              onClick={() => handleSelectFilterActive(index)}
              className={`px-3 py-1 ${
                index === filterActive ? "badge-active" : "badge-inactive"
              } badge-common ${
                index === lastFilter || index === firstFilter ? "mx-0" : "mx-2"
              }`}
            >
              {filter}
            </Badge>
          );
        })}
      </div> */}
      <div className={`${styles.pricesPaddingX}`}>
        {events?.map((race: Race) => {
          return (
            <div key={race?.id} className={`${styles.eventPriceCard} mb-2 p-0`}>
              <EventPriceCard race={race} serverTime={serverTime} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PriceOptions;
