import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText, encryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const Disclaimers = ({ handleSubmitNextHandler }: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  // let isAddMode: any;
  let eventId: any;
  const isViewEvent = ViewEvent();

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent]);
  const { token } = useAuth();
  const { storeEventId } = useEvent();
  const [generalContent, setGeneralContent] = useState("");
  const [updateGeneralContent, setUpdateGeneralContent] = useState("");
  const [termsandCondition, setTermsAndCondition] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleGeneralEditorChange = (content: any) => {
    setUpdateGeneralContent(content);
  };

  const handleTermsandCondition = (content: any) => {
    setTermsAndCondition(content);
  };

  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const editorToolbar =
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help";

  const editorContentStyle =
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

  const editorAPIKey = process.env.REACT_APP_TINY_API_KEY;
  function getEventDisclaimer() {
    setIsLoading(true);
    // axios.post(
    //   "/getEventDisclaimer",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventDisclaimer",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        setGeneralContent(res.data.data);
        setUpdateGeneralContent(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (Id) {
      getEventDisclaimer();
    }
  }, [Id]);
  const handleSubmit = () => {
    // console.log(
    //   "Disclaimers",
    //   JSON.stringify({ generalContent }),
    //   generalContent
    // );
    setIsLoading(true);
    // eslint-disable-next-line eqeqeq
    if (updateGeneralContent) {
      const EventId: any = storeEventId || Id;
      const postData = {
        eventId: `${EventId}`,
        disclaimer: updateGeneralContent,
        termsandCondition: termsandCondition,
      };

      // axios.post("/eventDisclaimer", postData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     Accept: "application/json",
      //   },
      // });
      axiosPrivate({
        url: "/eventDisclaimer",
        data: postData,
        method: "POST",
      })
        .then((res) => {
          if (res.data.status) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.success(`${res.data.message}`, { autoClose: 1000 });
            setTimeout(() => {
              handleSubmitNextHandler();
              Navigate(`/org/eventDashBoard/${encryptText(EventId)}`);
            }, 2000);
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.error("something went wrong");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      toast.error("please enter disclaimer");
    }
  };
  return (
    <Container>
      {isLoading && <Loader />}
      <ToastContainer />
      <div>
        <Row className="my-4">
          <Col md={12}>
            <h2 className="text-primary"> Disclaimer</h2>{" "}
          </Col>
        </Row>

        <Editor
          apiKey={editorAPIKey}
          onInit={(evt, editor) => (editorRef.current = editor)}
          disabled={isViewEvent}
          initialValue={generalContent}
          init={{
            height: 500,
            menubar: false,
            plugins: editorPlugins,
            toolbar: editorToolbar,
            content_style: editorContentStyle,
            setup: (editor: {
              on: (arg0: string, arg1: () => void) => void;
              getContent: () => any;
            }) => {
              editor.on("change", () => {
                handleGeneralEditorChange(editor.getContent());
              });
            },
          }}
        />
      </div>

      <div>
        <Row className="my-4">
          <Col md={12}>
            <h2 className="text-primary">Terms and Conditions</h2>{" "}
          </Col>
        </Row>

        <Editor
          apiKey={editorAPIKey}
          onInit={(evt, editor) => (editorRef.current = editor)}
          disabled={isViewEvent}
          initialValue={termsandCondition}
          init={{
            height: 500,
            menubar: false,
            plugins: editorPlugins,
            toolbar: editorToolbar,
            content_style: editorContentStyle,
            setup: (editor: {
              on: (arg0: string, arg1: () => void) => void;
              getContent: () => any;
            }) => {
              editor.on("change", () => {
                handleTermsandCondition(editor.getContent());
              });
            },
          }}
        />
      </div>

      <div className="d-flex justify-content-end my-4">
        {!isViewEvent && (
          <button className="btn btn-outline-primary" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
    </Container>
  );
};

export default Disclaimers;
