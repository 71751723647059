import React from "react";
import { useAuth } from "context/AuthContext";
// import axios from "utils/axios";
import { OrganizerType } from "types/Organizer";
import useAxiosPrivate from "./axiosusermodule/useAxiosPrivate";

const useOrganizer = () => {
  const { token } = useAuth();
  const [isOrganizer, setIsOrganizer] = React.useState<OrganizerType | any>();
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>();
  const [isLoading, setIsLoading] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  function OrganizersList() {
    setIsLoading(true);
    // axios.get(
    //   "/organiserList",

    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/organiserList",
      method: "GET",
    })
      .then((res) => {
        const { status, data, isSuperAdmin } = res.data;

        if (status) {
          setIsOrganizer(data);
          setIsSuperAdmin(isSuperAdmin);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  React.useEffect(() => {
    OrganizersList();
  }, []);
  return { isOrganizer, isSuperAdmin, isLoading };
};

export default useOrganizer;
