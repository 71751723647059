import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

type PaymentStatusParams = {
  participantId: number;
  paymentId: string;
  razorpayOrderId: string;
  razorPaySignature: string;
};
export const useCheckChangeCategoryPaymentStatus = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: PaymentStatusParams) => {
    const { participantId, paymentId, razorpayOrderId, razorPaySignature } =
      params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/changeRaceCategoryPaymentStatus",
        data: {
          participantId: participantId,
          razorpayPaymentId: paymentId,
          razorpayOrderId: razorpayOrderId,
          razorpaySignature: razorPaySignature,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error fetching Check Cuange Category Payment Details");
      throw error;
    }
  });
};
