import React from "react";
import { useLocation } from "react-router-dom";

const IsOrganizer = () => {
  const [IsOrganizer, setIsOrganizer] = React.useState(false);
  const location = useLocation();
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("org")) {
        setIsOrganizer(true);
    }
  }, []);
  return IsOrganizer;
};

export default IsOrganizer;
