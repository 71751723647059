/* eslint-disable array-callback-return */
import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import {
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import RadioInput from "components/Inputs/RadioInput";


import {
  CustomFieldOption,
  CustomFieldSection,
  DynamicParticipant,

} from "types/CustomTypes";

import {
  NMDCRaceList,
  fileAllowedTypes,
  fileMaxSize,
  alphabetsSpaceRegex,
  alphaNumericSpaceHypenSlashCommaRegex,
  alphaNumericSpaceHyphenCommaRegex,
  alphaNumericSpace,
} from "utils/utils";
import { readFileAsBase64, replaceUnderScoreWithSpace } from "utils/utils";
import { states } from "utils/address/states";
import { countries } from "utils/address/countries";
import { cities } from "utils/address/cities";
import { runnersgroup } from "utils/runnersgroup/runnersgroup";
import { toast } from "react-toastify";
import moment from "moment";
import styles from "./FormModal.module.scss";
import SpotRegistration from "pages/spotRegistration/SpotRegistration";
import { axiosPrivate } from "utils/axios";

interface SpotRegistrationFormProps {
  show: boolean;
  onHide: () => void;
}

const buildValidationSchema = (
  sections: CustomFieldSection[] | undefined,
  raceName: string
): Record<string, any> => {
  if (!sections || !Array.isArray(sections)) {
    return {};
  }

  const schema: Record<string, any> = {};

  sections.forEach((section) => {
    if (Array.isArray(section.re_fields)) {
      section.re_fields.forEach((field) => {
        if (typeof field.field_name === "string") {
          const fieldName = field.field_name;

          let isRequired;

          if (
            raceName === "10K" &&
            [
              "qualifying_event",
              "qualifying_distance",
              "qualifying_timing",
              "qualifying_timing_certificate_link",
            ].includes(fieldName)
          ) {
            isRequired = false;
          } else {
            isRequired = field.field_status === 0;
          }

          switch (field.input_type) {
            case "textbox":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string().nullable();
              break;
            case "email":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .email("Invalid email format")
                    .required(`${field.label_name} is required`)
                : Yup.string().email("Invalid email format");
              break;
            case "number":
              schema[fieldName] =
                isRequired && fieldName === "pincode"
                  ? Yup.string()
                      .matches(
                        /^\d{6}$/,
                        "Pincode must contain exactly 6 digits"
                      )
                      .required(`${field.label_name} is required`)
                  : schema[fieldName] != isRequired && fieldName === "pincode"
                  ? Yup.string().matches(
                      /^\d{6}$/,
                      "Pincode must contain exactly 6 digits"
                    )
                  : (schema[fieldName] =
                      isRequired && fieldName === "mobile_number"
                        ? Yup.string()
                            .matches(
                              /^\d{10}$/,
                              "Phone no must contain exactly 10 digits"
                            )
                            .required(`${field.label_name} is required`)
                        : schema[fieldName] != isRequired &&
                          fieldName === "mobile_number"
                        ? Yup.string().matches(
                            /^\d{10}$/,
                            "Phone no must contain exactly 10 digits"
                          )
                        : (schema[fieldName] =
                            isRequired && fieldName === "whatsapp_number"
                              ? Yup.string()
                                  .matches(
                                    /^\d{10}$/,
                                    "Whatsapp no must contain exactly 10 digits"
                                  )
                                  .required(`${field.label_name} is required`)
                              : schema[fieldName] != isRequired &&
                                fieldName === "whatsapp_number"
                              ? Yup.string().matches(
                                  /^\d{10}$/,
                                  "Whatsapp no must contain exactly 10 digits"
                                )
                              : fieldName === "emergency_contact_number"
                              ? Yup.string()
                                  .matches(
                                    /^\d{10}$/,
                                    "Emergency contact no must contain exactly 10 digits"
                                  )
                                  .required(`${field.label_name} is required`)
                              : schema[fieldName] != isRequired &&
                                fieldName === "emergency_contact_number"
                              ? Yup.string().matches(
                                  /^\d{10}$/,
                                  "Emergency contact no must contain exactly 10 digits"
                                )
                              : (schema[fieldName] =
                                  isRequired &&
                                  fieldName === "alternate_contact_number"
                                    ? Yup.string()
                                        .matches(
                                          /^\d{10}$/,
                                          "Alternate contact no must contain exactly 10 digits"
                                        )
                                        .required(
                                          `${field.label_name} is required`
                                        )
                                    : schema[fieldName] != isRequired &&
                                      fieldName === "alternate_contact_number"
                                    ? Yup.string().matches(
                                        /^\d{10}$/,
                                        "Alternate contact no must contain exactly 10 digits"
                                      )
                                    : (schema[fieldName] = isRequired
                                        ? Yup.number().required(
                                            `${field.label_name} is required`
                                          )
                                        : Yup.number()))));
              break;
            case "dropdown":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string().nullable();
              break;
            case "date":
              schema[fieldName] = isRequired
                ? Yup.date().required(`${field.label_name} is required`)
                : Yup.date();
              break;
            case "radio":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .oneOf(
                      field.options || [],
                      `Please select a valid ${field.label_name}`
                    )
                    .required(`${field.label_name} is required`)
                : Yup.string().oneOf(field.options || []);
              break;
            case "file":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string();
              break;
            case "url":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .url("Invalid URL format")
                    .required(`${field.label_name} is required`)
                : Yup.string();
              break;
            default:
          }
        }
      });
    }
  });

  return schema;
};

function fetchQualifyingDistances(raceName: string) {
  if (raceName === "5K") {
    return [];
  } else if (raceName === "MARATHON") {
    return [
      {
        id: 3,
        race_name: "Half Marathon",
      },
      {
        id: 4,
        race_name: "Marathon",
      },
      {
        id: 5,
        race_name: "Others",
      },
    ];
  } else {
    return [
      {
        id: 2,
        race_name: "10K",
      },
      {
        id: 3,
        race_name: "Half Marathon",
      },
      {
        id: 4,
        race_name: "Marathon",
      },
      {
        id: 5,
        race_name: "Others",
      },
    ];
  }
}
const SpotRegistrationForm: React.FC<SpotRegistrationFormProps> = ({
  show,
  onHide,
}) => {
  const eventId:number = useMemo(() => 84, []);
  const [dynamicFields, setDynamicFields] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [customTdata, setCustomTdata] = useState([]);
  const [ raceName, setRaceName] = useState<string>("");
  const [isFormAvailable, setIsFormAvailable] = useState<boolean>(false);
  const [filteredStates, setFilteredStates] = useState<any>([]);
  const [filteredCities, setFilteredCities] = useState<any>([]);
  const [formatQualifyingTiming, setFormatQualifyingTime] =
    useState<string>("");
  const [qualifyingFormatError, setQualifyingFormatError] =
    useState<boolean>(false);
  const [isFetchedUserInfo, setIsFetchedUserInfo] = useState<boolean>(false);
  const [userRaceInfo, setUserRaceInfo] = useState<any>([]);
  const [isEnableReadOnlyAttributes, setIsEnableReadOnlyAttributes] =
    useState<boolean>(false);
  const [qualifyingDistances, setQualifyingDistances] = useState<any>(
    fetchQualifyingDistances(raceName)
  );

  
  const [isFileError, setIsFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [isAvailWhatsAppUpdates, setIsAvailWhatsAppUpdates] =
    useState<boolean>(true);

  function filterStates(selectedCountry: any) {
    const newStates = states.filter(
      (state) => state.country_name === selectedCountry
    );
    setFilteredStates(newStates);
  }

  function filterCities(selectedCity: any) {
    const newCities = cities.filter((city) => city.state_name === selectedCity);
    setFilteredCities(newCities);
  }
  const placement = window.innerWidth <= 768 ? "top" : "left";

  function resetForm() {
    setFormatQualifyingTime("");
    setQualifyingFormatError(false);
    formik.resetForm();
    onHide();
  }

  // formik submit 

  const handleSubmit = async (values: DynamicParticipant<CustomFieldOption>)=> {
    console.log(values)
    try {
      const res = await axiosPrivate.post(
         '/expoSpotRegistration',
         {raceName: raceName, eventId: eventId, data : [{...values}] }
        , 
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
          },
        }
      );
  
      const { status, message } = res.data;
      if (status) {
        toast.success(message)
        resetForm();
      } else {
        toast.error(`${message}`, { autoClose: 1500 });
      }
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      // setIsLoading(false); // Uncomment if you have a loading state
    }
  };
  // formik submit 

  const formik = useFormik<DynamicParticipant<CustomFieldOption>>({
    enableReinitialize: true,
    initialValues: dynamicFields,
    validationSchema: Yup.object(
      buildValidationSchema(customFields, raceName)
    ),
    onSubmit: handleSubmit,
    validate: (values) => {
      const errors: any = {};

      if (
        values["history_of_health_issues"] === "Others" &&
        !values["other_health_issues"]
      ) {
        errors.other_health_issues =
          "Other History of health issues is required";
      }

      if (
        values["running_group_name"] === "Others" &&
        !values["other_running_groups"]
      ) {
        errors.other_running_groups = "Other Running group is required";
      }

      if (
        !["10K"].includes(raceName) &&
        values["qualifying_distance"] === "Others" &&
        !values["other_qualifying_distance"]
      ) {
        errors.other_qualifying_distance =
          "Other Qualified Distance is required";
      }
      return errors;
    },
  });


  useEffect(() => {
     if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      const fieldErrorNames = Object.keys(formik.errors);
      let element;

      element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);

      if (!element) {
        element = document.querySelector(`.${fieldErrorNames[0]}`);
      }

      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [formik.isSubmitting]);

  const handleCancel = () => {
    setFormatQualifyingTime("");
    setQualifyingFormatError(false);
    formik.resetForm();
    onHide();
  };

 async function handleSelectedOpt(raceId: any, raceName: any){
  
  await axiosPrivate({
        url: '/expoUserCustomFields',
        method: 'POST',
        data:{
          eventId: eventId,
          raceId: raceId
        }
      }).then((response) => {
       
        if(response.data.status){
         
          setCustomFields(response.data.data);
          setCustomTdata(response.data.tData.size);
          setRaceName(raceName);

          if (Array.isArray(response.data.data)) {
            const fields: Record<string, string> = {};
            response.data.data.forEach((section: CustomFieldSection) => {
              if (section && Array.isArray(section.re_fields)) {
                section.re_fields.forEach((field: CustomFieldOption) => {
                  fields[field.field_name] = "";
                });
              }
            });
            setDynamicFields(fields);
            setIsFormAvailable(true);
          }
        }else{
          setIsFormAvailable(false);
        }
      })
  }


  return (
    <Modal
      show={show}
      onHide={handleCancel}
      size="lg"
      centered
      backdrop="static"
    >
      <Form
        onSubmit={formik.handleSubmit}
        className=""
        encType="multipart/form-data"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="mb-0 formTitleInEventRegisteration">Spot Registration</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SpotRegistration selectOpt={handleSelectedOpt}  />

            {isFormAvailable && customFields.map(
              (section: CustomFieldSection) =>
                section.re_fields.length > 0 && (
                  <div key={section.id} className="py-2">
                    <p className={`fw-600 ff-saira fs-20`}>
                      {replaceUnderScoreWithSpace(section.section_name)}
                    </p>
                    <Container className="row">
                      {section.re_fields.map((field: CustomFieldOption) => {
                        if (
                          ![
                            "other_health_issues",
                            "other_qualifying_distance",
                            "other_running_groups",
                          ].includes(field.field_name)
                        ) {
                          return (
                            <div key={field.id} className="col-lg-6 mb-0 mb-sm-4">
                              {field.input_type === "textbox" &&
                                /* Below validations is a generic text field which will
                                accept all type of inputs with max length of 50 and 12 for Bib Name */
                                ![
                                  "qualifying_timing",
                                  "qualifying_event",
                                  "first_name",
                                  "last_name",
                                  "street_address",
                                  "emergency_contact_name",
                                  "area",
                                ].includes(field.field_name) && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultInput
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      type="text"
                                      name={field.field_name}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={formik.handleChange(
                                        field.field_name
                                      )}
                                      maxLength={
                                        field.field_name === "bib_name" ? 15 : 50
                                      }
                                      isError={
                                        formik.touched[
                                          field.field_name as string
                                        ] &&
                                        !!formik.errors[
                                          field.field_name as string
                                        ]
                                      }
                                      error={
                                        formik.errors[field.field_name as string]
                                      }
                                      readOnly={
                                        field.field_name ===
                                          "qualifying_timing_certificate_link" &&
                                        isEnableReadOnlyAttributes
                                      }
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 30
                                            : 12,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                ["street_address", "area"].includes(
                                  field.field_name
                                ) && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultInput
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      type="text"
                                      name={field.field_name}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          alphaNumericSpaceHypenSlashCommaRegex.test(
                                            inputValue
                                          )
                                        ) {
                                          formik.handleChange(field.field_name)(
                                            e
                                          );
                                        } else if (inputValue.length === 0) {
                                          formik.handleChange(field.field_name)(
                                            e
                                          );
                                        }
                                      }}
                                      maxLength={100}
                                      isError={
                                        formik.touched[
                                          field.field_name as string
                                        ] &&
                                        !!formik.errors[
                                          field.field_name as string
                                        ]
                                      }
                                      error={
                                        formik.errors[field.field_name as string]
                                      }
                                      readOnly={
                                        field.field_name ===
                                          "qualifying_timing_certificate_link" &&
                                        isEnableReadOnlyAttributes
                                      }
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 30
                                            : 12,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                [
                                  "first_name",
                                  "last_name",
                                  "emergency_contact_name",
                                ].includes(field.field_name) && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultInput
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      name={field.field_name}
                                      type="text"
                                      maxLength={50}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={(event) => {
                                        const input = event.target.value;
                                        if (alphabetsSpaceRegex.test(input)) {
                                          formik.setFieldValue(
                                            field.field_name,
                                            input
                                          );
                                        } else if (input.length === 0) {
                                          formik.setFieldValue(
                                            field.field_name,
                                            input
                                          );
                                        }
                                      }}
                                      isError={
                                        formik.touched[
                                          field.field_name as string
                                        ] &&
                                        !!formik.errors[
                                          field.field_name as string
                                        ]
                                      }
                                      error={
                                        formik.errors[field.field_name as string]
                                      }
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 30
                                            : 12,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "number" && (
                                <div className="mb-4 mb-sm-0 position-relative">
                                  <DefaultInput
                                    label={
                                      field.field_status === 0
                                        ? field.label_name + " *"
                                        : field.label_name
                                    }
                                    type="text"
                                    name={field.field_name}
                                    placeholder={field.label_name}
                                    value={formik.values[field.field_name]}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      const dynamicLength =
                                        field.label_name === "Pincode" ? 6 : 10;
                                      const regex = /^\d+$/;
                                      if (
                                        regex.test(inputValue) &&
                                        inputValue.length <= dynamicLength
                                      ) {
                                        formik.handleChange(field.field_name)(e);
                                      } else if (inputValue.length === 0) {
                                        formik.handleChange(field.field_name)(e);
                                      }
                                    }}
                                    isError={
                                      formik.touched[field.field_name] &&
                                      !!formik.errors[field.field_name]
                                    }
                                    error={formik.errors[field.field_name]}
                                  />
                                  {field?.help_content !== null && (
                                    <span
                                      style={{
                                        top: 16.8,
                                        right: formik.errors[field.field_name]
                                          ? 30
                                          : 12,
                                      }}
                                      className="position-absolute"
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>{field?.help_content}</Tooltip>
                                        }
                                      >
                                        <img
                                          className="cursor-pointer"
                                          height={14.5}
                                          width={15}
                                          alt="info tooltip"
                                          src="/images/info.svg"
                                        />
                                      </OverlayTrigger>
                                    </span>
                                  )}
                                </div>
                              )}

                              {field.input_type === "email" && (
                                <div className="mb-4 mb-sm-0 position-relative">
                                  {field.field_name === "email" ? (
                                    <DefaultInput
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      name={field.field_name}
                                      type="email"
                                      maxLength={50}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={formik.handleChange(
                                        field.field_name
                                      )}
                                      isError={
                                        formik.touched[field.field_name] &&
                                        !!formik.errors[field.field_name]
                                      }
                                      error={formik.errors[field.field_name]}
                                    />
                                  ) : (
                                    <DefaultInput
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      type="email"
                                      maxLength={50}
                                      name={field.field_name}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={formik.handleChange(
                                        field.field_name
                                      )}
                                      isError={
                                        formik.touched[field.field_name] &&
                                        !!formik.errors[field.field_name]
                                      }
                                      error={formik.errors[field.field_name]}
                                    />
                                  )}

                                  {field?.help_content !== null && (
                                    <span
                                      style={{
                                        top: 16.8,
                                        right: formik.errors[field.field_name]
                                          ? 32
                                          : 12,
                                      }}
                                      className="position-absolute"
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>{field?.help_content}</Tooltip>
                                        }
                                      >
                                        <img
                                          className="cursor-pointer"
                                          height={14.5}
                                          width={15}
                                          alt="info tooltip"
                                          src="/images/info.svg"
                                        />
                                      </OverlayTrigger>
                                    </span>
                                  )}
                                </div>
                              )}

                              {field.input_type === "date" && (
                                <div className="mb-4 mb-sm-0 position-relative">
                                  <DefaultInput
                                    label={
                                      field.field_status === 0
                                        ? field.label_name + " *"
                                        : field.label_name
                                    }
                                    type="date"
                                    maxDate={moment().format("YYYY-MM-DD")}
                                    placeholder={field.label_name}
                                    name={field.field_name}
                                    value={formik.values[field.field_name]}
                                    onChange={formik.handleChange(
                                      field.field_name
                                    )}
                                    onBlur={(event) => {
                                      const selectedDate = event.target.value;
                                      const currentDate =
                                        moment().format("YYYY-MM-DD");

                                      if (
                                        selectedDate &&
                                        moment(selectedDate).isAfter(
                                          currentDate,
                                          "day"
                                        )
                                      ) {
                                        formik.setFieldValue(
                                          field.field_name,
                                          ""
                                        );
                                      }
                                    }}
                                    isError={
                                      formik.touched[field.field_name] &&
                                      !!formik.errors[field.field_name]
                                    }
                                    error={formik.errors[field.field_name]}
                                  />
                                  {field?.help_content !== null && (
                                    <span
                                      style={{
                                        top: 16.8,
                                        right: formik.errors[field.field_name]
                                          ? 62
                                          : 12,
                                      }}
                                      className="position-absolute"
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>{field?.help_content}</Tooltip>
                                        }
                                      >
                                        <img
                                          className="cursor-pointer"
                                          height={14.5}
                                          width={15}
                                          alt="info tooltip"
                                          src="/images/info.svg"
                                        />
                                      </OverlayTrigger>
                                    </span>
                                  )}
                                </div>
                              )}

                              {field.input_type === "dropdown" &&
                                ![
                                  "country",
                                  "state",
                                  "city",
                                  "qualifying_distance",
                                  "t_shirt_size",
                                  "running_group_name",
                                ].includes(field.field_name) && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultDropdown
                                      className={field.field_name}
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      name={field.field_name}
                                      options={field?.options}
                                      placeholder={field.label_name}
                                      disablePlaceholder={
                                        field.field_status === 0 ? true : false
                                      }
                                      value={formik.values[field.field_name]}
                                      onChange={formik.handleChange(
                                        field.field_name
                                      )}
                                      isError={
                                        formik.touched[field.field_name] &&
                                        !!formik.errors[field.field_name]
                                      }
                                      error={formik.errors[field.field_name]}
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "radio" && (
                                <div className="mb-4 mb-sm-0">
                                  <RadioInput
                                    label={
                                      field.field_status === 0
                                        ? field.label_name + " *"
                                        : field.label_name
                                    }
                                    labelClassName="position-relative fs-14 ff-saira"
                                    className="ff-saira"
                                    name={field.field_name}
                                    options={field.options || []}
                                    selectedOption={
                                      formik.values[field.field_name]
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        field.field_name,
                                        e.target.value
                                      )
                                    }
                                    isError={
                                      formik.touched[field.field_name] &&
                                      !!formik.errors[field.field_name]
                                    }
                                    helperContent={field?.help_content}
                                    error={formik.errors[field.field_name]}
                                  />
                                </div>
                              )}

                              {field.input_type === "file" && (
                                <>
                                  <div className={`mb-4 mb-sm-0`}>
                                    <div className="position-relative">
                                      <DefaultInput
                                        className={`${
                                          !!formik.values.upload_id_proof &&
                                          "me-2"
                                        }`}
                                        label={
                                          field.field_status === 0
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        name={field.field_name}
                                        type="file"
                                        placeholder={field.label_name}
                                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                                        onChange={async (event: any) => {
                                          const file = event.target.files[0];
                                          if (
                                            !fileAllowedTypes?.includes(
                                              file?.type
                                            )
                                          ) {
                                            setIsFileError(true);
                                            setFileErrorMessage(
                                              "Please select an image (PNG, JPEG, JPG) or a PDF."
                                            );
                                            return;
                                          } else if (file?.size > fileMaxSize) {
                                            setIsFileError(true);
                                            setFileErrorMessage(
                                              "File size exceeds the allowed limit of 3 MB."
                                            );
                                            return;
                                          } else {
                                            setIsFileError(false);
                                            setFileErrorMessage("");
                                            const base64 = await readFileAsBase64(
                                              file
                                            );
                                            formik.setFieldValue(
                                              field.field_name,
                                              base64
                                            );
                                          }
                                        }}
                                        isError={
                                          (formik.touched[field.field_name] &&
                                            !!formik.errors[field.field_name]) ||
                                          isFileError
                                        }
                                        error={
                                          isFileError
                                            ? fileErrorMessage
                                            : formik.errors[field.field_name]
                                        }
                                      />
                                      {field?.help_content !== null && (
                                        <span
                                          style={{
                                            top: 16.8,
                                            right: formik.errors[field.field_name]
                                              ? 34
                                              : !!formik.values.upload_id_proof
                                              ? 20
                                              : 12,
                                          }}
                                          className="position-absolute"
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {field?.help_content}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              className="cursor-pointer"
                                              height={14.5}
                                              width={15}
                                              alt="info tooltip"
                                              src="/images/info.svg"
                                            />
                                          </OverlayTrigger>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mt-2 mb-1 text-center">
                                    {!!formik.values.upload_id_proof && (
                                      <div>
                                        {formik.values.upload_id_proof.startsWith(
                                          "data:image"
                                        ) ? (
                                          <img
                                            className="border border-1 border-dark"
                                            src={formik.values.upload_id_proof}
                                            alt="Preview"
                                            style={{
                                              border: "1px solid #000000",
                                              borderRadius: "1rem",
                                              width: "180px",
                                              height: "140px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        ) : (
                                          <iframe
                                            src={formik.values.upload_id_proof}
                                            title="PDF Preview"
                                            style={{
                                              width: "180px",
                                              height: "140px",
                                              border: "1px solid #000000",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              {field.input_type === "url" && (
                                <div className="mb-4 mb-sm-0 position-relative">
                                  <DefaultInput
                                    label={
                                      field.field_status === 0 &&
                                      raceName !== "10K"
                                        ? field.label_name + " *"
                                        : field.label_name
                                    }
                                    type="url"
                                    placeholder={field.label_name}
                                    value={formik.values[field.field_name]}
                                    onChange={formik.handleChange(
                                      field.field_name
                                    )}
                                    name={field.field_name}
                                    isError={
                                      formik.touched[field.field_name] &&
                                      !!formik.errors[field.field_name]
                                    }
                                    error={formik.errors[field.field_name]}
                                    readOnly={isEnableReadOnlyAttributes}
                                  />
                                  {field?.help_content !== null && (
                                    <span
                                      style={{
                                        top: 16.8,
                                        right: formik.errors[field.field_name]
                                          ? 30
                                          : 12,
                                      }}
                                      className="position-absolute"
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>{field?.help_content}</Tooltip>
                                        }
                                      >
                                        <img
                                          className="cursor-pointer"
                                          height={14.5}
                                          width={15}
                                          alt="info tooltip"
                                          src="/images/info.svg"
                                        />
                                      </OverlayTrigger>
                                    </span>
                                  )}
                                </div>
                              )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "country" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultDropdown
                                      className={field.field_name}
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      options={countries?.map(
                                        (country: any) => country.name
                                      )}
                                      name={field.field_name}
                                      placeholder={field.label_name}
                                      disablePlaceholder={true}
                                      value={formik.values[field.field_name]}
                                      onChange={(event) => {
                                        if (
                                          event.target.value !==
                                          formik.values[field.field_name]
                                        ) {
                                          formik.setFieldValue("state", "");
                                          formik.setFieldValue("city", "");
                                        }
                                        formik.setFieldValue(
                                          field.field_name,
                                          event.target.value
                                        );
                                        filterStates(event.target.value);
                                      }}
                                      isError={
                                        formik.touched[field.field_name] &&
                                        !!formik.errors[field.field_name]
                                      }
                                      error={formik.errors[field.field_name]}
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "state" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    {filteredStates.length > 0 ? (
                                      <DefaultDropdown
                                        className={field.field_name}
                                        label={
                                          field.field_status === 0
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        options={filteredStates?.map(
                                          (state: any) => state.name
                                        )}
                                        name={field.field_name}
                                        placeholder={field.label_name}
                                        disablePlaceholder={true}
                                        value={formik.values[field.field_name]}
                                        onChange={(event) => {
                                          if (
                                            event.target.value !==
                                            formik.values[field.field_name]
                                          ) {
                                            formik.setFieldValue("city", "");
                                          }
                                          formik.setFieldValue(
                                            field.field_name,
                                            event.target.value
                                          );
                                          filterCities(event.target.value);
                                        }}
                                        isError={
                                          formik.touched[field.field_name] &&
                                          !!formik.errors[field.field_name]
                                        }
                                        error={formik.errors[field.field_name]}
                                      />
                                    ) : (
                                      <DefaultInput
                                        label={
                                          field.field_status === 0
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        type="text"
                                        name={field.field_name}
                                        maxLength={50}
                                        placeholder={field.label_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (
                                            alphabetsSpaceRegex.test(inputValue)
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                                    )}
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "city" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    {formik.values["country"] === "India" ? (
                                      <DefaultDropdown
                                        className={field.field_name}
                                        label={
                                          field.field_status === 0
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        options={filteredCities?.map(
                                          (state: any) => state.name
                                        )}
                                        placeholder={field.label_name}
                                        disablePlaceholder={true}
                                        value={formik.values[field.field_name]}
                                        name={field.field_name}
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            field.field_name,
                                            event.target.value
                                          );
                                        }}
                                        isError={
                                          formik.touched[field.field_name] &&
                                          !!formik.errors[field.field_name]
                                        }
                                        error={formik.errors[field.field_name]}
                                      />
                                    ) : (
                                      <DefaultInput
                                        label={
                                          field.field_status === 0
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        type="text"
                                        maxLength={50}
                                        placeholder={field.label_name}
                                        name={field.field_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (
                                            alphabetsSpaceRegex.test(inputValue)
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                                    )}

                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                field.field_name === "qualifying_timing" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultInput
                                      label={
                                        field.field_status === 0 &&
                                        raceName !== "10K"
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      type="text"
                                      placeholder={field.label_name}
                                      value={formatQualifyingTiming}
                                      name={field.field_name}
                                      onChange={(event) => {
                                        const inputValue = event.target.value;
                                        if (inputValue.length <= 8) {
                                          const numericValue = inputValue.replace(
                                            /\D/g,
                                            ""
                                          );
                                          const formattedValue =
                                            numericValue.replace(
                                              /(\d{2})(?=\d)/g,
                                              "$1:"
                                            );
                                          setFormatQualifyingTime(formattedValue);
                                        }
                                      }}
                                      onBlur={(event) => {
                                        const timeRegex =
                                          /^([0-9]{2}):[0-5][0-9]:[0-5][0-9]$/;
                                        if (
                                          !timeRegex.test(event.target.value) ||
                                          event.target.value === "00:00:00"
                                        ) {
                                          setQualifyingFormatError(true);
                                        } else {
                                          formik.setFieldValue(
                                            field.field_name,
                                            event.target.value
                                          );
                                          setQualifyingFormatError(false);
                                        }

                                        if (
                                          raceName === "10K" &&
                                          event.target.value.length === 0
                                        ) {
                                          setQualifyingFormatError(false);
                                        }
                                      }}
                                      isError={
                                        (formik.touched[
                                          field.field_name as string
                                        ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]) ||
                                        qualifyingFormatError
                                      }
                                      error={
                                        qualifyingFormatError
                                          ? "Qualifying Timing format must be in HH:MM:SS"
                                          : formik.errors[
                                              field.field_name as string
                                            ]
                                      }
                                      readOnly={isEnableReadOnlyAttributes}
                                    />
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right:
                                            formik.errors[field.field_name] ||
                                            qualifyingFormatError
                                              ? 34
                                              : 12,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                field.field_name === "qualifying_event" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                  
                                      <DefaultInput
                                        label={
                                          field.field_status === 0 &&
                                          raceName !== "10K"
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        type="text"
                                        name={field.field_name}
                                        placeholder={field.label_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (
                                            alphaNumericSpaceHyphenCommaRegex.test(
                                              inputValue
                                            )
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                            
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "qualifying_distance" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                  
                                      <DefaultDropdown
                                        className={field.field_name}
                                        label={
                                          field.field_status === 0 &&
                                          raceName !== "10K"
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        options={qualifyingDistances.map(
                                          (qualifyDist: any) =>
                                            qualifyDist.race_name
                                        )}
                                        name={field.field_name}
                                        placeholder={field.label_name}
                                        disablePlaceholder={true}
                                        value={formik.values[field.field_name]}
                                        onChange={(event) => {
                                          if (event.target.value) {
                                            formik.setFieldValue(
                                              field.field_name,
                                              event.target.value
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[field.field_name] &&
                                          !!formik.errors[field.field_name]
                                        }
                                        error={formik.errors[field.field_name]}
                                      />
                                 
                                    {field?.help_content !== null && (
                                      <span
                                        style={{
                                          top: 16.8,
                                          right: formik.errors[field.field_name]
                                            ? 34
                                            : 34,
                                        }}
                                        className="position-absolute"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {field?.help_content}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="cursor-pointer"
                                            height={14.5}
                                            width={15}
                                            alt="info tooltip"
                                            src="/images/info.svg"
                                          />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "running_group_name" && (
                                  <div className="mb-4 mb-sm-0 position-relative">
                                    <DefaultDropdown
                                      className={field.field_name}
                                      label={
                                        field.field_status === 0
                                          ? field.label_name + " *"
                                          : field.label_name
                                      }
                                      name={field.field_name}
                                      options={runnersgroup}
                                      placeholder={field.label_name}
                                      value={formik.values[field.field_name]}
                                      onChange={(e) =>
                                        formik.handleChange(field.field_name)(e)
                                      }
                                      isError={
                                        formik.touched[field.field_name] &&
                                        !!formik.errors[field.field_name]
                                      }
                                      error={formik.errors[field.field_name]}
                                    />
                                  </div>
                                )}

                              {field.input_type === "dropdown" &&
                                field.field_name === "t_shirt_size" && (
                                  <Container className="row align-items-center px-0">
                                    <div className="col-7 col-sm-8">
                                      <div className="mb-4 mb-sm-0 position-relative">
                                        <DefaultDropdown
                                          className={field.field_name}
                                          label={
                                            field.field_status === 0
                                              ? field.label_name + " *"
                                              : field.field_name
                                          }
                                          options={customTdata}
                                          placeholder={field.label_name}
                                          disablePlaceholder={true}
                                          name={field.field_name}
                                          value={formik.values[field.field_name]}
                                          onChange={formik.handleChange(
                                            field.field_name
                                          )}
                                          isError={
                                            formik.touched[field.field_name] &&
                                            !!formik.errors[field.field_name]
                                          }
                                          error={formik.errors[field.field_name]}
                                        />

                                        {field?.help_content !== null && (
                                          <span
                                            style={{
                                              top: 16.8,
                                              right: formik.errors[
                                                field.field_name
                                              ]
                                                ? 34
                                                : 34,
                                            }}
                                            className="position-absolute"
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>
                                                  {field?.help_content}
                                                </Tooltip>
                                              }
                                            >
                                              <img
                                                className="cursor-pointer"
                                                height={14.5}
                                                width={15}
                                                alt="info tooltip"
                                                src="/images/info.svg"
                                              />
                                            </OverlayTrigger>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col mb-3 px-0">
                                      <span>
                                        <OverlayTrigger
                                          placement={placement}
                                          overlay={
                                            <Tooltip className="tshirt-tooltip">
                                              <Image
                                                className={`${styles.imageSize}`}
                                                src="/images/tshirtchart.jpeg"
                                                alt="size chart"
                                              />
                                            </Tooltip>
                                          }
                                        >
                                          <span className="fs-12 cursor-pointer">
                                            Size Chart
                                            <img
                                              className="ms-1 mb-1"
                                              height={14}
                                              width={14}
                                              alt="info tooltip"
                                              src="/images/info.svg"
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </div>
                                  </Container>
                                )}
                            </div>
                          );
                        } else {
                          return (
                            <>
                              {field.input_type === "textbox" &&
                                field.field_name ===
                                  "other_qualifying_distance" &&
                                formik.values["qualifying_distance"] ===
                                  "Others" && (
                                  <div className="col-lg-6 mb-0 mb-sm-4">
                                    <div className="mb-4 mb-sm-0 position-relative">
                                      <DefaultInput
                                        label={
                                          field.field_status === 1 &&
                                          raceName !== "10K"
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        type="text"
                                        name={field.field_name}
                                        maxLength={50}
                                        placeholder={field.label_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (
                                            alphaNumericSpaceHyphenCommaRegex.test(
                                              inputValue
                                            )
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              e
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                                      {field?.help_content !== null && (
                                        <span
                                          style={{
                                            top: 16.8,
                                            right: formik.errors[field.field_name]
                                              ? 30
                                              : 12,
                                          }}
                                          className="position-absolute"
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {field?.help_content}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              className="cursor-pointer"
                                              height={14.5}
                                              width={15}
                                              alt="info tooltip"
                                              src="/images/info.svg"
                                            />
                                          </OverlayTrigger>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                field.field_name === "other_running_groups" &&
                                formik.values["running_group_name"] ===
                                  "Others" && (
                                  <div className="col-lg-6 mb-0 mb-sm-4">
                                    <div className="mb-4 mb-sm-0 position-relative">
                                      <DefaultInput
                                        label={
                                          field.field_status === 1
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        maxLength={50}
                                        type="text"
                                        name={field.field_name}
                                        placeholder={field.label_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(event) => {
                                          const inputValue = event.target.value;
                                          if (
                                            alphaNumericSpace.test(inputValue)
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              event
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              event
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                                      {field?.help_content !== null && (
                                        <span
                                          style={{
                                            top: 16.8,
                                            right: formik.errors[field.field_name]
                                              ? 30
                                              : 12,
                                          }}
                                          className="position-absolute"
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {field?.help_content}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              className="cursor-pointer"
                                              height={14.5}
                                              width={15}
                                              alt="info tooltip"
                                              src="/images/info.svg"
                                            />
                                          </OverlayTrigger>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}

                              {field.input_type === "textbox" &&
                                field.field_name === "other_health_issues" &&
                                formik.values["history_of_health_issues"] ===
                                  "Others" && (
                                  <div className="col-lg-6 mb-0 mb-sm-4">
                                    <div className="mb-4 mb-sm-0 position-relative">
                                      <DefaultInput
                                        label={
                                          field.field_status === 1
                                            ? field.label_name + " *"
                                            : field.label_name
                                        }
                                        maxLength={50}
                                        type="text"
                                        name={field.field_name}
                                        placeholder={field.label_name}
                                        value={formik.values[field.field_name]}
                                        onChange={(event) => {
                                          const inputValue = event.target.value;
                                          if (
                                            alphaNumericSpace.test(inputValue)
                                          ) {
                                            formik.handleChange(field.field_name)(
                                              event
                                            );
                                          } else if (inputValue.length === 0) {
                                            formik.handleChange(field.field_name)(
                                              event
                                            );
                                          }
                                        }}
                                        isError={
                                          formik.touched[
                                            field.field_name as string
                                          ] &&
                                          !!formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                        error={
                                          formik.errors[
                                            field.field_name as string
                                          ]
                                        }
                                      />
                                      {field?.help_content !== null && (
                                        <span
                                          style={{
                                            top: 16.8,
                                            right: formik.errors[field.field_name]
                                              ? 30
                                              : 12,
                                          }}
                                          className="position-absolute"
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {field?.help_content}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              className="cursor-pointer"
                                              height={14.5}
                                              width={15}
                                              alt="info tooltip"
                                              src="/images/info.svg"
                                            />
                                          </OverlayTrigger>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}
                            </>
                          );
                        }
                      })}
                    </Container>
                  </div>
                )
            )}
           
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            type="button"
            className="default-action-button  fw-bold text-white ms-4"
            variant="primary"
            onClick={handleCancel}
          >
            Close
          </DefaultButton>
          <DefaultButton
            type="submit"
            className="default-action-button fw-bold text-white ms-4"
            variant="primary"
          >
            Save Details
          </DefaultButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SpotRegistrationForm;
