import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useAuth } from "context/AuthContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader/Loader";
import MultiSelect, { Option } from "components/Inputs/MultiSelect";
import ViewEvent from "utils/ViewEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const BenefitsFrom = ({ handleSubmitNextHandler, setIntialScreen }: any) => {
  const { token } = useAuth();
  const isViewEvent = ViewEvent();
  const { storeRaceId, setAccountId } = useEvent();
  const [isBenefits, setIsBenefits] = useState<any>();
  const [isallowdata, setIsAllow] = useState<any>();
  const [selectedBenefitId, setSelectedBenefitId] = useState<number | null>();
  const [showDetailsMap, setShowDetailsMap] = useState<any>({});
  const [isBenfitData, setIsBenefitData] = useState<any>([]);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isShirt, setIsShirt] = useState<any>();
  const [isAmount, setIsAmount] = useState<any>();
  const [optionSelected, setSelected] = useState<Option[] | null>();
  const axiosPrivate = useAxiosPrivate();

  const options = [
    { value: 0, label: "XS" },
    { value: 1, label: "S" },
    { value: 2, label: "M" },
    { value: 3, label: "L" },
    { value: 4, label: "XL" },
    { value: 5, label: "XXL" },
    { value: 6, label: "XXXL" },
  ];

  const handleMultiChange = (selected: Option[]) => {
    setSelected(selected);
  };

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);

  function fetchRaceBenefitsList() {
    setIsloading(true);
    //  axios.post(
    //   "/getRaceBenefitList",
    //   {
    //     raceId: storeRaceId,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getRaceBenefitList",
      data: { raceId: storeRaceId },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          setIsBenefitData(res.data.data);
          if (res.data.data.length > 1) {
            // Checking if the length is more than 1
            const data = "1"; // Setting the value to "1"
            setIsAllow(data); // Setting the state to "1"
            const matchingItem = res.data.data.find(
              (item: any) => item.benefit_id === 11
            );

            if (matchingItem && matchingItem.race_tshirt_details !== null) {
              setSelectedBenefitId(11);
              setIsShirt("1");
              const tshirtAmount =
                matchingItem && matchingItem.race_tshirt_details.tshirt_amount;
              setIsAmount(tshirtAmount);
              const tshirtSize =
                matchingItem && matchingItem.race_tshirt_details.size;

              const mappedOptions = JSON.parse(tshirtSize).map(
                (size: string) => {
                  const index = options.findIndex(
                    (option) => option.label === size
                  );
                  return { ...options[index], label: size };
                }
              );
              setSelected(mappedOptions);
            }
          } else {
            const data = "0"; // Setting the value to "0"
            setIsAllow(data); // Setting the state to "0"
          }
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsloading(false));
  }

  function fetchBenefitsList() {
    // axios.get("/benefitList", {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/benefitList",
      method: "GET",
    }).then((res) => {
      if (res.data.status) {
        setIsBenefits(res.data.data);
      }
    });
  }

  useEffect(() => {
    fetchRaceBenefitsList();
    fetchBenefitsList();
  }, []);

  const hnadleAllowchange = (e: any) => {
    const values = e.target.value;
    setIsAllow(values);

    if (values === "0") {
      setShowDetailsMap({});
      setSelected(null);
      setSelectedBenefitId(null);
      setIsShirt("");
      setIsAmount(null);
    }
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setShowDetailsMap((prevDetailsMap: any) => ({
      ...prevDetailsMap,
      [name]: value === "1",
    }));

    if (name === "11") {
      setIsShirt(value);
      setSelectedBenefitId(parseInt(name));
    }
    if (name === "11" && value === "0") {
      setSelected(null);
      setIsAmount(null);
    }
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsloading(true);

    // const selectedBenefits = Object.keys(showDetailsMap).map((benefitId) => ({
    //   benefitId,
    //   value: showDetailsMap[benefitId] ? 1 : 0,
    // }));

    // console.log("selectedBenefits", selectedBenefits);
    // console.log("showDetailsMap", showDetailsMap);
    // const selectedBenefitIds = showDetailsMap.map(
    //   (item: any) => item.benefitId
    // );

    // Adjusting the selected data based on initial data
    // const adjustedSelectedData = isBenefits.map((benefit: any) => {
    //   const selectedBenefitId = benefit.id.toString();
    //   const isSelected = showDetailsMap[selectedBenefitId] === true;
    //   const value = isSelected ? 1 : 0;
    //   return {
    //     benefitId: selectedBenefitId,
    //     value: value,
    //   };
    // });
    let adjustedSelectedData;
    if (isBenfitData.length === 0) {
      adjustedSelectedData = isBenefits.map((benefit: any) => {
        const selectedBenefitId = benefit.id.toString();
        const isSelected = showDetailsMap[selectedBenefitId] === true;
        const value = isSelected ? 1 : 0;
        return {
          benefitId: selectedBenefitId,
          value: value,
        };
      });
    } else {
      adjustedSelectedData = isBenefits.map((benefit: any) => {
        const selectedBenefit = isBenfitData.find(
          (item: any) => item.benefit_id === benefit.id
        );

        const selectedBenefitId = benefit.id.toString();
        const isSelected = showDetailsMap[selectedBenefitId] === true;
        let value;

        if (
          selectedBenefit &&
          showDetailsMap[selectedBenefitId] !== undefined
        ) {
          value = isSelected ? 1 : 0;
        } else {
          value = selectedBenefit ? selectedBenefit.status : 0;
        }

        return {
          benefitId: benefit.id,
          value: value,
        };
      });
    }

    const amount =
      isShirt === "1" ? (isallowdata === "0" ? null : isAmount) : null;
    const sizes = optionSelected?.map((item: any) => item.label);
    const Tshirt =
      isShirt === "1" ? (isallowdata === "0" ? null : sizes) : null;
    const postData = {
      raceId: `${storeRaceId}`,
      amount: amount,
      data: isallowdata === "0" ? [] : adjustedSelectedData,
      tshirtSize: Tshirt,
    };

    // axios.post("/createRaceBenefit", postData, {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/createRaceBenefit",
      data: postData,
      method: "post",
    })
      .then((res) => {
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${res.data.message}`, { autoClose: 1000 });
          // handleSubmitNextHandler();
          setTimeout(() => {
            setIntialScreen(false);
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsloading(false));
  };

  const handleAmountChange = (e: any) => {
    setIsAmount(e.target.value);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <Row>
            <Col className="col-12">
              <Form.Label className="my-2">
                Will this race come with benefits?{" "}
                <span className="text-primary">*</span>
              </Form.Label>

              <Form.Group>
                <Form.Check
                  inline
                  label="Yes"
                  name="allowBenefits"
                  type="radio"
                  onChange={hnadleAllowchange}
                  value="1"
                  checked={isallowdata === "1"}
                  // defaultChecked={isallowdata === "1"}
                />
                <Form.Check
                  inline
                  label="No"
                  name="allowBenefits"
                  type="radio"
                  onChange={hnadleAllowchange}
                  value="0"
                  checked={isallowdata === "0"}
                  // defaultChecked={isallowdata === "0"}
                />
              </Form.Group>

              {isallowdata === "1" && (
                <>
                  <Row className="mt-2">
                    <p className="ff-saira my-md-3">
                      The below benefits will be shown to the participants when
                      purchasing tickets. The race list will be divided into
                      "with benefits" and "without benefits". The icons for the
                      benefits will be shown to the participant if selected yes
                      in the "with benefits" section. The benefit name will be
                      shown when the user hovers over the icon.{" "}
                    </p>
                  </Row>
                  {isBenefits?.map((data: any) => {
                    let bindBenefits: any;

                    if (isBenfitData?.length > 0) {
                      bindBenefits = isBenfitData.filter(
                        (benefit: any) => benefit?.benefit_id === data.id
                      );
                    }

                    const Tshirt =
                      bindBenefits &&
                      bindBenefits[0].benefit_id === 11 &&
                      bindBenefits &&
                      bindBenefits[0].race_tshirt_details === null;

                    return (
                      <>
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td style={{ width: "250px" }}>
                                {data.benefit_name}
                              </td>
                              <td style={{ width: "60px" }}>
                                <img
                                  src={`${process.env.REACT_APP_URL}${data.benefit_icon_name}`}
                                  alt={data.benefit_name}
                                />
                              </td>

                              <td>
                                <Form.Group>
                                  <Form.Check
                                    inline
                                    label="Yes"
                                    name={`${data.id}`}
                                    type="radio"
                                    defaultChecked={
                                      bindBenefits &&
                                      bindBenefits[0]?.status === 1
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange}
                                    value="1"
                                    disabled={isPastEvent}
                                  />
                                  <Form.Check
                                    inline
                                    label="No"
                                    name={`${data.id}`}
                                    type="radio"
                                    onChange={handleChange}
                                    defaultChecked={
                                      bindBenefits &&
                                      bindBenefits[0]?.status === 0
                                        ? true
                                        : false
                                    }
                                    value="0"
                                    disabled={isPastEvent}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {data.id === 11 && (
                          <Row className="d-flex align-items-center">
                            {selectedBenefitId === 11 && isShirt === "1" && (
                              <>
                                <Col className="col-md-4">
                                  <div className="form-floating input-bx form-row w-100 mt-md-3 form-control">
                                    <div className="col-12">
                                      <input
                                        type="number"
                                        onWheel={(event: any) =>
                                          event.currentTarget.blur()
                                        }
                                        value={isAmount}
                                        onChange={handleAmountChange}
                                      />
                                      <span>Amount *</span>
                                    </div>
                                  </div>
                                </Col>

                                <Col className="col-md-5">
                                  <Form.Label>Select Size</Form.Label>
                                  <MultiSelect
                                    options={options}
                                    onChange={handleMultiChange}
                                    value={optionSelected}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>

          <Row className="my-md-3 d-flex align-items-center justify-content-end">
            {!isViewEvent && (
              <Col className="d-flex justify-content-end">
                <DefaultButton
                  type="submit"
                  variant="outline-primary"
                  className="default-action-button"
                >
                  Submit
                </DefaultButton>
              </Col>
            )}
          </Row>
        </form>
      </div>
    </>
  );
};

export default BenefitsFrom;
