import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useApplyCharity = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: any) => {
    const { registrationId, charityId, amount } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/applyCharity",
        data: {
          registrationId: registrationId,
          charityId: charityId,
          amount: amount,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error while adding charity");
    }
  });
};
