import { Container, Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../../../pages/EventsPage.module.scss";
import Partners from "components/Partners";
import ConnectingBanner from "assets/images/connectingBanner.png";
import OurTeamCard from "components/Cards/OurTeamCard";
import { TeamList } from "api/TeamList";
import JoinUS from "./JoinUs";
import GetINTouchCard from "components/Cards/GetINTouchCard";
import ContactCard from "components/Cards/ContactCard";
import { ContactData } from "api/ContactData";
import UpdateNewsLetter from "./UpdateNewsLetter";

const AboutUS = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigate("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active> About Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.coverImage}`}>
          <Image
            src="/images/footerTop.png"
            width="100%"
            style={{ height: "437px" }}
            alt="FooterBanner"
          />
        </div>
        {/* <div className={`${styles.coverText} position-absolute  ms-5`}>
          <span
            className={`${styles.bannerTitle} fw-700 ff-saira text-primary text-deeped`}
          >
            Marathon
          </span>
          <br />
          <span
            className={`text-white ff-saira mt-3 ${styles.coverTextSubtitle}`}
          >
            Push yourself to the limit, because that's where the magic happens
          </span>
        </div> */}
      </Container>
      <Container>
        <Row className="my-2">
          <Col md={5} className="my-5 align-items-center d-flex">
            <Row>
              <div className="">
                <h1 className="text-lightBlack"> Empowering Success Stories</h1>
              </div>
              <div className="mt-2">
                <h6 className="text-deepBlack noto_font_lists pe-4">
                  At iFinish, our mission is to provide a platform for
                  organizers to create and publish online programs, empowering
                  individuals to achieve their goals.
                </h6>
              </div>
            </Row>
          </Col>
          <Col md={7}>
            <Image
              src="/images/emStories.png"
              width="100%"
              style={{ height: "380px" }}
              alt="FooterBanner"
            />
          </Col>
        </Row>
      </Container>
      <Container className="bg-white">
        <Partners />
      </Container>
      <Container>
        <Row className="my-2">
          <Col md={7}>
            <Image
              src="/images/emOraganization.png"
              width="100%"
              style={{ height: "380px" }}
              alt="FooterBanner"
            />
          </Col>
          <Col md={5} className="my-5 align-items-center d-flex">
            <Row>
              <div className="mt-2 pt-3 justify-content-center d-flex">
                <h1 className="text-lightBlack px-4">
                  Empowering organizers to create and publish online programs on
                  iFinish platform
                </h1>
              </div>

              <div className="justify-content-center d-flex mt-2">
                <h6 className="text-deepBlack noto_font_lists px-4">
                  Learn about the history, vision, and goals of iFinish and its
                  founding team.
                </h6>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="mt-5 pt-3 justify-content-start d-flex">
          <h1 className="text-lightBlack fs-40">
            Streamlining the Connection Between Athletes, Races, and Organizers
          </h1>
        </div>
        <Row>
          <Col md={12} className="my-5">
            <Image
              src="/images/connectingBanner.png"
              width="100%"
              alt="ConnectingBanner"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="my-5">
          <Col md={5} className="my-5 align-items-center d-flex">
            <Row>
              <div className="mt-2 pt-3 justify-content-center d-flex">
                <h1 className="text-lightBlack px-4">
                  Timing Technology for Accurate Race Results
                </h1>
              </div>

              <div className="mt-2">
                <h6 className="text-deepBlack noto_font_lists px-4">
                  Our advanced timing technology ensures accurate race results,
                  capturing the time it takes for runners to cross the start
                  line to the finish line.
                </h6>
              </div>
            </Row>
          </Col>
          <Col md={7}>
            <Image
              src="/images/timmingRace.png"
              width="100%"
              style={{ height: "380px" }}
              alt="FooterBanner"
            />
          </Col>
        </Row>
      </Container>
      <Container className="py-4">
        <Row className="my-5">
          <Col md={7}>
            <Image
              src="/images/emPower.png"
              width="100%"
              style={{ height: "380px" }}
              alt="FooterBanner"
            />
          </Col>
          <Col md={5} className="my-5 align-items-center d-flex">
            <Row>
              <div className="mt-2 pt-3 justify-content-center d-flex">
                <h1 className="text-lightBlack px-4">
                  Empowering Athletes to Achieve Their Marathon Goals
                </h1>
              </div>

              <div className="justify-content-center d-flex mt-2">
                <h6 className="text-deepBlack noto_font_lists px-4">
                  At iFinish, we believe that every athlete has the potential to
                  conquer the marathon. Our mission is to provide a platform
                  that not only organizes marathon events but also collects and
                  utilizes athletes' personal and previous marathon
                  participation information to help them reach their full
                  potential.
                </h6>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="my-4">
        <h1 className="text-lightBlack fs-40">Meet Our Team</h1>
        <h6 className="text-deepBlack noto_font_lists">
          We have exciting career opportunities available.
        </h6>
        <Row className="mt-5">
          {TeamList.map((teamData1) => (
            <Col key={teamData1.id} md={6} lg={4}>
              <OurTeamCard teamData={teamData1} />
            </Col>
          ))}
        </Row>
      </Container>
      <JoinUS />
      <Container>
        <Row
          style={{
            backgroundImage: `url(/images/redbackground.png)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            marginTop: "150px",
            height: "240px",
            marginBottom: "150px",
          }}
        >
          <Col md={6} className="justify-content-center d-flex">
            <Image
              src="/images/profileImage.png"
              style={{
                height: "380px",
                marginTop: "-50px",
              }}
              alt="FooterBanner"
            />
          </Col>
          <Col md={6} className="justify-content-flexstart d-flex">
            <Row style={{ marginTop: "50px" }}>
              <div className="justify-content-flexstart d-flex">
                <h1 className="text-white px-4">Praveen Pandi</h1>
              </div>

              <div
                className="justify-content-flexstart d-flex "
                style={{ marginTop: "-75px" }}
              >
                <h6 className="text-white px-4 fs-14 fw-400">
                  Marathon Organizer, ABC Events
                </h6>
              </div>

              <div
                className="justify-content-flexstart d-flex"
                style={{ marginTop: "-120px" }}
              >
                <h6 className="text-white fs-14  px-4">
                  The iFinish Team has truly transformed the marathon experience
                  for me. Their attention to detail and seamless organization
                  made every race a memorable one.
                </h6>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="text-lightBlack fs-40">Get in Touch</h1>
        <h6 className="text-deepBlack noto_font_lists">
          Interested in collaborating with iFinish? Fill out the form below.
        </h6>
        <Row className="mt-5">
          <GetINTouchCard
            handleSubmit={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Row>
      </Container>
      <Container className="my-5 pb-5">
        <Row className="mt-5">
          {ContactData.map((contact) => (
            <Col xs={12} md={4} key={contact.id}>
              <ContactCard contact={contact} />
            </Col>
          ))}
        </Row>
      </Container>
      <UpdateNewsLetter />
    </>
  );
};

export default AboutUS;
