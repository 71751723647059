import Badge from "components/Badge/Badge";
import { Icon } from "components/Icon";
import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import MarathonTable from "./MarathonTable";

type MarathonTableContainerProps = {
  className: string;
};

const MarathonTableContainer: React.FC<MarathonTableContainerProps> = ({
  className,
}) => {
  const marathonFIlters = [
    "2023",
    "Full Marathon",
    "Top Finisher's: 10",
    "Age: 19-24",
  ];

  return (
    <Container className={`${className}`}>
      <div className="pt-3 pb-4">
        <span className="blockTitle">Hyderabad Marathon - Top Finishers</span>
      </div>

      <div className={`d-flex justify-content-end `}>
        {marathonFIlters.map((marathonFilter, idx) => (
          <div key={idx} className="ps-2">
            <Badge
              onClick={() => {
                // alert("badge got click ");
              }}
              className="px-3 py-1 badge-inactive marathon-common fs-14  ff-ns"
            >
              {marathonFilter}
              <Icon iconName="ChevronDown" size={15} className="ms-2" />
            </Badge>
          </div>
        ))}
      </div>
      <div>
        <Tabs
          defaultActiveKey={"mens"}
          id="event-tabs"
          className="event-nav-tabs border-bottom pt-4"
        >
          <Tab eventKey="mens" title="Men's">
            <MarathonTable />
          </Tab>
          <Tab eventKey="womwns" title="Women's">
            <MarathonTable />
          </Tab>
          <Tab eventKey="kids" title="Kids">
            <MarathonTable />
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default MarathonTableContainer;
