import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Container, Tabs, Tab, Row, Col } from "react-bootstrap";
// import axios from "utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "context/AuthContext";
import Loader from "components/Loader/Loader";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";

const NgoRegistrationModal = ({
  showModal,
  handleClose,
  modalData,
  setReRender,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const UpdateCharityData =
    modalData &&
    modalData?.charity_amount.map((part: any) => {
      const { id, charity_bibs_id, ticket_id, ...rest } = part;
      return rest;
    });

  const formattedData =
    UpdateCharityData &&
    UpdateCharityData?.map((item: any) => ({
      ticket_name: item.ticket_name,
      amount: item.amount,
    }));

  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const { companyAccount } = useAccount();
  const isViewer = companyAccount === OrgRoleEnum.Viewer;

  const handleSubmit = (e: any) => {
    setIsLoading(true);
    let userStatus;
    if (e.target.innerText === "Approve") {
      userStatus = "A";
    } else if (e.target.innerText === "Reject") {
      userStatus = "R";
    }

    const postData = {
      charityBibId: modalData && modalData?.id,
      status: userStatus,
    };
    // axios.post("/ngoRegistrationStatusUpdate", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/ngoRegistrationStatusUpdate",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;

        if (status) {
          handleClose();
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        console.log("participants status error", err);
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let ParticipantStatus;
  if (modalData?.status ? modalData?.status === "A" : null) {
    ParticipantStatus = (
      <>
        <h4>Status:</h4>
        <h4 className="ms-2 text-success">Active</h4>
      </>
    );
  } else if (modalData?.status === "P") {
    ParticipantStatus = (
      <>
        <h4>Status:</h4>
        <h4 className="ms-2 text-warning">Pending</h4>
      </>
    );
  } else if (modalData?.status === "R") {
    ParticipantStatus = (
      <>
        <h4>Status:</h4>
        <h4 className="ms-2 text-error">Reject</h4>
      </>
    );
  } else if (modalData?.status === "I") {
    ParticipantStatus = (
      <>
        <h4>Status:</h4>
        <h4 className="ms-2 text-error">Inactive</h4>
      </>
    );
  }
  const handleImageClick = (image: any) => {
    setSelectedImage(image);
  };
  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="modal-xl"
      >
        <Modal.Header
          className="border-white px-md-4"
          closeButton
        ></Modal.Header>
        <Modal.Body className="mx-5 scrollbar">
          <Container>
            <Row>
              <Col md={6}>
                <h4 className="justify-content-start d-flex  text-primary">
                  NGO's Details :
                </h4>
              </Col>
              <Col md={4} className="d-flex justify-content-end">
                {ParticipantStatus}
              </Col>
            </Row>

            <Row className="d-flex justify-content-between">
              <Col className="col-md-7">
                <Row className="mt-3 d-flex justify-content-between">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0"> Name </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalData?.ngo_name}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0"> Email </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalData?.ngo_email}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Website Url </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            <a
                              href={`${modalData?.ngo_website}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {modalData?.ngo_website}
                            </a>
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Facebook Url </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            <a
                              href={`${modalData?.ngo_facebook}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {modalData?.ngo_facebook}
                            </a>
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Twitter Url </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            <a
                              href={`${modalData?.ngo_twitter}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {modalData?.ngo_twitter}
                            </a>
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Youtube Url </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {" "}
                            <a
                              href={`${modalData?.ngo_youtube}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {modalData?.ngo_youtube}
                            </a>
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Instagram Url </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {" "}
                            <a
                              href={`${modalData?.ngo_instagram}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {modalData?.ngo_instagram}
                            </a>
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Col>

              <Row>
                <Col md={6}>
                  <h4 className="justify-content-start d-flex mt-3 text-primary">
                    Account Details :
                  </h4>
                </Col>
                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Account Number </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_bank_account_no}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">IFSC Code </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_bank_ifsc}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Bank Address </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_bank_address}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <Row>
                <Col md={6}>
                  <h4 className="justify-content-start d-flex mt-3 text-primary">
                    Primary Contact Details :
                  </h4>
                </Col>
                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Name </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_contact_name}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0"> Mobile </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_contact_number}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Email </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.ngo_contact_email}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <Row>
                <Col md={6}>
                  <h4 className="justify-content-start d-flex mt-3 text-primary">
                    Charity Bib Details :
                  </h4>
                </Col>
                <Row className="mt-2">
                  <Col md={5} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Minimum amount </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalData?.min_amount
                              ? modalData?.min_amount
                              : "—"}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                {formattedData &&
                  formattedData?.map((data: any, index: any) => (
                    <div key={data.id}>
                      <Row className="mt-2">
                        {Object.keys(data).map((key, index) => (
                          <>
                            {key !== "upload_id_proof" && (
                              <Col md={5} className="mt-md-2" key={index}>
                                <Container className="p-0 m-0">
                                  <Row className="d-flex justify-content-start align-items-center mx-0">
                                    <Col className="col-md-5 px-0">
                                      <Row className="d-flex justify-content-start align-items-center">
                                        <Col md="auto">
                                          <h6 className="mb-0">
                                            {" "}
                                            {key
                                              .split("_")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          </h6>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col className="col-md-5 d-flex justify-content-start align-items-center">
                                      <span className="fw-bold me-md-3">:</span>
                                      <h6 className="ms-2 mb-0">
                                        {data[key] ? data[key] : "—"}
                                      </h6>
                                    </Col>
                                  </Row>
                                </Container>
                              </Col>
                            )}
                          </>
                        ))}
                      </Row>
                    </div>
                  ))}
              </Row>
            </Row>

            {/* images start */}
            <Row className="mt-md-3">
              <h4 className="justify-content-start d-flex  text-primary">
                Certificates:
              </h4>
              <div className="d-flex justify-content-between">
                <Row>
                  <Col>
                    <img
                      key={modalData?.id} // Using the image id as the key for the list
                      src={modalData && modalData?.fcra_certificate}
                      alt="fcra_certificate"
                      style={{ width: "300px", margin: "10px" }}
                      onClick={() =>
                        handleImageClick(modalData?.fcra_certificate)
                      } // Example styling
                    />
                  </Col>
                  <Col>
                    <img
                      key={modalData?.id} // Using the image id as the key for the list
                      src={modalData && modalData?.last_it_return_document}
                      alt="fcra_certificate"
                      style={{ width: "300px", margin: "10px" }}
                      onClick={() =>
                        handleImageClick(modalData?.last_it_return_document)
                      } // Example styling
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img
                      key={modalData?.id} // Using the image id as the key for the list
                      src={modalData && modalData?.cancel_cheque}
                      alt="fcra_certificate"
                      style={{ width: "300px", margin: "10px" }}
                      onClick={() => handleImageClick(modalData?.cancel_cheque)} // Example styling
                    />
                  </Col>
                  <Col>
                    <img
                      key={modalData?.id} // Using the image id as the key for the list
                      src={modalData && modalData?.certificate_12a}
                      alt="fcra_certificate"
                      style={{ width: "300px", margin: "10px" }}
                      onClick={() =>
                        handleImageClick(modalData?.certificate_12a)
                      } // Example styling
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img
                      key={modalData?.id} // Using the image id as the key for the list
                      src={modalData && modalData?.certificate_80g}
                      alt="fcra_certificate"
                      style={{ width: "300px", margin: "10px" }}
                      onClick={() =>
                        handleImageClick(modalData?.certificate_80g)
                      } // Example styling
                    />
                  </Col>
                </Row>
              </div>

              <div>
                {selectedImage && (
                  <div className="selected-image">
                    <h2>Selected Image</h2>
                    <div className="d-flex justify-content-center">
                      <img
                        src={selectedImage}
                        alt="image"
                        style={{ width: "500px", height: "350px" }} // Different width and height
                      />
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </Container>

          {modalData?.status === "A" ||
          modalData?.status === "R" ||
          modalData?.status === "I" ||
          isViewer ? null : (
            <Row>
              <Col className="justify-content-center d-flex my-5">
                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{ backgroundColor: "#A1C95B", color: "#ffffff" }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Approve
                </button>
                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{ backgroundColor: "#FF5050", color: "#ffffff" }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Reject
                </button>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NgoRegistrationModal;
