import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Outlet } from "react-router-dom";

const DashBoardLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default DashBoardLayout;
