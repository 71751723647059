import React, { useEffect, useState } from "react";
import CouponsTabScreen from "OrganizerPages/eventDetails/couponsTabScreen";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { decryptText } from "utils/utils";
import Loader from "components/Loader/Loader";
import DefaultButton from "components/DefaultButton/DefaultButton";
const CouponUserDashboard = () => {
  const Navigate = useNavigate();
  const [isEventName, setIsEventName] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();
  function EventData() {
    setIsLoading(true);
    const postData = { eventId: eventId };
    axiosPrivate({
      url: "/getEventStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          //   setEventStatus(res.data.data.status);
          setIsEventName(data.event_name);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    EventData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{isEventName}</h6>
          </Col>
          <Col>
            <div className="d-flex justify-content-end align-items-center">
              <DefaultButton
                variant="primary"
                type="submit"
                className="default-action-button text-white mx-5 px-2"
                onClick={() => Navigate(`/org/corporateresults/${id}`)}
              >
                Results
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <Row>
          <CouponsTabScreen />
        </Row>
      </Container>
    </>
  );
};

export default CouponUserDashboard;
