import React, { useEffect, useState } from "react";
import OffCanvas from "components/OffCanvas/OffCanvas";
import { Col, Image, Row } from "react-bootstrap";
import styles from "./Profile.module.scss";
import LoginForm from "components/Forms/LoginForm";
import SignupForm from "components/Forms/SignupForm";
import { useAuth } from "context/AuthContext";
import { Icon } from "components/Icon";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile/EditProfile";
import { useUserProfile } from "api/GetUserProfile";
import Loader from "components/Loader/Loader";
import { useQueryClient } from "react-query";

interface Props {
  isOpenProfileModal: boolean;
  handleCloseProfileModal: () => void;
}

const Profile: React.FC<Props> = ({
  isOpenProfileModal,
  handleCloseProfileModal,
}) => {
  const [isSignedUp, setIsSignedUp] = useState(false);
  const handleSignupClickOnLogin = () => setIsSignedUp(true);
  const { isAuthenticated, logout } = useAuth();
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  const { data: userData, isLoading: userDataLoading } = useUserProfile();

  const onNotficationClick = () => {
    console.log("onNotficationClick");
    handleCloseProfileModal();
  };
  const onMyEventClick = () => {
    navigate("/myevents");
    handleCloseProfileModal();
  };
  const onTrainingClick = () => {
    console.log("onTrainingClick");
    handleCloseProfileModal();
  };
  const onPaymentClick = () => {
    navigate("/mypayments");
    handleCloseProfileModal();
  };
  const onCharityClick = () => {
    console.log("onCharityClick");
    handleCloseProfileModal();
  };
  const onSettingClick = () => {
    console.log("onSettingClick");
    handleCloseProfileModal();
  };
  const onHelpClick = () => {
    console.log("onHelpClick");
    handleCloseProfileModal();
  };

  const profileMenu = [
    { title: "Notification", onclick: onNotficationClick },
    { title: "My Events", onclick: onMyEventClick },
    {
      title: "My Training",
      onclick: onTrainingClick,
    },
    { title: "Payments", onclick: onPaymentClick },
    { title: "Charity", onclick: onCharityClick },
    { title: "Account Setting", onclick: onSettingClick },
    { title: "Help & Support", onclick: onHelpClick },
  ];
  const queryClient = useQueryClient();
  const handleSignOut = () => {
    queryClient.removeQueries();
    localStorage.clear();
    logout();
    navigate("/");
  };

  const handleSignInClickOnSignup = () => {
    setIsSignedUp(false);
  };

  const handleCanvasClose = () => {
    handleCloseProfileModal();
    setEditProfile(false);
  };

  const onPreviousClick = () => {
    setEditProfile(false);
  };

  if (userDataLoading) {
    return <Loader />;
  }
  return (
    <div>
      <OffCanvas
        showOffCanvasModal={isOpenProfileModal}
        handleCloseOffCanvasModal={handleCanvasClose}
        isProfile
      >
        {isAuthenticated && !editProfile ? (
          <div className="d-flex flex-column h-100">
            <div className="position-relative" style={{ height: "100px" }}>
              <Image
                src="/images/profile-banner.png"
                alt="profile banner"
                className="w-100 "
                style={{ height: "100px" }}
              />
              <div className="position-absolute top-0">
                <div className="text-white mt-3 ms-3">
                  <span className="fs-32 ff-saira fw-700">
                    Hi {userData?.data?.first_name}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={() => setEditProfile(true)}
                  >
                    <span className="fs-12 fw-600 ff-saira pe-2">
                      Edit profile
                    </span>
                    <Icon iconName="ChevronRight" size={12} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ height: "66px", background: "#232537", opacity: 0.9 }}
            >
              <div className="text-white my-2 ms-3">
                <span className="fs-12 ff-ns fw-600">You have update</span>
                <div>
                  <span className="fs-16 fw-400 ff-ns pe-2">
                    Check your results
                  </span>
                  <Icon iconName="ChevronRight" size={14} />
                </div>
              </div>
            </div>
            {profileMenu.map((menu, idx) => (
              <div
                key={idx}
                className="border-bottom d-flex align-items-center"
                style={{ height: "50px", cursor: "pointer" }}
                onClick={menu.onclick}
              >
                <span className="fs-12 fw-600 ff-saira ms-4">{menu.title}</span>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-5">
              <DefaultButton
                variant="primary"
                type="button"
                className="default-action-button text-white mt-4 w-50"
                onClick={handleSignOut}
              >
                SIGN OUT
              </DefaultButton>
            </div>
          </div>
        ) : isAuthenticated && editProfile ? (
          <EditProfile
            onPreviousClick={onPreviousClick}
            handleCloseProfileModal={handleCloseProfileModal}
            setEditProfile={setEditProfile}
          />
        ) : (
          <div className="d-flex flex-column h-100">
            <header className="p-3 d-flex justify-content-between">
              <div>
                <Image src="/images/ifinish-logo.png" alt="ifinish logo" />
              </div>
            </header>
            <div className="flex-grow-1 mt-2 mt-sm-0">
              {isSignedUp ? (
                <SignupForm
                  handleSignInClickOnSignup={handleSignInClickOnSignup}
                  handleCloseProfileModal={handleCloseProfileModal}
                />
              ) : (
                <LoginForm
                  handleSignupClickOnLogin={handleSignupClickOnLogin}
                  handleCloseProfileModal={handleCloseProfileModal}
                />
              )}
            </div>
            <footer className="d-flex w-100 p-4">
              <Row className="w-100 d-flex align-items-center">
                <Col xs={12} md={6}>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <span className={`${styles.footerText}`}>
                      Powered by iFinish
                    </span>
                    <span className={`${styles.footerText}`}>
                      Developed by Timing Technology
                    </span>
                  </div>
                </Col>

                <Col xs={12} md={6}>
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
                    <span className={`${styles.footerText} mt-2 mt-md-0`}>
                      All rights reserved. &copy;{new Date().getFullYear()}
                    </span>
                    <div className="d-flex align-items-center mt-2 mt-md-0 ps-md-4">
                      <span className="noto_font_lists ps-2 me-md-0 ">
                        <img
                          src="/images/profile-telegram.png"
                          alt="telegram"
                        />
                      </span>
                      <span className="noto_font_lists ps-2 me-md-0">
                        <img
                          src="/images/profile-facebook.png"
                          alt="Facebook"
                        />
                      </span>
                      <span className="noto_font_lists ps-2 me-md-0">
                        <img
                          src="/images/profile-instagram.png"
                          alt="Instagram"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </footer>
          </div>
        )}
      </OffCanvas>
    </div>
  );
};

export default React.memo(Profile);
