import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRefund } from "context/SupportContext";
import { Formik, Form as Formdata } from "formik";
import { object, string } from "yup";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import RefundModel from "./RefundModel";
import Loader from "components/Loader/Loader";
import { Icon } from "components/Icon";
import { TextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
const RefundsPage = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { name, eventId } = useRefund();
  const [refundList, setRefundList] = useState<any>([]);
  const [uploadShow, setUploadShow] = useState(false);
  // const [prevSelectedCount, setPrevSelectedCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedRowAmount, setSelectedRowAmount] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleUploadClose = () => setUploadShow(false);
  const initialValue = {
    orderId: "",
  };
  const OrderSchema = object({
    orderId: string().required("Order Id is required"),
  });
  const handleSubmit = (values: any) => {
    setIsLoading(true);
    axiosPrivate({
      url: "/fetchOrderDetails",
      data: { orderNumber: values.orderId, eventId: eventId },
      method: "POST",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setRefundList(data);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClick = (data: any) => {
    // Ensure data is an array
    const selectedRows = Array.isArray(data) ? data : [data];
    if (selectedRows.length > 1) {
      toast.error("A refund can only be processed for a single participant.");
      setSelectedRows([]);
      setUploadShow(false);
      return;
    }

    const selectedData = selectedRows.map((item: any) => item.id);
    const selectedParticipantAmount = selectedRows.map(
      (item: any) => item.amount_paid
    );

    setSelectedRowAmount(selectedParticipantAmount);
    setSelectedRows(selectedData);

    if (selectedRows.length >= 0) {
      setUploadShow(true);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate("/support/dashboard")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{name}</h6>
          </Col>
        </Row>
        <div className="mt-md-3">
          <Formik
            initialValues={initialValue}
            validationSchema={OrderSchema}
            onSubmit={handleSubmit}
          >
            <Formdata noValidate>
              <Row className="d-flex justify-content-center align-items-center">
                <Col>
                  <TextField
                    name="orderId"
                    type="text"
                    label="Enter Order Number *"
                  />
                </Col>

                <Col>
                  <DefaultButton
                    type="submit"
                    variant="outline-primary"
                    className=" default-action-button"
                  >
                    Submit
                  </DefaultButton>
                </Col>
              </Row>
            </Formdata>
          </Formik>
        </div>
        <div>
          {refundList.length > 0 && (
            <>
              <div className="my-md-3">
                <Table bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Mobile No</th>
                      <th>Race Name</th>
                      <th>Amount Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refundList &&
                      refundList?.map((data: any) => {
                        return (
                          <tr key={data.uid}>
                            <td>
                              {data.refund_status === 1 ? (
                                <Icon
                                  iconName="CheckSquareFill"
                                  size={18}
                                  className="me-2 text-primary"
                                />
                              ) : (
                                <Icon
                                  iconName="Square"
                                  size={18}
                                  style={{ cursor: "pointer" }}
                                  className="me-2"
                                  onClick={() => {
                                    handleClick(data);
                                  }}
                                />
                              )}
                            </td>
                            <td>{data.first_name}</td>
                            <td>{data.last_name}</td>
                            <td>{data.email}</td>
                            <td>{data.mobile_number}</td>
                            <td>{data.race_name}</td>
                            <td>{data.amount_paid}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
      </Container>
      {uploadShow && (
        <RefundModel
          uploadShow={uploadShow}
          handleUploadClose={handleUploadClose}
          selectedRowAmount={selectedRowAmount}
          selectedRows={selectedRows}
          setRefundList={setRefundList}
        />
      )}
    </>
  );
};

export default RefundsPage;
