import { Container, Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classes from "../TermsandCondition/TermsAndCondition.module.scss";
import GetINTouchCard from "components/Cards/GetINTouchCard";
import ContactCard from "components/Cards/ContactCard";
import { ContactData } from "api/ContactData";

const ContactUS = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigate("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active> Contact Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="px-0 pb-4">
        <div className={`${classes.termsBanner}`}>
          <div className="position-relative">
            <Image
              className={`${classes.coverImage} position-relative`}
              src="/images/ContactUsBanner.svg"
              alt="ContactUsBanner"
            />
            <div className={`${classes.contactContentOverImage}`}>
              <div className="d-flex flex-column mx-4">
                <h1 className="blockTitle text-white">Contact iFinish Team</h1>
                <p className="text-white ff-saira fs-14">
                  Are you an organiser looking to create an event on IFinish?
                  Please get in touch with us.
                </p>
              </div>
            </div>
          </div>
          <div className={`${classes.discover} px-3 py-4`}>
            <h6 className="text-dark fw-bold fs-14 ff-saira">
              Discover our latest offers and updates
            </h6>
            <p className="fs-14 ff-ns text-dark mb-0">
              Stay informed with our timely information and promotions
            </p>
          </div>
        </div>
      </Container>

      <Container>
        <h1 className="text-lightBlack fs-40">Get in Touch</h1>
        <h6 className="text-deepBlack noto_font_lists">
          Interested in collaborating with iFinish? Fill out the form below.
        </h6>
        <Row className="mt-5 mx-0">
          <GetINTouchCard
            handleSubmit={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Row>
      </Container>
      <Container className="mt-5">
        <Row className="m-0 mt-5">
          {ContactData.map((contact) => (
            <Col xs={12} md={4} key={contact.id}>
              <ContactCard contact={contact} />
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="position-relative mt-2 mt-sm-0 mb-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.955355274598!2d78.3827455!3d17.448279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915ff57eae4f%3A0xc7942541e298ae27!2sTiming%20Technologies%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1705485472551!5m2!1sen!2sin"
          width="100%"
          height="380"
          style={{ border: 0 }}
          title="Timing Technologies"
          loading="lazy"
        ></iframe>
      </Container>
    </>
  );
};

export default ContactUS;
