import { useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import styles from "./EditProfile.module.scss";
import Checkbox from "components/Inputs/Checkbox";
import RadioInput from "components/Inputs/RadioInput";
import {
  readFileAsBase64,
  nameConversion,
  alphabetsSpaceRegex,
  alphaNumeric,
} from "utils/utils";
import { useUserProfile } from "api/GetUserProfile";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import { useEditUserProfile } from "api/EditUserProfile";
import { Icon } from "components/Icon";
import { states } from "utils/address/states";
import { countries } from "utils/address/countries";
import { cities } from "utils/address/cities";
import moment from "moment";
import { useQueryClient } from "react-query";

type EditProfileProps = {
  onPreviousClick: () => void;
  handleCloseProfileModal: () => void;
  setEditProfile: (editProfile: boolean) => void;
};
const EditProfile: React.FC<EditProfileProps> = ({
  onPreviousClick,
  handleCloseProfileModal,
  setEditProfile,
}) => {
  const [filteredStates, setFilteredStates] = useState<any>([]);
  const [filteredCities, setFilteredCities] = useState<any>([]);
  const queryClient = useQueryClient();

  function filterStates(selectedCountry: any) {
    const newStates = states.filter(
      (state) => state.country_name === selectedCountry
    );
    setFilteredStates(newStates);
  }

  function filterCities(selectedState: any) {
    const newCities = cities.filter(
      (city) => city.state_name === selectedState
    );
    setFilteredCities(newCities);
  }

  const editUserProfileMutation = useEditUserProfile();

  const { data: userData, isLoading: userDataLoading } = useUserProfile();

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .max(50, "Max 50 characters allowed"),
    last_name: Yup.string()
      .required("Last Name is required")
      .max(50, "Max 50 characters allowed"),
    gender: Yup.string().required("Gender is required"),
    date_of_birth: Yup.date().required("Date Of Birth is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    mobile_number: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{10}$/, "Phone No must contain exactly 10 digits"),
    profile_image: Yup.string(),
    country_code: Yup.string().max(3, "Max 3 characters allowed"),
    street_address: Yup.string().max(70, "Max 70 character allowed"),
    area: Yup.string().max(50, "Max 50 character allowed"),
    city: Yup.string().max(50, "Max 50 character allowed"),
    country: Yup.string().max(50, "Max 50 character allowed"),
    state: Yup.string().max(50, "Max 50 character allowed"),
    landmark: Yup.string().max(50, "Max 50 character allowed"),
    nationality: Yup.string().max(50, "Max 50 character allowed"),
    pincode: Yup.string().matches(
      /^\d{6}$/,
      "Pincode must contain exactly 6 digits"
    ),
    blood_group: Yup.string().max(8, "Max 8 character allowed"),
    "height(centimeter)": Yup.string().max(6, "Max 6 character allowed"),
    "weight(kg)": Yup.string().max(6, "Max 6 character allowed"),
    history_of_health_issues: Yup.string(),
    "are_you_physically_challenged?": Yup.string(),
    shoe_size: Yup.string().max(2, "Max 2 character allowed"),
    "t-shirt_size(cm)": Yup.string(),
    alternate_email: Yup.string().email("Invalid email address"),
    emergency_contact_name: Yup.string().max(50, "Max 50 character allowed"),
    emergency_contact_number: Yup.string().matches(
      /^\d{10}$/,
      "Emergency contact no must contain exactly 10 digits"
    ),
    emergency_contact_relationship: Yup.string().max(
      50,
      "Max 50 character allowed"
    ),
    identity_proof_id: Yup.string(),
    upload_id_proof: Yup.string(),
    id_proof_number: Yup.string().max(20, "Max 20 character allowed"),
    subscribed_for_ifinish_update: Yup.boolean(),
    subscribed_for_event_updates: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      email: "",
      country_code: "+91",
      mobile_number: "",
      profile_image: "",
      street_address: "",
      area: "",
      city: "",
      country: "",
      state: "",
      landmark: "",
      nationality: "",
      pincode: "",
      blood_group: "",
      "height(centimeter)": "",
      "weight(kg)": "",
      "t-shirt_size(cm)": "",
      shoe_size: "",
      "are_you_physically_challenged?": "No",
      history_of_health_issues: "",
      alternate_email: "",
      emergency_contact_name: "",
      emergency_contact_number: "",
      emergency_contact_relationship: "",
      identity_proof_id: "",
      upload_id_proof: "",
      id_proof_number: "",
      subscribed_for_ifinish_update: false,
      subscribed_for_event_updates: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await editUserProfileMutation.mutateAsync({
          first_name: nameConversion(values.first_name),
          last_name: nameConversion(values.last_name),
          email: values.email,
          country_code: values.country_code,
          mobile_number: values.mobile_number,
          gender: values.gender.toLowerCase(),
          date_of_birth: values.date_of_birth,
          profile_image: values.profile_image,
          street_address: values.street_address,
          area: values.area,
          city: values.city,
          country: values.country,
          state: values.state,
          landmark: values.landmark,
          nationality: values.nationality,
          pincode: values.pincode,
          blood_group: values.blood_group,
          "height(centimeter)": values["height(centimeter)"],
          "weight(kg)": values["weight(kg)"],
          "t-shirt_size(cm)": values["t-shirt_size(cm)"],
          shoe_size: values.shoe_size,
          "are_you_physically_challenged?":
            values["are_you_physically_challenged?"] === "Yes" ? 1 : 0,
          history_of_health_issues: values["history_of_health_issues"],
          alternate_email: values.alternate_email,
          emergency_contact_name: values.emergency_contact_name,
          emergency_contact_number: values.emergency_contact_number,
          emergency_contact_relationship: values.emergency_contact_relationship,
          identity_proof_id: values.identity_proof_id,
          upload_id_proof: values.upload_id_proof,
          id_proof_number: values.id_proof_number,
          subscribed_for_ifinish_update: values.subscribed_for_ifinish_update
            ? 1
            : 0,
          subscribed_for_event_updates: values.subscribed_for_event_updates
            ? 1
            : 0,
        });
        if (response.status) {
          toast.success(`${response.message}`);
          queryClient.invalidateQueries("useUserProfile");
          handleCloseProfileModal();
          setEditProfile(false);
        } else {
          toast.error("Error updating user profile !!");
        }
      } catch (error) {
        // toast.error(`${error}`);
      }
    },
  });

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      const fieldErrorNames = Object.keys(formik.errors);

      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (!!formik.values.country) {
      filterStates(formik.values.country);
    }
    if (!!formik.values.state) {
      filterCities(formik.values.state);
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (userData && userData?.data) {
      formik.setValues(userData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.data]);

  const genderOptions = ["Male", "Female", "Other"];
  const bloodGoups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const tShitSizes = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];
  const healthIssues = [
    "None",
    "Heart disease",
    "Hypertension",
    "Diabetes",
    "Seizures",
    "Others",
  ];
  const idProofs = ["PAN", "Driving License", "Passport", "Voter ID"];

  if (userDataLoading) {
    return <Loader />;
  }

  return (
    <div className="p-4">
      <div className="ff-ns fs-24 fw-600">
        <span onClick={() => onPreviousClick()} className="cursor-pointer">
          <Icon iconName="ChevronLeft" size={25} />
        </span>{" "}
        Edit Profile
      </div>
      <Form onSubmit={formik.handleSubmit} className="mt-4">
        <Accordion className={`${styles.customAccordian}`} defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            bsPrefix={`${styles.accordianItem}`}
            className="px-2 mb-2 border"
          >
            <Accordion.Header
              className="px-0 py-3 "
              bsPrefix={`${styles.accordianHeader}`}
            >
              <div className="ps-3 ff-saira fs-20 fw-600">Basic Profile</div>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-0 pb-3">
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="First Name *"
                    name="first_name"
                    type="text"
                    maxLength={50}
                    placeholder="Enter your first name"
                    value={formik.values.first_name}
                    onChange={(event) => {
                      const input = event.target.value;
                      if (alphabetsSpaceRegex.test(input)) {
                        formik.setFieldValue("first_name", input);
                      } else if (input.length === 0) {
                        formik.setFieldValue("first_name", input);
                      }
                    }}
                    isError={
                      formik.touched.first_name && !!formik.errors.first_name
                    }
                    error={formik.errors.first_name}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="Last Name *"
                    name="last_name"
                    type="text"
                    maxLength={50}
                    placeholder="Enter your last name"
                    value={formik.values.last_name}
                    onChange={(event) => {
                      const input = event.target.value;
                      if (alphabetsSpaceRegex.test(input)) {
                        formik.setFieldValue("last_name", input);
                      } else if (input.length === 0) {
                        formik.setFieldValue("last_name", input);
                      }
                    }}
                    isError={
                      formik.touched.last_name && !!formik.errors.last_name
                    }
                    error={formik.errors.last_name}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 pe-3">
                  <DefaultDropdown
                    className="mt-4"
                    label="Gender *"
                    name="gender"
                    options={genderOptions}
                    placeholder="Gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange("gender")}
                    isError={formik.touched.gender && !!formik.errors.gender}
                    error={formik.errors.gender}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4 "
                    label="Date of Birth *"
                    name="date_of_birth"
                    type="date"
                    maxDate={moment().format("YYYY-MM-DD")}
                    placeholder="Date of Birth"
                    value={formik.values.date_of_birth}
                    onChange={formik.handleChange("date_of_birth")}
                    onBlur={(event) => {
                      const selectedDate = event.target.value;
                      const currentDate = moment().format("YYYY-MM-DD");

                      if (
                        selectedDate &&
                        moment(selectedDate).isAfter(currentDate, "day")
                      ) {
                        formik.setFieldValue("date_of_birth", "");
                      }
                    }}
                    isError={
                      formik.touched.date_of_birth &&
                      !!formik.errors.date_of_birth
                    }
                    error={formik.errors.date_of_birth}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 pe-3">
                  <DefaultInput
                    className="mt-4"
                    label="Email *"
                    name="email"
                    type="email"
                    maxLength={50}
                    placeholder="Enter your email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    isError={formik.touched.email && !!formik.errors.email}
                    error={formik.errors.email}
                    disabled={userData?.data?.email}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <div className="row flex-row mt-4">
                    <div className="col-4">
                      <DefaultInput
                        label="Code"
                        name="country_code"
                        type="text"
                        maxLength={3}
                        placeholder="+91"
                        value={formik.values.country_code}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regex = /^\+?\d*$/;
                          if (
                            regex.test(inputValue) &&
                            inputValue.length <= 3
                          ) {
                            formik.handleChange("country_code")(e);
                          } else if (inputValue.length === 0) {
                            formik.handleChange("country_code")(e);
                          }
                        }}
                        isError={
                          formik.touched.country_code &&
                          !!formik.errors.country_code
                        }
                        error={formik.errors.country_code}
                      />
                    </div>
                    <div className="col">
                      <DefaultInput
                        label="Mobile Number *"
                        name="mobile_number"
                        type="text"
                        placeholder="Mobile Number"
                        value={formik.values.mobile_number}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regex = /^\d+$/;
                          if (
                            regex.test(inputValue) &&
                            inputValue.length <= 10
                          ) {
                            formik.handleChange("mobile_number")(e);
                          } else if (inputValue.length === 0) {
                            formik.handleChange("mobile_number")(e);
                          }
                        }}
                        isError={
                          formik.touched.mobile_number &&
                          !!formik.errors.mobile_number
                        }
                        error={formik.errors.mobile_number}
                        disabled={userData?.data?.mobile_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  formik.values.profile_image
                    ? "d-flex flex-column flex-md-row justify-content-md-between"
                    : ""
                } `}
              >
                <div className="d-flex flex-column w-100">
                  <DefaultInput
                    className="mt-4 "
                    label="Photograph"
                    name="profile_image"
                    type="file"
                    placeholder="Photograph"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formik.setFieldValue("profile_image", base64);
                      } else {
                        formik.setFieldValue(
                          "profile_image",
                          formik.values.profile_image
                        );
                      }
                    }}
                  />
                </div>
                <div className="d-flex flex-column px-3 mt-4">
                  {formik.values.profile_image && (
                    <img
                      className="bordered"
                      src={formik.values.profile_image}
                      alt="Preview"
                      style={{
                        border: "1px solid #000000",
                        borderRadius: "50%",
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
              {formik.status && formik.status.submitError && (
                <div className="alert alert-danger" role="alert">
                  {formik.status.submitError}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={`${styles.customAccordian}`} defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            bsPrefix={`${styles.accordianItem}`}
            className="px-2 mb-2 border"
          >
            <Accordion.Header
              className="px-0 py-3 "
              bsPrefix={`${styles.accordianHeader}`}
            >
              <div className="ff-saira fs-20 fw-600 ps-3">Address</div>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-0 pb-3">
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultDropdown
                    className="mt-4"
                    label="Country"
                    name="country"
                    options={countries?.map((country: any) => country?.name)}
                    placeholder="Country"
                    value={formik.values.country}
                    onChange={(event) => {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("city", "");
                      formik.handleChange("country")(event.target.value);
                      filterStates(event.target.value);
                    }}
                    isError={formik.touched.country && !!formik.errors.country}
                    error={formik.errors.country}
                  />
                </div>
                <div className="d-flex flex-column w-100">
                  {filteredStates.length > 0 ? (
                    <DefaultDropdown
                      className="mt-4"
                      label="State"
                      name="state"
                      options={filteredStates?.map((state: any) => state?.name)}
                      placeholder="State"
                      value={formik.values.state}
                      onChange={(event) => {
                        formik.handleChange("state")(event.target.value);
                        filterCities(event.target.value);
                      }}
                      isError={formik.touched.state && !!formik.errors.state}
                      error={formik.errors.state}
                    />
                  ) : (
                    <DefaultInput
                      className="mt-4"
                      label="State"
                      name="state"
                      type="text"
                      maxLength={50}
                      placeholder="Enter your state"
                      value={formik.values.state}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[A-Za-z]*$/;
                        if (regex.test(inputValue) || inputValue === "") {
                          formik.handleChange("state")(e);
                        }
                      }}
                      isError={formik.touched.state && !!formik.errors.state}
                      error={formik.errors.state}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  {filteredCities.length > 0 && filteredStates.length > 0 ? (
                    <DefaultDropdown
                      className="mt-4"
                      label="City"
                      name="city"
                      options={filteredCities?.map((city: any) => city?.name)}
                      placeholder="City"
                      value={formik.values.city}
                      onChange={(event) => {
                        formik.handleChange("city")(event.target.value);
                      }}
                      isError={formik.touched.city && !!formik.errors.city}
                      error={formik.errors.city}
                    />
                  ) : (
                    <DefaultInput
                      className="mt-4"
                      label="City"
                      name="city"
                      type="text"
                      maxLength={50}
                      placeholder="Enter your city"
                      value={formik.values.city}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[A-Za-z]*$/;
                        if (regex.test(inputValue) || inputValue === "") {
                          formik.handleChange("city")(e);
                        }
                      }}
                      isError={formik.touched.city && !!formik.errors.city}
                      error={formik.errors.city}
                    />
                  )}
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="Street Address"
                    name="street_address"
                    type="text"
                    maxLength={100}
                    placeholder="Enter your street address"
                    value={formik.values.street_address}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^[a-zA-Z0-9\s/:,\-]*$/;
                      if (regex.test(inputValue) || inputValue === "") {
                        formik.handleChange("street_address")(e);
                      }
                    }}
                    isError={
                      formik.touched.street_address &&
                      !!formik.errors.street_address
                    }
                    error={formik.errors.street_address}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Area"
                    name="area"
                    type="text"
                    maxLength={50}
                    placeholder="Enter your area"
                    value={formik.values.area}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^[a-zA-Z\s]*$/;
                      if (regex.test(inputValue) || inputValue === "") {
                        formik.handleChange("area")(e);
                      }
                    }}
                    isError={formik.touched.area && !!formik.errors.area}
                    error={formik.errors.area}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="Landmark"
                    name="landmark"
                    type="text"
                    maxLength={50}
                    placeholder="Enter your landmark"
                    value={formik.values.landmark}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^[a-zA-Z\s]*$/;
                      if (regex.test(inputValue) || inputValue === "") {
                        formik.handleChange("landmark")(e);
                      }
                    }}
                    isError={
                      formik.touched.landmark && !!formik.errors.landmark
                    }
                    error={formik.errors.landmark}
                  />
                </div>
              </div>

              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Pincode"
                    name="pincode"
                    type="text"
                    placeholder="Enter your pincode"
                    value={formik.values.pincode}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^\d+$/;
                      if (regex.test(inputValue) && inputValue.length <= 6) {
                        formik.handleChange("pincode")(e);
                      } else if (inputValue.length === 0) {
                        formik.handleChange("pincode")(e);
                      }
                    }}
                    isError={formik.touched.pincode && !!formik.errors.pincode}
                    error={formik.errors.pincode}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="Nationalty"
                    name="nationality"
                    type="text"
                    maxLength={50}
                    placeholder="Enter your nationality"
                    value={formik.values.nationality}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^[a-zA-Z]*$/;
                      if (regex.test(inputValue) || inputValue === "") {
                        formik.handleChange("nationality")(e);
                      }
                    }}
                    isError={
                      formik.touched.nationality && !!formik.errors.nationality
                    }
                    error={formik.errors.nationality}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={`${styles.customAccordian}`} defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            bsPrefix={`${styles.accordianItem}`}
            className="px-2 mb-2 border"
          >
            <Accordion.Header
              className="px-0 py-3 "
              bsPrefix={`${styles.accordianHeader}`}
            >
              <div className="ff-saira fs-20 fw-600 ps-3">Physical Details</div>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-0 pb-3">
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultDropdown
                    className="mt-4"
                    label="Blood Group"
                    name="blood_group"
                    options={bloodGoups}
                    placeholder="Blood Group"
                    value={formik.values.blood_group}
                    onChange={formik.handleChange("blood_group")}
                    isError={
                      formik.touched.blood_group && !!formik.errors.blood_group
                    }
                    error={formik.errors.blood_group}
                  />
                </div>
                <div className="d-flex flex-column w-100">
                  <DefaultInput
                    className="mt-4"
                    label="Height (Centimeter)"
                    name="height(centimeter)"
                    type="text"
                    placeholder="Enter your height (centimeter)"
                    value={formik.values["height(centimeter)"]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^\d+$/;
                      if (regex.test(inputValue) && inputValue.length <= 3) {
                        formik.handleChange("height(centimeter)")(e);
                      } else if (inputValue.length === 0) {
                        formik.handleChange("height(centimeter)")(e);
                      }
                    }}
                    isError={
                      formik.touched["height(centimeter)"] &&
                      !!formik.errors["height(centimeter)"]
                    }
                    error={formik.errors["height(centimeter)"]}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Weight (kg)"
                    name="weight(kg)"
                    type="text"
                    placeholder="Enter your weight (kg)"
                    value={formik.values["weight(kg)"]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^\d+$/;
                      if (regex.test(inputValue) && inputValue.length <= 3) {
                        formik.handleChange("weight(kg)")(e);
                      } else if (inputValue.length === 0) {
                        formik.handleChange("weight(kg)")(e);
                      }
                    }}
                    isError={
                      formik.touched["weight(kg)"] &&
                      !!formik.errors["weight(kg)"]
                    }
                    error={formik.errors["weight(kg)"]}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultDropdown
                    className="mt-4"
                    label="Select T-Shirt Size"
                    name="t-shirt_size(cm)"
                    options={tShitSizes}
                    placeholder="Select T-Shirt Size"
                    value={formik.values["t-shirt_size(cm)"]}
                    onChange={formik.handleChange("t-shirt_size(cm)")}
                    isError={
                      formik.touched["t-shirt_size(cm)"] &&
                      !!formik.errors["t-shirt_size(cm)"]
                    }
                    error={formik.errors["t-shirt_size(cm)"]}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Shoe Size"
                    name="shoe_size"
                    type="text"
                    placeholder="Shoe Size"
                    value={formik.values.shoe_size}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^\d+$/;
                      if (regex.test(inputValue) && inputValue.length <= 2) {
                        formik.handleChange("shoe_size")(e);
                      } else if (inputValue.length == 0) {
                        formik.handleChange("shoe_size")(e);
                      }
                    }}
                    isError={
                      formik.touched.shoe_size && !!formik.errors.shoe_size
                    }
                    error={formik.errors.shoe_size}
                  />
                </div>
                <div className="d-flex flex-column w-100 fs-16 mt-4">
                  <DefaultDropdown
                    label="History of Health issues"
                    name="history_of_health_issues"
                    options={healthIssues}
                    placeholder="History of Health issues"
                    disablePlaceholder={true}
                    value={formik.values["history_of_health_issues"]}
                    onChange={formik.handleChange("history_of_health_issues")}
                    isError={
                      formik.touched["history_of_health_issues"] &&
                      !!formik.errors["history_of_health_issues"]
                    }
                    error={formik.errors["history_of_health_issues"]}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={`${styles.customAccordian}`} defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            bsPrefix={`${styles.accordianItem}`}
            className="px-2 mb-2 border"
          >
            <Accordion.Header
              className="px-0 py-3 "
              bsPrefix={`${styles.accordianHeader}`}
            >
              <div className="ff-saira fs-20 fw-600 ps-3">
                Additional Information
              </div>
            </Accordion.Header>
            <Accordion.Body className="px-0 py-0">
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Alternate Email"
                    name="alternate_email"
                    type="email"
                    maxLength={50}
                    placeholder="Enter your alternate email"
                    value={formik.values.alternate_email}
                    onChange={formik.handleChange("alternate_email")}
                    isError={
                      formik.touched.alternate_email &&
                      !!formik.errors.alternate_email
                    }
                    error={formik.errors.alternate_email}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <div className="d-flex flex-column w-100 me-3">
                    <DefaultInput
                      className="mt-4"
                      label="Emergency Contact Name"
                      name="emergency_contact_name"
                      type="text"
                      maxLength={50}
                      placeholder="Enter your emergency contact name"
                      value={formik.values.emergency_contact_name}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[A-Za-z\s]*$/;
                        if (regex.test(inputValue) || inputValue === "") {
                          formik.handleChange("emergency_contact_name")(e);
                        }
                      }}
                      isError={
                        formik.touched.emergency_contact_name &&
                        !!formik.errors.emergency_contact_name
                      }
                      error={formik.errors.emergency_contact_name}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3">
                  <DefaultInput
                    className="mt-4"
                    label="Emergency Contact Number"
                    name="emergency_contact_number"
                    type="text"
                    placeholder="Emergency Contact Number"
                    value={formik.values.emergency_contact_number}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^\d+$/;
                      if (regex.test(inputValue) && inputValue.length <= 10) {
                        formik.handleChange("emergency_contact_number")(e);
                      } else if (inputValue.length === 0) {
                        formik.handleChange("emergency_contact_number")(e);
                      }
                    }}
                    isError={
                      formik.touched.emergency_contact_number &&
                      !!formik.errors.emergency_contact_number
                    }
                    error={formik.errors.emergency_contact_number}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="Emergency Contact Relationship"
                    name="emergency_contact_relationship"
                    type="text"
                    maxLength={50}
                    placeholder="Emergency Contact Relationship"
                    value={formik.values.emergency_contact_relationship}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const regex = /^[A-Za-z\s]*$/;
                      if (regex.test(inputValue) || inputValue === "") {
                        formik.handleChange("emergency_contact_relationship")(
                          e
                        );
                      }
                    }}
                    isError={
                      formik.touched.emergency_contact_relationship &&
                      !!formik.errors.emergency_contact_relationship
                    }
                    error={formik.errors.emergency_contact_relationship}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="d-flex flex-column w-100 me-3 ">
                  <DefaultDropdown
                    className="mt-4"
                    label="ID Type"
                    name="identity_proof_id"
                    options={idProofs}
                    disablePlaceholder={true}
                    placeholder="Select your ID Type"
                    value={formik.values.identity_proof_id}
                    onChange={(event) => {
                      formik.handleChange("identity_proof_id")(
                        event.target.value
                      );
                    }}
                    isError={
                      formik.touched.identity_proof_id &&
                      !!formik.errors.identity_proof_id
                    }
                    error={formik.errors.identity_proof_id}
                  />
                </div>
                <div className="d-flex flex-column w-100 ">
                  <DefaultInput
                    className="mt-4"
                    label="ID Number"
                    name="id_proof_number"
                    type="text"
                    maxLength={20}
                    placeholder="ID Number"
                    value={formik.values.id_proof_number}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (alphaNumeric.test(inputValue)) {
                        formik.handleChange("id_proof_number")(e);
                      }
                    }}
                    isError={
                      formik.touched.id_proof_number &&
                      !!formik.errors.id_proof_number
                    }
                    error={formik.errors.id_proof_number}
                  />
                </div>
              </div>
              <div
                className={`${
                  formik.values.upload_id_proof
                    ? "d-flex flex-column flex-md-row justify-content-md-between"
                    : ""
                }`}
              >
                <div className="d-flex flex-column w-100">
                  <DefaultInput
                    className="mt-4 "
                    label="ID Proof"
                    name="upload_id_proof"
                    type="file"
                    placeholder="PID Proof"
                    accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;
                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formik.setFieldValue("upload_id_proof", base64);
                      } else {
                        formik.setFieldValue(
                          "id_proof",
                          formik.values.upload_id_proof
                        );
                      }
                    }}
                  />
                </div>
                <div className="d-flex flex-column px-3 mt-4">
                  {formik.values.upload_id_proof && (
                    <div>
                      {formik.values.upload_id_proof.endsWith(".jpg") ||
                      formik.values.upload_id_proof.endsWith(".jpeg") ||
                      formik.values.upload_id_proof.endsWith(".png") ||
                      formik.values.upload_id_proof.startsWith("data:image") ? (
                        <img
                          className="bordered"
                          src={formik.values.upload_id_proof}
                          alt="Preview"
                          style={{
                            border: "1px solid #000000",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <iframe
                          src={formik.values.upload_id_proof}
                          title="PDF Preview"
                          style={{
                            width: "60px",
                            height: "60px",
                            border: "1px solid #000000",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {formik.status && formik.status.submitError && (
          <div className="alert alert-danger" role="alert">
            {formik.status.submitError}
          </div>
        )}
        <DefaultButton
          variant="primary"
          type="submit"
          className="default-action-button text-white w-100 mt-5"
        >
          Update Profile
        </DefaultButton>
      </Form>
    </div>
  );
};

export default EditProfile;
