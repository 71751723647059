import { useMutation } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

export const useSignUp = () => {
  const axiosPublic = useAxiosPublic();
  return useMutation(async (params: any) => {
    const {
      firstName,
      lastName,
      gender,
      age,
      country_code,
      phone,
      email,
      password,
      confirmPassword,
      profile_image,
    } = params;

    try {
      const response = await axiosPublic({
        method: "POST",
        url: "/signUp",
        data: {
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          age: age,
          country_code: country_code,
          phone: phone,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          profile_image: profile_image,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error Signing Up, Please Try Again.");
      throw error;
    }
  });
};
