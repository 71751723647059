import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Modal, Row, Col } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PreviewEvent from "./previewEvent/PreviewEvent";
import HomeTabScreen from "./eventDetails/homeTabScreen";
import RegistrationTabScreen from "./eventDetails/registrationTabScreen";
import MessageTabScreen from "./eventDetails/messageTabScreen";
import EmailTabScreen from "./eventDetails/emailTabScreen";
import OfflineEmailTabScreen from "./eventDetails/offlineEmailTabScreen";
import OfflineRegistrationTabScreen from "./eventDetails/offlineRegistrationTabScreen";
import CouponsTabScreen from "./eventDetails/couponsTabScreen";
import { useAuth } from "context/AuthContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import BibsTabScreen from "./eventDetails/BibsTabScreen";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import { OrgDashboardType } from "types/OrgDashboardEnum";
import RefundTabScreen from "./eventDetails/refundTabScreen";
import NGOMainTabScreen from "./eventDetails/NGOMainTabScreen";
import RoleTabScreen from "./eventDetails/roleTabScreen";
import SponsorsTabComponent from "./eventDetails/SponsorsTabComponent";
import { useEvent } from "context/EventContext";
import useEventName from "utils/useEventName";
import useOrganizer from "utils/useOrganizer";
import { toast, ToastContainer } from "react-toastify";
import GalleryTabComponent from "./eventDetails/GalleryTabComponent";
import ParticipantBreakUp from "./eventDetails/ParticipantBreakUp";
import { decryptText, encryptText } from "utils/utils";
import { useAccount } from "context/AccountContext";
import { OrgRoleEnum } from "types/orgRoleEnum";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const OrgHomeScreen = () => {
  const { id } = useParams();
  const eventName = useEventName();
  const Navigate = useNavigate();
  const { isSuperAdmin } = useOrganizer();
  const { companyAccount } = useAccount();
  const account = companyAccount === OrgRoleEnum.Admin;
  const axiosPrivate = useAxiosPrivate();
  const eventId = decryptText(String(id));
  const { setEventId, setRaceId } = useEvent();
  const location = useLocation();

  const [eventStatus, setEventStatus] = useState<any>();
  const [isPastEvent, setIsPastEvent] = React.useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | null>("home");
  const [isLoading, setIsLoading] = useState(false);

  function getEventStatus() {
    setIsLoading(true);
    const postData = { eventId: eventId };
    axiosPrivate({
      url: "/getEventStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          setEventStatus(res.data.data.status);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getEventStatus();
  }, [eventId]);

  React.useLayoutEffect(
    function () {
      const pathName = location.pathname.split("/");
      if (pathName.includes("pastEventDashBoard")) {
        setIsPastEvent(true);
        setEventId(null);
        setRaceId(null);
      }
      if (pathName.includes("eventDashBoard")) {
        setEventId(null);
        setRaceId(null);
      }
    },
    [window.location.pathname]
  );

  const handleEventPublic = () => {
    setIsLoading(true);
    const postData = {
      eventId: eventId,
      status: eventStatus === "I" ? "A" : "D",
    };

    axiosPrivate({
      url: "/eventPublish",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          setEventStatus(data);
          setIsConfirmModal(false);
          setIsLoading(true);
          toast.success(`${message}`, { autoClose: 1000 });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  const handleClose = () => setIsConfirmModal(false);
  const handleClosePreviewModal = () => setIsOpenPreviewModal(false);

  const ConfirmModal = () => {
    return (
      <Modal show={isConfirmModal} onHide={handleClose} centered>
        <Modal.Body className="py-md-5 text-center">
          <h4>
            Are you sure your want to{" "}
            {eventStatus === "A"
              ? "close the registrations"
              : "publish the event"}{" "}
            ?
          </h4>
          <div className="d-flex align-items-center justify-content-center mt-md-3">
            <DefaultButton
              variant="primary"
              className="default-action-button me-md-3 px-md-4"
              type="button"
              onClick={handleEventPublic}
            >
              Yes
            </DefaultButton>
            <DefaultButton
              variant="outline-primary"
              className="default-action-button px-md-4"
              type="button"
              onClick={() => setIsConfirmModal(false)}
            >
              No
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <ConfirmModal />
      {isOpenPreviewModal && (
        <PreviewEvent
          isOpenPreviewModal={isOpenPreviewModal}
          handleClosePreviewModal={handleClosePreviewModal}
          eventId={eventId}
        />
      )}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
          </Col>
          <Col>
            <div className="d-flex justify-content-end ">
              <>
                {(eventStatus === "I" || eventStatus === "A") && !account && (
                  <DefaultButton
                    className={`default-action-button mx-3 border-${
                      eventStatus === "A" ? "lightGray" : "primary"
                    } text-white bg-${
                      eventStatus === "A" ? "lightGray" : "primary"
                    } `}
                    variant="primary"
                    type="button"
                    onClick={() => setIsConfirmModal(true)}
                  >
                    {eventStatus === "A"
                      ? "Close Registrations"
                      : "Publish Event"}
                  </DefaultButton>
                )}
                {eventStatus === "D" && (
                  <button
                    className="btn default-action-button mx-3 border-success bg-success text-white"
                    disabled={true}
                  >
                    Event is Live
                  </button>
                )}
              </>

              <DefaultButton
                onClick={() =>
                  Navigate(
                    `/org/${
                      isPastEvent || account ? "vieweventdata" : "editEvents"
                    }/${encryptText(eventId)}`
                  )
                }
                className="default-action-button text-white"
                variant="primary"
                type="button"
              >
                {isPastEvent || account ? "View Event" : "Edit Event"}
              </DefaultButton>

              <DefaultButton
                onClick={() => setIsOpenPreviewModal(true)}
                className="default-action-button text-white ms-3"
                variant="primary"
                type="button"
              >
                Preview Event
              </DefaultButton>
            </div>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={"home"}
          id="event-home-tabs"
          className="event-home-nav-tabs"
          onSelect={handleSelectTab}
        >
          <Tab eventKey="home" title="Home">
            {activeTab === "home" && <HomeTabScreen />}
          </Tab>
          <Tab eventKey="registartion" title="Registration">
            {activeTab === OrgDashboardType.Registartion && (
              <RegistrationTabScreen />
            )}
          </Tab>
          <Tab eventKey="refund" title="Refund">
            {activeTab === OrgDashboardType.Refund && <RefundTabScreen />}
          </Tab>
          <Tab eventKey="messages" title="Messages">
            {activeTab === OrgDashboardType.Messages && <MessageTabScreen />}
          </Tab>
          <Tab eventKey="email" title="Email">
            {activeTab === OrgDashboardType.Email && <EmailTabScreen />}
          </Tab>
          {/* <Tab eventKey="offlineEmail" title="Offline email">
            {activeTab === OrgDashboardType.OfflineEmail && (
              <OfflineEmailTabScreen />
            )}
          </Tab> */}
          <Tab eventKey="offlineRegistartion" title="Offline Registration">
            {activeTab === OrgDashboardType.OfflineRegistartion && (
              <OfflineRegistrationTabScreen />
            )}
          </Tab>
          <Tab eventKey="coupons" title="Coupons">
            {activeTab === OrgDashboardType.Coupons && <CouponsTabScreen />}
          </Tab>
          <Tab eventKey="bibs" title="Bibs">
            {activeTab === OrgDashboardType.Bibs && <BibsTabScreen />}
          </Tab>
          {isSuperAdmin && (
            <Tab eventKey="role" title="Manage Roles">
              {activeTab === OrgDashboardType.Role && <RoleTabScreen />}
            </Tab>
          )}
          {(isSuperAdmin || account) && (
            <Tab eventKey="charity" title="NGO's">
              {activeTab === OrgDashboardType.Charity && <NGOMainTabScreen />}
            </Tab>
          )}
          <Tab eventKey="partners" title="Partners & Sponsors">
            {activeTab === OrgDashboardType.Partners && (
              <SponsorsTabComponent />
            )}
          </Tab>
          <Tab eventKey="gallery" title="Gallery">
            {activeTab === OrgDashboardType.Gallery && <GalleryTabComponent />}
          </Tab>
          {(isSuperAdmin || account) && (
            <Tab eventKey="participantBreakup" title="Participant Break up">
              {activeTab === OrgDashboardType.ParticipantBreakUp && (
                <ParticipantBreakUp />
              )}
            </Tab>
          )}
        </Tabs>
      </Container>
    </>
  );
};

export default OrgHomeScreen;
