import React, { useState, useEffect } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";
import { Icon } from "components/Icon";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import axios from "utils/axios";

const GalleryTabComponent = () => {
  const { token } = useAuth();
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();
  const { storeEventId } = useEvent();

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGallery, setIsGallery] = useState<any>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSelectedID, setIsSelectedID] = React.useState<number>();

  function GetGalleryImage() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getEventGallery",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          console.log(data);
          setIsGallery(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  function handleDeleteImage() {
    setIsLoading(true);
    axiosPrivate({
      url: "/deleteEventGallery",
      data: {
        eventGalleryId: isSelectedID,
      },
      method: "POST",
    })
      .then((res) => {
        const { status } = res.data;
        if (status) {
          GetGalleryImage();
          handleModalClose();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        handleModalClose();
      });
  }

  useEffect(() => {
    GetGalleryImage();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = event.target.files;
      if (files.length > 30) {
        alert("You can upload only up to 30 images.");
        return;
      }
      setSelectedFiles(files);
    }
  };

  const handleUpload = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select one or more files.");
      return;
    }

    const formData: any = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(`file${i}`, selectedFiles[i]!);
    }

    formData.append("eventId", eventId || storeEventId);

    axios
      .post("/createEventGallery", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          ContentType: "multipart/form-data",
        },
      })

      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${message},`, { autoClose: 1000 });
          setTimeout(() => {
            GetGalleryImage();
          }, 1500);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setSelectedFiles(null);
      });
  };

  const handleModalClose = () => {
    setShowConfirmModal(false);
  };
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="align-items-center">
          <Col>
            <h4 className="my-2 fw-bold">Upload Image</h4>
          </Col>
        </Row>
        <Row className="d-flex mt-md-4">
          <Col>
            <input
              type="file"
              accept="image/png,image/jpeg, image/jpg"
              multiple
              onChange={handleFileChange}
            />
          </Col>
          <Col>
            <DefaultButton
              variant="primary"
              className="default-action-button me-md-3 px-md-4"
              type="button"
              onClick={handleUpload}
            >
              Upload
            </DefaultButton>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <h4 className="my-4 fw-bold">Images</h4>
          </Col>
        </Row>
        <Row>
          {isGallery.map((items: any) => {
            return (
              <Col md={4} className="my-4 px-4">
                <Card
                  style={{ width: "348px", height: "400px" }}
                  className=" border-0 "
                >
                  <div className="d-flex align-items-center justify-content-end me-4 pt-4">
                    <div style={{ cursor: "pointer" }}>
                      <Icon
                        iconName="Trash"
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        size={24}
                        onClick={(e) => {
                          setShowConfirmModal(true);
                          setIsSelectedID(items.id);
                        }}
                      />
                    </div>
                  </div>
                  <Card.Img
                    variant="top"
                    className="object-fit-fill"
                    src={
                      `${process.env.REACT_APP_URL}${items?.url}` ??
                      "/images/cardBackground.png"
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      {showConfirmModal && (
        <ConfirmModal
          title="Are you sure your want to delete?"
          showNote={false}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={handleDeleteImage}
        />
      )}
    </>
  );
};

export default GalleryTabComponent;
