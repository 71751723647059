import { useLocation } from "react-router-dom";

export const useCouponDashboard = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/");
  if (pathName.includes("coupondashboard")) {
    return true;
  }
  return false;
};
