import { Card, Image } from "react-bootstrap";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import { Booking } from "types/MyBooking";
import { encryptText, dateFormatConvertDDMMYYYY } from "../../utils/utils";

interface BookingCardProps {
  booking: Booking;
}

const BookingCard: React.FC<BookingCardProps> = ({ booking }) => {
  const navigate = useNavigate();
  const bookingId = encryptText(booking?.bookingId);

  return (
    <Card
      className="mb-4 border-0"
      style={{
        width: "348px",
        height: "253px",
        flexShrink: "0",
        cursor: "pointer",
        boxShadow: "0px 0px 25px 0px rgba(13, 15, 31, 0.07)",
      }}
      position-relative
      onClick={() => navigate(`/mybookings/${booking.eventSlug}/${bookingId}`)}
    >
      <Card.Body className="d-flex flex-column p-4">
        <div className="d-flex align-items-center mb-4">
          <Image
            height={40}
            width={40}
            src={booking?.organizerLogo ?? "/images/defaultorganiserlogo.svg"}
            alt="Organizer logo"
          />
          <span className="ms-3 fs-14 ff-ns text-darkGray fw-600">
            {booking?.orgName}
          </span>
        </div>
        <Card.Title>
          <h1 className="fw-600 mb-1">{booking?.eventName}</h1>
          <p className="my-1 fs-14">
            {booking?.raceName ? booking?.raceName : "Multiple Races"}
          </p>
        </Card.Title>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Card.Text className=" fs-14 ff-ns fw-400 text-darkGray mb-1">
              {booking?.eventLocation}
            </Card.Text>
            <Card.Text className="fs-14 ff-ns fw-400 text-darkGray">
              {dateFormatConvertDDMMYYYY(booking?.eventDate)}
            </Card.Text>
          </div>
          <div className="d-flex flex-column justify-content-around">
            {booking?.registrationMethod === "bulk" && (
              <div
                style={{ paddingTop: "2px", paddingBottom: "2px" }}
                className={`mb-2 fs-12 text-primary text-center text-uppercase fw-semi-bold px-2 rounded border border-primary
                `}
              >
                {booking?.registrationMethod}
              </div>
            )}

            <div
              className={`fs-12 text-light text-uppercase fw-semi-bold px-2 py-1 rounded ${
                booking?.status === "Completed" ? "bg-success" : "bg-primary"
              }`}
            >
              {booking?.status}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BookingCard;
