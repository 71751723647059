export enum OrgDashboardType {
  Registartion = "registartion",
  Messages = "messages",
  Email = "email",
  OfflineEmail = "offlineEmail",
  Bibs = "bibs",
  Coupons = "coupons",
  OfflineRegistartion = "offlineRegistartion",
  Refund = "refund",
  Charity = "charity",
  Partners = "partners",
  Role = "role",
  Gallery = "gallery",
  ParticipantBreakUp ="participantBreakup"
}
