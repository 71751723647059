import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import { useAccount } from "context/AccountContext";
import { useAuth } from "context/AuthContext";
import { useEvent } from "context/EventContext";
import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { MainRole } from "types/Organizer";
import { ToastContainer, toast } from "react-toastify";
import useOrganizer from "utils/useOrganizer";

const OrganizerAccountPage = () => {
  const { setAccountId } = useEvent();
  const Navigate = useNavigate();
  const { isOrganizer, isSuperAdmin, isLoading } = useOrganizer();

  const handleClick = (item: any, event: any) => {
    event.stopPropagation();
    setAccountId(item);
    Navigate("/org");
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Container className="mt-md-4">
        <Row>
          <Col>
            <div>
              <h6 className="fs-24 mt-1">Organisations</h6>
            </div>
          </Col>
          {isSuperAdmin && (
            <Col>
              <div className="d-flex justify-content-end align-items-center">
                <DefaultButton
                  variant="primary"
                  type="submit"
                  className="default-action-button text-white mx-5 px-2"
                  onClick={() => Navigate("/org/addorganisation")}
                >
                  Add Organisation
                </DefaultButton>
              </div>
            </Col>
          )}
        </Row>

        <Row className="mt-4 ">
          {isOrganizer &&
            isOrganizer?.map((item: MainRole) => {
              return (
                <Card
                  className="col-md-4 mx-md-2 my-md-3 "
                  style={{ cursor: "pointer", width: "auto" }}
                  key={item.accountId}
                  onClick={(event: any) => handleClick(item.accountId, event)}
                >
                  <Card.Body>
                    <div className="py-md-1">
                      <h4 className=" text-primary">{item.company_name}</h4>
                    </div>
                    <div className="py-md-1">
                      <div>
                        <h5>
                          <span className="fw-500">Email : </span>
                          <span className="fw-600 text-primary">
                            {item.company_email_id}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="py-md-1">
                      <div>
                        <h5>
                          <span className="fw-500">Mobile No : </span>
                          <span className="fw-600 text-primary">
                            {item.company_contact_no}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <Card.Text></Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
        </Row>
        {/* <Row className="mt-4 ">
          <h5>Role Based Events</h5>
          {isOrganizer?.roles?.map((item: Role) => {
            return (
              <Card
                className="col-md-4 mx-md-2 my-md-3"
                style={{ width: "18rem", cursor: "pointer" }}
                key={item.account_id}
                onClick={(event: any) => handleClick(item.account_id, event)}
              >
                <Card.Body>
                  <div className="py-md-1">
                    <h4>
                      <span className="fw-500"> Name: </span>
                      <span className="fw-600 text-primary">
                        {item.company_name}
                      </span>
                    </h4>
                  </div>
                  <div className="py-md-1">
                    <div>
                      <h5>
                        <span className="fw-500">Email : </span>
                        <span className="fw-600 text-primary">
                          {item.company_email_id}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="py-md-1">
                    <div>
                      <h5>
                        <span className="fw-500">Mobile No : </span>
                        <span className="fw-600 text-primary">
                          {item.company_contact_no}
                        </span>
                      </h5>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </Row> */}
      </Container>
    </>
  );
};

export default OrganizerAccountPage;
