import React, {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from "react";
import { CustomFieldOption, DynamicParticipant } from "types/CustomTypes";

interface Event {
  eventName: string;
  participants: DynamicParticipant<CustomFieldOption>[];
}

interface EventState {
  contactPersonDetails: {
    // name: string;
    // email: string;
    // phoneCode: string;
    // phoneNumber: string;
    // country: string;
    eventId: number;
    count: number;
  };
  events: Event[];
}

type EventAction =
  | {
      type: "UPDATE_CONTACT_PERSON_DETAILS";
      payload: Partial<EventState["contactPersonDetails"]>;
    }
  | {
      type: "ADD_PARTICIPANT";
      payload: {
        eventName: string;
        raceId: number;
        raceAmount: number;
        participant: DynamicParticipant<CustomFieldOption>;
      };
    }
  | {
      type: "DELETE_PARTICIPANT";
      payload: { eventNameToDelete: string; participantIndex: number };
    }
  | {
      type: "UPDATE_PARTICIPANT";
      payload: {
        eventNameToUpdate: string;
        participantIndexToUpdate: number;
        updatedParticipant: DynamicParticipant<CustomFieldOption>;
      };
    }
  | { type: "CLEAR_EVENTS" };

const initialState: EventState = {
  contactPersonDetails: {
    // name: "",
    // email: "",
    // phoneCode: "",
    // phoneNumber: "",
    // country: "",
    eventId: 0,
    count: 0,
  },
  events: [],
};

const EventContext = createContext<{
  state: EventState;
  dispatch: Dispatch<EventAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const eventReducer: Reducer<EventState, EventAction> = (state, action) => {
  switch (action.type) {
    case "UPDATE_CONTACT_PERSON_DETAILS":
      return {
        ...state,
        contactPersonDetails: {
          ...state.contactPersonDetails,
          ...action.payload,
        },
      };
    case "ADD_PARTICIPANT":
      const { eventName, raceId, participant, raceAmount } = action.payload;
      const existingEventIndex = state.events.findIndex(
        (event) => event.eventName === eventName
      );

      if (existingEventIndex !== -1) {
        // If the event already exists, update the participants
        return {
          ...state,
          events: state.events.map((event, index) =>
            index === existingEventIndex
              ? {
                  ...event,
                  participants: [...event.participants, participant],
                }
              : event
          ),
        };
      } else {
        // If the event doesn't exist, add a new event
        return {
          ...state,
          events: [
            ...state.events,
            { raceId, raceAmount, eventName, participants: [participant] },
          ],
        };
      }
    case "DELETE_PARTICIPANT":
      const { eventNameToDelete, participantIndex } = action.payload;
      const eventIndexToDelete = state.events.findIndex(
        (event) => event.eventName === eventNameToDelete
      );

      if (eventIndexToDelete !== -1) {
        // If the event exists, remove the participant
        const updatedEvents = state.events.map((event, index) => {
          if (index === eventIndexToDelete) {
            const updatedParticipants = event.participants.filter(
              (_, pIndex) => pIndex !== participantIndex
            );

            return {
              ...event,
              participants: updatedParticipants,
            };
          }

          return event;
        });

        return {
          ...state,
          events: updatedEvents,
        };
      } else {
        return state;
      }
    case "UPDATE_PARTICIPANT":
      const {
        eventNameToUpdate,
        participantIndexToUpdate,
        updatedParticipant,
      } = action.payload;

      const eventIndexToUpdate = state.events.findIndex(
        (event) => event.eventName === eventNameToUpdate
      );

      if (eventIndexToUpdate !== -1) {
        // If the event exists, update the participant
        const updatedEvents = state.events.map((event, index) => {
          if (index === eventIndexToUpdate) {
            const updatedParticipants = event.participants.map(
              (participant, pIndex) =>
                pIndex === participantIndexToUpdate
                  ? updatedParticipant
                  : participant
            );

            return {
              ...event,
              participants: updatedParticipants,
            };
          }

          return event;
        });

        return {
          ...state,
          events: updatedEvents,
        };
      } else {
        return state;
      }
    case "CLEAR_EVENTS":
      return initialState;
    default:
      return state;
  }
};

export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(eventReducer, initialState);

  return (
    <EventContext.Provider value={{ state, dispatch }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventState = () => {
  return useContext(EventContext).state;
};

export const useEventDispatch = () => {
  return useContext(EventContext).dispatch;
};
export const useClearEvents = () => {
  const dispatch = useEventDispatch();
  return () => dispatch({ type: "CLEAR_EVENTS" });
};
