import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Container,
} from "react-bootstrap";
import styles from "./Payment.module.scss";
import { Icon } from "components/Icon";
import DefaultInput from "components/Inputs/DefaultInput";
import { useVerifyCoupon } from "api/ApplyCoupon";
import { toast } from "react-toastify";
import PreviewTermsandConditions from "components/PreviewTermsandConditions/PreviewTermsandConditions";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import { useCharities } from "api/GetCharity";
import { useApplyCharity } from "api/ApplyCharity";
import { useRemoveCoupon } from "api/RemovePromoCode";
import { useRemoveCharity } from "api/RemoveCharity";
import Loader from "components/Loader/Loader";

type PaymentProps = {
  registeredParticipants: any;
  updateRegisteredParticipants: (
    participantId: number,
    raceId: number,
    updatedIndividualParticipantDetails: any,
    updatedTotalPaymentDetails: any
  ) => void;
  isOrgTermsChecked: boolean;
  setIsOrgTermsChecked: (checked: boolean) => void;
  isIfinishTermsChecked: boolean;
  setIsIfinishTermsChecked: (checked: boolean) => void;
  organiserTermsAndConditions: string;
  eventId: number;
  finalPaymentData: any;
  setFinalPaymentData: (finalPaymentData: any) => void;
  isCharityAdded: boolean;
  setIsCharityAdded: (isCharityAdded: boolean) => void;
  setIsOpenPayment: (isOpenPayment: boolean) => void;
};

const Payment: React.FC<PaymentProps> = ({
  registeredParticipants,
  updateRegisteredParticipants,
  isOrgTermsChecked,
  setIsOrgTermsChecked,
  isIfinishTermsChecked,
  setIsIfinishTermsChecked,
  organiserTermsAndConditions,
  eventId,
  finalPaymentData,
  setFinalPaymentData,
  isCharityAdded,
  setIsCharityAdded,
  setIsOpenPayment,
}) => {
  const [discountCoupons, setDiscountCoupons] = useState<{
    [key: number]: { promoCode: string; isPromoApplied: boolean };
  }>({});

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false);
  const [showChartity, setShowCharity] = useState<boolean>(false);
  const [selectedCharity, setSelectedCharity] = useState("");
  const [charityAmount, setCharityAmount] = useState(0);
  const [charityId, setCharityId] = useState(0);

  const { data: charityData, isLoading: charityDataLoading } = useCharities({
    eventId,
  });

  useEffect(() => {
    if (charityData?.status) {
      setShowCharity(true);
    } else {
      setShowCharity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charityData?.status]);

  const [
    showIsOrganiserTermsAndConditions,
    setShowIsOrganiserTermsAndConditions,
  ] = useState<boolean>(false);

  const handleClosePreviewModal = () => {
    setIsOpenPreviewModal(false);
  };

  const handleOpenOrganiserTermAndCondition = () => {
    setShowIsOrganiserTermsAndConditions(true);
    setIsOpenPreviewModal(true);
  };

  const handleOpenIfinishTermAndCondition = () => {
    setShowIsOrganiserTermsAndConditions(false);
    setIsOpenPreviewModal(true);
  };

  const verifyCouponMutation = useVerifyCoupon();
  const addCharityMutation = useApplyCharity();
  const removeCouponMutation = useRemoveCoupon();
  const removeCharityMutation = useRemoveCharity();

  const handleCouponInputChange =
    (participantId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscountCoupons((prevCoupons: any) => {
        return {
          ...prevCoupons,
          [participantId]: {
            promoCode: e.target.value,
            isPromoApplied: false,
          },
        };
      });
    };

  const handleApplyCoupon = (participant: any, raceId: number) => async () => {
    try {
      const response = await verifyCouponMutation.mutateAsync({
        id: participant.id,
        email: participant.email,
        actualAmount: participant.actualAmount,
        currentAmount: participant.currentAmount,
        earlyBirdDiscount: participant.earlyBirdDiscount,
        promoCode: discountCoupons[participant.id].promoCode,
        promoCodeDiscount: participant.promoCodeDiscount,
        promoCodeId: participant.promoCodeId,
        bulkDiscount: participant.bulkDiscount,
        name: participant.name,
        raceId: raceId,
      });
      if (response.status) {
        setDiscountCoupons({
          ...discountCoupons,
          [participant.id]: {
            promoCode: discountCoupons[participant.id].promoCode,
            isPromoApplied: true,
          },
        });
        updateRegisteredParticipants(
          participant.id,
          raceId,
          response.data.participantPayment,
          response.data.totalPaymentDetails
        );
        toast.success("Congratulations!! Coupon Applied Successful");
      } else {
        toast.error(
          `${
            response?.message.promoCode
              ? response?.message.promoCode[0]
              : response?.message
          }`
        );
      }
    } catch (error) {
      console.error("Coupon application error:", error);
    }
  };

  const handleRemoveCoupon = async (participantId: number, raceId: number) => {
    const response = await removeCouponMutation.mutateAsync({
      id: participantId,
    });

    if (response.status) {
      updateRegisteredParticipants(
        participantId,
        raceId,
        response.data.participantPayment,
        response.data.totalPaymentDetails
      );

      setDiscountCoupons((prevCoupons) => ({
        ...prevCoupons,
        [participantId]: {
          promoCode: "",
          isPromoApplied: false,
        },
      }));
    } else {
      toast.error("Error while removing charity");
    }
  };

  const handleAmountChange = (e: any) => {
    setCharityAmount(e.target.value);
  };

  const onCharityChange = (e: any) => {
    if (!e.target.value) {
      setCharityId(0);
      setCharityAmount(0);
    } else {
      charityData?.data?.forEach((charity: any) => {
        if (charity.charity_name === e.target.value) {
          setCharityAmount(charity.amount);
          setCharityId(charity.id);
        }
      });
    }
    setSelectedCharity(e.target.value);
  };

  const onAddCharityAmount = async () => {
    const currentCharity = charityData?.data?.filter((charity: any) => {
      return charity.charity_name === selectedCharity;
    });

    if (charityAmount <= 0) {
      toast.error("Please add a valid amount!!");
    } else if (currentCharity[0]?.amount > charityAmount) {
      toast.error(
        "Please add a greater or equivalent amount than minimum amount!!"
      );
    } else {
      try {
        const response = await addCharityMutation.mutateAsync({
          registrationId: finalPaymentData?.registrationId,
          charityId: charityId,
          amount: charityAmount,
        });

        if (response.status) {
          setIsCharityAdded(true);
          setFinalPaymentData({ ...finalPaymentData, ...response.data });
          toast.success("Charity amount added successfully");
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    }
  };

  const onRemoveCharityClick = async () => {
    const response = await removeCharityMutation.mutateAsync({
      id: finalPaymentData.registrationId,
    });
    if (response.status) {
      setFinalPaymentData({ ...finalPaymentData, ...response.data });
      setCharityAmount(0);
      setSelectedCharity("");
      setCharityId(0);
      setIsCharityAdded(false);

      toast.success("Charity amount removed successfully");
    } else {
      toast.error(`${response?.message}`);
    }
  };

  if (charityDataLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.paymentContainer}>
      <div>
        <div className="pt-2 ff-saira">
          <h1 className="fw-600 fs-saira mb-0 px-4">Payment Summary</h1>
          <div className={`py-sm-4 py-2`}>
            <h1 className="fw-600 fs-saira mb-0 px-4">
              Participant Information
            </h1>
            <div className={`pt-3`}>
              {registeredParticipants.map((race: any) => {
                return (
                  <div key={race.raceId} className="px-4">
                    {race.participants?.map((participant: any) => {
                      const participantId = participant.id;
                      return (
                        <Row
                          key={participantId}
                          className={`d-flex justify-content-center align-items-center pb-5 pb-md-3`}
                        >
                          <Col xs={12} lg={4} className="d-flex flex-column">
                            <span className={`${styles.detailsText}`}>
                              {participant.name}
                            </span>
                            <span className="fs-15">{race.raceName}</span>
                            <span className="text-primary fs-15">
                              <u
                                className="cursor-pointer"
                                onClick={() => setIsOpenPayment(false)}
                              >
                                View Details
                              </u>
                            </span>
                          </Col>
                          <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                            <div
                              style={{ maxWidth: "352px" }}
                              className="d-flex"
                            >
                              <DefaultInput
                                disabled={
                                  discountCoupons[participantId]?.isPromoApplied
                                }
                                label="Add Discount Coupon"
                                placeholder="Add Discount Coupon"
                                className="w-100 custom-margin-right"
                                value={
                                  discountCoupons[participantId]?.promoCode ||
                                  ""
                                }
                                onChange={handleCouponInputChange(
                                  participantId
                                )}
                              />
                              <Button
                                disabled={
                                  discountCoupons[participantId]
                                    ?.isPromoApplied ||
                                  !discountCoupons[participantId]?.promoCode
                                }
                                variant="primary"
                                className="mb-1"
                                type="button"
                                style={{
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                  zIndex: "10",
                                }}
                                onClick={handleApplyCoupon(
                                  participant,
                                  race.raceId
                                )}
                              >
                                <Icon
                                  iconName="ArrowRight"
                                  className="text-white"
                                  size={25}
                                />
                              </Button>
                            </div>

                            {participant?.promoCodeDiscount > 0 &&
                              discountCoupons[participantId]
                                ?.isPromoApplied && (
                                <div className="text-success2 fs-14 my-2 d-flex align-items-center">
                                  <button
                                    onClick={() =>
                                      handleRemoveCoupon(
                                        participantId,
                                        race.raceId
                                      )
                                    }
                                    className="bg-primary border-0 me-2 px-3 py-1 rounded-pill"
                                  >
                                    <img
                                      height={16}
                                      src="/images/promo.svg"
                                      alt="promo"
                                    />
                                    <span className="ms-1 me-2 fs-12 text-white">
                                      {discountCoupons[participantId].promoCode}
                                    </span>
                                    <img
                                      style={{ marginBottom: "2px" }}
                                      height={16}
                                      src="/images/removepromo.svg"
                                      alt="remove promo"
                                    />
                                  </button>
                                  Promo Code Applied!
                                </div>
                              )}
                          </Col>
                          <Col
                            xs={12}
                            lg={4}
                            className="text-lg-end mt-3 mt-lg-0 fs-20"
                          >
                            <div>
                              {participant.bulkDiscount ||
                              participant.earlyBirdDiscount ||
                              participant.promoCodeDiscount > 0 ? (
                                <div>
                                  <span>
                                    <s>Rs {participant.actualAmount}</s>
                                  </span>
                                  <span className="text-primary ps-3">
                                    Rs {participant.currentAmount?.toFixed(2)}
                                  </span>
                                </div>
                              ) : (
                                <span className="text-primary ps-3">
                                  Rs {participant.currentAmount?.toFixed(2)}
                                </span>
                              )}
                            </div>
                            {participant?.bulkDiscount > 0 &&
                            participant?.earlyBirdDiscount > 0 ? (
                              <span className="text-success2 fs-14">
                                Early Bird and Bulk Discount Applied!
                              </span>
                            ) : participant?.bulkDiscount > 0 &&
                              participant?.earlyBirdDiscount === 0 ? (
                              <span className="text-success2 fs-14">
                                Bulk Discount Applied!
                              </span>
                            ) : participant?.bulkDiscount === 0 &&
                              participant?.earlyBirdDiscount > 0 ? (
                              <span className="text-success2 fs-14">
                                Early Bird Discount Applied!
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {showChartity && (
          <>
            <hr className="py-1 " style={{ borderTop: "1px solid #BDBDBD" }} />
            <div className="my-3 px-4">
              <Row className="align-items-center">
                <Col className="my-2 my-sm-0" sm={6} lg={3}>
                  <div style={{ maxWidth: "352px" }}>
                    <DefaultDropdown
                      label="Select Charity"
                      options={charityData?.data?.map(
                        (charity: any) => charity.charity_name
                      )}
                      onChange={onCharityChange}
                      value={selectedCharity}
                      placeholder="Select Charity"
                    />
                  </div>
                </Col>
                <Col className="my-2 my-sm-0" sm={6} lg={4}>
                  <div
                    style={{ maxWidth: "352px" }}
                    className="d-flex position-relative"
                  >
                    <DefaultInput
                      disabled={!selectedCharity || isCharityAdded}
                      label="Charity Amount"
                      type="number"
                      placeholder="Charity Amount"
                      className="w-100 custom-margin-right"
                      value={charityAmount}
                      onChange={handleAmountChange}
                    />{" "}
                    {isCharityAdded && (
                      <Icon
                        iconName="X"
                        size={35}
                        style={{
                          position: "absolute",
                          top: "9",
                          right: "55",
                        }}
                        onClick={onRemoveCharityClick}
                        className="cursor-pointer"
                      />
                    )}
                    <Button
                      disabled={!selectedCharity || isCharityAdded}
                      variant="primary"
                      className="mb-1 "
                      type="button"
                      style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        zIndex: "10",
                      }}
                      onClick={onAddCharityAmount}
                    >
                      <Icon
                        iconName="ArrowRight"
                        className="text-white"
                        size={25}
                      />
                    </Button>
                  </div>
                </Col>
                <Col lg={3} className="text-end d-none d-lg-block">
                  <div>Charity Amount:</div>
                </Col>
                <Col lg={2} className="px-1 text-end d-none d-lg-block">
                  <div>Rs {finalPaymentData?.charityAmount?.toFixed(2)}</div>
                </Col>
              </Row>
              <Row className="mt-2 d-lg-none">
                <Col xs={7} sm={8} className="text-end">
                  <div>Charity Amount:</div>
                </Col>
                <Col xs={5} sm={4} className="text-end">
                  <div>Rs {finalPaymentData?.charityAmount}</div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>

      <div>
        <hr className="py-1 " style={{ borderTop: "1px solid #BDBDBD" }} />
        <div className={`${styles.detailsText} text-end px-3 px-sm-4 ff-saira`}>
          <Row className="fs-16">
            <Col xs={7} sm={8} lg={9}>
              Discount:
            </Col>
            <Col xs={5} sm={4} lg={3}>
              - Rs {finalPaymentData.discountedAmount}
            </Col>
          </Row>

          {finalPaymentData?.totalTshirtAmount > 0 && (
            <Row className="fs-16 pt-2">
              <Col xs={7} sm={8} lg={9}>
                T-Shirt Price:
              </Col>
              <Col xs={5} sm={4} lg={3}>
                Rs {finalPaymentData?.totalTshirtAmount}
              </Col>
            </Row>
          )}

          <Row className="pt-2 fs-16">
            <Col xs={7} sm={8} lg={9}>
              Sub-Total:
            </Col>
            <Col xs={5} sm={4} lg={3}>
              Rs {finalPaymentData?.totalCurrentAmount?.toFixed(2)}
            </Col>
          </Row>

          <Row className="pt-2 fs-16">
            <Col xs={7} sm={8} lg={9}>
              GST{" "}
              <span className="fs-12">
                ({finalPaymentData?.gstType === 1 ? "Exclusive" : "Inclusive"})
              </span>
              :
            </Col>
            <Col xs={5} sm={4} lg={3}>
              {finalPaymentData?.gstType === 0 && "-"} Rs{" "}
              {finalPaymentData?.gst?.toFixed(2)}
            </Col>
          </Row>

          <Row className="pt-2 fs-16">
            <Col xs={7} sm={8} lg={9}>
              <div className="d-flex justify-content-end align-items-center">
                <span className={styles.dottedBorder}>
                  Processing Fee
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ width: "292px" }}>
                        <div className="py-1">
                          <p className="mb-1">Processing Fee Break Up</p>
                          <Container className="align-items-end">
                            <Row>
                              <Col className="px-0" xs={8}>
                                <p className="mb-0 text-end fs-12">
                                  Processing Fee:
                                </p>
                              </Col>
                              <Col className="px-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0 fs-12 ms-1">Rs.</p>
                                  <p className="mb-0 fs-12">
                                    {finalPaymentData?.processingFee.toFixed(2)}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Container>

                          <Container className="mb-1">
                            <Row className="align-items-end">
                              <Col className="px-0" xs={8}>
                                <p className="mb-0 text-end fs-12">
                                  GST on Processing Fee:
                                </p>
                              </Col>
                              <Col className="px-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0 fs-12 ms-1">Rs.</p>
                                  <p className="mb-0 fs-12">
                                    {finalPaymentData?.gstProcessingAmount.toFixed(
                                      2
                                    )}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </Tooltip>
                    }
                  >
                    <img
                      className="cursor-pointer mx-2"
                      height={14}
                      width={14}
                      alt="info tooltip"
                      src="/images/info.svg"
                    />
                  </OverlayTrigger>
                </span>
                :
              </div>
            </Col>
            <Col xs={5} sm={4} lg={3}>
              Rs{" "}
              {(
                finalPaymentData?.processingFee +
                finalPaymentData?.gstProcessingAmount
              ).toFixed(2)}
            </Col>
          </Row>

          <Row className="fw-600 pt-2">
            <Col xs={7} sm={8} lg={9}>
              Total Amount:
            </Col>
            <Col xs={5} sm={4} lg={3}>
              Rs {finalPaymentData.totalPaymentAmount.toFixed(2)}
            </Col>
          </Row>
        </div>
        <hr className="py-1" style={{ borderTop: "1px solid #BDBDBD" }} />
        <div className="px-3 px-sm-4 pb-4">
          <div
            className={`d-flex align-items-center ${styles.termsAdaptiveFontSize} mb-1`}
          >
            <Form.Check
              onChange={() => setIsOrgTermsChecked(!isOrgTermsChecked)}
              checked={isOrgTermsChecked}
            />
            <span className="ms-2 ff-ns fw-400">
              I agree to the{" "}
              <u
                className="cursor-pointer"
                onClick={handleOpenOrganiserTermAndCondition}
              >
                Organiser Terms & Conditions
              </u>
            </span>
          </div>

          <div
            className={`d-flex align-items-center ${styles.termsAdaptiveFontSize}`}
          >
            <Form.Check
              onChange={() => setIsIfinishTermsChecked(!isIfinishTermsChecked)}
              checked={isIfinishTermsChecked}
            />
            <span className="ms-2 ff-ns fw-400">
              I agree to the{" "}
              <u
                className="cursor-pointer"
                onClick={handleOpenIfinishTermAndCondition}
              >
                IFinish Terms & Conditions
              </u>
            </span>
          </div>
        </div>
      </div>
      <PreviewTermsandConditions
        isOpenPreviewModal={isOpenPreviewModal}
        handleClosePreviewModal={handleClosePreviewModal}
        showIsOrganiserTermsAndConditions={showIsOrganiserTermsAndConditions}
        organiserTermsAndConditions={organiserTermsAndConditions}
      />
    </div>
  );
};

export default Payment;
