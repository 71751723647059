import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EliteRunner from "./EliteRunner";
import EliteParticipantsList from "./EliteParticipantsList";
import { Icon } from "components/Icon";
import useEventName from "utils/useEventName";

const EliteDashboard = () => {
  const Navigate = useNavigate();
  const eventName = useEventName();
  const [activeTab, setActiveTab] = useState<string | null>("home");
  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };
  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={"home"}
          id="event-home-tabs"
          className="event-home-nav-tabs border-0"
          onSelect={handleSelectTab}
        >
          <Tab eventKey="home" title="Home">
            {activeTab === "home" && <EliteRunner />}
          </Tab>
          <Tab eventKey="elite" title="Elite Participants">
            {activeTab === "elite" && <EliteParticipantsList />}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default EliteDashboard;
