import { useMutation } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

export const useEditUserProfile = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: any) => {
    const {
      first_name,
      last_name,
      gender,
      date_of_birth,
      email,
      country_code,
      mobile_number,
      profile_image,
      street_address,
      area,
      city,
      country,
      state,
      landmark,
      nationality,
      pincode,
      blood_group,
      "height(centimeter)": heightCentimeter,
      "weight(kg)": weightKg,
      "t-shirt_size(cm)": tShirtSize,
      shoe_size,
      history_of_health_issues: healthIssues,
      alternate_email,
      emergency_contact_name,
      emergency_contact_number,
      emergency_contact_relationship,
      identity_proof_id,
      id_proof_number,
      upload_id_proof,
      subscribed_for_ifinish_update,
      subscribed_for_event_updates,
    } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/editUserProfile",
        data: {
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          date_of_birth: date_of_birth,
          email: email,
          country_code: country_code,
          mobile_number: mobile_number,
          profile_image: profile_image,
          street_address: street_address,
          area: area,
          city: city,
          country: country,
          state: state,
          landmark: landmark,
          nationality: nationality,
          pincode: pincode,
          blood_group: blood_group,
          "height(centimeter)": heightCentimeter,
          "weight(kg)": weightKg,
          "t-shirt_size(cm)": tShirtSize,
          shoe_size: shoe_size,
          history_of_health_issues: healthIssues,
          alternate_email: alternate_email,
          emergency_contact_name: emergency_contact_name,
          emergency_contact_number: emergency_contact_number,
          emergency_contact_relationship: emergency_contact_relationship,
          identity_proof_id: identity_proof_id,
          upload_id_proof: upload_id_proof,
          id_proof_number: id_proof_number,
          subscribed_for_ifinish_update: subscribed_for_ifinish_update,
          subscribed_for_event_updates: subscribed_for_event_updates,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error Updating User Profile");
      throw error;
    }
  });
};
