export enum OrgRoleEnum {
  Admin = "Admin",
  CouponAdmin = "Coupon Admin",
  Viewer = "Viewer",
  Superadmin = "Super Admin",
  Approver = "Approver",
  Editor = "Editor",
  CharityAdmin = "Charity Admin",
  CouponUser = "Coupon User",
  CharityDashboard = "Charity",
  EliteRunnerAdmin = "Elite Runner Admin",
}
