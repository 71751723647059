import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import OffCanvas from "components/OffCanvas/OffCanvas";
import CustomizedOffCanvas from "components/CustomizedOffCanvas/CustomizedOffCanvas";
import IndividualEventDetails from "components/IndividualEventDetails/IndividualEventDetails";
import AddToCartButton from "components/AddToCartButton/AddToCartButton";
import styles from "./EventPriceCard.module.scss";
import { Race } from "types/Race";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Benefit } from "types/Benefit";
import moment from "moment";
import { UpdateRace } from "types/UpdateRace";
import { hasExpired, isRegistrationStarted } from "utils/utils";

interface Props {
  serverTime: string;
  race: Race;
  upgradingRaceInfo?: UpdateRace | null;
  isFromUpdateDetails?: boolean;
}

const EventPriceCard: React.FC<Props> = ({
  serverTime,
  race,
  upgradingRaceInfo,
  isFromUpdateDetails,
}) => {
  const location = useLocation();

  const [showIndividualEventDetails, setShowIndividualEventDetails] =
    useState<boolean>(false);

  const [showAddToCartButton, setShowAddToCartButton] = useState(true);

  const [isOrganiserDashboard, setIsOrganiserDashboard] = useState(false);

  const [isOpenCanvasInsideModal, setIsOpenCanvasInsideModal] =
    useState<boolean>(false);

  const [showEarlyBird, setShowEarlyBird] = useState<boolean>(false);

  const handleShowIndividualEventDetails = () => {
    if (isOrganiserDashboard && !isFromUpdateDetails) {
      setIsOpenCanvasInsideModal(true);
    } else if (!isFromUpdateDetails) {
      setShowIndividualEventDetails(true);
    }
  };

  const handleCloseIndividualEventDetails = useCallback(() => {
    setShowIndividualEventDetails((prevState) => !prevState);
  }, []);

  const handleCloseIndividualEventDetailsInCustomizedOffCanvas =
    useCallback(() => {
      setIsOpenCanvasInsideModal((prevState) => !prevState);
    }, []);

  useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (
      pathName.includes("mybookings") ||
      pathName.includes("eventDashBoard")
    ) {
      setShowAddToCartButton(false);
    }

    if (pathName.includes("eventDashBoard")) {
      setIsOrganiserDashboard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (race.allow_early_bird) {
      setShowEarlyBird(true);
    } else {
      setShowEarlyBird(false);
    }
  }, [race?.allow_early_bird]);

  return (
    <>
      <div
        onClick={handleShowIndividualEventDetails}
        className={`${styles.priceCard} cursor-pointer`}
        key={race.id}
      >
        <Container className="px-0">
          <Row className="flex-md-row flex-column align-items-center">
            <Col md={3} sm={3}>
              <div className="d-flex flex-column justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <div
                  style={{ minWidth: "100px" }}
                  className="position-relative"
                >
                  {/* <p className={`${styles.colorGrey} fs-12 mb-1 font-noto`}>
                    {Race?.race_distance_info}
                  </p> */}
                  <p
                    className={`fw-600 w-100 font-saira mb-0 ${styles.raceName}`}
                  >
                    {race?.ticket_name}
                  </p>
                  <span
                    className={`w-100 d-block d-md-none fs-12 text-center mb-1`}
                  >
                    View Details
                  </span>
                  <span className="position-absolute d-none fs-12 d-md-block">
                    View Details
                  </span>
                </div>
              </div>
            </Col>
            <Col className="px-2 my-3 my-md-0" md={5} sm={4}>
              <div className="text-center">
                <div className="text-center">
                  {!isRegistrationStarted(
                    race?.registration_start_date,
                    serverTime
                  ) && (
                    <p className={`text-primary mb-0 fs-14 font-saira`}>
                      Registrations will start from{" "}
                      {moment(race?.registration_start_date).format(
                        "DD-MM-YYYY, hh:mm a"
                      )}
                    </p>
                  )}
                  {showEarlyBird ? (
                    <>
                      <div className="text-primary fs-16 ff-saira">
                        Earlybird Price
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <p>
                          <span style={{ marginRight: "0.2rem" }}>&#8377;</span>
                          <del> {race?.amount}/-</del>
                        </p>
                        <p className="fw-600 text-primary font-saira ps-2">
                          <span style={{ marginRight: "0.2rem" }}>&#8377;</span>
                          {race?.early_bird_discount_amount}/-
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className={`${styles.colorGrey} fs-16 ff-saira mb-1`}>
                        Race Price
                      </p>
                      <p className="fw-600 font-saira mb-2">
                        <span style={{ marginRight: "0.2rem" }}>&#8377;</span>
                        {race?.amount}/-
                      </p>
                    </>
                  )}

                  <div className="w-100 d-flex align-items-center justify-content-center flex-wrap">
                    {race?.benefits.map((benefit: Benefit, index: number) => (
                      <OverlayTrigger
                        key={index}
                        placement="top"
                        overlay={<Tooltip>{benefit.benefitName}</Tooltip>}
                      >
                        <div className="pe-2 mb-1">
                          <img
                            key={index}
                            src={`${process.env.REACT_APP_URL}${benefit.benefitIconName}`}
                            alt={benefit.benefitName}
                          />
                        </div>
                      </OverlayTrigger>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            {showAddToCartButton && (
              <Col md={4} sm={5}>
                <div className="d-flex justify-content-center justify-content-md-end">
                  <AddToCartButton
                    disabled={
                      hasExpired(race.registration_end_date, serverTime) ||
                      upgradingRaceInfo?.raceName === race.ticket_name ||
                      !isRegistrationStarted(
                        race?.registration_start_date,
                        serverTime
                      )
                    }
                    serverTime={serverTime}
                    variant="filled"
                    eventInfo={race}
                    isEarlyBirdPrice={showEarlyBird}
                    isFromUpdateDetails={isFromUpdateDetails}
                    participantId={upgradingRaceInfo?.participantId}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <OffCanvas
        showOffCanvasModal={showIndividualEventDetails}
        handleCloseOffCanvasModal={handleCloseIndividualEventDetails}
      >
        <IndividualEventDetails eventInfo={race} serverTime={serverTime} />
      </OffCanvas>
      {isOpenCanvasInsideModal && (
        <CustomizedOffCanvas
          handleCloseCustomizedOffCanvasModal={
            handleCloseIndividualEventDetailsInCustomizedOffCanvas
          }
        >
          <IndividualEventDetails eventInfo={race} serverTime={serverTime} />
        </CustomizedOffCanvas>
      )}
    </>
  );
};

export default React.memo(EventPriceCard);
