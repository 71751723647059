import BookingCard from "components/Cards/BookingCard";
import { useEventList } from "api/EventApi";
import { useMyBooking } from "api/MyBookingApi";
import { Col, Row } from "react-bootstrap";
import RecommendedEvents from "components/Dashboard/RecommendedEvents";
import useBootstrapSize from "utils/useBootstrapSize";
import { useState } from "react";
import { Event } from "types/Event";
import { sortEventsByEndDate } from "utils/utils";
import { Booking } from "types/MyBooking";
import Loader from "components/Loader/Loader";

const MyBooking = () => {
  const screenSize = useBootstrapSize();
  const [startIndex, setStartIndex] = useState(0);

  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs" || screenSize === "md"
      ? 1
      : screenSize === "lg"
      ? 2
      : 3;

  const {
    data: recommendedEvents,
    isLoading: recommendedEventsLoading,
    isError: recommendedEventsError,
  } = useEventList();

  const {
    data: myBookings,
    isLoading: myBookingsLoading,
    isError: myBookingsError,
  } = useMyBooking();

  const sortedEvents = sortEventsByEndDate(recommendedEvents?.data);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };

  const handleNext = () => {
    if (startIndex + CHUNK_SIZE < sortedEvents?.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };

  const currentEvents: Event[] = Array.isArray(sortedEvents)
    ? sortedEvents?.slice(startIndex, startIndex + CHUNK_SIZE)
    : [];

  // Check if there are items to show for PrevIcon
  const showPrevItems = startIndex > 0;

  // Check if there are items to show for NextIcon
  const showNextItems = startIndex + CHUNK_SIZE < sortedEvents?.length;

  if (recommendedEventsLoading || myBookingsLoading) {
    return <Loader />;
  }

  if (recommendedEventsError) {
    return <p>Error fetching data</p>;
  }

  return (
    <>
      <Row className="mt-4 mb-3">
        {myBookings?.status ? (
          <>
            {myBookings?.data?.map((booking: Booking, id: string) => {
              return (
                <Col
                  className="d-flex justify-content-sm-start justify-content-center"
                  xs={12}
                  md={6}
                  xl={4}
                  key={id}
                >
                  <BookingCard booking={booking} />
                </Col>
              );
            })}
          </>
        ) : (
          <p className="my-1">{myBookings?.message}</p>
        )}
      </Row>
      <RecommendedEvents
        currentEvents={currentEvents}
        handlePrev={handlePrev}
        handleNext={handleNext}
        title="Recommended Events"
        showPrevItems={showPrevItems}
        showNextItems={showNextItems}
      />
    </>
  );
};

export default MyBooking;
