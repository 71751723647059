import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const ReadMore = ({ eventName }: any) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {eventName}
    </Tooltip>
  );

  const truncatedeventName =
    eventName && eventName?.length > 30
      ? `${eventName.slice(0, 20)}...`
      : eventName;

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div className="fw-600">{truncatedeventName}</div>
    </OverlayTrigger>
  );
};

export default ReadMore;
