import React, { useState, useEffect } from "react";
import { Form as Formdata, Formik } from "formik";
import { object, string, number } from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { TextField, TextSelect } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import { useEventDashboard } from "utils/useCreateEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useCouponDashboard } from "utils/useCoupon";

const PromotionCode = ({ setIntialScreen, handleReloadScreen }: any) => {
  const [isRaceList, setIsRaceList] = useState<any>([]);
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const isEventDashboard = useEventDashboard();
  const axiosPrivate = useAxiosPrivate();
  const isCouponDashboard = useCouponDashboard();
  // let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isEventDashboard || isCouponDashboard) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id]);
  const { storeEventId } = useEvent();
  const [isLoading, setIsLoading] = useState(false);
  const isViewEvent = ViewEvent();
  function CouponRaceList() {
    // axios
    //   .post(
    //     "/couponRaceList",
    //     {
    //       eventId: Id || storeEventId,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         Accept: "application/json",
    //       },
    //     }
    //   )
    axiosPrivate({
      url: "/couponRaceList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; id: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; id: any; amount: any }[] = [];
        arrayEarlyData.push({
          name: "All",
          id: "0",
          amount: "",
        });
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            id: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              id: items.raceId,
              amount: items.amount,
            });
          }
        });
        setIsRaceList(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      CouponRaceList();
    }
  }, [Id]);
  const isString = (value: any): value is string => typeof value === "string";

  const PromoCodeSchema = object({
    couponRaceType: string().required("Coupon race type is required"),
    numberofcoupons: number().required("Number of coupons is required"),
    couponValidFrom: string().required("Valid from is required"),
    couponValidTo: string().required("Valid to is required"),
    maxDiscountAmount: string(),
    couponDescription: string(),
    couponCompanyName: string(),
    couponContactPersonEmail: string(),
    sharecouponcode: string(),
    couponContactPersonName: string(),
    couponContactPersonNumber: number(),
    couponPrefix: string(),
    couponPrefixData: string(),
    couponDiscountPercentage: string(),
    couponDiscountAmount: string(),
    couponType: string(),
  });

  function submitCall(postData: any) {
    setIsLoading(true);
    // axios.post("/createPromoCoupons", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/createPromoCoupons",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${res.data.message},`, { autoClose: 1000 });
          setTimeout(() => {
            handleReloadScreen();
            setIntialScreen(false);
          }, 2000);
        } else {
          //---- Validate the Is unique ----//
          if (res.data.isUnique) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            Object.keys(res.data.message).forEach((key) => {
              res.data.message[key].forEach((message: any) => {
                // console.log(`${key}: ${message}`);
                toast.error(` ${message}`);
              });
            });
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.error(` ${res.data.message}`);
          }
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  const handlePromotionFormSubmit = (values: any) => {
    const postData = {
      eventId: Id || storeEventId,
      raceId: values.couponRaceType === "0" ? null : values.couponRaceType,
      isPrefix: values.couponPrefix,
      couponPrefix: values.couponPrefixData,
      discountName: values.couponPrefixData,
      discountIn:
        values.couponType === "Discount Percentage" ? "percentage" : "amount",
      discount:
        values.couponType === "Discount Percentage"
          ? values.couponDiscountPercentage
          : values.couponDiscountAmount,
      noOfCoupons: values.numberofcoupons,
      maxDiscount: "0",
      companyName:
        values.sharecouponcode === "1" ? values.couponCompanyName : "",
      companyContactName:
        values.sharecouponcode === "1" ? values.couponContactPersonName : "",
      companyEmail:
        values.sharecouponcode === "1" ? values.couponContactPersonEmail : "",
      companyMobile:
        values.sharecouponcode === "1" ? values.couponContactPersonNumber : "",
      validFrom: values.couponValidFrom,
      validTo: values.couponValidTo,
      couponDescription:
        values.sharecouponcode === "1" ? values.couponDescription : "",
      couponType: "code",
    };
    const couponType =
      values.couponType === "Discount Percentage" ? "percentage" : "amount";
    const discountType =
      values.couponType === "Discount Percentage"
        ? values.couponDiscountPercentage
        : values.couponDiscountAmount;
    const raceAmount: any = getRaceAmount(Number(values.couponRaceType));
    if (
      couponType === "percentage" &&
      (discountType < 0 || discountType <= 100)
    ) {
      submitCall(postData);
    } else if (couponType === "amount" && raceAmount >= discountType) {
      submitCall(postData);
    } else {
      const errorMessage =
        (couponType === "percentage" &&
          (discountType < 0 || discountType > 100)) ||
        (couponType === "amount" && discountType >= raceAmount)
          ? `Discount ${
              couponType === "percentage" ? "percentage" : "amount"
            } ${discountType} must be less than ${
              couponType === "amount" ? "race amount" : ""
            }   ${couponType === "percentage" ? "100" : `${raceAmount}`} ${
              couponType === "percentage" ? "percentage" : ""
            }  `
          : undefined;

      if (errorMessage) {
        toast.error(errorMessage, { autoClose: 1500 });
      }
    }
  };

  const promoCodeInitialValues = {
    couponRaceType: "",
    numberofcoupons: "",
    couponValidFrom: "",
    couponValidTo: "",
    maxDiscountAmount: "",
    couponDescription: "",
    couponCompanyName: "",
    couponContactPersonEmail: "",
    couponContactPersonName: "",
    couponContactPersonNumber: "",
    couponPrefix: "",
    couponPrefixData: "",
    couponDiscountPercentage: "",
    couponDiscountAmount: "",
    couponType: "",
    sharecouponcode: "",
  };
  const getRaceAmount = (id1: number): string | undefined => {
    const country = isRaceList.find((c: any) => c.id === id1);
    return country ? country.amount ?? "0" : "0";
  };
  return (
    <>
      {isLoading && <Loader />}
      <Row className="mt-4">
        <h6 className="text-darkgray fw-400 fs-13">
          1. These are the coupons that will be shared with the public or
          specific clients.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          2. The option same coupon code will result in one coupon code that
          will created and will be used by all.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          3. The option different coupon code will have a prefix which will
          remain the same, but there will a 4 digit unique number that will
          change and can only be used once.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          4. Please add and verify the Promotion codes. Once created, they
          cannot be edited/deleted.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          5. Selecting "Event Based" in the select race drop down will apply the
          bulk discount to all the races.
        </h6>
      </Row>
      <Formik
        validationSchema={PromoCodeSchema}
        onSubmit={handlePromotionFormSubmit}
        initialValues={promoCodeInitialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Formdata noValidate onSubmit={handleSubmit}>
            <>
              <Row className="my-2 d-flex align-items-center">
                <Col xs={12} md={5}>
                  <TextSelect
                    name="couponRaceType"
                    options={isRaceList}
                    label="Please Select Race *"
                    type="select"
                  />
                </Col>
                <Col xs={12} md={5} className="d-flex justify-content-start">
                  {values.couponRaceType !== "0" && (
                    <Form.Group>
                      <Form.Label>
                        Race Amount ₹{" "}
                        {getRaceAmount(Number(values.couponRaceType))}
                      </Form.Label>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Label className="my-2">
                    Coupon Prefix <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Group>
                    <>
                      <Row className="my-2 d-flex align-items-center">
                        <Col xs={12} md={3}>
                          <Form.Check
                            inline
                            name="couponPrefix"
                            type="radio"
                            onChange={handleChange}
                            isInvalid={!!errors.couponPrefix}
                          >
                            <Form.Check.Input
                              type="radio"
                              name="couponPrefix"
                              value="1"
                              onChange={handleChange}
                              isInvalid={!!errors.couponPrefix}
                            />
                            <Form.Check.Label>
                              Yes (Random Coupon Code)
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                        <Col xs={10} md={4}>
                          {values.couponPrefix === "1" && (
                            <>
                              <TextField
                                type="text"
                                name="couponPrefixData"
                                label="Coupon prefix *"
                                value={values.couponPrefixData}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                    <Row className="my-2 d-flex align-items-center">
                      <Col xs={12} md={3}>
                        <Form.Check
                          name="couponPrefix"
                          type="radio"
                          onChange={handleChange}
                          isInvalid={!!errors.couponPrefix}
                        >
                          <Form.Check.Input
                            type="radio"
                            name="couponPrefix"
                            value="0"
                            onChange={handleChange}
                            isInvalid={!!errors.couponPrefix}
                          />
                          <Form.Check.Label>
                            No (Same Coupon Code)
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                      <Col xs={10} md={4}>
                        {values.couponPrefix === "0" && (
                          <>
                            <TextField
                              type="text"
                              name="couponPrefixData"
                              label="Enter Coupon Code *"
                              value={values.couponPrefixData}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Label className="my-2">
                    Coupon Type <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Group>
                    <>
                      <Row className="my-2 d-flex align-items-center">
                        <Col xs={12} md="auto">
                          <Form.Check
                            inline
                            name="couponType"
                            type="radio"
                            onChange={handleChange}
                            isInvalid={!!errors.couponType}
                          >
                            <Form.Check.Input
                              type="radio"
                              name="couponType"
                              value="Discount Percentage"
                              onChange={handleChange}
                              isInvalid={!!errors.couponType}
                            />
                            <Form.Check.Label>
                              Discount Percentage
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                        <Col xs={10} md={4}>
                          {values.couponType === "Discount Percentage" && (
                            <>
                              <TextField
                                type="number"
                                name="couponDiscountPercentage"
                                label="Discount Percentage *"
                                value={values.couponDiscountPercentage}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onWheel={(event: any) =>
                                  event.currentTarget.blur()
                                }
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    </>

                    <>
                      {values.couponRaceType !== "0" && (
                        <Row className="my-2 d-flex align-items-center">
                          <Col xs={12} md="auto">
                            <Form.Check
                              inline
                              name="couponType"
                              type="radio"
                              onChange={handleChange}
                              isInvalid={!!errors.couponType}
                            >
                              <Form.Check.Input
                                type="radio"
                                name="couponType"
                                value="Discount Amount"
                                onChange={handleChange}
                                isInvalid={!!errors.couponType}
                              />
                              <Form.Check.Label>
                                Discount Amount
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                          <Col xs={10} md={4}>
                            {values.couponType === "Discount Amount" && (
                              <>
                                <TextField
                                  type="number"
                                  name="couponDiscountAmount"
                                  label="Discount Amount *"
                                  value={values.couponDiscountAmount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onWheel={(event: any) =>
                                    event.currentTarget.blur()
                                  }
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="my-md-2">
                <Col className="col-md-4">
                  <TextField
                    type="text"
                    name="numberofcoupons"
                    label="Number of Coupons *"
                  />
                </Col>
              </Row>
              <Row className="my-md-2">
                <Col xs={12} md={4}>
                  <TextField
                    type="datetime-local"
                    name="couponValidFrom"
                    label="Valid From *"
                    value={values.couponValidFrom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={new Date().toISOString().slice(0, -8)}
                  />
                </Col>

                <Col xs={12} md={4}>
                  <TextField
                    type="datetime-local"
                    name="couponValidTo"
                    label="Valid To *"
                    value={values.couponValidTo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row className="my-md-2">
                <Form.Label>
                  Do you want to share the coupon?{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Group>
                  <Form.Check
                    inline
                    label="Yes"
                    name="sharecouponcode"
                    type="radio"
                    onChange={handleChange}
                    value="1"
                    isInvalid={!!errors.sharecouponcode}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="sharecouponcode"
                    onChange={handleChange}
                    type="radio"
                    value="0"
                    isInvalid={!!errors.sharecouponcode}
                  />
                </Form.Group>
                {values.sharecouponcode === "1" && (
                  <>
                    <Row className="my-md-2">
                      <Col xs={12} md={6}>
                        <TextField
                          type="text"
                          name="couponCompanyName"
                          label="Company Name *"
                          value={values.couponCompanyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          type="email"
                          name="couponContactPersonEmail"
                          label="Contact Person Email"
                          value={values.couponContactPersonEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row className="my-md-2">
                      <Col xs={12} md={6}>
                        <TextField
                          type="text"
                          name="couponContactPersonName"
                          label="Contact Person Name *"
                          value={values.couponContactPersonName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          type="number"
                          name="couponContactPersonNumber"
                          label="Contact Person Number *"
                          value={values.couponContactPersonNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onWheel={(event: any) => event.currentTarget.blur()}
                        />
                      </Col>
                    </Row>

                    <Row className="my-md-2">
                      <Col xs={12}>
                        <Form.Group className="my-2">
                          <Form.Label>
                            Description <span className="text-primary">*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="couponDescription"
                            placeholder="Enter Description"
                            value={values.couponDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.couponDescription &&
                              !!errors.couponDescription
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
            </>

            <Row className="my-md-3 d-flex align-items-center justify-content-end">
              {!isViewEvent && (
                <Col className="d-flex justify-content-end">
                  <DefaultButton
                    type="submit"
                    variant="outline-primary"
                    className=" default-action-button"
                  >
                    Submit
                  </DefaultButton>
                </Col>
              )}
            </Row>
          </Formdata>
        )}
      </Formik>
    </>
  );
};

export default PromotionCode;
