import React, { useState } from "react";

import { Col, Container, Row, Tab, Tabs, Form } from "react-bootstrap";
import { Form as Formdata, Formik } from "formik";
import { object, string, number } from "yup";
import { TextArea, TextField, TextRadio, TextSelect } from "utils/FormControls";

const MessageTabScreen = () => {
  const [isRaceNameList, setIsRaceNameList] = useState([
    { name: "Race" },
    { name: "Race1" },
  ]);
  const [isEventCategoryList, setIsEventCategoryList] = useState([
    { name: "Race" },
    { name: "Race1" },
  ]);

  const [isGenderCategoryList, setIsGenderCategoryList] = useState([
    { name: "All" },
    { name: "Male" },
    { name: "FeMale" },
  ]);

  const [isApplicationCategoryList, setIsApplicationCategoryList] = useState([
    { name: "Apporved" },
    { name: "Rejected" },
    { name: "MoreInfo" },
  ]);
  const [isSMSCategoryList, setIsSMSCategoryList] = useState([
    { name: "SMS Template" },
  ]);

  const handleSubmit = (values: any) => {
    console.log("Disclaimers", values);
  };

  const WhatsappValidation = object({
    eventName: string().required(),
    raceName: string().required(),
    category: string().required(),
    gender: string().required(),
    applicationStatus: string().required(),
    description: string().required(),
  });

  const SMSValidation = object({
    eventName: string().required(),
    raceName: string().required(),
    category: string().required(),
    gender: string().required(),
    applicationStatus: string().required(),
    SMSTemplate: string().required(),
  });
  const OfflineSMSValidation = object({
    eventName: string().required(),

    OfflineSMSTemplate: string().required(),
  });
  return (
    <>
      <Tabs
        defaultActiveKey={"whatsAppMessage"}
        id="event-message-tabs"
        className="event-message-nav-tabs"
      >
        <Tab eventKey="whatsAppMessage" title="Whatsapp Message">
          <Container>
            <Formik
              validationSchema={WhatsappValidation}
              onSubmit={handleSubmit}
              initialValues={{
                eventName: "",
                raceName: "",
                category: "",
                gender: "",
                applicationStatus: "",
                description: "",
              }}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="px-0  d-flex flex-column justify-content-center">
                    <>
                      <Row className="">
                        <Col xs={12} md={4}>
                          <TextField
                            name="eventName"
                            type="text"
                            label="Event Name"
                            isRequired={true}
                            // value={isBasicDetailsData?.location}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="raceName"
                            options={isRaceNameList}
                            label="Select Race Name"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="category"
                            options={isEventCategoryList}
                            label="Category"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-4 text-primary">
                        Select the participants who should receive the SMS
                      </h4>
                      <Row className="">
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="gender"
                            options={isGenderCategoryList}
                            label="Gender"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="applicationStatus"
                            options={isApplicationCategoryList}
                            label="Application Status"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <TextArea
                            name="description"
                            rows={5}
                            label="Description"
                            isRequired={true}
                            // value={isBasicDetailsData?.description}
                          />
                        </Col>
                      </Row>
                    </>

                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn btn-outline-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        </Tab>
        <Tab eventKey="normalSMS" title="Normal SMS">
          <Container>
            <Formik
              validationSchema={isSMSCategoryList}
              onSubmit={handleSubmit}
              initialValues={{
                eventName: "",
                raceName: "",
                category: "",
                gender: "",
                applicationStatus: "",
                SMSTemplate: "",
              }}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="px-0  d-flex flex-column justify-content-center">
                    <>
                      <Row className="">
                        <Col xs={12} md={4}>
                          <TextField
                            name="eventName"
                            type="text"
                            label="Event Name"
                            isRequired={true}
                            // value={isBasicDetailsData?.location}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="raceName"
                            options={isRaceNameList}
                            label="Select Race Name"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="category"
                            options={isEventCategoryList}
                            label="Category"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                      </Row>
                      <h4 className="mt-4 text-primary">
                        Select the participants who should receive the SMS
                      </h4>
                      <Row className="">
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="gender"
                            options={isGenderCategoryList}
                            label="Gender"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="applicationStatus"
                            options={isApplicationCategoryList}
                            label="Application Status"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextSelect
                            name="SMSTemplate"
                            options={isSMSCategoryList}
                            label="SMS Template"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                      </Row>
                    </>

                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn btn-outline-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        </Tab>
        <Tab eventKey="offlineSMS" title="Offline SMS">
          <Container>
            <Formik
              validationSchema={OfflineSMSValidation}
              onSubmit={handleSubmit}
              initialValues={{
                eventName: "",
                OfflineSMSTemplate: "",
              }}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="px-0  d-flex flex-column justify-content-center">
                    <>
                      <Row className="">
                        <Col xs={12} md={6}>
                          <TextField
                            name="eventName"
                            type="text"
                            label="Event Name"
                            isRequired={true}
                            // value={isBasicDetailsData?.location}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <TextSelect
                            name="SMSTemplate"
                            options={isSMSCategoryList}
                            label="SMS Template"
                            type="select"
                            isRequired={true}
                            // value={isBasicDetailsData?.event_category_id}
                          />
                        </Col>
                      </Row>
                    </>

                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn btn-outline-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        </Tab>
      </Tabs>
    </>
  );
};

export default MessageTabScreen;
