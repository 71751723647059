import React from "react";
import { Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

interface RadioInputProps {
  label: string;
  options: string[];
  selectedOption: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  error?: string;
  className?: string;
  labelClassName?: string;
  helperContent?: null | string;
  name?: string;
}

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  options,
  selectedOption,
  onChange,
  isError,
  error,
  className,
  labelClassName,
  helperContent,
  name
}) => {
  return (
    <Form.Group as={Col} className="mb-3" controlId="radioInput">
      <Form.Label
        className={`fs-12 ff-ns fw-400 ${labelClassName} ${
          isError ? "text-primary" : ""
        }`}
      >
        {label}
        {helperContent != null && (
          <span className="ms-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{helperContent}</Tooltip>}
            >
              <img
                className="cursor-pointer"
                height={14.5}
                width={15}
                alt="info tooltip"
                src="/images/info.svg"
              />
            </OverlayTrigger>
          </span>
        )}
      </Form.Label>
      <div>
        {options.map((option, index) => (
          <Form.Check
            name={name}
            key={index}
            className={`fs-12 ff-ns fw-400 ${className}`}
            type="radio"
            label={option}
            value={option}
            checked={selectedOption === option}
            onChange={onChange}
            feedback={error}
            isInvalid={isError}
            inline
          />
        ))}
      </div>
    </Form.Group>
  );
};

export default RadioInput;
