import { useQuery } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

interface EventRaceListParams {
  eventId: number;
}

export const useCharities = (params: EventRaceListParams) => {
  const axiosPrivate = useAxiosPrivate();
  const { eventId } = params;

  return useQuery(
    ["useCharities", eventId],
    async () => {
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: "/getCharities",
          data: {
            eventId: eventId,
          },
        });
        return response.data;
      } catch (error) {
        // toast.error("Error fetching charities");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
