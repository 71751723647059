import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import { Formik, Field, FieldInputProps } from "formik";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import * as FileSaver from "file-saver";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const OfflineRegistrationTabScreen = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { id } = useParams();
  const isViewEvent = ViewEvent();
  const acceptableFileName = ["xlsx", "xls"];
  const axiosPrivate = useAxiosPrivate();
  const eventId = decryptText(String(id));
  const { storeEventId } = useEvent();
  const [SelectRaceId, setSelectRaceId] = useState("");
  const [isSelect, setIsSelect] = useState<any>([]);
  const [isHeader, setIsHeader] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [excelData, setExcelData] = useState<any>([]);
  const [updateRaceName, setUpdateRaceName] = useState();
  const [isTableDisplay, setIsTableDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkFileName = (name: string) => {
    const isValidFormat = acceptableFileName.includes(
      name.split(".").pop()?.toLowerCase() || ""
    );
    return isValidFormat;
  };

  const readDataFromExcel = (data: any) => {
    const wb = XLSX.read(data);
    // setSheetNames(wb.SheetNames);
    var mySheetData: any = {};

    for (var i = 0; i < wb.SheetNames.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      mySheetData[sheetName] = jsonData;
    }
    // setSheetData(mySheetData);
    return mySheetData;
  };

  function convertExcelDateToReadableDate(excelDate: number): string {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const date = new Date(
      (excelDate - 1) * millisecondsPerDay + Date.UTC(1899, 11, 30)
    );
    // // Check if the date is invalid
    // if (isNaN(date.getTime())) {
    //   toast.error("Invalid date format. Please upload a valid Excel date.");
    // }
    const adjustedDate = new Date(date.getTime() + millisecondsPerDay);
    // Format the date to DD-MM-YYYY
    const formattedDate = moment.utc(adjustedDate).format("DD-MM-YYYY");
    // Check if the formatted date is in the correct format
    if (!moment(formattedDate, "DD-MM-YYYY", true).isValid()) {
      toast.error(
        "Excel was not able to read the date format. Please upload the file with a valid Excel date."
      );
    }
    return formattedDate;
  }

  // function capitalizeFirstLetter(str: any) {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // }

  function capitalizeFirstLetter(str: any) {
    const lowerStr = str.toLowerCase();
    if (lowerStr === "male" || lowerStr === "female") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      toast.error("Invalid gender. Only 'male', 'female'are allowed.");
      return "";
    }
  }

  function toUpperCase(str: any) {
    return str.toUpperCase();
  }

  function validateMobileNumber(mobileNumber: any) {
    const mobileNumberPattern = /^[0-9]{10}$/;

    if (mobileNumber === "") {
      toast.error("Mobile number cannot be empty");
      return false;
    }

    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(
        "Mobile number must be 10 digits long and contain only numbers"
      );
      return false;
    }

    return true;
  }
  function validatePincode(pincodeNumber: any) {
    // Regex pattern to match a number with more than 5 digits
    const pincodeNumberPattern = /^[0-9]{6,}$/;

    if (pincodeNumber === "") {
      toast.error("Pincode number cannot be empty");
      return false;
    }

    if (!pincodeNumberPattern.test(pincodeNumber)) {
      toast.error(
        "Pincode number must be more than 5 digits long and contain only numbers"
      );
      return false;
    }

    return true;
  }

  function ValidateHHMMSSFormat(timeString: any): boolean {
    // Regular expression to match HH:mm:ss format
    const regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

    // Test the string against the regex
    if (!regex.test(timeString)) {
      toast.error("Invalid time format. Time must be in HH:mm:ss format");
      return false;
    }
    return true;
  }
  const handleSubmit = async (values: any) => {
    setIsTableDisplay(false);
    const myFile = values.uploadExcel;
    if (!myFile) return;
    if (!checkFileName(myFile.name)) {
      toast.error(
        "Please upload an Excel file with the extensions 'xlsx' or 'xls'."
      );
    } else {
      const data = await myFile?.arrayBuffer();
      const mySheetData = readDataFromExcel(data);

      const Headers = mySheetData.Sheet1[0];

      // const result = mySheetData.Sheet1.slice(1).map((row: any) => {
      //   const obj: { [key: string]: string | number | boolean } = {};
      //   Headers.forEach((header: any, index: any) => {
      //     if (row[index] === undefined) {
      //       obj[header] = "";
      //     } else if (header === "Date Of Birth(DD-MM-YYYY)(required)") {
      //       obj[header] = convertExcelDateToReadableDate(row[index]);
      //     } else if (header === "Qualifying Timing(required)") {
      //       obj[header] = convertExcelTime(row[index]);
      //     } else if (header === "Gender(required)") {
      //       obj[header] = capitalizeFirstLetter(row[index]);
      //     } else if (header === "T Shirt Size(required)") {
      //       obj[header] = toUpperCase(row[index]);
      //     } else if (header === "Mobile Number(required)") {
      //       obj[header] = validateMobileNumber(row[index]);
      //     } else if (header === "Pincode(required)") {
      //       obj[header] = validatePincode(row[index]);
      //     } else {
      //       obj[header] = row[index];
      //     }
      //   });
      //   return obj;
      // });
      const result = mySheetData.Sheet1.slice(1).map((row: any) => {
        const obj: { [key: string]: string | number | boolean } = {};
        let isValid = true; // Flag to track validation status

        Headers.forEach((header: any, index: any) => {
          if (row[index] === undefined) {
            obj[header] = "";
          } else if (header === "Date Of Birth(DD-MM-YYYY)(required)") {
            obj[header] = convertExcelDateToReadableDate(row[index]);
          } else if (header === "Qualifying Timing(required)") {
            obj[header] = convertExcelTime(row[index]);
          } else if (header === "Gender(required)") {
            obj[header] = capitalizeFirstLetter(row[index]);
          } else if (header === "T Shirt Size(required)") {
            obj[header] = toUpperCase(row[index]);
          } else if (header === "Mobile Number(required)") {
            if (!validateMobileNumber(row[index])) {
              isValid = false;
            }
            obj[header] = row[index];
          } else if (header === "Qualifying Timing(required)") {
            if (!ValidateHHMMSSFormat(row[index])) {
              isValid = false;
            }
            obj[header] = row[index];
          } else if (header === "Pincode(required)") {
            if (!validatePincode(row[index])) {
              isValid = false;
            }
            obj[header] = row[index];
          } else {
            obj[header] = row[index];
          }
        });

        // Check if any validation failed
        if (!isValid) {
          return null; // Return null to indicate that there were validation errors
        }

        return obj;
      });

      const updatedResult = result.map((part: any) => {
        const { RaceName, ...rest } = part;
        return RaceName;
      });

      setUpdateRaceName(updatedResult[0]);

      const postData = {
        eventId: eventId || storeEventId,
        raceName: updatedResult[0],
        data: result,
      };
      if (fileRef.current) {
        fileRef.current.value = "";
      }

      setIsLoading(true);
      axiosPrivate({
        url: "/verifyOfflineData",
        data: postData,
        method: "POST",
      })
        .then((res) => {
          const { status, data, message, isValidate } = res.data;
          if (status) {
            if (data.length === 0) {
              toast.success(`${message}`);
            } else if (!isValidate) {
              setExcelData(data.data);
              setIsTableDisplay(true);
            }
          } else {
            toast.error(`${message}`);
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  };

  const convertExcelTime = (excelTime: any) => {
    const totalSeconds = excelTime * 86400; // 86400 seconds in a day
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { data } = res.data;
        const racesList: any =
          data && data?.filter((data: any) => data.raceId !== "0");
        setIsSelect(racesList);
      })
      .catch((err) => {
        return;
      });
  }

  const raceName =
    isSelect &&
    isSelect?.filter((item: any) => item.raceId === parseInt(SelectRaceId));

  useEffect(() => {
    const isSelected = SelectRaceId === "" ? true : false;
    if (isSelected) {
      CouponRaceList();
    }
  }, [isHeader]);

  const exportHeadersToExcel = () => {
    const data = [isHeader];

    // Create a worksheet from the 2D data array
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Create a workbook
    const wb = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [[`${raceName[0]?.name}`]], {
      origin: -1,
    });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Save the Excel file using FileSaver
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = `offline_registration_excel_for_${raceName[0]?.name}`;
    const fileExtension = ".xlsx";

    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, fileName + fileExtension);
  };

  const handleSelectOption = (e: any) => {
    setSelectRaceId(e.target.value);
    setIsTableDisplay(false);
    setExcelData([]);
    axiosPrivate({
      url: "/raceSampleExcel",
      data: {
        eventId: eventId || storeEventId,
        raceId: e.target.value,
      },
      method: "POST",
    })
      .then((res: any) => {
        if (res.data.status && res.data.data.length > 0) {
          setIsDisplay(true);
          const excelHeading = [res.data.data][0];
          const FinalHeading: any = ["RaceName", ...excelHeading];
          setIsHeader(FinalHeading);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        return;
      });
  };

  const formatHeader = (key: string): string => {
    const formattedString = key.replace(/_/g, " ");
    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    dataIndex: number,
    key: string
  ) => {
    const updatedData: any = [...excelData];
    updatedData[dataIndex][key] = e.target.value;
    setExcelData(updatedData);
  };

  const shouldHaveRedBorder = (dataIndex: number, key: string): boolean => {
    const validations: any = excelData[dataIndex].validations;
    return validations && !validations[key];
  };

  const handleTableSubmit = () => {
    setIsLoading(true);
    const postData = {
      eventId: eventId || storeEventId,
      raceName: updateRaceName,
      data: excelData,
    };

    axiosPrivate({
      url: "/verifyOfflineData",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data, message, isValidate } = res.data;

        if (status) {
          if (!isValidate) {
            setExcelData(data.data);
            setIsTableDisplay(true);
          } else if (status && data.length === 0) {
            setIsTableDisplay(false);
            toast.success(`${message}`);
          }
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  // const handleSelectTab = (selectedTab: any) => {
  //   setActiveTab(selectedTab);
  // };
  return (
    <>
      {isLoading && <Loader />}

      <Container>
        <Row className="align-items-center">
          <Col>
            <h5 className="my-3 fw-bold">Offline Registration</h5>
          </Col>
        </Row>
        <Row className="my-2 d-flex align-items-center">
          <Col xs={12} md={5}>
            <div>
              <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                <div className="col">
                  <select onChange={handleSelectOption}>
                    <option value="">Choose an option</option>
                    {isSelect?.map((option: any) => {
                      return (
                        <>
                          <option
                            key={option.raceId}
                            value={option.raceId}
                            disabled={option.status}
                            onChange={option.raceId}
                          >
                            {option.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span>Please select the race *</span>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={4}>
            {isDisplay && (
              <button
                type="button"
                className="px-md-5 btn btn-link"
                onClick={() => exportHeadersToExcel()}
              >
                Download sample excel
              </button>
            )}
          </Col>
        </Row>
        <Formik
          initialValues={{
            uploadExcel: null,
          }}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="py-md-3 px-md-0"
            >
              <Row className="py-2 px-md-0 mx-0">
                <Col className="col-md-8 px-md-0 mx-0">
                  <label className="py-md-2 fw-600">Upload Excel File</label>
                  <div>
                    <Field name="uploadExcel">
                      {({
                        field,
                        form,
                      }: {
                        field: FieldInputProps<File | null>;
                        form: any;
                      }) => (
                        <input
                          className="uploadExcel border-0 w-100"
                          type="file"
                          id="uploadExcel"
                          name="uploadExcel"
                          accept="xlsx, xls"
                          onChange={(event: any) => {
                            const file = event.currentTarget.files?.[0] || null;
                            setFieldValue("uploadExcel", file);
                            form.setFieldTouched("uploadExcel", true);
                          }}
                          ref={fileRef}
                        />
                      )}
                    </Field>
                  </div>
                </Col>
              </Row>
              <div className="mt-md-4">
                <DefaultButton
                  type="submit"
                  variant="primary"
                  className="px-md-5"
                >
                  Upload Excel
                </DefaultButton>
              </div>
            </Form>
          )}
        </Formik>
        {isTableDisplay && (
          <div>
            <Table style={{ width: "2500px" }} responsive>
              <thead>
                <tr>
                  {excelData &&
                    excelData?.length > 0 &&
                    Object.keys(excelData[0]).map((key, index) =>
                      index !== Object.keys(excelData[0]).length - 1 ? (
                        <th key={index}>{formatHeader(key)}</th>
                      ) : null
                    )}
                </tr>
              </thead>
              <tbody>
                {excelData &&
                  excelData?.map((data: any, dataIndex: any) => (
                    <tr key={dataIndex}>
                      {Object.entries(data)
                        .map(([key, value]: any, index) => {
                          const isValidationKey = key === "validations";
                          // const isInvalidField =
                          //   isValidationKey &&
                          //   Object.values(value as Record<string, boolean>).some(
                          //     (isValid: boolean) => !isValid
                          //   );

                          const isFirstCell = index === 0;
                          const inputStyle = isFirstCell
                            ? {}
                            : shouldHaveRedBorder(dataIndex, key) &&
                              data.validations &&
                              data.validations.hasOwnProperty(key)
                            ? { border: "2px solid #f0877f", width: "150px" }
                            : { width: "150px" };

                          return index !== Object.values(data).length - 1 ? (
                            <td key={index}>
                              {/* Render input fields for editable columns */}
                              {typeof value !== "object" ? (
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control"
                                  value={value}
                                  onChange={(e) =>
                                    handleInputChange(e, dataIndex, key)
                                  }
                                  style={inputStyle}
                                  // disabled={isFirstCell}
                                />
                              ) : (
                                JSON.stringify(value)
                              )}
                            </td>
                          ) : null;
                        })
                        .filter(Boolean)}
                    </tr>
                  ))}
              </tbody>
            </Table>
            {!isViewEvent && (
              <div className="mt-md-4">
                <DefaultButton
                  type="button"
                  variant="primary"
                  className="px-md-5"
                  onClick={handleTableSubmit}
                >
                  Submit
                </DefaultButton>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default OfflineRegistrationTabScreen;
