import { Row, Col, Image } from "react-bootstrap";

const Partners = () => {
  return (
    <div className="pb-5 ">
      <div className="py-4">
        <span className="fs-24 fw-600 ff-saira ">Pleasure to partner with</span>
      </div>
      <Row className="d-flex justify-content-around">
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/mrf.png" alt="mrf" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/sbi.png" alt="SBI" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/puma.png" alt="puma" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/parle.png" alt="parle" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/boi.png" alt="BOI" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4 overflow-hidden"
        >
          <Image src="/images/airtel.png" alt="airtel" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/herbspro.png" alt="herbspro" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/BP.png" alt="BP" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/phonepe.png" alt="phonepe" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/wipro.png" alt="wipro" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/jio.png" alt="jio" />
        </Col>
        <Col
          xs={6}
          md={4}
          lg={3}
          className="d-flex justify-content-center align-items-center my-4"
        >
          <Image src="/images/airIndia.png" alt="airIndia" />
        </Col>
      </Row>
    </div>
  );
};

export default Partners;
