import React, { useState, useEffect } from "react";

interface OtpCountDownProps {
  onResendOtpHandler: () => Promise<boolean>;
}

const OtpCountDown: React.FC<OtpCountDownProps> = ({ onResendOtpHandler }) => {
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleResendOtp = async () => {
    try {
      const retryResendOtp = await Promise.resolve(onResendOtpHandler());
      if (retryResendOtp) {
        setSeconds(30);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="d-flex justify-content-between">
      {seconds > 0 ? (
        <p className="mb-0 fs-14 font-saira">
          Resend Otp in {`${seconds}`} seconds
        </p>
      ) : (
        <p className="mb-0 fs-14 font-saira">Didn't recieve OTP?</p>
      )}

      <button
        disabled={seconds > 0}
        onClick={handleResendOtp}
        className={`text-center fs-14 text-primary border-0 bg-light p-0 text-decoration-underline`}
      >
        Resend OTP
      </button>
    </div>
  );
};

export default React.memo(OtpCountDown);
