import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router-dom";
import { useEventDashboard } from "utils/useCreateEvent";
import { decryptText } from "utils/utils";
import EarlyBirdDiscount from "OrganizerPages/coupons/EarlyBirdDiscount";
import PromotionCode from "OrganizerPages/coupons/PromotionCode";
import ClientSpecificDisCount from "OrganizerPages/coupons/ClientSpecificDisCount";
import BulkDiscount from "OrganizerPages/coupons/BulkDiscount";

const CreateCouponScreen = ({ setIntialScreen, handleReloadScreen }: any) => {
  const [isCouponTypeData, setIsCouponTypeData] = useState<any>();
  const [isCouponType, setIsCouponType] = useState("");
  const [Id, setId] = useState<String>();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();
  const { id } = useParams();
  const isEventDashboard = useEventDashboard();

  function discountList() {
    setIsLoading(true);
    axios
      .post(
        "/discountList",
        {
          eventId: decryptText(String(id)),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        let arrayData: { name: any; status: boolean }[] = [];
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.discount_name,
            status: !items.status,
          });
        });
        setIsCouponTypeData(arrayData);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    discountList();
  }, [id]);
  return (
    <div>
      <Row className="my-2">
        <Col xs={12} md={5}>
          <div>
            <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
              <div className="col">
                <select
                  onChange={(e) => {
                    // setType(e.target.value);
                    setIsCouponType(e.target.value);
                  }}
                  required
                >
                  <option value=""></option>
                  {isCouponTypeData?.map((option: any) => {
                    return (
                      <>
                        <option
                          key={option.id}
                          value={option.id}
                          disabled={option.status}
                          onChange={option.id}
                        >
                          {option.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span>Please select the type of dicount/coupon *</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isCouponType === "Early Bird Discount" && (
        <EarlyBirdDiscount
          setIntialScreen={setIntialScreen}
          handleReloadScreen={handleReloadScreen}
        />
      )}

      {isCouponType === "Promotion Codes" && (
        <PromotionCode
          setIntialScreen={setIntialScreen}
          handleReloadScreen={handleReloadScreen}
        />
      )}

      {isCouponType === "Client Specific Discount" && (
        <ClientSpecificDisCount
          setIntialScreen={setIntialScreen}
          handleReloadScreen={handleReloadScreen}
        />
      )}

      {isCouponType === "Bulk Discount" && (
        <BulkDiscount
          setIntialScreen={setIntialScreen}
          handleReloadScreen={handleReloadScreen}
        />
      )}
    </div>
  );
};

export default CreateCouponScreen;
