import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface OurTeamCardProps {
  teamData: {
    id: number;
    title: string;
    description: string;
    designation: string;
    imageUrl: string;
  };
}

const OurTeamCard: React.FC<OurTeamCardProps> = ({ teamData }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="mb-4 border-0 shadow"
      style={{
        width: "348px",
        height: "475px",
        flexShrink: "0",
        cursor: "pointer",
      }}
      position-relative
      // onClick={() => navigate("/")}
    >
      <Card.Img
        variant="top"
        src={teamData.imageUrl}
        style={{ width: "348px", height: "251px", flexShrink: "0" }}
      />

      <Card.Body className="d-flex flex-column">
        <Card.Title>
          <span className="fs-20 ff-saira text-blue">{teamData.title}</span>
        </Card.Title>
        <span className="fs-16 ff-saira fw-400 gray500">
          {teamData.designation}{" "}
        </span>

        <Card.Text className="fs-14 ff-saira fw-400 gray500 mt-4">
          {teamData.description}{" "}
        </Card.Text>
        <div style={{ zIndex: "1", bottom: "20px", position: "absolute" }}>
          <Image
            src="/images/linkedin.png"
            alt="Linkedin"
            style={{ height: "20px", width: "20px" }}
          />
          <Image
            src="/images/fb.svg"
            className="mx-3"
            alt="download icon"
            style={{ height: "20px", width: "20px" }}
          />
          <Image
            src="/images/sms.svg"
            alt="download icon"
            style={{ height: "20px", width: "20px" }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default OurTeamCard;
