import { Col, Container, Image, Row } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import styles from "./MyWishlist.module.scss";

const MyWishlist = () => {
  return (
    <>
      <Container className="d-flex flex-column align-items-center pb-5">
        <span className={`${styles.title} pt-5`}>Hey!</span>
        <span className={`${styles.title} pb-5`}>Welcome to iFinish</span>
        <Image
          style={{ height: "202px", width: "199px" }}
          src="/images/wishlist-image.png"
          alt="wishlist image"
        />
        <span className={`${styles.subTitle} pt-4`}>No favorites</span>
        <span className="fs-14 pt-2 pb-4">
          You have nothing on your list yet. It's never too late to change it :)
        </span>
        <DefaultButton
          variant="primary"
          className="text-white customBtn-width"
          type="button"
        >
          Explore
          <Icon iconName="ChevronRight" size={16} />
        </DefaultButton>
      </Container>
      <Container
        fluid
        className="d-none d-lg-flex justify-content-center align-items-center g-0"
        style={{
          height: "341px",
          width: "100vw",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          paddingTop: "40px",
        }}
      >
        <div
          className="bg-deepAsh d-none d-md-block"
          style={{ height: "341px", width: "512px" }}
        >
          <Image
            src="/images/wishlist-puzzle.png"
            alt="hand"
            style={{ height: "341px", width: "512px" }}
          />
        </div>
        <div className="d-none d-md-flex justify-content-center align-items-center position-relative w-100">
          <div
            style={{
              height: "341px",
              width: "100%",
              background: "#4f4f4f",
            }}
          ></div>
          <div className="position-absolute" style={{ left: "140px" }}>
            <span className="fs-40 fw-700 text-white">List your Show</span>
            <div className="pt-3">
              <span className="fs-24 fw-600 ff-saira text-white">
                Got a show, event, activity or a great experience? Partner with
                us & get listed on iFinish
              </span>
              <br />
              <span className="fs-14 fw-400 ff-ns text-white">
                The #1 community for all looking to share, learn & grow. {""}
                <u>Learn More</u>
              </span>
            </div>

            <div className="pt-3 w-75">
              <Row>
                <Col sm={12} md={6}>
                  <DefaultButton
                    onClick={() => console.log("Button Alert")}
                    className="default-action-button text-white"
                    variant="primary"
                    type="button"
                  >
                    PARTNER WITH IFINISH
                  </DefaultButton>
                </Col>
                <Col sm={12} md={6}>
                  <DefaultButton
                    onClick={() => console.log("Button Alert")}
                    className="default-action-button "
                    variant="outline-light"
                    type="button"
                  >
                    JION THE COMMUNITY
                  </DefaultButton>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
      <Container
        fluid
        className="d-flex flex-column d-lg-none  justify-content-center align-items-center g-0"
        style={{
          width: "100vw",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          background: "#E0E0E0",
        }}
      >
        <div className={`${styles.handImageSize} bg-deepAsh`}>
          <Image
            src="/images/wishlist-puzzle.png"
            alt="hand"
            className={`${styles.handImageSize}`}
          />
        </div>

        <div
          className="w-100 d-flex justify-content-center align-items-center position-relative"
          style={{ height: "341px" }}
        >
          <div
            style={{
              height: "341px",
              width: "100%",
              background: "#4f4f4f",
            }}
          ></div>
          <div className="position-absolute p-4">
            <span className="fs-24 fw-700 ff-saira text-white">
              List your Show
            </span>
            <div className="pt-3">
              <span className="fs-18 fw-500 ff-saira text-white">
                Got a show, event, activity or a great experience? Partner with
                us & get listed on iFinish
              </span>
              <br />
              <span className="fs-14 fw-400 ff-ns text-white">
                The #1 community for all looking to share, learn & grow. {""}
                <u>Learn More</u>
              </span>
            </div>

            <div className="pt-3">
              <div className="pb-3">
                <DefaultButton
                  onClick={() => console.log("Button Alert")}
                  className="default-action-button text-white w-100"
                  variant="primary"
                  type="button"
                >
                  PARTNER WITH IFINISH
                </DefaultButton>
              </div>
              <div>
                <DefaultButton
                  onClick={() => console.log("Button Alert")}
                  className="default-action-button w-100"
                  variant="outline-light"
                  type="button"
                >
                  JION THE COMMUNITY
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center g-0"
        style={{
          width: "100vw",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          background: "#E0E0E0",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center py-4 px-2"
          style={{ maxWidth: "1120px" }}
        >
          <span className={`${styles.privacyText} ff-ns text-darkGray`}>
            Privacy Note <br /> By using www.ifinish.com(our website), you are
            fully accepting the Privacy Policy available at
            https://ifinish.com/privacy governing your access to ifinish and
            provision of services by ifinish to you. If you do not accept terms
            mentioned in the Privacy Policy, you must not share any of your
            personal information and immediately exit ifinish.
          </span>
        </div>
      </Container>
    </>
  );
};

export default MyWishlist;
