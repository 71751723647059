import React, { useContext, MouseEvent, ChangeEvent, useState } from "react";
import CartContext from "store/cart/cartContext";
import { Button, Form } from "react-bootstrap";
import styles from "./AddToCartButton.module.scss";
import { Race } from "types/Race";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { useCategoryChange } from "api/EventCategoryChange";
import OffCanvas from "components/OffCanvas/OffCanvas";
import ChangeCategoryPayment from "components/ChangeCategory/ChangeCategoryPayment";
import { toast } from "react-toastify";
import { CategoryPayment } from "types/CategoryPayment";
import { hasExpired } from "utils/utils";

interface Props {
  serverTime: string;
  eventInfo: Race;
  variant: string;
  disabled?: boolean;
  isEarlyBirdPrice: boolean;
  isFromUpdateDetails?: boolean;
  participantId?: number;
}

const AddToCartButton: React.FC<Props> = ({
  serverTime,
  eventInfo,
  variant,
  disabled,
  isEarlyBirdPrice,
  isFromUpdateDetails,
  participantId,
}) => {
  const { addItem, addItemSelect, removeItem, items } = useContext(CartContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isOpenCategoryChangePayment, setIsOpenCategoryChangePayment] =
    useState(false);
  const [changePaymentData, setChangePaymentData] = useState<CategoryPayment>({
    amount: 0,
    processingfee: 0,
    gstprocessingfee: 0,
    totalamount: 0,
    gst: 0,
    gstType: 0,
    disclaimer: "",
    raceName: "",
    participantName: "",
  });

  const individualItem: any = items.filter(
    (item: any) => item?.id === eventInfo.id
  );
  const [updatingRaceId, setUpdatingRaceId] = useState(0);

  const ChangeEventCategoryMutation = useCategoryChange();
  const quantity: number = individualItem ? individualItem[0]?.itemAmount : 0;
  const total: number = individualItem ? individualItem[0]?.itemTotal : 0;

  const cartItemAddHandler = (
    event: MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    const itemCost = isEarlyBirdPrice
      ? item?.early_bird_discount_amount
      : item?.amount;

    addItem({ ...item, itemAmount: 1, itemTotal: itemCost, isEarlyBirdPrice });
    event.stopPropagation();
  };

  const cartItemRemoveHandler = (
    event: MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    removeItem(id);
    event.stopPropagation();
  };

  const cartSelectItemAddHandler = (
    event: ChangeEvent<HTMLSelectElement>,
    item: any
  ) => {
    addItemSelect({
      ...item,
      itemAmount: +event.target.value,
      itemTotal: item.amount,
      isEarlyBirdPrice,
    });

    event.stopPropagation();
  };

  const preventOpenIndividualEventDetails = (
    event: MouseEvent<HTMLSelectElement>
  ) => {
    event.stopPropagation();
  };

  const onAddingRace = (event: any, eventInfo: any) => {
    if (isFromUpdateDetails) {
      setShowConfirmModal(true);
      setUpdatingRaceId(eventInfo?.id);
    } else {
      cartItemAddHandler(event, eventInfo);
    }
  };

  const handleModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmation = async () => {
    const response = await ChangeEventCategoryMutation.mutateAsync({
      participantId: participantId,
      raceId: updatingRaceId,
    });
    if (response?.status) {
      if (!response?.isPayment) {
        toast.success(`${response.message}`);
      } else {
        setChangePaymentData(response?.data);
        setIsOpenCategoryChangePayment(true);
      }
    } else {
      toast.error(`${response?.message}`);
    }

    setShowConfirmModal(false);
  };

  return (
    <>
      <div
        className={`d-flex ${
          variant === "filled"
            ? "flex-column align-items-center"
            : "justify-content-between align-items-end"
        }`}
      >
        {quantity > 0 ? (
          <div
            className={`${styles.addToCartButton} ${
              variant === "filled" ? "mb-2" : "mb-0"
            }`}
          >
            <Button
              onClick={(event) => cartItemRemoveHandler(event, eventInfo.id)}
              className={`${styles.btnCommon} ${
                variant === "filled"
                  ? styles.decreaseFilled
                  : styles.decreaseOutlined
              }`}
            >
              <img
                width={12}
                src={
                  variant === "filled"
                    ? "/images/minus-filled.svg"
                    : "/images/minus-outlined.svg"
                }
                alt="minus"
              />
            </Button>
            <Form.Select
              className="form-select"
              bsPrefix={`${styles.qtySelect}`}
              value={quantity}
              aria-label="Qty"
              onChange={(event) => cartSelectItemAddHandler(event, eventInfo)}
              onClick={preventOpenIndividualEventDetails}
            >
              {[1, 2, 3, 4, 5, 6, 7].map((option) => (
                <option key={option} className="cursor-pointer" value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <Button
              disabled={quantity === 7}
              onClick={(event) => {
                if (quantity === 7) {
                  event.stopPropagation();
                } else {
                  cartItemAddHandler(event, eventInfo);
                }
              }}
              className={`${styles.btnCommon} ${
                variant === "filled"
                  ? styles.increaseFilled
                  : styles.increaseOutlined
              }`}
            >
              <img
                width={12}
                src={
                  variant === "filled"
                    ? "/images/plus-filled.svg"
                    : "/images/plus-outlined.svg"
                }
                alt="plus"
              />
            </Button>
          </div>
        ) : (
          <button
            onClick={(event) => onAddingRace(event, eventInfo)}
            className={`${
              disabled ? styles.disabledCartButton : styles.initialCartButton
            } ${variant === "filled" ? "mb-2" : "mb-0"} fs-14 font-noto`}
            disabled={disabled}
          >
            {isFromUpdateDetails ? "Select Category" : "add"}
          </button>
        )}
        <div className={`${isFromUpdateDetails ? "d-none" : ""}`}>
          {disabled &&
          hasExpired(eventInfo?.registration_end_date, serverTime) ? (
            <p className={`text-primary mb-0 fs-14 font-saira`}>
              Registrations are closed
            </p>
          ) : variant === "filled" ? (
            <p className={`${styles.colorGrey} mb-0 font-saira`}>
              Total -{" "}
              <span className="fw-600" style={{ marginRight: "0.2rem" }}>
                &#8377; {total ? total : 0}
              </span>
            </p>
          ) : (
            <div className={`${styles.colorGrey} mb-0 font-noto fs-14`}>
              <p className="mb-0 font-noto ms-3 fs-14">
                Total Amount:
                <span
                  className="fw-600 fs-24 ms-1"
                  style={{ marginRight: "0.2rem", color: "black" }}
                >
                  &#8377;
                </span>
                <span className="fw-600 fs-24" style={{ color: "black" }}>
                  {total ? total : 0}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <OffCanvas
        showOffCanvasModal={isOpenCategoryChangePayment}
        handleCloseOffCanvasModal={() => setIsOpenCategoryChangePayment(false)}
      >
        <ChangeCategoryPayment
          amount={changePaymentData.amount}
          processingfee={changePaymentData.processingfee}
          gstprocessingfee={changePaymentData.gstprocessingfee}
          totalamount={changePaymentData.totalamount}
          gst={changePaymentData.gst}
          gstType={changePaymentData.gstType}
          disclaimer={changePaymentData.disclaimer}
          raceName={changePaymentData.raceName}
          participantName={changePaymentData.participantName}
          participantId={participantId}
          handleClosePaymentModal={() => setIsOpenCategoryChangePayment(false)}
        />
      </OffCanvas>
      <ConfirmModal
        title="Are you certain you want to change the category?"
        note="Note: Category can only be changed once per booking."
        showNote={true}
        show={showConfirmModal}
        handleClose={handleModalClose}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default AddToCartButton;
