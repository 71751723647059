import { useLayoutEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Breadcrumb,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import styles from "./TermsAndCondition.module.scss";

interface TermsAndConditionProps {
  showIsOrganiserTermsAndConditions?: boolean;
  organiserTermsAndConditions?: string;
}

const TermsAndCondition = ({
  showIsOrganiserTermsAndConditions = false,
  organiserTermsAndConditions,
}: TermsAndConditionProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showTermsBreadCrumb, setShowTermsBreadCrumb] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (location.pathname.includes("/termsandcondition")) {
      setShowTermsBreadCrumb(true);
    } else {
      setShowTermsBreadCrumb(false);
    }
  }, []);

  return (
    <>
      {showTermsBreadCrumb && (
        <Container>
          <Breadcrumb className="mt-4 mb-2">
            <Breadcrumb.Item
              className="fs-14 fs-saira"
              href="#"
              onClick={() => navigate("/")}
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active> Terms and Conditions</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      )}

      <Container className="px-0 pb-4">
        <div className={`${styles.termsBanner}`}>
          <div className="position-relative">
            <Image
              className={`${styles.coverImage} position-relative`}
              src="/images/termsandconditions.svg"
              alt="TermsBanner"
            />
            <div className={`${styles.termsContentOverImage}`}>
              <div className="d-flex flex-column mx-4">
                <h1 className="blockTitle text-white">
                  {showIsOrganiserTermsAndConditions ? "Organiser" : "IFinish"}{" "}
                  Terms and Conditions
                </h1>
                <p className="text-white ff-saira fs-14">
                  Please read the following terms and conditions carefully
                  before participating in our events.
                </p>
              </div>
            </div>
          </div>
          {!showIsOrganiserTermsAndConditions && (
            <div className={`${styles.discover} px-3 py-4`}>
              <h6 className="text-dark fw-bold fs-14 ff-saira">
                Discover our latest offers and updates
              </h6>
              <p className="fs-14 ff-ns text-dark mb-0">
                Stay informed with our timely information and promotions
              </p>
            </div>
          )}
        </div>
        <div className="px-3 px-sm-0">
          {!showIsOrganiserTermsAndConditions ? (
            <Tabs
              defaultActiveKey={"privacyPolicy"}
              id="event-teams-tabs"
              className="event-teams-tabs my-3"
            >
              <Tab eventKey="privacyPolicy" title="Privacy Policy">
                <h4>Privacy Policy</h4>
                <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                  This privacy policy sets out how iFinish uses and protects any
                  information that you give when you use this website. iFinish
                  is committed to ensure that your privacy is protected. Should
                  we ask you to provide certain information by which you can be
                  identified when using this website, then you can be assured
                  that it will only be used in accordance with this privacy
                  statement of iFinish. iFinish might change the privacy policy
                  any time in the future, so kindly do check it regularly.
                </p>
              </Tab>
              <Tab eventKey="terms&Conditions" title="Terms & Conditions">
                <h4>Terms & Conditions</h4>
                <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                  This privacy policy sets out how iFinish uses and protects any
                  information that you give when you use this website. iFinish
                  is committed to ensure that your privacy is protected. Should
                  we ask you to provide certain information by which you can be
                  identified when using this website, then you can be assured
                  that it will only be used in accordance with this privacy
                  statement of iFinish. iFinish might change the privacy policy
                  any time in the future, so kindly do check it regularly.
                </p>
              </Tab>
              <Tab eventKey="refund" title="Refund">
                <h4>Refund</h4>
                <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                  We collect no personally identifiable information about you
                  when you visit our site unless you choose to provide that
                  information to us. If any found they are not by willingly
                  copied nor posted by other users of our support team. Please
                  contact us if any identifiable informaton that morely
                  correlates your information and need to be removed from
                  website. The information provided does not try to boost our
                  products sales against the similar poducts available in the
                  market. The product information posted on this webiste does
                  boost the development, support and service of our products
                  only. For the protection of users of our Web site, we have
                  safeguards in place to identify and prevent unauthorized
                  attempts to access or cause harm to information and systems.
                  Our web site, the type of browser and operating system you are
                  using, the date and time you visit, the pages and files your
                  request, and the last site you visited (if that site contained
                  a link to any web page). We use this information to determine
                  the level of traffic through the server and the level of
                  interest in the individual files available on the server. None
                  of this information identifies you personally, and we do not
                  give, sell, or transfer any of this information to a third
                  party. The logs may be preserved indefinitely, and they may be
                  used to prevent security breaches and to ensure the integrity
                  of the data on our servers.
                </p>
              </Tab>
            </Tabs>
          ) : (
            <div className="my-3">
              <div className={styles.organiserTerms}>
                {parse(`${organiserTermsAndConditions}`)}
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default TermsAndCondition;
