import { Button, Col, Container, Image, Row } from "react-bootstrap";
import VirtualEventCard from "components/Cards/VirtualEventCard";
import { VirtualEvent } from "types/VirtualEvent";

import useBootstrapSize from "utils/useBootstrapSize";

type VirtualRaceProps = {
  currentVirtualEvents: VirtualEvent[];
  handleVirtualPrev: () => void;
  handleVirtualNext: () => void;
};

const VirtualRace: React.FC<VirtualRaceProps> = ({
  currentVirtualEvents,
  handleVirtualPrev,
  handleVirtualNext,
}) => {
  const pageSize = useBootstrapSize();
  return (
    <Container className={`pt-5`}>
      <Row className="pb-3">
        <Col md={10}>
          <span className="blockTitle">Discover Exciting Virtual Races</span>
        </Col>
        <Col
          md={2}
          className="d-none d-md-flex justify-content-end align-items-center px-0"
        >
          <Button variant="" onClick={handleVirtualPrev}>
            <Image src="/images/prev.png" alt="prev" />
          </Button>
          <Button variant="" onClick={handleVirtualNext}>
            <Image src="/images/next.png" slot="next" />
          </Button>
        </Col>
      </Row>

      <Row className="position-relative d-flex justify-content-center justify-content-sm-between">
        {currentVirtualEvents.map((event, index, array) => {
          let alignmentClass = "justify-content-center";
          if (index === 0 && pageSize !== "xs" && pageSize !== "sm") {
            alignmentClass = "justify-content-start";
          } else if (
            index === array.length - 1 &&
            pageSize !== "xs" &&
            pageSize !== "sm"
          ) {
            alignmentClass = "justify-content-end";
          }

          return (
            <Col
              md={4}
              key={event.id}
              className={`position-relative d-flex  align-items-center ${alignmentClass}`}
            >
              <VirtualEventCard event={event} />

              <div
                className="d-md-none position-absolute  w-100 d-flex justify-content-between"
                style={{ top: "40%", transform: "translateY(-50%)" }}
              >
                <Button variant="" onClick={handleVirtualPrev}>
                  <Image src="/images/prev.png" alt="prev" />
                </Button>
                <Button variant="" onClick={handleVirtualNext}>
                  <Image src="/images/next.png" alt="next" />
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default VirtualRace;
