import React, { useEffect, useState } from "react";
import OffCanvas from "components/OffCanvas/OffCanvas";
import { Col, Image, Row } from "react-bootstrap";
import styles from "./OrganizationProfile.module.scss";
import LoginForm from "components/Forms/LoginForm";
import SignupForm from "components/Forms/SignupForm";
import { Icon } from "components/Icon";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useNavigate } from "react-router-dom";
import OrganizationEditProfile from "./OrganizationEditProfile/OrganizationEditProfile";

interface Props {
  isOpenProfileModal: boolean;
  handleCloseProfileModal: () => void;
}

const Profile: React.FC<Props> = ({
  isOpenProfileModal,
  handleCloseProfileModal,
}) => {
  const [isSignedUp, setIsSignedUp] = useState(false);
  const handleSignupClickOnLogin = () => setIsSignedUp(true);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage?.getItem("isIfinishLoogedIn")
  );
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();

  const onNotficationClick = () => {
    console.log("onNotficationClick");
    handleCloseProfileModal();
  };
  const onMyEventClick = () => {
    navigate("/myevents");
    handleCloseProfileModal();
  };
  const onTrainingClick = () => {
    console.log("onTrainingClick");
    handleCloseProfileModal();
  };
  const onPaymentClick = () => {
    navigate("/mypayments");
    handleCloseProfileModal();
  };
  const onCharityClick = () => {
    console.log("onCharityClick");
    handleCloseProfileModal();
  };
  const onSettingClick = () => {
    console.log("onSettingClick");
    handleCloseProfileModal();
  };
  const onHelpClick = () => {
    console.log("onHelpClick");
    handleCloseProfileModal();
  };

  const profileMenu = [
    { title: "Notification", onclick: onNotficationClick },
    { title: "My Events", onclick: onMyEventClick },
    {
      title: "My Training",
      onclick: onTrainingClick,
    },
    { title: "Payments", onclick: onPaymentClick },
    { title: "Charity", onclick: onCharityClick },
    { title: "Account Setting", onclick: onSettingClick },
    { title: "Help & Support", onclick: onHelpClick },
  ];

  const handleSignuOut = () => {
    localStorage.setItem("isIfinishLoogedIn", "false");
    localStorage.removeItem("ifinishToken");
    window.location.reload();
  };

  const handleSignInClickOnSignup = () => {
    setIsSignedUp(false);
  };

  useEffect(() => {
    setIsLoggedIn(localStorage?.getItem("isIfinishLoogedIn"));
  }, []);
  const handleCanvasClose = () => {
    handleCloseProfileModal();
    setEditProfile(false);
  };
  return (
    <div>
      <OffCanvas
        showOffCanvasModal={isOpenProfileModal}
        handleCloseOffCanvasModal={handleCanvasClose}
        isProfile
      >
        {<OrganizationEditProfile />}
      </OffCanvas>
    </div>
  );
};

export default React.memo(Profile);
