import AppLayout from "./layouts/AppLayout";
import { Routes, Route } from "react-router-dom";
import EventsPage from "./pages/EventsPage";
import Dashboard from "pages/Dashboard";
import EventDetailsPage from "pages/EventDetailsPage/EventDetailsPage";
import ViewOnlyEventDetailsPage from "pages/ViewOnlyEventDetailsPage/ViewOnlyEventDetailsPage";
import MyEvents from "pages/MyEvent";
import MyPayments from "pages/MyPayments";
import AboutUS from "components/FooterComponents/About/About";
import ContactUS from "components/FooterComponents/ContactUS/ContactUs";
import TermsAndCondition from "components/FooterComponents/TermsandCondition/TermsAndCondition";
import Support from "components/FooterComponents/Support/Support";
import Photos from "components/FooterComponents/Photos/photos";
import JoinPhotoGraphy from "components/FooterComponents/JoinPhotoGraphy/JoinPhotoGraphy";
import OrganizerPage from "pages/OrganizerPage";
import OrgLoginPage from "OrganizerPages/OrgLoginPage";
import OrgDashBoardLayout from "layouts/OrgDashBoardLayout";
import OrgEventsPage from "OrganizerPages/OrgEventsPage";
import OrgCreateEvents from "OrganizerPages/OrgCreateEvents";
import OrgHomeScreen from "OrganizerPages/orgHomeScreen";
import OrgSingupPage from "OrganizerPages/OrgSignupPage";
import OrganizerAccountPage from "OrganizerPages/OrganizerAccountPage";
import AddOrgPage from "OrganizerPages/AddOrgPage";
import EventResultPage from "pages/EventResultPage/EventResultPage";
import VerifySuccessComponent from "pages/VerifySuccessComponent";
import UseNetwork from "utils/useNetwork";
import OfflinePage from "pages/OfflinePage";
import ErrorPage from "pages/ErrorPage";
import ForgotPassword from "pages/ForgotPassword";
import CouponDashboard from "OrganizerPages/roleBasedPages/couponAdmin/CouponDashboard";
import CreateCoupon from "OrganizerPages/roleBasedPages/couponAdmin/CreateCoupon";
import ViewerDashboard from "OrganizerPages/roleBasedPages/viewer/ViewerDashboard";
import ApproverDashboard from "OrganizerPages/roleBasedPages/approver/ApproverDashboard";
import CharityDashboard from "OrganizerPages/roleBasedPages/charityAdmin/CharityDashboard";
import NgosForm from "OrganizerPages/NgosForm";
import OrgSignUpFormCompany from "OrganizerPages/OrgSignUpFormCompany";
import PrivateRoutes from "utils/PrivateRoutes";
import ErrorRoutePage from "pages/ErrorRoutePage";
import ForbiddenPage from "pages/ForbiddenPage";
import CouponUserDashboard from "pages/CouponUserDashboard";
import IndividualCharityDashboard from "OrganizerPages/roleBasedPages/individualCharityDashboard/IndividualCharityDashboard";
import SupportLogin from "pages/supportpages/supportlogin/SupportLogin";
import SupportDashboardLayout from "layouts/SupportDashboardLayout";
import SupportDashboard from "pages/supportpages/supportdashboard/SupportDashboard";
import RefundsPage from "pages/supportpages/refundspage/RefundsPage";
import EliteDashboard from "OrganizerPages/roleBasedPages/eliterunner/EliteDashboard";
import SuperadminDashboard from "OrganizerPages/superadmin/SuperadminDashboard";
import SupportAdminLogin from "pages/superadminpages/superadmindashboard/SuperadminLogin";
import SuperadminDashboardLayout from "layouts/SuperAdminDashboardLayout";
import SupportRoleList from "pages/superadminpages/supportroleslist/SupportRoleList";
import SupportEventDashboard from "pages/superadminpages/supporteventdashboard/SupportEventDashboard";
import ExpoAdminLogin from "pages/expoadmin/expoadminlogin/ExpoAdminLogin";
import ExpoadminDashboardLayout from "layouts/ExpoAdminDashboardLayout";
import ExpoAdminDashboard from "OrganizerPages/expoadmin/ExpoAdminDashboard";
import ExpoAdminEventPage from "pages/expoadmin/expoadmineventpages/ExpoAdminEventPage";
import ExpoUserList from "pages/expoadmin/expouserlist/ExpoUserList";
import NmdcExpoBibCollection from "pages/expoadmin/expobibcollection/NmdcExpoBibCollection";
import ExpoRegistrationList from "pages/expoadmin/exporegistartionlist/ExpoRegistrationList";
import PublicRoutes from "utils/PublicRoutes";
import CompanyResults from "pages/CompanyResults";

const App = () => {
  const { online } = UseNetwork();

  let routes;
  routes = (
    <>
      <Route element={<PrivateRoutes />}>
        <Route path="/org/accounts" element={<OrganizerAccountPage />} />
        <Route path="/org" element={<OrgEventsPage />} />
        {/* <Route path="/org/createEvents/:id" element={<OrgCreateEvents />} /> */}
        <Route path="/org/editEvents/:id" element={<OrgCreateEvents />} />
        <Route path="/org/createEvents" element={<OrgCreateEvents />} />
        <Route path="/org/eventDashBoard/:id" element={<OrgHomeScreen />} />
        <Route path="/org/createCoupons/:id" element={<CreateCoupon />} />
        <Route path="/org/pastEventDashBoard/:id" element={<OrgHomeScreen />} />
        {/* <Route path="/org/viewEvents/:id" element={<OrgCreateEvents />} /> */}
        <Route path="/org/coupondashboard/:id" element={<CouponDashboard />} />
        <Route path="/org/viewevent/:id" element={<ViewerDashboard />} />
        <Route path="/org/vieweventdata/:id" element={<OrgCreateEvents />} />
        <Route path="/org/registration/:id" element={<ApproverDashboard />} />
        <Route
          path="/org/charitydashboard/:id"
          element={<CharityDashboard />}
        />
        <Route
          path="/org/charity/:id"
          element={<IndividualCharityDashboard />}
        />
        <Route path="/org/eliterunner/:id" element={<EliteDashboard />} />
        <Route path="/org/couponuser/:id" element={<CouponUserDashboard />} />
        <Route path="/org/corporateresults/:id" element={<CompanyResults />} />
        <Route path="/org/addorganisation" element={<AddOrgPage />} />
      </Route>
    </>
  );
  return (
    <>
      {online ? (
        <>
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/events" element={<EventsPage />} />
              <Route
                path="/eventdetails/:eventSlug"
                element={<EventDetailsPage />}
              />
              <Route
                path="/mybookings/:eventSlug/:bookingId"
                element={<ViewOnlyEventDetailsPage />}
              />
              <Route path="/myevents" element={<MyEvents />} />
              <Route path="/mypayments" element={<MyPayments />} />
              <Route path="/aboutus" element={<AboutUS />} />
              <Route path="/contactus" element={<ContactUS />} />
              <Route
                path="/termsandcondition"
                element={<TermsAndCondition />}
              />
              <Route path="/support" element={<Support />} />
              <Route path="/photos" element={<Photos />} />
              <Route path="/joinourphotography" element={<JoinPhotoGraphy />} />
              <Route path="/organizer" element={<OrganizerPage />} />
              <Route path="/eventresult" element={<EventResultPage />} />
            </Route>

            {/* {/ organizer routing /} */}

            {/* <>
              {isAuthenticated ? (
                <Route element={<OrgDashBoardLayout />}>{routes}</Route>
              ) : (
                <Route path="/org/login" element={<OrgLoginPage />} />
              )}
            </> */}

            {/* support role */}
            <Route element={<SupportDashboardLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/support/dashboard"
                  element={<SupportDashboard />}
                />
                <Route path="/refunds" element={<RefundsPage />} />
              </Route>
            </Route>

            {/* Super admin routes */}
            <Route element={<SuperadminDashboardLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/support/admindashboard"
                  element={<SuperadminDashboard />}
                />
                <Route path="/support/list" element={<SupportRoleList />} />
                <Route
                  path="/support/:id"
                  element={<SupportEventDashboard />}
                />
              </Route>
            </Route>

            {/* expo routes */}

            <Route element={<ExpoadminDashboardLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/support/expoadmindashboaord"
                  element={<ExpoAdminDashboard />}
                />
                <Route
                  path="/support/expoadminevent/:id"
                  element={<ExpoAdminEventPage />}
                />
                <Route
                  path="/support/registrationlist/:id"
                  element={<ExpoRegistrationList />}
                />

                <Route
                  path="/support/expouserlist/:id"
                  element={<ExpoUserList />}
                />
                <Route
                  path="/support/expobibcollection/:id"
                  element={<NmdcExpoBibCollection />}
                />
              </Route>
            </Route>
            <Route element={<PublicRoutes />}>
              {/* organizer Signup */}
              <Route path="/org/signup" element={<OrgSingupPage />} />
              <Route
                path="/org/signup/step2"
                element={<OrgSignUpFormCompany />}
              />
              {/* organizer Login */}
              <Route path="/org/login" element={<OrgLoginPage />} />{" "}
              {/* support login */}
              <Route path="/support/login" element={<SupportLogin />} />
              {/* superadmin login */}
              <Route
                path="/support/adminlogin"
                element={<SupportAdminLogin />}
              />
              {/* expoadmin login */}
              <Route path="/support/expologin" element={<ExpoAdminLogin />} />
              <Route path="/forgotpassword/:id" element={<ForgotPassword />} />
            </Route>

            <Route element={<OrgDashBoardLayout />}>{routes}</Route>
            <Route
              path="/verifyemail/:id"
              element={<VerifySuccessComponent />}
            />
            <Route path="/ngoregistration" element={<NgosForm />} />
            <Route path="/internalservererror" element={<ErrorRoutePage />} />
            <Route path="/forbidden" element={<ForbiddenPage />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </>
      ) : (
        <OfflinePage />
      )}
    </>
  );
};

export default App;
