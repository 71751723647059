import { useState, useMemo, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Breadcrumb,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import styles from "./EventsPage.module.scss";
import Badge from "components/Badge/Badge";
import { Icon } from "components/Icon";
import { useEventList } from "api/EventApi";
import { organizations } from "api/Organizations";
import OrganizationCard from "components/Cards/OrganizationCard";
import { useNavigate } from "react-router-dom";
import RecommendedEvents from "components/Dashboard/RecommendedEvents";
import useBootstrapSize from "utils/useBootstrapSize";
import MobileOrganizationComp from "components/EventsPage/MobileOrganizationComp";
import { Event } from "types/Event";
import { removeDuplicates, sortEventsByEndDate } from "utils/utils";
import Loader from "components/Loader/Loader";

const marathonFIlters = [
  "Location",
  "Race Type",
  "Organisers",
  "Popular",
  "Tags",
  "Date Range",
];
const EventsPage = () => {
  //🔥🔥 filter based on Organizer name and location are already implemented but commented out for time being 🔥🔥

  const navigation = useNavigate();
  const screenSize = useBootstrapSize();
  const [startIndex, setStartIndex] = useState(0);
  const [startSecondaryEventIndex, setStartSecondaryEventIndex] = useState(0);
  // const [organizationFilter, setOrganizationFilter] = useState("");
  // const [organizations, setOrganizations] = useState<string[]>([]);
  const [locationFilter, setLocationFilter] = useState("");
  const [locations, setLocations] = useState<string[]>([]);

  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs" || screenSize === "md"
      ? 1
      : screenSize === "lg"
      ? 2
      : 3;
  const { data: recommendedEvents, isLoading, isError } = useEventList();

  useEffect(() => {
    // const orgList = recommendedEvents?.data?.map((event: Event) => {
    //   return event.organizerName;
    // });
    // setOrganizations(removeDuplicates(orgList));

    const locationsList = recommendedEvents?.data?.map((event: Event) => {
      return event?.location;
    });
    setLocations(removeDuplicates(locationsList) as string[]);
  }, [recommendedEvents?.data]);

  const filteredEvents = useMemo(() => {
    let filteredList = recommendedEvents?.data || [];

    // if (organizationFilter) {
    //   filteredList = filteredList.filter(
    //     (event: Event) => event.organizerName === organizationFilter
    //   );
    // }

    if (locationFilter) {
      filteredList = filteredList.filter(
        (event: Event) => event.location === locationFilter
      );
    }

    return sortEventsByEndDate(filteredList);
  }, [
    recommendedEvents?.data,
    //  organizationFilter,
    locationFilter,
  ]);

  const sortedEvents = sortEventsByEndDate(filteredEvents);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };
  const handleNext = () => {
    if (startIndex + CHUNK_SIZE < sortedEvents?.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };

  const currentEvents: Event[] = Array.isArray(sortedEvents)
    ? sortedEvents?.slice(startIndex, startIndex + CHUNK_SIZE)
    : [];

  // Check if there are items to show for PrevIcon
  const showPrevItems = startIndex > 0;

  // Check if there are items to show for NextIcon
  const showNextItems = startIndex + CHUNK_SIZE < sortedEvents?.length;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }

  const handleSecondEventPrev = () => {
    if (startSecondaryEventIndex > 0) {
      setStartSecondaryEventIndex(startSecondaryEventIndex - CHUNK_SIZE);
    }
  };
  const handleSecondEventNext = () => {
    if (
      startSecondaryEventIndex + CHUNK_SIZE <
      recommendedEvents?.data?.length
    ) {
      setStartSecondaryEventIndex(startSecondaryEventIndex + CHUNK_SIZE);
    }
  };

  // Check if there are items to show for PrevIcon
  const showSecondaryPrevItems = startSecondaryEventIndex > 0;

  // Check if there are items to show for NextIcon
  const showSecondaryNextItems =
    startSecondaryEventIndex + CHUNK_SIZE < sortedEvents?.length;

  // Reversing this array to get different data from above component
  // as we are using same dummy data

  const currentSecondaryEvents = sortedEvents?.slice(
    startSecondaryEventIndex,
    startSecondaryEventIndex + CHUNK_SIZE
  );

  return (
    <>
      <Container className="pt-4 pb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Marathon</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.coverImage}`}>
          <Image
            src="/images/marathon-baner.png"
            width="100%"
            alt="MarathonBaner"
          />
        </div>
        <div className={`${styles.coverText} position-absolute  ms-5`}>
          <span
            className={`${styles.bannerTitle} fw-700 ff-saira text-primary text-deeped`}
          >
            Marathon
          </span>
          <br />
          <span
            className={`text-white ff-saira mt-3 ${styles.coverTextSubtitle}`}
          >
            Push yourself to the limit, because that's where the magic happens
          </span>
        </div>
      </Container>

      <div className={` ${styles.backgroundGray} `}>
        <Container className="default-space pt-5 d-flex justify-content-center align-items-center">
          <Row className={`w-md-75  mb-4`}>
            {/* <Col className="py-2">
              <DropdownButton
                id="dropdown-item-button"
                className="w-100 ff-saira border border-rounded bg-white"
                style={{
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                variant="white"
                title={`Locations: ${locationFilter ? locationFilter : "All"}`}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => setLocationFilter("")}
                >
                  All
                </Dropdown.Item>
                {locations.map((location: string, index: number) => (
                  <Dropdown.Item
                    key={index}
                    as="button"
                    onClick={() => setLocationFilter(location)}
                  >
                    {location}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col> */}
            {/* <Col className="py-2">
              <DropdownButton
                id="dropdown-item-button"
                className="w-100 ff-saira border border-rounded bg-white"
                style={{
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                variant="white"
                title={`Organizers: ${
                  organizationFilter ? organizationFilter : "All"
                }`}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => setOrganizationFilter("")}
                >
                  All
                </Dropdown.Item>
                {organizations.map((org: string, index: number) => (
                  <Dropdown.Item
                    key={index}
                    as="button"
                    onClick={() => setOrganizationFilter(org)}
                  >
                    {org}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col> */}
          </Row>
        </Container>

        <RecommendedEvents
          currentEvents={currentEvents}
          handlePrev={handlePrev}
          handleNext={handleNext}
          title="Events"
          showPrevItems={showPrevItems}
          showNextItems={showNextItems}
        />

        <Container>
          <div className="pb-4">
            <span className={`${styles.orgTitle}`}>Organizers to follow</span>
          </div>

          <Row className="d-none d-md-flex">
            {organizations.map((organization) => (
              <Col sm={3} className="" key={organization.id}>
                <OrganizationCard organization={organization} />
              </Col>
            ))}
          </Row>
          <div className="d-md-none">
            <MobileOrganizationComp />
          </div>
        </Container>
      </div>
    </>
  );
};

export default EventsPage;
