import React from "react";
import { Carousel, Image } from "react-bootstrap";

const MobileGallery = () => {
  return (
    <Carousel controls={false}>
      <Carousel.Item>
        <Image
          src="/images/galleryImage1.png"
          alt="shoe"
          style={{ width: "315px", height: "320px" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <div style={{ width: "315px", height: "320px" }}>
          <Image
            src="/images/galleryImage3.png"
            alt="shoe"
            style={{ height: "153px", width: "100%" }}
          />
          <Image
            src="/images/galleryImage4.png"
            alt="shoe"
            style={{ height: "158px", width: "328px", marginTop: "30px" }}
          />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <Image
          src="/images/galleryImage2.png"
          alt="shoe"
          style={{ width: "315px", height: "320px" }}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default MobileGallery;
