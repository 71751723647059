import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useRemoveCharity = () => {
  const axiosPrivate = useAxiosPrivate();

  return useMutation(async (params: any) => {
    const { id } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/removeCharity",
        data: {
          registrationId: id,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error while removing charity");
      throw error;
    }
  });
};
