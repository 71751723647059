import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useCategoryChange = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: any) => {
    const { participantId, raceId } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/eventCategoryChange",
        data: {
          participantId: participantId,
          raceId: raceId,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error changing event category, please try again!!");
    }
  });
};
