import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams, useLocation } from "react-router";
import { useAuth } from "context/AuthContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEvent } from "context/EventContext";
import { Icon } from "components/Icon";
import CouponForm from "./CouponForm";
import Loader from "components/Loader/Loader";
import moment from "moment";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const CouponIntialPage = ({ handleSubmitNextHandler }: any) => {
  const [intialScreen, setIntialScreen] = useState(false);
  const [isEventId, setIsEventId] = useState<number>();
  const [isRaceId, setIsRaceId] = useState<number>();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  // let isAddMode: any;
  let eventId: any;
  const { token } = useAuth();
  const { storeEventId } = useEvent();
  const [isCouponList, setIsCouponList] = useState<any>();
  const [isRaceList, setIsRaceList] = useState<any>([]);
  const [isRaceType, setIsRaceType] = useState("-1");
  const [isCouponAdmin, setIsCouponAdmin] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isViewEvent = ViewEvent();
  React.useLayoutEffect(function () {
    const pathName = location?.pathname.split("/");
    if (pathName.includes("createCoupons")) {
      setIsCouponAdmin(true);
    }
  }, []);
  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent]);

  function CouponRaceList() {
    // axios.post(
    //   "/couponRaceList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/couponRaceList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; id: any; amount: any }[] = [];
        arrayData.push({
          name: "All",
          id: "-1",
          amount: "",
        });
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            id: items.raceId,
            amount: items.amount,
          });
        });
        setIsRaceList(arrayData);
      })
      .catch((err) => {
        console.log("err", err);
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      CouponRaceList();
      handleReloadScreen();
    }
  }, [Id, storeEventId]);

  const RaceCard = ({
    Id,
    coupon_type,
    distance,
    amount,
    valid_from,
    valid_to,
    name,
    discountIn,
    couponType,
    minTickets,
    noOfCoupons,
    prefix,
  }: any) => {
    return (
      <Row>
        <Col className="col-md-4">
          <Card style={{ width: "18rem" }} className="p-md-3">
            <div className="d-flex justify-content-between align-items-center py-md-1">
              {couponType === "Promotion Codes" ? (
                <>
                  {prefix === 1 ? (
                    <div>
                      <h6 className="text-primary">Random {coupon_type}</h6>
                    </div>
                  ) : (
                    <div>
                      <h6 className="text-primary">Same {coupon_type}</h6>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <h6 className="text-primary">{coupon_type}</h6>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center py-md-1">
              <div>
                <h6 className="text-primary">
                  {name === "All" ? "All Race" : name}
                </h6>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center py-md-1">
             */}
            <div
              className={
                couponType === "Early Bird Discount"
                  ? ""
                  : "d-flex justify-content-between align-items-center py-md-1"
              }
            >
              <div>
                {couponType === "Promotion Codes" && (
                  <h5>
                    <span className="fw-500">No of Coupons : </span>
                    <span className="fw-600 text-primary">
                      {noOfCoupons ? noOfCoupons : "—"}
                    </span>
                  </h5>
                )}
                {couponType === "Bulk Discount" && (
                  <h5>
                    <span className="fw-500">Min no of Tickets : </span>
                    <span className="fw-600 text-primary">
                      {minTickets ? minTickets : "—"}
                    </span>
                  </h5>
                )}
              </div>

              <div>
                <h5>
                  <span className="fw-500">Discount : </span>
                  <span className="fw-600 text-primary"> {amount} </span>
                  <span>
                    {discountIn === "Amount" ? (
                      <span>&#8377;</span>
                    ) : (
                      <span>&#x25;</span>
                    )}
                  </span>
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-md-1">
              <div>
                <h5>
                  <span className="fw-500">Valid from : </span>
                  <span className="fw-600 text-primary">
                    {moment(valid_from).format("DD-MM-YYYY HH:mm")}
                  </span>
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-md-1">
              <div>
                <h5>
                  <span className="fw-500">Valid to : </span>
                  <span className="fw-600 text-primary">
                    {moment(valid_to).format("DD-MM-YYYY HH:mm")}
                  </span>
                </h5>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  const handleReloadScreen = () => {
    setIsLoading(true);
    // axios.post(
    //   "/getCoupons",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getCoupons",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        const { status } = res.data;
        if (status) {
          setIsCouponList(res.data.data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        {!intialScreen && (
          <>
            <div className="d-flex justify-content-between align-items-center my-md-3">
              <div>
                {!isCouponAdmin && <h2 className="text-primary">Coupon</h2>}
              </div>
              {!isViewEvent && (
                <div>
                  {(!isCouponList || isCouponList.length === 0) && (
                    <DefaultButton
                      type="button"
                      variant="outline-primary"
                      className=" default-action-button"
                      onClick={() => setIntialScreen(true)}
                    >
                      Create Coupon
                    </DefaultButton>
                  )}
                  {!intialScreen && isCouponList && isCouponList.length > 0 && (
                    <DefaultButton
                      type="button"
                      variant="outline-primary"
                      className=" default-action-button"
                      onClick={() => setIntialScreen(true)}
                    >
                      Create Coupon
                    </DefaultButton>
                  )}
                </div>
              )}
            </div>
            {((!intialScreen && !isCouponList) ||
              isCouponList.length === 0) && (
              <p>No Coupon to Display. Click on Create Coupon to add.</p>
            )}
          </>
        )}

        {!intialScreen && isCouponList && isCouponList.length > 0 && (
          <div className="row">
            <Row className="my-2">
              <Col xs={12} md={5}>
                <div>
                  <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                    <div className="col">
                      <select
                        required
                        onChange={(e) => {
                          setIsRaceType(e.target.value);
                        }}
                        defaultValue={"-1"}
                      >
                        {isRaceList?.map((option: any) => {
                          return (
                            <>
                              <option
                                key={option.id}
                                value={option.id}
                                disabled={option.status}
                                onChange={option.id}
                              >
                                {option.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <span>Please select race*</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {isRaceType &&
              isCouponList &&
              isCouponList?.map((items: any, index: any) => (
                <>
                  {(items.ticket_id === isRaceType || isRaceType === "-1") && (
                    <div
                      key={items.id}
                      className="col-md-3 col-12 mx-md-3 my-4"
                    >
                      <RaceCard
                        discountIn={items.discount_in}
                        Id={items.id}
                        coupon_type={items.coupon_type}
                        valid_from={items.valid_from}
                        valid_to={items.valid_to}
                        amount={items.discount}
                        name={items.name}
                        couponType={items.coupon_type}
                        minTickets={items.min_tickets}
                        noOfCoupons={items.no_of_coupons}
                        prefix={items.is_prefix}
                        // companyName={items.company_name}
                      />
                    </div>
                  )}
                </>
              ))}
          </div>
        )}

        {intialScreen && (
          <CouponForm
            isEventId={isEventId}
            isRaceId={isRaceId}
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadScreen}
          />
        )}
        {!intialScreen && (
          <div className="d-flex justify-content-end">
            {!isViewEvent && (
              <DefaultButton
                onClick={() => handleSubmitNextHandler()}
                type="submit"
                variant="outline-primary"
                className="default-action-button"
              >
                Next
              </DefaultButton>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default CouponIntialPage;
