import React from "react";
import { useLocation } from "react-router-dom";

const useRegister = () => {
  const [isRegister, setIsRegister] = React.useState(false);
  const location = useLocation();
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("registration")) {
      setIsRegister(true);
    }
  }, []);
  return isRegister;
};

export default useRegister;
