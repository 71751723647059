import DefaultButton from "components/DefaultButton/DefaultButton";
import React from "react";
import { Container } from "react-bootstrap";

const OfflinePage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 vh-100">
      <div className="d-flex flex-column">
        <div>
          <img
            src="/images/NoInternet.svg"
            alt="No_internet"
            width={440}
            height={280}
          />
        </div>
        <div>
          <h1 className="fs-48">
            <span className="text-primary">Whoops !! </span>
          </h1>
          <p>
            No Internet connection was found. Check your connection or try
            again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfflinePage;
