import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { useParams } from "react-router-dom";

// import { AgChartsReact } from "ag-charts-react";
import { useAuth } from "context/AuthContext";
import { useEvent } from "context/EventContext";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import moment from "moment";
import * as XLSX from "xlsx";
import DefaultButton from "components/DefaultButton/DefaultButton";

const CouponHomeTabScreen = () => {
  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();

  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { token } = useAuth();
  const [corporateCoupons, setCorporateCoupons] = useState<any>([]);
  //logic based on No of registartion based on date

  //logic based on No of registartion based on race

  function getCorporateCoupons() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/getCorporateCoupons",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setCorporateCoupons(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    getCorporateCoupons();
  }, []);

  const exportToExcel = () => {
    const wsData = [
      [
        "Race Name",
        "Company Name",
        "Company Contact Person",
        "Company Email",
        "Company Number",
        "Coupon Prefix",
        "Coupon Type",
        "Coupons Remaining",
        "No of Coupons",
        "No of Coupons Used",
        "Discount Name",
        "Discount In",
        "Discount",
      ],
      ...corporateCoupons.coupons_data?.map((item: any) => [
        item["raceName"],
        item["company_name"],
        item["company_contact_person"],
        item["company_email"],
        item["company_mobile"],
        item["coupon_prefix"],
        item["coupon_type"],
        item["couponsRemaining"],
        item["no_of_coupons"],
        item["no_of_coupons_used"],
        item["discount_name"],
        item["discount_in"],
        item["discount"],
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`Coupons_Download_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`}`
    );
  };

  return (
    <>
      {/* corporateCoupons start */}
      <Row className="my-4 me-1 border border-tagBlue rounded p-2">
        <div className="d-flex justify-content-between align-items-center my-4">
          <div>
            <h3>Corporate Coupon Report</h3>
          </div>
          <div className="w-50 d-flex justify-content-end align-items-center">
            <div className="ms-md-4">
              <DefaultButton
                type="submit"
                variant="primary"
                className="px-md-5 bg-railBlue text-white"
                onClick={() => {
                  exportToExcel();
                }}
              >
                Download Excel
              </DefaultButton>
            </div>
          </div>
        </div>

        <table className="table table-borderless header-fixed">
          <thead className="table-primary">
            <tr>
              <th>Race Name</th>
              <th>Contact Name</th>
              <th>Company Name</th>
              <th>Short Code</th>
              <th>Coupons Issued</th>
              <th>Coupons Used</th>
              <th>Coupons Remaining</th>
              <th>Valid From</th>
              <th>Valid To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bold">Total</td>
              <td>—</td>
              <td className="fw-bold">{corporateCoupons.totalCoupons}</td>
              <td className="fw-bold">{corporateCoupons.totalCouponsUsed}</td>
              <td className="fw-bold">
                {corporateCoupons.totalCouponsRemaining}
              </td>
              <td>—</td>
              <td>—</td>
            </tr>
            {corporateCoupons.coupons_data?.map((item: any) => {
              return (
                <tr>
                  <td style={{ width: "250px" }}>{item.raceName}</td>
                  <td style={{ width: "250px" }}>
                    {item.company_contact_person}
                  </td>
                  <td style={{ width: "250px" }}>{item.company_name}</td>
                  <td>{item.coupon_prefix}</td>
                  <td>{item.no_of_coupons}</td>
                  <td>{item.no_of_coupons_used}</td>
                  <td>{item.couponsRemaining}</td>
                  <td>{item.valid_from}</td>
                  <td>{item.valid_to}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Row>
      {/* corporateCoupons end */}
    </>
  );
};

export default CouponHomeTabScreen;
