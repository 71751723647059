import React, { useEffect } from "react";
import { Form, Container, Row, Col, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import { toast } from "react-toastify";
import {
  readFileAsBase64,
  nameConversion,
  alphabetsSpaceRegex,
} from "utils/utils";
import moment from "moment";
import { useSignUp } from "api/SignUp";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("First Name is required")
    .max(50, "Max 50 characters allowed"),
  lastName: Yup.string()
    .required("Last Name is required")
    .max(50, "Max 50 characters allowed"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.date().required("Date of Birth is Required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    .max(50, "Max 50 characters allowed"),
  country_code: Yup.string()
    .required("Phone code is required")
    .max(3, "Max 3 characters allowed"),
  phone: Yup.string()
    .max(10, "Max 10 characters allowed")
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Emergency contact no must contain exactly 10 digits"),
  password: Yup.string()
    .min(8, "Minimum of 8 characters of length")
    .max(20, "Maximum of 20 characters of length")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

interface SignupFormProps {
  handleSignInClickOnSignup: () => void;
  handleCloseProfileModal: () => void;
}

const SignupForm: React.FC<SignupFormProps> = ({
  handleSignInClickOnSignup,
  handleCloseProfileModal,
}) => {
  const signUpMutation = useSignUp();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      age: "",
      email: "",
      country_code: "+91",
      phone: "",
      password: "",
      confirmPassword: "",
      profile_image: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        const response = await signUpMutation.mutateAsync({
          firstName: nameConversion(values.firstName),
          lastName: nameConversion(values.lastName),
          gender: values.gender,
          age: values.age,
          email: values.email,
          country_code: values.country_code,
          phone: values.phone,
          password: values.password,
          confirmPassword: values.confirmPassword,
          profile_image: values.profile_image,
        });

        if (response.status) {
          toast.success(`${response.message}`);
          handleSignInClickOnSignup();
        }

        if (!response.status) {
          const errorMessages = response.message;
          let errorsToUpdate: Record<string, any> = {};
          Object.keys(errorMessages).forEach((fieldName: any) => {
            if (fieldName === "email" || fieldName === "phone") {
              errorsToUpdate[fieldName] = errorMessages[fieldName];
            }
          });
          formik.setErrors(errorsToUpdate);
        }
      } catch (error) {
        const message = (error as Error).message;
        setStatus({ submitError: message });
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      const fieldErrorNames = Object.keys(formik.errors);

      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [formik.isSubmitting]);

  const genderOptions = ["Male", "Female", "Other"];

  return (
    <Container style={{ maxWidth: "500px" }}>
      <h1 className="mt-2 mb-0">Sign Up</h1>
      <Form onSubmit={formik.handleSubmit} className="mt-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column w-100 me-2">
            <DefaultInput
              className="mt-4"
              label="First Name *"
              name="firstName"
              type="text"
              maxLength={50}
              placeholder="Enter your first name"
              value={formik.values.firstName}
              onChange={(event) => {
                const input = event.target.value;
                if (alphabetsSpaceRegex.test(input)) {
                  formik.setFieldValue("firstName", input);
                } else if (input.length === 0) {
                  formik.setFieldValue("firstName", input);
                }
              }}
              isError={formik.touched.firstName && !!formik.errors.firstName}
              error={formik.errors.firstName}
            />
          </div>
          <div className="d-flex flex-column w-100 me-2">
            <DefaultInput
              className="mt-4"
              label="Last Name *"
              name="lastName"
              type="text"
              maxLength={50}
              placeholder="Enter your last name"
              value={formik.values.lastName}
              onChange={(event) => {
                const input = event.target.value;
                if (alphabetsSpaceRegex.test(input)) {
                  formik.setFieldValue("lastName", input);
                } else if (input.length === 0) {
                  formik.setFieldValue("lastName", input);
                }
              }}
              isError={formik.touched.lastName && !!formik.errors.lastName}
              error={formik.errors.lastName}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column w-100 me-2">
            <DefaultDropdown
              className="mt-4"
              label="Gender *"
              name="gender"
              disablePlaceholder
              options={genderOptions}
              placeholder="Gender"
              value={formik.values.gender}
              onChange={formik.handleChange("gender")}
              isError={formik.touched.gender && !!formik.errors.gender}
              error={formik.errors.gender}
            />
          </div>
          <div className="d-flex flex-column w-100 pe-2">
            <DefaultInput
              className="mt-4 "
              label="Date of Birth *"
              name="age"
              type="date"
              maxDate={moment().format("YYYY-MM-DD")}
              placeholder="Date of Birth"
              value={formik.values.age}
              onChange={formik.handleChange("age")}
              onBlur={(event) => {
                const selectedDate = event.target.value;
                const currentDate = moment().format("YYYY-MM-DD");

                if (
                  selectedDate &&
                  moment(selectedDate).isAfter(currentDate, "day")
                ) {
                  formik.setFieldValue("age", "");
                }
              }}
              isError={formik.touched.age && !!formik.errors.age}
              error={formik.errors.age}
            />
          </div>
        </div>

        <DefaultInput
          className="mt-4"
          label="Email *"
          name="email"
          type="email"
          maxLength={50}
          placeholder="Enter your email"
          value={formik.values.email}
          onChange={formik.handleChange("email")}
          isError={formik.touched.email && !!formik.errors.email}
          error={formik.errors.email}
        />
        <Row>
          <Col xs={3}>
            <DefaultInput
              className="mt-4"
              label="Code"
              name="country_code"
              type="text"
              maxLength={3}
              placeholder="+91"
              value={formik.values.country_code}
              onChange={(e) => {
                const inputValue = e.target.value;
                const regex = /^\+?\d*$/;
                if (regex.test(inputValue) && inputValue.length <= 3) {
                  formik.handleChange("country_code")(e);
                } else if (inputValue.length === 0) {
                  formik.handleChange("country_code")(e);
                }
              }}
              isError={
                formik.touched.country_code && !!formik.errors.country_code
              }
              error={formik.errors.country_code}
            />
          </Col>
          <Col xs={9}>
            <DefaultInput
              className="mt-4"
              label="Phone Number *"
              name="phone"
              type="text"
              placeholder="Enter your phone number"
              value={formik.values.phone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const regex = /^\d+$/;
                if (regex.test(inputValue) && inputValue.length <= 10) {
                  formik.handleChange("phone")(e);
                } else if (inputValue.length === 0) {
                  formik.handleChange("phone")(e);
                }
              }}
              isError={formik.touched.phone && !!formik.errors.phone}
              error={formik.errors.phone}
            />
          </Col>
        </Row>

        <DefaultInput
          className="mt-4"
          label="Password *"
          name="password"
          type="password"
          maxLength={50}
          placeholder="Enter your password"
          value={formik.values.password}
          onChange={formik.handleChange("password")}
          isError={formik.touched.password && !!formik.errors.password}
          error={formik.errors.password}
        />

        <DefaultInput
          className="mt-4"
          label="Confirm Password *"
          name="confirmPassword"
          type="password"
          maxLength={50}
          placeholder="Confirm your password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange("confirmPassword")}
          isError={
            formik.touched.confirmPassword && !!formik.errors.confirmPassword
          }
          error={formik.errors.confirmPassword}
        />
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column w-100">
            <DefaultInput
              className="mt-4 mb-3"
              label="Profile Image"
              name="profile_image"
              type="file"
              placeholder="Profile Image"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              onChange={async (event: any) => {
                const file = event.currentTarget.files
                  ? event.currentTarget.files[0]
                  : null;

                if (file) {
                  const base64 = await readFileAsBase64(file);
                  formik.setFieldValue("profile_image", base64);
                } else {
                  formik.setFieldValue(
                    "profile_image",
                    formik.values.profile_image
                  );
                }
              }}
            />
          </div>
          {formik.values.profile_image && (
            <div className="d-flex flex-column px-3 mt-4">
              <img
                className="bordered"
                src={formik.values.profile_image}
                alt="Preview"
                style={{
                  border: "1px solid #000000",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center my-1">
          {formik.status && formik.status.submitError && (
            <div className="alert alert-danger" role="alert">
              {formik.status.submitError}
            </div>
          )}
        </div>
        <DefaultButton
          variant="primary"
          type="submit"
          className="default-action-button text-white w-100 mt-4"
        >
          SIGN UP{" "}
          {signUpMutation.isLoading && (
            <Spinner animation="border" variant="light" size="sm" />
          )}
        </DefaultButton>
      </Form>

      <div className="d-flex flex-column align-items-center my-4">
        <span className="text-darkGray fs-14 fw-400 ff-ns mt-3 text-center">
          Already signed up? Please Sign In to access your account.
        </span>
        <DefaultButton
          variant="primary"
          type="button"
          className="default-action-button text-white mt-3"
          onClick={handleSignInClickOnSignup}
        >
          SIGN IN
        </DefaultButton>
      </div>
    </Container>
  );
};

export default SignupForm;
