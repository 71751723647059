import { useState, useEffect } from "react";

const useBootstrapSize = () => {
  const [size, setSize] = useState("");

  const updateSize = () => {
    const width = window.innerWidth;
    if (width < 576) {
      setSize("xs");
    } else if (width >= 576 && width < 768) {
      setSize("sm");
    } else if (width >= 768 && width < 992) {
      setSize("md");
    } else if (width >= 992 && width < 1200) {
      setSize("lg");
    } else if (width >= 1200 && width < 1400) {
      setSize("xl");
    } else {
      setSize("xxl");
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return size;
};

export default useBootstrapSize;
