import { createContext, useContext, useState, ReactNode } from "react";
import { UpdateRace } from "types/UpdateRace";

interface CategoryContextType {
  changingCategoryForRace: UpdateRace | null;
  setChangingCategoryForRace: (category: UpdateRace | null) => void;
}

const CategoryContext = createContext<CategoryContextType>({
  changingCategoryForRace: null,
  setChangingCategoryForRace: () => {},
});

export const useCategoryContext = () => useContext(CategoryContext);

export const CategoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [changingCategoryForRace, setChangingCategoryForRace] =
    useState<UpdateRace | null>(null);

  return (
    <CategoryContext.Provider
      value={{ changingCategoryForRace, setChangingCategoryForRace }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
