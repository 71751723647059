import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

type PaymentStatusParams = {
  paymentId: string;
  razorpayOrderId: string;
  razorPaySignature: string;
};
export const useCheckPaymentStatus = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(async (params: PaymentStatusParams) => {
    const { paymentId, razorpayOrderId, razorPaySignature } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/checkPaymentStatus",
        data: {
          razorpayPaymentId: paymentId,
          razorpayOrderId: razorpayOrderId,
          razorpaySignature: razorPaySignature,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error fetching Check Payment Details");
      throw error;
    }
  });
};
