import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";

type CTAProps = {
  onButtonClick?: () => void;
  imageSrc?: string;
  altText?: string;
};

const DashboardCTA: React.FC<CTAProps> = ({
  onButtonClick,
  imageSrc,
  altText,
}) => {
  return (
    <>
      <Container
        fluid
        className="d-none d-lg-flex justify-content-center align-items-center g-0"
        style={{ height: "348px" }}
      >
        <div
          className="bg-deepAsh d-none d-md-block"
          style={{ height: "348px", width: "453px" }}
        >
          <Image
            src="/images/hand.png"
            alt="hand"
            style={{ height: "348px", width: "452px" }}
          />
        </div>
        <div className="d-none d-md-flex justify-content-center align-items-center position-relative w-100">
          <Image
            src="/images/hexagon-dotted.png"
            alt="hexagon"
            fluid
            style={{
              height: "348px",
              width: "100%",
              opacity: "0.8",
              background:
                "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
              mixBlendMode: "exclusion",
            }}
          />
          <div className="position-absolute" style={{ left: "140px" }}>
            <span className="fs-40 fw-700 text-white ps-3">
              Join hands with iFinish
            </span>
            <div className="pt-3">
              <span className="fs-24 fw-600 ff-saira text-white">
                Nothing great is made alone.
              </span>
              <br />
              <span className="fs-14 fw-400 ff-ns text-white">
                The #1 community for all looking to share, learn & grow. Learn
                More
              </span>
            </div>

            <div className="pt-3">
              <Row>
                <Col sm={12} md={6}>
                  <DefaultButton
                    onClick={() => alert("Button Alert")}
                    className="default-action-button text-white"
                    variant="primary"
                    type="button"
                  >
                    PARTNER WITH IFINISH
                  </DefaultButton>
                </Col>
                <Col sm={12} md={6}>
                  <DefaultButton
                    onClick={() => alert("Button Alert")}
                    className="default-action-button "
                    variant="outline-light"
                    type="button"
                  >
                    JOIN THE COMMUNITY
                  </DefaultButton>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
      <Container
        fluid
        className="d-flex flex-column d-lg-none  justify-content-center align-items-center g-0"
      >
        <div
          className="bg-deepAsh overflow-hidden w-100"
          style={{ height: "348px" }}
        >
          <Image
            src="/images/hand.png"
            alt="hand"
            style={{ height: "348px" }}
          />
        </div>

        <div
          className="w-100 d-flex justify-content-center align-items-center position-relative"
          style={{ height: "348px" }}
        >
          <Image
            src="/images/mobile-hexagon.png"
            alt="hexagon"
            style={{
              height: "348px",
              width: "100%",
              opacity: "0.8",
              background:
                "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
              mixBlendMode: "exclusion",
            }}
          />
          <div className="position-absolute p-4" style={{}}>
            <span className="fs-24 fw-700 ff-saira text-white ps-3">
              Join hands with iFinish
            </span>
            <div className="pt-3">
              <span className="fs-20 fw-700 ff-saira text-white">
                Nothing great is made alone.
              </span>
              <br />
              <span className="fs-14 fw-400 ff-ns text-white">
                The #1 community for all looking to share, learn & grow. Learn
                More
              </span>
            </div>

            <div className="pt-3">
              <div className="pb-3">
                <DefaultButton
                  onClick={() => console.log("Button Alert")}
                  className="default-action-button text-white w-100"
                  variant="primary"
                  type="button"
                >
                  PARTNER WITH IFINISH
                </DefaultButton>
              </div>
              <div>
                <DefaultButton
                  onClick={() => console.log("Button Alert")}
                  className="default-action-button w-100"
                  variant="outline-light"
                  type="button"
                >
                  JOIN THE COMMUNITY
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DashboardCTA;
