import DefaultButton from "components/DefaultButton/DefaultButton";
import { Modal } from "react-bootstrap";

type ConfirmModalProps = {
  show: boolean;
  note?: string;
  showNote?: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
  title: string;
};
const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  handleClose,
  handleConfirmation,
  title,
  note,
  showNote = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="py-md-5 text-center">
        <h4>{title}</h4>
        {showNote && <p className="mb-4 text-danger fs-14">{note}</p>}

        <div className="d-flex align-items-center justify-content-center mt-md-3">
          <DefaultButton
            variant="primary"
            className="default-action-button mx-2 me-md-3 px-md-4"
            type="button"
            onClick={handleConfirmation}
          >
            Yes
          </DefaultButton>
          <DefaultButton
            variant="outline-primary"
            className="default-action-button mx-2 px-md-4"
            type="button"
            onClick={handleClose}
          >
            No
          </DefaultButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
