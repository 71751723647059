import { ReactNode, memo } from "react";
import styles from "./CustomizedOffCanvas.module.scss";

interface Props {
  handleCloseCustomizedOffCanvasModal: () => void;
  children?: ReactNode;
}

interface BackdropProps {
  onClose: () => void;
}

interface CanvasOverlayProps {
  children?: ReactNode;
}

const CustomizedOffCanvas: React.FC<Props> = ({
  handleCloseCustomizedOffCanvasModal,
  children,
}) => {
  const Backdrop: React.FC<BackdropProps> = ({ onClose }) => {
    return <div className={styles.backdrop} onClick={onClose} />;
  };

  const CanvasOverlay: React.FC<CanvasOverlayProps> = ({ children }) => {
    return (
      <div className={styles.customizedOffCanvasContent}>
        <div className={styles.content}>{children}</div>
      </div>
    );
  };

  return (
    <>
      <Backdrop onClose={handleCloseCustomizedOffCanvasModal}></Backdrop>
      <CanvasOverlay>{children}</CanvasOverlay>
    </>
  );
};

export default memo(CustomizedOffCanvas);
