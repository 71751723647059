import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface AchievementCardProps {
  achievement: {
    id: number;
    title: string;
    place: string;
    date: string;
    time: string;
    imageUrl: string;
  };
}

const AchievementCard: React.FC<AchievementCardProps> = ({ achievement }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="mb-4 border-0 shadow"
      style={{
        width: "348px",
        height: "362px",
        flexShrink: "0",
        cursor: "pointer",
      }}
      position-relative
      onClick={() => navigate("/eventresult")}
    >
      <div style={{ position: "absolute", top: "16px", right: "16px" }}>
        <Image
          src="/images/download-icon.png"
          alt="download icon"
          style={{ height: "32px", width: "32px" }}
        />
      </div>
      <Card.Img
        variant="top"
        src={achievement.imageUrl}
        style={{ width: "348px", height: "196px", flexShrink: "0" }}
      />

      <Card.Body className="d-flex flex-column">
        <Card.Title>
          <span className="fs-24 ff-saira">{achievement.title}</span>
        </Card.Title>
        <Card.Text className="fs-14 ff-saira fw-600 text-diffBlack">
          {achievement.date}, {achievement.place}
        </Card.Text>
        <Card.Text className="fs-32 ff-saira fw-700 text-diffBlack">
          {achievement.time}{" "}
          <span className="fs-14 fw-400 ff-ns text-lightGray">Sec.</span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AchievementCard;
