import React from 'react';
import parse from "html-react-parser";
import { Modal } from 'react-bootstrap';

interface PreviewEmailProps {
    isOpenPreviewEmailModal: boolean;
    handleClosePreviewEmailModal: () => void;
    eventId: any;
    emailPreview: any
  }
  const PreviewEmail :React.FC<PreviewEmailProps> = ({isOpenPreviewEmailModal,handleClosePreviewEmailModal,eventId,emailPreview}) => {
  return (
    <Modal 
        show={isOpenPreviewEmailModal}
        onHide={handleClosePreviewEmailModal}
        eventId 
        dialogClassName="customModalWidth"
        centered>
        <Modal.Header closeButton>  
          <Modal.Title style={{ fontSize: "20px" }}>Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden">
                {/* {emailPreview} */}
                {parse(`${emailPreview}`)}
        </Modal.Body>

    </Modal>
  )
}


export default PreviewEmail;