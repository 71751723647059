import React from "react";
import { Container, Image } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";

const ShareYourPhotos = () => {
  return (
    <Container
      fluid
      className="d-none position-relative g-0 d-md-flex justify-content-center mb-5"
      style={{ height: "580px" }}
    >
      <div className="d-none d-md-flex justify-content-center align-items-center position-relative w-100">
        <Image
          src="/images/Hexagon-dotted-connect-line-background 1.svg"
          alt="hexagon"
          fluid
          style={{
            height: "580px",
            width: "100%",
            opacity: "0.8",
            background:
              "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
            mixBlendMode: "exclusion",
          }}
        />
        <div className="position-absolute">
          <div className="pt-3 text-center">
            <span className="fs-40 fw-700 ff-saira text-white">
              Share Your Marathon Event Photos
            </span>
            <br />
            <span className="fs-16 fw-400 ff-ns text-white">
              Upload your favorite photos from the marathon event and relive the
              memories.
            </span>
            <br />
            <span className="font-saira fs-32 fw-700 text-white py-md-5">
              Upload
            </span>
            <br />

            {/* need to complete this functionality */}
            <div className="d-none position-relative g-0 d-md-flex justify-content-center mb-5">
              <Image src="/images/Upload file background.svg" fluid />
              <div className="position-absolute  mt-md-5 pt-md-5">
                <span className="ff-saira fs-14 text-white fw-600">
                  Drag & drop files or Browse
                </span>
              </div>
            </div>
            <div className="my-md-5">
              <DefaultButton
                onClick={() => console.log("Button Alert")}
                className="default-action-button text-white "
                variant="primary"
                type="button"
              >
                UPLOAD PHOTOS
              </DefaultButton>
            </div>
          </div>

          <div className="pt-3">
            {/* <Row>
              <Col sm={12} md={6}>
                <DefaultButton
                  onClick={() => alert("Button Alert")}
                  className="default-action-button text-white"
                  variant="primary"
                  type="button"
                >
                  PARTNER WITH IFINISH
                </DefaultButton>
              </Col>
              <Col sm={12} md={6}>
                <DefaultButton
                  onClick={() => alert("Button Alert")}
                  className="default-action-button "
                  variant="outline-light"
                  type="button"
                >
                  JION THE COMMUNITY
                </DefaultButton>
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ShareYourPhotos;
