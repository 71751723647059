import React from "react";
import { Form as FormData, Formik } from "formik";
import * as Yup from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { TextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import DefaultInput from "components/Inputs/DefaultInput";
import styles from "./OrgniserSignup.module.scss";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const AddOrganiserSignUpFrom1 = ({ scrollbar }: any) => {
  const { storeAccountId, setAccountId } = useEvent();
  const { token, email } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const Navigate = useNavigate();
  const SingupinitialValues = {
    companyName: "",
    countryCode: +91,
    phone: "",
    companyAddress: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    landmark: "",
    sameAsAddress: 0,
    correspondenceAddress: "",
    isYourTurnOver20c: "",
    bankName: "",
    accountNo: "",
    accountName: "",
    accountType: "",
    micrCode: "",
    ifscCode: "",
    cancelChequeAttachment: null,
    workOrderAttachment: null,
    turnOverDeclarationAttachment: null,
    gstNo: "",
    gstAttachment: null,
    panNo: "",
    panAttachment: null,
  };
  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    // name: Yup.string().required("Short Vendor Name is Required"),
    // email: Yup.string().email("Email is invalid").required("Email is required"),
    countryCode: Yup.string(),

    // password: Yup.string()
    //   .min(8, "Minimum of 8 characters of length")
    //   .max(15, "Maximum of 15 characters of length")
    //   .matches(/[0-9]/, "Password requires a number")
    //   .matches(/[a-z]/, "Password requires a lowercase letter")
    //   .matches(/[A-Z]/, "Password requires an uppercase letter")
    //   .matches(/[^\w]/, "Password requires a symbol")
    //   .required("Password is required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password")], "Password does not match")
    //   .required("Confirm Password is required"),
    pincode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(6, "Must be exactly 5 digits"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .required("Mobile Number is required"),
    companyAddress: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(60, "Maximum of 60 characters of length"),
    correspondenceAddress: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(60, "Maximum of 60 characters of length"),
    landmark: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(40, "Maximum of 40 characters of length"),
    city: Yup.string()
      .required("City is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "City should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    state: Yup.string()
      .required("State is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "State should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    country: Yup.string()
      .required("Country is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Country should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    sameAsAddress: Yup.bool().required(
      "Billing address is same as mail address? is required"
    ),
    isYourTurnOver20c: Yup.bool().required("Is your Turn over is required"),
    gstNo: Yup.string()
      .required("GST Number is required")
      .matches(
        /^(?:[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})|(?:^[A-Za-z0-9]{1,15}$)/,
        "Invalid GST number format or must be alphanumeric and up to 15 characters"
      )
      .min(15, "Minimum of 15 numeric characters of length")
      .max(15, "Maximum of 15 numeric characters of length"),
    bankName: Yup.string()
      .required("Bank Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Bank Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    accountNo: Yup.string()
      .required("Account No is Required")
      .matches(/^\d{9,18}$/, "Invalid account number"),
    panNo: Yup.string()
      .required("PAN Number is required")
      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
    accountName: Yup.string()
      .required("Account Name is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    accountType: Yup.string()
      .required("Account Type is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    micrCode: Yup.string()
      .required("micrCode is Required")
      .matches(/^[0-9]{9}$/, "Invalid MICR code"),
    ifscCode: Yup.string()
      .required("ifscCode is Required")
      .matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, "Invalid IFSC code"),
    gstAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),
    cancelChequeAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),
    workOrderAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),

    turnOverDeclarationAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),
    panAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),
  });
  const handleSubmit = (values: any) => {
    const postData = {
      accountId: storeAccountId || 10,
      companyName: values.companyName,
      // name: "",
      countryCode: +91,
      email: email,
      phone: values.phone,
      // password: "",
      // confirmPassword: "",
      companyAddress: values.companyAddress,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      country: values.country,
      landmark: values.landmark,
      sameAsAddress: values.sameAsAddress,
      correspondenceAddress: values.correspondenceAddress,
      isYourTurnOver20c: values.isYourTurnOver20c,
      bankName: values.bankName,
      accountNo: values.accountNo,
      accountName: values.accountName,
      accountType: values.accountType,
      micrCode: values.micrCode,
      ifscCode: values.ifscCode,
      cancelChequeAttachment: values.cancelChequeAttachmentbase64,
      workOrderAttachment: values.workOrderAttachmentbase64,
      turnOverDeclarationAttachment: values.turnOverDeclarationAttachmentbase64,
      gstNo: values.bankName,
      gstAttachment: values.gstAttachmentbase64,
      panNo: values.bankName,
      panAttachment: values.panAttachmentbase64,
    };
    // axios
    //   .post("/createOrganiser", postData, {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    axiosPrivate({
      url: "/createOrganiser",
      data: postData,
      method: "POST",
    })
      .then((response: any) => {
        const { status, message } = response.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            Navigate("/org/accounts");
            setAccountId("null");
          }, 2000);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        return;
      });
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as base64."));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  return (
    <>
      <ToastContainer />
      <div className={`${styles.OrganiserSignup}`}>
        <Formik
          initialValues={SingupinitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            return (
              <FormData
                noValidate
                className={`${styles.scrollContainer} px-md-4 pb-md-4 `}
              >
                <div className="border px-4 rounded form-control">
                  <Row>
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="companyName"
                        label="Company Name *"
                      />
                    </Col>

                    <Col md={4}>
                      <TextField
                        type="text"
                        name="name"
                        label="Short Vendor Name *"
                      />
                    </Col>
                    {/* <Col md={4}>
                  <TextField
                    type="email"
                    name="email"
                    label="EmailID"
                    isRequired={true}
                  />
                </Col> */}
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="phone"
                        label="Mobile Number *"
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md={4}>
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    isRequired={true}
                  />
                </Col>

                <Col md={4}>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    isRequired={true}
                  />
                </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        type="text"
                        name="companyAddress"
                        label="Company Address *"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="landmark"
                        label="Landmark *"
                      />
                    </Col>
                    <Col md={4}>
                      <TextField type="text" name="pincode" label="Pincode *" />
                    </Col>
                    <Col md={4}>
                      <TextField type="text" name="city" label="City *" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextField type="text" name="state" label="State *" />
                    </Col>
                    <Col md={6}>
                      <TextField type="text" name="country" label="Country *" />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="col-12 my-md-3 d-flex align-items-center">
                      <Form.Group className="d-flex">
                        <Form.Label className="me-2">
                          Is the correspondence address same as company address?
                        </Form.Label>

                        <Form.Check
                          type="radio"
                          name="sameAsAddress"
                          id="sameAsAddress"
                          value="0"
                          label="Yes"
                          checked={formikProps.values.sameAsAddress === 0}
                          onChange={() =>
                            formikProps.setFieldValue("sameAsAddress", 0)
                          }
                        />

                        <Form.Check
                          type="radio"
                          className="ms-md-3"
                          name="sameAsAddress"
                          id="sameAsAddress"
                          value="1"
                          label="No"
                          checked={formikProps.values.sameAsAddress === 1}
                          onChange={() =>
                            formikProps.setFieldValue("sameAsAddress", 1)
                          }
                        />
                      </Form.Group>
                    </Col>
                    {formikProps.values.sameAsAddress === 1 && (
                      <Col className="col-12">
                        <TextField
                          type="text"
                          name="correspondenceAddress"
                          label="Correspondence Address *"
                        />
                      </Col>
                    )}
                  </Row>

                  <h4 className="text-primary">GST DETAILS</h4>
                  <Row>
                    <Col className="col-12 d-flex align-items-center mt-md-2">
                      <Form.Group className="d-flex  align-items-center justify-content-center">
                        <Form.Label className="me-2">
                          Is your turnover above 20 cr?
                        </Form.Label>

                        <Form.Check
                          style={{ marginTop: "-10px" }}
                          type="radio"
                          className="d-flex align-items-center"
                          name="isYourTurnOver20c"
                          id="isYourTurnOver20c"
                          value="1"
                          label="Yes"
                          onChange={() =>
                            formikProps.setFieldValue("isYourTurnOver20c", 1)
                          }
                        />

                        <Form.Check
                          style={{ marginTop: "-10px" }}
                          type="radio"
                          className="d-flex align-items-center ms-md-2"
                          name="isYourTurnOver20c"
                          id="isYourTurnOver20c"
                          value="0"
                          label="No"
                          onChange={() =>
                            formikProps.setFieldValue("isYourTurnOver20c", 0)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col className="col-md-6 col-12">
                      <TextField type="text" label="GST No *" name="gstNo" />
                    </Col>

                    <Col className="col-md-6 col-12">
                      {/* <label
                    htmlFor="gstAttachment"
                    className="text-darkgray fw-400 my-3"
                  >
                    GST Attachment<span className="text-primary ms-1">*</span>
                  </label>
                  <input
                    className={`form-control py-1`}
                    id="fileUpload"
                    type="file"
                    name="gstAttachment"
                    accept="image/png, image/heic, image/jpeg, image/jpg"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formikProps.setFieldValue(
                          "gstAttachmentbase64",
                          base64
                        );
                      }

                      formikProps.setFieldValue("gstAttachment", file);
                    }}
                  /> */}
                      <DefaultInput
                        label="GST Attachment"
                        type="file"
                        placeholder="Upload Image"
                        className="mt-md-2"
                        accept="image/png, image/heic, image/jpeg, image/jpg"
                        onChange={async (event: any) => {
                          const file = event.currentTarget.files
                            ? event.currentTarget.files[0]
                            : null;

                          if (file) {
                            const base64 = await readFileAsBase64(file);
                            formikProps.setFieldValue(
                              "gstAttachmentbase64",
                              base64
                            );
                          }

                          formikProps.setFieldValue("gstAttachment", file);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="py-md-2">
                    <Col className="col-md-6 col-12">
                      <TextField type="text" label="PAN No *" name="panNo" />
                    </Col>
                    <Col className="col-md-6 col-12">
                      {/* <label
                    htmlFor="panAttachment"
                    className="text-darkgray fw-400 my-3"
                  >
                    PAN Attachment<span className="text-primary ms-1">*</span>
                  </label>
                  <input
                    className={`form-control py-1`}
                    id="fileUpload"
                    type="file"
                    name="panAttachment"
                    accept="image/png, image/heic, image/jpeg, image/jpg"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formikProps.setFieldValue(
                          "panAttachmentbase64",
                          base64
                        );
                      }

                      formikProps.setFieldValue("panAttachment", file);
                    }}
                  /> */}
                      <DefaultInput
                        label="Pan Attachment"
                        type="file"
                        placeholder="Upload Image"
                        className="mt-md-2"
                        accept="image/png, image/heic, image/jpeg, image/jpg"
                        onChange={async (event: any) => {
                          const file = event.currentTarget.files
                            ? event.currentTarget.files[0]
                            : null;

                          if (file) {
                            const base64 = await readFileAsBase64(file);
                            formikProps.setFieldValue(
                              "panAttachmentbase64",
                              base64
                            );
                          }

                          formikProps.setFieldValue("panAttachment", file);
                        }}
                      />
                    </Col>
                  </Row>
                  <h4 className="text-primary mt-md-4">BANK DETAILS</h4>
                  <Row>
                    <Col className="col-md-4 col-12">
                      <TextField
                        type="text"
                        label="Bank Name *"
                        name="bankName"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Bank A/C No *"
                        name="accountNo"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Account holder name *"
                        name="accountName"
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-4 col-12">
                      <TextField
                        type="text"
                        label="Account Type *"
                        name="accountType"
                      />
                    </Col>
                    <Col className="col-md-4 col-12">
                      <TextField
                        type="text"
                        label="MICR Code *"
                        name="micrCode"
                      />
                    </Col>
                    <Col className="col-md-4 col-12">
                      <TextField
                        type="text"
                        label="IFSC Code *"
                        name="ifscCode"
                      />
                    </Col>
                  </Row>
                  <Row className="py-md-4">
                    <Col className="col-md-6 col-12">
                      {/* <label
                    htmlFor="cancelChequeAttachment"
                    className="text-darkgray fw-400 my-3"
                  >
                    Cancel Cheque<span className="text-primary ms-1">*</span>
                  </label>
                  <input
                    className={`form-control py-1`}
                    id="fileUpload"
                    type="file"
                    name="cancelChequeAttachment"
                    accept="image/png, image/heic, image/jpeg, image/jpg"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formikProps.setFieldValue(
                          "cancelChequeAttachmentbase64",
                          base64
                        );
                      }

                      formikProps.setFieldValue("cancelChequeAttachment", file);
                    }}
                  /> */}
                      <DefaultInput
                        label="Cancel Cheque"
                        type="file"
                        placeholder="Upload Image"
                        accept="image/png, image/heic, image/jpeg, image/jpg"
                        onChange={async (event: any) => {
                          const file = event.currentTarget.files
                            ? event.currentTarget.files[0]
                            : null;

                          if (file) {
                            const base64 = await readFileAsBase64(file);
                            formikProps.setFieldValue(
                              "cancelChequeAttachmentbase64",
                              base64
                            );
                          }

                          formikProps.setFieldValue(
                            "cancelChequeAttachment",
                            file
                          );
                        }}
                      />
                    </Col>
                    <Col className="col-md-6 col-12">
                      {/* <label
                    htmlFor="workOrderAttachment"
                    className="text-darkgray fw-400 my-3"
                  >
                    Work Order<span className="text-primary ms-1">*</span>
                  </label>
                  <input
                    className={`form-control py-1`}
                    id="fileUpload"
                    type="file"
                    name="workOrderAttachment"
                    accept="image/png, image/heic, image/jpeg, image/jpg"
                    onChange={async (event: any) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        const base64 = await readFileAsBase64(file);
                        formikProps.setFieldValue(
                          "workOrderAttachmentbase64",
                          base64
                        );
                      }

                      formikProps.setFieldValue("workOrderAttachment", file);
                    }}
                  /> */}
                      <DefaultInput
                        label="Work Order Attachment"
                        type="file"
                        placeholder="Upload Image"
                        accept="image/png, image/heic, image/jpeg, image/jpg"
                        onChange={async (event: any) => {
                          const file = event.currentTarget.files
                            ? event.currentTarget.files[0]
                            : null;

                          if (file) {
                            const base64 = await readFileAsBase64(file);
                            formikProps.setFieldValue(
                              "workOrderAttachmentbase64",
                              base64
                            );
                          }

                          formikProps.setFieldValue(
                            "workOrderAttachment",
                            file
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <label
                        htmlFor="turnOverDeclarationAttachment"
                        className="text-darkgray fw-400 mb-md-3"
                      >
                        Turnover Declartion for E_Invoice as per the GST Act
                        <span className="text-primary ms-1">*</span>
                      </label>
                      <input
                        className={`form-control py-1`}
                        id="fileUpload"
                        type="file"
                        name="turnOverDeclarationAttachment"
                        accept="image/png, image/heic, image/jpeg, image/jpg"
                        onChange={async (event: any) => {
                          const file = event.currentTarget.files
                            ? event.currentTarget.files[0]
                            : null;

                          if (file) {
                            const base64 = await readFileAsBase64(file);
                            formikProps.setFieldValue(
                              "turnOverDeclarationAttachmentbase64",
                              base64
                            );
                          }

                          formikProps.setFieldValue(
                            "turnOverDeclarationAttachment",
                            file
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="row my-4">
                    <div className="col">
                      <DefaultButton
                        type="submit"
                        variant="outline-primary"
                        className=" default-action-button w-100"
                      >
                        Submit
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </FormData>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddOrganiserSignUpFrom1;
