import React, { useEffect, useState } from "react";
import { Icon } from "components/Icon";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultButton from "components/DefaultButton/DefaultButton";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import Loader from "components/Loader/Loader";
import DataTable from "react-data-table-component";
import { itemsPerPageOptions, tableCustomStyles } from "utils/utils";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { Form as FormData, Formik } from "formik";
import { TextField } from "utils/FormControls";
import * as Yup from "yup";

const SupportRoleList = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [supportRolesList, setSupportRolesList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [supportId, setSupportId] = useState(null);
  const [supportEmail, setSupportEmail] = useState(null);
  const [createSupportRoleModel, setCreateSupportRoleModel] = useState(false);
  function getSupportRolesList() {
    setIsLoading(true);
    axiosPrivate({
      method: "GET",
      url: "/getSupportRoles",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setSupportRolesList(data);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function deleteSupportRole() {
    setIsLoading(true);
    setShowConfirmModal(false);
    axiosPrivate({
      method: "POST",
      data: { id: supportId },
      url: "/deleteSupportRole",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
          setTimeout(() => {
            getSupportRolesList();
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getSupportRolesList();
  }, []);

  const handleModalClose = () => {
    setShowConfirmModal(false);
  };
  const handleCloseCreateSupportRoleModel = () => {
    setCreateSupportRoleModel(false);
  };

  const handleConfirmButton = (row: any) => {
    setSupportId(row.id);
    setShowConfirmModal(true);
    setSupportEmail(row.email);
  };

  const supportList = [
    // {
    //   name: "Account Id",
    //   selector: (row: any) => (row.id ? row.id : "—"),
    //   sortable: true,
    // },
    {
      name: "Name",
      selector: (row: any) => (row.name ? row.name : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: true,
    },
    {
      name: "Action",
      width: "150px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleConfirmButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Delete
        </DefaultButton>
      ),
    },
  ];

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
  });

  const handleCreateSupportRoleSubmit = (values: any) => {
    setIsLoading(true);
    const { name, password, email } = values;
    const postData = {
      email: email,
      name: name,
      password: password,
    };
    axiosPrivate({
      url: "/createSupportRole",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          handleCloseCreateSupportRoleModel();
          setTimeout(() => {
            getSupportRolesList();
          }, 2000);
        } else {
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              toast.error(` ${message}`);
            });
          });
          handleCloseCreateSupportRoleModel();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setSupportEmail(null);
        setSupportId(null);
      });
  };
  const CreateSupportModel = () => {
    return (
      <Modal
        show={createSupportRoleModel}
        onHide={handleCloseCreateSupportRoleModel}
        centered
      >
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>Create Support Role</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" px-md-4 text-center">
          <Formik
            onSubmit={handleCreateSupportRoleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, handleChange }) => {
              return (
                <FormData noValidate onSubmit={handleSubmit}>
                  <div className="px-0 d-flex flex-column justify-content-center">
                    <Row>
                      <Col className="col-12 py-md-1">
                        <TextField
                          name="name"
                          type="text"
                          label="Name *"
                          onChange={handleChange}
                          onWheel={(event: any) => event.currentTarget.blur()}
                        />
                      </Col>
                      <Col className="col-12 py-md-1">
                        <TextField
                          name="email"
                          type="text"
                          label="Email *"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col className="col-12 py-md-1">
                        <TextField
                          name="password"
                          label="Password * "
                          type="password"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col>
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password * "
                          type="password"
                        />
                      </Col>

                      <div className="d-flex align-items-center justify-content-end my-md-4">
                        <DefaultButton
                          type="submit"
                          variant="primary"
                          className="default-action-button me-md-3 px-md-5"
                        >
                          Submit
                        </DefaultButton>
                        <DefaultButton
                          variant="outline-primary"
                          className="default-action-button px-md-5"
                          type="button"
                          onClick={() => setCreateSupportRoleModel(false)}
                        >
                          Close
                        </DefaultButton>
                      </div>
                    </Row>
                  </div>
                </FormData>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div>
      {isLoading && <Loader />}
      <Container className="mt-4">
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Dashboard
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">Support Role List</h6>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <DefaultButton
                onClick={() => setCreateSupportRoleModel(true)}
                className="default-action-button text-white ms-3"
                variant="primary"
                type="button"
              >
                Create Support role
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <div className="my-md-4 d-flex justify-content-center align-items-center">
          <div className="mt-md-3 w-75">
            <DataTable
              pagination
              paginationRowsPerPageOptions={itemsPerPageOptions}
              columns={supportList}
              data={supportRolesList}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>
      </Container>
      {showConfirmModal && (
        <ConfirmModal
          title="Confirm Email Support Deletion"
          note={`Are you certain you want to delete the support role associated with ${supportEmail}?`}
          showNote={true}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={deleteSupportRole}
        />
      )}
      {createSupportRoleModel && <CreateSupportModel />}
    </div>
  );
};

export default SupportRoleList;
