import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useVerifyCoupon = () => {
  const axiosPrivate = useAxiosPrivate();

  return useMutation(async (params: any) => {
    const {
      id,
      email,
      actualAmount,
      currentAmount,
      earlyBirdDiscount,
      promoCode,
      promoCodeDiscount,
      promoCodeId,
      bulkDiscount,
      name,
      raceId,
    } = params;

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/verifyPromoCode",
        data: {
          id: id,
          email: email,
          actualAmount: actualAmount,
          currentAmount: currentAmount,
          earlyBirdDiscount: earlyBirdDiscount,
          promoCode: promoCode,
          promoCodeDiscount: promoCodeDiscount,
          promoCodeId: promoCodeId,
          bulkDiscount: bulkDiscount,
          name: name,
          raceId: raceId,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error while appling coupon");
      throw error;
    }
  });
};
