import { Button, Col, Container, Image, Row } from "react-bootstrap";
import TrainingProgramsCard from "components/Cards/TrainingProgrammeCard";
import { TrainingPrograms } from "types/TrainingPrograms";
import useBootstrapSize from "utils/useBootstrapSize";

type TrainingProgramProps = {
  currentTrainningPrograms: TrainingPrograms[];
  handleProgramNext: () => void;
  handleProgramPrev: () => void;
};
const TraingProgram: React.FC<TrainingProgramProps> = ({
  currentTrainningPrograms,
  handleProgramNext,
  handleProgramPrev,
}) => {
  const pageSize = useBootstrapSize();

  return (
    <Container className={`pt-5 pb-5 `}>
      <Row className="pb-3">
        <Col md={10}>
          <span className="blockTitle">Training Programs</span>
        </Col>
        <Col
          md={2}
          className="d-none d-md-flex justify-content-end align-items-center px-0"
        >
          <Button variant="" onClick={handleProgramPrev}>
            <Image src="/images/prev.png" alt="prev" />
          </Button>
          <Button variant="" onClick={handleProgramNext}>
            <Image src="/images/next.png" slot="next" />
          </Button>
        </Col>
      </Row>

      <Row className="position-relative d-flex justify-content-between">
        {currentTrainningPrograms.map((program, index, array) => {
          let alignmentClass = "justify-content-center";
          if (index === 0 && pageSize !== "xs" && pageSize !== "sm") {
            alignmentClass = "justify-content-start";
          } else if (
            index === array.length - 1 &&
            pageSize !== "xs" &&
            pageSize !== "sm"
          ) {
            alignmentClass = "justify-content-end";
          }

          return (
            <Col
              md={4}
              key={program.id}
              className={`position-relative d-flex align-items-center ${alignmentClass}`}
            >
              <TrainingProgramsCard programme={program} />

              <div
                className="d-md-none position-absolute  w-100 d-flex justify-content-between"
                style={{
                  zIndex: "100",
                  top: "40%",
                  transform: "translateY(-50%)",
                }}
              >
                <Button variant="" onClick={handleProgramPrev}>
                  <Image src="/images/prev.png" alt="prev" />
                </Button>
                <Button variant="" onClick={handleProgramNext}>
                  <Image src="/images/next.png" alt="next" />
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default TraingProgram;
