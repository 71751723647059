import React, { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReadMore from "utils/ReadMore";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { encryptText } from "utils/utils";
import Loader from "components/Loader/Loader";

const SuperadminDashboard = () => {
  const axiosPublic = useAxiosPublic();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [eventsList, setEventsList] = useState([]);

  function userEventList() {
    setIsLoading(true);
    axiosPublic({
      method: "GET",
      url: "/userEventList",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setEventsList(data);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    userEventList();
  }, []);

  const EventCard = ({
    Card_id,
    imageSrc1,
    imageSrc2,
    organizer,
    eventName,
    location,
    Enddate,
    city,
  }: any) => {
    return (
      <Card
        style={{ width: "348px", cursor: "pointer" }}
        onClick={() => navigate(`/support/${encryptText(Card_id)}`)}
      >
        <Card.Img
          variant="top"
          src={imageSrc1 ?? "/images/cardBackground.png"}
        />

        <Card.Body className="px-md-4 flex-grow-1 m-md-0 pb-md-2">
          <div className="d-flex align-items-center py-md-2">
            <div className="p-0 m-0">
              <img
                style={{ height: 40, width: 40 }}
                className="rounded-2 m-0 p-0"
                alt={imageSrc2}
                src={imageSrc2 ?? "/Images/IfiinishOrgDefaultLogo.svg"}
              />
            </div>
            <div className="d-flex align-items-center">
              <h5 className="text-uppercase fw-600 ms-3 mt-2">{organizer}</h5>
            </div>
          </div>
          <div>
            <h1
              className={`fw-600 ${
                eventName.length > 23 && eventName.length < 31 && " fs-20"
              }`}
            >
              {eventName && eventName.length > 30 ? (
                <ReadMore eventName={eventName} />
              ) : (
                `${eventName}`
              )}
            </h1>

            <h6 className="fw-600">
              {location},{city}
            </h6>
          </div>

          <div className=" d-flex flex-column align-items-start justify-content-center ">
            <div className="d-flex justify-content-between w-100">
              <div className="p-0 m-0">
                {/* <h6 className="fw-normal">
                  <span>Start Date: </span>
                  {Startdate}
                </h6> */}
                <h6 className="fw-normal">
                  <span>End Date: </span>
                  {Enddate}
                </h6>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      <Container className="mt-md-4">
        <Row className="mt-4">
          <Col>
            <div className="d-flex justify-content-end">
              <DefaultButton
                className="default-action-button text-white"
                variant="primary"
                type="button"
                onClick={() => navigate("/support/list")}
              >
                Support Role
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <div className="my-md-4">
          {eventsList && eventsList?.length > 0 ? (
            <Row>
              {eventsList?.map((card: any) => {
                return (
                  <Col key={card.id} md={4} className="my-md-2">
                    <EventCard
                      Card_id={card.id}
                      imageSrc1={card.imageUrl}
                      imageSrc2={card.organizerLogo}
                      eventName={card.title}
                      organizer={card.organizerName}
                      location={card.location}
                      city={card.city}
                      Enddate={card.event_end_date}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            "No active events for this organization yet. Start creating one by clicking 'Create Event'!"
          )}
        </div>
      </Container>
    </>
  );
};

export default SuperadminDashboard;
