import { VirtualEvent } from "../types/VirtualEvent";

export const recommendedVirtualEvents: VirtualEvent[] = [
  {
    id: 1,
    title: "Get Fit and Have Fun at Home",
    description:
      "Join our virtual races and exercise programs to stay active and motivated",
    notification: "3 DAYS LEFT",
    date: "20 NOV 2023",
    imageUrl: "/images/VirtualEvent1.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
    tags: ["Athletics", "Endurance"],
  },
  {
    id: 2,
    title: "Stay Active and Healthy",
    description:
      "Explore virtual races and exercise programs to maintain your active lifestyle",
    notification: "Limited Entry",
    date: "20 SEP 2023",
    imageUrl: "/images/VirtualEvent2.png",
    organizerLogo: "url(/images/ACEM-logo.png)",
    organizerName: "ACEM, IFCSS",
    tags: ["Running", "Health"],
  },
  {
    id: 3,
    title: "Achieve Your Fitness Goals",
    description:
      "Discover virtual races and exercise programs designed to help you reach your fitness goals",
    notification: "",
    date: "23 JAN 2024",
    imageUrl: "/images/VirtualEvent3.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
    tags: ["Sports", "Fitness"],
  },
  {
    id: 4,
    title: "Stay Active and Healthy",
    description:
      "Explore virtual races and exercise programs to maintain your active lifestyle",
    notification: "Limited Entry",
    date: "20 SEP 2023",
    imageUrl: "/images/VirtualEvent2.png",
    organizerLogo: "url(/images/ACEM-logo.png)",
    organizerName: "ACEM, IFCSS",
    tags: ["Running", "Health"],
  },
];
