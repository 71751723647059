import React from "react";
import { Icon } from "components/Icon";

interface PendingIconProps {
  className: string;
}
const PendingIcon: React.FC<PendingIconProps> = ({ className }) => {
  return (
    <div
      className={`${className} d-flex justify-content-center align-items-center`}
      style={{
        height: "48px",
        width: "48px",
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      <Icon
        iconName="Exclamation"
        className="text-white   border-5"
        size={40}
      />
    </div>
  );
};

export default PendingIcon;
