import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Icon } from "components/Icon";
import DataTable from "react-data-table-component";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { NGOData } from "types/NGOData";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import Loader from "components/Loader/Loader";
import { decryptText, tableCustomStyles } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";
import FundRaiserMoreModal from "./fundRaiserMoreModal";

const FundRaiserTabScreen = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();
  const { companyAccount } = useAccount();

  const [isShowManageModal, setIsShowManageModal] = useState(false);
  const [reRender, setReRender] = useState<boolean>(false);
  const [modalData, setModalData] = useState<NGOData[]>();
  const [searchValue, setSearchValue] = useState<any>("");
  const [isNgoDetails, setIsNgoDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isViewer = companyAccount === OrgRoleEnum.Viewer;

  const handleAcceptButton = (data: any) => {
    setModalData(data);
    setIsShowManageModal(true);
  };

  function getNgoDetails() {
    setIsLoading(true);
    axiosPrivate({
      url: "/individualFundRaiserIdDetails",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "GET",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setIsNgoDetails(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  const approvedData = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },

    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "200px",
    },

    {
      name: "Min Amount",
      selector: (row: any) => (row.goal_amount ? row.goal_amount : "—"),
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Status",
    //   cell: (row: any) => (
    //     <div>
    //       {isViewer ? (
    //         <div>
    //           <Icon
    //             iconName={row.status === "I" ? "ToggleOff" : "ToggleOn"}
    //             className={row.status === "I" ? "text-gray" : "text-success"}
    //             size={36}
    //           />
    //         </div>
    //       ) : (
    //         <div onClick={(newState: any) => handleSwitchToggle(newState, row)}>
    //           <Icon
    //             iconName={row.status === "I" ? "ToggleOff" : "ToggleOn"}
    //             className={row.status === "I" ? "text-gray" : "text-success"}
    //             size={36}
    //           />
    //         </div>
    //       )}
    //     </div>
    //   ),
    //   width: "150px",
    // },
    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];

  const pedingData = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },

    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: false,
      width: "200px",
    },

    {
      name: "Goal Amount",
      selector: (row: any) => (row.goal_amount ? row.goal_amount : "—"),
      sortable: true,
      width: "150px",
    },

    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];

  const rejectedData = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },

    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "200px",
    },
    {
      name: "Goal Amount",
      selector: (row: any) => (row.goal_amount ? row.goal_amount : "—"),
      sortable: true,
      width: "150px",
    },

    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];

  const successParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "A" || data.status === "I"
  );

  const pendingParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "P"
  );

  const rejectParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "R"
  );
  const approvedTotalRows = successParticipant.length;
  const pendingTotalRows = pendingParticipant.length;
  const rejectedTotalRows = rejectParticipant.length;

  const pendingFilterResult = pendingParticipant.filter(
    (e: any) =>
      e.name && e.name.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const approvedFilterResult = successParticipant.filter(
    (e: any) =>
      e.name && e.name.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const rejectedFilterResult = rejectParticipant.filter(
    (e: any) =>
      e.name && e.name.toLowerCase().includes(searchValue?.toLowerCase())
  );

  useEffect(() => {
    getNgoDetails();
  }, [reRender]);

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Tabs
          defaultActiveKey="success"
          id="fundraiser-tabs"
          className="event-registartion-nav-tabs border-0"
        >
          <Tab eventKey="success" title="Approved">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by name"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={approvedTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={approvedData}
                data={approvedFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
          <Tab eventKey="inComplete" title="Pending">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by email, phone no"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={pendingTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={pedingData}
                data={pendingFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
          <Tab eventKey="rejected" title="Rejected">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by email, phone no"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={rejectedTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={rejectedData}
                data={rejectedFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      {isShowManageModal && (
        <FundRaiserMoreModal
          showModal={isShowManageModal}
          handleClose={() => setIsShowManageModal(false)}
          setReRender={setReRender}
          modalData={modalData}
        />
      )}
    </>
  );
};

export default FundRaiserTabScreen;
