import { ReactNode } from "react";

type BadgeProps = {
  onClick?: () => void;
  children: ReactNode;
};

const Badge = ({ onClick, children }: BadgeProps) => {
  return (
    <button
      onClick={onClick}
      className="text-info border-0 fs-14 fw-400 bg-tagBlue px-3 me-2"
      style={{
        fontFamily: "Noto Sans",
        borderRadius: "24px",
      }}
    >
      {children}
    </button>
  );
};

export default Badge;
