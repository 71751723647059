import React from "react";
import parse from "html-react-parser";

interface EventDetailsProps {
  description: string;
}

export const EventDetails: React.FC<EventDetailsProps> = ({ description }) => {
  return (
    <div>
      <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
        {" "}
        {parse(`${description}`)}
      </p>
    </div>
  );
};
