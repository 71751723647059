import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Toaster from "components/Toaster";
import { BrowserRouter } from "react-router-dom";
import { CartContextProvider } from "store/cart/cartContext";
import { RegisterationContextProvider } from "store/cart/registerationContext";
import { EventProvider } from "store/event/EventContext";
import { OrgEventProvider } from "context/EventContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { CategoryProvider } from "store/event/CategoryContext";
import "./assets/scss/custom.scss";
import { AuthProvider } from "context/AuthContext";
import { AccountProvider } from "context/AccountContext";
import ScrollToTop from "utils/ScrollToTop";
import { RefundProvider } from "context/SupportContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <CartContextProvider>
          <RegisterationContextProvider>
            <AuthProvider>
              <EventProvider>
                <OrgEventProvider>
                  <AccountProvider>
                    <CategoryProvider>
                      <RefundProvider>
                        <ScrollToTop />
                        <Toaster />
                        <App />
                      </RefundProvider>
                    </CategoryProvider>
                  </AccountProvider>
                </OrgEventProvider>
              </EventProvider>
            </AuthProvider>
          </RegisterationContextProvider>
        </CartContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
