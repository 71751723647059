import DefaultButton from "components/DefaultButton/DefaultButton";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Form as FormData, Formik } from "formik";
import { TextField } from "utils/FormControls";
import DefaultInput from "components/Inputs/DefaultInput";
import { decryptText, readFileAsBase64 } from "utils/utils";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { useParams } from "react-router-dom";

const AddOfflineCharity = ({
  addOfflineCharity,
  handleCloseAddOfflineCharity,
  setReRender,
}: any) => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [getCharities, setGetCharities] = useState([]);
  const [getCharityId, setgetCharityId] = useState<any>();

  const IntialValues = {
    fristName: "",
    lastName: "",
    mobileNumber: "",
    panNumber: "",
    email: "",
    donationAmount: "",
    donationProof: "",
    isAnonymous: "",
  };
  const validationSchema = Yup.object({
    fristName: Yup.string()
      .required("First Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "First Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    lastName: Yup.string()
      .required("First Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Last Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .required("Mobile Number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    panNumber: Yup.string()
      .required("PAN Number is required")
      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
    donationAmount: Yup.number()
      .positive("Donation Amount must be positive")
      .required("Donation Amount is required"),
    donationProof: Yup.mixed()
      .required("Donation Proof Attachment is required")
      .test("fileType", "Unsupported file type", (value: any) => {
        if (!value) return true; // Skip if no file provided
        const supportedTypes = ["image/jpeg", "image/png", "application/pdf"];
        return value && supportedTypes.includes(value.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true; // Skip if no file provided
        return value && value.size <= 2 * 1024 * 1024; // 1 MB
      }),
    isAnonymous: Yup.string(),
  });
  const handleOfflineCharitySubmit = (values: any) => {
    if (!getCharityId) {
      setIsLoading(false);
      toast.error("You need to select Charity Name");
    }
    setIsLoading(true);
    const postData = {
      firstName: values.fristName,
      lastName: values.lastName,
      email: values.email,
      panNumber: values.panNumber,
      mobileNumber: values.mobileNumber,
      donationAmount: values.donationAmount,
      charityId: getCharityId,
      proof: values.donationProofbase64,
      isAnonymous: values.isAnonymous,
    };

    axiosPrivate({
      url: "/offlineCharityDonation",
      data: postData,
      method: "POST",
    })
      .then((response: any) => {
        const { status, message } = response.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          handleCloseAddOfflineCharity();
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  function getCharity() {
    axiosPrivate({
      url: "/getCharities",
      data: {
        eventId: eventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setGetCharities(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getCharity();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Modal
          show={addOfflineCharity}
          onHide={handleCloseAddOfflineCharity}
          centered
        >
          <Modal.Header className="border-white px-md-4"></Modal.Header>
          <Modal.Body className="mx-3 scrollbar mb-md-3">
            <Modal.Title>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3>Offline Charity</h3>
                </div>
                <div>
                  <DefaultButton
                    type="button"
                    variant="outline-primary"
                    className="default-action-button px-md-5"
                    onClick={handleCloseAddOfflineCharity}
                  >
                    Close
                  </DefaultButton>
                </div>
              </div>
            </Modal.Title>
            <div>
              <Formik
                onSubmit={handleOfflineCharitySubmit}
                validationSchema={validationSchema}
                initialValues={IntialValues}
              >
                {({ handleSubmit, handleChange, setFieldValue }) => {
                  return (
                    <FormData noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col className="col-12">
                          <TextField
                            name="fristName"
                            type="text"
                            label="First Name *"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <TextField
                            name="lastName"
                            type="text"
                            label="Last Name *"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <TextField
                            name="email"
                            type="text"
                            label="Email *"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <TextField
                            name="mobileNumber"
                            type="text"
                            label="Mobile Number *"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                            <div className="col">
                              <select
                                onChange={(e) => {
                                  setgetCharityId(e.target.value);
                                }}
                                required
                              >
                                <option value=""></option>
                                {getCharities?.map((option: any) => {
                                  return (
                                    <>
                                      <option
                                        key={option.id}
                                        value={option.id}
                                        onChange={option.id}
                                      >
                                        {option.charity_name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                              <span>Charity Name*</span>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-12">
                          <TextField
                            name="panNumber"
                            type="text"
                            label="PAN Number *"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <TextField
                            name="donationAmount"
                            type="number"
                            label="Donation Amount *"
                            min="0"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col className="col-12">
                          <DefaultInput
                            label="Donation Amount Proof"
                            type="file"
                            placeholder="Upload Image"
                            className="mt-md-2"
                            accept="image/png, image/heic, image/jpeg, image/jpg"
                            onChange={async (event: any) => {
                              const file = event.currentTarget.files
                                ? event.currentTarget.files[0]
                                : null;

                              if (file) {
                                const base64 = await readFileAsBase64(file);
                                setFieldValue("donationProofbase64", base64);
                              }

                              setFieldValue("donationProof", file);
                            }}
                          />
                        </Col>
                        <Col className="col-12 my-md-3">
                          <Form.Group className="d-flex">
                            <Form.Label className="">
                              Would you prefer to donate anonymously?
                            </Form.Label>

                            <Form.Check
                              type="radio"
                              name="isAnonymous"
                              id="isAnonymous"
                              value="1"
                              label="Yes"
                              onChange={() => setFieldValue("isAnonymous", 1)}
                            />

                            <Form.Check
                              type="radio"
                              className="ms-md-3"
                              name="isAnonymous"
                              id="isAnonymous"
                              value="0"
                              label="No"
                              onChange={() => setFieldValue("isAnonymous", 0)}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="col-12">
                          <DefaultButton
                            type="submit"
                            variant="primary"
                            className="default-action-button w-100"
                          >
                            Submit
                          </DefaultButton>
                        </Col>
                      </Row>
                    </FormData>
                  );
                }}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddOfflineCharity;
