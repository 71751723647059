import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

type Props = {
  children?: React.ReactNode;
};

type EventContextType = {
  storeEventId: string | null;
  storeRaceId: string | null;
  storeAccountId: string | null;
  setEventId: (eventId: string | null) => void;
  setRaceId: (raceId: string | null) => void;
  setAccountId: (accountId: string | null) => void;
};

const EventContext = createContext<EventContextType | undefined>(undefined);

export const useEvent = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error("useEvent must be used within an EventProvider");
  }
  return context;
};

export const OrgEventProvider: React.FC<Props> = (props) => {
  const [storeEventId, setStoreEventId] = useState<string | null>(() => {
    return localStorage.getItem("eventId") || null;
  });

  const [storeRaceId, setStoreRaceId] = useState<string | null>(() => {
    return localStorage.getItem("raceId") || null;
  });
  const [storeAccountId, setstoreAccountId] = useState<string | null>(() => {
    return localStorage.getItem("accountId") || null;
  });

  const setEventId = (eventId: string | null) => {
    setStoreEventId(eventId);
    localStorage.setItem("eventId", eventId || ""); // Ensure eventId is not null
  };

  const setRaceId = (raceId: string | null) => {
    setStoreRaceId(raceId);
    localStorage.setItem("raceId", raceId || "");
  };

  const setAccountId = (accountId: string | null) => {
    setstoreAccountId(accountId);
    localStorage.setItem("accountId", accountId || "");
  };

  return (
    <EventContext.Provider
      value={{
        storeRaceId,
        storeEventId,
        setEventId,
        setRaceId,
        setAccountId,
        storeAccountId,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventContext;
