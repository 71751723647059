import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { axiosPrivate } from "../axios";
import { useAuth } from "context/AuthContext";

const useAxiosPrivate = () => {
  const { token, logout } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (config.url) {
          const apiToken = CryptoJS.HmacSHA256(
            config.url.split("/")[1],
            `${process.env.REACT_APP_ENDPOINT_ENCRYPT}`
          ).toString(CryptoJS.enc.Hex);

          if (!config.headers["apiKey"]) {
            config.headers["apiKey"] = apiToken;
          }
        }
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${
            token || localStorage.getItem("token")
          }`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          logout();
          navigate("/");
        } else if (error.response?.status === 403) {
          navigate("/forbidden");
        } else if (error?.response?.code === "ERR_NETWORK") {
          navigate("/connectiontimeout");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
    //   Change dependency to [refreshToken] when implementing
  }, []);

  return axiosPrivate;
};

export default useAxiosPrivate;
