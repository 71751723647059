import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EventCard from "components/Cards/EventCard";
import { Event } from "types/Event";
import useBootstrapSize from "utils/useBootstrapSize";
import NextIcon from "components/Icons/NextIcon";
import PrevIcon from "components/Icons/PrevIcon";

type RecommendedEventsProps = {
  currentEvents: Event[];
  handlePrev: () => void;
  handleNext: () => void;
  title: string;
  showPrevItems: boolean;
  showNextItems: boolean;
};
const RecommendedEvents: React.FC<RecommendedEventsProps> = ({
  currentEvents,
  handlePrev,
  handleNext,
  title,
  showPrevItems,
  showNextItems,
}) => {
  const pageSize = useBootstrapSize();

  return (
    <Container className={`pt-5`}>
      <Row className="pb-3 ">
        <Col md={10}>
          <span className="blockTitle">{title}</span>
        </Col>
        <Col
          md={2}
          className="d-none d-lg-flex justify-content-end align-items-center px-0"
        >
          <PrevIcon
            className={`bg-${showPrevItems ? "primary" : "gray3"} me-3`}
            onClick={handlePrev}
          />
          <NextIcon
            className={`bg-${showNextItems ? "primary" : "gray3"}`}
            onClick={handleNext}
          />
        </Col>
      </Row>

      <Row className="position-relative d-flex justify-content-between">
        {currentEvents?.map((event, index, array) => {
          let alignmentClass = "justify-content-center";
          if (
            index === 0 &&
            pageSize !== "xs" &&
            pageSize !== "sm" &&
            pageSize !== "md"
          ) {
            alignmentClass = "justify-content-start";
          } else if (
            index === array.length - 1 &&
            pageSize !== "xs" &&
            pageSize !== "sm" &&
            pageSize !== "md"
          ) {
            alignmentClass = "justify-content-end";
          }
          return (
            <Col
              lg={4}
              key={event?.id}
              className={`position-relative d-flex align-items-center ${alignmentClass}`}
            >
              <EventCard event={event} />
              <div
                className="d-lg-none position-absolute w-100 d-flex justify-content-between"
                style={{ top: "40%", transform: "translateY(-50%)" }}
              >
                <PrevIcon
                  className={`bg-${showPrevItems ? "primary" : "gray3"} ms-2`}
                  onClick={handlePrev}
                />
                <NextIcon
                  className={`bg-${showNextItems ? "primary" : "gray3"} me-2`}
                  onClick={handleNext}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default RecommendedEvents;
