import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "components/Loader/Loader";

import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
interface SpotRegistrationProps {
  selectOpt: any;
}

const SpotRegistration: React.FC<SpotRegistrationProps> = ( {selectOpt} ) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRolesList, setIsRolesList] = useState<any>();
  const [ticketName, setTisetTicketNAme] = useState([]);
  
  const axiosPrivate = useAxiosPrivate();
 
  function GetRaceList (){
    setIsLoading(true);
    axiosPrivate({
      url: "/userEventRaceList",
      data: {
        eventId: 84,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        setTisetTicketNAme(data.tickets) ;
        if (status) {
          const roleList: any = data?.filter(
            (data: any) => data.roleName === "Approver"
          );
          setIsRolesList(roleList);
        }
      })
      .catch((err) => {
        return;
      }).finally(()=>{
        setIsLoading(false);
      });
  }
    useEffect(()=>{
      GetRaceList()
    }, [])

  const handleSelectOption = (e: any) => {
    const selectedOption   =  e.target.value;
    const race:any = ticketName.find((ticket: any) => ticket.ticket_name === selectedOption);
    selectOpt(race.id, selectedOption);
  };
  return (
    <>
      {isLoading && <Loader />}
        <Row className="my-2">
        <Col xs={12} md={5}>
          <div>
            <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
              <div className="col">
                <select onChange={handleSelectOption}>
                  <option disabled={true} value="" selected>Choose an option</option>
                   {
                    ticketName?.map((option: any)=>{
                      return (
                        <>
                        <option
                          key={option.raceId}
                          value={option.ticket_name}
                        >
                          {option.ticket_name}
                        </option>
                      </>
                      )
                    })
                   }
                </select>
                <span>Please select the race *</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SpotRegistration;
