import { useQuery } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

interface CustomFieldParams {
  eventId: number;
  raceId: number;
}

export const useUserCustomFields = (params: CustomFieldParams) => {
  const { eventId, raceId } = params;
  const axiosPrivate = useAxiosPrivate();

  return useQuery(
    ["userCustomFields", eventId, raceId],
    async () => {
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: "/userCustomFields",
          data: {
            eventId: eventId,
            raceId: raceId,
          },
        });
        return response.data;
      } catch (error) {
        toast.error("Error fetching custom fields");
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
