import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Icon } from "components/Icon";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import CouponDetailModal from "./couponDetailModal";
import DataTable from "react-data-table-component";
import moment from "moment";
import Loader from "components/Loader/Loader";
import { decryptText, tableCustomStyles } from "utils/utils";
import DefaultButton from "components/DefaultButton/DefaultButton";
import CouponForm from "OrganizerPages/eventsforms/CouponForm";
import CreateCouponScreen from "./CreateCouponScreen";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";
import { useCouponUser } from "utils/useCouponUser";
import EditCouponsModal from "OrganizerPages/coupons/EditCouponsModal";

const CouponsTabScreen = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const { companyAccount } = useAccount();
  const eventId = decryptText(String(id));
  const { userId } = useAuth();
  const isCouponUser = useCouponUser();
  const { storeEventId } = useEvent();
  const [modalData, setModalData] = useState(null);
  const [isCouponList, setIsCouponList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [createCoupon, setIntialScreen] = useState(false);
  const [isShowManageModal, setIsShowManageModal] = useState(false);
  const [nmdcManageModal, setNmdcManageModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [reRender, setReRender] = useState<boolean>(false);
  // const [isRaceList, setIsRaceList] = useState<any>([]);
  // const [isRaceType, setIsRaceType] = useState("-1");

  const isViewer = companyAccount === OrgRoleEnum.Viewer;
  const totalRows = isCouponList.length;

  function getCoupons() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getCoupons",
      data: {
        eventId: eventId || storeEventId,
        userId: isCouponUser ? userId : null,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setIsCouponList(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    getCoupons();
  }, [reRender]);

  const filterResult = isCouponList.filter(
    (e: any) =>
      (e.coupon_type &&
        e.coupon_type.toLowerCase().includes(searchValue?.toLowerCase())) ||
      (e.coupon_prefix &&
        e.coupon_prefix.toLowerCase().includes(searchValue?.toLowerCase())) ||
      ""
  );

  const handleNMDCCloseManageModal = () => setNmdcManageModal(false);
  const handleCloseManageModal = () => {
    setIsShowManageModal(false);
    setModalData(null);
  };
  const columns = [
    // {
    //   name: "Sr No",
    //   cell: (row: any, index: any) => {
    //     const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
    //     return serialNumber;
    //   },
    // },
    {
      name: "Race name",
      selector: (row: any) => (row.name ? row.name : "—"),
      sortable: true,
      width: "160px",
    },
    {
      name: "Coupon type",
      selector: (row: any) => (row.coupon_type ? row.coupon_type : "—"),
      sortable: true,
    },
    {
      name: "Prefix",
      selector: (row: any) => (row.coupon_prefix ? row.coupon_prefix : "—"),
      sortable: true,
      width: "100px",
    },
    {
      name: "Valid from",
      selector: (row: any) =>
        row.valid_from
          ? moment(row.valid_from).format("DD-MM-YYYY, HH:mm a")
          : "—",
      sortable: false,
    },
    {
      name: "Valid to",
      selector: (row: any) =>
        row.valid_from
          ? moment(row.valid_to).format("DD-MM-YYYY, HH:mm a")
          : "—",
      sortable: false,
    },
    {
      name: "Total coupons",
      selector: (row: any) => (row.no_of_coupons ? row.no_of_coupons : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "View",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleRowClicked(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
          disabled={row && row.coupon_type === "Promotion Codes" ? false : true}
        >
          View Coupons
        </DefaultButton>
      ),
    },
    {
      name: "Used coupons",
      selector: (row: any) =>
        row.no_of_coupons_used ? row.no_of_coupons_used : "—",
      sortable: true,
      width: "150px",
    },
    {
      name: "Unused coupons",
      selector: (row: any) =>
        row.no_of_coupons_used
          ? `${row.no_of_coupons - row.no_of_coupons_used}`
          : "—",
      sortable: true,
      width: "160px",
    },
    {
      name: "Created by",
      selector: (row: any) => (row.created_by ? row.created_by : "—"),
      sortable: true,
    },
    {
      name: "Created at",
      selector: (row: any) =>
        row.creation_date
          ? moment(row.creation_date).format("DD-MM-YYYY, HH:mm a")
          : "—",
      sortable: true,
    },
    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleEditCoupon(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Edit
        </DefaultButton>
      ),
      omit: isCouponUser,
    },
  ];

  const handleRowClicked = (row: any) => {
    if (row && row.coupon_type === "Promotion Codes") {
      setIsShowManageModal(true);
      setModalData(row);
    }
  };

  const handleEditCoupon = (row: any) => {
    setNmdcManageModal(true);
    setModalId(row);
  };

  return (
    <>
      {isLoading && <Loader />}

      {!isCouponUser && (
        <div className=" mt-4">
          <div
            className={`${
              createCoupon
                ? "d-flex justify-content-between align-item-center"
                : "d-flex justify-content-end"
            }`}
          >
            {createCoupon && (
              <div>
                <h2 className="text-primary">Create Coupon</h2>
              </div>
            )}
            {!isViewer && (
              <div className="d-flex justify-content-end">
                {!createCoupon ? (
                  <DefaultButton
                    type="button"
                    variant="outline-primary"
                    className="default-action-button"
                    onClick={() => setIntialScreen(true)}
                  >
                    Create Coupon
                  </DefaultButton>
                ) : (
                  <DefaultButton
                    type="button"
                    variant="outline-primary"
                    className="default-action-button"
                    onClick={() => setIntialScreen(false)}
                  >
                    Back
                  </DefaultButton>
                )}
              </div>
            )}
          </div>

          {/* <h5>
          <span className="text-primary">* </span>
          Please note that only coupon validity dates can be edited. Other
          coupon information cannot be edited/deleted.
        </h5> */}
        </div>
      )}

      {createCoupon ? (
        <>
          <CreateCouponScreen
            setIntialScreen={setIntialScreen}
            handleReloadScreen={getCoupons}
          />
        </>
      ) : (
        <Row className="my-4 me-1 px-md-2">
          <div className="d-flex justify-content-end">
            <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="border-0"
                placeholder="Search by Coupon Type and Prefix"
                style={{ outline: "none", width: "320px" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
          <div className="mt-md-2">
            <DataTable
              pagination
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[25]}
              paginationPerPage={25}
              columns={columns}
              data={filterResult}
              customStyles={tableCustomStyles}
              // onRowClicked={handleRowClicked}
            />
          </div>
        </Row>
      )}
      {nmdcManageModal && (
        <EditCouponsModal
          showNmdcModal={nmdcManageModal}
          handleNmdcClose={handleNMDCCloseManageModal}
          modalId={modalId}
          setReRender={setReRender}
        />
      )}
      {isShowManageModal && (
        <CouponDetailModal
          showModal={isShowManageModal}
          handleClose={handleCloseManageModal}
          modalData={modalData}
          setReRender={setReRender}
        />
      )}
    </>
  );
};

export default CouponsTabScreen;
