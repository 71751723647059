import { Container, Row, Col } from "react-bootstrap";
import NoPayment from "../NoPayment/NoPayment";
import PaymentStatusCard from "components/Cards/PaymentStatusCard";
import { Payment } from "types/UserPaymentStatus";

interface PendingPaymentsProps {
  pendingPayments: Payment[];
}

const PaymentPending: React.FC<PendingPaymentsProps> = ({
  pendingPayments,
}) => {
  return (
    <Container>
      {pendingPayments?.length > 0 ? (
        <Row>
          {pendingPayments.map((individualPayment: Payment, id: number) => (
            <Col
              className="d-flex justify-content-sm-start justify-content-center"
              xs={12}
              md={6}
              xl={4}
              key={id}
            >
              <PaymentStatusCard payment={individualPayment} />
            </Col>
          ))}
        </Row>
      ) : (
        <NoPayment />
      )}
    </Container>
  );
};

export default PaymentPending;
