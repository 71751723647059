import useBootstrapSize from "utils/useBootstrapSize";
import { useState } from "react";
import PhotoCard from "components/Cards/PhotoCard";
import { Col, Container, Row, Breadcrumb, Image } from "react-bootstrap";
import { Photo } from "../../../types/Photo";
import { useNavigate } from "react-router-dom";
import styles from "../../../pages/EventsPage.module.scss";
import JoinUpComing from "./JoinUpComing";
import RecommendedEvents from "components/Dashboard/RecommendedEvents";
import ShareYourPhotos from "./ShareYourPhotos";
import FaqsComponent from "./FaqsComponent";
import NewsLetter from "./NewsLetter";
import { sortEventsByEndDate } from "utils/utils";
import { useEventList } from "api/EventApi";
import { Event } from "types/Event";
import Loader from "components/Loader/Loader";

const Photos = () => {
  const navigate = useNavigate();
  const screenSize = useBootstrapSize();
  const photo1: Photo = {
    id: 1,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery1.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };
  const photo2: Photo = {
    id: 2,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery2.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };
  const photo3: Photo = {
    id: 3,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery3.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };

  const photo4: Photo = {
    id: 4,
    title: "World Conference On Exercise Medicine",
    location: "Taran Pushkar, Bhopal",
    date: "03 JAN 2024",
    imageUrl: "/images/gallery4.png",
    organizerLogo: "url(/images/ACEM-logo.png)",
    organizerName: "ACEM, IFCSS",
  };

  const photo5: Photo = {
    id: 5,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery5.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };

  const photo6: Photo = {
    id: 6,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery6.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };
  const {
    data: recommendedEvents,
    isLoading: recommendedEventsLoading,
    isError: recommendedEventsError,
  } = useEventList();

  const sortedEvents = sortEventsByEndDate(recommendedEvents?.data);

  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs" || screenSize === "md"
      ? 1
      : screenSize === "lg"
      ? 2
      : 3;

  const [startIndex, setStartIndex] = useState(0);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };
  const handleNext = () => {
    if (startIndex + CHUNK_SIZE < sortedEvents?.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };

  // Check if there are items to show for PrevIcon
  const showPrevItems = startIndex > 0;

  // Check if there are items to show for NextIcon
  const showNextItems = startIndex + CHUNK_SIZE < sortedEvents?.length;

  const currentEvents: Event[] = Array.isArray(sortedEvents)
    ? sortedEvents?.slice(startIndex, startIndex + CHUNK_SIZE)
    : [];

  if (recommendedEventsLoading) {
    return <Loader />;
  }

  if (recommendedEventsError) {
    return <p>Error fetching data</p>;
  }

  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigate("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active> Photos</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.coverImage}`}>
          <Image
            src="/images/PhotoBanner.png"
            width="100%"
            style={{ height: "437px" }}
            alt="PhotoBanner"
          />
        </div>
      </Container>
      <Container>
        <h1 className="text-lightBlack fs-40">Marathon Memories</h1>
        <h6 className="text-deepBlack noto_font_lists">
          Browse through our collection of stunning marathon event photos
        </h6>
        <Row className="mt-4">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo1} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo2} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo3} className="resposiveSmallerCardSize" />
          </Col>
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo4} className="resposiveBiggerCardSize" />
          </Col>
        </Row>{" "}
        <Row className="mt-3">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo5} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo6} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
      </Container>
      <JoinUpComing />
      {currentEvents?.length > 0 && (
        <RecommendedEvents
          currentEvents={currentEvents}
          handlePrev={handlePrev}
          handleNext={handleNext}
          title="Recommended Events"
          showPrevItems={showPrevItems}
          showNextItems={showNextItems}
        />
      )}

      <Container>
        <Row className="my-5">
          <Col md={5} className="my-5 align-items-center d-flex">
            <Row>
              <div className="mt-2 pt-3 justify-content-center d-flex">
                <h1 className="text-lightBlack fs-40 fw-700 font-saira px-4">
                  Capture and Share Your Marathon Memories with the iFinish
                  Community
                </h1>
              </div>

              <div className="mt-2">
                <h6 className="text-deepBlack noto_font_lists px-4">
                  Join the iFinish community and showcase your marathon
                  achievements through stunning event photos.
                </h6>
                <div className="px-4 mt-md-5">
                  <h6 className="d-flex align-items-center">
                    <Image src="/images/Tick.svg" alt="Tick" />
                    <span className="fw-600 fs-16 font-saira ms-md-3">
                      Preserve Your Marathon Journey Forever
                    </span>
                  </h6>
                  <h6 className="d-flex align-items-center my-md-4">
                    <Image src="/images/Tick.svg" alt="Tick" />
                    <span className="fw-600 fs-16 font-saira ms-md-3">
                      Relive the Thrill of Crossing the Finish Line
                    </span>
                  </h6>
                  <h6 className="d-flex align-items-center">
                    <Image src="/images/Tick.svg" alt="Tick" />
                    <span className="fw-600 fs-16 font-saira ms-md-3">
                      Share Your Marathon Success with the World
                    </span>
                  </h6>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={7}>
            <Image
              src="/images/CaptureImage.svg"
              width="100%"
              style={{ height: "543px" }}
              alt="RunningImage"
            />
          </Col>
        </Row>
      </Container>
      <ShareYourPhotos />
      <FaqsComponent />
      <NewsLetter />
    </>
  );
};

export default Photos;
