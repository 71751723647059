import CouponIntialPage from "OrganizerPages/eventsforms/CouponIntialPage";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { Icon } from "components/Icon";
import { useState } from "react";
import { useAuth } from "context/AuthContext";
import useEventName from "utils/useEventName";

const CreateCoupon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const Navigate = useNavigate();
  const isEventName = useEventName();
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Coupon Dashboard
              </span>
            </div>
            <h6 className="fs-24 mt-md-2 ">{isEventName}</h6>
          </Col>
        </Row>
        <CouponIntialPage />
      </Container>
    </>
  );
};

export default CreateCoupon;
