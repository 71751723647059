export const divStyle: React.CSSProperties = {
  backgroundImage: "url(/images/Combine_login.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  width: "100%",
  height: "100vh", // Set the desired height
};
export const transparentBoxStyle: React.CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the alpha value for transparency
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const formContainerStyle: React.CSSProperties = {
  padding: "20px",
  backgroundColor: "white",
  borderRadius: "8px",
  maxWidth: "800px",
  maxHeight: window.innerWidth < 1400 ? "580px" : "",
  overflow: "hidden",
  borderColor: "#dee0fa",
  boxShadow: "0px 0px 25px 0px rgba(13, 15, 31, 0.07)",
};
