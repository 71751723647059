import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Form as Formdata, Formik } from "formik";
import { Col, Row, Form } from "react-bootstrap";
import { object, string, number } from "yup";
import { toast, ToastContainer } from "react-toastify";
import { TextField } from "utils/FormControls";
// import axios from "utils/axios";
import { useParams, useLocation } from "react-router-dom";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import ViewEvent from "utils/ViewEvent";
import Loader from "components/Loader/Loader";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const EmailForm = ({ setActiveTab }: any) => {
  const { id } = useParams();
  const { token } = useAuth();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  const isViewEvent = ViewEvent();
  // let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { storeEventId } = useEvent();
  const [emailData, setEmailData] = useState([]);
  const [isApproveEmail, setIsApproveEmail] = useState(false);
  const [generalContent, setGeneralContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [approvalContent, setApprovalContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [rejectContent, setRejectContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [moreInfoContent, setMoreInfoContent] = useState(
    "Thank you for participating in the Ifinish event"
  );

  const [updateGeneralContent, setUpdateGeneralContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [updateApprovalContent, setUpdateApprovalContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [updateRejectContent, setUpdateRejectContent] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [updateMoreInfoContent, setUpdateMoreInfoContent] = useState(
    "Thank you for participating in the Ifinish event"
  );

  const [generalSubject, setGeneralSubject] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [approvalSubject, setApprovalSubject] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [rejectSubject, setRejectSubject] = useState(
    "Thank you for participating in the Ifinish event"
  );
  const [moreInfoSubject, setMoreInfoSubject] = useState(
    "Thank you for participating in the Ifinish event"
  );

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleGeneralEditorChange = (content: any) => {
    setUpdateGeneralContent(content);
  };
  const handleApprovalEditorChange = (content: any) => {
    setUpdateApprovalContent(content);
  };
  const handleRejectEditorChange = (content: any) => {
    setUpdateRejectContent(content);
  };
  const handleMoreInfoEditorChange = (content: any) => {
    setUpdateMoreInfoContent(content);
  };

  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];

  function getEmailTemplate() {
    // axios
    //   .post(
    //     "/getEmailTemplate",
    //     {
    //       eventId: Id || storeEventId,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         Accept: "application/json",
    //       },
    //     }
    //   )
    axiosPrivate({
      url: "/getEmailTemplate",
      data: {
        eventId: Id || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        setIsApproveEmail(res.data.data.emailStatus === 1 ? true : false);
        setEmailData(res.data.data);

        // eslint-disable-next-line array-callback-return
        res.data.data.emailTemplates.map((emailData: any) => {
          if (emailData.name === "approved") {
            setApprovalSubject(
              emailData.email_template_subject === ""
                ? "Approval Subject"
                : emailData.email_template_subject
            );
            setApprovalContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );

            setUpdateApprovalContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
          } else if (emailData.name === "rejected") {
            setRejectSubject(
              emailData.email_template_subject === ""
                ? "Reject Subject"
                : emailData.email_template_subject
            );
            setRejectContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
            setUpdateRejectContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
          } else if (emailData.name === "moreinfo") {
            setMoreInfoSubject(
              emailData.email_template_subject === ""
                ? "More Info Subject"
                : emailData.email_template_subject
            );
            setMoreInfoContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
            setUpdateMoreInfoContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
          } else {
            setGeneralSubject(
              emailData.email_template_subject === ""
                ? ""
                : emailData.email_template_subject
            );
            setGeneralContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );

            setUpdateGeneralContent(
              emailData.email_template_body === ""
                ? "Thank you for participating in the Ifinish event"
                : emailData.email_template_body
            );
          }
        });
        // setGeneralContent(res.data.data);
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id) {
      getEmailTemplate();
    }
  }, [Id]);

  const editorToolbar =
    // "undo redo | blocks | " +
    // "bold italic forecolor | alignleft aligncenter " +
    // "alignright alignjustify | bullist numlist outdent indent | " +
    // "removeformat | help" +
    // "image"
    "undo redo | cut copy paste | bold italic underline strikethrough subscript superscript | bullist numlist | alignleft aligncenter alignright alignjustify outdent indent | link image table";

  const editorContentStyle =
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

  const editorAPIKey = process.env.REACT_APP_TINY_API_KEY;

  const EmailInitialValue = {
    allowApprovalEmail: isApproveEmail === true ? "1" : "0",
    approvalSubject: approvalSubject,
    generalSubject: generalSubject,
    rejectSubject: rejectSubject,
    moreSubject: moreInfoSubject,
  };

  const emailApprovalYesSchema = object({
    allowApprovalEmail: string(),
    approvalSubject: string(),
    generalSubject: string(),
    rejectSubject: string(),
    moreSubject: string(),
    generalContent: string(),
    approvalContent: string(),
    rejectContent: string(),
    moreInfoContent: string(),
  });

  const handleEmailSubmit = (values: any) => {
    let EventId = storeEventId || Id ? storeEventId || Id : null;
    if (EventId === null) {
      toast.error("Please fill Basic details");
    } else {
      setIsLoading(true);
      const {
        allowApprovalEmail,
        approvalSubject,
        generalSubject,
        moreSubject,
        rejectSubject,
      } = values;

      // console.log("id", EventId, allowApprovalEmail, approvalContent);
      const postData = {
        // isEmail: allowApprovalEmail,
        eventId: `${EventId}`,
        isEmail: `${allowApprovalEmail}`,
        templates: [
          {
            name: "approved",
            emailSubject: allowApprovalEmail === "1" ? approvalSubject : "",
            emailBody: allowApprovalEmail === "1" ? updateApprovalContent : "",
          },
          {
            name: "rejected",
            emailSubject: allowApprovalEmail === "1" ? `${rejectSubject}` : "",
            emailBody:
              allowApprovalEmail === "1" ? `${updateRejectContent}` : "",
          },
          {
            name: "moreinfo",
            emailSubject: allowApprovalEmail === "1" ? `${moreSubject}` : "",
            emailBody:
              allowApprovalEmail === "1" ? `${updateMoreInfoContent}` : "",
          },
          {
            name: "general",
            emailSubject: `${generalSubject}`,
            emailBody: `${updateGeneralContent}`,
          },
        ],
      };
      // const data = JSON.stringify(postData);

      // axios.post("/eventEmailTemplate", JSON.stringify(postData), {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     Accept: "application/json",
      //   },
      // });
      axiosPrivate({
        url: "/eventEmailTemplate",
        data: JSON.stringify(postData),
        method: "POST",
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.success(`${res.data.message}`, { autoClose: 1000 });
            setTimeout(() => {
              setActiveTab(3);
            }, 2000);
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            Object.keys(res.data.message).forEach((key) => {
              res.data.message[key].forEach((message: any) => {
                // console.log(`${key}: ${message}`);
                toast.error(` ${message}`);
              });
            });
          }
        })
        .catch((err) => {
          console.log("email error", err);
          return;
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleEditorChange = (content: string, editor: any) => {
    console.log("Content was updated:", content);
    // editor.on("change", () => {
    //   handleGeneralEditorChange(editor.getContent());
    // });
  };

  const handleKeyDown = (e: any, editor: any) => {
    console.log("Key down:", e.keyCode);
    // Your custom logic for keydown event
  };

  const handleEditorSetup = (editor: any) => {
    editor.on("keydown", function (e: any) {
      handleKeyDown(e, editor);
    });
    editor.on("change", function () {
      // handleEditorChange(editor.getContent(), editor);
      // handleGeneralEditorChange(editor.getContent());
    });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ToastContainer />
      <Formik
        validationSchema={emailApprovalYesSchema}
        onSubmit={handleEmailSubmit}
        initialValues={EmailInitialValue}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="px-0 mx-3 d-flex flex-column justify-content-center">
              <Row>
                <Col md={12}>
                  <Form.Label className="my-2">
                    Are participant approval emails required?{" "}
                    <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Group>
                    <Form.Group>
                      <Form.Check
                        inline
                        label="Yes"
                        name="allowApprovalEmail"
                        type="radio"
                        onChange={handleChange}
                        isInvalid={!!errors.allowApprovalEmail}
                        value="1"
                        checked={values.allowApprovalEmail === "1"}
                        disabled={isViewEvent}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="allowApprovalEmail"
                        type="radio"
                        onChange={handleChange}
                        isInvalid={!!errors.allowApprovalEmail}
                        value="0"
                        checked={values.allowApprovalEmail === "0"}
                        disabled={isViewEvent}
                      />
                    </Form.Group>
                    {values.allowApprovalEmail === "1" && (
                      <>
                        <div>
                          <Row className="mt-md-4">
                            <Col md={12}>
                              <h2 className="text-primary">
                                {" "}
                                Participant Registration Email Template
                              </h2>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12}>
                              <TextField
                                type="text"
                                name="generalSubject"
                                label="Subject *"

                                // value={isBasicDetailsData?.company_name}
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={12}>
                              <p className="text-primary fw-400 fs-16 my-3">
                                <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Category Name {{category_name}}, T-shirt Size {{tshirt_size}}, Gender {{gender}}, Date Of Birth {{dob}}, Race Date {{race_date}}, Charity Name {{charity_name}}, Charity Amount {{charity_amount}}, Charity Bib Name {{charity_bib_name}}, Charity Bib Amount {{charity_bib_amount}} , Pre Order Photos {{pre_order_photos}}`}</span>
                              </p>
                              <p className="text-darkgray fw-400 fs-16 my-3">
                                Body
                                <span className="text-primary ms-1">*</span>
                              </p>
                            </Col>
                          </Row>
                          <Editor
                            apiKey={editorAPIKey}
                            disabled={isViewEvent}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={generalContent}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: editorPlugins,
                              toolbar: editorToolbar,
                              content_style: editorContentStyle,
                              setup: (editor: {
                                on: (arg0: string, arg1: () => void) => void;
                                getContent: () => any;
                              }) => {
                                editor.on("change", () => {
                                  handleGeneralEditorChange(
                                    editor.getContent()
                                  );
                                });
                              },

                              // setup: function (editor) {
                              //   editor.on("keydown", function (e) {
                              //     if (e.key === "Enter") {
                              //       editor.execCommand("mceInsertNewline");
                              //       e.preventDefault();
                              //     }
                              //   });
                              // },

                              // setup: handleEditorSetup, //
                            }}
                            // onEditorChange={handleEditorChange}
                          />
                        </div>

                        <div>
                          <Row className="mt-md-4">
                            <Col md={12}>
                              <h2 className="text-primary">
                                {" "}
                                Participant approved email template
                              </h2>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12}>
                              <TextField
                                type="text"
                                name="approvalSubject"
                                label="Subject *"

                                // value={isBasicDetailsData?.company_name}
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={12}>
                              <p className="text-primary fw-400 fs-16 my-3">
                                <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Category Name {{category_name}}, T-shirt Size {{tshirt_size}}, Gender {{gender}}, Date Of Birth {{dob}}, Race Date {{race_date}}`}</span>
                              </p>
                              <p className="text-darkgray fw-400 fs-16 my-3">
                                Body
                                <span className="text-primary ms-1">*</span>
                              </p>
                            </Col>
                          </Row>
                          <Editor
                            apiKey={editorAPIKey}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={approvalContent}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: editorPlugins,
                              toolbar: editorToolbar,
                              content_style: editorContentStyle,
                              setup: (editor: {
                                on: (arg0: string, arg1: () => void) => void;
                                getContent: () => any;
                              }) => {
                                editor.on("change", () => {
                                  handleApprovalEditorChange(
                                    editor.getContent()
                                  );
                                });
                              },
                            }}
                          />
                        </div>

                        <div>
                          <Row className="mt-md-4">
                            <Col md={12}>
                              <h2 className="text-primary">
                                {" "}
                                Participant rejected email template
                              </h2>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12}>
                              <TextField
                                type="text"
                                name="rejectSubject"
                                label="Subject *"

                                // value={isBasicDetailsData?.company_name}
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={12}>
                              <p className="text-primary fw-400 fs-16 my-3">
                                <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Event {{event}}, Rejected Fields {{rejected_Fields}}, Remarks {{remarks}}`}</span>
                              </p>
                              <p className="text-darkgray fw-400 fs-16 my-3">
                                Body
                                <span className="text-primary ms-1">*</span>
                              </p>
                            </Col>
                          </Row>
                          <Editor
                            apiKey={editorAPIKey}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={rejectContent}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: editorPlugins,
                              toolbar: editorToolbar,
                              content_style: editorContentStyle,
                              setup: (editor: {
                                on: (arg0: string, arg1: () => void) => void;
                                getContent: () => any;
                              }) => {
                                editor.on("change", () => {
                                  handleRejectEditorChange(editor.getContent());
                                });
                              },
                            }}
                          />
                        </div>

                        <div>
                          <Row className="mt-md-4">
                            <Col md={12}>
                              <h2 className="text-primary">
                                {" "}
                                Participant more information needed email
                                template
                              </h2>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12}>
                              <TextField
                                type="text"
                                name="moreSubject"
                                label="Subject *"

                                // value={isBasicDetailsData?.company_name}
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={12}>
                              <p className="text-darkgray fw-400 fs-16 my-3">
                                Body
                                <span className="text-primary ms-1">*</span>
                              </p>
                              <p className="text-primary fw-400 fs-16 my-3">
                                <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Event {{event}}, Rejected Fields {{rejected_Fields}}, Remarks {{remarks}}`}</span>
                              </p>
                            </Col>
                          </Row>
                          <Editor
                            apiKey={editorAPIKey}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={moreInfoContent}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: editorPlugins,
                              toolbar: editorToolbar,
                              content_style: editorContentStyle,
                              setup: (editor: {
                                on: (arg0: string, arg1: () => void) => void;
                                getContent: () => any;
                              }) => {
                                editor.on("change", () => {
                                  handleMoreInfoEditorChange(
                                    editor.getContent()
                                  );
                                });
                              },
                            }}
                          />
                        </div>
                      </>
                    )}

                    {values.allowApprovalEmail === "0" && (
                      <>
                        <div>
                          <Row className="mt-md-4">
                            <Col md={12}>
                              <h2 className="text-primary">
                                {" "}
                                Participant Registration Email Template
                              </h2>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12}>
                              <TextField
                                type="text"
                                name="generalSubject"
                                label="Subject *"

                                // value={isBasicDetailsData?.company_name}
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={12}>
                              <p className="text-darkgray fw-400 fs-16 my-3">
                                Body
                                <span className="text-primary ms-1">*</span>
                              </p>
                              <p className="text-primary fw-400 fs-16 my-3">
                                <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Category Name {{category_name}}, T-shirt Size {{tshirt_size}}, Gender {{gender}}, Date Of Birth {{dob}}, Race Date {{race_date}}`}</span>
                              </p>
                            </Col>
                          </Row>
                          <Editor
                            apiKey={editorAPIKey}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={generalContent}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: editorPlugins,
                              toolbar: editorToolbar,
                              content_style: editorContentStyle,
                              setup: (editor: {
                                on: (arg0: string, arg1: () => void) => void;
                                getContent: () => any;
                              }) => {
                                editor.on("change", () => {
                                  handleGeneralEditorChange(
                                    editor.getContent()
                                  );
                                });
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-md-3 d-flex align-items-center justify-content-end">
                {!isViewEvent && (
                  <Col className="d-flex justify-content-end">
                    <DefaultButton
                      type="submit"
                      variant="outline-primary"
                      className=" default-action-button"
                    >
                      Submit
                    </DefaultButton>
                  </Col>
                )}
              </Row>
            </div>
          </Form>
        )}
      </Formik>

      {/* <Row className="my-md-3 d-flex align-items-center justify-content-end">
        <Col className="d-flex justify-content-end">
          <DefaultButton
            type="button"
            variant="outline-primary"
            className=" default-action-button"
            onClick={handleEmailSubmit}
          >
            Submit
          </DefaultButton>
        </Col>
      </Row> */}
    </div>
  );
};

export default EmailForm;
