import React, { useState } from "react";
import Loader from "components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import axios from "utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import { PasswordField, TextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";

const ForgotPassword = () => {
  const { id } = useParams();
  const axiosPublic = useAxiosPublic();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
  });

  const submitHandler = (values: any) => {
    setIsLoading(true);

    // axios
    //   .post(
    //     "/resetPassword",
    //     {
    // token: id,
    // password: values.password,
    // password_confirmation: values.confirmPassword,
    //     },
    //     {
    //       headers: {
    //         Accept: "application/json",
    //       },
    //     }
    //   )
    axiosPublic({
      url: "/resetPassword",
      data: {
        token: id,
        password: values.password,
        password_confirmation: values.confirmPassword,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            navigate("/org/login");
          }, 2000);
        } else {
          toast.error(`${message}`, { autoClose: 1500 });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="d-none d-md-block">
        <nav className="shadow-sm px-3 bg-white rounded py-md-4">
          <Container fluid className="d-flex align-items-center py-md-2 m-0">
            <div className="w-100">
              <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
            </div>
            <div className="mx-4">
              <img src="/images/ifinish-logo.png" alt="Logo" />
            </div>
            <div className="w-100">
              <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
            </div>
          </Container>
        </nav>

        <Container className="d-flex justify-content-center py-md-5">
          <Card style={{ width: "35rem" }}>
            <Card.Body className="px-md-4">
              <div className="d-flex justify-content-center pt-md-2">
                <h2>Reset Password</h2>
              </div>

              <Card.Text className="py-md-3 ">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={submitHandler}
                >
                  <Form>
                    <div>
                      <TextField
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="mobile"
                      />
                    </div>
                    <div className="my-md-4">
                      <PasswordField
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        label="Confirm Password"
                        placeholder="confirm Password"
                        showPassword={showConfirmPassword}
                        togglePasswordVisibility={showConfirmPasswordHandler}
                      />
                    </div>

                    <div className="d-flex justify-content-center mb-md-4">
                      <DefaultButton
                        variant="outline-primary"
                        className="w-100 default-action-button"
                        type="submit"
                      >
                        Submit
                      </DefaultButton>
                    </div>
                  </Form>
                </Formik>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <div className="d-block d-md-none">
        <Container className="d-flex flex-column py-md-5">
          <div className="py-4 d-flex justify-content-center align-items-center">
            <img src="/images/ifinish-logo.png" alt="Logo" width={160} />
          </div>
          <div className="pb-4 d-flex justify-content-center align-items-center">
            <h4>Reset Password</h4>
          </div>
          <Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
            >
              <Form>
                <Col className="col-12">
                  <TextField
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="mobile"
                  />
                </Col>
                <Col className="col-12 my-4">
                  <PasswordField
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    placeholder="confirm Password"
                    showPassword={showConfirmPassword}
                    togglePasswordVisibility={showConfirmPasswordHandler}
                  />
                </Col>

                <Col className="col-12">
                  <DefaultButton
                    variant="outline-primary"
                    className="w-100 default-action-button"
                    type="submit"
                  >
                    Submit
                  </DefaultButton>
                </Col>
              </Form>
            </Formik>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
