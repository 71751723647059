import React, { useEffect, useState } from "react";
import Badge from "../../../components/Badge/Badge";
import EventPriceCard from "components/EventPriceCard/EventPriceCard";
import { filterOptions } from "../../../api/EventDetails";
import styles from "./PriceOptions.module.scss";
import { Race } from "types/Race";
import { UpdateRace } from "types/UpdateRace";

type PriceOptionsProps = {
  events: Race[];
  serverTime: string;
  upgradingRaceInfo: UpdateRace | null;
  isFromUpdateDetails?: boolean;
};
const PriceOptions: React.FC<PriceOptionsProps> = ({
  events,
  serverTime,
  upgradingRaceInfo,
  isFromUpdateDetails,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Race[]>(events || []);

  const handleSelectFilterActive = (id: number) => {
    const filter = filterOptions[id];
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((item) => item !== filter)
        : [...prevFilters, filter]
    );
  };

  useEffect(() => {
    const newFilteredEvents = events?.filter((race: any) => {
      if (selectedFilters.length === 0 || selectedFilters.includes("All")) {
        return true;
      }

      return selectedFilters.every((filter) => {
        return race.age.some(
          (ageCategory: any) => ageCategory.gender === filter.toLowerCase()
        );
      });
    });

    setFilteredEvents(newFilteredEvents);
  }, [selectedFilters, events]);

  return (
    <div className={`${styles.priceOptions}`}>
      {/* <div
        className={`${styles.scrollContainer} d-none d-sm-flex justify-content-left align-items-center mt-3 mb-4 `}
      >
        {filterOptions.map((filter: string, index: number) => {
          return (
            <div key={index} className="pe-2">
              <Badge
                onClick={() => handleSelectFilterActive(index)}
                className={`px-3 py-1 ${
                  selectedFilters.includes(filter)
                    ? "badge-active"
                    : "badge-inactive"
                } badge-common
            `}
              >
                {filter}
              </Badge>
            </div>
          );
        })}
      </div> */}

      {isFromUpdateDetails && (
        <p className="my-3 text-danger text-center">
          Note: Category can only be changed once per booking.
        </p>
      )}

      <div className={`${styles.pricesPaddingX}`}>
        {events?.map((race: Race) => {
          return (
            <div key={race?.id} className={`${styles.eventPriceCard} mb-2 p-0`}>
              <EventPriceCard
                serverTime={serverTime}
                key={race?.id}
                race={race}
                upgradingRaceInfo={upgradingRaceInfo}
                isFromUpdateDetails={isFromUpdateDetails}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PriceOptions;
