import React from "react";
import { Button } from "react-bootstrap";
import { ReactNode } from "react";

type ButtonProps = {
  onClick?: () => void;
  variant: string;
  className: string;
  children: ReactNode;
  type: "button" | "submit" | "reset";
  disabled?: boolean;
};

const DefaultButton: React.FC<ButtonProps> = ({
  className,
  onClick,
  variant,
  type,
  children,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant={variant}
      type={type}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default DefaultButton;
