import { FocusEvent } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

interface DefaultTextAreaProps {
  label: string;
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  className?: string;
  isError?: boolean;
  error?: any;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  rows?: number;
}

const DefaultTextArea: React.FC<DefaultTextAreaProps> = ({
  label,
  placeholder,
  onChange,
  onBlur,
  value,
  className,
  isError,
  error,
  disabled,
  readOnly,
  rows = 3,
  name,
}) => {
  return (
    <>
      <FloatingLabel
        controlId={name}
        label={label}
        className={`mb-1 custom-floating-label ${className} ${
          isError ? "error-border" : ""
        }`}
      >
        <Form.Control
          style={{ height: "100%" }}
          as="textarea"
          name={name}
          placeholder={placeholder}
          value={value}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          isInvalid={!!isError}
          disabled={disabled}
          readOnly={readOnly}
        />
      </FloatingLabel>
      {isError && <div className="text-danger fs-14">{error}</div>}
    </>
  );
};

export default DefaultTextArea;
