import React, { useState } from "react";
import { Container, Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./Organizer.module.scss";
import RecommendedEvents from "components/Dashboard/RecommendedEvents";
import useBootstrapSize from "utils/useBootstrapSize";
import DashboardCTA from "components/Dashboard/DashboardCTA";
import PhotoCard from "components/Cards/PhotoCard";
import { Photo } from "types/Photo";

import NewsLetter from "components/FooterComponents/Photos/NewsLetter";
import FaqsComponent from "components/FooterComponents/Photos/FaqsComponent";
import { useEventList } from "api/EventApi";
import Loader from "components/Loader/Loader";

const OrganizerPage = () => {
  const navigation = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const screenSize = useBootstrapSize();
  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs"
      ? 1
      : screenSize === "md" || screenSize === "lg"
      ? 2
      : 3;

  const {
    data: recommendedEvents,
    isLoading: recommendedEventsLoading,
    isError: recommendedEventsError,
  } = useEventList();

  const currentEvents = recommendedEvents.slice(
    startIndex,
    startIndex + CHUNK_SIZE
  );
  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };
  const handleNext = () => {
    if (startIndex + CHUNK_SIZE < recommendedEvents.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };

  // Check if there are items to show for PrevIcon
  const showPrevItems = startIndex > 0;

  // Check if there are items to show for NextIcon
  const showNextItems = startIndex + CHUNK_SIZE < recommendedEvents?.length;

  const photo1: Photo = {
    id: 1,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery1.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };
  const photo2: Photo = {
    id: 2,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery2.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };
  const photo3: Photo = {
    id: 3,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery3.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };

  const photo4: Photo = {
    id: 4,
    title: "World Conference On Exercise Medicine",
    location: "Taran Pushkar, Bhopal",
    date: "03 JAN 2024",
    imageUrl: "/images/gallery4.png",
    organizerLogo: "url(/images/ACEM-logo.png)",
    organizerName: "ACEM, IFCSS",
  };

  const photo5: Photo = {
    id: 5,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery5.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };

  const photo6: Photo = {
    id: 6,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery6.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };

  if (recommendedEventsLoading) {
    return <Loader />;
  }

  if (recommendedEventsError) {
    return <p>Error fetching data</p>;
  }

  return (
    <>
      <Container className="my-md-3">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Marathon</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <Container className="my-md-3">
        <div className={`${styles.OrgcoverImage}`}>
          <Image src="/images/OrgPage.svg" width="100%" alt="OrgBanner" />
        </div>
        <div className="row d-flex justify-content-between py-md-3 px-md-3 p-3 bg-Marker">
          <div className="col-md-6 col-12 d-flex flex-column">
            <p className="font-saira fw-700 fs-14 p-0 m-0 my-md-2">
              Discover our latest offers and updates
            </p>
            <p className="ff-ns fw-400 fs-14 p-0 m-0">
              Stay informed with our timely information and promotions
            </p>
          </div>
          <div className="col-md-6 col-12 my-2 my-md-0 d-flex align-items-center justify-content-md-end">
            <div className="mx-md-3">
              <Image src="/images/twitter.svg" alt="Twitter" />
            </div>
            <div className="mx-md-3">
              <Image
                src="/images/icon-park-outline_telegram (1).svg"
                alt="Telegram"
              />
            </div>
            <div className="mx-md-3">
              <Image
                src="/images/icon-park-outline_facebook (1).svg"
                alt="Facebook"
              />
            </div>
            <div className="mx-md-3">
              <Image
                src="/images/icon-park-outline_instagram (1).svg"
                alt="Instagram"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container className="my-md-5 my-3 bg-diffBlack">
        <Row className="py-md-4 px-md-4 p-3">
          <Col className="col-md-3 col-12 px-md-4 py-3 py-md-0">
            <Image src="/images/calendra.svg" alt="MarathonBaner" />
            <h4 className="text-Marker fw-700 fs-20 ff-saira">
              Event
              <br /> Planning
            </h4>
            <hr style={{ border: " 1px solid #FFFFFF" }} />
            <p className="text-white fw-400 fs-16">Attention to Detail</p>
          </Col>
          <Col className="col-md-3 col-12 px-md-4 py-3 py-md-0">
            <Image src="/images/editSetting.svg" alt="MarathonBaner" />
            <h4 className="text-Marker fw-700 fs-20 ff-saira">
              Course
              <br /> Management
            </h4>
            <hr style={{ border: " 1px solid #FFFFFF" }} />
            <p className="text-white fw-400 fs-16">AIMS</p>
          </Col>
          <Col className="col-md-3 col-12 px-md-4 py-3 py-md-0">
            {" "}
            <Image src="/images/profile.svg" alt="MarathonBaner" />
            <h4 className="text-Marker fw-700 fs-20 ff-saira">
              Safety and
              <br /> Security
            </h4>
            <hr style={{ border: " 1px solid #FFFFFF" }} />
            <p className="text-white fw-400 fs-16">
              Emergency Preparedness
            </p>{" "}
          </Col>
          <Col className="col-md-3 col-12 px-md-4 py-3 py-md-0">
            {" "}
            <Image src="/images/SettingIcon.svg" alt="MarathonBaner" />
            <h4 className="text-Marker fw-700 fs-20 ff-saira">
              Race Day
              <br /> Operations
            </h4>
            <hr style={{ border: " 1px solid #FFFFFF" }} />
            <p className="text-white fw-400 fs-16">Event Execution</p>{" "}
          </Col>
        </Row>
      </Container>

      <Container className="my-md-5 my-4">
        <Row>
          <Col className="col-md-6 col-12">
            <Image
              src="/images/RunningInOrg.svg"
              width="100%"
              alt="MarathonBaner"
            />
          </Col>
          <Col className="col-md-6 col-12">
            <Row>
              <Col className="col-12">
                <h4 className="ff-saira fw-600 fs-24">About:</h4>
                <p className="ff-ns fw-400 fs-14">
                  At Kaambhari, we believe that education is the key to
                  unlocking the potential within every individual. We are a
                  dedicated social group based in Mumbai, India, working
                  tirelessly to support underprivileged students in their
                  educational journey. Our mission is to bridge the educational
                  gap and empower these deserving individuals to create a
                  brighter future for themselves.
                </p>
              </Col>
              <Col className="col-12">
                <h4 className="ff-saira fw-600 fs-24">Our Vision</h4>
                <p className="ff-ns fw-400 fs-14">
                  We envision a society where every child has equal access to
                  quality education, regardless of their socio-economic
                  background. We believe that education is not just a privilege
                  but a fundamental right that should be accessible to all.
                  Through our initiatives, we strive to break down barriers and
                  empower students to realize their dreams and aspirations.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-md-5">
          <p className="ff-ns fw-400 fs-16">
            Organizers are responsible for accurately recording race times,
            compiling results, and distributing awards and prizes to top
            finishers in various categories.
          </p>
        </Row>
        <Row>
          <Col className="col-md-6 col-12">
            <Row>
              <Col className="col-12">
                <h4 className="ff-saira fw-600 fs-24">Our Impact:</h4>
                <p className="ff-ns fw-400 fs-14">
                  Since our inception, Kaambhari has impacted the lives of
                  numerous underprivileged students. We have witnessed stories
                  of resilience, determination, and success as our beneficiaries
                  go on to pursue higher education, secure employment, and
                  become change-makers in their communities. Our aim is to
                  create a ripple effect of empowerment, where each student we
                  support becomes an agent of change, uplifting their families
                  and communities.
                </p>
              </Col>
              <Col className="col-12">
                <h4 className="ff-saira fw-600 fs-24">
                  Join Us: Together, We Can Make a Difference!
                </h4>
                <p className="ff-ns fw-400 fs-14">
                  We believe that education is a collective responsibility. We
                  invite individuals, corporations, and organizations to join
                  hands with us in our mission to empower education and
                  transform lives. Whether through volunteering, donations, or
                  partnerships, your support can make a significant impact in
                  shaping a brighter future for underprivileged students.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="col-md-6 col-12">
            <Image
              src="/images/kaambhari-teeShirt.svg"
              width="100%"
              alt="MarathonBaner"
            />
          </Col>
        </Row>
        <div className="my-md-5">
          <hr></hr>
        </div>
      </Container>
      <Container>
        {currentEvents?.length > 0 && (
          <RecommendedEvents
            currentEvents={currentEvents}
            handlePrev={handlePrev}
            handleNext={handleNext}
            title="Recommended Events"
            showPrevItems={showPrevItems}
            showNextItems={showNextItems}
          />
        )}
      </Container>
      <div className="my-md-5">
        <DashboardCTA />
      </div>
      <Container>
        <h1 className="text-lightBlack fs-40">Marathon Memories</h1>
        <h6 className="text-deepBlack noto_font_lists">
          Browse through our collection of stunning marathon event photos
        </h6>
        <Row className="mt-4">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo1} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo2} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo3} className="resposiveSmallerCardSize" />
          </Col>
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo4} className="resposiveBiggerCardSize" />
          </Col>
        </Row>{" "}
        <Row className="mt-3">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo5} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo6} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
      </Container>
      <div className="my-md-5">
        <NewsLetter />
      </div>
      <div className="my-md-5">
        <FaqsComponent />
      </div>
    </>
  );
};

export default OrganizerPage;
