import React, { useState } from "react";
import { Tab, Tabs, Container, Row, Col } from "react-bootstrap";
import ExpoDashboard from "../expodashboard/ExpoDashboard";
import { useNavigate } from "react-router-dom";
import { Icon } from "components/Icon";
import useEventName from "utils/useEventName";

const SupportEventDashboard = () => {
  const navigate = useNavigate();
  const eventName = useEventName();

  const [activeTab, setActiveTab] = useState<string | null>("expodashboard");
  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };
  return (
    <Container className="mt-4">
      <Row className="mt-4">
        <Col>
          <div className="d-flex align-items-center">
            <span
              className="text-primary fs-12 fw-600"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              <Icon iconName="ChevronLeft" size={18} className="me-2" />
              Back to Events
            </span>
          </div>
          <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={"expodashboard"}
        id="event-home-tabs"
        className="event-home-nav-tabs border-0"
        onSelect={handleSelectTab}
      >
        <Tab eventKey="expodashboard" title="Expo Dashboard">
          {activeTab === "expodashboard" && <ExpoDashboard />}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default SupportEventDashboard;
