import { TrainingPrograms } from "../types/TrainingPrograms";

export const trainingProgrammes: TrainingPrograms[] = [
  {
    id: 1,
    title: "Principle of Specificity in Athletic Training",
    description: "VERY WELL FIT",
    registrationFee: 299,
    image: "/images/TrainingProgramme1.png",
  },
  {
    id: 2,
    title: "Principle of Specificity in Athletic Training",
    description: "VERY WELL FIT",
    registrationFee: 499,
    image: "/images/TrainingProgramme2.png",
  },
  {
    id: 3,
    title: "Principle of Specificity in Athletic Training",
    description: "VERY WELL FIT",
    registrationFee: 999,
    image: "/images/TrainingProgramme3.png",
  },
  {
    id: 4,
    title: "Principle of Specificity in Athletic Training",
    description: "VERY WELL FIT",
    registrationFee: 499,
    image: "/images/TrainingProgramme2.png",
  },
];
