import React from "react";
import { Form as FormData, Formik } from "formik";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { TextField } from "utils/FormControls";
import DefaultInput from "components/Inputs/DefaultInput";
import { decryptText, readFileAsBase64 } from "utils/utils";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "context/AuthContext";
import Loader from "components/Loader/Loader";
import { SponsorType } from "types/SponsorsTypes";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import ViewEvent from "utils/ViewEvent";
import { Icon } from "components/Icon";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";

const ItemType = "SPONSORS_ITEM";

const SponsorsTabComponent = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { storeEventId } = useEvent();
  const isViewEvent = ViewEvent();
  const axiosPrivate = useAxiosPrivate();
  const { companyAccount } = useAccount();

  const [isSelectedID, setIsSelectedID] = React.useState<number>();
  const [isConfirmModal, setIsConfirmModal] = React.useState(false);
  const [isCreateSponsorsModal, setIsCreateSponsorsModal] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isPartnerData, setIsPartnerData] = React.useState<any>([]);
  const [isChangedPosition, setIsChangedPosition] = React.useState<any>([]);

  const IntialValues = {
    postionNumber: "",
    partnerName: "",
    partnerFile: "",
  };

  const isViewer = companyAccount === OrgRoleEnum.Viewer;

  function GetPartners() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getEventSponser",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setIsPartnerData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  React.useEffect(() => {
    GetPartners();
  }, []);

  const SubmitChangedPosition = () => {
    const RemovedPostion = isChangedPosition.map((part: any) => {
      const { position, sponser_image, created_at, updated_at, name, ...rest } =
        part;
      return rest;
    });

    const UpdatedPosition = RemovedPostion.map(
      (item: any, positionCounter: number) => ({
        ...item,
        position: positionCounter + 1,
      })
    );

    setIsLoading(true);
    axiosPrivate({
      url: "/updateEventSponser",
      data: UpdatedPosition,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;

        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
          GetPartners();
        } else {
          toast.error(`${message}`, { autoClose: 1500 });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCategorySubmit = (values: any) => {
    setIsLoading(true);
    const inputData = {
      eventId: eventId || storeEventId,
      position: values.postionNumber,
      name: values.partnerName,
      sponser_image: values.partnerFilebase64,
    };
    axiosPrivate({
      url: "/eventSponser",
      data: inputData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${message}`, {
            autoClose: 2000,
          });
          handleCloseCreate();
          GetPartners();
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => console.log("Category Details", err))
      .finally(() => setIsLoading(false));
  };

  const handleDeletePartner = () => {
    setIsLoading(true);
    axiosPrivate({
      url: "/deleteEventSponser",
      data: {
        eventSponserId: isSelectedID,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, {
            autoClose: 2000,
          });
          handleClose();
          GetPartners();
        } else {
          toast.error(`${message}`, {
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => setIsConfirmModal(false);
  const handleCloseCreate = () => setIsCreateSponsorsModal(false);

  const ConfirmModal = () => {
    return (
      <Modal show={isConfirmModal} onHide={handleClose} centered>
        <Modal.Body className="py-md-5 text-center">
          <h4>Are you sure your want to delete?</h4>
          <div className="d-flex align-items-center justify-content-center mt-md-3">
            <DefaultButton
              variant="primary"
              className="default-action-button me-md-3 px-md-4"
              type="button"
              onClick={handleDeletePartner}
            >
              Yes
            </DefaultButton>
            <DefaultButton
              variant="outline-primary"
              className="default-action-button px-md-4"
              type="button"
              onClick={() => setIsConfirmModal(false)}
            >
              No
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const CreateSponsorModal = () => {
    return (
      <Modal show={isCreateSponsorsModal} onHide={handleCloseCreate} centered>
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>Creating Sponsors</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" px-md-4 text-center">
          <Formik onSubmit={handleCategorySubmit} initialValues={IntialValues}>
            {({ handleSubmit, handleChange, setFieldValue }) => {
              return (
                <FormData noValidate onSubmit={handleSubmit}>
                  <div className="px-0 d-flex flex-column justify-content-center">
                    <Row>
                      <Col className="col-12 ">
                        <TextField
                          name="postionNumber"
                          type="number"
                          label="Image Position *"
                          onChange={handleChange}
                          onWheel={(event: any) => event.currentTarget.blur()}
                        />
                      </Col>
                      <Col className="col-12 py-md-4">
                        <TextField
                          name="partnerName"
                          type="text"
                          label="Name *"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={12} md={12}>
                        <DefaultInput
                          label="Upload file *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg, application/pdf"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              setFieldValue("partnerFilebase64", base64);
                            }

                            setFieldValue("partnerFile", file);
                          }}
                        />
                      </Col>

                      <div className="d-flex align-items-center justify-content-end my-md-4">
                        <DefaultButton
                          type="submit"
                          variant="primary"
                          className="default-action-button me-md-3 px-md-5"
                        >
                          Submit
                        </DefaultButton>
                        <DefaultButton
                          variant="outline-primary"
                          className="default-action-button px-md-5"
                          type="button"
                          onClick={() => setIsCreateSponsorsModal(false)}
                        >
                          Close
                        </DefaultButton>
                      </div>
                    </Row>
                  </div>
                </FormData>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  };

  const DraggableProfileItem = ({ index, data, moveItem }: any) => {
    const [{ isDragging }, ref]: any = useDrag({
      type: ItemType,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemType,
      hover: (dragged: any) => {
        if (dragged.index !== index) {
          moveItem(dragged.index, index);
          dragged.index = index;
        }
      },
    });

    return (
      <Col
        ref={(node: any) => ref(drop(node))}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className="my-md-2 col-md-2"
      >
        <Card
          style={{
            width: "180px",
            height: "180px",
          }}
          className="p-md-3"
        >
          <Card.Header className="border-0 d-flex justify-content-end bg-white p-0 m-0">
            {!isViewer && (
              <Icon
                iconName="Trash"
                className="text-primary"
                style={{ cursor: "pointer" }}
                size={18}
                onClick={(e) => {
                  setIsConfirmModal(true);
                  setIsSelectedID(data.id);
                }}
              />
            )}
          </Card.Header>

          <Card.Img
            variant="top"
            src={data.sponser_image}
            style={{
              objectFit: "contain",
              width: "140px",
              height: "100px",
            }}
          />
          {/* <Card.Body className="px-md-4 flex-grow-1 m-md-0 pb-md-2">
            <div>
              <h1 className={`fw-600`}>{data.name}</h1>
            </div>
          </Card.Body> */}
        </Card>
      </Col>
    );
  };

  const moveItem = (fromIndex: any, toIndex: any) => {
    const updatedItems = [...isPartnerData];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setIsPartnerData(updatedItems);
    setIsChangedPosition(updatedItems);
  };

  const handleStatusChange = (updatedData: any, index: any) => {
    const updatedItems = [...isPartnerData];
    console.log("updatedItems", updatedItems);
    updatedItems[index] = updatedData;
    setIsPartnerData(updatedItems);
    setIsChangedPosition(updatedItems);
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isViewEvent && (
        <div>
          <div className="my-md-4">
            <h5>Click the 'Add' button to include your sponsors</h5>
          </div>
          <div className="d-flex justify-content-end">
            <DefaultButton
              type="submit"
              variant="primary text-white mt-md-1"
              className="px-md-5"
              onClick={() => setIsCreateSponsorsModal(true)}
            >
              Add{" "}
            </DefaultButton>
          </div>
        </div>
      )}
      <Row className="py-md-5">
        <DndProvider backend={HTML5Backend}>
          {isPartnerData && isPartnerData.length > 0 ? (
            <>
              {isPartnerData.map((partner: SponsorType, index: any) => (
                <>
                  <DraggableProfileItem
                    key={index}
                    index={index}
                    data={partner}
                    moveItem={moveItem}
                    handleStatusChange={handleStatusChange}
                  />
                </>
              ))}
              {!isViewEvent && (
                <div className="d-flex justify-content-end">
                  <DefaultButton
                    type="submit"
                    variant="primary text-white mt-md-1"
                    className="px-md-5"
                    onClick={SubmitChangedPosition}
                  >
                    Save
                  </DefaultButton>
                </div>
              )}
            </>
          ) : (
            <div className="text-center">
              <h3>
                You haven't included any sponsors yet. Click on 'Add' to begin
                the process.
              </h3>
            </div>
          )}
        </DndProvider>
      </Row>
      {isConfirmModal && <ConfirmModal />}
      {isCreateSponsorsModal && <CreateSponsorModal />}
    </>
  );
};

export default SponsorsTabComponent;
