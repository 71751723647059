import React, { useEffect, useState } from "react";
import AddOrganiserSignUpFrom1 from "components/Forms/OrganizerForms/AddOrganiserSignUpFrom1";
import { useEvent } from "context/EventContext";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const AddOrgPage = () => {
  let Navigate = useNavigate();
  const { storeAccountId } = useEvent();
  const [showSignupStep2, setShowSignupStep2] = useState<boolean>(false);
  useEffect(() => {
    setShowSignupStep2(storeAccountId === null ? false : true);
  }, []);
  const divStyle: React.CSSProperties = {
    backgroundImage: "url(/images/Combine_login.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "100%",
    height: "100vh", // Set the desired height
  };
  const transparentBoxStyle: React.CSSProperties = {
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the alpha value for transparency
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const formContainerStyle: React.CSSProperties = {
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    maxWidth: "900px",
    // height: "700px",
    borderColor: "#dee0fa",
    boxShadow: "0px 0px 25px 0px rgba(13, 15, 31, 0.07)",
  };
  const updateSignupStep2Handler = () => {
    setShowSignupStep2(true);
  };

  return (
    <div style={divStyle}>
      <div style={transparentBoxStyle}>
        <div style={formContainerStyle}>
          <Row className="my-3 d-flex">
            <Col md={2}>
              <div>
                <span
                  className="text-primary"
                  onClick={() => Navigate("/org/accounts")}
                  style={{ cursor: "pointer" }}
                >
                  <Icon
                    iconName="ChevronLeft"
                    className="text-primary border-5 me-md-2"
                    size={20}
                  />
                  Back
                </span>
              </div>
            </Col>
            <Col md={8} className="d-flex justify-content-center">
              <h1 className="text-uppercase">Add Organisation</h1>
            </Col>
          </Row>

          <div>
            <AddOrganiserSignUpFrom1
              updateSignupStep2Handler={updateSignupStep2Handler}
              scrollbar="scroll"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrgPage;
