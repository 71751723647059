import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import OtpValidation from "./OTP/OtpValidation";
import OtpCountDown from "./OTP/OtpCountDown";
import Checkbox from "components/Inputs/Checkbox";
import ForgotPasswordModal from "components/ForgotPasswordModal";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useLogin } from "api/Login";
import { useVerifyLoginThroughMobile } from "api/VerifyLoginThroughMobile";
import { useVerifyLoginOtp } from "api/VerifyLoginOtp";
import { useResendVerifyEmail } from "api/ResendVerifyEmail";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
interface LoginFormProps {
  handleCloseProfileModal: () => void;
  handleSignupClickOnLogin: () => void;
}
const validationSchema = Yup.object({
  username: Yup.string()
    .required("Username / Mobile Number is required")
    .email("Invalid email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
});

const LoginForm: React.FC<LoginFormProps> = ({
  handleSignupClickOnLogin,
  handleCloseProfileModal,
}) => {
  const navigate = useNavigate();
  const { userLogin } = useAuth();

  const [loginError, setLoginError] = useState("");
  const [isEmailVarified, setIsEmailVarified] = useState(false);

  const [isForgotPasswordModal, setIsForgotPasswordModal] = useState(false);
  const [isLoginOtpMode, setIsLoginOtpMode] = useState<boolean>(false);
  const [isValidMobileNumber, setIsValidMobileNumber] =
    useState<boolean>(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState<boolean>(false);

  const [showMobileNumberInvalid, setShowMobileNumberInvalid] =
    useState<boolean>(false);
  const [invalidMobileNumberErrorMessage, setInvalidMobileNumberErrorMessage] =
    useState("");

  const [showOtpInvalid, setShowOtpInvalid] = useState<boolean>(false);
  const [invalidOtpErrorMessage, setInvalidOtpErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const loginMutation = useLogin();
  const otpLoginMutation = useVerifyLoginThroughMobile();
  const verifyLoginOtp = useVerifyLoginOtp();

  const resendVerifyEmailMutation = useResendVerifyEmail();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        const response = await loginMutation.mutateAsync({
          email: values.username,
          password: values.password,
          type: "user",
        });

        if (response.status) {
          localStorage.setItem("name", response?.data?.name);
          setLoginError("");
          // localStorage.setItem("ifinishToken", response?.token);
          // localStorage.setItem("isIfinishLoogedIn", "true");
          // // navigate("/");
          userLogin(response?.token, response?.data?.name);
          handleCloseProfileModal();
        }

        if (!response?.status) {
          setLoginError(response.message);
          if (response?.isVerified) {
            setIsEmailVarified(true);
          } else {
            setIsEmailVarified(false);
          }
        }
      } catch (error) {
        const message = (error as Error).message;
        setStatus({ submitError: message });
      }
      setSubmitting(false);
    },
  });

  const resendVerification = async () => {
    const response = await resendVerifyEmailMutation.mutateAsync({
      email: formik.values.username,
    });
    if (response.status) {
      toast.success(`${response.message}`);
    }
  };

  const handleVerifyMobileNumber = async () => {
    setShowMobileNumberInvalid(false);
    setInvalidMobileNumberErrorMessage("");

    const response = await otpLoginMutation.mutateAsync({
      mobileNumber: mobileNumber,
    });

    if (response.status) {
      setIsValidMobileNumber(true);
    } else {
      setShowMobileNumberInvalid(true);
      setInvalidMobileNumberErrorMessage(response.message);
    }
  };

  const handleResendOtp = async () => {
    const response = await otpLoginMutation.mutateAsync({
      mobileNumber: mobileNumber,
    });

    if (response.status) {
      return true;
    } else {
      return false;
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    setShowMobileNumberInvalid(false);
    setInvalidMobileNumberErrorMessage("");

    const response = await verifyLoginOtp.mutateAsync({
      mobileNumber: mobileNumber,
      otp: otp,
    });

    if (response.status) {
      localStorage.setItem("name", response?.data?.name);
      setLoginError("");
      localStorage.setItem("ifinishToken", response?.token);
      localStorage.setItem("isIfinishLoogedIn", "true");
      userLogin(response?.token, response?.data?.name);
      toast.success(`${response.message}`);
      handleCloseProfileModal();
    } else {
      setShowOtpInvalid(true);
      setInvalidOtpErrorMessage(response.message);
    }
  };

  const handleChangeMobileNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 10) {
      setMobileNumber(event.target.value);
    }
  };

  const otpChangeHandler = (otpValue: string) => {
    setOtp(otpValue);

    if (otpValue.trim().length !== 6) {
      setIsOtpValid(false);
    } else {
      setIsOtpValid(true);
    }
  };

  const handleCloseForgotPasswordModal = () => setIsForgotPasswordModal(false);
  const handleShowForgotPasswordModal = () => setIsForgotPasswordModal(true);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ForgotPasswordModal
        isForgotPasswordModal={isForgotPasswordModal}
        handleCloseForgotPasswordModal={handleCloseForgotPasswordModal}
      />
      <Container style={{ maxWidth: "500px" }}>
        <div className="mt-0 mt-md-4">
          <h1 className={`mt-2 ${isLoginOtpMode && "text-center"}`}>Login</h1>
          {!isLoginOtpMode && (
            <>
              <Form onSubmit={formik.handleSubmit} className="mt-4">
                <DefaultInput
                  className="mt-4"
                  label="Username*"
                  type="email"
                  placeholder="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange("username")}
                  isError={formik.touched.username && !!formik.errors.username}
                  error={formik.errors.username}
                />

                <DefaultInput
                  className="mt-4"
                  label="Password *"
                  type="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  isError={formik.touched.password && !!formik.errors.password}
                  error={formik.errors.password}
                />

                <div className="d-flex justify-content-end fw-600">
                  <small
                    onClick={handleShowForgotPasswordModal}
                    className="cursor-pointer text-purple font-saira"
                  >
                    Forgot password ?
                  </small>
                </div>

                <Checkbox
                  className="mt-3"
                  label="Remember me on this device."
                  checked={formik.values.rememberMe}
                  onChange={formik.handleChange("rememberMe")}
                />
                {loginError !== "" && (
                  <div>
                    <span className=" fs-14 text-primary pe-3">
                      {loginError}
                    </span>{" "}
                    {!isEmailVarified && (
                      <DefaultButton
                        variant="primary"
                        type="button"
                        className="default-action-button text-white py-1"
                        onClick={resendVerification}
                      >
                        Resend Verification
                      </DefaultButton>
                    )}
                  </div>
                )}

                <DefaultButton
                  variant="primary"
                  type="submit"
                  className="default-action-button text-white w-100 mt-4"
                >
                  SUBMIT
                </DefaultButton>
              </Form>
              <DefaultButton
                onClick={() => setIsLoginOtpMode(true)}
                variant="primary"
                type="submit"
                className="default-action-button text-white w-100 mt-3 mb-4"
              >
                LOGIN THROUGH OTP
              </DefaultButton>
            </>
          )}

          {isLoginOtpMode && (
            <div className="my-5">
              <div>
                {!isValidMobileNumber ? (
                  <>
                    <div className="my-3 d-flex flex-column align-items-center justify-content-center">
                      <DefaultInput
                        className="w-100"
                        label="Mobile Number*"
                        type="number"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={handleChangeMobileNumber}
                      />
                      {showMobileNumberInvalid && (
                        <p className="mt-1 mb-0 text-danger">
                          {invalidMobileNumberErrorMessage}
                        </p>
                      )}
                    </div>
                    <DefaultButton
                      onClick={handleVerifyMobileNumber}
                      variant="primary"
                      type="submit"
                      className="default-action-button text-white w-100 mt-4"
                      disabled={mobileNumber.length !== 10}
                    >
                      SEND OTP
                    </DefaultButton>
                  </>
                ) : (
                  <>
                    <div className="my-3 d-flex flex-column align-items-center justify-content-center">
                      <p className="mb-4 text-center font-saira fs-14">
                        Enter the OTP code that was sent to your mobile number
                        <span className="text-primary ms-2">
                          {mobileNumber.substring(0, 1)}
                        </span>
                        <span className="text-primary">********</span>
                        <span className="me-2 text-primary">
                          {mobileNumber.substring(9, 10)}
                        </span>{" "}
                        be careful not to share code with anyone.
                      </p>
                      <OtpValidation
                        value={otp}
                        valueLength={6}
                        onChange={otpChangeHandler}
                      />

                      {showOtpInvalid && (
                        <p className="mt-1 mb-0 text-danger">
                          {invalidOtpErrorMessage}
                        </p>
                      )}

                      <div className="w-100 mt-4 mb-3 px-1 px-md-3">
                        <OtpCountDown onResendOtpHandler={handleResendOtp} />
                      </div>
                    </div>
                    <DefaultButton
                      onClick={handleVerifyOtp}
                      variant="primary"
                      type="submit"
                      className="default-action-button text-white w-100"
                      disabled={!isOtpValid}
                    >
                      VERIFY
                    </DefaultButton>
                  </>
                )}
              </div>
              <DefaultButton
                onClick={() => {
                  setIsLoginOtpMode(false);
                  setIsValidMobileNumber(false);
                }}
                variant="primary"
                type="submit"
                className="default-action-button text-white w-100 mt-3 mb-4"
              >
                LOGIN THROUGH EMAIL
              </DefaultButton>
            </div>
          )}
        </div>

        <div className="d-flex flex-column align-items-center my-3">
          <span className="text-darkGray fs-14 fw-400 ff-ns">
            Not an IFinish User? Click below to Sign Up!
          </span>
          <DefaultButton
            variant="primary"
            type="button"
            className="default-action-button text-white mt-3"
            onClick={handleSignupClickOnLogin}
          >
            SIGN UP
          </DefaultButton>
        </div>
      </Container>
      <Container className="d-flex flex-column align-items-center">
        <div
          className="text-darkGray fs-14 fw-400 ff-ns my-4 text-center"
          onClick={() => navigate("/org/login")}
        >
          Are you an event organiser? Click here to{" "}
          <span className="text-primary" style={{ cursor: "pointer" }}>
            login/sign
          </span>{" "}
          up as an event organiser!
        </div>
      </Container>
    </>
  );
};

export default LoginForm;
