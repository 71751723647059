import React, { useState, useCallback, useEffect } from "react";
import getCroppedImg from "OrganizerPages/eventsforms/cropImage";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Cropper from "react-easy-crop";
// import axios from "utils/axios";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const LogoForm = ({ handleSubmitNextHandler }: any) => {
  const { storeEventId } = useEvent();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  const isViewEvent = ViewEvent();
  // let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent, isEditEvent]);
  const { token } = useAuth();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedBannerImage, setCroppedBannerImage] = useState("");
  const [croppedThumbailImage, setCroppedThumbailImage] = useState("");
  const [croppedLogoImage, setCroppedLogoImage] = useState("");
  const [isSelectImageName, setSelectImageName] = useState("");
  const [bannerImage, setBannerImage] = useState({ preview: "", raw: "" });
  const [thumbnailImage, setThumbnailImage] = useState({
    preview: "",
    raw: "",
  });
  const [logoImage, setLogoImage] = useState({ preview: "", raw: "" });
  const [selectCropImage, setSelectCropImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const CONTAINER_HEIGHT = 300;

  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        isSelectImageName === "Banner"
          ? bannerImage.preview
          : isSelectImageName === "Thumbnail"
          ? thumbnailImage.preview
          : logoImage.preview,
        croppedAreaPixels
      );
      // console.log(
      //   "donee",
      //   `data:image/jpeg;base64,${croppedImage}`
      //   // URL.createObjectURL(`data:image/jpeg;base64,${croppedImage}`)
      // );

      if (isSelectImageName === "Banner") {
        setCroppedBannerImage(croppedImage ?? "");
      } else if (isSelectImageName === "Thumbnail") {
        setCroppedThumbailImage(croppedImage ?? "");
      } else {
        setCroppedLogoImage(croppedImage ?? "");
      }

      handleClose();
      // setImage({
      //   preview: croppedImage,
      //   raw: croppedImage,
      // });
    } catch (e) {
      console.error(e);
    }
  };
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      console.log(croppedArea, croppedAreaPixels);
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleChange = (e: any) => {
    // console.log("on Click data");
    if (e.target.files.length) {
      // console.log("on Click  Inside data");
      if (isSelectImageName === "Banner") {
        setBannerImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setSelectCropImage(URL.createObjectURL(e.target.files[0]));
      } else if (isSelectImageName === "Thumbnail") {
        setThumbnailImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setSelectCropImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setLogoImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setSelectCropImage(URL.createObjectURL(e.target.files[0]));
      }

      handleOpen();
    }
  };

  const handleTheSubmitLogo = () => {
    setIsLoading(true);
    let Parameter = {
      eventId: Id || storeEventId,
      logoName: "logo",
      logo: croppedLogoImage,
      bannerName: "banner",
      banner: croppedBannerImage,
      thumbnailName: "thumbnail",
      thumbnail: croppedThumbailImage,
    };

    // axios.post("/eventBanner", Parameter, {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/eventBanner",
      data: Parameter,
      method: "POST",
    })
      .then((response) => {
        if (response.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${response.data.message}`, { autoClose: 1000 });
          setTimeout(() => {
            handleSubmitNextHandler();
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(response.data.message).forEach((key) => {
            response.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  function getEventBanner() {
    setIsLoading(true);
    // axios.post(
    //   "/getEventBanner",
    //   {
    //     event_id: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventBanner",
      data: {
        event_id: Id || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        setBannerImage({
          preview: res.data.data[1].image,
          raw: res.data.data[1].image,
        });
        setLogoImage({
          preview: res.data.data[0].image,
          raw: res.data.data[0].image,
        });
        setThumbnailImage({
          preview: res.data.data[2].image,
          raw: res.data.data[2].image,
        });
        setCroppedBannerImage(res.data.data[1].image);
        setCroppedLogoImage(res.data.data[0].image);
        setCroppedThumbailImage(res.data.data[2].image);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    {
      Id && getEventBanner();
    }
  }, [Id]);
  return (
    <div>
      {isLoading && <Loader />}
      <ToastContainer />
      <Modal
        show={open}
        onHide={handleClose}
        // className="modal-dialog modal-fullscreen"
        centered
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title style={{ flexGrow: 1 }}>
            <Row className="align-items-center">
              <Col className="fw-blod text-black2 pt-2">
                <h4>Add Image</h4>
              </Col>
              <Col xs="auto">
                <Button
                  className="px-3 fw-normal"
                  variant="outline-next"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="auto" className="ps-0">
                <Button
                  className="px-3 fw-normal"
                  variant="primary"
                  onClick={showCroppedImage}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div> */}

          <Cropper
            image={selectCropImage}
            crop={crop}
            zoom={zoom}
            cropSize={{
              width:
                isSelectImageName === "Banner"
                  ? 1100
                  : isSelectImageName === "Thumbnail"
                  ? 348
                  : 40,
              height:
                isSelectImageName === "Banner"
                  ? 350
                  : isSelectImageName === "Thumbnail"
                  ? 196
                  : 40,
            }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onMediaLoaded={(mediaSize) => {
              // Adapt zoom based on media size to fit max height
              setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
            }}
          />
          {/* </div> */}
        </Modal.Body>
      </Modal>
      <div>
        <h5 className="my-4">
          Banner Image (Size: 350*1100) <span className="text-primary">*</span>
        </h5>

        {bannerImage.preview ? (
          <>
            <label htmlFor="upload-button">
              <Card
                data-target="upload-button"
                style={{ height: 350, width: 1100 }}
                onClick={() => setSelectImageName("Banner")}
              >
                <Card.Img
                  variant="top"
                  src={croppedBannerImage ?? "/images/cardBackground.png"}
                  style={{ height: 350, width: 1100 }}
                  alt="Card image cap"
                />
              </Card>
            </label>
          </>
        ) : (
          <>
            <label htmlFor="upload-button">
              <Card
                data-target="upload-button"
                style={{ height: 350, width: 1100 }}
                onClick={() => setSelectImageName("Banner")}
              >
                <Card.Body>
                  <h5 className="text-center">Upload your photo</h5>
                </Card.Body>
              </Card>
            </label>
          </>
        )}

        <Row>
          <Col md={6}>
            <h5 className="my-4">
              Thumbnail Image (Size: 348*196){" "}
              <span className="text-primary">*</span>
            </h5>
            {thumbnailImage.preview ? (
              <>
                <label htmlFor="upload-thumbnail-button">
                  <Card
                    data-target="upload-thumbnail-button"
                    style={{ height: 196, width: 348 }}
                    onClick={() => setSelectImageName("Thumbnail")}
                  >
                    <Card.Img
                      variant="top"
                      src={croppedThumbailImage ?? "/images/cardBackground.png"}
                      style={{ height: 196, width: 348 }}
                      alt="Card image cap"
                    />
                  </Card>
                </label>
                <></>
              </>
            ) : (
              <>
                <label htmlFor="upload-thumbnail-button">
                  <Card
                    data-target="upload-thumbnail-button"
                    style={{ height: 196, width: 348 }}
                    onClick={() => setSelectImageName("Thumbnail")}
                  >
                    <Card.Body>
                      <h5 className="text-center">Upload your photo</h5>
                    </Card.Body>
                  </Card>
                </label>
              </>
            )}
          </Col>
          <Col md={6}>
            <h5 className="my-4">
              Logo Image (Size: 40*40) <span className="text-primary">*</span>
            </h5>
            {logoImage.preview ? (
              <>
                <label htmlFor="upload-logo-button">
                  <Card
                    data-target="upload-logo-button"
                    style={{ height: 40, width: 40 }}
                    onClick={() => setSelectImageName("Logo")}
                  >
                    <Card.Img
                      variant="top"
                      src={croppedLogoImage ?? "/images/cardBackground.png"}
                      style={{ height: 40, width: 40 }}
                      alt="Card image cap"
                    />
                  </Card>
                </label>
                <></>
              </>
            ) : (
              <>
                <label htmlFor="upload-logo-button">
                  <Card
                    data-target="upload-logo-button"
                    style={{ height: 100, width: 100 }}
                    onClick={() => setSelectImageName("Logo")}
                  >
                    <Card.Body>
                      <h5 className="text-center">Upload your photo</h5>
                    </Card.Body>
                  </Card>
                </label>
              </>
            )}
          </Col>
        </Row>

        <input
          type="file"
          id="upload-button"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
        />

        <input
          type="file"
          id="upload-logo-button"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
        />

        <input
          type="file"
          id="upload-thumbnail-button"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
        />
      </div>
      <Row className="my-md-3 d-flex align-items-center justify-content-end">
        {!isViewEvent && (
          <Col className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                handleTheSubmitLogo();
              }}
              disabled={isPastEvent}
            >
              Submit
            </button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LogoForm;
