import React, { useState, useEffect } from "react";
import { Formik, Form as Formdata } from "formik";
import { object, string, number } from "yup";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
// import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { Col, Row } from "react-bootstrap";
import { TextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const ContactDetailsForm = ({ setActiveTab }: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  const isViewEvent = ViewEvent();
  let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent, isEditEvent]);
  const { token } = useAuth();
  const { storeEventId } = useEvent();

  const [isBasicDetailsData, setIsBasicDetailsData] = useState<any>([]);
  const [isStatus, setIsStatus] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const StoredId = storeEventId === null ? false : true;
  // const isAddMode = !eventId && !StoredId;
  const contactSchema = object({
    contactName: string()
      .required("Contact name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(25, "Maximum of 25 aplhanumeric  characters of length"),
    contactNumber: string()
      .required("Contact number is required")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .matches(/^[0-9]+$/, "Must be only digits"),
    contactAddress2: string()
      .required("Contact address-2 is required")
      .min(3, "Minimum of 3 aplhanumeric  characters of length")
      .max(80, "Maximum of 80 aplhanumeric  characters of length"),
    contactEmail: string()
      .required("Contact email is required")
      .email("Invalid email address"),
    companyName: string()
      .required("Company name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 3 aplhanumeric  characters of length")
      .max(40, "Maximum of 40 aplhanumeric  characters of length"),
    contactAddress1: string()
      .required("Contact address-1 is required")
      .min(1, "Minimum of 1 aplhanumeric  characters of length")
      .max(80, "Maximum of 80 aplhanumeric  characters of length"),
    website: string().required("Website url is required"),
    panNumber: string()
      .required("PAN Number is required")
      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
    gstNumber: string()
      .required("GST Number is required")
      .matches(
        /^(?:[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})|(?:^[A-Za-z0-9]{1,15}$)/,
        "Invalid GST number format or must be alphanumeric and up to 15 characters"
      )
      .min(15, "Minimum of 15 aplhanumeric  characters of length")
      .max(15, "Maximum of 15 aplhanumeric  characters of length"),
  });

  const ContactDetailValues = {
    contactName: isAddMode ? "" : isBasicDetailsData?.contact_name,
    contactNumber: isAddMode ? "" : isBasicDetailsData?.contact_no,
    contactEmail: isAddMode ? "" : isBasicDetailsData?.contact_email_id,
    companyName: isAddMode ? "" : isBasicDetailsData?.company_name,
    contactAddress1: isAddMode ? "" : isBasicDetailsData?.company_address1,
    contactAddress2: isAddMode ? "" : isBasicDetailsData?.company_address2,
    website: isAddMode ? "" : isBasicDetailsData?.website,
    panNumber: isAddMode ? "" : isBasicDetailsData?.pan_number,
    gstNumber: isAddMode ? "" : isBasicDetailsData?.gst_number,
  };

  const handleContactSubmit = (values: any) => {
    setIsLoading(true);
    const EventId = storeEventId || Id;
    const postData = {
      ...values,
      event_id: EventId,
    };
    // axios.post("/eventContactInfo", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/eventContactInfo",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        setIsStatus(res.data.status);
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${res.data.message}`, { autoClose: 1000 });
          setTimeout(() => {
            setActiveTab(4);
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  function getEventBasicDetails() {
    const EventId = Id || storeEventId;
    // axios.post(
    //   "/getEventBasicDetails",
    //   {
    //     event_id: EventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventBasicDetails",
      data: { event_id: EventId },
      method: "POST",
    })
      .then((res) => {
        setIsBasicDetailsData(res.data.data);
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      getEventBasicDetails();
    }
  }, [Id, storeEventId]);

  return (
    <div>
      {isLoading && <Loader />}
      <ToastContainer />
      <Formik
        initialValues={ContactDetailValues}
        validationSchema={contactSchema}
        onSubmit={handleContactSubmit}
        enableReinitialize={true}
      >
        <Formdata noValidate>
          <Row>
            <Col md={4} sm={12}>
              <TextField
                name="contactName"
                type="text"
                label="Contact Name *"
              />
            </Col>
            <Col md={4} sm={12}>
              <TextField
                name="contactNumber"
                type="number"
                label="Contact Number *"
                onWheel={(event: any) => event.currentTarget.blur()}
              />
            </Col>
            <Col md={4} sm={12}>
              <TextField name="contactEmail" type="email" label="Email *" />
            </Col>
          </Row>
          <Row className="my-md-3">
            <Col md={12}>
              <h2 className="text-primary">Company Details</h2>{" "}
            </Col>
          </Row>

          <Row>
            <Col md={4} sm={12}>
              <TextField
                type="text"
                name="companyName"
                label="Company Name *"
              />
            </Col>
            <Col md={4} sm={12}>
              <TextField
                type="text"
                name="contactAddress1"
                label="Address-1 *"
              />
            </Col>
            <Col md={4} sm={12}>
              <TextField
                type="text"
                name="contactAddress2"
                label="Address-2 *"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={12}>
              <TextField type="url" name="website" label="Website *" />
            </Col>
            <Col md={4} sm={12}>
              <TextField type="text" name="panNumber" label="Pan Number *" />
            </Col>
            <Col md={4} sm={12}>
              <TextField type="text" name="gstNumber" label="GST *" />
            </Col>
          </Row>
          <Row className="my-md-3 d-flex align-items-center justify-content-between">
            {!isViewEvent && (
              <Col className="d-flex justify-content-end">
                <DefaultButton
                  type="submit"
                  variant="outline-primary"
                  className=" default-action-button"
                >
                  Submit
                </DefaultButton>
              </Col>
            )}
          </Row>
        </Formdata>
      </Formik>
    </div>
  );
};

export default ContactDetailsForm;
