import PhotoCard from "components/Cards/PhotoCard";
import { Col, Container, Row } from "react-bootstrap";

import { Photo } from "../../../types/Photo";

const MyPhotos = () => {
  const photo1: Photo = {
    id: 1,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery1.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };
  const photo2: Photo = {
    id: 2,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery2.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };
  const photo3: Photo = {
    id: 3,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery3.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };

  const photo4: Photo = {
    id: 4,
    title: "World Conference On Exercise Medicine",
    location: "Taran Pushkar, Bhopal",
    date: "03 JAN 2024",
    imageUrl: "/images/gallery4.png",
    organizerLogo: "url(/images/ACEM-logo.png)",
    organizerName: "ACEM, IFCSS",
  };

  const photo5: Photo = {
    id: 5,
    title: "RBR Bhopal Triathlon (SPRINT) 2023",
    location: "Taran Pushkar, Bhopal",
    date: "23 JAN 2024",
    imageUrl: "/images/gallery5.png",
    organizerLogo: "/images/Bhopal-logo.png",
    organizerName: "BHOPAL RUNNERS",
  };

  const photo6: Photo = {
    id: 6,
    title: "Monsoon Marathon 2023",
    location: "Thane, Maharashtra",
    date: "20 AUG 2023",
    imageUrl: "/images/gallery6.png",
    organizerLogo: "/images/Kambhari-logo.png",
    organizerName: "KAMBHARI ORG",
  };

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo1} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo2} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo3} className="resposiveSmallerCardSize" />
          </Col>
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo4} className="resposiveBiggerCardSize" />
          </Col>
        </Row>{" "}
        <Row className="mt-3">
          <Col xs={12} lg={7}>
            <PhotoCard photo={photo5} className="resposiveBiggerCardSize" />
          </Col>
          <Col xs={12} lg={5}>
            <PhotoCard photo={photo6} className="resposiveSmallerCardSize" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyPhotos;
