import { Card, Image } from "react-bootstrap";
import EventsBadge from "../Badge/EventsBadge";
import { Icon } from "components/Icon";

interface VirtualEventCardProps {
  event: {
    id: number;
    title: string;
    description: string;
    notification: string;
    date: string;
    imageUrl: string;
    organizerLogo: string;
    organizerName: string;
    tags: string[];
  };
}

const VirtualEventCard: React.FC<VirtualEventCardProps> = ({ event }) => {
  return (
    <Card
      className="mb-4 border-0"
      style={{ width: "348px", height: "477px", flexShrink: "0" }}
      position-relative
    >
      <div style={{ position: "absolute", top: "8px", right: "8px" }}>
        <Icon iconName="Heart" size={25} color="white" />
      </div>
      <Card.Img
        variant="top"
        src={event.imageUrl}
        style={{ width: "348px", height: "196px", flexShrink: "0" }}
      />
      {event.notification.length !== 0 && (
        <span
          className="bg-primary fs-12 ff-ns text-white px-2 py-1 fw-500"
          style={{
            position: "absolute",
            borderRadius: "4px",
            right: "35px",
            top: "182px",
          }}
        >
          {event.notification}
        </span>
      )}
      <Card.Body className="d-flex flex-column">
        <div className="pt-3 pb-3">
          <Image src={event.organizerLogo} alt="Organizer logo" />
          <span className="ps-3 fs-14 ff-ns text-darkGray fw-700">
            {event.organizerName}
          </span>
        </div>
        <Card.Title>
          <h1 style={{ minHeight: "57px" }}>{event.title}</h1>
        </Card.Title>
        <Card.Text className="fs-14 ff-ns fw-400 text-darkGray">
          {event.description}
        </Card.Text>
        <div>
          {event.tags.map((tag, idx) => (
            <EventsBadge key={idx}>{tag}</EventsBadge>
          ))}
        </div>

        <Card.Text className="pt-2 fs-14 ff-ns fw-700 text-darkGray">
          {event.date}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default VirtualEventCard;
