import React, { useEffect, useState } from "react";
import { Form as Formdata, Formik } from "formik";
import { Col, Row, Form, Accordion } from "react-bootstrap";
import styles from "./CategoryFormAccordion.module.scss";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
// import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { CategoryTextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

interface BibPair {
  startingBib: number;
  endingBib: number;
}
interface FormValues {
  raceId: string;
  categoryNameMen: string[];
  categoryNameWomen: string[];
  categoryNameOther: string[];
  menMinAge: number[];
  menMaxAge: number[];
  womenMinAge: number[];
  womenMaxAge: number[];
  otherMinAge: number[];
  otherMaxAge: number[];
}

const CategoryForm = ({ setActiveTab }: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  // const [Id, setId] = useState<any>();
  const [isAddMode, setIsAddMode] = useState<any>();
  const axiosPrivate = useAxiosPrivate();
  let isEditMode: any;
  let eventId: any;
  const { token } = useAuth();
  const isViewEvent = ViewEvent();
  const { storeRaceId } = useEvent();
  const StoredId = storeRaceId === null ? false : true;

  // useEffect(() => {
  //   if (isEditEvent && id) {
  //     eventId = decryptText(String(id));
  //     console.log("eventId", eventId);
  //     setId(eventId);
  //     isEditMode = !eventId && !StoredId;
  //     setIsAddMode(isEditMode);
  //   }
  // }, [eventId, id]);

  // const isAddMode = !eventId && !StoredId;
  const [isCategory, setIsCategory] = useState<any>();
  const [counter, setCounter] = useState(1);
  const [womencounter, setWomenCounter] = useState(1);
  const [othercounter, setOtherCounter] = useState(1);
  const [menMinAgeInputValues, setMenMinAgeInputValues] = useState(
    Array(counter).fill("")
  );
  const [menMaxAgeInputValues, setMenMaxAgeInputValues] = useState(
    Array(counter).fill("")
  );
  const [womenMinAgeInputValues, setWomenMinAgeInputValues] = useState(
    Array(womencounter).fill("")
  );
  const [womenMaxAgeInputValues, setWomenMaxAgeInputValues] = useState(
    Array(womencounter).fill("")
  );
  const [otherMinAgeInputValues, setOtherMinAgeInputValues] = useState(
    Array(othercounter).fill("")
  );
  const [otherMaxAgeInputValues, setOtherMaxAgeInputValues] = useState(
    Array(othercounter).fill("")
  );
  const [menCategoryNames, setMenCategoryName] = useState<any>(
    Array(counter).fill("")
  );

  const [womenCategoryNames, setWomenCategoryName] = useState(
    Array(counter).fill("")
  );
  const [otherCategoryNames, setOtherCategoryName] = useState(
    Array(counter).fill("")
  );

  const [menCategoryID, setMenCategoryID] = useState(Array(counter).fill(""));
  const [womenCategoryID, setWomenCategoryID] = useState(
    Array(counter).fill("")
  );
  const [otherCategoryID, setOtherCategoryID] = useState(
    Array(counter).fill("")
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filteredMenData: any = isCategory?.filter(
    (data: any) => data.gender.toLowerCase() === "male"
  );

  const filteredData: any = filteredMenData?.map(
    (data: any) => data.category_name
  );

  const filteredMensMinAge: any = filteredMenData?.map(
    (data: any) => data.min_age
  );
  const filteredMensMaxAge: any = filteredMenData?.map(
    (data: any) => data.max_age
  );
  const filteredFemaleData: any = isCategory?.filter(
    (data: any) => data.gender.toLowerCase() === "female"
  );

  const filteredFemaleCategoryData: any = filteredFemaleData?.map(
    (data: any) => data.category_name
  );

  const filteredFemaleMinAge: any = filteredFemaleData?.map(
    (data: any) => data.min_age
  );
  const filteredFemaleMaxAge: any = filteredFemaleData?.map(
    (data: any) => data.max_age
  );

  const filteredOtherData: any = isCategory?.filter(
    (data: any) => data.gender.toLowerCase() === "other"
  );
  const filteredOtherCategoryData: any = filteredOtherData?.map(
    (data: any) => data.category_name
  );
  const filteredOtherMinAge: any = filteredOtherData?.map(
    (data: any) => data.min_age
  );
  const filteredOtherMaxAge: any = filteredOtherData?.map(
    (data: any) => data.max_age
  );
  useEffect(() => {
    const isIdExists = !!id;
    if (isEditEvent && isIdExists) {
      // const eventId = decryptText(String(id));
      // setId(eventId);
      setIsAddMode(true);
    } else if (!isEditEvent && StoredId) {
      setIsAddMode(true);
    } else {
      setIsAddMode(false);
    }
  }, [isEditEvent, id, StoredId]);

  function getRaceCategories() {
    setIsLoading(true);
    // axios.post(
    //   "/getRaceCategories",
    //   {
    //     raceId: storeRaceId,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getRaceCategories",
      data: { raceId: storeRaceId },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setIsCategory(data);
          const maleCategoryName: any[] = [];
          const maleMinAge: any[] = [];
          const maleMaxAge: any[] = [];
          const femaleCategoryName: any[] = [];
          const femaleMinAge: any[] = [];
          const femaleMaxAge: any[] = [];
          const otherCategoryNameData: any[] = [];
          const otherMinAge: any[] = [];
          const otherMaxAge: any[] = [];
          const maleCategoryIDArray: any[] = [];
          const womenCategoryIDArray: any[] = [];
          const otherCategoryIDArray: any[] = [];
          const genderCounts = {
            male: 0,
            female: 0,
            other: 0,
          };
          res.data.data.forEach((category: any) => {
            if (category.gender === "male") {
              maleCategoryName.push(category.category_name);
              maleMinAge.push(category.min_age);
              maleMaxAge.push(category.max_age);
              maleCategoryIDArray.push(category.id);
              genderCounts.male += 1;
            } else if (category.gender === "female") {
              femaleCategoryName.push(category.category_name);
              femaleMinAge.push(category.min_age);
              femaleMaxAge.push(category.max_age);
              womenCategoryIDArray.push(category.id);
              genderCounts.female += 1;
            } else if (category.gender === "other") {
              otherCategoryNameData.push(category.category_name);
              otherMinAge.push(category.min_age);
              otherMaxAge.push(category.max_age);
              otherCategoryIDArray.push(category.id);
              genderCounts.other += 1;
            }
          });
          setCounter(genderCounts.male);
          setWomenCounter(genderCounts.female);
          setOtherCounter(genderCounts.other);
          setMenCategoryName(maleCategoryName);
          setMenMinAgeInputValues(maleMinAge);
          setMenMaxAgeInputValues(maleMaxAge);
          setWomenCategoryName(femaleCategoryName);
          setWomenMinAgeInputValues(femaleMinAge);
          setWomenMaxAgeInputValues(femaleMaxAge);
          setOtherCategoryName(otherCategoryNameData);
          setOtherMinAgeInputValues(otherMinAge);
          setOtherMaxAgeInputValues(otherMaxAge);
          setMenCategoryID(maleCategoryIDArray);
          setWomenCategoryID(womenCategoryIDArray);
          setOtherCategoryID(otherCategoryIDArray);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (storeRaceId) {
      getRaceCategories();
    }
  }, [storeRaceId]);

  const handleDeleteRace = (categoryID: any) => {
    setIsLoading(true);
    // axios.post(
    //   "/deleteRaceCategories",
    //   {
    //     categoryId: categoryID,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/deleteRaceCategories",
      data: { categoryId: categoryID },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          toast.success(`${res.data.message}`, { autoClose: 1000 });
          getRaceCategories();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  const updatedMenCategoryNames = [...menCategoryNames];
  const updatedMenMinAgeInputValues = [...menMinAgeInputValues];
  const updatedMenMaxAgeInputValues = [...menMaxAgeInputValues];
  const CategoryIntialValues = {
    categoryNameMen: isAddMode ? updatedMenCategoryNames : filteredData,
    categoryNameWomen: isAddMode
      ? womenCategoryNames
      : filteredFemaleCategoryData,
    categoryNameOther: isAddMode
      ? otherCategoryNames
      : filteredOtherCategoryData,
    menMinAge: isAddMode ? updatedMenMinAgeInputValues : filteredMensMinAge,
    menMaxAge: isAddMode ? updatedMenMaxAgeInputValues : filteredMensMaxAge,
    womenMinAge: isAddMode ? womenMinAgeInputValues : filteredFemaleMinAge,
    womenMaxAge: isAddMode ? womenMaxAgeInputValues : filteredFemaleMaxAge,
    otherMinAge: isAddMode ? otherMinAgeInputValues : filteredOtherMinAge,
    otherMaxAge: isAddMode ? otherMaxAgeInputValues : filteredOtherMaxAge,
  };

  const transformData = (inputData: any) => {
    setIsLoading(true);
    const transformedData = {
      raceId: parseInt(inputData.raceId),
      Men: [] as {
        gender: string;
        categoryName: string;
        minAge: number;
        maxAge: number;
      }[],
      Women: [] as {
        gender: string;
        categoryName: string;
        minAge: number;
        maxAge: number;
      }[],
      Other: [] as {
        gender: string;
        categoryName: string;
        minAge: number;
        maxAge: number;
      }[],
    };

    inputData.categoryNameMen.forEach((category: any, index: any) => {
      transformedData.Men.push({
        gender: "Male",
        categoryName: category,
        minAge: inputData.menMinAge[index],
        maxAge: inputData.menMaxAge[index],
      });
    });

    inputData.categoryNameWomen.forEach((category: any, index: any) => {
      transformedData.Women.push({
        gender: "Female",
        categoryName: category,
        minAge: inputData.womenMinAge[index],
        maxAge: inputData.womenMaxAge[index],
      });
    });

    inputData.categoryNameOther.forEach((category: any, index: any) => {
      transformedData.Other.push({
        gender: "Other",
        categoryName: category,
        minAge: inputData.otherMinAge[index],
        maxAge: inputData.otherMaxAge[index],
      });
    });

    return transformedData;
  };

  function checkUnique(arr: any, gender: string): boolean {
    let uniqueSet = new Set(arr);

    if (uniqueSet.size !== arr.length) {
      const duplicates: string[] = [];
      const countMap: { [key: string]: number } = {};

      for (const item of arr) {
        countMap[item] = (countMap[item] || 0) + 1;
        if (countMap[item] > 1 && !duplicates.includes(item)) {
          duplicates.push(item);
        }
      }

      toast.error(
        `${gender} Category contains duplicate elements! Duplicate values: ${duplicates.join(
          ", "
        )}`
      );
      return false;
    } else {
      return true;
    }
  }
  function validateBibPairs(
    startingNumbers: string[],
    endingNumbers: string[],
    gender: string
  ): BibPair[] | null {
    if (startingNumbers.length !== endingNumbers.length) {
      toast.error("Error: Arrays must have the same length!");
      return null;
    }

    const pairs: BibPair[] = [];
    for (let i = 0; i < startingNumbers.length; i++) {
      const currentStartingBib = parseInt(startingNumbers[i], 10);
      const currentEndingBib = parseInt(endingNumbers[i], 10);

      if (!(currentStartingBib < currentEndingBib)) {
        toast.error(
          `${gender} min age ${currentStartingBib} should be less than the max age ${currentEndingBib} of its own pair!`
        );
        return null;
      }

      for (let j = 0; j < pairs.length; j++) {
        const existingPair = pairs[j];
        if (
          (currentStartingBib >= existingPair.startingBib &&
            currentStartingBib <= existingPair.endingBib) ||
          (currentEndingBib >= existingPair.startingBib &&
            currentEndingBib <= existingPair.endingBib) ||
          (existingPair.startingBib >= currentStartingBib &&
            existingPair.startingBib <= currentEndingBib) ||
          (existingPair.endingBib >= currentStartingBib &&
            existingPair.endingBib <= currentEndingBib)
        ) {
          toast.error(
            `Error: Age category range (${currentStartingBib}-${currentEndingBib}) overlaps with range (${existingPair.startingBib}-${existingPair.endingBib})`
          );
          return null;
        }
      }

      pairs.push({
        startingBib: currentStartingBib,
        endingBib: currentEndingBib,
      });
    }

    return pairs;
  }
  const handleCategorySubmit = (values: any) => {
    const isMenUnique = checkUnique(menCategoryNames, "Male");
    const isFemaleUnique = checkUnique(womenCategoryNames, "Female");
    const isOtherUnique = checkUnique(otherCategoryNames, "Other");
    const validationMenResult = validateBibPairs(
      menMinAgeInputValues,
      menMaxAgeInputValues,
      "Male"
    );
    const validationFemaleResult = validateBibPairs(
      womenMinAgeInputValues,
      womenMaxAgeInputValues,
      "Female"
    );
    const validationOtherResult = validateBibPairs(
      otherMinAgeInputValues,
      otherMaxAgeInputValues,
      "Other"
    );

    if (
      isMenUnique &&
      isFemaleUnique &&
      isOtherUnique &&
      Array.isArray(validationMenResult) &&
      Array.isArray(validationFemaleResult) &&
      Array.isArray(validationOtherResult)
    ) {
      const inputData = {
        categoryNameMen: menCategoryNames,
        categoryNameOther: otherCategoryNames,
        categoryNameWomen: womenCategoryNames,
        menMaxAge: menMaxAgeInputValues,
        menMinAge: menMinAgeInputValues,
        otherMaxAge: otherMaxAgeInputValues,
        otherMinAge: otherMinAgeInputValues,
        womenMaxAge: womenMaxAgeInputValues,
        womenMinAge: womenMinAgeInputValues,
        raceId: storeRaceId,
      };
      const transformedData = transformData(inputData);
      // axios.post("/raceCategories", transformedData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     Accept: "application/json",
      //   },
      // });
      axiosPrivate({
        url: "/raceCategories",
        data: transformedData,
        method: "POST",
      })
        .then((res) => {
          // setIsStatus(res.data.status);
          if (res.data.status) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.success(`${res.data.message}`, { autoClose: 1000 });
            setTimeout(() => {
              setActiveTab(3);
            }, 2000);
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.error(`${res.data.message}`);
          }
        })
        .catch((err) => {
          return;
        })
        .finally(() => setIsLoading(false));
    }
  };

  const isMenCounterReachedLimit = counter === 6;
  const isWomenCounterReachedLimit = womencounter === 6;
  const isOtherCounterReachedLimit = othercounter === 6;
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div>
        <div className="pb-md-3">
          <h5>Instructions:</h5>
          <h6>
            {" "}
            1. A minimum of one age category needs to be entered before
            submitting.
          </h6>{" "}
          <h6>
            {" "}
            2. One a minimum age and maximum age range has been entered the age
            range cannot be repeated for the same gender.
          </h6>
          <h6> 3. A total of 6 categories can be entered per gender.</h6>
        </div>
        <div>
          <Formik
            onSubmit={handleCategorySubmit}
            initialValues={CategoryIntialValues}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, errors, setValues, values }) => {
              const hasCategoryNameMenError = !!errors.categoryNameMen;
              const hasCategoryNameWomenError = !!errors.categoryNameWomen;
              const hasCategoryNameOtherError = !!errors.categoryNameOther;
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="px-0 d-flex flex-column justify-content-center">
                    <p className="fs-13">
                      Please fill information regarding the age. Fill the ages
                      for the necessary genders. If the race is open for
                      everybody please fill the ages for all the fields{" "}
                    </p>

                    <Accordion
                      defaultActiveKey={["0", "1", "2"]}
                      className={`${styles.customAccordian} my-md-4`}
                    >
                      <Accordion.Item
                        eventKey="0"
                        bsPrefix={`${styles.accordianItem} `}
                        className="px-2 mb-2"
                      >
                        <Accordion.Header
                          bsPrefix={`${styles.accordianHeader} `}
                        >
                          <span className="fw-600">Mens Age</span>
                        </Accordion.Header>
                        <Accordion.Body className="p-0 m-0">
                          <Row className="my-md-4">
                            <p className="fs-14">
                              Press the Add button to include Age Categories for
                              Men's.
                            </p>

                            {Array.from(Array(counter)).map((_, index: any) => {
                              return (
                                <div
                                  className="d-flex align-items-center p-0 m-0"
                                  key={index}
                                >
                                  <div className="col-md-10 m-0 p-0">
                                    <Row className="m-0 p-0">
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`categoryNameMen[${index}]`}
                                          type="text"
                                          label="Name *"
                                          onChange={(e: any) => {
                                            const updatedCategoryMenValues = [
                                              ...menCategoryNames,
                                            ];
                                            updatedCategoryMenValues[index] =
                                              e.target.value;
                                            setMenCategoryName(
                                              updatedCategoryMenValues
                                            );

                                            handleChange({
                                              target: {
                                                name: `categoryNameMen[${index}]`,
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                        {errors.categoryNameMen &&
                                          typeof errors.categoryNameMen ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.categoryNameMen}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`menMinAge[${index}]`}
                                          type="number"
                                          label="Min Age *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const menMinValues = [
                                              ...menMinAgeInputValues,
                                            ];
                                            menMinValues[index] =
                                              e.target.value;
                                            setMenMinAgeInputValues(
                                              menMinValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.menMinAge &&
                                          typeof errors.menMinAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.menMinAge}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`menMaxAge[${index}]`}
                                          type="number"
                                          label="Max Age *"
                                          isRequired={false}
                                          min="0"
                                          onChange={(e: any) => {
                                            const menMaxValues = [
                                              ...menMaxAgeInputValues,
                                            ];
                                            menMaxValues[index] =
                                              e.target.value;
                                            setMenMaxAgeInputValues(
                                              menMaxValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.menMaxAge &&
                                          typeof errors.menMaxAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.menMaxAge}
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                  </div>
                                  {!isViewEvent && (
                                    <div className="col-auto">
                                      <Icon
                                        iconName="Trash"
                                        className="text-primary border-5"
                                        style={{ cursor: "pointer" }}
                                        size={24}
                                        onClick={() => {
                                          const updatedCategoryMenNames: any = [
                                            ...menCategoryNames,
                                          ];
                                          const updatedMenMinAgeValues: any = [
                                            ...menMinAgeInputValues,
                                          ];
                                          const updatedMenMaxAgeValues: any = [
                                            ...menMaxAgeInputValues,
                                          ];

                                          updatedCategoryMenNames.splice(
                                            index,
                                            1
                                          );
                                          updatedMenMinAgeValues.splice(
                                            index,
                                            1
                                          );
                                          updatedMenMaxAgeValues.splice(
                                            index,
                                            1
                                          );

                                          handleDeleteRace(
                                            menCategoryID[index]
                                          );
                                          setValues(updatedCategoryMenNames);
                                          setValues(updatedMenMinAgeValues);
                                          setValues(updatedMenMaxAgeValues);
                                          setMenCategoryName(
                                            updatedCategoryMenNames
                                          );
                                          setMenMinAgeInputValues(
                                            updatedMenMinAgeValues
                                          );
                                          setMenMaxAgeInputValues(
                                            updatedMenMaxAgeValues
                                          );
                                          setCounter(
                                            (prevCounter) => prevCounter - 1
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Row>

                          {!isViewEvent && (
                            <div className=" mt-md-3">
                              <DefaultButton
                                disabled={
                                  isMenCounterReachedLimit ||
                                  hasCategoryNameMenError
                                }
                                type="button"
                                variant="primary text-white mt-md-1"
                                className="px-md-5"
                                onClick={() =>
                                  counter < 6 && setCounter(counter + 1)
                                }
                              >
                                Add
                              </DefaultButton>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="1"
                        bsPrefix={`${styles.accordianItem}`}
                        className="px-2 py-md-4 mb-2"
                      >
                        <Accordion.Header
                          bsPrefix={`${styles.accordianHeader}`}
                        >
                          <span className="fw-600">Women Age</span>
                        </Accordion.Header>
                        <Accordion.Body className="p-0 m-0">
                          <p className="fs-14">
                            Press the Add button to include Age Categories for
                            Women.
                          </p>
                          <Row className="my-md-4">
                            {Array.from(Array(womencounter)).map((c, index) => {
                              return (
                                <div className="d-flex  align-items-center p-0 m-0">
                                  <div key={c} className="col-md-10 m-0 p-0">
                                    <Row className="m-0 p-0">
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`categoryNameWomen[${index}]`}
                                          type="text"
                                          label="Name *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const categoryWomenValues = [
                                              ...womenCategoryNames,
                                            ];
                                            categoryWomenValues[index] =
                                              e.target.value;
                                            setWomenCategoryName(
                                              categoryWomenValues
                                            );
                                            handleChange(e);
                                          }}
                                        />
                                        {errors.categoryNameWomen &&
                                          typeof errors.categoryNameWomen ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.categoryNameWomen}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`womenMinAge[${index}]`}
                                          type="number"
                                          label="Women Min Age *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const womenMinValues = [
                                              ...womenMinAgeInputValues,
                                            ];
                                            womenMinValues[index] =
                                              e.target.value;
                                            setWomenMinAgeInputValues(
                                              womenMinValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.womenMinAge &&
                                          typeof errors.womenMinAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.womenMinAge}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`womenMaxAge[${index}]`}
                                          type="number"
                                          label="Women Max Age *"
                                          onChange={(e: any) => {
                                            const womenMaxValues = [
                                              ...womenMaxAgeInputValues,
                                            ];
                                            womenMaxValues[index] =
                                              e.target.value;
                                            setWomenMaxAgeInputValues(
                                              womenMaxValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.womenMaxAge &&
                                          typeof errors.womenMaxAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.womenMaxAge}
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                  </div>
                                  {!isViewEvent && (
                                    <div className="col-auto ">
                                      <Icon
                                        iconName="Trash"
                                        className="text-primary border-5"
                                        style={{ cursor: "pointer" }}
                                        size={24}
                                        onClick={() => {
                                          const updatedCategoryWomenNames: any =
                                            [...womenCategoryNames];
                                          const updatedWomenMinAgeValues: any =
                                            [...womenMinAgeInputValues];
                                          const updatedWomenMaxAgeValues: any =
                                            [...womenMaxAgeInputValues];
                                          updatedCategoryWomenNames.splice(
                                            index,
                                            1
                                          );
                                          updatedWomenMinAgeValues.splice(
                                            index,
                                            1
                                          );
                                          updatedWomenMaxAgeValues.splice(
                                            index,
                                            1
                                          );
                                          handleDeleteRace(
                                            womenCategoryID[index]
                                          );
                                          setValues(updatedCategoryWomenNames);
                                          setValues(updatedWomenMinAgeValues);
                                          setValues(updatedWomenMaxAgeValues);
                                          setWomenCategoryName(
                                            updatedCategoryWomenNames
                                          );
                                          setWomenMinAgeInputValues(
                                            updatedWomenMinAgeValues
                                          );
                                          setWomenMaxAgeInputValues(
                                            updatedWomenMaxAgeValues
                                          );
                                          setWomenCounter(womencounter - 1);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Row>
                          {!isViewEvent && (
                            <div className="mt-md-3">
                              <DefaultButton
                                disabled={
                                  isWomenCounterReachedLimit ||
                                  hasCategoryNameWomenError
                                }
                                type="button"
                                variant="primary text-white mt-md-1"
                                className="px-md-5"
                                onClick={() =>
                                  womencounter < 6 &&
                                  setWomenCounter(womencounter + 1)
                                }
                              >
                                Add
                              </DefaultButton>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="2"
                        bsPrefix={`${styles.accordianItem}`}
                        className="px-2 mb-2"
                      >
                        <Accordion.Header
                          bsPrefix={`${styles.accordianHeader}`}
                        >
                          <span className="fw-600">Others Age</span>
                        </Accordion.Header>
                        <Accordion.Body className="p-0 m-0">
                          <Row className="my-md-4">
                            <p className="fs-14">
                              Press the Add button to include Age Categories for
                              Other's.
                            </p>

                            {Array.from(Array(othercounter)).map((c, index) => {
                              return (
                                <div className="d-flex  align-items-center m-0 p-0">
                                  <div key={c} className="col-md-10 m-0 p-0">
                                    <Row className="m-0 p-0">
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`categoryNameOther[${index}]`}
                                          type="text"
                                          label="Name *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const categoryOtherValues = [
                                              ...otherCategoryNames,
                                            ];
                                            categoryOtherValues[index] =
                                              e.target.value;
                                            setOtherCategoryName(
                                              categoryOtherValues
                                            );
                                            handleChange(e);
                                          }}
                                        />
                                        {errors.categoryNameOther &&
                                          typeof errors.categoryNameOther ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.categoryNameOther}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`otherMinAge[${index}]`}
                                          type="number"
                                          label="Min Age *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const otherMinValues = [
                                              ...otherMinAgeInputValues,
                                            ];
                                            otherMinValues[index] =
                                              e.target.value;
                                            setOtherMinAgeInputValues(
                                              otherMinValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.otherMinAge &&
                                          typeof errors.otherMinAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.otherMinAge}
                                            </div>
                                          )}
                                      </Col>
                                      <Col xs={12} md={4}>
                                        <CategoryTextField
                                          name={`otherMaxAge[${index}]`}
                                          type="number"
                                          label="Max Age *"
                                          min="0"
                                          onChange={(e: any) => {
                                            const otherMaxValues = [
                                              ...otherMaxAgeInputValues,
                                            ];
                                            otherMaxValues[index] =
                                              e.target.value;
                                            setOtherMaxAgeInputValues(
                                              otherMaxValues
                                            );
                                            handleChange(e);
                                          }}
                                          onWheel={(event: any) =>
                                            event.currentTarget.blur()
                                          }
                                        />
                                        {errors.otherMaxAge &&
                                          typeof errors.otherMaxAge ===
                                            "string" && (
                                            <div className="error text-danger float-end">
                                              {errors.otherMaxAge}
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                  </div>
                                  {!isViewEvent && (
                                    <div className="col-auto ">
                                      <Icon
                                        iconName="Trash"
                                        className="text-primary border-5"
                                        style={{ cursor: "pointer" }}
                                        size={24}
                                        onClick={() => {
                                          const updatedCategoryOtherNames: any =
                                            [...otherCategoryNames];
                                          const updatedOtherMinAgeValues: any =
                                            [...otherMinAgeInputValues];
                                          const updatedOtherMaxAgeValues: any =
                                            [...otherMaxAgeInputValues];
                                          updatedCategoryOtherNames.splice(
                                            index,
                                            1
                                          );
                                          updatedOtherMinAgeValues.splice(
                                            index,
                                            1
                                          );
                                          updatedOtherMaxAgeValues.splice(
                                            index,
                                            1
                                          );
                                          setValues(updatedCategoryOtherNames);
                                          setValues(updatedOtherMinAgeValues);
                                          setValues(updatedOtherMaxAgeValues);
                                          setOtherCategoryName(
                                            updatedCategoryOtherNames
                                          );
                                          setOtherMinAgeInputValues(
                                            updatedOtherMinAgeValues
                                          );
                                          setOtherMaxAgeInputValues(
                                            updatedOtherMaxAgeValues
                                          );
                                          handleDeleteRace(
                                            otherCategoryID[index]
                                          );
                                          setOtherCounter(othercounter - 1);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Row>
                          {!isViewEvent && (
                            <div className=" mt-md-3">
                              <DefaultButton
                                type="button"
                                variant="primary text-white mt-md-1"
                                disabled={
                                  isOtherCounterReachedLimit ||
                                  hasCategoryNameOtherError
                                }
                                className="px-md-5"
                                onClick={() =>
                                  othercounter < 6 &&
                                  setOtherCounter(othercounter + 1)
                                }
                              >
                                Add
                              </DefaultButton>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    {((menCategoryNames.length > 0 &&
                      menMinAgeInputValues.length > 0 &&
                      menMaxAgeInputValues.length > 0) ||
                      (womenCategoryNames.length > 0 &&
                        womenMinAgeInputValues.length > 0 &&
                        womenMaxAgeInputValues.length > 0) ||
                      (otherCategoryNames.length > 0 &&
                        otherMinAgeInputValues.length > 0 &&
                        otherMaxAgeInputValues.length > 0)) && (
                      <div className="d-flex justify-content-end">
                        {!isViewEvent && (
                          <DefaultButton
                            type="submit"
                            variant="outline-primary"
                            className="default-action-button"
                          >
                            Submit
                          </DefaultButton>
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CategoryForm;
