import { FocusEvent } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

interface DefaultInputProps {
  label: string;
  type?: string;
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  className?: string;
  isError?: boolean;
  error?: any;
  accept?: string;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  name?: string;
}

const DefaultInput: React.FC<DefaultInputProps> = ({
  label,
  type = "text",
  placeholder,
  onChange,
  onBlur,
  value,
  className,
  isError,
  error,
  accept,
  minDate,
  maxDate,
  disabled,
  readOnly,
  maxLength,
  name,
}) => {
  return (
    <>
      <FloatingLabel
        controlId={name}
        label={label}
        className={`mb-1 custom-floating-label ${className} ${
          isError ? "error-border" : ""
        }`}
      >
        <Form.Control
          name={name}
          type={type}
          accept={accept}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          isInvalid={!!isError}
          min={minDate}
          max={maxDate}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
      </FloatingLabel>
      {isError && <div className="text-danger fs-14">{error}</div>}
    </>
  );
};

export default DefaultInput;
