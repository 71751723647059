import React from "react";
import { Card, Image } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import styles from "./OrganizationCard.module.scss";
import { useNavigate } from "react-router-dom";
interface OrganizationCardProps {
  organization: {
    id: number;
    name: string;
    logo: string;
    followers: number;
  };
}

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  organization,
}) => {
  const Navigate = useNavigate();
  return (
    <Card
      className={`bg-white text-center border-0 ${styles.cardSize}`}
      style={{ cursor: "pointer" }}
      onClick={() => Navigate("/organizer")}
    >
      <Card.Body className="">
        <div>
          <Image src={organization.logo} alt="Organizer logo" />
        </div>
        <Card.Text
          className={`${styles.orgName} fs-12 ff-ns text-diffBlack fw-700`}
        >
          {organization.name}
        </Card.Text>
        <Card.Text className="fs-12 ff-saira fw-600 text-lightGray">
          {organization.followers} followers
        </Card.Text>
        <DefaultButton
          variant="outline-primary"
          className="w-100 default-action-button"
          type="button"
          onClick={() => console.log("followed")}
        >
          FOLLOW
        </DefaultButton>
      </Card.Body>
    </Card>
  );
};

export default OrganizationCard;
