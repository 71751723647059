import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./EventCategories.module.scss";

type CategoriesProps = {
  onEventClick: () => void;
};
const EventCategories: React.FC<CategoriesProps> = ({ onEventClick }) => {
  return (
    <Container>
      <span className="blockTitle">Event Categories</span>
      <Row
        className=" bg-deepGray rounded-3 pb-4 px-3 px-sm-5"
        xs={2}
        md={3}
        lg={5}
      >
        <Col className="pt-4">
          <Card
            bg-white
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
            onClick={onEventClick}
          >
            <Image
              src="/images/Marathon.png"
              alt="Marathon"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Running</p>
          </Card>
        </Col>
        <Col className="pt-4 position-relative">
          <Card
            bg-white
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Cycling.png"
              alt="Cycling"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Cycling</p>
          </Card>
        </Col>
        <Col className="d-none d-md-block pt-4 position-relative">
          <Card
            bg-white
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Swimming.png"
              alt="Swimming"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Swimming</p>
          </Card>
        </Col>
        <Col className="d-none d-md-block pt-4 position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Triathlon.png"
              alt="Triathlon"
              style={{ width: "100px", height: "100px" }}
            />{" "}
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Triathlon</p>
          </Card>
        </Col>
        <Col className="pt-4 d-none d-md-block position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Charity.png"
              alt="Charity"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Charity</p>
          </Card>
        </Col>
        <Col className="pt-4 d-none d-md-block position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Theatre.png"
              alt="Theatre"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Theatre</p>
          </Card>
        </Col>
        <Col className="d-none d-md-block pt-4 position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Music.png"
              alt="Music"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Music</p>
          </Card>
        </Col>
        <Col className="pt-4 d-none d-md-block position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Fitness.png"
              alt="Weight"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Fitness</p>
          </Card>
        </Col>
        <Col className="pt-4 position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/Cricket.png"
              alt="Cricket"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">Cricket</p>
          </Card>
        </Col>
        <Col className="pt-4 position-relative">
          <Card
            className={`${styles.cardSize} cursor-pointer d-flex justify-content-center align-items-center border-0`}
          >
            <Image
              src="/images/MoreEvents.png"
              style={{ width: "100px", height: "100px" }}
              alt="MoreEvents"
            />
            <p className="mb-0 mt-2 fw-700 fs-18 font-saira">More Events</p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventCategories;
