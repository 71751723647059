import { Container, Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../../../pages/EventsPage.module.scss";
import GetINTouchCard from "components/Cards/GetINTouchCard";

const Support = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigate("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active> Support</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.coverImage}`}>
          <Image
            src="/images/SupportBanner.png"
            width="100%"
            style={{ height: "437px" }}
            alt="SupportBanner"
          />
        </div>
      </Container>

      <Container>
        <h1 className="text-lightBlack fs-40">Get in Touch</h1>
        <h6 className="text-deepBlack noto_font_lists">
          Interested in collaborating with iFinish? Fill out the form below.
        </h6>
        <Row className="mt-5">
          <GetINTouchCard
            handleSubmit={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Row>
      </Container>
    </>
  );
};

export default Support;
