import AchievementCard from "components/Cards/AchievementCard";
import { Achievements } from "api/Achievements";
import { Col, Row } from "react-bootstrap";

const MyAchievements = () => {
  return (
    <>
      <Row>
        {Achievements.map((achievement) => (
          <Col
            className="d-flex justify-content-sm-start justify-content-center"
            key={achievement.id}
            md={6}
            lg={4}
          >
            <AchievementCard achievement={achievement} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default MyAchievements;
