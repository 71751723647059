import React, { useReducer } from "react";

interface Props {
  children: React.ReactNode;
}

interface ContextType {
  items: [];
  currentEventId: null | number;
  totalAmount: number;
  addItem: (item: any) => void;
  addItemSelect: (item: any) => void;
  removeItem: (id: number) => void;
  newEvent: (eventId: number) => void;
  clearCart: () => void;
}

const defaultCartState = {
  items: [],
  currentEventId: null,
  totalAmount: 0,
};

const CartContext = React.createContext<ContextType>({
  items: [],
  currentEventId: null,
  totalAmount: 0,
  addItem: (item: any) => {},
  addItemSelect: (item: any) => {},
  removeItem: (id: number) => {},
  newEvent: (eventId: number) => {},
  clearCart: () => {},
});

const CartReducer = (state: any, action: any) => {
  if (action.type === "ADD") {
    const updatedTotalAmount =
      state.totalAmount +
      (action.item?.isEarlyBirdPrice
        ? action.item?.early_bird_discount_amount
        : action.item.amount) *
        action.item.itemAmount;

    const existingCartItemIndex = state.items.findIndex(
      (item: any) => item.id === action.item.id
    );

    const existingCartItem = state.items[existingCartItemIndex];

    let updatedItems;

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        itemTotal:
          (existingCartItem.itemAmount + action.item.itemAmount) *
          (action.item?.isEarlyBirdPrice
            ? action.item?.early_bird_discount_amount
            : action.item.amount),
        itemAmount: existingCartItem.itemAmount + action.item.itemAmount,
      };

      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems = state.items.concat(action.item);
    }

    return {
      ...state,
      items: updatedItems,
      totalAmount: updatedTotalAmount,
    };
  }

  if (action.type === "ADDSELECT") {
    const existingCartItemIndex = state.items.findIndex(
      (item: any) => item.id === action.item.id
    );

    const existingCartItem = state.items[existingCartItemIndex];

    const initialCalculatedTotalAmount =
      state.totalAmount - existingCartItem.itemTotal;

    let updatedItems;

    const updatedItem = {
      ...existingCartItem,
      itemTotal:
        action.item.itemAmount *
        (action.item?.isEarlyBirdPrice
          ? action.item?.early_bird_discount_amount
          : action.item.amount),
      itemAmount: action.item.itemAmount,
    };

    updatedItems = [...state.items];
    updatedItems[existingCartItemIndex] = updatedItem;

    const updatedTotalAmount =
      initialCalculatedTotalAmount +
      (action.item?.isEarlyBirdPrice
        ? action.item?.early_bird_discount_amount
        : action.item.amount) *
        action.item.itemAmount;

    return {
      ...state,
      items: updatedItems,
      totalAmount: updatedTotalAmount,
    };
  }

  if (action.type === "REMOVE") {
    const existingCartItemIndex = state.items.findIndex(
      (item: any) => item.id === action.id
    );

    const existingItem = state.items[existingCartItemIndex];
    const updatedTotalAmount =
      state.totalAmount -
      (existingItem.isEarlyBirdPrice
        ? existingItem.early_bird_discount_amount
        : existingItem.amount);

    let updatedItems;

    if (existingItem.itemAmount === 1) {
      updatedItems = state.items.filter((item: any) => item.id !== action.id);
    } else {
      const updatedItem = {
        ...existingItem,
        itemAmount: existingItem.itemAmount - 1,
        itemTotal:
          (existingItem.itemAmount - 1) *
          (existingItem.isEarlyBirdPrice
            ? existingItem.early_bird_discount_amount
            : existingItem.amount),
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    }

    return {
      items: updatedItems,
      totalAmount: updatedTotalAmount,
    };
  }

  if (action.type === "NEWEVENT") {
    if (
      state.currentEventId !== null &&
      state.currentEventId === action.eventId
    ) {
      return {
        ...state,
      };
    } else {
      return {
        ...defaultCartState,
        currentEventId: action.eventId,
      };
    }
  }

  if (action.type === "CLEAR") {
    return defaultCartState; // Reset the cart state to the initial state
  }
};

export const CartContextProvider: React.FC<Props> = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    CartReducer,
    defaultCartState
  );

  const addItemToCartHandler = (item: any) => {
    dispatchCartAction({ type: "ADD", item: item });
  };

  const addItemSelectToCartHandler = (item: any) => {
    dispatchCartAction({ type: "ADDSELECT", item: item });
  };

  const removeItemFromCartHandler = (id: number) => {
    dispatchCartAction({ type: "REMOVE", id: id });
  };

  const newEventToCartHandler = (eventId: number) => {
    dispatchCartAction({ type: "NEWEVENT", eventId: eventId });
  };

  const clearCartFromCartHandler = () => {
    dispatchCartAction({
      type: "CLEAR",
      item: [],
    });
  };

  const cartContext = {
    items: cartState?.items,
    currentEventId: cartState?.currentEventId,
    totalAmount: cartState?.totalAmount,
    addItem: addItemToCartHandler,
    addItemSelect: addItemSelectToCartHandler,
    removeItem: removeItemFromCartHandler,
    newEvent: newEventToCartHandler,
    clearCart: clearCartFromCartHandler,
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
