import React from "react";
import parse from "html-react-parser";

interface DisclaimerProps {
  disclaimer: string;
}

export const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimer }) => {
  return (
    <div>
      <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
        {" "}
        {parse(`${disclaimer}`)}
      </p>
    </div>
  );
};

export default Disclaimer;
