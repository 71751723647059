import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import PostRegistrationComponent from "OrganizerPages/eventDetails/PostRegistrationComponent";
import PreRegistrationComponents from "./PreRegistrationComponents";

const BibsTabScreen = () => {
  const [activeTab, setActiveTab] = useState<string | null>(
    "preRegistrationBibs"
  );

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };
  return (
    <>
      <div className="m-0 p-0 mt-4">
        <div>
          <h5>Instructions:</h5>
          <h6>1. Bib Numbers once submitted cannot be changed</h6>
          <h6>
            2. Bib numbers cannot be repeated if there is no prefix present.
          </h6>
          <h6>
            3. If a prefix is present, same bib number range cannot be repeated
            for the same prefix
          </h6>
          <h6>4. Bib number range can be repeated for different prefix’s. </h6>
          <h6>5. Prefix input is limited to only 2 characters. </h6>
        </div>
        <Tabs
          defaultActiveKey={"preRegistrationBibs"}
          id="event-registartion-tabs"
          onSelect={handleSelectTab}
          className="border-0"
        >
          <Tab eventKey="preRegistrationBibs" title="Pre-Registration Bibs">
            {activeTab === "preRegistrationBibs" && (
              <PreRegistrationComponents activeTab={activeTab} />
            )}
          </Tab>
          <Tab eventKey="postRegistrationBibs" title="Post-Registration Bibs">
            {activeTab === "postRegistrationBibs" && (
              // <PostRegistrationComponent activeTab={activeTab} />
              <PreRegistrationComponents activeTab={activeTab} />
            )}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default BibsTabScreen;
