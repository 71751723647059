import React, { useEffect, useMemo, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import { useAuth } from "context/AuthContext";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import {
  decryptText,
  itemsPerPageOptions,
  tableCustomStyles,
} from "utils/utils";
import * as XLSX from "xlsx";

const CompanyResults = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { userId } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  function Results() {
    setIsLoading(true);
    const postData = {
      eventId: eventId,
      userId: userId,
    };
    axiosPrivate({
      url: "/corporateResults",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setRegistrationData(data);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    Results();
  }, [userId, eventId]);

  const filteredResults = useMemo(() => {
    if (!searchValue) {
      return registrationData;
    }
    return registrationData.filter((e: any) =>
      Object.values(e).some((val: any) =>
        String(val).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [registrationData, searchValue]);

  const columns = useMemo(() => {
    if (filteredResults.length === 0) return [];
    const desiredOrder = ["name", "bib", "chip_time", "race", "couponId"];
    const orderedColumns = desiredOrder.map((key) => ({
      name:
        key === "couponId"
          ? "COUPON NAME"
          : key.replace(/_/g, " ").toUpperCase(),
      selector: (row: any) => row[key] ?? "—",
    }));
    return orderedColumns;
  }, [filteredResults]);

  function exportexcel() {
    const desiredOrder = ["name", "bib", "chip_time", "race", "couponId"];
    const wsData = [
      desiredOrder.map((key) => {
        if (key === "couponId") {
          return "COUPON NAME";
        }
        return key.replace(/_/g, " ").toUpperCase();
      }),
      ,
      ...registrationData.map((item: any) =>
        desiredOrder.map((key) => item[key] ?? "-")
      ),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `corporate_results_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
    );
  }

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate(`/org/couponuser/${id}`)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Coupons Dashboard
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">Results</h6>
          </Col>
          <Col>
            {registrationData.length > 0 && (
              <div className="d-flex justify-content-end align-items-center">
                <DefaultButton
                  variant="primary"
                  type="submit"
                  className="default-action-button text-white  px-2"
                  onClick={() => exportexcel()}
                >
                  Export Excel
                </DefaultButton>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-md-4">
          <div className="d-flex justify-content-end">
            <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="border-0"
                placeholder="Search by Name or Bib Number"
                style={{ outline: "none", width: "320px" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
        </Row>
        <DataTable
          pagination
          paginationRowsPerPageOptions={itemsPerPageOptions}
          columns={columns}
          data={filteredResults}
          customStyles={tableCustomStyles}
        />
      </Container>
    </>
  );
};

export default CompanyResults;
