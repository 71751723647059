import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import {
  decryptText,
  itemsPerPageOptions,
  tableCustomStyles,
} from "utils/utils";
import DataTable from "react-data-table-component";
import DefaultButton from "components/DefaultButton/DefaultButton";
import ExpoParticipantEditDetailsModal from "../expobibcollection/ExpoParticipantEditDetailsModal";

const ExpoRegistrationList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const urlid = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showEditDetails, setShowEditDetails] = useState<boolean>(false);
  const [selectParticipantData, setSelectParticipantData] = useState<any>(null);
  useEffect(() => {
    fetchRegistrationList();
  }, [axiosPrivate, urlid]);

  const fetchRegistrationList = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post("/getRegistrationList", {
        eventId: urlid,
      });
      const { status, data } = response.data;
      if (status) {
        setRegistrationData(data);
      }
    } catch (error) {
      console.error("Failed to fetch registration list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredResults = useMemo(() => {
    if (!searchValue) {
      return registrationData;
    }
    return registrationData.filter((e: any) =>
      Object.values(e).some((val: any) =>
        String(val).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [registrationData, searchValue]);

  const columns = useMemo(() => {
    if (filteredResults.length === 0) return [];

    return Object.keys(filteredResults[0]).map((key) => ({
      name: key.replace(/_/g, " ").toUpperCase(),
      selector: (row: any) => row[key] ?? "—",
    }));
  }, [filteredResults]);

  const registrationCloumnData = [
    {
      name: "Id",
      // selector: (row: any) => (row.is_offline === 1 ? "Offline" : "Online"),
      selector: (row: any) => (row.participant_id ? row.participant_id : "-"),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
      // width: "240px",
    },

    {
      name: "Status",
      // selector: (row: any) => (row.is_offline === 1 ? "Offline" : "Online"),
      selector: (row: any) => {
        if (row.approve_status === "I") return "Pending";
        if (row.approve_status === "A") return "Approve";
        if (row.approve_status === "M") return "More Info";
      },
      sortable: true,
      width: "100px",
    },
    {
      name: "Race Name",
      selector: (row: any) => (row.ticket_name ? row.ticket_name : "-"),
      sortable: true,
      width: "140px",
    },

    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "-"),
      sortable: true,
      // width: "300px",
    },
    {
      name: "Mobile No",
      selector: (row: any) => (row.mobile_number ? row.mobile_number : "-"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Order Number",
      selector: (row: any) => (row.order_number ? row.order_number : "-"),
      sortable: false,
      width: "120px",
    },
    {
      name: "T Shirt",
      selector: (row: any) => (row.tshirt ? row.tshirt : "-"),
      sortable: true,
      width: "90px",
    },

    {
      name: "Bibs",
      selector: (row: any) => (row.assigned_bibs ? row.assigned_bibs : "-"),
      sortable: true,
      width: "90px",
    },
    {
      name: "Edit",
      width: "90px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => {
            setSelectParticipantData(row);
            setShowEditDetails(true);
          }}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Edit
        </DefaultButton>
      ),
    },
  ];

  const onHide = () => {
    setShowEditDetails(false);
    fetchRegistrationList();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4 p-0">
          <Col className="p-0">
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Dashboard
              </span>
            </div>
            <h6 className="fs-24 mt-3">Registration List</h6>
          </Col>
        </Row>
        <Row className="mb-md-4">
          <div className="d-flex justify-content-end">
            <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="border-0"
                placeholder="Search by Participant Id"
                style={{ outline: "none", width: "320px" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
        </Row>
        <DataTable
          pagination
          paginationRowsPerPageOptions={itemsPerPageOptions}
          columns={registrationCloumnData}
          data={filteredResults}
          customStyles={tableCustomStyles}
        />
      </Container>

      {showEditDetails && (
        <ExpoParticipantEditDetailsModal
          participantId={selectParticipantData.participant_id}
          raceName={selectParticipantData.ticket_name}
          status={""}
          show={showEditDetails}
          onHide={onHide}
          // setReRender={setReRender}
          // setReRenderParticipantDetails={setReRenderParticipantDetails}
        />
      )}
    </>
  );
};

export default ExpoRegistrationList;
