import React from "react";
import { useLocation } from "react-router-dom";

const IsEditEvent = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/");
  if (pathName.includes("editEvents")) {
    return true;
  }
  return false;
};

export default IsEditEvent;
