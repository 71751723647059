import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import { Icon } from "components/Icon";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import CsvConverter from "utils/CsvConverter";
import moment from "moment";

const CouponDetailModal = ({
  showModal,
  handleClose,
  modalData,
  setReRender,
}: any) => {
  const [couponData, setCouponData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  function getCouponsList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getCouponsList",
      data: {
        couponId: modalData.id,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setCouponData(data);
          setReRender((prevState: boolean) => !prevState);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (modalData && modalData.coupon_type === "Promotion Codes") {
      getCouponsList();
    }
  }, [modalData]);
  const totalRows = couponData.length;
  const columns = [
    {
      name: "Coupon code",
      selector: (row: any) => (row.couponName ? row.couponName : "—"),
      sortable: true,
    },
    {
      name: "Coupon status",
      selector: (row: any) => (row.userOn ? row.userOn : "—"),
      sortable: true,
    },
    {
      name: "Coupon used by",
      selector: (row: any) => (row.usedBy ? row.usedBy : "—"),
      sortable: true,
    },
    {
      name: "Valid From",
      selector: (row: any) =>
        row.valid_from
          ? moment(row.valid_from).format("DD-MM-YYYY, HH:mm a")
          : "—",
      sortable: true,
    },
    {
      name: "Valid To",
      selector: (row: any) =>
        row.valid_to ? moment(row.valid_to).format("DD-MM-YYYY, HH:mm a") : "—",
      sortable: true,
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffe9e9",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const filterResult = couponData.filter(
    (e: any) =>
      e.couponName &&
      e.couponName.toLowerCase().includes(searchValue?.toLowerCase() || "")
  );
  return (
    <>
      {isLoading && <Loader />}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="mx-md-3"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Coupon details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end align-items-center">
            <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by coupon name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
          <div className="mt-md-2">
            <DataTable
              pagination
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10]}
              paginationPerPage={10}
              columns={columns}
              data={filterResult}
              customStyles={tableCustomStyles}
              actions={
                <CsvConverter
                  data={filterResult}
                  headingKeys={filterResult[0]}
                  fileName={`${modalData && modalData?.name} coupons`}
                />
              }
            />
          </div>
          <div className="d-flex justify-content-end mt-md-3">
            <DefaultButton
              type="button"
              variant="primary"
              className="default-action-button px-md-5"
              onClick={handleClose}
            >
              Close
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CouponDetailModal;
