import React from "react";
import Footer from "components/Footer";
import { Icon } from "components/Icon";
import { useAuth } from "context/AuthContext";
import { Container, Dropdown } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";

const SupportDashboardLayout = () => {
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const HandleLogout = () => {
    navigate("/support/login");
    logout();
  };
  const handleNavigate = () => {
    navigate("/support/dashboard");
  };
  return (
    <>
      <nav
        className={`${"sticky-top"} shadow-sm px-3 bg-white rounded pt-2 d-none d-md-block`}
      >
        {isAuthenticated && (
          <Container className="d-flex justify-content-end">
            <div style={{ cursor: "pointer" }}>
              <Dropdown align="end" className="p-0 m-0">
                <Dropdown.Toggle
                  variant="white"
                  id="dropdown-basic"
                  className="border-0 outline-0"
                >
                  <Icon
                    iconName="PersonCircle"
                    className="text-lightGray"
                    size={18}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="m-0 p-2">
                  <Dropdown.Item
                    className="dropdown dropdown-item-hover text-purple"
                    onClick={HandleLogout}
                  >
                    <i className="bi bi-box-arrow-left me-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        )}

        <Container fluid className="d-flex align-items-center p-0 pb-md-4 m-0">
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
          <div className="mx-4">
            <img
              className="cursor-pointer"
              onClick={handleNavigate}
              src="/images/ifinish-logo.png"
              alt="Logo"
            />
          </div>
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
        </Container>
      </nav>
      <Outlet />
      <Footer />
    </>
  );
};

export default SupportDashboardLayout;
