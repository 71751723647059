import React from "react";
import { useLocation } from "react-router-dom";

// export const useCreateEvent = () => {
//   const [isEvent, setIsEvent] = React.useState(false);
//   const location = useLocation();
//   React.useLayoutEffect(function () {
//     const pathName = location.pathname.split("/");
//     if (pathName.includes("editEvents") || pathName.includes("createEvents")) {
//       setIsEvent(true);
//     }
//   }, []);
//   return isEvent;
// };

export const useEventDashboard = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/");
  if (pathName.includes("eventDashBoard")) {
    return true;
  }
  return false;
};
