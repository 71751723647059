export const cities = [
  {
    name: "Bamboo Flat",
    state_name: "Andaman and Nicobar Islands",
  },
  {
    name: "Nicobar",
    state_name: "Andaman and Nicobar Islands",
  },
  {
    name: "Port Blair",
    state_name: "Andaman and Nicobar Islands",
  },
  {
    name: "South Andaman",
    state_name: "Andaman and Nicobar Islands",
  },
  {
    name: "Addanki",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Adoni",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Akasahebpet",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Akividu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Akkarampalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Amalapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Amudalavalasa",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Anakapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Anantapur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Atmakur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Attili",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Avanigadda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Badvel",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Banganapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Bapatla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Betamcherla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Bhattiprolu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Bhimavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Bhimunipatnam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Bobbili",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Challapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chemmumiahpet",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chilakalurupet",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chinnachowk",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chipurupalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chirala",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chittoor",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Chodavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Cuddapah",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Cumbum",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Darsi",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Dharmavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Dhone",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Diguvametta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "East Godavari",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Elamanchili",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ellore",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Emmiganur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Erraguntla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Etikoppaka",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gajuwaka",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ganguvada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gannavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Giddalur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gokavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gorantla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Govindapuram Chilakaluripet Guntur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gudivada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gudlavalleru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Gudur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Guntakal Junction",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Guntur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Hindupur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ichchapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Jaggayyapeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Jammalamadugu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kadiri",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kaikalur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kakinada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kalyandurg",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kamalapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kandukur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kanigiri",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kankipadu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kanuru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kavali",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kolanukonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kondapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Korukollu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kosigi",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kovvur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Krishna",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kuppam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Kurnool",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Macherla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Machilipatnam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Madanapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Madugula",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Mandapeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Mandasa",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Mangalagiri",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Markapur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nagari",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nagireddipalli",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nandigama",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nandikotkur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nandyal",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narasannapeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narasapur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narasaraopet",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narasingapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narayanavanam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Narsipatnam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nayudupet",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nellore",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nidadavole",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Nuzvid",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ongole",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pakala",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Palakollu",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Palasa",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Palkonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pallevada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Palmaner",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Parlakimidi",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Parvatipuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pavuluru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pedana",
    state_name: "Andhra Pradesh",
  },
  {
    name: "pedda nakkalapalem",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Peddapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Penugonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Penukonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Phirangipuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pippara",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pithapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Polavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ponnur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ponnuru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Prakasam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Proddatur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Pulivendla",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Punganuru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Puttaparthi",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Puttur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Rajahmundry",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ramachandrapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ramanayyapeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Ramapuram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Rampachodavaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Rayachoti",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Rayadrug",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Razam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Razampeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Razole",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Renigunta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Repalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Salur",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Samalkot",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Sattenapalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Singarayakonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Sompeta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Srikakulam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Srisailain",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Suluru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tadepalle",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tadepallegudem",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tadpatri",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tanuku",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tekkali",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tirumala",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tirupati",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Tuni",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Uravakonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "vadlamuru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vadlapudi",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Venkatagiri",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vepagunta",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vetapalem",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vijayawada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vinukonda",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Visakhapatnam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vizianagaram",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vizianagaram District",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Vuyyuru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "West Godavari",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Yanam",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Yanamalakuduru",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Yarada",
    state_name: "Andhra Pradesh",
  },
  {
    name: "Along",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Anjaw",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Basar",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Bomdila",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Changlang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Dibang Valley",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "East Kameng",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "East Siang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Hayuliang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Itanagar",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Khonsa",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Kurung Kumey",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Lohit District",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Lower Dibang Valley",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Lower Subansiri",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Margherita",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Naharlagun",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Pasighat",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Tawang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Tezu",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Tirap",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Upper Siang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Upper Subansiri",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "West Kameng",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "West Siang",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Ziro",
    state_name: "Arunachal Pradesh",
  },
  {
    name: "Abhayapuri",
    state_name: "Assam",
  },
  {
    name: "Amguri",
    state_name: "Assam",
  },
  {
    name: "Badarpur",
    state_name: "Assam",
  },
  {
    name: "Baksa",
    state_name: "Assam",
  },
  {
    name: "Barpathar",
    state_name: "Assam",
  },
  {
    name: "Barpeta",
    state_name: "Assam",
  },
  {
    name: "Barpeta Road",
    state_name: "Assam",
  },
  {
    name: "Basugaon",
    state_name: "Assam",
  },
  {
    name: "Bihpuriagaon",
    state_name: "Assam",
  },
  {
    name: "Bijni",
    state_name: "Assam",
  },
  {
    name: "Bilasipara",
    state_name: "Assam",
  },
  {
    name: "Bokajan",
    state_name: "Assam",
  },
  {
    name: "Bokakhat",
    state_name: "Assam",
  },
  {
    name: "Bongaigaon",
    state_name: "Assam",
  },
  {
    name: "Cachar",
    state_name: "Assam",
  },
  {
    name: "Chabua",
    state_name: "Assam",
  },
  {
    name: "Chapar",
    state_name: "Assam",
  },
  {
    name: "Chirang",
    state_name: "Assam",
  },
  {
    name: "Darrang",
    state_name: "Assam",
  },
  {
    name: "Dergaon",
    state_name: "Assam",
  },
  {
    name: "Dhekiajuli",
    state_name: "Assam",
  },
  {
    name: "Dhemaji",
    state_name: "Assam",
  },
  {
    name: "Dhing",
    state_name: "Assam",
  },
  {
    name: "Dhubri",
    state_name: "Assam",
  },
  {
    name: "Dibrugarh",
    state_name: "Assam",
  },
  {
    name: "Digboi",
    state_name: "Assam",
  },
  {
    name: "Dima Hasao District",
    state_name: "Assam",
  },
  {
    name: "Diphu",
    state_name: "Assam",
  },
  {
    name: "Dispur",
    state_name: "Assam",
  },
  {
    name: "Duliagaon",
    state_name: "Assam",
  },
  {
    name: "Dum Duma",
    state_name: "Assam",
  },
  {
    name: "Gauripur",
    state_name: "Assam",
  },
  {
    name: "Goalpara",
    state_name: "Assam",
  },
  {
    name: "Gohpur",
    state_name: "Assam",
  },
  {
    name: "Golaghat",
    state_name: "Assam",
  },
  {
    name: "Golakganj",
    state_name: "Assam",
  },
  {
    name: "Goshaingaon",
    state_name: "Assam",
  },
  {
    name: "Guwahati",
    state_name: "Assam",
  },
  {
    name: "Haflong",
    state_name: "Assam",
  },
  {
    name: "Hailakandi",
    state_name: "Assam",
  },
  {
    name: "Hajo",
    state_name: "Assam",
  },
  {
    name: "Hojai",
    state_name: "Assam",
  },
  {
    name: "Howli",
    state_name: "Assam",
  },
  {
    name: "Jogighopa",
    state_name: "Assam",
  },
  {
    name: "Jorhat",
    state_name: "Assam",
  },
  {
    name: "Kamrup",
    state_name: "Assam",
  },
  {
    name: "Kamrup Metropolitan",
    state_name: "Assam",
  },
  {
    name: "Karbi Anglong",
    state_name: "Assam",
  },
  {
    name: "Karimganj",
    state_name: "Assam",
  },
  {
    name: "Kharupatia",
    state_name: "Assam",
  },
  {
    name: "Kokrajhar",
    state_name: "Assam",
  },
  {
    name: "Lakhimpur",
    state_name: "Assam",
  },
  {
    name: "Lakhipur",
    state_name: "Assam",
  },
  {
    name: "Lala",
    state_name: "Assam",
  },
  {
    name: "Lumding Railway Colony",
    state_name: "Assam",
  },
  {
    name: "Mahur",
    state_name: "Assam",
  },
  {
    name: "Maibong",
    state_name: "Assam",
  },
  {
    name: "Makum",
    state_name: "Assam",
  },
  {
    name: "Mangaldai",
    state_name: "Assam",
  },
  {
    name: "Mariani",
    state_name: "Assam",
  },
  {
    name: "Moranha",
    state_name: "Assam",
  },
  {
    name: "Morigaon",
    state_name: "Assam",
  },
  {
    name: "Nagaon",
    state_name: "Assam",
  },
  {
    name: "Nahorkatiya",
    state_name: "Assam",
  },
  {
    name: "Nalbari",
    state_name: "Assam",
  },
  {
    name: "Namrup",
    state_name: "Assam",
  },
  {
    name: "Nazira",
    state_name: "Assam",
  },
  {
    name: "North Guwahati",
    state_name: "Assam",
  },
  {
    name: "North Lakhimpur",
    state_name: "Assam",
  },
  {
    name: "Numaligarh",
    state_name: "Assam",
  },
  {
    name: "Palasbari",
    state_name: "Assam",
  },
  {
    name: "Raha",
    state_name: "Assam",
  },
  {
    name: "Rangapara",
    state_name: "Assam",
  },
  {
    name: "Rangia",
    state_name: "Assam",
  },
  {
    name: "Sapatgram",
    state_name: "Assam",
  },
  {
    name: "Sarupathar",
    state_name: "Assam",
  },
  {
    name: "Sibsagar",
    state_name: "Assam",
  },
  {
    name: "Silapathar",
    state_name: "Assam",
  },
  {
    name: "Silchar",
    state_name: "Assam",
  },
  {
    name: "Soalkuchi",
    state_name: "Assam",
  },
  {
    name: "Sonari",
    state_name: "Assam",
  },
  {
    name: "Sonitpur",
    state_name: "Assam",
  },
  {
    name: "Sorbhog",
    state_name: "Assam",
  },
  {
    name: "Tezpur",
    state_name: "Assam",
  },
  {
    name: "Tinsukia",
    state_name: "Assam",
  },
  {
    name: "Titabar",
    state_name: "Assam",
  },
  {
    name: "Udalguri",
    state_name: "Assam",
  },
  {
    name: "Amarpur",
    state_name: "Bihar",
  },
  {
    name: "Araria",
    state_name: "Bihar",
  },
  {
    name: "Arrah",
    state_name: "Bihar",
  },
  {
    name: "Arwal",
    state_name: "Bihar",
  },
  {
    name: "Asarganj",
    state_name: "Bihar",
  },
  {
    name: "Aurangabad",
    state_name: "Bihar",
  },
  {
    name: "Bagaha",
    state_name: "Bihar",
  },
  {
    name: "Bahadurganj",
    state_name: "Bihar",
  },
  {
    name: "Bairagnia",
    state_name: "Bihar",
  },
  {
    name: "Baisi",
    state_name: "Bihar",
  },
  {
    name: "Bakhtiyarpur",
    state_name: "Bihar",
  },
  {
    name: "Bangaon",
    state_name: "Bihar",
  },
  {
    name: "Banka",
    state_name: "Bihar",
  },
  {
    name: "Banmankhi",
    state_name: "Bihar",
  },
  {
    name: "Bar Bigha",
    state_name: "Bihar",
  },
  {
    name: "Barauli",
    state_name: "Bihar",
  },
  {
    name: "Barh",
    state_name: "Bihar",
  },
  {
    name: "Barhiya",
    state_name: "Bihar",
  },
  {
    name: "Bariarpur",
    state_name: "Bihar",
  },
  {
    name: "Baruni",
    state_name: "Bihar",
  },
  {
    name: "Begusarai",
    state_name: "Bihar",
  },
  {
    name: "Belsand",
    state_name: "Bihar",
  },
  {
    name: "Bettiah",
    state_name: "Bihar",
  },
  {
    name: "Bhabhua",
    state_name: "Bihar",
  },
  {
    name: "Bhagalpur",
    state_name: "Bihar",
  },
  {
    name: "Bhagirathpur",
    state_name: "Bihar",
  },
  {
    name: "Bhawanipur",
    state_name: "Bihar",
  },
  {
    name: "Bhojpur",
    state_name: "Bihar",
  },
  {
    name: "Bihar Sharif",
    state_name: "Bihar",
  },
  {
    name: "Bihariganj",
    state_name: "Bihar",
  },
  {
    name: "Bikramganj",
    state_name: "Bihar",
  },
  {
    name: "Birpur",
    state_name: "Bihar",
  },
  {
    name: "Buddh Gaya",
    state_name: "Bihar",
  },
  {
    name: "Buxar",
    state_name: "Bihar",
  },
  {
    name: "Chakia",
    state_name: "Bihar",
  },
  {
    name: "Chapra",
    state_name: "Bihar",
  },
  {
    name: "Chhatapur",
    state_name: "Bihar",
  },
  {
    name: "Colgong",
    state_name: "Bihar",
  },
  {
    name: "Dalsingh Sarai",
    state_name: "Bihar",
  },
  {
    name: "Darbhanga",
    state_name: "Bihar",
  },
  {
    name: "Daudnagar",
    state_name: "Bihar",
  },
  {
    name: "Dehri",
    state_name: "Bihar",
  },
  {
    name: "Dhaka",
    state_name: "Bihar",
  },
  {
    name: "Dighwara",
    state_name: "Bihar",
  },
  {
    name: "Dinapore",
    state_name: "Bihar",
  },
  {
    name: "Dumra",
    state_name: "Bihar",
  },
  {
    name: "Dumraon",
    state_name: "Bihar",
  },
  {
    name: "Fatwa",
    state_name: "Bihar",
  },
  {
    name: "Forbesganj",
    state_name: "Bihar",
  },
  {
    name: "Gaya",
    state_name: "Bihar",
  },
  {
    name: "Ghoga",
    state_name: "Bihar",
  },
  {
    name: "Gopalganj",
    state_name: "Bihar",
  },
  {
    name: "Hajipur",
    state_name: "Bihar",
  },
  {
    name: "Hilsa",
    state_name: "Bihar",
  },
  {
    name: "Hisua",
    state_name: "Bihar",
  },
  {
    name: "Islampur",
    state_name: "Bihar",
  },
  {
    name: "Jagdispur",
    state_name: "Bihar",
  },
  {
    name: "Jahanabad",
    state_name: "Bihar",
  },
  {
    name: "Jamalpur",
    state_name: "Bihar",
  },
  {
    name: "Jamui",
    state_name: "Bihar",
  },
  {
    name: "Jaynagar",
    state_name: "Bihar",
  },
  {
    name: "Jehanabad",
    state_name: "Bihar",
  },
  {
    name: "Jha-Jha",
    state_name: "Bihar",
  },
  {
    name: "Jhanjharpur",
    state_name: "Bihar",
  },
  {
    name: "Jogbani",
    state_name: "Bihar",
  },
  {
    name: "Kaimur District",
    state_name: "Bihar",
  },
  {
    name: "Kasba",
    state_name: "Bihar",
  },
  {
    name: "Katihar",
    state_name: "Bihar",
  },
  {
    name: "Khagaria",
    state_name: "Bihar",
  },
  {
    name: "Khagaul",
    state_name: "Bihar",
  },
  {
    name: "Kharagpur",
    state_name: "Bihar",
  },
  {
    name: "Khusropur",
    state_name: "Bihar",
  },
  {
    name: "Kishanganj",
    state_name: "Bihar",
  },
  {
    name: "Koath",
    state_name: "Bihar",
  },
  {
    name: "Koelwar",
    state_name: "Bihar",
  },
  {
    name: "Lakhisarai",
    state_name: "Bihar",
  },
  {
    name: "Lalganj",
    state_name: "Bihar",
  },
  {
    name: "Luckeesarai",
    state_name: "Bihar",
  },
  {
    name: "Madhepura",
    state_name: "Bihar",
  },
  {
    name: "Madhubani",
    state_name: "Bihar",
  },
  {
    name: "Maharajgani",
    state_name: "Bihar",
  },
  {
    name: "Mairwa",
    state_name: "Bihar",
  },
  {
    name: "Maner",
    state_name: "Bihar",
  },
  {
    name: "Manihari",
    state_name: "Bihar",
  },
  {
    name: "Marhaura",
    state_name: "Bihar",
  },
  {
    name: "Masaurhi Buzurg",
    state_name: "Bihar",
  },
  {
    name: "Mohiuddi nagar",
    state_name: "Bihar",
  },
  {
    name: "Mokameh",
    state_name: "Bihar",
  },
  {
    name: "Monghyr",
    state_name: "Bihar",
  },
  {
    name: "Mothihari",
    state_name: "Bihar",
  },
  {
    name: "Munger",
    state_name: "Bihar",
  },
  {
    name: "Murliganj",
    state_name: "Bihar",
  },
  {
    name: "Muzaffarpur",
    state_name: "Bihar",
  },
  {
    name: "Nabinagar",
    state_name: "Bihar",
  },
  {
    name: "Nalanda",
    state_name: "Bihar",
  },
  {
    name: "Nasriganj",
    state_name: "Bihar",
  },
  {
    name: "Naugachhia",
    state_name: "Bihar",
  },
  {
    name: "Nawada",
    state_name: "Bihar",
  },
  {
    name: "Nirmali",
    state_name: "Bihar",
  },
  {
    name: "Pashchim Champaran",
    state_name: "Bihar",
  },
  {
    name: "Patna",
    state_name: "Bihar",
  },
  {
    name: "Piro",
    state_name: "Bihar",
  },
  {
    name: "Pupri",
    state_name: "Bihar",
  },
  {
    name: "Purba Champaran",
    state_name: "Bihar",
  },
  {
    name: "Purnia",
    state_name: "Bihar",
  },
  {
    name: "Rafiganj",
    state_name: "Bihar",
  },
  {
    name: "Raghunathpur",
    state_name: "Bihar",
  },
  {
    name: "Rajgir",
    state_name: "Bihar",
  },
  {
    name: "Ramnagar",
    state_name: "Bihar",
  },
  {
    name: "Raxaul",
    state_name: "Bihar",
  },
  {
    name: "Revelganj",
    state_name: "Bihar",
  },
  {
    name: "Rohtas",
    state_name: "Bihar",
  },
  {
    name: "Rusera",
    state_name: "Bihar",
  },
  {
    name: "Sagauli",
    state_name: "Bihar",
  },
  {
    name: "Saharsa",
    state_name: "Bihar",
  },
  {
    name: "Samastipur",
    state_name: "Bihar",
  },
  {
    name: "Saran",
    state_name: "Bihar",
  },
  {
    name: "Shahbazpur",
    state_name: "Bihar",
  },
  {
    name: "Shahpur",
    state_name: "Bihar",
  },
  {
    name: "Sheikhpura",
    state_name: "Bihar",
  },
  {
    name: "Sheohar",
    state_name: "Bihar",
  },
  {
    name: "Sherghati",
    state_name: "Bihar",
  },
  {
    name: "Silao",
    state_name: "Bihar",
  },
  {
    name: "Sitamarhi",
    state_name: "Bihar",
  },
  {
    name: "Siwan",
    state_name: "Bihar",
  },
  {
    name: "Supaul",
    state_name: "Bihar",
  },
  {
    name: "Teghra",
    state_name: "Bihar",
  },
  {
    name: "Tekari",
    state_name: "Bihar",
  },
  {
    name: "Thakurganj",
    state_name: "Bihar",
  },
  {
    name: "Vaishali",
    state_name: "Bihar",
  },
  {
    name: "Waris Aliganj",
    state_name: "Bihar",
  },
  {
    name: "Chandigarh",
    state_name: "Chandigarh",
  },
  {
    name: "Akaltara",
    state_name: "Chhattisgarh",
  },
  {
    name: "Ambagarh Chauki",
    state_name: "Chhattisgarh",
  },
  {
    name: "Ambikapur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Arang",
    state_name: "Chhattisgarh",
  },
  {
    name: "Baikunthpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Balod",
    state_name: "Chhattisgarh",
  },
  {
    name: "Baloda",
    state_name: "Chhattisgarh",
  },
  {
    name: "Baloda Bazar",
    state_name: "Chhattisgarh",
  },
  {
    name: "Basna",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bastar",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bemetara",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bhanpuri",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bhatapara",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bhatgaon",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bhilai",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bijapur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Bilaspur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Champa",
    state_name: "Chhattisgarh",
  },
  {
    name: "Chhuikhadan",
    state_name: "Chhattisgarh",
  },
  {
    name: "Dantewada",
    state_name: "Chhattisgarh",
  },
  {
    name: "Deori",
    state_name: "Chhattisgarh",
  },
  {
    name: "Dhamtari",
    state_name: "Chhattisgarh",
  },
  {
    name: "Dongargaon",
    state_name: "Chhattisgarh",
  },
  {
    name: "Dongargarh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Durg",
    state_name: "Chhattisgarh",
  },
  {
    name: "Gandai",
    state_name: "Chhattisgarh",
  },
  {
    name: "Gariaband",
    state_name: "Chhattisgarh",
  },
  {
    name: "Gaurela",
    state_name: "Chhattisgarh",
  },
  {
    name: "Gharghoda",
    state_name: "Chhattisgarh",
  },
  {
    name: "Gidam",
    state_name: "Chhattisgarh",
  },
  {
    name: "Jagdalpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Janjgir",
    state_name: "Chhattisgarh",
  },
  {
    name: "Janjgir-Champa",
    state_name: "Chhattisgarh",
  },
  {
    name: "Jashpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Jashpurnagar",
    state_name: "Chhattisgarh",
  },
  {
    name: "Junagarh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kabeerdham",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kanker",
    state_name: "Chhattisgarh",
  },
  {
    name: "Katghora",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kawardha",
    state_name: "Chhattisgarh",
  },
  {
    name: "Khairagarh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Khamharia",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kharod",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kharsia",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kirandul",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kondagaon",
    state_name: "Chhattisgarh",
  },
  {
    name: "Korba",
    state_name: "Chhattisgarh",
  },
  {
    name: "Koria",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kota",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kotaparh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kumhari",
    state_name: "Chhattisgarh",
  },
  {
    name: "Kurud",
    state_name: "Chhattisgarh",
  },
  {
    name: "Lormi",
    state_name: "Chhattisgarh",
  },
  {
    name: "Mahasamund",
    state_name: "Chhattisgarh",
  },
  {
    name: "Mungeli",
    state_name: "Chhattisgarh",
  },
  {
    name: "Narayanpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Narharpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pandaria",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pandatarai",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pasan",
    state_name: "Chhattisgarh",
  },
  {
    name: "Patan",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pathalgaon",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pendra",
    state_name: "Chhattisgarh",
  },
  {
    name: "Pithora",
    state_name: "Chhattisgarh",
  },
  {
    name: "Raigarh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Raipur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Raj Nandgaon",
    state_name: "Chhattisgarh",
  },
  {
    name: "Ramanuj Ganj",
    state_name: "Chhattisgarh",
  },
  {
    name: "Ratanpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Sakti",
    state_name: "Chhattisgarh",
  },
  {
    name: "Saraipali",
    state_name: "Chhattisgarh",
  },
  {
    name: "Sarangarh",
    state_name: "Chhattisgarh",
  },
  {
    name: "Seorinarayan",
    state_name: "Chhattisgarh",
  },
  {
    name: "Simga",
    state_name: "Chhattisgarh",
  },
  {
    name: "Surguja",
    state_name: "Chhattisgarh",
  },
  {
    name: "Takhatpur",
    state_name: "Chhattisgarh",
  },
  {
    name: "Umarkot",
    state_name: "Chhattisgarh",
  },
  {
    name: "Uttar Bastar Kanker",
    state_name: "Chhattisgarh",
  },
  {
    name: "Amli",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Dadra",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Dadra & Nagar Haveli",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Daman",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Diu",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Silvassa",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Alipur",
    state_name: "Delhi",
  },
  {
    name: "Bawana",
    state_name: "Delhi",
  },
  {
    name: "Central Delhi",
    state_name: "Delhi",
  },
  {
    name: "Delhi",
    state_name: "Delhi",
  },
  {
    name: "Deoli",
    state_name: "Delhi",
  },
  {
    name: "East Delhi",
    state_name: "Delhi",
  },
  {
    name: "Karol Bagh",
    state_name: "Delhi",
  },
  {
    name: "Najafgarh",
    state_name: "Delhi",
  },
  {
    name: "Nangloi Jat",
    state_name: "Delhi",
  },
  {
    name: "Narela",
    state_name: "Delhi",
  },
  {
    name: "New Delhi",
    state_name: "Delhi",
  },
  {
    name: "North Delhi",
    state_name: "Delhi",
  },
  {
    name: "North East Delhi",
    state_name: "Delhi",
  },
  {
    name: "North West Delhi",
    state_name: "Delhi",
  },
  {
    name: "Pitampura",
    state_name: "Delhi",
  },
  {
    name: "Rohini",
    state_name: "Delhi",
  },
  {
    name: "South Delhi",
    state_name: "Delhi",
  },
  {
    name: "South West Delhi",
    state_name: "Delhi",
  },
  {
    name: "West Delhi",
    state_name: "Delhi",
  },
  {
    name: "Aldona",
    state_name: "Goa",
  },
  {
    name: "Arambol",
    state_name: "Goa",
  },
  {
    name: "Baga",
    state_name: "Goa",
  },
  {
    name: "Bambolim",
    state_name: "Goa",
  },
  {
    name: "Bandora",
    state_name: "Goa",
  },
  {
    name: "Benaulim",
    state_name: "Goa",
  },
  {
    name: "Calangute",
    state_name: "Goa",
  },
  {
    name: "Candolim",
    state_name: "Goa",
  },
  {
    name: "Carapur",
    state_name: "Goa",
  },
  {
    name: "Cavelossim",
    state_name: "Goa",
  },
  {
    name: "Chicalim",
    state_name: "Goa",
  },
  {
    name: "Chinchinim",
    state_name: "Goa",
  },
  {
    name: "Colovale",
    state_name: "Goa",
  },
  {
    name: "Colva",
    state_name: "Goa",
  },
  {
    name: "Cortalim",
    state_name: "Goa",
  },
  {
    name: "Cuncolim",
    state_name: "Goa",
  },
  {
    name: "Curchorem",
    state_name: "Goa",
  },
  {
    name: "Curti",
    state_name: "Goa",
  },
  {
    name: "Davorlim",
    state_name: "Goa",
  },
  {
    name: "Dicholi",
    state_name: "Goa",
  },
  {
    name: "Goa Velha",
    state_name: "Goa",
  },
  {
    name: "Guirim",
    state_name: "Goa",
  },
  {
    name: "Jua",
    state_name: "Goa",
  },
  {
    name: "Kankon",
    state_name: "Goa",
  },
  {
    name: "Madgaon",
    state_name: "Goa",
  },
  {
    name: "Mapuca",
    state_name: "Goa",
  },
  {
    name: "Morjim",
    state_name: "Goa",
  },
  {
    name: "Mormugao",
    state_name: "Goa",
  },
  {
    name: "Navelim",
    state_name: "Goa",
  },
  {
    name: "North Goa",
    state_name: "Goa",
  },
  {
    name: "Palle",
    state_name: "Goa",
  },
  {
    name: "Panaji",
    state_name: "Goa",
  },
  {
    name: "Pernem",
    state_name: "Goa",
  },
  {
    name: "Ponda",
    state_name: "Goa",
  },
  {
    name: "Quepem",
    state_name: "Goa",
  },
  {
    name: "Queula",
    state_name: "Goa",
  },
  {
    name: "Raia",
    state_name: "Goa",
  },
  {
    name: "Saligao",
    state_name: "Goa",
  },
  {
    name: "Sancoale",
    state_name: "Goa",
  },
  {
    name: "Sanguem",
    state_name: "Goa",
  },
  {
    name: "Sanquelim",
    state_name: "Goa",
  },
  {
    name: "Sanvordem",
    state_name: "Goa",
  },
  {
    name: "Serula",
    state_name: "Goa",
  },
  {
    name: "Solim",
    state_name: "Goa",
  },
  {
    name: "South Goa",
    state_name: "Goa",
  },
  {
    name: "Taleigao",
    state_name: "Goa",
  },
  {
    name: "Vagator",
    state_name: "Goa",
  },
  {
    name: "Valpoy",
    state_name: "Goa",
  },
  {
    name: "Varca",
    state_name: "Goa",
  },
  {
    name: "Vasco da Gama",
    state_name: "Goa",
  },
  {
    name: "Abrama",
    state_name: "Gujarat",
  },
  {
    name: "Adalaj",
    state_name: "Gujarat",
  },
  {
    name: "Agol",
    state_name: "Gujarat",
  },
  {
    name: "Ahmedabad",
    state_name: "Gujarat",
  },
  {
    name: "Ahwa",
    state_name: "Gujarat",
  },
  {
    name: "Akrund",
    state_name: "Gujarat",
  },
  {
    name: "Amod",
    state_name: "Gujarat",
  },
  {
    name: "Amod",
    state_name: "Gujarat",
  },
  {
    name: "Amreli",
    state_name: "Gujarat",
  },
  {
    name: "Amroli",
    state_name: "Gujarat",
  },
  {
    name: "Anand",
    state_name: "Gujarat",
  },
  {
    name: "Anjar",
    state_name: "Gujarat",
  },
  {
    name: "Ankleshwar",
    state_name: "Gujarat",
  },
  {
    name: "Babra",
    state_name: "Gujarat",
  },
  {
    name: "Bagasara",
    state_name: "Gujarat",
  },
  {
    name: "Bagasra",
    state_name: "Gujarat",
  },
  {
    name: "Bakharla",
    state_name: "Gujarat",
  },
  {
    name: "Balagam",
    state_name: "Gujarat",
  },
  {
    name: "Balasinor",
    state_name: "Gujarat",
  },
  {
    name: "Balisana",
    state_name: "Gujarat",
  },
  {
    name: "Bamanbore",
    state_name: "Gujarat",
  },
  {
    name: "Banas Kantha",
    state_name: "Gujarat",
  },
  {
    name: "Bandia",
    state_name: "Gujarat",
  },
  {
    name: "Bantva",
    state_name: "Gujarat",
  },
  {
    name: "Bardoli",
    state_name: "Gujarat",
  },
  {
    name: "Bedi",
    state_name: "Gujarat",
  },
  {
    name: "Bhachau",
    state_name: "Gujarat",
  },
  {
    name: "Bhadran",
    state_name: "Gujarat",
  },
  {
    name: "Bhandu",
    state_name: "Gujarat",
  },
  {
    name: "Bhanvad",
    state_name: "Gujarat",
  },
  {
    name: "Bharuch",
    state_name: "Gujarat",
  },
  {
    name: "Bhatha",
    state_name: "Gujarat",
  },
  {
    name: "Bhavnagar",
    state_name: "Gujarat",
  },
  {
    name: "Bhayavadar",
    state_name: "Gujarat",
  },
  {
    name: "Bhildi",
    state_name: "Gujarat",
  },
  {
    name: "Bhojpur Dharampur",
    state_name: "Gujarat",
  },
  {
    name: "Bhuj",
    state_name: "Gujarat",
  },
  {
    name: "Bilimora",
    state_name: "Gujarat",
  },
  {
    name: "Bilkha",
    state_name: "Gujarat",
  },
  {
    name: "Borsad",
    state_name: "Gujarat",
  },
  {
    name: "Botad",
    state_name: "Gujarat",
  },
  {
    name: "Chaklasi",
    state_name: "Gujarat",
  },
  {
    name: "Chalala",
    state_name: "Gujarat",
  },
  {
    name: "Chaloda",
    state_name: "Gujarat",
  },
  {
    name: "Champaner",
    state_name: "Gujarat",
  },
  {
    name: "Chanasma",
    state_name: "Gujarat",
  },
  {
    name: "Chhala",
    state_name: "Gujarat",
  },
  {
    name: "Chhota Udepur",
    state_name: "Gujarat",
  },
  {
    name: "Chikhli",
    state_name: "Gujarat",
  },
  {
    name: "Chotila",
    state_name: "Gujarat",
  },
  {
    name: "Chuda",
    state_name: "Gujarat",
  },
  {
    name: "Dabhoda",
    state_name: "Gujarat",
  },
  {
    name: "Dabhoi",
    state_name: "Gujarat",
  },
  {
    name: "Dahegam",
    state_name: "Gujarat",
  },
  {
    name: "Dahod",
    state_name: "Gujarat",
  },
  {
    name: "Dakor",
    state_name: "Gujarat",
  },
  {
    name: "Damnagar",
    state_name: "Gujarat",
  },
  {
    name: "Dandi",
    state_name: "Gujarat",
  },
  {
    name: "Dangs (India)",
    state_name: "Gujarat",
  },
  {
    name: "Danta",
    state_name: "Gujarat",
  },
  {
    name: "Dayapar",
    state_name: "Gujarat",
  },
  {
    name: "Delvada",
    state_name: "Gujarat",
  },
  {
    name: "Delwada",
    state_name: "Gujarat",
  },
  {
    name: "Detroj",
    state_name: "Gujarat",
  },
  {
    name: "Devbhumi Dwarka",
    state_name: "Gujarat",
  },
  {
    name: "Devgadh Bariya",
    state_name: "Gujarat",
  },
  {
    name: "Dhandhuka",
    state_name: "Gujarat",
  },
  {
    name: "Dhanera",
    state_name: "Gujarat",
  },
  {
    name: "Dhansura",
    state_name: "Gujarat",
  },
  {
    name: "Dharampur",
    state_name: "Gujarat",
  },
  {
    name: "Dharasana",
    state_name: "Gujarat",
  },
  {
    name: "Dhari",
    state_name: "Gujarat",
  },
  {
    name: "Dhasa",
    state_name: "Gujarat",
  },
  {
    name: "Dhola",
    state_name: "Gujarat",
  },
  {
    name: "Dholera",
    state_name: "Gujarat",
  },
  {
    name: "Dholka",
    state_name: "Gujarat",
  },
  {
    name: "Dhoraji",
    state_name: "Gujarat",
  },
  {
    name: "Dhrangadhra",
    state_name: "Gujarat",
  },
  {
    name: "Dhrol",
    state_name: "Gujarat",
  },
  {
    name: "Dhuwaran",
    state_name: "Gujarat",
  },
  {
    name: "Disa",
    state_name: "Gujarat",
  },
  {
    name: "Dohad",
    state_name: "Gujarat",
  },
  {
    name: "Dumkhal",
    state_name: "Gujarat",
  },
  {
    name: "Dungarpur",
    state_name: "Gujarat",
  },
  {
    name: "Dwarka",
    state_name: "Gujarat",
  },
  {
    name: "Gadhada",
    state_name: "Gujarat",
  },
  {
    name: "Gandevi",
    state_name: "Gujarat",
  },
  {
    name: "Gandhidham",
    state_name: "Gujarat",
  },
  {
    name: "Gandhinagar",
    state_name: "Gujarat",
  },
  {
    name: "Gariadhar",
    state_name: "Gujarat",
  },
  {
    name: "Ghodasar",
    state_name: "Gujarat",
  },
  {
    name: "Ghogha",
    state_name: "Gujarat",
  },
  {
    name: "Gir Somnath",
    state_name: "Gujarat",
  },
  {
    name: "Godhra",
    state_name: "Gujarat",
  },
  {
    name: "Gondal",
    state_name: "Gujarat",
  },
  {
    name: "Gorwa",
    state_name: "Gujarat",
  },
  {
    name: "Halenda",
    state_name: "Gujarat",
  },
  {
    name: "Halol",
    state_name: "Gujarat",
  },
  {
    name: "Halvad",
    state_name: "Gujarat",
  },
  {
    name: "Hansot",
    state_name: "Gujarat",
  },
  {
    name: "Harij",
    state_name: "Gujarat",
  },
  {
    name: "Harsol",
    state_name: "Gujarat",
  },
  {
    name: "Hathuran",
    state_name: "Gujarat",
  },
  {
    name: "Himatnagar",
    state_name: "Gujarat",
  },
  {
    name: "Idar",
    state_name: "Gujarat",
  },
  {
    name: "Jakhau",
    state_name: "Gujarat",
  },
  {
    name: "Jalalpore",
    state_name: "Gujarat",
  },
  {
    name: "Jalalpur",
    state_name: "Gujarat",
  },
  {
    name: "Jalia",
    state_name: "Gujarat",
  },
  {
    name: "Jambuda",
    state_name: "Gujarat",
  },
  {
    name: "Jambusar",
    state_name: "Gujarat",
  },
  {
    name: "Jamnagar",
    state_name: "Gujarat",
  },
  {
    name: "Jarod",
    state_name: "Gujarat",
  },
  {
    name: "Jasdan",
    state_name: "Gujarat",
  },
  {
    name: "Jetalpur",
    state_name: "Gujarat",
  },
  {
    name: "Jetalsar",
    state_name: "Gujarat",
  },
  {
    name: "Jetpur",
    state_name: "Gujarat",
  },
  {
    name: "Jetpur (Navagadh)",
    state_name: "Gujarat",
  },
  {
    name: "Jhalod",
    state_name: "Gujarat",
  },
  {
    name: "Jhulasan",
    state_name: "Gujarat",
  },
  {
    name: "Jodhpur",
    state_name: "Gujarat",
  },
  {
    name: "Jodhpur (Ahmedabad)",
    state_name: "Gujarat",
  },
  {
    name: "Jodia",
    state_name: "Gujarat",
  },
  {
    name: "Jodiya Bandar",
    state_name: "Gujarat",
  },
  {
    name: "Junagadh",
    state_name: "Gujarat",
  },
  {
    name: "Kachchh",
    state_name: "Gujarat",
  },
  {
    name: "Kachholi",
    state_name: "Gujarat",
  },
  {
    name: "Kadi",
    state_name: "Gujarat",
  },
  {
    name: "Kadod",
    state_name: "Gujarat",
  },
  {
    name: "Kalavad",
    state_name: "Gujarat",
  },
  {
    name: "Kalol",
    state_name: "Gujarat",
  },
  {
    name: "Kandla",
    state_name: "Gujarat",
  },
  {
    name: "Kandla port",
    state_name: "Gujarat",
  },
  {
    name: "Kanodar",
    state_name: "Gujarat",
  },
  {
    name: "Kapadvanj",
    state_name: "Gujarat",
  },
  {
    name: "Karamsad",
    state_name: "Gujarat",
  },
  {
    name: "Kariana",
    state_name: "Gujarat",
  },
  {
    name: "Karjan",
    state_name: "Gujarat",
  },
  {
    name: "Kathor",
    state_name: "Gujarat",
  },
  {
    name: "Katpur",
    state_name: "Gujarat",
  },
  {
    name: "Kawant",
    state_name: "Gujarat",
  },
  {
    name: "Kayavarohan",
    state_name: "Gujarat",
  },
  {
    name: "Kerwada",
    state_name: "Gujarat",
  },
  {
    name: "Keshod",
    state_name: "Gujarat",
  },
  {
    name: "Khambhalia",
    state_name: "Gujarat",
  },
  {
    name: "Khambhat",
    state_name: "Gujarat",
  },
  {
    name: "Khavda",
    state_name: "Gujarat",
  },
  {
    name: "Kheda",
    state_name: "Gujarat",
  },
  {
    name: "Khedbrahma",
    state_name: "Gujarat",
  },
  {
    name: "Khedoi",
    state_name: "Gujarat",
  },
  {
    name: "Kherali",
    state_name: "Gujarat",
  },
  {
    name: "Kheralu",
    state_name: "Gujarat",
  },
  {
    name: "Kodinar",
    state_name: "Gujarat",
  },
  {
    name: "Kosamba",
    state_name: "Gujarat",
  },
  {
    name: "Kothara",
    state_name: "Gujarat",
  },
  {
    name: "Kotharia",
    state_name: "Gujarat",
  },
  {
    name: "Kukarmunda",
    state_name: "Gujarat",
  },
  {
    name: "Kukma",
    state_name: "Gujarat",
  },
  {
    name: "Kundla",
    state_name: "Gujarat",
  },
  {
    name: "Kutch district",
    state_name: "Gujarat",
  },
  {
    name: "Kutiyana",
    state_name: "Gujarat",
  },
  {
    name: "Ladol",
    state_name: "Gujarat",
  },
  {
    name: "Lakhpat",
    state_name: "Gujarat",
  },
  {
    name: "Lakhtar",
    state_name: "Gujarat",
  },
  {
    name: "Lalpur",
    state_name: "Gujarat",
  },
  {
    name: "Langhnaj",
    state_name: "Gujarat",
  },
  {
    name: "Lathi",
    state_name: "Gujarat",
  },
  {
    name: "Limbdi",
    state_name: "Gujarat",
  },
  {
    name: "Limkheda",
    state_name: "Gujarat",
  },
  {
    name: "Lunavada",
    state_name: "Gujarat",
  },
  {
    name: "Madhavpur Ghed",
    state_name: "Gujarat",
  },
  {
    name: "Madhi",
    state_name: "Gujarat",
  },
  {
    name: "Mahemdavad",
    state_name: "Gujarat",
  },
  {
    name: "Mahesana",
    state_name: "Gujarat",
  },
  {
    name: "Mahisa",
    state_name: "Gujarat",
  },
  {
    name: "Mahudha",
    state_name: "Gujarat",
  },
  {
    name: "Mahuva",
    state_name: "Gujarat",
  },
  {
    name: "Mahuva (Surat)",
    state_name: "Gujarat",
  },
  {
    name: "Malpur",
    state_name: "Gujarat",
  },
  {
    name: "Manavadar",
    state_name: "Gujarat",
  },
  {
    name: "Mandal",
    state_name: "Gujarat",
  },
  {
    name: "Mandvi",
    state_name: "Gujarat",
  },
  {
    name: "Mandvi (Surat)",
    state_name: "Gujarat",
  },
  {
    name: "Mangrol",
    state_name: "Gujarat",
  },
  {
    name: "Mangrol (Junagadh)",
    state_name: "Gujarat",
  },
  {
    name: "Mansa",
    state_name: "Gujarat",
  },
  {
    name: "Meghraj",
    state_name: "Gujarat",
  },
  {
    name: "Mehsana",
    state_name: "Gujarat",
  },
  {
    name: "Mendarda",
    state_name: "Gujarat",
  },
  {
    name: "Mithapur",
    state_name: "Gujarat",
  },
  {
    name: "Modasa",
    state_name: "Gujarat",
  },
  {
    name: "Morbi",
    state_name: "Gujarat",
  },
  {
    name: "Morva (Hadaf)",
    state_name: "Gujarat",
  },
  {
    name: "Morwa",
    state_name: "Gujarat",
  },
  {
    name: "Mundra",
    state_name: "Gujarat",
  },
  {
    name: "Nadiad",
    state_name: "Gujarat",
  },
  {
    name: "Nadiad",
    state_name: "Gujarat",
  },
  {
    name: "Nagwa",
    state_name: "Gujarat",
  },
  {
    name: "Naldhara",
    state_name: "Gujarat",
  },
  {
    name: "Naliya",
    state_name: "Gujarat",
  },
  {
    name: "Nargol",
    state_name: "Gujarat",
  },
  {
    name: "Narmada",
    state_name: "Gujarat",
  },
  {
    name: "Naroda",
    state_name: "Gujarat",
  },
  {
    name: "Navsari",
    state_name: "Gujarat",
  },
  {
    name: "Nikora",
    state_name: "Gujarat",
  },
  {
    name: "Nizar",
    state_name: "Gujarat",
  },
  {
    name: "Odadar",
    state_name: "Gujarat",
  },
  {
    name: "Okha",
    state_name: "Gujarat",
  },
  {
    name: "Olpad",
    state_name: "Gujarat",
  },
  {
    name: "Paddhari",
    state_name: "Gujarat",
  },
  {
    name: "Padra",
    state_name: "Gujarat",
  },
  {
    name: "Palanpur",
    state_name: "Gujarat",
  },
  {
    name: "Palanswa",
    state_name: "Gujarat",
  },
  {
    name: "Palitana",
    state_name: "Gujarat",
  },
  {
    name: "Paliyad",
    state_name: "Gujarat",
  },
  {
    name: "Palsana",
    state_name: "Gujarat",
  },
  {
    name: "Panch Mahals",
    state_name: "Gujarat",
  },
  {
    name: "Panchmahal district",
    state_name: "Gujarat",
  },
  {
    name: "Pardi",
    state_name: "Gujarat",
  },
  {
    name: "Parnera",
    state_name: "Gujarat",
  },
  {
    name: "Patan",
    state_name: "Gujarat",
  },
  {
    name: "Pavi Jetpur",
    state_name: "Gujarat",
  },
  {
    name: "Petlad",
    state_name: "Gujarat",
  },
  {
    name: "Pipavav",
    state_name: "Gujarat",
  },
  {
    name: "Piplod",
    state_name: "Gujarat",
  },
  {
    name: "Porbandar",
    state_name: "Gujarat",
  },
  {
    name: "Prabhas Patan",
    state_name: "Gujarat",
  },
  {
    name: "Prantij",
    state_name: "Gujarat",
  },
  {
    name: "Radhanpur",
    state_name: "Gujarat",
  },
  {
    name: "Rajkot",
    state_name: "Gujarat",
  },
  {
    name: "Rajpipla",
    state_name: "Gujarat",
  },
  {
    name: "Rajula",
    state_name: "Gujarat",
  },
  {
    name: "Ranavav",
    state_name: "Gujarat",
  },
  {
    name: "Ranpur",
    state_name: "Gujarat",
  },
  {
    name: "Rapar",
    state_name: "Gujarat",
  },
  {
    name: "Reha",
    state_name: "Gujarat",
  },
  {
    name: "Roha",
    state_name: "Gujarat",
  },
  {
    name: "Sabar Kantha",
    state_name: "Gujarat",
  },
  {
    name: "Sachin",
    state_name: "Gujarat",
  },
  {
    name: "Salaya",
    state_name: "Gujarat",
  },
  {
    name: "Samakhiali",
    state_name: "Gujarat",
  },
  {
    name: "Sanand",
    state_name: "Gujarat",
  },
  {
    name: "Sankheda",
    state_name: "Gujarat",
  },
  {
    name: "Sarbhon",
    state_name: "Gujarat",
  },
  {
    name: "Sardoi",
    state_name: "Gujarat",
  },
  {
    name: "Sarkhej",
    state_name: "Gujarat",
  },
  {
    name: "Sathamba",
    state_name: "Gujarat",
  },
  {
    name: "Savarkundla",
    state_name: "Gujarat",
  },
  {
    name: "Savli",
    state_name: "Gujarat",
  },
  {
    name: "Sayla",
    state_name: "Gujarat",
  },
  {
    name: "Shahpur",
    state_name: "Gujarat",
  },
  {
    name: "Shivrajpur",
    state_name: "Gujarat",
  },
  {
    name: "Siddhpur",
    state_name: "Gujarat",
  },
  {
    name: "Sihor",
    state_name: "Gujarat",
  },
  {
    name: "Sikka",
    state_name: "Gujarat",
  },
  {
    name: "Sinor",
    state_name: "Gujarat",
  },
  {
    name: "Sojitra",
    state_name: "Gujarat",
  },
  {
    name: "Songadh",
    state_name: "Gujarat",
  },
  {
    name: "Supedi",
    state_name: "Gujarat",
  },
  {
    name: "Surat",
    state_name: "Gujarat",
  },
  {
    name: "Surendranagar",
    state_name: "Gujarat",
  },
  {
    name: "Sutrapada",
    state_name: "Gujarat",
  },
  {
    name: "Talaja",
    state_name: "Gujarat",
  },
  {
    name: "Tankara",
    state_name: "Gujarat",
  },
  {
    name: "Tapi",
    state_name: "Gujarat",
  },
  {
    name: "Than",
    state_name: "Gujarat",
  },
  {
    name: "Thangadh",
    state_name: "Gujarat",
  },
  {
    name: "Tharad",
    state_name: "Gujarat",
  },
  {
    name: "Thasra",
    state_name: "Gujarat",
  },
  {
    name: "The Dangs",
    state_name: "Gujarat",
  },
  {
    name: "Umarpada",
    state_name: "Gujarat",
  },
  {
    name: "Umrala",
    state_name: "Gujarat",
  },
  {
    name: "Umreth",
    state_name: "Gujarat",
  },
  {
    name: "Un",
    state_name: "Gujarat",
  },
  {
    name: "Una",
    state_name: "Gujarat",
  },
  {
    name: "Unjha",
    state_name: "Gujarat",
  },
  {
    name: "Upleta",
    state_name: "Gujarat",
  },
  {
    name: "Utran",
    state_name: "Gujarat",
  },
  {
    name: "Vadgam",
    state_name: "Gujarat",
  },
  {
    name: "Vadnagar",
    state_name: "Gujarat",
  },
  {
    name: "Vadodara",
    state_name: "Gujarat",
  },
  {
    name: "Vaghodia",
    state_name: "Gujarat",
  },
  {
    name: "Vaghodia INA",
    state_name: "Gujarat",
  },
  {
    name: "Vallabh Vidyanagar",
    state_name: "Gujarat",
  },
  {
    name: "Vallabhipur",
    state_name: "Gujarat",
  },
  {
    name: "Valsad",
    state_name: "Gujarat",
  },
  {
    name: "Vanala",
    state_name: "Gujarat",
  },
  {
    name: "Vansda",
    state_name: "Gujarat",
  },
  {
    name: "Vanthli",
    state_name: "Gujarat",
  },
  {
    name: "Vapi",
    state_name: "Gujarat",
  },
  {
    name: "Vartej",
    state_name: "Gujarat",
  },
  {
    name: "Vasa",
    state_name: "Gujarat",
  },
  {
    name: "Vasavad",
    state_name: "Gujarat",
  },
  {
    name: "Vaso",
    state_name: "Gujarat",
  },
  {
    name: "Vataman",
    state_name: "Gujarat",
  },
  {
    name: "Vejalpur",
    state_name: "Gujarat",
  },
  {
    name: "Veraval",
    state_name: "Gujarat",
  },
  {
    name: "Vijapur",
    state_name: "Gujarat",
  },
  {
    name: "Vinchhiya",
    state_name: "Gujarat",
  },
  {
    name: "Viramgam",
    state_name: "Gujarat",
  },
  {
    name: "Virpur",
    state_name: "Gujarat",
  },
  {
    name: "Visavadar",
    state_name: "Gujarat",
  },
  {
    name: "Visnagar",
    state_name: "Gujarat",
  },
  {
    name: "Vyara",
    state_name: "Gujarat",
  },
  {
    name: "Wadhai",
    state_name: "Gujarat",
  },
  {
    name: "Wadhwan",
    state_name: "Gujarat",
  },
  {
    name: "Waghai",
    state_name: "Gujarat",
  },
  {
    name: "Wankaner",
    state_name: "Gujarat",
  },
  {
    name: "Ambala",
    state_name: "Haryana",
  },
  {
    name: "Asandh",
    state_name: "Haryana",
  },
  {
    name: "Ateli Mandi",
    state_name: "Haryana",
  },
  {
    name: "Bahadurgarh",
    state_name: "Haryana",
  },
  {
    name: "Bara Uchana",
    state_name: "Haryana",
  },
  {
    name: "Barwala",
    state_name: "Haryana",
  },
  {
    name: "Bawal",
    state_name: "Haryana",
  },
  {
    name: "Beri Khas",
    state_name: "Haryana",
  },
  {
    name: "Bhiwani",
    state_name: "Haryana",
  },
  {
    name: "Bilaspur",
    state_name: "Haryana",
  },
  {
    name: "Buriya",
    state_name: "Haryana",
  },
  {
    name: "Charkhi Dadri",
    state_name: "Haryana",
  },
  {
    name: "Chhachhrauli",
    state_name: "Haryana",
  },
  {
    name: "Dabwali",
    state_name: "Haryana",
  },
  {
    name: "Dharuhera",
    state_name: "Haryana",
  },
  {
    name: "Ellenabad",
    state_name: "Haryana",
  },
  {
    name: "Faridabad",
    state_name: "Haryana",
  },
  {
    name: "Farrukhnagar",
    state_name: "Haryana",
  },
  {
    name: "Fatehabad",
    state_name: "Haryana",
  },
  {
    name: "Firozpur Jhirka",
    state_name: "Haryana",
  },
  {
    name: "Gharaunda",
    state_name: "Haryana",
  },
  {
    name: "Gohana",
    state_name: "Haryana",
  },
  {
    name: "Gorakhpur",
    state_name: "Haryana",
  },
  {
    name: "Gurgaon",
    state_name: "Haryana",
  },
  {
    name: "Hansi",
    state_name: "Haryana",
  },
  {
    name: "Hasanpur",
    state_name: "Haryana",
  },
  {
    name: "Hisar",
    state_name: "Haryana",
  },
  {
    name: "Hodal",
    state_name: "Haryana",
  },
  {
    name: "Inda Chhoi",
    state_name: "Haryana",
  },
  {
    name: "Indri",
    state_name: "Haryana",
  },
  {
    name: "Jagadhri",
    state_name: "Haryana",
  },
  {
    name: "Jakhal",
    state_name: "Haryana",
  },
  {
    name: "Jhajjar",
    state_name: "Haryana",
  },
  {
    name: "Jind",
    state_name: "Haryana",
  },
  {
    name: "Kaithal",
    state_name: "Haryana",
  },
  {
    name: "Kalanaur",
    state_name: "Haryana",
  },
  {
    name: "Kalanwali",
    state_name: "Haryana",
  },
  {
    name: "Kanina Khas",
    state_name: "Haryana",
  },
  {
    name: "Karnal",
    state_name: "Haryana",
  },
  {
    name: "Kharkhauda",
    state_name: "Haryana",
  },
  {
    name: "Kheri Sampla",
    state_name: "Haryana",
  },
  {
    name: "Kurukshetra",
    state_name: "Haryana",
  },
  {
    name: "Ladwa",
    state_name: "Haryana",
  },
  {
    name: "Loharu",
    state_name: "Haryana",
  },
  {
    name: "Maham",
    state_name: "Haryana",
  },
  {
    name: "Mahendragarh",
    state_name: "Haryana",
  },
  {
    name: "Mandholi Kalan",
    state_name: "Haryana",
  },
  {
    name: "Mustafabad",
    state_name: "Haryana",
  },
  {
    name: "Narayangarh",
    state_name: "Haryana",
  },
  {
    name: "Narnaul",
    state_name: "Haryana",
  },
  {
    name: "Narnaund",
    state_name: "Haryana",
  },
  {
    name: "Narwana",
    state_name: "Haryana",
  },
  {
    name: "Nilokheri",
    state_name: "Haryana",
  },
  {
    name: "Nuh",
    state_name: "Haryana",
  },
  {
    name: "Palwal",
    state_name: "Haryana",
  },
  {
    name: "Panchkula",
    state_name: "Haryana",
  },
  {
    name: "Panipat",
    state_name: "Haryana",
  },
  {
    name: "Pataudi",
    state_name: "Haryana",
  },
  {
    name: "Pehowa",
    state_name: "Haryana",
  },
  {
    name: "Pinjaur",
    state_name: "Haryana",
  },
  {
    name: "Punahana",
    state_name: "Haryana",
  },
  {
    name: "Pundri",
    state_name: "Haryana",
  },
  {
    name: "Radaur",
    state_name: "Haryana",
  },
  {
    name: "Rania",
    state_name: "Haryana",
  },
  {
    name: "Ratia",
    state_name: "Haryana",
  },
  {
    name: "Rewari",
    state_name: "Haryana",
  },
  {
    name: "Rohtak",
    state_name: "Haryana",
  },
  {
    name: "Safidon",
    state_name: "Haryana",
  },
  {
    name: "Samalkha",
    state_name: "Haryana",
  },
  {
    name: "Shadipur Julana",
    state_name: "Haryana",
  },
  {
    name: "Shahabad",
    state_name: "Haryana",
  },
  {
    name: "Sirsa",
    state_name: "Haryana",
  },
  {
    name: "Sohna",
    state_name: "Haryana",
  },
  {
    name: "Sonipat",
    state_name: "Haryana",
  },
  {
    name: "Taoru",
    state_name: "Haryana",
  },
  {
    name: "Thanesar",
    state_name: "Haryana",
  },
  {
    name: "Tohana",
    state_name: "Haryana",
  },
  {
    name: "Tosham",
    state_name: "Haryana",
  },
  {
    name: "Uklana",
    state_name: "Haryana",
  },
  {
    name: "Yamunanagar",
    state_name: "Haryana",
  },
  {
    name: "Arki",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Baddi",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Banjar",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Bilaspur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Chamba",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Chaupal",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Chowari",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Chuari Khas",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Dagshai",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Dalhousie",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Daulatpur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Dera Gopipur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Dharamsala",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Gagret",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Ghumarwin",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Hamirpur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Jawala Mukhi",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Jogindarnagar",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Jubbal",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Jutogh",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kalka",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kangar",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kangra",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kasauli",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kinnaur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kotkhai",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kotla",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kulu",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Kyelang",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Lahul and Spiti",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Manali",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Mandi",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Nadaun",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Nagar",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Nagrota",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Nahan",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Nalagarh",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Palampur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Pandoh",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Paonta Sahib",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Parwanoo",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Rajgarh",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Rampur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Rohru",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Sabathu",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Santokhgarh",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Sarahan",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Sarka Ghat",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Seoni",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Shimla",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Sirmaur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Solan",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Sundarnagar",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Theog",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Tira Sujanpur",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Una",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Yol",
    state_name: "Himachal Pradesh",
  },
  {
    name: "Akhnur",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Anantnag",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Awantipur",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Badgam",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Bandipore",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Banihal",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Baramula",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Batoti",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Bhadarwah",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Bijbehara",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Bishnah",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Doda",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Ganderbal",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Gho Brahmanan de",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Hajan",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Hiranagar",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Jammu",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Jaurian",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Kathua",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Katra",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Khaur",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Kishtwar",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Kud",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Kulgam",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Kupwara",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Ladakh",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Magam",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Nawanshahr",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Noria",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Padam",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Pahlgam",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Parol",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Pattan",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Pulwama",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Punch",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Qazigund",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Rajaori",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Rajauri",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Ramban",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Ramgarh",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Ramnagar",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Riasi",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Samba",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Shupiyan",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Sopur",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Soyibug",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Srinagar",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Sumbal",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Thang",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Thanna Mandi",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Tral",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Tsrar Sharif",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Udhampur",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Uri",
    state_name: "Jammu and Kashmir",
  },
  {
    name: "Bagra",
    state_name: "Jharkhand",
  },
  {
    name: "Barka Kana",
    state_name: "Jharkhand",
  },
  {
    name: "Barki Saria",
    state_name: "Jharkhand",
  },
  {
    name: "Barwadih",
    state_name: "Jharkhand",
  },
  {
    name: "Bhojudih",
    state_name: "Jharkhand",
  },
  {
    name: "Bokaro",
    state_name: "Jharkhand",
  },
  {
    name: "Bundu",
    state_name: "Jharkhand",
  },
  {
    name: "Chaibasa",
    state_name: "Jharkhand",
  },
  {
    name: "Chakradharpur",
    state_name: "Jharkhand",
  },
  {
    name: "Chakulia",
    state_name: "Jharkhand",
  },
  {
    name: "Chandil",
    state_name: "Jharkhand",
  },
  {
    name: "Chas",
    state_name: "Jharkhand",
  },
  {
    name: "Chatra",
    state_name: "Jharkhand",
  },
  {
    name: "Chiria",
    state_name: "Jharkhand",
  },
  {
    name: "Daltonganj",
    state_name: "Jharkhand",
  },
  {
    name: "Deogarh",
    state_name: "Jharkhand",
  },
  {
    name: "Dhanbad",
    state_name: "Jharkhand",
  },
  {
    name: "Dhanwar",
    state_name: "Jharkhand",
  },
  {
    name: "Dugda",
    state_name: "Jharkhand",
  },
  {
    name: "Dumka",
    state_name: "Jharkhand",
  },
  {
    name: "Garhwa",
    state_name: "Jharkhand",
  },
  {
    name: "Ghatsila",
    state_name: "Jharkhand",
  },
  {
    name: "Giridih",
    state_name: "Jharkhand",
  },
  {
    name: "Gobindpur",
    state_name: "Jharkhand",
  },
  {
    name: "Godda",
    state_name: "Jharkhand",
  },
  {
    name: "Gomoh",
    state_name: "Jharkhand",
  },
  {
    name: "Gopinathpur",
    state_name: "Jharkhand",
  },
  {
    name: "Gua",
    state_name: "Jharkhand",
  },
  {
    name: "Gumia",
    state_name: "Jharkhand",
  },
  {
    name: "Gumla",
    state_name: "Jharkhand",
  },
  {
    name: "Hazaribag",
    state_name: "Jharkhand",
  },
  {
    name: "Hazaribagh",
    state_name: "Jharkhand",
  },
  {
    name: "Hesla",
    state_name: "Jharkhand",
  },
  {
    name: "Husainabad",
    state_name: "Jharkhand",
  },
  {
    name: "Jagannathpur",
    state_name: "Jharkhand",
  },
  {
    name: "Jamadoba",
    state_name: "Jharkhand",
  },
  {
    name: "Jamshedpur",
    state_name: "Jharkhand",
  },
  {
    name: "Jamtara",
    state_name: "Jharkhand",
  },
  {
    name: "Jasidih",
    state_name: "Jharkhand",
  },
  {
    name: "Jharia",
    state_name: "Jharkhand",
  },
  {
    name: "Jugsalai",
    state_name: "Jharkhand",
  },
  {
    name: "Jumri Tilaiya",
    state_name: "Jharkhand",
  },
  {
    name: "Kalikapur",
    state_name: "Jharkhand",
  },
  {
    name: "Kandra",
    state_name: "Jharkhand",
  },
  {
    name: "Kanke",
    state_name: "Jharkhand",
  },
  {
    name: "Katras",
    state_name: "Jharkhand",
  },
  {
    name: "Kenduadih",
    state_name: "Jharkhand",
  },
  {
    name: "Kharsawan",
    state_name: "Jharkhand",
  },
  {
    name: "Khunti",
    state_name: "Jharkhand",
  },
  {
    name: "Kodarma",
    state_name: "Jharkhand",
  },
  {
    name: "Kuju",
    state_name: "Jharkhand",
  },
  {
    name: "Latehar",
    state_name: "Jharkhand",
  },
  {
    name: "Lohardaga",
    state_name: "Jharkhand",
  },
  {
    name: "Madhupur",
    state_name: "Jharkhand",
  },
  {
    name: "Malkera",
    state_name: "Jharkhand",
  },
  {
    name: "Manoharpur",
    state_name: "Jharkhand",
  },
  {
    name: "Mugma",
    state_name: "Jharkhand",
  },
  {
    name: "Mushabani",
    state_name: "Jharkhand",
  },
  {
    name: "Neturhat",
    state_name: "Jharkhand",
  },
  {
    name: "Nirsa",
    state_name: "Jharkhand",
  },
  {
    name: "Noamundi",
    state_name: "Jharkhand",
  },
  {
    name: "Pakur",
    state_name: "Jharkhand",
  },
  {
    name: "Palamu",
    state_name: "Jharkhand",
  },
  {
    name: "Pashchim Singhbhum",
    state_name: "Jharkhand",
  },
  {
    name: "patamda",
    state_name: "Jharkhand",
  },
  {
    name: "Pathardih",
    state_name: "Jharkhand",
  },
  {
    name: "Purba Singhbhum",
    state_name: "Jharkhand",
  },
  {
    name: "Ramgarh",
    state_name: "Jharkhand",
  },
  {
    name: "Ranchi",
    state_name: "Jharkhand",
  },
  {
    name: "Ray",
    state_name: "Jharkhand",
  },
  {
    name: "Sahibganj",
    state_name: "Jharkhand",
  },
  {
    name: "Saraikela",
    state_name: "Jharkhand",
  },
  {
    name: "Sarubera",
    state_name: "Jharkhand",
  },
  {
    name: "Sijua",
    state_name: "Jharkhand",
  },
  {
    name: "Simdega",
    state_name: "Jharkhand",
  },
  {
    name: "Sini",
    state_name: "Jharkhand",
  },
  {
    name: "Topchanchi",
    state_name: "Jharkhand",
  },
  {
    name: "Afzalpur",
    state_name: "Karnataka",
  },
  {
    name: "Ajjampur",
    state_name: "Karnataka",
  },
  {
    name: "Aland",
    state_name: "Karnataka",
  },
  {
    name: "Alnavar",
    state_name: "Karnataka",
  },
  {
    name: "Alur",
    state_name: "Karnataka",
  },
  {
    name: "Anekal",
    state_name: "Karnataka",
  },
  {
    name: "Ankola",
    state_name: "Karnataka",
  },
  {
    name: "Annigeri",
    state_name: "Karnataka",
  },
  {
    name: "Arkalgud",
    state_name: "Karnataka",
  },
  {
    name: "Arsikere",
    state_name: "Karnataka",
  },
  {
    name: "Athni",
    state_name: "Karnataka",
  },
  {
    name: "Aurad",
    state_name: "Karnataka",
  },
  {
    name: "Badami",
    state_name: "Karnataka",
  },
  {
    name: "Bagalkot",
    state_name: "Karnataka",
  },
  {
    name: "Bagepalli",
    state_name: "Karnataka",
  },
  {
    name: "Bail-Hongal",
    state_name: "Karnataka",
  },
  {
    name: "Ballari",
    state_name: "Karnataka",
  },
  {
    name: "Banavar",
    state_name: "Karnataka",
  },
  {
    name: "Bangalore Rural",
    state_name: "Karnataka",
  },
  {
    name: "Bangalore Urban",
    state_name: "Karnataka",
  },
  {
    name: "Bangarapet",
    state_name: "Karnataka",
  },
  {
    name: "Bannur",
    state_name: "Karnataka",
  },
  {
    name: "Bantval",
    state_name: "Karnataka",
  },
  {
    name: "Basavakalyan",
    state_name: "Karnataka",
  },
  {
    name: "Basavana Bagevadi",
    state_name: "Karnataka",
  },
  {
    name: "Belagavi",
    state_name: "Karnataka",
  },
  {
    name: "Belluru",
    state_name: "Karnataka",
  },
  {
    name: "Beltangadi",
    state_name: "Karnataka",
  },
  {
    name: "Belur",
    state_name: "Karnataka",
  },
  {
    name: "Bengaluru",
    state_name: "Karnataka",
  },
  {
    name: "Bhadravati",
    state_name: "Karnataka",
  },
  {
    name: "Bhalki",
    state_name: "Karnataka",
  },
  {
    name: "Bhatkal",
    state_name: "Karnataka",
  },
  {
    name: "Bidar",
    state_name: "Karnataka",
  },
  {
    name: "Bilgi",
    state_name: "Karnataka",
  },
  {
    name: "Birur",
    state_name: "Karnataka",
  },
  {
    name: "Byadgi",
    state_name: "Karnataka",
  },
  {
    name: "Byndoor",
    state_name: "Karnataka",
  },
  {
    name: "Canacona",
    state_name: "Karnataka",
  },
  {
    name: "Challakere",
    state_name: "Karnataka",
  },
  {
    name: "Chamrajnagar",
    state_name: "Karnataka",
  },
  {
    name: "Channagiri",
    state_name: "Karnataka",
  },
  {
    name: "Channapatna",
    state_name: "Karnataka",
  },
  {
    name: "Channarayapatna",
    state_name: "Karnataka",
  },
  {
    name: "Chik Ballapur",
    state_name: "Karnataka",
  },
  {
    name: "Chikkaballapur",
    state_name: "Karnataka",
  },
  {
    name: "Chikkamagaluru",
    state_name: "Karnataka",
  },
  {
    name: "Chiknayakanhalli",
    state_name: "Karnataka",
  },
  {
    name: "Chikodi",
    state_name: "Karnataka",
  },
  {
    name: "Chincholi",
    state_name: "Karnataka",
  },
  {
    name: "Chintamani",
    state_name: "Karnataka",
  },
  {
    name: "Chitapur",
    state_name: "Karnataka",
  },
  {
    name: "Chitradurga",
    state_name: "Karnataka",
  },
  {
    name: "Closepet",
    state_name: "Karnataka",
  },
  {
    name: "Coondapoor",
    state_name: "Karnataka",
  },
  {
    name: "Dakshina Kannada",
    state_name: "Karnataka",
  },
  {
    name: "Dandeli",
    state_name: "Karnataka",
  },
  {
    name: "Davanagere",
    state_name: "Karnataka",
  },
  {
    name: "Devanhalli",
    state_name: "Karnataka",
  },
  {
    name: "Dharwad",
    state_name: "Karnataka",
  },
  {
    name: "Dod Ballapur",
    state_name: "Karnataka",
  },
  {
    name: "French Rocks",
    state_name: "Karnataka",
  },
  {
    name: "Gadag",
    state_name: "Karnataka",
  },
  {
    name: "Gadag-Betageri",
    state_name: "Karnataka",
  },
  {
    name: "Gajendragarh",
    state_name: "Karnataka",
  },
  {
    name: "Gangawati",
    state_name: "Karnataka",
  },
  {
    name: "Gangolli",
    state_name: "Karnataka",
  },
  {
    name: "Gokak",
    state_name: "Karnataka",
  },
  {
    name: "Gokarna",
    state_name: "Karnataka",
  },
  {
    name: "Goribidnur",
    state_name: "Karnataka",
  },
  {
    name: "Gorur",
    state_name: "Karnataka",
  },
  {
    name: "Gubbi",
    state_name: "Karnataka",
  },
  {
    name: "Gudibanda",
    state_name: "Karnataka",
  },
  {
    name: "Guledagudda",
    state_name: "Karnataka",
  },
  {
    name: "Gundlupet",
    state_name: "Karnataka",
  },
  {
    name: "Gurmatkal",
    state_name: "Karnataka",
  },
  {
    name: "Hadagalli",
    state_name: "Karnataka",
  },
  {
    name: "Haliyal",
    state_name: "Karnataka",
  },
  {
    name: "Hampi",
    state_name: "Karnataka",
  },
  {
    name: "Hangal",
    state_name: "Karnataka",
  },
  {
    name: "Harihar",
    state_name: "Karnataka",
  },
  {
    name: "Harpanahalli",
    state_name: "Karnataka",
  },
  {
    name: "Hassan",
    state_name: "Karnataka",
  },
  {
    name: "Haveri",
    state_name: "Karnataka",
  },
  {
    name: "Heggadadevankote",
    state_name: "Karnataka",
  },
  {
    name: "Hirekerur",
    state_name: "Karnataka",
  },
  {
    name: "Hiriyur",
    state_name: "Karnataka",
  },
  {
    name: "Holalkere",
    state_name: "Karnataka",
  },
  {
    name: "Hole Narsipur",
    state_name: "Karnataka",
  },
  {
    name: "Homnabad",
    state_name: "Karnataka",
  },
  {
    name: "Honavar",
    state_name: "Karnataka",
  },
  {
    name: "Honnali",
    state_name: "Karnataka",
  },
  {
    name: "Hosanagara",
    state_name: "Karnataka",
  },
  {
    name: "Hosangadi",
    state_name: "Karnataka",
  },
  {
    name: "Hosdurga",
    state_name: "Karnataka",
  },
  {
    name: "Hoskote",
    state_name: "Karnataka",
  },
  {
    name: "Hospet",
    state_name: "Karnataka",
  },
  {
    name: "Hubballi",
    state_name: "Karnataka",
  },
  {
    name: "Hukeri",
    state_name: "Karnataka",
  },
  {
    name: "Hungund",
    state_name: "Karnataka",
  },
  {
    name: "Hunsur",
    state_name: "Karnataka",
  },
  {
    name: "Ilkal",
    state_name: "Karnataka",
  },
  {
    name: "Indi",
    state_name: "Karnataka",
  },
  {
    name: "Jagalur",
    state_name: "Karnataka",
  },
  {
    name: "Jamkhandi",
    state_name: "Karnataka",
  },
  {
    name: "Jevargi",
    state_name: "Karnataka",
  },
  {
    name: "Kadur",
    state_name: "Karnataka",
  },
  {
    name: "Kalaburgi",
    state_name: "Karnataka",
  },
  {
    name: "Kalghatgi",
    state_name: "Karnataka",
  },
  {
    name: "Kampli",
    state_name: "Karnataka",
  },
  {
    name: "Kankanhalli",
    state_name: "Karnataka",
  },
  {
    name: "Karkala",
    state_name: "Karnataka",
  },
  {
    name: "Karwar",
    state_name: "Karnataka",
  },
  {
    name: "Kavalur",
    state_name: "Karnataka",
  },
  {
    name: "Kerur",
    state_name: "Karnataka",
  },
  {
    name: "Khanapur",
    state_name: "Karnataka",
  },
  {
    name: "Kodagu",
    state_name: "Karnataka",
  },
  {
    name: "Kodigenahalli",
    state_name: "Karnataka",
  },
  {
    name: "Kodlipet",
    state_name: "Karnataka",
  },
  {
    name: "Kolar",
    state_name: "Karnataka",
  },
  {
    name: "Kollegal",
    state_name: "Karnataka",
  },
  {
    name: "Konanur",
    state_name: "Karnataka",
  },
  {
    name: "Konnur",
    state_name: "Karnataka",
  },
  {
    name: "Koppa",
    state_name: "Karnataka",
  },
  {
    name: "Koppal",
    state_name: "Karnataka",
  },
  {
    name: "Koratagere",
    state_name: "Karnataka",
  },
  {
    name: "Kotturu",
    state_name: "Karnataka",
  },
  {
    name: "Krishnarajpet",
    state_name: "Karnataka",
  },
  {
    name: "Kudachi",
    state_name: "Karnataka",
  },
  {
    name: "Kudligi",
    state_name: "Karnataka",
  },
  {
    name: "Kumsi",
    state_name: "Karnataka",
  },
  {
    name: "Kumta",
    state_name: "Karnataka",
  },
  {
    name: "Kundgol",
    state_name: "Karnataka",
  },
  {
    name: "Kunigal",
    state_name: "Karnataka",
  },
  {
    name: "Kurgunta",
    state_name: "Karnataka",
  },
  {
    name: "Kushalnagar",
    state_name: "Karnataka",
  },
  {
    name: "Kushtagi",
    state_name: "Karnataka",
  },
  {
    name: "Lakshmeshwar",
    state_name: "Karnataka",
  },
  {
    name: "Lingsugur",
    state_name: "Karnataka",
  },
  {
    name: "Londa",
    state_name: "Karnataka",
  },
  {
    name: "Maddagiri",
    state_name: "Karnataka",
  },
  {
    name: "Maddur",
    state_name: "Karnataka",
  },
  {
    name: "Madikeri",
    state_name: "Karnataka",
  },
  {
    name: "Magadi",
    state_name: "Karnataka",
  },
  {
    name: "Mahalingpur",
    state_name: "Karnataka",
  },
  {
    name: "Malavalli",
    state_name: "Karnataka",
  },
  {
    name: "Malpe",
    state_name: "Karnataka",
  },
  {
    name: "Malur",
    state_name: "Karnataka",
  },
  {
    name: "Mandya",
    state_name: "Karnataka",
  },
  {
    name: "Mangaluru",
    state_name: "Karnataka",
  },
  {
    name: "Manipal",
    state_name: "Karnataka",
  },
  {
    name: "Manvi",
    state_name: "Karnataka",
  },
  {
    name: "Mayakonda",
    state_name: "Karnataka",
  },
  {
    name: "Melukote",
    state_name: "Karnataka",
  },
  {
    name: "Mudbidri",
    state_name: "Karnataka",
  },
  {
    name: "Muddebihal",
    state_name: "Karnataka",
  },
  {
    name: "Mudgal",
    state_name: "Karnataka",
  },
  {
    name: "Mudgere",
    state_name: "Karnataka",
  },
  {
    name: "Mudhol",
    state_name: "Karnataka",
  },
  {
    name: "Mulbagal",
    state_name: "Karnataka",
  },
  {
    name: "Mulgund",
    state_name: "Karnataka",
  },
  {
    name: "Mulki",
    state_name: "Karnataka",
  },
  {
    name: "Mundargi",
    state_name: "Karnataka",
  },
  {
    name: "Mundgod",
    state_name: "Karnataka",
  },
  {
    name: "Munirabad",
    state_name: "Karnataka",
  },
  {
    name: "Murudeshwara",
    state_name: "Karnataka",
  },
  {
    name: "Mysuru",
    state_name: "Karnataka",
  },
  {
    name: "Nagamangala",
    state_name: "Karnataka",
  },
  {
    name: "Nanjangud",
    state_name: "Karnataka",
  },
  {
    name: "Narasimharajapura",
    state_name: "Karnataka",
  },
  {
    name: "Naregal",
    state_name: "Karnataka",
  },
  {
    name: "Nargund",
    state_name: "Karnataka",
  },
  {
    name: "Navalgund",
    state_name: "Karnataka",
  },
  {
    name: "Nelamangala",
    state_name: "Karnataka",
  },
  {
    name: "Nyamti",
    state_name: "Karnataka",
  },
  {
    name: "Pangala",
    state_name: "Karnataka",
  },
  {
    name: "Pavugada",
    state_name: "Karnataka",
  },
  {
    name: "Piriyapatna",
    state_name: "Karnataka",
  },
  {
    name: "Ponnampet",
    state_name: "Karnataka",
  },
  {
    name: "Puttur",
    state_name: "Karnataka",
  },
  {
    name: "Rabkavi",
    state_name: "Karnataka",
  },
  {
    name: "Raichur",
    state_name: "Karnataka",
  },
  {
    name: "Ramanagara",
    state_name: "Karnataka",
  },
  {
    name: "Ranibennur",
    state_name: "Karnataka",
  },
  {
    name: "Raybag",
    state_name: "Karnataka",
  },
  {
    name: "Robertsonpet",
    state_name: "Karnataka",
  },
  {
    name: "Ron",
    state_name: "Karnataka",
  },
  {
    name: "Sadalgi",
    state_name: "Karnataka",
  },
  {
    name: "Sagar",
    state_name: "Karnataka",
  },
  {
    name: "Sakleshpur",
    state_name: "Karnataka",
  },
  {
    name: "Sandur",
    state_name: "Karnataka",
  },
  {
    name: "Sanivarsante",
    state_name: "Karnataka",
  },
  {
    name: "Sankeshwar",
    state_name: "Karnataka",
  },
  {
    name: "Sargur",
    state_name: "Karnataka",
  },
  {
    name: "Saundatti",
    state_name: "Karnataka",
  },
  {
    name: "Savanur",
    state_name: "Karnataka",
  },
  {
    name: "Seram",
    state_name: "Karnataka",
  },
  {
    name: "Shahabad",
    state_name: "Karnataka",
  },
  {
    name: "Shahpur",
    state_name: "Karnataka",
  },
  {
    name: "Shiggaon",
    state_name: "Karnataka",
  },
  {
    name: "Shikarpur",
    state_name: "Karnataka",
  },
  {
    name: "Shimoga",
    state_name: "Karnataka",
  },
  {
    name: "Shirhatti",
    state_name: "Karnataka",
  },
  {
    name: "Shorapur",
    state_name: "Karnataka",
  },
  {
    name: "Shrirangapattana",
    state_name: "Karnataka",
  },
  {
    name: "Siddapur",
    state_name: "Karnataka",
  },
  {
    name: "Sidlaghatta",
    state_name: "Karnataka",
  },
  {
    name: "Sindgi",
    state_name: "Karnataka",
  },
  {
    name: "Sindhnur",
    state_name: "Karnataka",
  },
  {
    name: "Sira",
    state_name: "Karnataka",
  },
  {
    name: "Sirsi",
    state_name: "Karnataka",
  },
  {
    name: "Siruguppa",
    state_name: "Karnataka",
  },
  {
    name: "Someshwar",
    state_name: "Karnataka",
  },
  {
    name: "Somvarpet",
    state_name: "Karnataka",
  },
  {
    name: "Sorab",
    state_name: "Karnataka",
  },
  {
    name: "Sravana Belgola",
    state_name: "Karnataka",
  },
  {
    name: "Sringeri",
    state_name: "Karnataka",
  },
  {
    name: "Srinivaspur",
    state_name: "Karnataka",
  },
  {
    name: "Sulya",
    state_name: "Karnataka",
  },
  {
    name: "Suntikoppa",
    state_name: "Karnataka",
  },
  {
    name: "Talikota",
    state_name: "Karnataka",
  },
  {
    name: "Tarikere",
    state_name: "Karnataka",
  },
  {
    name: "Tekkalakote",
    state_name: "Karnataka",
  },
  {
    name: "Terdal",
    state_name: "Karnataka",
  },
  {
    name: "Tiptur",
    state_name: "Karnataka",
  },
  {
    name: "Tirthahalli",
    state_name: "Karnataka",
  },
  {
    name: "Tirumakudal Narsipur",
    state_name: "Karnataka",
  },
  {
    name: "Tumakuru",
    state_name: "Karnataka",
  },
  {
    name: "Turuvekere",
    state_name: "Karnataka",
  },
  {
    name: "Udupi",
    state_name: "Karnataka",
  },
  {
    name: "Ullal",
    state_name: "Karnataka",
  },
  {
    name: "Uttar Kannada",
    state_name: "Karnataka",
  },
  {
    name: "Vadigenhalli",
    state_name: "Karnataka",
  },
  {
    name: "Vijayapura",
    state_name: "Karnataka",
  },
  {
    name: "Virarajendrapet",
    state_name: "Karnataka",
  },
  {
    name: "Wadi",
    state_name: "Karnataka",
  },
  {
    name: "Yadgir",
    state_name: "Karnataka",
  },
  {
    name: "Yelahanka",
    state_name: "Karnataka",
  },
  {
    name: "Yelandur",
    state_name: "Karnataka",
  },
  {
    name: "Yelbarga",
    state_name: "Karnataka",
  },
  {
    name: "Yellapur",
    state_name: "Karnataka",
  },
  {
    name: "Adur",
    state_name: "Kerala",
  },
  {
    name: "Alappuzha",
    state_name: "Kerala",
  },
  {
    name: "Aluva",
    state_name: "Kerala",
  },
  {
    name: "Alwaye",
    state_name: "Kerala",
  },
  {
    name: "Angamali",
    state_name: "Kerala",
  },
  {
    name: "Aroor",
    state_name: "Kerala",
  },
  {
    name: "Arukutti",
    state_name: "Kerala",
  },
  {
    name: "Attingal",
    state_name: "Kerala",
  },
  {
    name: "Avanoor",
    state_name: "Kerala",
  },
  {
    name: "Azhikkal",
    state_name: "Kerala",
  },
  {
    name: "Badagara",
    state_name: "Kerala",
  },
  {
    name: "Beypore",
    state_name: "Kerala",
  },
  {
    name: "Changanacheri",
    state_name: "Kerala",
  },
  {
    name: "Chelakara",
    state_name: "Kerala",
  },
  {
    name: "Chengannur",
    state_name: "Kerala",
  },
  {
    name: "Cherpulassery",
    state_name: "Kerala",
  },
  {
    name: "Cherthala",
    state_name: "Kerala",
  },
  {
    name: "Chetwayi",
    state_name: "Kerala",
  },
  {
    name: "Chittur",
    state_name: "Kerala",
  },
  {
    name: "Cochin",
    state_name: "Kerala",
  },
  {
    name: "Dharmadam",
    state_name: "Kerala",
  },
  {
    name: "Edakkulam",
    state_name: "Kerala",
  },
  {
    name: "Elur",
    state_name: "Kerala",
  },
  {
    name: "Erattupetta",
    state_name: "Kerala",
  },
  {
    name: "Ernakulam",
    state_name: "Kerala",
  },
  {
    name: "Ferokh",
    state_name: "Kerala",
  },
  {
    name: "Guruvayur",
    state_name: "Kerala",
  },
  {
    name: "Idukki",
    state_name: "Kerala",
  },
  {
    name: "Iringal",
    state_name: "Kerala",
  },
  {
    name: "Irinjalakuda",
    state_name: "Kerala",
  },
  {
    name: "Kadakkavoor",
    state_name: "Kerala",
  },
  {
    name: "Kalamassery",
    state_name: "Kerala",
  },
  {
    name: "Kalavoor",
    state_name: "Kerala",
  },
  {
    name: "Kalpatta",
    state_name: "Kerala",
  },
  {
    name: "Kannangad",
    state_name: "Kerala",
  },
  {
    name: "Kannavam",
    state_name: "Kerala",
  },
  {
    name: "Kannur",
    state_name: "Kerala",
  },
  {
    name: "Kasaragod",
    state_name: "Kerala",
  },
  {
    name: "Kasaragod District",
    state_name: "Kerala",
  },
  {
    name: "Kattanam",
    state_name: "Kerala",
  },
  {
    name: "Kayankulam",
    state_name: "Kerala",
  },
  {
    name: "Kizhake Chalakudi",
    state_name: "Kerala",
  },
  {
    name: "Kodungallur",
    state_name: "Kerala",
  },
  {
    name: "Kollam",
    state_name: "Kerala",
  },
  {
    name: "Kotamangalam",
    state_name: "Kerala",
  },
  {
    name: "Kottayam",
    state_name: "Kerala",
  },
  {
    name: "Kovalam",
    state_name: "Kerala",
  },
  {
    name: "Kozhikode",
    state_name: "Kerala",
  },
  {
    name: "Kumbalam",
    state_name: "Kerala",
  },
  {
    name: "Kunnamangalam",
    state_name: "Kerala",
  },
  {
    name: "Kunnamkulam",
    state_name: "Kerala",
  },
  {
    name: "Kunnumma",
    state_name: "Kerala",
  },
  {
    name: "Kutiatodu",
    state_name: "Kerala",
  },
  {
    name: "Kuttampuzha",
    state_name: "Kerala",
  },
  {
    name: "Lalam",
    state_name: "Kerala",
  },
  {
    name: "Mahe",
    state_name: "Kerala",
  },
  {
    name: "Malappuram",
    state_name: "Kerala",
  },
  {
    name: "Manjeri",
    state_name: "Kerala",
  },
  {
    name: "Manjeshvar",
    state_name: "Kerala",
  },
  {
    name: "Mannarakkat",
    state_name: "Kerala",
  },
  {
    name: "Marayur",
    state_name: "Kerala",
  },
  {
    name: "Mattanur",
    state_name: "Kerala",
  },
  {
    name: "Mavelikara",
    state_name: "Kerala",
  },
  {
    name: "Mavoor",
    state_name: "Kerala",
  },
  {
    name: "Muluppilagadu",
    state_name: "Kerala",
  },
  {
    name: "Munnar",
    state_name: "Kerala",
  },
  {
    name: "Muvattupula",
    state_name: "Kerala",
  },
  {
    name: "Muvattupuzha",
    state_name: "Kerala",
  },
  {
    name: "Nadapuram",
    state_name: "Kerala",
  },
  {
    name: "Naduvannur",
    state_name: "Kerala",
  },
  {
    name: "Nedumangad",
    state_name: "Kerala",
  },
  {
    name: "Neyyattinkara",
    state_name: "Kerala",
  },
  {
    name: "Nileshwar",
    state_name: "Kerala",
  },
  {
    name: "Ottappalam",
    state_name: "Kerala",
  },
  {
    name: "Palackattumala",
    state_name: "Kerala",
  },
  {
    name: "Palakkad district",
    state_name: "Kerala",
  },
  {
    name: "Palghat",
    state_name: "Kerala",
  },
  {
    name: "Panamaram",
    state_name: "Kerala",
  },
  {
    name: "Pappinissheri",
    state_name: "Kerala",
  },
  {
    name: "Paravur Tekkumbhagam",
    state_name: "Kerala",
  },
  {
    name: "Pariyapuram",
    state_name: "Kerala",
  },
  {
    name: "Pathanamthitta",
    state_name: "Kerala",
  },
  {
    name: "Pattanamtitta",
    state_name: "Kerala",
  },
  {
    name: "Payyannur",
    state_name: "Kerala",
  },
  {
    name: "Perinthalmanna",
    state_name: "Kerala",
  },
  {
    name: "Perumbavoor",
    state_name: "Kerala",
  },
  {
    name: "Perumpavur",
    state_name: "Kerala",
  },
  {
    name: "Perya",
    state_name: "Kerala",
  },
  {
    name: "Piravam",
    state_name: "Kerala",
  },
  {
    name: "Ponmana",
    state_name: "Kerala",
  },
  {
    name: "Ponnani",
    state_name: "Kerala",
  },
  {
    name: "Punalur",
    state_name: "Kerala",
  },
  {
    name: "Ramamangalam",
    state_name: "Kerala",
  },
  {
    name: "Sharanur",
    state_name: "Kerala",
  },
  {
    name: "Shertallai",
    state_name: "Kerala",
  },
  {
    name: "Talipparamba",
    state_name: "Kerala",
  },
  {
    name: "Tellicherry",
    state_name: "Kerala",
  },
  {
    name: "Thanniyam",
    state_name: "Kerala",
  },
  {
    name: "Thiruvananthapuram",
    state_name: "Kerala",
  },
  {
    name: "Thrissur",
    state_name: "Kerala",
  },
  {
    name: "Tirur",
    state_name: "Kerala",
  },
  {
    name: "Tiruvalla",
    state_name: "Kerala",
  },
  {
    name: "Vaikam",
    state_name: "Kerala",
  },
  {
    name: "Varkala",
    state_name: "Kerala",
  },
  {
    name: "Vayalar",
    state_name: "Kerala",
  },
  {
    name: "Vettur",
    state_name: "Kerala",
  },
  {
    name: "Wayanad",
    state_name: "Kerala",
  },
  {
    name: "Kargil",
    state_name: "Ladakh",
  },
  {
    name: "Leh",
    state_name: "Ladakh",
  },
  {
    name: "Kavaratti",
    state_name: "Lakshadweep",
  },
  {
    name: "Lakshadweep",
    state_name: "Lakshadweep",
  },
  {
    name: "Agar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ajaigarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Akodia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Alampur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Alirajpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Alot",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Amanganj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Amarkantak",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Amarpatan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Amarwara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ambah",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Amla",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Anjad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Antri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Anuppur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Aron",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ashoknagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ashta",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Babai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Badarwas",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Badnawar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bagh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bagli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Baihar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Baikunthpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bakshwaha",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Balaghat",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Baldeogarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bamna",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bamor Kalan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bamora",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Banda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Barela",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Barghat",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bargi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Barhi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Barwani",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Basoda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Begamganj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Beohari",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Berasia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Betma",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Betul",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Betul Bazar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhabhra",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhainsdehi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhander",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhanpura",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhawaniganj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhikangaon",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhind",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhitarwar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bhopal",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Biaora",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bijawar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bijrauni",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Bodri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Burhanpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Burhar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chanderi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chandia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chandla",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chhatarpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chhindwara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chichli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Chorhat",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Daboh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dabra",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Damoh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Datia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Deori Khas",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Depalpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dewas",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dhamnod",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dhana",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dhar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dharampuri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Dindori",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Etawa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gadarwara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Garha Brahman",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Garhakota",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gautampura",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ghansor",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gogapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gohadi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Govindgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Guna",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gurh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Gwalior",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Harda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Harda Khas",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Harpalpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Harrai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Harsud",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Hatod",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Hatta",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Hindoria",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Hoshangabad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Iawar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ichhawar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Iklehra",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Indore",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Isagarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Itarsi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jabalpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jaisinghnagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jaithari",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jamai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jaora",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jatara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jawad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jhabua",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jiran",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Jobat",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kailaras",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kaimori",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kannod",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kareli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Karera",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Karrapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kasrawad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Katangi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Katni",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khachrod",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khailar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khajuraho Group of Monuments",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khamaria",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khandwa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khargapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khargone",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khategaon",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khilchipur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khirkiyan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khujner",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Khurai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kolaras",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Korwai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kotar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kothi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kotma",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kotwa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kukshi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Kumbhraj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Lahar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Lakhnadon",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Leteri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Lodhikheda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Machalpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Madhogarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Maheshwar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mahgawan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Maihar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Majholi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Maksi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Malhargarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Manasa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Manawar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mandideep",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mandla",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mandleshwar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mandsaur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mangawan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Manpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mau",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mauganj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mihona",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mohgaon",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Morar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Morena",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Multai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mundi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Mungaoli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Murwara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Nagda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Nagod",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Naigarhi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Nainpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Namli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Naraini",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Narayangarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Narsimhapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Narsinghgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Narwar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Nasrullahganj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Neemuch",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Nepanagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Orchha",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pachmarhi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Palera",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pali",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Panagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Panara",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pandhana",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pandhurna",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Panna",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pansemal",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Parasia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Patan",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Patharia",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pawai",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Petlawad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Piploda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Pithampur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Porsa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Punasa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Raghogarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rahatgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Raisen",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rajgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rajnagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rajpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rampura",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ranapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ratangarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ratlam",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rehli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rehti",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Rewa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sabalgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sagar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sailana",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sanawad",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sanchi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sanwer",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sarangpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Satna",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Satwas",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Saugor",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sausar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sehore",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sendhwa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Seondha",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Seoni",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Seoni Malwa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shahdol",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shahgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shahpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shahpura",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shajapur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shamgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sheopur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shivpuri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Shujalpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sidhi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sihora",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Simaria",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Singoli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Singrauli",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sirmaur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sironj",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sitamau",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sohagi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sohagpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Sultanpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Susner",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tal",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Talen",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tarana",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tekanpur",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tendukheda",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Teonthar",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Thandla",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tikamgarh",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Tirodi",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Udaipura",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ujjain",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Ukwa",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Umaria",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Umri",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Unhel",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Vidisha",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Waraseoni",
    state_name: "Madhya Pradesh",
  },
  {
    name: "Achalpur",
    state_name: "Maharashtra",
  },
  {
    name: "Adawad",
    state_name: "Maharashtra",
  },
  {
    name: "Agar Panchaitan",
    state_name: "Maharashtra",
  },
  {
    name: "Aheri",
    state_name: "Maharashtra",
  },
  {
    name: "Ahmadpur",
    state_name: "Maharashtra",
  },
  {
    name: "Ahmednagar",
    state_name: "Maharashtra",
  },
  {
    name: "Airoli",
    state_name: "Maharashtra",
  },
  {
    name: "Ajara",
    state_name: "Maharashtra",
  },
  {
    name: "Akalkot",
    state_name: "Maharashtra",
  },
  {
    name: "Akluj",
    state_name: "Maharashtra",
  },
  {
    name: "Akola",
    state_name: "Maharashtra",
  },
  {
    name: "Akolner",
    state_name: "Maharashtra",
  },
  {
    name: "Akot",
    state_name: "Maharashtra",
  },
  {
    name: "Akrani",
    state_name: "Maharashtra",
  },
  {
    name: "Alandi",
    state_name: "Maharashtra",
  },
  {
    name: "Ale",
    state_name: "Maharashtra",
  },
  {
    name: "Alibag",
    state_name: "Maharashtra",
  },
  {
    name: "Alkuti",
    state_name: "Maharashtra",
  },
  {
    name: "Allapalli",
    state_name: "Maharashtra",
  },
  {
    name: "Amalner",
    state_name: "Maharashtra",
  },
  {
    name: "Amarnath",
    state_name: "Maharashtra",
  },
  {
    name: "Ambad",
    state_name: "Maharashtra",
  },
  {
    name: "Ambajogai",
    state_name: "Maharashtra",
  },
  {
    name: "Ambegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Ambernath",
    state_name: "Maharashtra",
  },
  {
    name: "Amgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Amravati",
    state_name: "Maharashtra",
  },
  {
    name: "Andheri",
    state_name: "Maharashtra",
  },
  {
    name: "Andura",
    state_name: "Maharashtra",
  },
  {
    name: "Anjangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Anjarle",
    state_name: "Maharashtra",
  },
  {
    name: "Anshing",
    state_name: "Maharashtra",
  },
  {
    name: "Arag",
    state_name: "Maharashtra",
  },
  {
    name: "Arangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Ardhapur",
    state_name: "Maharashtra",
  },
  {
    name: "Argaon",
    state_name: "Maharashtra",
  },
  {
    name: "Artist Village",
    state_name: "Maharashtra",
  },
  {
    name: "Arvi",
    state_name: "Maharashtra",
  },
  {
    name: "Ashta",
    state_name: "Maharashtra",
  },
  {
    name: "Ashti",
    state_name: "Maharashtra",
  },
  {
    name: "Asoda",
    state_name: "Maharashtra",
  },
  {
    name: "Assaye",
    state_name: "Maharashtra",
  },
  {
    name: "Astagaon",
    state_name: "Maharashtra",
  },
  {
    name: "Aundh Satara",
    state_name: "Maharashtra",
  },
  {
    name: "Aurangabad",
    state_name: "Maharashtra",
  },
  {
    name: "Ausa",
    state_name: "Maharashtra",
  },
  {
    name: "Badlapur",
    state_name: "Maharashtra",
  },
  {
    name: "Badnapur",
    state_name: "Maharashtra",
  },
  {
    name: "Badnera",
    state_name: "Maharashtra",
  },
  {
    name: "Bagewadi",
    state_name: "Maharashtra",
  },
  {
    name: "Balapur",
    state_name: "Maharashtra",
  },
  {
    name: "Balapur Akola district",
    state_name: "Maharashtra",
  },
  {
    name: "Ballalpur",
    state_name: "Maharashtra",
  },
  {
    name: "Ballard Estate",
    state_name: "Maharashtra",
  },
  {
    name: "Ballarpur",
    state_name: "Maharashtra",
  },
  {
    name: "Banda Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Bandra",
    state_name: "Maharashtra",
  },
  {
    name: "Baner",
    state_name: "Maharashtra",
  },
  {
    name: "Bankot",
    state_name: "Maharashtra",
  },
  {
    name: "Baramati",
    state_name: "Maharashtra",
  },
  {
    name: "Barsi",
    state_name: "Maharashtra",
  },
  {
    name: "Basmat",
    state_name: "Maharashtra",
  },
  {
    name: "Bavdhan",
    state_name: "Maharashtra",
  },
  {
    name: "Bawanbir",
    state_name: "Maharashtra",
  },
  {
    name: "Beed",
    state_name: "Maharashtra",
  },
  {
    name: "Bhadgaon Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Bhandara",
    state_name: "Maharashtra",
  },
  {
    name: "Bhandardara",
    state_name: "Maharashtra",
  },
  {
    name: "Bhandup",
    state_name: "Maharashtra",
  },
  {
    name: "Bhayandar",
    state_name: "Maharashtra",
  },
  {
    name: "Bhigvan",
    state_name: "Maharashtra",
  },
  {
    name: "Bhiwandi",
    state_name: "Maharashtra",
  },
  {
    name: "Bhiwapur",
    state_name: "Maharashtra",
  },
  {
    name: "Bhokar",
    state_name: "Maharashtra",
  },
  {
    name: "Bhokardan",
    state_name: "Maharashtra",
  },
  {
    name: "Bhoom",
    state_name: "Maharashtra",
  },
  {
    name: "Bhor",
    state_name: "Maharashtra",
  },
  {
    name: "Bhudgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Bhugaon",
    state_name: "Maharashtra",
  },
  {
    name: "Bhusaval",
    state_name: "Maharashtra",
  },
  {
    name: "Bijur",
    state_name: "Maharashtra",
  },
  {
    name: "Bilashi",
    state_name: "Maharashtra",
  },
  {
    name: "Biloli",
    state_name: "Maharashtra",
  },
  {
    name: "Boisar",
    state_name: "Maharashtra",
  },
  {
    name: "Borgaon Manju",
    state_name: "Maharashtra",
  },
  {
    name: "Borivali",
    state_name: "Maharashtra",
  },
  {
    name: "Brahmapuri",
    state_name: "Maharashtra",
  },
  {
    name: "Breach Candy",
    state_name: "Maharashtra",
  },
  {
    name: "Buldana",
    state_name: "Maharashtra",
  },
  {
    name: "Byculla",
    state_name: "Maharashtra",
  },
  {
    name: "Chakan",
    state_name: "Maharashtra",
  },
  {
    name: "Chakur",
    state_name: "Maharashtra",
  },
  {
    name: "Chalisgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Chanda",
    state_name: "Maharashtra",
  },
  {
    name: "Chandgad",
    state_name: "Maharashtra",
  },
  {
    name: "Chandor",
    state_name: "Maharashtra",
  },
  {
    name: "Chandrapur",
    state_name: "Maharashtra",
  },
  {
    name: "Chandur",
    state_name: "Maharashtra",
  },
  {
    name: "Chandur Bazar",
    state_name: "Maharashtra",
  },
  {
    name: "Chausala",
    state_name: "Maharashtra",
  },
  {
    name: "Chembur",
    state_name: "Maharashtra",
  },
  {
    name: "Chicholi",
    state_name: "Maharashtra",
  },
  {
    name: "Chichondi Patil",
    state_name: "Maharashtra",
  },
  {
    name: "Chikhli (Buldhana)",
    state_name: "Maharashtra",
  },
  {
    name: "Chikhli (Jalna)",
    state_name: "Maharashtra",
  },
  {
    name: "Chimur",
    state_name: "Maharashtra",
  },
  {
    name: "Chinchani",
    state_name: "Maharashtra",
  },
  {
    name: "Chinchpokli",
    state_name: "Maharashtra",
  },
  {
    name: "Chiplun",
    state_name: "Maharashtra",
  },
  {
    name: "Chopda",
    state_name: "Maharashtra",
  },
  {
    name: "Colaba",
    state_name: "Maharashtra",
  },
  {
    name: "Dabhol",
    state_name: "Maharashtra",
  },
  {
    name: "Daddi",
    state_name: "Maharashtra",
  },
  {
    name: "Dahanu",
    state_name: "Maharashtra",
  },
  {
    name: "Dahivel",
    state_name: "Maharashtra",
  },
  {
    name: "Dapoli",
    state_name: "Maharashtra",
  },
  {
    name: "Darwha",
    state_name: "Maharashtra",
  },
  {
    name: "Daryapur",
    state_name: "Maharashtra",
  },
  {
    name: "Dattapur",
    state_name: "Maharashtra",
  },
  {
    name: "Daulatabad",
    state_name: "Maharashtra",
  },
  {
    name: "Daund",
    state_name: "Maharashtra",
  },
  {
    name: "Deccan Gymkhana",
    state_name: "Maharashtra",
  },
  {
    name: "Deglur",
    state_name: "Maharashtra",
  },
  {
    name: "Dehu",
    state_name: "Maharashtra",
  },
  {
    name: "Deolali",
    state_name: "Maharashtra",
  },
  {
    name: "Deolapar",
    state_name: "Maharashtra",
  },
  {
    name: "Deoli",
    state_name: "Maharashtra",
  },
  {
    name: "Deoni",
    state_name: "Maharashtra",
  },
  {
    name: "Deulgaon Raja",
    state_name: "Maharashtra",
  },
  {
    name: "Devrukh",
    state_name: "Maharashtra",
  },
  {
    name: "Dharangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Dharavi",
    state_name: "Maharashtra",
  },
  {
    name: "Dharmabad",
    state_name: "Maharashtra",
  },
  {
    name: "Dharur",
    state_name: "Maharashtra",
  },
  {
    name: "Dhawalpuri",
    state_name: "Maharashtra",
  },
  {
    name: "Dhule",
    state_name: "Maharashtra",
  },
  {
    name: "Dhulia",
    state_name: "Maharashtra",
  },
  {
    name: "Dighori",
    state_name: "Maharashtra",
  },
  {
    name: "Diglur",
    state_name: "Maharashtra",
  },
  {
    name: "Digras",
    state_name: "Maharashtra",
  },
  {
    name: "Dindori Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Diveagar",
    state_name: "Maharashtra",
  },
  {
    name: "Dombivli",
    state_name: "Maharashtra",
  },
  {
    name: "Dondaicha",
    state_name: "Maharashtra",
  },
  {
    name: "Dongri",
    state_name: "Maharashtra",
  },
  {
    name: "Dudhani",
    state_name: "Maharashtra",
  },
  {
    name: "Durgapur",
    state_name: "Maharashtra",
  },
  {
    name: "Durgapur Chandrapur",
    state_name: "Maharashtra",
  },
  {
    name: "Erandol",
    state_name: "Maharashtra",
  },
  {
    name: "Faizpur",
    state_name: "Maharashtra",
  },
  {
    name: "Fort",
    state_name: "Maharashtra",
  },
  {
    name: "Gadchiroli",
    state_name: "Maharashtra",
  },
  {
    name: "Gadhinglaj",
    state_name: "Maharashtra",
  },
  {
    name: "Gangakher",
    state_name: "Maharashtra",
  },
  {
    name: "Gangapur",
    state_name: "Maharashtra",
  },
  {
    name: "Ganpatipule",
    state_name: "Maharashtra",
  },
  {
    name: "Gevrai",
    state_name: "Maharashtra",
  },
  {
    name: "Ghargaon",
    state_name: "Maharashtra",
  },
  {
    name: "Ghatanji",
    state_name: "Maharashtra",
  },
  {
    name: "Ghatkopar",
    state_name: "Maharashtra",
  },
  {
    name: "Ghoti Budrukh",
    state_name: "Maharashtra",
  },
  {
    name: "Ghugus",
    state_name: "Maharashtra",
  },
  {
    name: "Girgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Gondia",
    state_name: "Maharashtra",
  },
  {
    name: "Gorai",
    state_name: "Maharashtra",
  },
  {
    name: "Goregaon",
    state_name: "Maharashtra",
  },
  {
    name: "Guhagar",
    state_name: "Maharashtra",
  },
  {
    name: "Hadapsar Pune",
    state_name: "Maharashtra",
  },
  {
    name: "Hadgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Halkarni",
    state_name: "Maharashtra",
  },
  {
    name: "Harangul",
    state_name: "Maharashtra",
  },
  {
    name: "Harnai",
    state_name: "Maharashtra",
  },
  {
    name: "Helwak",
    state_name: "Maharashtra",
  },
  {
    name: "Hinganghat",
    state_name: "Maharashtra",
  },
  {
    name: "Hingoli",
    state_name: "Maharashtra",
  },
  {
    name: "Hirapur",
    state_name: "Maharashtra",
  },
  {
    name: "Hirapur Hamesha",
    state_name: "Maharashtra",
  },
  {
    name: "Hotgi",
    state_name: "Maharashtra",
  },
  {
    name: "Ichalkaranji",
    state_name: "Maharashtra",
  },
  {
    name: "Igatpuri",
    state_name: "Maharashtra",
  },
  {
    name: "Indapur",
    state_name: "Maharashtra",
  },
  {
    name: "Jaisingpur",
    state_name: "Maharashtra",
  },
  {
    name: "Jaitapur",
    state_name: "Maharashtra",
  },
  {
    name: "Jakhangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Jalgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Jalgaon Jamod",
    state_name: "Maharashtra",
  },
  {
    name: "Jalkot",
    state_name: "Maharashtra",
  },
  {
    name: "Jalna",
    state_name: "Maharashtra",
  },
  {
    name: "Jamkhed",
    state_name: "Maharashtra",
  },
  {
    name: "Jamod",
    state_name: "Maharashtra",
  },
  {
    name: "Janephal",
    state_name: "Maharashtra",
  },
  {
    name: "Jaoli",
    state_name: "Maharashtra",
  },
  {
    name: "Jat Sangli",
    state_name: "Maharashtra",
  },
  {
    name: "Jategaon",
    state_name: "Maharashtra",
  },
  {
    name: "Jawhar",
    state_name: "Maharashtra",
  },
  {
    name: "Jaysingpur",
    state_name: "Maharashtra",
  },
  {
    name: "Jejuri",
    state_name: "Maharashtra",
  },
  {
    name: "Jintur",
    state_name: "Maharashtra",
  },
  {
    name: "Jogeshwari",
    state_name: "Maharashtra",
  },
  {
    name: "Juhu",
    state_name: "Maharashtra",
  },
  {
    name: "Junnar",
    state_name: "Maharashtra",
  },
  {
    name: "Kachurwahi",
    state_name: "Maharashtra",
  },
  {
    name: "Kadegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Kadus",
    state_name: "Maharashtra",
  },
  {
    name: "Kagal",
    state_name: "Maharashtra",
  },
  {
    name: "Kaij",
    state_name: "Maharashtra",
  },
  {
    name: "Kalamb",
    state_name: "Maharashtra",
  },
  {
    name: "Kalamb Osmanabad",
    state_name: "Maharashtra",
  },
  {
    name: "Kalamboli",
    state_name: "Maharashtra",
  },
  {
    name: "Kalamnuri",
    state_name: "Maharashtra",
  },
  {
    name: "Kalas",
    state_name: "Maharashtra",
  },
  {
    name: "Kali(DK)",
    state_name: "Maharashtra",
  },
  {
    name: "Kalmeshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Kalundri",
    state_name: "Maharashtra",
  },
  {
    name: "Kalyan",
    state_name: "Maharashtra",
  },
  {
    name: "Kalyani Nagar",
    state_name: "Maharashtra",
  },
  {
    name: "Kamargaon",
    state_name: "Maharashtra",
  },
  {
    name: "Kamatgi",
    state_name: "Maharashtra",
  },
  {
    name: "Kamptee",
    state_name: "Maharashtra",
  },
  {
    name: "Kandri",
    state_name: "Maharashtra",
  },
  {
    name: "Kankauli",
    state_name: "Maharashtra",
  },
  {
    name: "Kankavli",
    state_name: "Maharashtra",
  },
  {
    name: "Kannad",
    state_name: "Maharashtra",
  },
  {
    name: "Karad",
    state_name: "Maharashtra",
  },
  {
    name: "Karajagi",
    state_name: "Maharashtra",
  },
  {
    name: "Karanja",
    state_name: "Maharashtra",
  },
  {
    name: "Karanja Lad",
    state_name: "Maharashtra",
  },
  {
    name: "Karjat",
    state_name: "Maharashtra",
  },
  {
    name: "Karkamb",
    state_name: "Maharashtra",
  },
  {
    name: "Karmala",
    state_name: "Maharashtra",
  },
  {
    name: "Kasara",
    state_name: "Maharashtra",
  },
  {
    name: "Kasoda",
    state_name: "Maharashtra",
  },
  {
    name: "Kati",
    state_name: "Maharashtra",
  },
  {
    name: "Katol",
    state_name: "Maharashtra",
  },
  {
    name: "Katral",
    state_name: "Maharashtra",
  },
  {
    name: "Khadki",
    state_name: "Maharashtra",
  },
  {
    name: "Khalapur",
    state_name: "Maharashtra",
  },
  {
    name: "Khallar",
    state_name: "Maharashtra",
  },
  {
    name: "Khamgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Khanapur",
    state_name: "Maharashtra",
  },
  {
    name: "Khandala",
    state_name: "Maharashtra",
  },
  {
    name: "Khangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Khapa",
    state_name: "Maharashtra",
  },
  {
    name: "Kharakvasla",
    state_name: "Maharashtra",
  },
  {
    name: "Kharda",
    state_name: "Maharashtra",
  },
  {
    name: "Kharghar",
    state_name: "Maharashtra",
  },
  {
    name: "Kharsundi",
    state_name: "Maharashtra",
  },
  {
    name: "Khed",
    state_name: "Maharashtra",
  },
  {
    name: "Khetia",
    state_name: "Maharashtra",
  },
  {
    name: "Khoni",
    state_name: "Maharashtra",
  },
  {
    name: "Khopoli",
    state_name: "Maharashtra",
  },
  {
    name: "Khuldabad",
    state_name: "Maharashtra",
  },
  {
    name: "Kinwat",
    state_name: "Maharashtra",
  },
  {
    name: "Kodoli",
    state_name: "Maharashtra",
  },
  {
    name: "Kolhapur",
    state_name: "Maharashtra",
  },
  {
    name: "Kondalwadi",
    state_name: "Maharashtra",
  },
  {
    name: "Kondhali",
    state_name: "Maharashtra",
  },
  {
    name: "Kopar Khairane",
    state_name: "Maharashtra",
  },
  {
    name: "Kopargaon",
    state_name: "Maharashtra",
  },
  {
    name: "Kopela",
    state_name: "Maharashtra",
  },
  {
    name: "Koradi",
    state_name: "Maharashtra",
  },
  {
    name: "Koregaon",
    state_name: "Maharashtra",
  },
  {
    name: "Koynanagar",
    state_name: "Maharashtra",
  },
  {
    name: "Kudal",
    state_name: "Maharashtra",
  },
  {
    name: "Kuhi",
    state_name: "Maharashtra",
  },
  {
    name: "Kurandvad",
    state_name: "Maharashtra",
  },
  {
    name: "Kurankhed",
    state_name: "Maharashtra",
  },
  {
    name: "Kurduvadi",
    state_name: "Maharashtra",
  },
  {
    name: "Kusumba",
    state_name: "Maharashtra",
  },
  {
    name: "Lakhandur",
    state_name: "Maharashtra",
  },
  {
    name: "Lanja",
    state_name: "Maharashtra",
  },
  {
    name: "Lasalgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Latur",
    state_name: "Maharashtra",
  },
  {
    name: "Lavasa",
    state_name: "Maharashtra",
  },
  {
    name: "Lohogaon",
    state_name: "Maharashtra",
  },
  {
    name: "Lonar",
    state_name: "Maharashtra",
  },
  {
    name: "Lonavla",
    state_name: "Maharashtra",
  },
  {
    name: "Mahabaleshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Mahad",
    state_name: "Maharashtra",
  },
  {
    name: "Mahape",
    state_name: "Maharashtra",
  },
  {
    name: "Mahim",
    state_name: "Maharashtra",
  },
  {
    name: "Maindargi",
    state_name: "Maharashtra",
  },
  {
    name: "Majalgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Makhjan",
    state_name: "Maharashtra",
  },
  {
    name: "Malabar Hill",
    state_name: "Maharashtra",
  },
  {
    name: "Malad",
    state_name: "Maharashtra",
  },
  {
    name: "Malegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Malkapur",
    state_name: "Maharashtra",
  },
  {
    name: "Malvan",
    state_name: "Maharashtra",
  },
  {
    name: "Manchar",
    state_name: "Maharashtra",
  },
  {
    name: "Mandangad",
    state_name: "Maharashtra",
  },
  {
    name: "Mandhal",
    state_name: "Maharashtra",
  },
  {
    name: "Mandwa",
    state_name: "Maharashtra",
  },
  {
    name: "Mangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Mangrul Pir",
    state_name: "Maharashtra",
  },
  {
    name: "Manjlegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Mankeshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Mankhurd",
    state_name: "Maharashtra",
  },
  {
    name: "Manmad",
    state_name: "Maharashtra",
  },
  {
    name: "Manor",
    state_name: "Maharashtra",
  },
  {
    name: "Mansar",
    state_name: "Maharashtra",
  },
  {
    name: "Manwat",
    state_name: "Maharashtra",
  },
  {
    name: "Maregaon",
    state_name: "Maharashtra",
  },
  {
    name: "Mastiholi",
    state_name: "Maharashtra",
  },
  {
    name: "Masur India",
    state_name: "Maharashtra",
  },
  {
    name: "Matheran",
    state_name: "Maharashtra",
  },
  {
    name: "Matunga",
    state_name: "Maharashtra",
  },
  {
    name: "Mazagaon",
    state_name: "Maharashtra",
  },
  {
    name: "Mehekar",
    state_name: "Maharashtra",
  },
  {
    name: "Mehergaon",
    state_name: "Maharashtra",
  },
  {
    name: "Mehkar",
    state_name: "Maharashtra",
  },
  {
    name: "Mhasla",
    state_name: "Maharashtra",
  },
  {
    name: "Mhasvad",
    state_name: "Maharashtra",
  },
  {
    name: "Miraj",
    state_name: "Maharashtra",
  },
  {
    name: "Mohadi",
    state_name: "Maharashtra",
  },
  {
    name: "Mohol",
    state_name: "Maharashtra",
  },
  {
    name: "Mohpa",
    state_name: "Maharashtra",
  },
  {
    name: "Mokhada taluka",
    state_name: "Maharashtra",
  },
  {
    name: "Mora Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Moram",
    state_name: "Maharashtra",
  },
  {
    name: "Morsi",
    state_name: "Maharashtra",
  },
  {
    name: "Mowad",
    state_name: "Maharashtra",
  },
  {
    name: "Mudkhed",
    state_name: "Maharashtra",
  },
  {
    name: "Mukher",
    state_name: "Maharashtra",
  },
  {
    name: "Mul",
    state_name: "Maharashtra",
  },
  {
    name: "Mulher",
    state_name: "Maharashtra",
  },
  {
    name: "Mulund",
    state_name: "Maharashtra",
  },
  {
    name: "Mumbai",
    state_name: "Maharashtra",
  },
  {
    name: "Mumbai Suburban",
    state_name: "Maharashtra",
  },
  {
    name: "Murbad",
    state_name: "Maharashtra",
  },
  {
    name: "Murgud",
    state_name: "Maharashtra",
  },
  {
    name: "Murtajapur",
    state_name: "Maharashtra",
  },
  {
    name: "Murud (Raigad)",
    state_name: "Maharashtra",
  },
  {
    name: "Murud (Ratnagiri)",
    state_name: "Maharashtra",
  },
  {
    name: "Murum",
    state_name: "Maharashtra",
  },
  {
    name: "Nadgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Nagapur",
    state_name: "Maharashtra",
  },
  {
    name: "Nagothana",
    state_name: "Maharashtra",
  },
  {
    name: "Nagpur",
    state_name: "Maharashtra",
  },
  {
    name: "Nagpur Division",
    state_name: "Maharashtra",
  },
  {
    name: "Nala Sopara",
    state_name: "Maharashtra",
  },
  {
    name: "Naldurg",
    state_name: "Maharashtra",
  },
  {
    name: "Nalegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Nampur",
    state_name: "Maharashtra",
  },
  {
    name: "Nanded",
    state_name: "Maharashtra",
  },
  {
    name: "Nandgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Nandnee",
    state_name: "Maharashtra",
  },
  {
    name: "Nandura",
    state_name: "Maharashtra",
  },
  {
    name: "Nandura Buzurg",
    state_name: "Maharashtra",
  },
  {
    name: "Nandurbar",
    state_name: "Maharashtra",
  },
  {
    name: "Narayangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Nardana",
    state_name: "Maharashtra",
  },
  {
    name: "Nariman Point",
    state_name: "Maharashtra",
  },
  {
    name: "Narkhed",
    state_name: "Maharashtra",
  },
  {
    name: "Nashik",
    state_name: "Maharashtra",
  },
  {
    name: "Nashik Division",
    state_name: "Maharashtra",
  },
  {
    name: "Navapur",
    state_name: "Maharashtra",
  },
  {
    name: "Navi Mumbai",
    state_name: "Maharashtra",
  },
  {
    name: "Neral",
    state_name: "Maharashtra",
  },
  {
    name: "Nerur",
    state_name: "Maharashtra",
  },
  {
    name: "Nevasa",
    state_name: "Maharashtra",
  },
  {
    name: "Nighoj",
    state_name: "Maharashtra",
  },
  {
    name: "Nilanga",
    state_name: "Maharashtra",
  },
  {
    name: "Nipani",
    state_name: "Maharashtra",
  },
  {
    name: "Niphad",
    state_name: "Maharashtra",
  },
  {
    name: "Nira Narsingpur",
    state_name: "Maharashtra",
  },
  {
    name: "Osmanabad",
    state_name: "Maharashtra",
  },
  {
    name: "Ozar",
    state_name: "Maharashtra",
  },
  {
    name: "Pabal",
    state_name: "Maharashtra",
  },
  {
    name: "Pachora",
    state_name: "Maharashtra",
  },
  {
    name: "Pahur Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Paithan",
    state_name: "Maharashtra",
  },
  {
    name: "Palghar",
    state_name: "Maharashtra",
  },
  {
    name: "Pali Raigad",
    state_name: "Maharashtra",
  },
  {
    name: "Palso",
    state_name: "Maharashtra",
  },
  {
    name: "Panchgani",
    state_name: "Maharashtra",
  },
  {
    name: "Pandharpur",
    state_name: "Maharashtra",
  },
  {
    name: "Pandhurli",
    state_name: "Maharashtra",
  },
  {
    name: "Panhala",
    state_name: "Maharashtra",
  },
  {
    name: "Panvel",
    state_name: "Maharashtra",
  },
  {
    name: "Parbhani",
    state_name: "Maharashtra",
  },
  {
    name: "Parel",
    state_name: "Maharashtra",
  },
  {
    name: "Parli Vaijnath",
    state_name: "Maharashtra",
  },
  {
    name: "Parner",
    state_name: "Maharashtra",
  },
  {
    name: "Parola",
    state_name: "Maharashtra",
  },
  {
    name: "Parseoni",
    state_name: "Maharashtra",
  },
  {
    name: "Partur",
    state_name: "Maharashtra",
  },
  {
    name: "Patan",
    state_name: "Maharashtra",
  },
  {
    name: "Pathardi",
    state_name: "Maharashtra",
  },
  {
    name: "Pathri",
    state_name: "Maharashtra",
  },
  {
    name: "Patur",
    state_name: "Maharashtra",
  },
  {
    name: "Paturda",
    state_name: "Maharashtra",
  },
  {
    name: "Paud",
    state_name: "Maharashtra",
  },
  {
    name: "Pauni",
    state_name: "Maharashtra",
  },
  {
    name: "Pawni",
    state_name: "Maharashtra",
  },
  {
    name: "Pedgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Peint",
    state_name: "Maharashtra",
  },
  {
    name: "Pen",
    state_name: "Maharashtra",
  },
  {
    name: "Phaltan",
    state_name: "Maharashtra",
  },
  {
    name: "Phulambri",
    state_name: "Maharashtra",
  },
  {
    name: "Piliv",
    state_name: "Maharashtra",
  },
  {
    name: "Pimpalgaon Baswant",
    state_name: "Maharashtra",
  },
  {
    name: "Pimpalgaon Raja",
    state_name: "Maharashtra",
  },
  {
    name: "Pimpri",
    state_name: "Maharashtra",
  },
  {
    name: "Pimpri-Chinchwad",
    state_name: "Maharashtra",
  },
  {
    name: "Pipri",
    state_name: "Maharashtra",
  },
  {
    name: "Powai",
    state_name: "Maharashtra",
  },
  {
    name: "Prabhadevi",
    state_name: "Maharashtra",
  },
  {
    name: "Prakasha",
    state_name: "Maharashtra",
  },
  {
    name: "Pulgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Pune",
    state_name: "Maharashtra",
  },
  {
    name: "Pune Division",
    state_name: "Maharashtra",
  },
  {
    name: "Puntamba",
    state_name: "Maharashtra",
  },
  {
    name: "Pural",
    state_name: "Maharashtra",
  },
  {
    name: "Purna",
    state_name: "Maharashtra",
  },
  {
    name: "Pusad",
    state_name: "Maharashtra",
  },
  {
    name: "Radhanagari",
    state_name: "Maharashtra",
  },
  {
    name: "Rahata",
    state_name: "Maharashtra",
  },
  {
    name: "Rahimatpur",
    state_name: "Maharashtra",
  },
  {
    name: "Rahuri",
    state_name: "Maharashtra",
  },
  {
    name: "Raigarh",
    state_name: "Maharashtra",
  },
  {
    name: "Raireshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Rajapur",
    state_name: "Maharashtra",
  },
  {
    name: "Rajgurunagar",
    state_name: "Maharashtra",
  },
  {
    name: "Rajur",
    state_name: "Maharashtra",
  },
  {
    name: "Rajura",
    state_name: "Maharashtra",
  },
  {
    name: "Ralegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Ramewadi",
    state_name: "Maharashtra",
  },
  {
    name: "Ramtek",
    state_name: "Maharashtra",
  },
  {
    name: "Ratnagiri",
    state_name: "Maharashtra",
  },
  {
    name: "Raver",
    state_name: "Maharashtra",
  },
  {
    name: "Renapur",
    state_name: "Maharashtra",
  },
  {
    name: "Renavi",
    state_name: "Maharashtra",
  },
  {
    name: "Revadanda",
    state_name: "Maharashtra",
  },
  {
    name: "Revdanda",
    state_name: "Maharashtra",
  },
  {
    name: "Risod",
    state_name: "Maharashtra",
  },
  {
    name: "Roha",
    state_name: "Maharashtra",
  },
  {
    name: "Sailu",
    state_name: "Maharashtra",
  },
  {
    name: "Sakol",
    state_name: "Maharashtra",
  },
  {
    name: "Sakoli",
    state_name: "Maharashtra",
  },
  {
    name: "Sakri",
    state_name: "Maharashtra",
  },
  {
    name: "Samudrapur",
    state_name: "Maharashtra",
  },
  {
    name: "Sangameshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Sangamner",
    state_name: "Maharashtra",
  },
  {
    name: "Sangli",
    state_name: "Maharashtra",
  },
  {
    name: "Sangola",
    state_name: "Maharashtra",
  },
  {
    name: "Sangrampur Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Saoli",
    state_name: "Maharashtra",
  },
  {
    name: "Saoner",
    state_name: "Maharashtra",
  },
  {
    name: "Sarangkheda",
    state_name: "Maharashtra",
  },
  {
    name: "Saswad",
    state_name: "Maharashtra",
  },
  {
    name: "Satana",
    state_name: "Maharashtra",
  },
  {
    name: "Satara",
    state_name: "Maharashtra",
  },
  {
    name: "Satara Division",
    state_name: "Maharashtra",
  },
  {
    name: "Satpati",
    state_name: "Maharashtra",
  },
  {
    name: "Savantvadi",
    state_name: "Maharashtra",
  },
  {
    name: "Savda",
    state_name: "Maharashtra",
  },
  {
    name: "Savlaj",
    state_name: "Maharashtra",
  },
  {
    name: "Sawantvadi",
    state_name: "Maharashtra",
  },
  {
    name: "Selu",
    state_name: "Maharashtra",
  },
  {
    name: "Sevagram",
    state_name: "Maharashtra",
  },
  {
    name: "Sewri",
    state_name: "Maharashtra",
  },
  {
    name: "Shahada",
    state_name: "Maharashtra",
  },
  {
    name: "Shahapur",
    state_name: "Maharashtra",
  },
  {
    name: "Shedbal",
    state_name: "Maharashtra",
  },
  {
    name: "Shegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Shevgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Shikrapur",
    state_name: "Maharashtra",
  },
  {
    name: "Shiraguppi",
    state_name: "Maharashtra",
  },
  {
    name: "Shirala",
    state_name: "Maharashtra",
  },
  {
    name: "Shirdi",
    state_name: "Maharashtra",
  },
  {
    name: "Shirgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Shirol",
    state_name: "Maharashtra",
  },
  {
    name: "Shirpur",
    state_name: "Maharashtra",
  },
  {
    name: "Shirud",
    state_name: "Maharashtra",
  },
  {
    name: "Shirwal",
    state_name: "Maharashtra",
  },
  {
    name: "Shivaji Nagar",
    state_name: "Maharashtra",
  },
  {
    name: "Shrigonda",
    state_name: "Maharashtra",
  },
  {
    name: "Sillod",
    state_name: "Maharashtra",
  },
  {
    name: "Sindewahi",
    state_name: "Maharashtra",
  },
  {
    name: "Sindhudurg",
    state_name: "Maharashtra",
  },
  {
    name: "Sindi",
    state_name: "Maharashtra",
  },
  {
    name: "Sindkheda",
    state_name: "Maharashtra",
  },
  {
    name: "Sinnar",
    state_name: "Maharashtra",
  },
  {
    name: "Sion Mumbai",
    state_name: "Maharashtra",
  },
  {
    name: "Sironcha",
    state_name: "Maharashtra",
  },
  {
    name: "Sirur",
    state_name: "Maharashtra",
  },
  {
    name: "Sivala East Godavari district",
    state_name: "Maharashtra",
  },
  {
    name: "Solapur",
    state_name: "Maharashtra",
  },
  {
    name: "Sonala",
    state_name: "Maharashtra",
  },
  {
    name: "Sonegaon",
    state_name: "Maharashtra",
  },
  {
    name: "Songir",
    state_name: "Maharashtra",
  },
  {
    name: "Sonvad",
    state_name: "Maharashtra",
  },
  {
    name: "Soygaon",
    state_name: "Maharashtra",
  },
  {
    name: "Srivardhan",
    state_name: "Maharashtra",
  },
  {
    name: "Surgana",
    state_name: "Maharashtra",
  },
  {
    name: "Taklibhan",
    state_name: "Maharashtra",
  },
  {
    name: "Talbid",
    state_name: "Maharashtra",
  },
  {
    name: "Talegaon Dabhade",
    state_name: "Maharashtra",
  },
  {
    name: "Talegaon Dhamdhere",
    state_name: "Maharashtra",
  },
  {
    name: "Taloda",
    state_name: "Maharashtra",
  },
  {
    name: "Talode",
    state_name: "Maharashtra",
  },
  {
    name: "Tarapur",
    state_name: "Maharashtra",
  },
  {
    name: "Tardeo",
    state_name: "Maharashtra",
  },
  {
    name: "Tasgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Telhara",
    state_name: "Maharashtra",
  },
  {
    name: "Thalner",
    state_name: "Maharashtra",
  },
  {
    name: "Thane",
    state_name: "Maharashtra",
  },
  {
    name: "Trimbak",
    state_name: "Maharashtra",
  },
  {
    name: "Trombay",
    state_name: "Maharashtra",
  },
  {
    name: "Tuljapur",
    state_name: "Maharashtra",
  },
  {
    name: "Tumsar",
    state_name: "Maharashtra",
  },
  {
    name: "Udgir",
    state_name: "Maharashtra",
  },
  {
    name: "Ulhasnagar",
    state_name: "Maharashtra",
  },
  {
    name: "Umarga",
    state_name: "Maharashtra",
  },
  {
    name: "Umarkhed",
    state_name: "Maharashtra",
  },
  {
    name: "Umred",
    state_name: "Maharashtra",
  },
  {
    name: "Uran",
    state_name: "Maharashtra",
  },
  {
    name: "Uruli Kanchan",
    state_name: "Maharashtra",
  },
  {
    name: "Vada",
    state_name: "Maharashtra",
  },
  {
    name: "Vadgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Vadner",
    state_name: "Maharashtra",
  },
  {
    name: "Vaijapur",
    state_name: "Maharashtra",
  },
  {
    name: "Vairag",
    state_name: "Maharashtra",
  },
  {
    name: "Valsang",
    state_name: "Maharashtra",
  },
  {
    name: "Vangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Varangaon",
    state_name: "Maharashtra",
  },
  {
    name: "Vashi",
    state_name: "Maharashtra",
  },
  {
    name: "Vasind",
    state_name: "Maharashtra",
  },
  {
    name: "Vatul",
    state_name: "Maharashtra",
  },
  {
    name: "Velas Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Velneshwar",
    state_name: "Maharashtra",
  },
  {
    name: "Vengurla",
    state_name: "Maharashtra",
  },
  {
    name: "Vijaydurg",
    state_name: "Maharashtra",
  },
  {
    name: "Vikhroli",
    state_name: "Maharashtra",
  },
  {
    name: "Vile Parle",
    state_name: "Maharashtra",
  },
  {
    name: "Vinchur",
    state_name: "Maharashtra",
  },
  {
    name: "Virar",
    state_name: "Maharashtra",
  },
  {
    name: "Vita Maharashtra",
    state_name: "Maharashtra",
  },
  {
    name: "Vite",
    state_name: "Maharashtra",
  },
  {
    name: "Wadala",
    state_name: "Maharashtra",
  },
  {
    name: "Wadgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Wadner",
    state_name: "Maharashtra",
  },
  {
    name: "Wadwani",
    state_name: "Maharashtra",
  },
  {
    name: "Wagholi",
    state_name: "Maharashtra",
  },
  {
    name: "Wai",
    state_name: "Maharashtra",
  },
  {
    name: "Wakad",
    state_name: "Maharashtra",
  },
  {
    name: "Walgaon",
    state_name: "Maharashtra",
  },
  {
    name: "Walki",
    state_name: "Maharashtra",
  },
  {
    name: "Wani",
    state_name: "Maharashtra",
  },
  {
    name: "Wardha",
    state_name: "Maharashtra",
  },
  {
    name: "Warora",
    state_name: "Maharashtra",
  },
  {
    name: "Warud",
    state_name: "Maharashtra",
  },
  {
    name: "Washim",
    state_name: "Maharashtra",
  },
  {
    name: "Worli",
    state_name: "Maharashtra",
  },
  {
    name: "Yaval",
    state_name: "Maharashtra",
  },
  {
    name: "Yavatmal",
    state_name: "Maharashtra",
  },
  {
    name: "Yeola",
    state_name: "Maharashtra",
  },
  {
    name: "Bishnupur",
    state_name: "Manipur",
  },
  {
    name: "Chandel",
    state_name: "Manipur",
  },
  {
    name: "Churachandpur",
    state_name: "Manipur",
  },
  {
    name: "Imphal East",
    state_name: "Manipur",
  },
  {
    name: "Imphal West",
    state_name: "Manipur",
  },
  {
    name: "Jiribam",
    state_name: "Manipur",
  },
  {
    name: "Kakching",
    state_name: "Manipur",
  },
  {
    name: "Kamjong",
    state_name: "Manipur",
  },
  {
    name: "Kangpokpi",
    state_name: "Manipur",
  },
  {
    name: "Noney",
    state_name: "Manipur",
  },
  {
    name: "Pherzawl",
    state_name: "Manipur",
  },
  {
    name: "Senapati",
    state_name: "Manipur",
  },
  {
    name: "Tamenglong",
    state_name: "Manipur",
  },
  {
    name: "Tengnoupal",
    state_name: "Manipur",
  },
  {
    name: "Thoubal",
    state_name: "Manipur",
  },
  {
    name: "Ukhrul",
    state_name: "Manipur",
  },
  {
    name: "Cherrapunji",
    state_name: "Meghalaya",
  },
  {
    name: "East Garo Hills",
    state_name: "Meghalaya",
  },
  {
    name: "East Jaintia Hills",
    state_name: "Meghalaya",
  },
  {
    name: "East Khasi Hills",
    state_name: "Meghalaya",
  },
  {
    name: "Mairang",
    state_name: "Meghalaya",
  },
  {
    name: "Mankachar",
    state_name: "Meghalaya",
  },
  {
    name: "Nongpoh",
    state_name: "Meghalaya",
  },
  {
    name: "Nongstoin",
    state_name: "Meghalaya",
  },
  {
    name: "North Garo Hills",
    state_name: "Meghalaya",
  },
  {
    name: "Ri-Bhoi",
    state_name: "Meghalaya",
  },
  {
    name: "Shillong",
    state_name: "Meghalaya",
  },
  {
    name: "South Garo Hills",
    state_name: "Meghalaya",
  },
  {
    name: "South West Garo Hills",
    state_name: "Meghalaya",
  },
  {
    name: "South West Khasi Hills",
    state_name: "Meghalaya",
  },
  {
    name: "Tura",
    state_name: "Meghalaya",
  },
  {
    name: "West Garo Hills",
    state_name: "Meghalaya",
  },
  {
    name: "West Jaintia Hills",
    state_name: "Meghalaya",
  },
  {
    name: "West Khasi Hills",
    state_name: "Meghalaya",
  },
  {
    name: "Aizawl",
    state_name: "Mizoram",
  },
  {
    name: "Champhai",
    state_name: "Mizoram",
  },
  {
    name: "Darlawn",
    state_name: "Mizoram",
  },
  {
    name: "Khawhai",
    state_name: "Mizoram",
  },
  {
    name: "Kolasib",
    state_name: "Mizoram",
  },
  {
    name: "Lawngtlai",
    state_name: "Mizoram",
  },
  {
    name: "Lunglei",
    state_name: "Mizoram",
  },
  {
    name: "Mamit",
    state_name: "Mizoram",
  },
  {
    name: "North Vanlaiphai",
    state_name: "Mizoram",
  },
  {
    name: "Saiha",
    state_name: "Mizoram",
  },
  {
    name: "Sairang",
    state_name: "Mizoram",
  },
  {
    name: "Saitlaw",
    state_name: "Mizoram",
  },
  {
    name: "Serchhip",
    state_name: "Mizoram",
  },
  {
    name: "Thenzawl",
    state_name: "Mizoram",
  },
  {
    name: "Dimapur",
    state_name: "Nagaland",
  },
  {
    name: "Kohima",
    state_name: "Nagaland",
  },
  {
    name: "Mokokchung",
    state_name: "Nagaland",
  },
  {
    name: "Mon",
    state_name: "Nagaland",
  },
  {
    name: "Peren",
    state_name: "Nagaland",
  },
  {
    name: "Phek",
    state_name: "Nagaland",
  },
  {
    name: "Tuensang",
    state_name: "Nagaland",
  },
  {
    name: "Tuensang District",
    state_name: "Nagaland",
  },
  {
    name: "Wokha",
    state_name: "Nagaland",
  },
  {
    name: "Zunheboto",
    state_name: "Nagaland",
  },
  {
    name: "Angul",
    state_name: "Odisha",
  },
  {
    name: "Angul District",
    state_name: "Odisha",
  },
  {
    name: "Asika",
    state_name: "Odisha",
  },
  {
    name: "Athagarh",
    state_name: "Odisha",
  },
  {
    name: "Bada Barabil",
    state_name: "Odisha",
  },
  {
    name: "Balangir",
    state_name: "Odisha",
  },
  {
    name: "Balasore",
    state_name: "Odisha",
  },
  {
    name: "Baleshwar",
    state_name: "Odisha",
  },
  {
    name: "Balimila",
    state_name: "Odisha",
  },
  {
    name: "Balugaon",
    state_name: "Odisha",
  },
  {
    name: "Banapur",
    state_name: "Odisha",
  },
  {
    name: "Banki",
    state_name: "Odisha",
  },
  {
    name: "Banposh",
    state_name: "Odisha",
  },
  {
    name: "Baragarh",
    state_name: "Odisha",
  },
  {
    name: "Barbil",
    state_name: "Odisha",
  },
  {
    name: "Bargarh",
    state_name: "Odisha",
  },
  {
    name: "Barpali",
    state_name: "Odisha",
  },
  {
    name: "Basudebpur",
    state_name: "Odisha",
  },
  {
    name: "Baud",
    state_name: "Odisha",
  },
  {
    name: "Baudh",
    state_name: "Odisha",
  },
  {
    name: "Belaguntha",
    state_name: "Odisha",
  },
  {
    name: "Bhadrak",
    state_name: "Odisha",
  },
  {
    name: "Bhadrakh",
    state_name: "Odisha",
  },
  {
    name: "Bhanjanagar",
    state_name: "Odisha",
  },
  {
    name: "Bhawanipatna",
    state_name: "Odisha",
  },
  {
    name: "Bhuban",
    state_name: "Odisha",
  },
  {
    name: "Bhubaneswar",
    state_name: "Odisha",
  },
  {
    name: "Binka",
    state_name: "Odisha",
  },
  {
    name: "Birmitrapur",
    state_name: "Odisha",
  },
  {
    name: "Bolanikhodan",
    state_name: "Odisha",
  },
  {
    name: "Brahmapur",
    state_name: "Odisha",
  },
  {
    name: "Brajarajnagar",
    state_name: "Odisha",
  },
  {
    name: "Buguda",
    state_name: "Odisha",
  },
  {
    name: "Burla",
    state_name: "Odisha",
  },
  {
    name: "Champua",
    state_name: "Odisha",
  },
  {
    name: "Chandbali",
    state_name: "Odisha",
  },
  {
    name: "Chatrapur",
    state_name: "Odisha",
  },
  {
    name: "Chikitigarh",
    state_name: "Odisha",
  },
  {
    name: "Chittarkonda",
    state_name: "Odisha",
  },
  {
    name: "Cuttack",
    state_name: "Odisha",
  },
  {
    name: "Daitari",
    state_name: "Odisha",
  },
  {
    name: "Deogarh",
    state_name: "Odisha",
  },
  {
    name: "Dhenkanal",
    state_name: "Odisha",
  },
  {
    name: "Digapahandi",
    state_name: "Odisha",
  },
  {
    name: "Gajapati",
    state_name: "Odisha",
  },
  {
    name: "Ganjam",
    state_name: "Odisha",
  },
  {
    name: "Gopalpur",
    state_name: "Odisha",
  },
  {
    name: "Gudari",
    state_name: "Odisha",
  },
  {
    name: "Gunupur",
    state_name: "Odisha",
  },
  {
    name: "Hinjilikatu",
    state_name: "Odisha",
  },
  {
    name: "Hirakud",
    state_name: "Odisha",
  },
  {
    name: "Jagatsinghpur",
    state_name: "Odisha",
  },
  {
    name: "Jajpur",
    state_name: "Odisha",
  },
  {
    name: "Jaleshwar",
    state_name: "Odisha",
  },
  {
    name: "Jatani",
    state_name: "Odisha",
  },
  {
    name: "Jeypore",
    state_name: "Odisha",
  },
  {
    name: "Jharsuguda",
    state_name: "Odisha",
  },
  {
    name: "Jharsuguda District",
    state_name: "Odisha",
  },
  {
    name: "Kaintragarh",
    state_name: "Odisha",
  },
  {
    name: "Kalahandi",
    state_name: "Odisha",
  },
  {
    name: "Kamakhyanagar",
    state_name: "Odisha",
  },
  {
    name: "Kandhamal",
    state_name: "Odisha",
  },
  {
    name: "Kantabanji",
    state_name: "Odisha",
  },
  {
    name: "Kantilo",
    state_name: "Odisha",
  },
  {
    name: "Kendrapara",
    state_name: "Odisha",
  },
  {
    name: "Kendujhar",
    state_name: "Odisha",
  },
  {
    name: "Kesinga",
    state_name: "Odisha",
  },
  {
    name: "Khallikot",
    state_name: "Odisha",
  },
  {
    name: "Kharhial",
    state_name: "Odisha",
  },
  {
    name: "Khordha",
    state_name: "Odisha",
  },
  {
    name: "Khurda",
    state_name: "Odisha",
  },
  {
    name: "Kiri Buru",
    state_name: "Odisha",
  },
  {
    name: "Kodala",
    state_name: "Odisha",
  },
  {
    name: "Konarka",
    state_name: "Odisha",
  },
  {
    name: "Koraput",
    state_name: "Odisha",
  },
  {
    name: "Kuchaiburi",
    state_name: "Odisha",
  },
  {
    name: "Kuchinda",
    state_name: "Odisha",
  },
  {
    name: "Malkangiri",
    state_name: "Odisha",
  },
  {
    name: "Mayurbhanj",
    state_name: "Odisha",
  },
  {
    name: "Nabarangpur",
    state_name: "Odisha",
  },
  {
    name: "Nayagarh",
    state_name: "Odisha",
  },
  {
    name: "Nayagarh District",
    state_name: "Odisha",
  },
  {
    name: "Nilgiri",
    state_name: "Odisha",
  },
  {
    name: "Nimaparha",
    state_name: "Odisha",
  },
  {
    name: "Nowrangapur",
    state_name: "Odisha",
  },
  {
    name: "Nuapada",
    state_name: "Odisha",
  },
  {
    name: "Padampur",
    state_name: "Odisha",
  },
  {
    name: "Paradip Garh",
    state_name: "Odisha",
  },
  {
    name: "Patamundai",
    state_name: "Odisha",
  },
  {
    name: "Patnagarh",
    state_name: "Odisha",
  },
  {
    name: "Phulbani",
    state_name: "Odisha",
  },
  {
    name: "Pipili",
    state_name: "Odisha",
  },
  {
    name: "Polasara",
    state_name: "Odisha",
  },
  {
    name: "Puri",
    state_name: "Odisha",
  },
  {
    name: "Purushottampur",
    state_name: "Odisha",
  },
  {
    name: "Rambha",
    state_name: "Odisha",
  },
  {
    name: "Raurkela",
    state_name: "Odisha",
  },
  {
    name: "Rayagada",
    state_name: "Odisha",
  },
  {
    name: "Remuna",
    state_name: "Odisha",
  },
  {
    name: "Rengali",
    state_name: "Odisha",
  },
  {
    name: "Sambalpur",
    state_name: "Odisha",
  },
  {
    name: "Sonepur",
    state_name: "Odisha",
  },
  {
    name: "Sorada",
    state_name: "Odisha",
  },
  {
    name: "Soro",
    state_name: "Odisha",
  },
  {
    name: "Subarnapur",
    state_name: "Odisha",
  },
  {
    name: "Sundargarh",
    state_name: "Odisha",
  },
  {
    name: "Talcher",
    state_name: "Odisha",
  },
  {
    name: "Tarabha",
    state_name: "Odisha",
  },
  {
    name: "Titlagarh",
    state_name: "Odisha",
  },
  {
    name: "Udayagiri",
    state_name: "Odisha",
  },
  {
    name: "Karaikal",
    state_name: "Puducherry",
  },
  {
    name: "Mahe",
    state_name: "Puducherry",
  },
  {
    name: "Puducherry",
    state_name: "Puducherry",
  },
  {
    name: "Yanam",
    state_name: "Puducherry",
  },
  {
    name: "Abohar",
    state_name: "Punjab",
  },
  {
    name: "Adampur",
    state_name: "Punjab",
  },
  {
    name: "Ajitgarh",
    state_name: "Punjab",
  },
  {
    name: "Ajnala",
    state_name: "Punjab",
  },
  {
    name: "Akalgarh",
    state_name: "Punjab",
  },
  {
    name: "Alawalpur",
    state_name: "Punjab",
  },
  {
    name: "Amloh",
    state_name: "Punjab",
  },
  {
    name: "Amritsar",
    state_name: "Punjab",
  },
  {
    name: "Anandpur Sahib",
    state_name: "Punjab",
  },
  {
    name: "Badhni Kalan",
    state_name: "Punjab",
  },
  {
    name: "Bagha Purana",
    state_name: "Punjab",
  },
  {
    name: "Bakloh",
    state_name: "Punjab",
  },
  {
    name: "Balachor",
    state_name: "Punjab",
  },
  {
    name: "Banga",
    state_name: "Punjab",
  },
  {
    name: "Banur",
    state_name: "Punjab",
  },
  {
    name: "Barnala",
    state_name: "Punjab",
  },
  {
    name: "Batala",
    state_name: "Punjab",
  },
  {
    name: "Begowal",
    state_name: "Punjab",
  },
  {
    name: "Bhadaur",
    state_name: "Punjab",
  },
  {
    name: "Bhatinda",
    state_name: "Punjab",
  },
  {
    name: "Bhawanigarh",
    state_name: "Punjab",
  },
  {
    name: "Bhikhi",
    state_name: "Punjab",
  },
  {
    name: "Bhogpur",
    state_name: "Punjab",
  },
  {
    name: "Bholath",
    state_name: "Punjab",
  },
  {
    name: "Budhlada",
    state_name: "Punjab",
  },
  {
    name: "Chima",
    state_name: "Punjab",
  },
  {
    name: "Dasuya",
    state_name: "Punjab",
  },
  {
    name: "Dera Baba Nanak",
    state_name: "Punjab",
  },
  {
    name: "Dera Bassi",
    state_name: "Punjab",
  },
  {
    name: "Dhanaula",
    state_name: "Punjab",
  },
  {
    name: "Dhariwal",
    state_name: "Punjab",
  },
  {
    name: "Dhilwan",
    state_name: "Punjab",
  },
  {
    name: "Dhudi",
    state_name: "Punjab",
  },
  {
    name: "Dhuri",
    state_name: "Punjab",
  },
  {
    name: "Dina Nagar",
    state_name: "Punjab",
  },
  {
    name: "Dirba",
    state_name: "Punjab",
  },
  {
    name: "Doraha",
    state_name: "Punjab",
  },
  {
    name: "Faridkot",
    state_name: "Punjab",
  },
  {
    name: "Fatehgarh Churian",
    state_name: "Punjab",
  },
  {
    name: "Fatehgarh Sahib",
    state_name: "Punjab",
  },
  {
    name: "Fazilka",
    state_name: "Punjab",
  },
  {
    name: "Firozpur",
    state_name: "Punjab",
  },
  {
    name: "Firozpur District",
    state_name: "Punjab",
  },
  {
    name: "Gardhiwala",
    state_name: "Punjab",
  },
  {
    name: "Garhshankar",
    state_name: "Punjab",
  },
  {
    name: "Ghanaur",
    state_name: "Punjab",
  },
  {
    name: "Giddarbaha",
    state_name: "Punjab",
  },
  {
    name: "Gurdaspur",
    state_name: "Punjab",
  },
  {
    name: "Guru Har Sahai",
    state_name: "Punjab",
  },
  {
    name: "Hajipur",
    state_name: "Punjab",
  },
  {
    name: "Hariana",
    state_name: "Punjab",
  },
  {
    name: "Hoshiarpur",
    state_name: "Punjab",
  },
  {
    name: "Ishanpur",
    state_name: "Punjab",
  },
  {
    name: "Jagraon",
    state_name: "Punjab",
  },
  {
    name: "Jaito",
    state_name: "Punjab",
  },
  {
    name: "Jalalabad",
    state_name: "Punjab",
  },
  {
    name: "Jalandhar",
    state_name: "Punjab",
  },
  {
    name: "Jandiala",
    state_name: "Punjab",
  },
  {
    name: "Jandiala Guru",
    state_name: "Punjab",
  },
  {
    name: "Kalanaur",
    state_name: "Punjab",
  },
  {
    name: "Kapurthala",
    state_name: "Punjab",
  },
  {
    name: "Kartarpur",
    state_name: "Punjab",
  },
  {
    name: "Khamanon",
    state_name: "Punjab",
  },
  {
    name: "Khanna",
    state_name: "Punjab",
  },
  {
    name: "Kharar",
    state_name: "Punjab",
  },
  {
    name: "Khemkaran",
    state_name: "Punjab",
  },
  {
    name: "Kot Isa Khan",
    state_name: "Punjab",
  },
  {
    name: "Kotkapura",
    state_name: "Punjab",
  },
  {
    name: "Laungowal",
    state_name: "Punjab",
  },
  {
    name: "Ludhiana",
    state_name: "Punjab",
  },
  {
    name: "Machhiwara",
    state_name: "Punjab",
  },
  {
    name: "Majitha",
    state_name: "Punjab",
  },
  {
    name: "Makhu",
    state_name: "Punjab",
  },
  {
    name: "Malaut",
    state_name: "Punjab",
  },
  {
    name: "Malerkotla",
    state_name: "Punjab",
  },
  {
    name: "Mansa",
    state_name: "Punjab",
  },
  {
    name: "Maur Mandi",
    state_name: "Punjab",
  },
  {
    name: "Moga",
    state_name: "Punjab",
  },
  {
    name: "Mohali",
    state_name: "Punjab",
  },
  {
    name: "Morinda",
    state_name: "Punjab",
  },
  {
    name: "Mukerian",
    state_name: "Punjab",
  },
  {
    name: "Nabha",
    state_name: "Punjab",
  },
  {
    name: "Nakodar",
    state_name: "Punjab",
  },
  {
    name: "Nangal",
    state_name: "Punjab",
  },
  {
    name: "Nawanshahr",
    state_name: "Punjab",
  },
  {
    name: "Nurmahal",
    state_name: "Punjab",
  },
  {
    name: "Nurpur Kalan",
    state_name: "Punjab",
  },
  {
    name: "Pathankot",
    state_name: "Punjab",
  },
  {
    name: "Patiala",
    state_name: "Punjab",
  },
  {
    name: "Patti",
    state_name: "Punjab",
  },
  {
    name: "Phagwara",
    state_name: "Punjab",
  },
  {
    name: "Phillaur",
    state_name: "Punjab",
  },
  {
    name: "Qadian",
    state_name: "Punjab",
  },
  {
    name: "Rahon",
    state_name: "Punjab",
  },
  {
    name: "Raikot",
    state_name: "Punjab",
  },
  {
    name: "Rajasansi",
    state_name: "Punjab",
  },
  {
    name: "Rajpura",
    state_name: "Punjab",
  },
  {
    name: "Ram Das",
    state_name: "Punjab",
  },
  {
    name: "Rampura",
    state_name: "Punjab",
  },
  {
    name: "Rupnagar",
    state_name: "Punjab",
  },
  {
    name: "Samrala",
    state_name: "Punjab",
  },
  {
    name: "Sanaur",
    state_name: "Punjab",
  },
  {
    name: "Sangrur",
    state_name: "Punjab",
  },
  {
    name: "Sardulgarh",
    state_name: "Punjab",
  },
  {
    name: "Shahid Bhagat Singh Nagar",
    state_name: "Punjab",
  },
  {
    name: "Shahkot",
    state_name: "Punjab",
  },
  {
    name: "Sham Churasi",
    state_name: "Punjab",
  },
  {
    name: "Sirhind-Fategarh",
    state_name: "Punjab",
  },
  {
    name: "Sri Muktsar Sahib",
    state_name: "Punjab",
  },
  {
    name: "Sultanpur Lodhi",
    state_name: "Punjab",
  },
  {
    name: "Sunam",
    state_name: "Punjab",
  },
  {
    name: "Talwandi Bhai",
    state_name: "Punjab",
  },
  {
    name: "Talwara",
    state_name: "Punjab",
  },
  {
    name: "Tarn Taran Sahib",
    state_name: "Punjab",
  },
  {
    name: "Zira",
    state_name: "Punjab",
  },
  {
    name: "Abhaneri",
    state_name: "Rajasthan",
  },
  {
    name: "Abu",
    state_name: "Rajasthan",
  },
  {
    name: "Abu Road",
    state_name: "Rajasthan",
  },
  {
    name: "Ajmer",
    state_name: "Rajasthan",
  },
  {
    name: "Aklera",
    state_name: "Rajasthan",
  },
  {
    name: "Alwar",
    state_name: "Rajasthan",
  },
  {
    name: "Amet",
    state_name: "Rajasthan",
  },
  {
    name: "Anta",
    state_name: "Rajasthan",
  },
  {
    name: "Anupgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Asind",
    state_name: "Rajasthan",
  },
  {
    name: "Bagar",
    state_name: "Rajasthan",
  },
  {
    name: "Bakani",
    state_name: "Rajasthan",
  },
  {
    name: "Bali",
    state_name: "Rajasthan",
  },
  {
    name: "Balotra",
    state_name: "Rajasthan",
  },
  {
    name: "Bandikui",
    state_name: "Rajasthan",
  },
  {
    name: "Banswara",
    state_name: "Rajasthan",
  },
  {
    name: "Baran",
    state_name: "Rajasthan",
  },
  {
    name: "Bari",
    state_name: "Rajasthan",
  },
  {
    name: "Bari Sadri",
    state_name: "Rajasthan",
  },
  {
    name: "Barmer",
    state_name: "Rajasthan",
  },
  {
    name: "Basi",
    state_name: "Rajasthan",
  },
  {
    name: "Basni",
    state_name: "Rajasthan",
  },
  {
    name: "Baswa",
    state_name: "Rajasthan",
  },
  {
    name: "Bayana",
    state_name: "Rajasthan",
  },
  {
    name: "Beawar",
    state_name: "Rajasthan",
  },
  {
    name: "Begun",
    state_name: "Rajasthan",
  },
  {
    name: "Behror",
    state_name: "Rajasthan",
  },
  {
    name: "Bhadasar",
    state_name: "Rajasthan",
  },
  {
    name: "Bhadra",
    state_name: "Rajasthan",
  },
  {
    name: "Bharatpur",
    state_name: "Rajasthan",
  },
  {
    name: "Bhasawar",
    state_name: "Rajasthan",
  },
  {
    name: "Bhilwara",
    state_name: "Rajasthan",
  },
  {
    name: "Bhindar",
    state_name: "Rajasthan",
  },
  {
    name: "Bhinmal",
    state_name: "Rajasthan",
  },
  {
    name: "Bhiwadi",
    state_name: "Rajasthan",
  },
  {
    name: "Bhuma",
    state_name: "Rajasthan",
  },
  {
    name: "Bikaner",
    state_name: "Rajasthan",
  },
  {
    name: "Bilara",
    state_name: "Rajasthan",
  },
  {
    name: "Bissau",
    state_name: "Rajasthan",
  },
  {
    name: "Borkhera",
    state_name: "Rajasthan",
  },
  {
    name: "Bundi",
    state_name: "Rajasthan",
  },
  {
    name: "Chaksu",
    state_name: "Rajasthan",
  },
  {
    name: "Chechat",
    state_name: "Rajasthan",
  },
  {
    name: "Chhabra",
    state_name: "Rajasthan",
  },
  {
    name: "Chhapar",
    state_name: "Rajasthan",
  },
  {
    name: "Chhoti Sadri",
    state_name: "Rajasthan",
  },
  {
    name: "Chidawa",
    state_name: "Rajasthan",
  },
  {
    name: "Chittaurgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Churu",
    state_name: "Rajasthan",
  },
  {
    name: "Dariba",
    state_name: "Rajasthan",
  },
  {
    name: "Dausa",
    state_name: "Rajasthan",
  },
  {
    name: "Deoli",
    state_name: "Rajasthan",
  },
  {
    name: "Deshnoke",
    state_name: "Rajasthan",
  },
  {
    name: "Devgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Dhaulpur",
    state_name: "Rajasthan",
  },
  {
    name: "Didwana",
    state_name: "Rajasthan",
  },
  {
    name: "Dig",
    state_name: "Rajasthan",
  },
  {
    name: "Dungarpur",
    state_name: "Rajasthan",
  },
  {
    name: "Fatehpur",
    state_name: "Rajasthan",
  },
  {
    name: "Galiakot",
    state_name: "Rajasthan",
  },
  {
    name: "Ganganagar",
    state_name: "Rajasthan",
  },
  {
    name: "Gangapur",
    state_name: "Rajasthan",
  },
  {
    name: "Govindgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Gulabpura",
    state_name: "Rajasthan",
  },
  {
    name: "Hanumangarh",
    state_name: "Rajasthan",
  },
  {
    name: "Hindaun",
    state_name: "Rajasthan",
  },
  {
    name: "Jahazpur",
    state_name: "Rajasthan",
  },
  {
    name: "Jaipur",
    state_name: "Rajasthan",
  },
  {
    name: "Jaisalmer",
    state_name: "Rajasthan",
  },
  {
    name: "Jaitaran",
    state_name: "Rajasthan",
  },
  {
    name: "Jalor",
    state_name: "Rajasthan",
  },
  {
    name: "Jalore",
    state_name: "Rajasthan",
  },
  {
    name: "Jhalawar",
    state_name: "Rajasthan",
  },
  {
    name: "Jhalrapatan",
    state_name: "Rajasthan",
  },
  {
    name: "Jhunjhunun",
    state_name: "Rajasthan",
  },
  {
    name: "Jobner",
    state_name: "Rajasthan",
  },
  {
    name: "Jodhpur",
    state_name: "Rajasthan",
  },
  {
    name: "Kaman",
    state_name: "Rajasthan",
  },
  {
    name: "Kanor",
    state_name: "Rajasthan",
  },
  {
    name: "Kapren",
    state_name: "Rajasthan",
  },
  {
    name: "Karanpur",
    state_name: "Rajasthan",
  },
  {
    name: "Karauli",
    state_name: "Rajasthan",
  },
  {
    name: "Kekri",
    state_name: "Rajasthan",
  },
  {
    name: "Keshorai Patan",
    state_name: "Rajasthan",
  },
  {
    name: "Khandela",
    state_name: "Rajasthan",
  },
  {
    name: "Khanpur",
    state_name: "Rajasthan",
  },
  {
    name: "Khetri",
    state_name: "Rajasthan",
  },
  {
    name: "Kishangarh",
    state_name: "Rajasthan",
  },
  {
    name: "Kota",
    state_name: "Rajasthan",
  },
  {
    name: "Kotputli",
    state_name: "Rajasthan",
  },
  {
    name: "Kuchaman",
    state_name: "Rajasthan",
  },
  {
    name: "Kuchera",
    state_name: "Rajasthan",
  },
  {
    name: "Kumher",
    state_name: "Rajasthan",
  },
  {
    name: "Kushalgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Lachhmangarh Sikar",
    state_name: "Rajasthan",
  },
  {
    name: "Ladnun",
    state_name: "Rajasthan",
  },
  {
    name: "Lakheri",
    state_name: "Rajasthan",
  },
  {
    name: "Lalsot",
    state_name: "Rajasthan",
  },
  {
    name: "Losal",
    state_name: "Rajasthan",
  },
  {
    name: "Mahwah",
    state_name: "Rajasthan",
  },
  {
    name: "Makrana",
    state_name: "Rajasthan",
  },
  {
    name: "Malpura",
    state_name: "Rajasthan",
  },
  {
    name: "Mandal",
    state_name: "Rajasthan",
  },
  {
    name: "Mandalgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Mandawar",
    state_name: "Rajasthan",
  },
  {
    name: "Mangrol",
    state_name: "Rajasthan",
  },
  {
    name: "Manohar Thana",
    state_name: "Rajasthan",
  },
  {
    name: "Manoharpur",
    state_name: "Rajasthan",
  },
  {
    name: "Meethari Marwar",
    state_name: "Rajasthan",
  },
  {
    name: "Merta",
    state_name: "Rajasthan",
  },
  {
    name: "Mundwa",
    state_name: "Rajasthan",
  },
  {
    name: "Nadbai",
    state_name: "Rajasthan",
  },
  {
    name: "Nagar",
    state_name: "Rajasthan",
  },
  {
    name: "Nagaur",
    state_name: "Rajasthan",
  },
  {
    name: "Nainwa",
    state_name: "Rajasthan",
  },
  {
    name: "Napasar",
    state_name: "Rajasthan",
  },
  {
    name: "Naraina",
    state_name: "Rajasthan",
  },
  {
    name: "Nasirabad",
    state_name: "Rajasthan",
  },
  {
    name: "Nathdwara",
    state_name: "Rajasthan",
  },
  {
    name: "Nawa",
    state_name: "Rajasthan",
  },
  {
    name: "Nawalgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Neem ka Thana",
    state_name: "Rajasthan",
  },
  {
    name: "Nimaj",
    state_name: "Rajasthan",
  },
  {
    name: "Nimbahera",
    state_name: "Rajasthan",
  },
  {
    name: "Niwai",
    state_name: "Rajasthan",
  },
  {
    name: "Nohar",
    state_name: "Rajasthan",
  },
  {
    name: "Nokha",
    state_name: "Rajasthan",
  },
  {
    name: "Padampur",
    state_name: "Rajasthan",
  },
  {
    name: "Pali",
    state_name: "Rajasthan",
  },
  {
    name: "Partapur",
    state_name: "Rajasthan",
  },
  {
    name: "Parvatsar",
    state_name: "Rajasthan",
  },
  {
    name: "Phalodi",
    state_name: "Rajasthan",
  },
  {
    name: "Phulera",
    state_name: "Rajasthan",
  },
  {
    name: "Pilani",
    state_name: "Rajasthan",
  },
  {
    name: "Pilibangan",
    state_name: "Rajasthan",
  },
  {
    name: "Pindwara",
    state_name: "Rajasthan",
  },
  {
    name: "Pipar",
    state_name: "Rajasthan",
  },
  {
    name: "Pirawa",
    state_name: "Rajasthan",
  },
  {
    name: "Pokaran",
    state_name: "Rajasthan",
  },
  {
    name: "Pratapgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Pushkar",
    state_name: "Rajasthan",
  },
  {
    name: "Raipur",
    state_name: "Rajasthan",
  },
  {
    name: "Raisinghnagar",
    state_name: "Rajasthan",
  },
  {
    name: "Rajakhera",
    state_name: "Rajasthan",
  },
  {
    name: "Rajaldesar",
    state_name: "Rajasthan",
  },
  {
    name: "Rajgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Rajsamand",
    state_name: "Rajasthan",
  },
  {
    name: "Ramganj Mandi",
    state_name: "Rajasthan",
  },
  {
    name: "Ramgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Rani",
    state_name: "Rajasthan",
  },
  {
    name: "Ratangarh",
    state_name: "Rajasthan",
  },
  {
    name: "Rawatbhata",
    state_name: "Rajasthan",
  },
  {
    name: "Rawatsar",
    state_name: "Rajasthan",
  },
  {
    name: "Ringas",
    state_name: "Rajasthan",
  },
  {
    name: "Sadri",
    state_name: "Rajasthan",
  },
  {
    name: "Salumbar",
    state_name: "Rajasthan",
  },
  {
    name: "Sambhar",
    state_name: "Rajasthan",
  },
  {
    name: "Samdari",
    state_name: "Rajasthan",
  },
  {
    name: "Sanchor",
    state_name: "Rajasthan",
  },
  {
    name: "Sangaria",
    state_name: "Rajasthan",
  },
  {
    name: "Sangod",
    state_name: "Rajasthan",
  },
  {
    name: "Sardarshahr",
    state_name: "Rajasthan",
  },
  {
    name: "Sarwar",
    state_name: "Rajasthan",
  },
  {
    name: "Sawai Madhopur",
    state_name: "Rajasthan",
  },
  {
    name: "Shahpura",
    state_name: "Rajasthan",
  },
  {
    name: "Sheoganj",
    state_name: "Rajasthan",
  },
  {
    name: "Sikar",
    state_name: "Rajasthan",
  },
  {
    name: "Sirohi",
    state_name: "Rajasthan",
  },
  {
    name: "Siwana",
    state_name: "Rajasthan",
  },
  {
    name: "Sojat",
    state_name: "Rajasthan",
  },
  {
    name: "Sri Dungargarh",
    state_name: "Rajasthan",
  },
  {
    name: "Sri Madhopur",
    state_name: "Rajasthan",
  },
  {
    name: "Sujangarh",
    state_name: "Rajasthan",
  },
  {
    name: "Suket",
    state_name: "Rajasthan",
  },
  {
    name: "Sunel",
    state_name: "Rajasthan",
  },
  {
    name: "Surajgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Suratgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Takhatgarh",
    state_name: "Rajasthan",
  },
  {
    name: "Taranagar",
    state_name: "Rajasthan",
  },
  {
    name: "Tijara",
    state_name: "Rajasthan",
  },
  {
    name: "Todabhim",
    state_name: "Rajasthan",
  },
  {
    name: "Todaraisingh",
    state_name: "Rajasthan",
  },
  {
    name: "Tonk",
    state_name: "Rajasthan",
  },
  {
    name: "Udaipur",
    state_name: "Rajasthan",
  },
  {
    name: "Udpura",
    state_name: "Rajasthan",
  },
  {
    name: "Uniara",
    state_name: "Rajasthan",
  },
  {
    name: "Wer",
    state_name: "Rajasthan",
  },
  {
    name: "East District",
    state_name: "Sikkim",
  },
  {
    name: "Gangtok",
    state_name: "Sikkim",
  },
  {
    name: "Gyalshing",
    state_name: "Sikkim",
  },
  {
    name: "Jorethang",
    state_name: "Sikkim",
  },
  {
    name: "Mangan",
    state_name: "Sikkim",
  },
  {
    name: "Namchi",
    state_name: "Sikkim",
  },
  {
    name: "Naya Bazar",
    state_name: "Sikkim",
  },
  {
    name: "North District",
    state_name: "Sikkim",
  },
  {
    name: "Rangpo",
    state_name: "Sikkim",
  },
  {
    name: "Singtam",
    state_name: "Sikkim",
  },
  {
    name: "South District",
    state_name: "Sikkim",
  },
  {
    name: "West District",
    state_name: "Sikkim",
  },
  {
    name: "Abiramam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Adirampattinam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Aduthurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alagapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alandur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alanganallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alangayam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alangudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alangulam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alappakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Alwa Tirunagari",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ambasamudram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ambattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ambur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ammapettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Anamalais",
    state_name: "Tamil Nadu",
  },
  {
    name: "Andippatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Annamalainagar",
    state_name: "Tamil Nadu",
  },
  {
    name: "Annavasal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Annur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Anthiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arakkonam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arantangi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arcot",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arimalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ariyalur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arni",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arumbavur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Arumuganeri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Aruppukkottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Aruvankad",
    state_name: "Tamil Nadu",
  },
  {
    name: "Attayyampatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Attur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Auroville",
    state_name: "Tamil Nadu",
  },
  {
    name: "Avadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Avinashi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ayakudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ayyampettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Belur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Bhavani",
    state_name: "Tamil Nadu",
  },
  {
    name: "Bodinayakkanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chengam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chennai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chennimalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chetput",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chettipalaiyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Cheyyar",
    state_name: "Tamil Nadu",
  },
  {
    name: "Cheyyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chidambaram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chingleput",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chinna Salem",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chinnamanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Chinnasekkadu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Cholapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Coimbatore",
    state_name: "Tamil Nadu",
  },
  {
    name: "Colachel",
    state_name: "Tamil Nadu",
  },
  {
    name: "Cuddalore",
    state_name: "Tamil Nadu",
  },
  {
    name: "Cumbum",
    state_name: "Tamil Nadu",
  },
  {
    name: "Denkanikota",
    state_name: "Tamil Nadu",
  },
  {
    name: "Desur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Devadanappatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Devakottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Dhali",
    state_name: "Tamil Nadu",
  },
  {
    name: "Dharapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Dharmapuri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Dindigul",
    state_name: "Tamil Nadu",
  },
  {
    name: "Dusi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Elayirampannai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Elumalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Eral",
    state_name: "Tamil Nadu",
  },
  {
    name: "Eraniel",
    state_name: "Tamil Nadu",
  },
  {
    name: "Erode",
    state_name: "Tamil Nadu",
  },
  {
    name: "Erumaippatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ettaiyapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gandhi Nagar",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gangaikondan",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gangavalli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gingee",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gobichettipalayam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gudalur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gudiyatham",
    state_name: "Tamil Nadu",
  },
  {
    name: "Guduvancheri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Gummidipundi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Harur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Hosur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Idappadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ilampillai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Iluppur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Injambakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Irugur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Jalakandapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Jalarpet",
    state_name: "Tamil Nadu",
  },
  {
    name: "Jayamkondacholapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kadambur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kadayanallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kalakkadu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kalavai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kallakkurichchi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kallidaikurichi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kallupatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kalugumalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kamuthi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kanadukattan",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kanchipuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kangayam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kanniyakumari",
    state_name: "Tamil Nadu",
  },
  {
    name: "Karaikkudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Karamadai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Karambakkudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kariapatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Karumbakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Karur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Katpadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kattivakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kattupputtur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kaveripatnam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kayalpattinam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kayattar",
    state_name: "Tamil Nadu",
  },
  {
    name: "Keelakarai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kelamangalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kil Bhuvanagiri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kilvelur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kiranur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kodaikanal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kodumudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kombai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Konganapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Koothanallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Koradachcheri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Korampallam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kotagiri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kottaiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kovilpatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Krishnagiri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kulattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kulittalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kumaralingam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kumbakonam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kunnattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kurinjippadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kuttalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Kuzhithurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Lalgudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madambakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madipakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madukkarai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madukkur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Madurantakam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mallapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mallasamudram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manali",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manalurpettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manamadurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manappakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manapparai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Manavalakurichi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mandapam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mangalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mannargudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Marakkanam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Marandahalli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Masinigudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mayiladuthurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Melur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mettuppalaiyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mettur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Minjur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mohanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mudukulattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Mulanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Musiri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Muttupet",
    state_name: "Tamil Nadu",
  },
  {
    name: "Naduvattam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nagapattinam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nagercoil",
    state_name: "Tamil Nadu",
  },
  {
    name: "Namagiripettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Namakkal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nambiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nambutalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nandambakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nangavalli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nangilickondan",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nanguneri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nannilam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Naravarikuppam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nattam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nattarasankottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Needamangalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Neelankarai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Negapatam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nellikkuppam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nilakottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Nilgiris",
    state_name: "Tamil Nadu",
  },
  {
    name: "Odugattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Omalur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ooty",
    state_name: "Tamil Nadu",
  },
  {
    name: "Padmanabhapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Palakkodu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Palamedu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Palani",
    state_name: "Tamil Nadu",
  },
  {
    name: "Palavakkam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Palladam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallappatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallavaram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallikondai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallipattu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pallippatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Panruti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Papanasam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Papireddippatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Papparappatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Paramagudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pattukkottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pennadam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pennagaram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pennathur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Peraiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Perambalur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Peranamallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Peranampattu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Peravurani",
    state_name: "Tamil Nadu",
  },
  {
    name: "Periyakulam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Periyanayakkanpalaiyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Periyanegamam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Periyapatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Periyapattinam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Perundurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Perungudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Perur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pollachi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Polur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ponnamaravati",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ponneri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Poonamalle",
    state_name: "Tamil Nadu",
  },
  {
    name: "Porur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pudukkottai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Puduppatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pudur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Puduvayal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Puliyangudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Puliyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Pullambadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Punjai Puliyampatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Rajapalaiyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ramanathapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Rameswaram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Ranipet",
    state_name: "Tamil Nadu",
  },
  {
    name: "Rasipuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Saint Thomas Mount",
    state_name: "Tamil Nadu",
  },
  {
    name: "Salem",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sathankulam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sathyamangalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sattur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sayalkudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Seven Pagodas",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sholinghur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Singanallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Singapperumalkovil",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sirkazhi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sirumugai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sivaganga",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sivagiri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sivakasi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Srimushnam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sriperumbudur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Srivaikuntam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Srivilliputhur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Suchindram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Sulur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Surandai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Swamimalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tambaram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tanjore",
    state_name: "Tamil Nadu",
  },
  {
    name: "Taramangalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tattayyangarpettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thanjavur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tharangambadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Theni",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thenkasi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thirukattupalli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thiruthani",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thiruvaiyaru",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thiruvallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thiruvarur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thiruvidaimaruthur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Thoothukudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tindivanam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tinnanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruchchendur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruchengode",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruchirappalli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tirukkoyilur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tirumullaivasal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tirunelveli",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tirunelveli Kattabo",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruppalaikudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tirupparangunram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruppur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruppuvanam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruttangal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruvannamalai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tiruvottiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tisaiyanvilai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Tondi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Turaiyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Udangudi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Udumalaippettai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Uppiliyapuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Usilampatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Uttamapalaiyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Uttiramerur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Uttukkuli",
    state_name: "Tamil Nadu",
  },
  {
    name: "V.S.K.Valasai (Dindigul-Dist.)",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vadakku Valliyur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vadakku Viravanallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vadamadurai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vadippatti",
    state_name: "Tamil Nadu",
  },
  {
    name: "Valangaiman",
    state_name: "Tamil Nadu",
  },
  {
    name: "Valavanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vallam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Valparai",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vandalur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vandavasi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vaniyambadi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vasudevanallur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vattalkundu",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vedaraniyam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vedasandur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Velankanni",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vellanur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vellore",
    state_name: "Tamil Nadu",
  },
  {
    name: "Velur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vengavasal",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vettaikkaranpudur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vettavalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vijayapuri",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vikravandi",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vilattikulam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Villupuram",
    state_name: "Tamil Nadu",
  },
  {
    name: "Viraganur",
    state_name: "Tamil Nadu",
  },
  {
    name: "Virudhunagar",
    state_name: "Tamil Nadu",
  },
  {
    name: "Vriddhachalam",
    state_name: "Tamil Nadu",
  },
  {
    name: "Walajapet",
    state_name: "Tamil Nadu",
  },
  {
    name: "Wallajahbad",
    state_name: "Tamil Nadu",
  },
  {
    name: "Wellington",
    state_name: "Tamil Nadu",
  },
  {
    name: "Adilabad",
    state_name: "Telangana",
  },
  {
    name: "Alampur",
    state_name: "Telangana",
  },
  {
    name: "Andol",
    state_name: "Telangana",
  },
  {
    name: "Asifabad",
    state_name: "Telangana",
  },
  {
    name: "Balapur",
    state_name: "Telangana",
  },
  {
    name: "Banswada",
    state_name: "Telangana",
  },
  {
    name: "Bellampalli",
    state_name: "Telangana",
  },
  {
    name: "Bhadrachalam",
    state_name: "Telangana",
  },
  {
    name: "Bhadradri Kothagudem",
    state_name: "Telangana",
  },
  {
    name: "Bhaisa",
    state_name: "Telangana",
  },
  {
    name: "Bhongir",
    state_name: "Telangana",
  },
  {
    name: "Bodhan",
    state_name: "Telangana",
  },
  {
    name: "Chandur",
    state_name: "Telangana",
  },
  {
    name: "Chatakonda",
    state_name: "Telangana",
  },
  {
    name: "Dasnapur",
    state_name: "Telangana",
  },
  {
    name: "Devarkonda",
    state_name: "Telangana",
  },
  {
    name: "Dornakal",
    state_name: "Telangana",
  },
  {
    name: "Farrukhnagar",
    state_name: "Telangana",
  },
  {
    name: "Gaddi Annaram",
    state_name: "Telangana",
  },
  {
    name: "Gadwal",
    state_name: "Telangana",
  },
  {
    name: "Ghatkesar",
    state_name: "Telangana",
  },
  {
    name: "Gopalur",
    state_name: "Telangana",
  },
  {
    name: "Gudur",
    state_name: "Telangana",
  },
  {
    name: "Hyderabad",
    state_name: "Telangana",
  },
  {
    name: "Jagtial",
    state_name: "Telangana",
  },
  {
    name: "Jangaon",
    state_name: "Telangana",
  },
  {
    name: "Jayashankar Bhupalapally",
    state_name: "Telangana",
  },
  {
    name: "Jogulamba Gadwal",
    state_name: "Telangana",
  },
  {
    name: "Kagaznagar",
    state_name: "Telangana",
  },
  {
    name: "Kamareddi",
    state_name: "Telangana",
  },
  {
    name: "Kamareddy",
    state_name: "Telangana",
  },
  {
    name: "Karimnagar",
    state_name: "Telangana",
  },
  {
    name: "Khammam",
    state_name: "Telangana",
  },
  {
    name: "Kodar",
    state_name: "Telangana",
  },
  {
    name: "Koratla",
    state_name: "Telangana",
  },
  {
    name: "Kothapet",
    state_name: "Telangana",
  },
  {
    name: "Kottagudem",
    state_name: "Telangana",
  },
  {
    name: "Kottapalli",
    state_name: "Telangana",
  },
  {
    name: "Kukatpally",
    state_name: "Telangana",
  },
  {
    name: "Kyathampalle",
    state_name: "Telangana",
  },
  {
    name: "Lakshettipet",
    state_name: "Telangana",
  },
  {
    name: "Lal Bahadur Nagar",
    state_name: "Telangana",
  },
  {
    name: "Mahabubabad",
    state_name: "Telangana",
  },
  {
    name: "Mahbubnagar",
    state_name: "Telangana",
  },
  {
    name: "Malkajgiri",
    state_name: "Telangana",
  },
  {
    name: "Mancheral",
    state_name: "Telangana",
  },
  {
    name: "Mandamarri",
    state_name: "Telangana",
  },
  {
    name: "Manthani",
    state_name: "Telangana",
  },
  {
    name: "Manuguru",
    state_name: "Telangana",
  },
  {
    name: "Medak",
    state_name: "Telangana",
  },
  {
    name: "Medchal",
    state_name: "Telangana",
  },
  {
    name: "Medchal Malkajgiri",
    state_name: "Telangana",
  },
  {
    name: "Mirialguda",
    state_name: "Telangana",
  },
  {
    name: "Nagar Karnul",
    state_name: "Telangana",
  },
  {
    name: "Nalgonda",
    state_name: "Telangana",
  },
  {
    name: "Narayanpet",
    state_name: "Telangana",
  },
  {
    name: "Narsingi",
    state_name: "Telangana",
  },
  {
    name: "Naspur",
    state_name: "Telangana",
  },
  {
    name: "Nirmal",
    state_name: "Telangana",
  },
  {
    name: "Nizamabad",
    state_name: "Telangana",
  },
  {
    name: "Paloncha",
    state_name: "Telangana",
  },
  {
    name: "Palwancha",
    state_name: "Telangana",
  },
  {
    name: "Patancheru",
    state_name: "Telangana",
  },
  {
    name: "Peddapalli",
    state_name: "Telangana",
  },
  {
    name: "Quthbullapur",
    state_name: "Telangana",
  },
  {
    name: "Rajanna Sircilla",
    state_name: "Telangana",
  },
  {
    name: "Ramagundam",
    state_name: "Telangana",
  },
  {
    name: "Ramgundam",
    state_name: "Telangana",
  },
  {
    name: "Rangareddi",
    state_name: "Telangana",
  },
  {
    name: "Sadaseopet",
    state_name: "Telangana",
  },
  {
    name: "Sangareddi",
    state_name: "Telangana",
  },
  {
    name: "Sathupalli",
    state_name: "Telangana",
  },
  {
    name: "Secunderabad",
    state_name: "Telangana",
  },
  {
    name: "Serilingampalle",
    state_name: "Telangana",
  },
  {
    name: "Siddipet",
    state_name: "Telangana",
  },
  {
    name: "Singapur",
    state_name: "Telangana",
  },
  {
    name: "Sirpur",
    state_name: "Telangana",
  },
  {
    name: "Sirsilla",
    state_name: "Telangana",
  },
  {
    name: "Sriramnagar",
    state_name: "Telangana",
  },
  {
    name: "Suriapet",
    state_name: "Telangana",
  },
  {
    name: "Tandur",
    state_name: "Telangana",
  },
  {
    name: "Uppal Kalan",
    state_name: "Telangana",
  },
  {
    name: "Vemalwada",
    state_name: "Telangana",
  },
  {
    name: "Vikarabad",
    state_name: "Telangana",
  },
  {
    name: "Wanparti",
    state_name: "Telangana",
  },
  {
    name: "Warangal",
    state_name: "Telangana",
  },
  {
    name: "Yellandu",
    state_name: "Telangana",
  },
  {
    name: "Zahirabad",
    state_name: "Telangana",
  },
  {
    name: "Agartala",
    state_name: "Tripura",
  },
  {
    name: "Amarpur",
    state_name: "Tripura",
  },
  {
    name: "Ambasa",
    state_name: "Tripura",
  },
  {
    name: "Barjala",
    state_name: "Tripura",
  },
  {
    name: "Belonia",
    state_name: "Tripura",
  },
  {
    name: "Dhalai",
    state_name: "Tripura",
  },
  {
    name: "Dharmanagar",
    state_name: "Tripura",
  },
  {
    name: "Gomati",
    state_name: "Tripura",
  },
  {
    name: "Kailashahar",
    state_name: "Tripura",
  },
  {
    name: "Kamalpur",
    state_name: "Tripura",
  },
  {
    name: "Khowai",
    state_name: "Tripura",
  },
  {
    name: "North Tripura",
    state_name: "Tripura",
  },
  {
    name: "Ranir Bazar",
    state_name: "Tripura",
  },
  {
    name: "Sabrum",
    state_name: "Tripura",
  },
  {
    name: "Sonamura",
    state_name: "Tripura",
  },
  {
    name: "South Tripura",
    state_name: "Tripura",
  },
  {
    name: "Udaipur",
    state_name: "Tripura",
  },
  {
    name: "Unakoti",
    state_name: "Tripura",
  },
  {
    name: "West Tripura",
    state_name: "Tripura",
  },
  {
    name: "Achhnera",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Afzalgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Agra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ahraura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Aidalpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Airwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Akbarpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Akola",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Aliganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Aligarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Allahabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Allahganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Amanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Amauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ambahta",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ambedkar Nagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Amethi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Amroha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Anandnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Antu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Anupshahr",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Aonla",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Araul",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Asalatganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Atarra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Atrauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Atraulia",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Auraiya",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Auras",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ayodhya",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Azamgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Azizpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baberu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Babina",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Babrala",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Babugarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bachhraon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bachhrawan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baghpat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baghra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bah",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baheri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bahjoi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bahraich",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bahraigh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bahsuma",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bahua",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bajna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bakewar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baksar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Balamau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baldeo",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baldev",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ballia",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Balrampur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Banat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Banbasa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Banda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bangarmau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bansdih",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bansgaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bansi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Banthra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bara Banki",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baragaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Baraut",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bareilly",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barhalganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barkhera",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barkhera Kalan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barokhar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barsana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Barwar (Lakhimpur Kheri)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Basti",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Behat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bela",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Belthara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Beniganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Beswan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bewar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhadarsa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhadohi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhagwantnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bharatpura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhargain",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bharthana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bharwari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhaupur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhimtal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhinga",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhognipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bhongaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bidhnu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bidhuna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bighapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bighapur Khurd",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bijnor",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bikapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilariaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilaspur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilgram",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilhaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilsanda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilsi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bilthra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Binauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Binaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bindki",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Birdpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Birpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bisalpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bisanda Buzurg",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bisauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bisenda Buzurg",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bishunpur Urf Maharajganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Biswan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bithur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Budaun",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Budhana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Bulandshahr",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Captainganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chail",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chakia",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chandauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chandauli District",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chandausi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chandpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chanduasi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Charkhari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Charthawal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chhaprauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chharra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chhata",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chhibramau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chhitauni",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chhutmalpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chillupar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chirgaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chitrakoot",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chitrakoot Dham",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chopan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Chunar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Churk",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Colonelganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dadri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dalmau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dankaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Daraganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Daranagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dasna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dataganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Daurala",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dayal Bagh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Deoband",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Deogarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Deoranian",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Deoria",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Derapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dewa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dhampur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dhanaura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dhanghata",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dharau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dhaurahra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dibai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Divrasai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dohrighat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Domariaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dostpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Dudhi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Etah",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Etawah",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Etmadpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Faizabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Farah",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Faridnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Faridpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Farrukhabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehganj West",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehpur (Barabanki)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehpur Chaurasi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fatehpur Sikri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Firozabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Fyzabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gahlon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gahmar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gaini",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gajraula",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gangoh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ganj Dundawara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ganj Dundwara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ganj Muradabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Garautha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Garhi Pukhta",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Garhmuktesar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Garhwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gauriganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gautam Buddha Nagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gawan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghatampur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghaziabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghazipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghiror",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghorawal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ghosi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gohand",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gokul",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gola Bazar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gola Gokarannath",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gonda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gopamau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gorakhpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gosainganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Goshainganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Govardhan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Greater Noida",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gulaothi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gunnaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gursahaiganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gursarai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Gyanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Haldaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hamirpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Handia",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Haraipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Haraiya",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Harchandpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hardoi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Harduaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hasanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hastinapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hata",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hata (India)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hathras",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Hulas",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ibrahimpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Iglas",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ikauna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Indergarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Indragarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Islamnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Islamnagar (Badaun)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Itaunja",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Itimadpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jagdishpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jagnair",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jahanabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jahanabad (Pilibhit)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jahangirabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jahangirpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jainpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jais",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jalalabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jalali",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jalalpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jalaun",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jalesar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Janghai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jansath",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jarwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jarwal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jasrana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jaswantnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jaunpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jewar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhajhar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhalu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhansi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhinjhak",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhinjhana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jhusi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jiyanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Jyotiba Phule Nagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kabrai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kachhwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kadaura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kadipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kagarol",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kaimganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kairana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kakori",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kakrala",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kalinagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kalpi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kalyanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kamalganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kampil",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kandhla",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kannauj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kanpur Dehat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kant",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kanth",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kaptanganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Karari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Karbigwan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Karchana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Karhal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kasganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Katra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kausani",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kaushambi District",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kemri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khada",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khaga",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khailar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khair",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khairabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khalilabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kharela",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khargupur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kharkhauda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khatauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khekra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kheri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khudaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khurja",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Khutar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kirakat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kiraoli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kiratpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kishanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kishanpur baral",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kishni",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kithor",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Konch",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kopaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kosi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kota",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kotra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kuchesar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kudarkot",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kulpahar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kunda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kundarkhi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kundarki",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kurara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kurebharsaidkhanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kushinagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kusmara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Kuthaund",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Laharpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lakhimpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lakhna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lalganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lalitpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lambhua",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lawar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lawar Khas",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Loni",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lucknow",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Lucknow District",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Machhali Shahar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Machhlishahr",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Madhoganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Madhogarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maghar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahaban",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maharajganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahmudabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahoba",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maholi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahrajganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahrajganj (Raebareli)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahroni",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mahul",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mailani",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mainpuri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Majhupur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Makanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Malasa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Malihabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mandawar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maniar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Manikpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Manjhanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mankapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Marahra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mariahu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mataundh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mathura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mau Aima",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mau Aimma",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maudaha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Maurawan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mawana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mawar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Meerut",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mehdawal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mehnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mehndawal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Milak",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Milkipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Miranpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Miranpur Katra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mirganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mirzapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Misrikh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mohan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mohanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Moradabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Moth",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mubarakpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mughal Sarai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Muhammadabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mukteshwar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mungra Badshahpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Munsyari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Muradabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Muradnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Mursan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Musafir-Khana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Musafirkhana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Muzaffarnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nadigaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nagina",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nagla",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nagram",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Najibabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nakur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nanauta",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nandgaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nanpara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Narauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Naraura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Narora",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Naugama",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Naurangpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nautanwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nawabganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nawabganj (Barabanki)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nawabganj (Bareilly)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Newara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nichlaul",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nigoh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nihtaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Niwari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nizamabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Noida",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Nurpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Obra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Orai",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Oran",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pachperwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Padrauna",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pahasu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Paigaon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pali",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Palia Kalan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Paras Rampur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Parichha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Parichhatgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Parshadepur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pathakpura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Patiali",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Patti",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pawayan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Payagpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Phalauda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Phaphamau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Phaphund",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Phariha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pheona",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Phulpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pichhaura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pihani",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pilibhit",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pilkhua",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pilkhuwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pinahat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pipraich",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pipri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pratapgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Prayagraj (Allahabad)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Pukhrayan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Puranpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Purmafi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Purwa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Qadirganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rabupura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Radha Kund",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Radhakund",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Raebareli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rajapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ramkola",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ramnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rampur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rampura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ranipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ranipur Barsi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rasra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rasulabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rath",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Raya",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rehar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Renukoot",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Renukut",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Reoti",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Reotipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Richha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Robertsganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rudarpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rudauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Rura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sabalpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sachendi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sadabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sadat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Safipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Saharanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sahaspur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sahaswan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sahawar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sahibabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sahpau",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Saidpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sakhanu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sakit",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Salempur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Salon",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sambhal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Samthar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sandi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sandila",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sant Kabir Nagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sant Ravi Das Nagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarai Akil",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarai Ekdil",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarai Mir",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sardhana",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarila",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sarurpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sasni",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Satrikh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Saurikh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sector",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Seohara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shahabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shahganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shahi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shahjahanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shahpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shamli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shamsabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shankargarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shergarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sherkot",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shibnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shikarpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shikarpur (Bulandshahr)",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shikohabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shishgarh",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shivrajpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Shrawasti",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Siddharthnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Siddhaur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sidhauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sidhpura",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sikandarabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sikandarpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sikandra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sikandra Rao",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sikandrabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sirathu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sirsa",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sirsaganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sirsi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sisauli",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Siswa Bazar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sitapur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sonbhadra",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Soron",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Suar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Sultanpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Surianwan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tajpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Talbahat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Talgram",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tanda",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Terha",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Thakurdwara",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Thana Bhawan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tigri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tikaitnagar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tikri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tilhar",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tilsahri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tindwari",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Titron",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tori Fatehpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tori-Fatehpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tulsipur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Tundla",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ugu",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Ujhani",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Umri",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Un",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Unnao",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Usawan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Usehat",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Uska",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Utraula",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Varanasi",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Vindhyachal",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Vrindavan",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Walterganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Wazirganj",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Yusufpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Zafarabad",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Zaidpur",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Zamania",
    state_name: "Uttar Pradesh",
  },
  {
    name: "Almora",
    state_name: "Uttarakhand",
  },
  {
    name: "Bageshwar",
    state_name: "Uttarakhand",
  },
  {
    name: "Barkot",
    state_name: "Uttarakhand",
  },
  {
    name: "Bazpur",
    state_name: "Uttarakhand",
  },
  {
    name: "Bhim Tal",
    state_name: "Uttarakhand",
  },
  {
    name: "Bhowali",
    state_name: "Uttarakhand",
  },
  {
    name: "Birbhaddar",
    state_name: "Uttarakhand",
  },
  {
    name: "Chakrata",
    state_name: "Uttarakhand",
  },
  {
    name: "Chamoli",
    state_name: "Uttarakhand",
  },
  {
    name: "Champawat",
    state_name: "Uttarakhand",
  },
  {
    name: "Clement Town",
    state_name: "Uttarakhand",
  },
  {
    name: "Dehradun",
    state_name: "Uttarakhand",
  },
  {
    name: "Devaprayag",
    state_name: "Uttarakhand",
  },
  {
    name: "Dharchula",
    state_name: "Uttarakhand",
  },
  {
    name: "Doiwala",
    state_name: "Uttarakhand",
  },
  {
    name: "Dugadda",
    state_name: "Uttarakhand",
  },
  {
    name: "Dwarahat",
    state_name: "Uttarakhand",
  },
  {
    name: "Garhwal",
    state_name: "Uttarakhand",
  },
  {
    name: "Haldwani",
    state_name: "Uttarakhand",
  },
  {
    name: "Harbatpur",
    state_name: "Uttarakhand",
  },
  {
    name: "Haridwar",
    state_name: "Uttarakhand",
  },
  {
    name: "Jaspur",
    state_name: "Uttarakhand",
  },
  {
    name: "Joshimath",
    state_name: "Uttarakhand",
  },
  {
    name: "Kaladhungi",
    state_name: "Uttarakhand",
  },
  {
    name: "Kalagarh Project Colony",
    state_name: "Uttarakhand",
  },
  {
    name: "Kashipur",
    state_name: "Uttarakhand",
  },
  {
    name: "Khatima",
    state_name: "Uttarakhand",
  },
  {
    name: "Kichha",
    state_name: "Uttarakhand",
  },
  {
    name: "Kotdwara",
    state_name: "Uttarakhand",
  },
  {
    name: "Laksar",
    state_name: "Uttarakhand",
  },
  {
    name: "Lansdowne",
    state_name: "Uttarakhand",
  },
  {
    name: "Lohaghat",
    state_name: "Uttarakhand",
  },
  {
    name: "Manglaur",
    state_name: "Uttarakhand",
  },
  {
    name: "Mussoorie",
    state_name: "Uttarakhand",
  },
  {
    name: "Naini Tal",
    state_name: "Uttarakhand",
  },
  {
    name: "Narendranagar",
    state_name: "Uttarakhand",
  },
  {
    name: "Pauri",
    state_name: "Uttarakhand",
  },
  {
    name: "Pipalkoti",
    state_name: "Uttarakhand",
  },
  {
    name: "Pithoragarh",
    state_name: "Uttarakhand",
  },
  {
    name: "Raipur",
    state_name: "Uttarakhand",
  },
  {
    name: "Raiwala Bara",
    state_name: "Uttarakhand",
  },
  {
    name: "Ramnagar",
    state_name: "Uttarakhand",
  },
  {
    name: "Ranikhet",
    state_name: "Uttarakhand",
  },
  {
    name: "Rishikesh",
    state_name: "Uttarakhand",
  },
  {
    name: "Roorkee",
    state_name: "Uttarakhand",
  },
  {
    name: "Rudraprayag",
    state_name: "Uttarakhand",
  },
  {
    name: "Sitarganj",
    state_name: "Uttarakhand",
  },
  {
    name: "Srinagar",
    state_name: "Uttarakhand",
  },
  {
    name: "Sultanpur",
    state_name: "Uttarakhand",
  },
  {
    name: "Tanakpur",
    state_name: "Uttarakhand",
  },
  {
    name: "Tehri",
    state_name: "Uttarakhand",
  },
  {
    name: "Tehri-Garhwal",
    state_name: "Uttarakhand",
  },
  {
    name: "Udham Singh Nagar",
    state_name: "Uttarakhand",
  },
  {
    name: "Uttarkashi",
    state_name: "Uttarakhand",
  },
  {
    name: "Vikasnagar",
    state_name: "Uttarakhand",
  },
  {
    name: "Adra",
    state_name: "West Bengal",
  },
  {
    name: "Ahmedpur",
    state_name: "West Bengal",
  },
  {
    name: "Aistala",
    state_name: "West Bengal",
  },
  {
    name: "Aknapur",
    state_name: "West Bengal",
  },
  {
    name: "Alipurduar",
    state_name: "West Bengal",
  },
  {
    name: "Amlagora",
    state_name: "West Bengal",
  },
  {
    name: "Amta",
    state_name: "West Bengal",
  },
  {
    name: "Amtala",
    state_name: "West Bengal",
  },
  {
    name: "Andal",
    state_name: "West Bengal",
  },
  {
    name: "Arambagh community development block",
    state_name: "West Bengal",
  },
  {
    name: "Asansol",
    state_name: "West Bengal",
  },
  {
    name: "Ashoknagar Kalyangarh",
    state_name: "West Bengal",
  },
  {
    name: "Badkulla",
    state_name: "West Bengal",
  },
  {
    name: "Baduria",
    state_name: "West Bengal",
  },
  {
    name: "Bagdogra",
    state_name: "West Bengal",
  },
  {
    name: "Bagnan",
    state_name: "West Bengal",
  },
  {
    name: "Bagula",
    state_name: "West Bengal",
  },
  {
    name: "Bahula",
    state_name: "West Bengal",
  },
  {
    name: "Baidyabati",
    state_name: "West Bengal",
  },
  {
    name: "Bakreswar",
    state_name: "West Bengal",
  },
  {
    name: "Balarampur",
    state_name: "West Bengal",
  },
  {
    name: "Bali Chak",
    state_name: "West Bengal",
  },
  {
    name: "Bally",
    state_name: "West Bengal",
  },
  {
    name: "Balurghat",
    state_name: "West Bengal",
  },
  {
    name: "Bamangola community development block",
    state_name: "West Bengal",
  },
  {
    name: "Baneswar",
    state_name: "West Bengal",
  },
  {
    name: "Bangaon",
    state_name: "West Bengal",
  },
  {
    name: "Bankra",
    state_name: "West Bengal",
  },
  {
    name: "Bankura",
    state_name: "West Bengal",
  },
  {
    name: "Bansberia",
    state_name: "West Bengal",
  },
  {
    name: "Bansihari community development block",
    state_name: "West Bengal",
  },
  {
    name: "Barabazar",
    state_name: "West Bengal",
  },
  {
    name: "Baranagar",
    state_name: "West Bengal",
  },
  {
    name: "Barasat",
    state_name: "West Bengal",
  },
  {
    name: "Barddhaman",
    state_name: "West Bengal",
  },
  {
    name: "Barjora",
    state_name: "West Bengal",
  },
  {
    name: "Barrackpore",
    state_name: "West Bengal",
  },
  {
    name: "Baruipur",
    state_name: "West Bengal",
  },
  {
    name: "Basanti",
    state_name: "West Bengal",
  },
  {
    name: "Basirhat",
    state_name: "West Bengal",
  },
  {
    name: "Bawali",
    state_name: "West Bengal",
  },
  {
    name: "Begampur",
    state_name: "West Bengal",
  },
  {
    name: "Belda",
    state_name: "West Bengal",
  },
  {
    name: "Beldanga",
    state_name: "West Bengal",
  },
  {
    name: "Beliatore",
    state_name: "West Bengal",
  },
  {
    name: "Berhampore",
    state_name: "West Bengal",
  },
  {
    name: "Bhadreswar",
    state_name: "West Bengal",
  },
  {
    name: "Bhandardaha",
    state_name: "West Bengal",
  },
  {
    name: "Bhatpara",
    state_name: "West Bengal",
  },
  {
    name: "Birbhum district",
    state_name: "West Bengal",
  },
  {
    name: "Birpara",
    state_name: "West Bengal",
  },
  {
    name: "Bishnupur",
    state_name: "West Bengal",
  },
  {
    name: "Bolpur",
    state_name: "West Bengal",
  },
  {
    name: "Budge Budge",
    state_name: "West Bengal",
  },
  {
    name: "Canning",
    state_name: "West Bengal",
  },
  {
    name: "Chakapara",
    state_name: "West Bengal",
  },
  {
    name: "Chakdaha",
    state_name: "West Bengal",
  },
  {
    name: "Champadanga",
    state_name: "West Bengal",
  },
  {
    name: "Champahati",
    state_name: "West Bengal",
  },
  {
    name: "Champdani",
    state_name: "West Bengal",
  },
  {
    name: "Chandannagar",
    state_name: "West Bengal",
  },
  {
    name: "Chandrakona",
    state_name: "West Bengal",
  },
  {
    name: "Chittaranjan",
    state_name: "West Bengal",
  },
  {
    name: "Churulia",
    state_name: "West Bengal",
  },
  {
    name: "Contai",
    state_name: "West Bengal",
  },
  {
    name: "Cooch Behar",
    state_name: "West Bengal",
  },
  {
    name: "Cossimbazar",
    state_name: "West Bengal",
  },
  {
    name: "Dakshin Dinajpur district",
    state_name: "West Bengal",
  },
  {
    name: "Dalkola",
    state_name: "West Bengal",
  },
  {
    name: "Dam Dam",
    state_name: "West Bengal",
  },
  {
    name: "Darjeeling",
    state_name: "West Bengal",
  },
  {
    name: "Daulatpur",
    state_name: "West Bengal",
  },
  {
    name: "Debagram",
    state_name: "West Bengal",
  },
  {
    name: "Debipur",
    state_name: "West Bengal",
  },
  {
    name: "Dhaniakhali community development block",
    state_name: "West Bengal",
  },
  {
    name: "Dhulagari",
    state_name: "West Bengal",
  },
  {
    name: "Dhulian",
    state_name: "West Bengal",
  },
  {
    name: "Dhupguri",
    state_name: "West Bengal",
  },
  {
    name: "Diamond Harbour",
    state_name: "West Bengal",
  },
  {
    name: "Digha",
    state_name: "West Bengal",
  },
  {
    name: "Dinhata",
    state_name: "West Bengal",
  },
  {
    name: "Domjur",
    state_name: "West Bengal",
  },
  {
    name: "Dubrajpur",
    state_name: "West Bengal",
  },
  {
    name: "Durgapur",
    state_name: "West Bengal",
  },
  {
    name: "Egra",
    state_name: "West Bengal",
  },
  {
    name: "Falakata",
    state_name: "West Bengal",
  },
  {
    name: "Farakka",
    state_name: "West Bengal",
  },
  {
    name: "Fort Gloster",
    state_name: "West Bengal",
  },
  {
    name: "Gaighata community development block",
    state_name: "West Bengal",
  },
  {
    name: "Gairkata",
    state_name: "West Bengal",
  },
  {
    name: "Gangadharpur",
    state_name: "West Bengal",
  },
  {
    name: "Gangarampur",
    state_name: "West Bengal",
  },
  {
    name: "Garui",
    state_name: "West Bengal",
  },
  {
    name: "Garulia",
    state_name: "West Bengal",
  },
  {
    name: "Ghatal",
    state_name: "West Bengal",
  },
  {
    name: "Giria",
    state_name: "West Bengal",
  },
  {
    name: "Gobardanga",
    state_name: "West Bengal",
  },
  {
    name: "Gobindapur",
    state_name: "West Bengal",
  },
  {
    name: "Gopalpur",
    state_name: "West Bengal",
  },
  {
    name: "Gopinathpur",
    state_name: "West Bengal",
  },
  {
    name: "Gorubathan",
    state_name: "West Bengal",
  },
  {
    name: "Gosaba",
    state_name: "West Bengal",
  },
  {
    name: "Gosanimari",
    state_name: "West Bengal",
  },
  {
    name: "Gurdaha",
    state_name: "West Bengal",
  },
  {
    name: "Guskhara",
    state_name: "West Bengal",
  },
  {
    name: "Habra",
    state_name: "West Bengal",
  },
  {
    name: "Haldia",
    state_name: "West Bengal",
  },
  {
    name: "Haldibari",
    state_name: "West Bengal",
  },
  {
    name: "Halisahar",
    state_name: "West Bengal",
  },
  {
    name: "Harindanga",
    state_name: "West Bengal",
  },
  {
    name: "Haringhata",
    state_name: "West Bengal",
  },
  {
    name: "Haripur",
    state_name: "West Bengal",
  },
  {
    name: "Hasimara",
    state_name: "West Bengal",
  },
  {
    name: "Hindusthan Cables Town",
    state_name: "West Bengal",
  },
  {
    name: "Hooghly district",
    state_name: "West Bengal",
  },
  {
    name: "Howrah",
    state_name: "West Bengal",
  },
  {
    name: "Ichapur",
    state_name: "West Bengal",
  },
  {
    name: "Indpur community development block",
    state_name: "West Bengal",
  },
  {
    name: "Ingraj Bazar",
    state_name: "West Bengal",
  },
  {
    name: "Islampur",
    state_name: "West Bengal",
  },
  {
    name: "Jafarpur",
    state_name: "West Bengal",
  },
  {
    name: "Jaigaon",
    state_name: "West Bengal",
  },
  {
    name: "Jalpaiguri",
    state_name: "West Bengal",
  },
  {
    name: "Jamuria",
    state_name: "West Bengal",
  },
  {
    name: "Jangipur",
    state_name: "West Bengal",
  },
  {
    name: "Jaynagar Majilpur",
    state_name: "West Bengal",
  },
  {
    name: "Jejur",
    state_name: "West Bengal",
  },
  {
    name: "Jhalida",
    state_name: "West Bengal",
  },
  {
    name: "Jhargram",
    state_name: "West Bengal",
  },
  {
    name: "Jhilimili",
    state_name: "West Bengal",
  },
  {
    name: "Kakdwip",
    state_name: "West Bengal",
  },
  {
    name: "Kalaikunda",
    state_name: "West Bengal",
  },
  {
    name: "Kaliaganj",
    state_name: "West Bengal",
  },
  {
    name: "Kalimpong",
    state_name: "West Bengal",
  },
  {
    name: "Kalna",
    state_name: "West Bengal",
  },
  {
    name: "Kalyani",
    state_name: "West Bengal",
  },
  {
    name: "Kamarhati",
    state_name: "West Bengal",
  },
  {
    name: "Kamarpukur",
    state_name: "West Bengal",
  },
  {
    name: "Kanchrapara",
    state_name: "West Bengal",
  },
  {
    name: "Kandi",
    state_name: "West Bengal",
  },
  {
    name: "Karimpur",
    state_name: "West Bengal",
  },
  {
    name: "Katwa",
    state_name: "West Bengal",
  },
  {
    name: "Kenda",
    state_name: "West Bengal",
  },
  {
    name: "Keshabpur",
    state_name: "West Bengal",
  },
  {
    name: "Kharagpur",
    state_name: "West Bengal",
  },
  {
    name: "Kharar",
    state_name: "West Bengal",
  },
  {
    name: "Kharba",
    state_name: "West Bengal",
  },
  {
    name: "Khardaha",
    state_name: "West Bengal",
  },
  {
    name: "Khatra",
    state_name: "West Bengal",
  },
  {
    name: "Kirnahar",
    state_name: "West Bengal",
  },
  {
    name: "Kolkata",
    state_name: "West Bengal",
  },
  {
    name: "Konnagar",
    state_name: "West Bengal",
  },
  {
    name: "Krishnanagar",
    state_name: "West Bengal",
  },
  {
    name: "Krishnapur",
    state_name: "West Bengal",
  },
  {
    name: "Kshirpai",
    state_name: "West Bengal",
  },
  {
    name: "Kulpi",
    state_name: "West Bengal",
  },
  {
    name: "Kultali",
    state_name: "West Bengal",
  },
  {
    name: "Kulti",
    state_name: "West Bengal",
  },
  {
    name: "Kurseong",
    state_name: "West Bengal",
  },
  {
    name: "Lalgarh",
    state_name: "West Bengal",
  },
  {
    name: "Lalgola",
    state_name: "West Bengal",
  },
  {
    name: "Loyabad",
    state_name: "West Bengal",
  },
  {
    name: "Madanpur",
    state_name: "West Bengal",
  },
  {
    name: "Madhyamgram",
    state_name: "West Bengal",
  },
  {
    name: "Mahiari",
    state_name: "West Bengal",
  },
  {
    name: "Mahishadal community development block",
    state_name: "West Bengal",
  },
  {
    name: "Mainaguri",
    state_name: "West Bengal",
  },
  {
    name: "Manikpara",
    state_name: "West Bengal",
  },
  {
    name: "Masila",
    state_name: "West Bengal",
  },
  {
    name: "Mathabhanga",
    state_name: "West Bengal",
  },
  {
    name: "Matiali community development block",
    state_name: "West Bengal",
  },
  {
    name: "Matigara community development block",
    state_name: "West Bengal",
  },
  {
    name: "Medinipur",
    state_name: "West Bengal",
  },
  {
    name: "Mejia community development block",
    state_name: "West Bengal",
  },
  {
    name: "Memari",
    state_name: "West Bengal",
  },
  {
    name: "Mirik",
    state_name: "West Bengal",
  },
  {
    name: "Monoharpur",
    state_name: "West Bengal",
  },
  {
    name: "Muragacha",
    state_name: "West Bengal",
  },
  {
    name: "Muri",
    state_name: "West Bengal",
  },
  {
    name: "Murshidabad",
    state_name: "West Bengal",
  },
  {
    name: "Nabadwip",
    state_name: "West Bengal",
  },
  {
    name: "Nabagram",
    state_name: "West Bengal",
  },
  {
    name: "Nadia district",
    state_name: "West Bengal",
  },
  {
    name: "Nagarukhra",
    state_name: "West Bengal",
  },
  {
    name: "Nagrakata",
    state_name: "West Bengal",
  },
  {
    name: "Naihati",
    state_name: "West Bengal",
  },
  {
    name: "Naksalbari",
    state_name: "West Bengal",
  },
  {
    name: "Nalhati",
    state_name: "West Bengal",
  },
  {
    name: "Nalpur",
    state_name: "West Bengal",
  },
  {
    name: "Namkhana community development block",
    state_name: "West Bengal",
  },
  {
    name: "Nandigram",
    state_name: "West Bengal",
  },
  {
    name: "Nangi",
    state_name: "West Bengal",
  },
  {
    name: "Nayagram community development block",
    state_name: "West Bengal",
  },
  {
    name: "North 24 Parganas district",
    state_name: "West Bengal",
  },
  {
    name: "Odlabari",
    state_name: "West Bengal",
  },
  {
    name: "Paikpara",
    state_name: "West Bengal",
  },
  {
    name: "Panagarh",
    state_name: "West Bengal",
  },
  {
    name: "Panchla",
    state_name: "West Bengal",
  },
  {
    name: "Panchmura",
    state_name: "West Bengal",
  },
  {
    name: "Pandua",
    state_name: "West Bengal",
  },
  {
    name: "Panihati",
    state_name: "West Bengal",
  },
  {
    name: "Panskura",
    state_name: "West Bengal",
  },
  {
    name: "Parbatipur",
    state_name: "West Bengal",
  },
  {
    name: "Paschim Medinipur district",
    state_name: "West Bengal",
  },
  {
    name: "Patiram",
    state_name: "West Bengal",
  },
  {
    name: "Patrasaer",
    state_name: "West Bengal",
  },
  {
    name: "Patuli",
    state_name: "West Bengal",
  },
  {
    name: "Pujali",
    state_name: "West Bengal",
  },
  {
    name: "Puncha community development block",
    state_name: "West Bengal",
  },
  {
    name: "Purba Medinipur district",
    state_name: "West Bengal",
  },
  {
    name: "Purulia",
    state_name: "West Bengal",
  },
  {
    name: "Raghudebbati",
    state_name: "West Bengal",
  },
  {
    name: "Raghunathpur",
    state_name: "West Bengal",
  },
  {
    name: "Raiganj",
    state_name: "West Bengal",
  },
  {
    name: "Rajmahal",
    state_name: "West Bengal",
  },
  {
    name: "Rajnagar community development block",
    state_name: "West Bengal",
  },
  {
    name: "Ramchandrapur",
    state_name: "West Bengal",
  },
  {
    name: "Ramjibanpur",
    state_name: "West Bengal",
  },
  {
    name: "Ramnagar",
    state_name: "West Bengal",
  },
  {
    name: "Rampur Hat",
    state_name: "West Bengal",
  },
  {
    name: "Ranaghat",
    state_name: "West Bengal",
  },
  {
    name: "Raniganj",
    state_name: "West Bengal",
  },
  {
    name: "Raypur",
    state_name: "West Bengal",
  },
  {
    name: "Rishra",
    state_name: "West Bengal",
  },
  {
    name: "Sahapur",
    state_name: "West Bengal",
  },
  {
    name: "Sainthia",
    state_name: "West Bengal",
  },
  {
    name: "Salanpur community development block",
    state_name: "West Bengal",
  },
  {
    name: "Sankarpur",
    state_name: "West Bengal",
  },
  {
    name: "Sankrail",
    state_name: "West Bengal",
  },
  {
    name: "Santipur",
    state_name: "West Bengal",
  },
  {
    name: "Santoshpur",
    state_name: "West Bengal",
  },
  {
    name: "Santuri community development block",
    state_name: "West Bengal",
  },
  {
    name: "Sarenga",
    state_name: "West Bengal",
  },
  {
    name: "Serampore",
    state_name: "West Bengal",
  },
  {
    name: "Serpur",
    state_name: "West Bengal",
  },
  {
    name: "Shyamnagar West Bengal",
    state_name: "West Bengal",
  },
  {
    name: "Siliguri",
    state_name: "West Bengal",
  },
  {
    name: "Singur",
    state_name: "West Bengal",
  },
  {
    name: "Sodpur",
    state_name: "West Bengal",
  },
  {
    name: "Solap",
    state_name: "West Bengal",
  },
  {
    name: "Sonada",
    state_name: "West Bengal",
  },
  {
    name: "Sonamukhi",
    state_name: "West Bengal",
  },
  {
    name: "Sonarpur community development block",
    state_name: "West Bengal",
  },
  {
    name: "South 24 Parganas district",
    state_name: "West Bengal",
  },
  {
    name: "Srikhanda",
    state_name: "West Bengal",
  },
  {
    name: "Srirampur",
    state_name: "West Bengal",
  },
  {
    name: "Suri",
    state_name: "West Bengal",
  },
  {
    name: "Swarupnagar community development block",
    state_name: "West Bengal",
  },
  {
    name: "Takdah",
    state_name: "West Bengal",
  },
  {
    name: "Taki",
    state_name: "West Bengal",
  },
  {
    name: "Tamluk",
    state_name: "West Bengal",
  },
  {
    name: "Tarakeswar",
    state_name: "West Bengal",
  },
  {
    name: "Titagarh",
    state_name: "West Bengal",
  },
  {
    name: "Tufanganj",
    state_name: "West Bengal",
  },
  {
    name: "Tulin",
    state_name: "West Bengal",
  },
  {
    name: "Uchalan",
    state_name: "West Bengal",
  },
  {
    name: "Ula",
    state_name: "West Bengal",
  },
  {
    name: "Uluberia",
    state_name: "West Bengal",
  },
  {
    name: "Uttar Dinajpur district",
    state_name: "West Bengal",
  },
  {
    name: "Uttarpara Kotrung",
    state_name: "West Bengal",
  },
];
