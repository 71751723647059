import DefaultButton from "components/DefaultButton/DefaultButton";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const [isPathName, setIsPathName] = useState<boolean>();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const pathName = window.location.pathname.split("/");
    const value = pathName.includes("org");
    if (value) {
      setIsPathName(false);
    } else {
      setIsPathName(true);
    }
  }, [window.location.pathname]);

  const handleClick = () => {
    if (isPathName) {
      navigate("/org/accounts");
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center w-100 vh-100">
      <div className="d-flex flex-column">
        <div>
          <img src="/images/404_Image.svg" alt="404" width={440} height={280} />
        </div>
        <div className="text-center">
          <h3 className="fs-48 text-primary">Page Not Found !!</h3>
        </div>
        <div className="text-center mt-md-3">
          <DefaultButton
            type="button"
            variant="primary"
            className="default-action-button text-white rounded-pill"
            // onClick={() => navigate("/org/accounts")}
            onClick={handleClick}
          >
            Go to Dashboard
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
