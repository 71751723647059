import React, { useLayoutEffect, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useIndividualEventRaceDetails } from "api/IndividualEventRaceDetails";
import AddToCartButton from "components/AddToCartButton/AddToCartButton";
import { toast } from "react-toastify";
import { Button, Table } from "react-bootstrap";
import styles from "./IndividualEventDetails.module.scss";
import { Race } from "types/Race";
import Loader from "components/Loader/Loader";
import {
  dateFormatConvertDDMMYYYY,
  hasExpired,
  isRegistrationStarted,
} from "utils/utils";
import moment from "moment";
import parse from "html-react-parser";

interface Props {
  serverTime: string;
  eventInfo: Race;
}

const IndividualEventDetails: React.FC<Props> = ({ serverTime, eventInfo }) => {
  const location = useLocation();
  const raceId = eventInfo.id;
  const [showAddToCartButton, setShowAddToCartButton] = useState(true);
  const [genderCategories, setGenderCategories] = useState<any>([]);
  const [showEarlyBird, setShowEarlyBird] = useState<boolean>(false);

  useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (
      pathName.includes("mybookings") ||
      pathName.includes("eventDashBoard")
    ) {
      setShowAddToCartButton(false);
    }
  }, []);

  const {
    data: individualEventDetails,
    isLoading: individualEventDetailsLoading,
    error: individualEventDetailsError,
  } = useIndividualEventRaceDetails(raceId);

  const eventDetails = individualEventDetails?.data;

  useEffect(() => {
    const groupBy = (array: any, property: string) => {
      return Object?.values(
        array?.reduce((result: any, obj: any) => {
          const key = obj[property];
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(obj);
          return result;
        }, {})
      );
    };

    if (eventDetails?.ageStatus) {
      const groupByCategory = groupBy(eventDetails?.age, "category_slug");
      setGenderCategories(groupByCategory);
    }
  }, [eventDetails]);

  useEffect(() => {
    if (eventInfo?.allow_early_bird) {
      const startEarlyBirdStartDate = moment(
        eventInfo?.early_bird_valid_from
      ).valueOf();

      const startEarlyBirdEndDate = moment(
        eventInfo?.early_bird_valid_to
      ).valueOf();

      const now = moment(new Date()).valueOf();

      if (startEarlyBirdStartDate < now && startEarlyBirdEndDate > now) {
        setShowEarlyBird(true);
      } else {
        setShowEarlyBird(false);
      }
    }
  }, []);

  if (individualEventDetailsError) {
    toast.error("Error loading event race details. Please try again later.");
  }

  if (individualEventDetailsLoading) {
    return <Loader />;
  }

  return (
    <div className={`${styles.canvasContainer}`}>
      <div className={`${styles.canvasContent}`}>
        <div
          className={`${styles.horizontalPadding} ${styles.verticalPadding}`}
        >
          <div>
            <h1 className={`${styles.title} mb-0`}>
              {eventDetails?.ticket_name}
            </h1>

            <Container className="mt-4 px-0">
              <Row className="justify-content-between align-items-center">
                <Col className="my-1 px-1" sm={6} md={8} xl={3} xxl={2}>
                  <div className="d-flex flex-column">
                    <div className="text-center">
                      {showEarlyBird ? (
                        <>
                          <div className="text-primary font-noto fs-14 mb-2">
                            Earlybird Price:
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <p className="font-saira fw-600 fs-22 mb-0">
                              <span style={{ marginRight: "0.2rem" }}>
                                &#8377;
                              </span>
                              <del> {eventInfo?.amount}/-</del>
                            </p>
                            <p className="font-saira fw-600 fs-22 mb-0 text-primary ms-2">
                              <span style={{ marginRight: "0.2rem" }}>
                                &#8377;
                              </span>
                              {eventInfo?.early_bird_discount_amount}/-
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <p
                            className={`${styles.colorGrey} font-noto fs-14 mb-2`}
                          >
                            Race Price
                          </p>
                          <p className="font-saira fw-600 fs-22 mb-0">
                            <span style={{ marginRight: "0.2rem" }}>
                              &#8377;
                            </span>
                            {eventInfo?.amount}/-
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="my-1" xs={6} md={4} xl={2}>
                  <div className="d-flex flex-column">
                    <div className="text-center">
                      <p className="font-noto fs-14 mb-2">Distance:</p>
                      <p className="font-saira fw-600 fs-22 mb-0">
                        {eventDetails?.distance} Km
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="my-1 px-md-0" xs={6} md={4} xl={2}>
                  <div className="d-flex flex-column">
                    <div className="text-center">
                      <p className="font-noto fs-14 mb-2">Start Date:</p>
                      <p className="font-saira fw-600 fs-22 mb-0">
                        {dateFormatConvertDDMMYYYY(
                          eventDetails?.ticketStartDate
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="my-1" xs={6} md={4} xl={2}>
                  <div className="d-flex flex-column">
                    <div className="text-center">
                      <p className="font-noto fs-14 mb-2">Reporting Time:</p>
                      <p className="font-saira fw-600 fs-22 mb-0">
                        {eventDetails?.report_timing}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="my-1" xs={6} md={4} xl={2}>
                  <div className="d-flex flex-column">
                    <div className="text-center">
                      <p className="font-noto fs-14 mb-2">Race Start Time:</p>
                      <p className="font-saira fw-600 fs-22 mb-0">
                        {eventDetails?.tkt_evnt_start_time}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <hr className="mx-2 my-0 d-none d-sm-block" />
        <div
          className={`${styles.horizontalPadding} ${styles.verticalPadding} ${styles.verticalTopPaddingNone}`}
        >
          <h1 className="fs-24 fw-600 mb-0">Race Description</h1>
          <p className="font-saira mt-3">
            {" "}
            {parse(`${eventDetails?.description}`)}
          </p>
        </div>

        {eventDetails?.benefitStatus && (
          <>
            <hr className="mx-2 my-0 d-none d-sm-block" />
            <div
              className={`${styles.horizontalPadding} ${styles.verticalPadding} ${styles.verticalTopPaddingNone}`}
            >
              <h1 className="fs-24 fw-600 mb-0">Participants will get</h1>
              <div className={`${styles.listStyle}`}>
                <ul className="mt-3">
                  {eventDetails?.benefit?.map((benefit: any, index: number) => {
                    return (
                      <li>
                        <div className="d-flex align-items-center">
                          <img
                            key={index}
                            src={`${process.env.REACT_APP_URL}${benefit?.benefitlogo}`}
                            alt={benefit?.benefitName}
                          />
                          <p className="mb-0 ms-3">{benefit?.benefitName}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className={`${styles.note} font-saira fw-600`}>
            <span className={`${styles.black}`}>Note:</span> You can collect
            your BIB Kit on the 14th & 15th of July from 5.00 to 8.30 PM. From
            Decathlon, SF-12, Viviana Mall, Service Rd, Laxmi Nagar, Thane West,
            Thane, Maharashtra 400601
          </div> */}
            </div>
          </>
        )}

        {eventDetails?.ageStatus && (
          <>
            <hr className="mx-2 my-0 d-none d-sm-block" />
            <div
              className={`${styles.horizontalPadding} ${styles.verticalPadding} ${styles.verticalTopPaddingNone}`}
            >
              <h1 className="fs-24 fw-600">Age Categories</h1>
              <div className={`${styles.listStyle}`}>
                <Container fluid className="px-0">
                  <Row>
                    {genderCategories?.map(
                      (ageCategory: any, index: number) => {
                        const title = ageCategory[0]?.category_slug;
                        return (
                          <Col
                            className="my-2"
                            key={index}
                            xxl={4}
                            xs={12}
                            md={6}
                          >
                            <h1 className="fs-24 fw-600 text-capitalize">
                              {title}
                            </h1>
                            <Table className="table-light">
                              <thead>
                                <tr className="table-light">
                                  <th className="text-center">Category Name</th>
                                  <th className="text-center">Min Age</th>
                                  <th className="text-center">Max Age</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ageCategory.map(
                                  (category: any, index: number) => {
                                    return (
                                      <tr key={index}>
                                        <td className="text-capitalize text-center table-light">
                                          {category?.category_name}
                                        </td>
                                        <td className="text-center table-light">
                                          {category?.min_age}
                                        </td>
                                        <td className="text-center table-light">
                                          {category?.max_age}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </Container>
              </div>
            </div>
          </>
        )}

        {/* <hr className="mx-2 my-0 d-none d-sm-block" />
        <div
          className={`${styles.horizontalPadding} ${styles.verticalPadding} ${styles.verticalTopPaddingNone}`}
        >
          <h1 className="fs-24 fw-600 mb-0">Schedule & Course Map</h1>
          <p className={`${styles.subtitle} font-saira`}>
            Click to view the course for your distance of choice.
          </p>
          <div className={`${styles.layoutContainer} text-center`}>
            <img className="inline-block" src={mapLayout} alt="map" />
          </div>
        </div> */}
      </div>

      {showAddToCartButton && (
        <div className={`${styles.registerContainer}`}>
          <div className="d-flex flex-column flex-md-row justify-md-content-between justify-content-center">
            <div className="mb-2 mb-md-0 ms-2" style={{ flexGrow: "1" }}>
              <AddToCartButton
                isEarlyBirdPrice={showEarlyBird}
                variant="outlined"
                eventInfo={eventInfo}
                serverTime={serverTime}
                disabled={
                  hasExpired(eventDetails?.registration_end_date, serverTime) ||
                  !isRegistrationStarted(
                    eventDetails?.registration_start_date,
                    serverTime
                  )
                }
              />
            </div>
            {/* <Button
              className="default-action-button fw-bold text-white ms-md-4 mt-4 mt-md-0"
              variant="primary"
            >
              REGISTER
            </Button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualEventDetails;
