import DefaultButton from "components/DefaultButton/DefaultButton";
import styles from "./ErrorPage.module.scss";

const ErrorRoutePage: React.FC = () => {
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <img
        className={`${styles.errorImage}`}
        src="/images/errorpage.svg"
        alt="somethingwentwrong"
      />
      <div className="my-1 text-center">
        <p className="text-center my-3 fs-18 px-2 ff-saira">
          Oops! Something went wrong. We apologize for the inconvenience. Please
          try again later.
        </p>
        <DefaultButton
          type="button"
          className="px-4 rounded-pill text-white"
          variant="primary"
          onClick={() => (window.location.href = "/")}
        >
          Go to Home
        </DefaultButton>
      </div>
    </div>
  );
};

export default ErrorRoutePage;
