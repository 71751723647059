import { Col, Container, Image, Row } from "react-bootstrap";
const Gallery = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <Image
            src="/images/galleryImage1.png"
            alt="shoe"
            style={{ width: "315px", height: "344px" }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Image
            src="/images/galleryImage3.png"
            alt="shoe image"
            style={{ width: "360px", height: "153px" }}
          />
          <Image
            src="/images/galleryImage4.png"
            alt="shoe image"
            style={{ width: "374px", height: "224px" }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Image
            src="/images/galleryImage2.png"
            alt="shoe image"
            style={{ width: "369px", height: "344px" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Gallery;
