import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Icon } from "components/Icon";
import useEventName from "utils/useEventName";

import CharityTabScreen from "OrganizerPages/eventDetails/NGOTabScreen";
import FundRaiserTabScreen from "OrganizerPages/eventDetails/fundRaiserTabScreen";
import GetAllCharity from "OrganizerPages/eventDetails/GetAllCharity";
import OfflineCharity from "OrganizerPages/eventDetails/OfflineCharity";

const CharityDashboard = () => {
  const Navigate = useNavigate();
  const eventName = useEventName();

  const [activeTab, setActiveTab] = useState<string | null>("ngo");

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
          </Col>
        </Row>

        <>
          <div>
            <Tabs
              defaultActiveKey="ngo"
              id="event-ngo-tabs"
              className="event-registartion-nav-tabs border-0"
              onSelect={handleSelectTab}
            >
              <Tab eventKey="allcharities" title="Dashboard">
                {activeTab === "allcharities" && <GetAllCharity />}
              </Tab>
              <Tab eventKey="ngo" title="NGO's">
                {activeTab === "ngo" && <CharityTabScreen />}
              </Tab>
              <Tab eventKey="fundRaiser" title="Fundraiser">
                {activeTab === "fundRaiser" && <FundRaiserTabScreen />}
              </Tab>
              <Tab eventKey="offlinecharity" title="Offline Donation">
                {activeTab === "offlinecharity" && <OfflineCharity />}
              </Tab>
            </Tabs>
          </div>
        </>
      </Container>
    </>
  );
};

export default CharityDashboard;
