import { Card, Image } from "react-bootstrap";
import EventsBadge from "../Badge/EventsBadge";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import { Event } from "types/Event";
import { dateFormatConvertDDMMYYYY } from "utils/utils";

interface EventCardProps {
  event: Event;
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/eventdetails/${event.event_slug}`);
  };
  return (
    <Card
      className="mb-4 border-0 shadow"
      style={{
        width: "348px",
        height: "477px",
        flexShrink: "0",
        cursor: "pointer",
      }}
      position-relative
      onClick={handleCardClick}
    >
      <div style={{ position: "absolute", top: "8px", right: "8px" }}>
        <Icon iconName="Heart" size={25} color="white" />
      </div>
      <Card.Img
        variant="top"
        src={event?.imageUrl}
        style={{ width: "348px", height: "196px", flexShrink: "0" }}
      />
      {event?.notification <= 7 && (
        <span
          className="bg-primary fs-12 ff-ns text-white px-2 py-1 fw-500"
          style={{
            position: "absolute",
            borderRadius: "4px",
            right: "35px",
            top: "182px",
          }}
        >
          {event?.notification} DAYS LEFT
        </span>
      )}
      <Card.Body className="d-flex flex-column">
        <div className="pt-3 pb-4">
          <Image
            src={event?.organizerLogo}
            alt="Organizer logo"
            style={{ height: "40px", width: "40px" }}
          />
          <span className="ps-3 fs-14 ff-ns text-darkGray fw-700">
            {event?.organizerName}
          </span>
        </div>
        <Card.Title>
          <h1 style={{ minHeight: "60px" }}>{event?.title}</h1>
        </Card.Title>
        <div>
          {event?.tags &&
            event?.tags?.map((tag, idx) => (
              <EventsBadge key={idx}>{tag}</EventsBadge>
            ))}
        </div>
        <Card.Text className="mt-3 fs-14 ff-ns fw-400 text-darkGray mb-1">
          {event?.location}, {event?.city}
        </Card.Text>
        <Card.Text className="fs-14 ff-ns fw-700 text-darkGray">
          {dateFormatConvertDDMMYYYY(event?.date)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default EventCard;
