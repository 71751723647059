import React, { useEffect, useState, ReactNode } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./OffCanvas.module.scss";

interface Props {
  handleCloseOffCanvasModal: () => void;
  showOffCanvasModal: boolean;
  children?: ReactNode;
  isProfile?: boolean;
}

type OffcanvasPlacement = "bottom" | "end";

const IndividualEventDetails: React.FC<Props> = ({
  showOffCanvasModal,
  handleCloseOffCanvasModal,
  children,
  isProfile,
}) => {
  const [placement, setPlacement] = useState<OffcanvasPlacement | undefined>(
    undefined
  );

  function handleResize() {
    const width = window.innerWidth;
    if (width > 576) {
      setPlacement("end");
    } else {
      setPlacement("bottom");
    }
  }

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Offcanvas
      show={showOffCanvasModal}
      onHide={handleCloseOffCanvasModal}
      backdrop={true}
      className={`${
        isProfile ? styles.customProfileOffCanvas : styles.customOffCanvas
      } offcanvas-placement-bottom offcanvas-placement-end`}
      placement={placement ? `${placement}` : "end"}
    >
      <Offcanvas.Body className="p-0">{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default React.memo(IndividualEventDetails);
