import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEditUserProfile } from "api/EditUserProfile";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import axios from "utils/axios";
import { Form as FormData, Formik } from "formik";
import { TextField } from "utils/FormControls";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";

const OrganizationEditProfile = () => {
  const editUserProfileMutation = useEditUserProfile();

  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState<any>({});
  const { token } = useAuth();
  const { storeAccountId } = useEvent();
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    organisationProfile();
  }, []);

  function organisationProfile() {
    setIsLoading(true);
    axios
      .post(
        "/getOrganiser",
        {
          accountId: storeAccountId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProfileData(res.data.data);
        setLoadData(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    name: Yup.string()
      .required("Short Vendor Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Name should only contain alphabetic characters"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    countryCode: Yup.string()
      .min(1, "Minimum of 1 characters of length")
      .max(4, "Maximum of 4 characters of length"),
    pincode: Yup.string()
      .required("Pincode is required field")
      .matches(/^[0-9]+$/, {
        message: "Must be only digits",
        excludeEmptyString: false,
      })
      .min(5, "Must be exactly 5 digits")
      .max(6, "Must be exactly 5 digits"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .required("Mobile Number is required"),
    companyAddress: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(80, "Maximum of 80 characters of length"),
    correspondenceAddress: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(60, "Maximum of 60 characters of length"),
    landmark: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(40, "Maximum of 40 characters of length"),
    city: Yup.string()
      .required("City is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "City should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    state: Yup.string()
      .required("State is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "State should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    country: Yup.string()
      .required("Country is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Country should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    sameAsAddress: Yup.bool().required(
      "Billing address is same as mail address? is required"
    ),
    isYourTurnOver20c: Yup.bool().required("Is your Turn over is required"),
    gstNo: Yup.string()
      .required("GST Number is required")
      .matches(
        /^(?:[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})|(?:^[A-Za-z0-9]{1,15}$)/,
        "Invalid GST number format or must be alphanumeric and up to 15 characters"
      )
      .min(15, "Minimum of 15 aplhanumeric  characters of length")
      .max(15, "Maximum of 15 aplhanumeric  characters of length"),
    panNo: Yup.string()
      .required("PAN Number is required")
      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
    bankName: Yup.string()
      .required("Bank Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Bank Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    accountNo: Yup.string()
      .required("Account No is Required")
      .matches(/^\d{9,18}$/, "Invalid account number"),

    accountName: Yup.string()
      .required("Account Name is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    accountType: Yup.string()
      .required("Account Type is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    micrCode: Yup.string()
      .required("micrCode is Required")
      .matches(/^[0-9]{9}$/, "Invalid MICR code"),
    ifscCode: Yup.string()
      .required("ifscCode is Required")
      .matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, "Invalid IFSC code"),
    profileImage: Yup.string().nullable(),
    gstAttachment: Yup.string().nullable(),
    cancelChequeAttachment: Yup.string().nullable(),
    workOrderAttachment: Yup.string().nullable(),
    turnOverDeclarationAttachment: Yup.string().nullable(),
    panAttachment: Yup.string().nullable(),
    // profileImage: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),
    // gstAttachment: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),
    // cancelChequeAttachment: Yup.mixed()
    //   .required("Cancel Cheque Attachment is required")
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),
    // workOrderAttachment: Yup.mixed()
    //   .required("Cancel Cheque Attachment is required")
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),

    // turnOverDeclarationAttachment: Yup.mixed()
    //   .required("Cancel Cheque Attachment is required")
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),
    // panAttachment: Yup.mixed()
    //   .required("Cancel Cheque Attachment is required")
    //   .test(
    //     "fileSize",
    //     "Image size exceeds the maximum limit of 5MB.",
    //     (value) => {
    //       return (
    //         value &&
    //         (value instanceof File || value instanceof Blob) &&
    //         value.size <= 5 * 1024 * 1024
    //       ); // 5MB
    //     }
    //   )
    //   .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
    //     return (
    //       value &&
    //       (value instanceof File || value instanceof Blob) &&
    //       ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
    //         (value as File).type
    //       )
    //     );
    //   }),
  });

  const SingupinitialValues = {
    companyName: loadData ? profileData?.company_name ?? "" : "",
    name: loadData ? profileData?.company_name ?? "" : "",
    countryCode: +91,
    email: loadData ? profileData?.company_email_id ?? "" : "",
    phone: loadData ? profileData?.company_contact_no ?? "" : "",
    companyAddress: loadData ? profileData?.company_address ?? "" : "",
    city: loadData ? profileData?.city ?? "" : "",
    state: loadData ? profileData?.state ?? "" : "",
    pincode: loadData ? profileData?.pincode ?? "" : "",
    country: loadData ? profileData?.country ?? "" : "",
    landmark: loadData ? profileData?.landmark ?? "" : "",
    correspondenceAddress: loadData
      ? profileData?.correspondence_address ?? ""
      : "",
    sameAsAddress: loadData ? profileData?.same_as_company_address ?? "" : "",
    profileImage: loadData ? profileData?.profile_image ?? "" : "",
    isYourTurnOver20c: loadData ? profileData?.is_turnover_above_20c ?? "" : "",
    bankName: loadData ? profileData?.bank_name ?? "" : "",
    accountNo: loadData ? profileData?.account_number ?? "" : "",
    accountName: loadData ? profileData?.account_name ?? "" : "",
    accountType: loadData ? profileData?.account_type ?? "" : "",
    micrCode: loadData ? profileData?.micr_code ?? "" : "",
    ifscCode: loadData ? profileData?.ifsc_code ?? "" : "",
    cancelChequeAttachment: loadData ? profileData?.cancel_cheque ?? "" : "",
    workOrderAttachment: loadData ? profileData?.work_order ?? "" : "",
    turnOverDeclarationAttachment: loadData
      ? profileData?.turnover_declartion_einvoice ?? ""
      : "",
    gstNo: loadData ? profileData?.gst_number ?? "" : "",
    gstAttachment: loadData ? profileData?.gst_attachement ?? "" : "",
    panNo: loadData ? profileData?.pan_number ?? "" : "",
    panAttachment: loadData ? profileData?.pan_attachement ?? "" : "",
  };

  const handleSubmit = (values: any) => {
    const postData = {
      accountId: storeAccountId,
      companyName: values.companyName,
      name: values.name,
      countryCode: +91,
      email: values.email,
      phone: values.phone,
      companyAddress: values.companyAddress,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      country: values.country,
      landmark: values.landmark,
      sameAsAddress: values.sameAsAddress,
      correspondenceAddress: values.correspondenceAddress,
      isYourTurnOver20c: values.isYourTurnOver20c,
      bankName: values.bankName,
      accountNo: values.accountNo,
      accountName: values.accountName,
      accountType: values.accountType,
      micrCode: values.micrCode,
      ifscCode: values.ifscCode,
      cancelChequeAttachment:
        values.cancelChequeAttachmentbase64 || values.cancelChequeAttachment,
      workOrderAttachment:
        values.workOrderAttachmentbase64 || values.workOrderAttachment,
      turnOverDeclarationAttachment:
        values.turnOverDeclarationAttachmentbase64 ||
        values.turnOverDeclarationAttachment,
      gstNo: values.gstNo,
      gstAttachment: values.gstAttachmentbase64 || values.gstAttachment,
      panNo: values.panNo,
      panAttachment: values.panAttachmentbase64 || values.panAttachment,
      profileImage: values.profileImagebase64 || values.profileImage,
    };

    axios
      .post("/editOrganiser", postData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        const { status, message } = response.data;
        console.log(status);
        if (status) {
          toast.success(`${message}`);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as base64."));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="ff-ns fs-24 fw-600 ps-4 mt-4">Edit Profile</div>
      <Formik
        initialValues={SingupinitialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          return (
            <FormData noValidate className={"px-md-4 py-md-4"}>
              <div className="border px-4 rounded form-control py-4">
                <Row className="mt-2">
                  <Col md={6}>
                    <TextField
                      type="text"
                      name="companyName"
                      label="Company Name *"
                      // isRequired={true}
                    />
                  </Col>

                  <Col md={6}>
                    <TextField
                      type="text"
                      name="name"
                      label="Short Vendor Name *"
                      // isRequired={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <TextField
                      type="text"
                      name="phone"
                      label="Mobile Number *"
                      // isRequired={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      type="email"
                      name="email"
                      label="Email ID *"
                      // isRequired={true}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column w-100">
                        <DefaultInput
                          label="Upload Image"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "profileImagebase64",
                                base64
                              );
                              formikProps.setFieldValue("profileImage", base64);
                            } else {
                              formikProps.setFieldValue(
                                "profileImage",
                                formikProps.values.profileImage
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2 ">
                        {formikProps.values.profileImage && (
                          <img
                            className="bordered"
                            src={formikProps.values.profileImage}
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <TextField
                      type="text"
                      name="companyAddress"
                      label="Company Address *"
                      // isRequired={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4}>
                    <TextField
                      type="text"
                      name="landmark"
                      label="Landmark *"
                      // isRequired={true}
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      type="text"
                      name="pincode"
                      label="Pincode *"
                      // isRequired={true}
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      type="text"
                      name="city"
                      label="City *"
                      // isRequired={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <TextField
                      type="text"
                      name="state"
                      label="State *"
                      // isRequired={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      type="text"
                      name="country"
                      label="Country *"
                      // isRequired={true}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col className="col-12 my-md-3 d-flex align-items-center">
                    <Form.Group className="d-flex">
                      <Form.Label className="me-2">
                        Is the correspondence address same as company address?
                      </Form.Label>

                      <Form.Check
                        type="radio"
                        name="sameAsAddress"
                        id="sameAsAddress"
                        value="0"
                        label="Yes"
                        checked={formikProps.values.sameAsAddress === 0}
                        onChange={() =>
                          formikProps.setFieldValue("sameAsAddress", 0)
                        }
                      />

                      <Form.Check
                        type="radio"
                        className="ms-md-3"
                        name="sameAsAddress"
                        id="sameAsAddress"
                        value="1"
                        label="No"
                        checked={formikProps.values.sameAsAddress === 1}
                        onChange={() =>
                          formikProps.setFieldValue("sameAsAddress", 1)
                        }
                      />
                    </Form.Group>
                  </Col>
                  {formikProps.values.sameAsAddress === 1 && (
                    <Col className="col-12 mb-3">
                      <TextField
                        type="text"
                        name="correspondenceAddress *"
                        label="Correspondence Address"
                        // isRequired={true}
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col className="col-12 d-flex align-items-center">
                    <Form.Group className="d-flex">
                      <Form.Label className="me-2">
                        Is your turnover above 20 cr?
                      </Form.Label>

                      <Form.Check
                        type="radio"
                        name="isYourTurnOver20c"
                        id="isYourTurnOver20c"
                        value="0"
                        label="Yes"
                        checked={formikProps.values.isYourTurnOver20c === 0}
                        onChange={() =>
                          formikProps.setFieldValue("isYourTurnOver20c", 0)
                        }
                      />

                      <Form.Check
                        type="radio"
                        className="ms-md-3"
                        name="isYourTurnOver20c"
                        id="isYourTurnOver20c"
                        value="1"
                        label="No"
                        checked={formikProps.values.isYourTurnOver20c === 1}
                        onChange={() =>
                          formikProps.setFieldValue("isYourTurnOver20c", 1)
                        }
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col className="col-12 d-flex align-items-center">
                    <Form.Group className="d-flex  align-items-center justify-content-center">
                      <Form.Label className="me-2">
                        Is your turnover above 20 cr?
                      </Form.Label>

                      <Form.Check
                        style={{ marginTop: "-10px" }}
                        type="radio"
                        className="d-flex align-items-center"
                        name="isYourTurnOver20c"
                        id="isYourTurnOver20c"
                        value="1"
                        label="Yes"
                        checked={formikProps.values.isYourTurnOver20c === "1"}
                        onChange={() =>
                          formikProps.setFieldValue("isYourTurnOver20c", 1)
                        }
                      />

                      <Form.Check
                        style={{ marginTop: "-10px" }}
                        type="radio"
                        className="d-flex align-items-center ms-md-2"
                        name="isYourTurnOver20c"
                        id="isYourTurnOver20c"
                        value="0"
                        label="No"
                        checked={formikProps.values.isYourTurnOver20c === "0"}
                        onChange={() =>
                          formikProps.setFieldValue("isYourTurnOver20c", 0)
                        }
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row className="mt-3">
                  <Col className="col-md-6 col-12">
                    <TextField
                      type="text"
                      label="GST No *"
                      name="gstNo"
                      // isRequired={true}
                    />
                  </Col>
                  <Col className="col-md-6 col-12 mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="GST Attachment"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "gstAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue("gstAttachment", file);
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        {formikProps.values.gstAttachment && (
                          <img
                            className="bordered"
                            src={formikProps.values.gstAttachment}
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-md-6 col-12 py-1">
                    <TextField
                      type="text"
                      label="PAN No *"
                      name="panNo"
                      // isRequired={true}
                    />
                  </Col>
                  <Col className="col-md-6 col-12 mt-1">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Pan Attachment"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "panAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue("panAttachment", file);
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        {formikProps.values.panAttachment && (
                          <img
                            className="bordered"
                            src={formikProps.values.panAttachment}
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <h4 className="text-primary my-md-4">BANK DETAILS</h4>
                <Row>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="Bank Name *"
                      name="bankName"
                      // isRequired={true}
                    />{" "}
                  </Col>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="Bank A/C No *"
                      name="accountNo"
                      // isRequired={true}
                    />{" "}
                  </Col>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="Account holder name *"
                      name="accountName"
                      // isRequired={true}
                    />{" "}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="Account Type *"
                      name="accountType"
                      // isRequired={true}
                    />{" "}
                  </Col>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="MICR Code *"
                      name="micrCode"
                      // isRequired={true}
                    />{" "}
                  </Col>
                  <Col className="col-md-4 col-12">
                    {" "}
                    <TextField
                      type="text"
                      label="IFSC Code *"
                      name="ifscCode"
                      // isRequired={true}
                    />{" "}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-md-6 col-12">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Cancel Cheque"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "cancelChequeAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "cancelChequeAttachment",
                              file
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        {formikProps.values.cancelChequeAttachment && (
                          <img
                            className="bordered"
                            src={formikProps.values.cancelChequeAttachment}
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-6 col-12">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Work Order Attachment"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "workOrderAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "workOrderAttachment",
                              file
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        {formikProps.values.workOrderAttachment && (
                          <img
                            className="bordered"
                            src={formikProps.values.workOrderAttachment}
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-md-12">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Turnover Declartion for E_Invoice as per the GST Act"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "turnOverDeclarationAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "turnOverDeclarationAttachment",
                              file
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        {formikProps.values.turnOverDeclarationAttachment && (
                          <img
                            className="bordered"
                            src={
                              formikProps.values.turnOverDeclarationAttachment
                            }
                            alt="Preview"
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {/* <label
                      htmlFor="turnOverDeclarationAttachment"
                      className="text-darkgray fw-400 my-3"
                    >
                      Turnover Declartion for E_Invoice as per the GST Act
                      <span className="text-primary ms-1">*</span>
                    </label>
                    <input
                      className={`form-control py-1`}
                      id="fileUpload"
                      type="file"
                      name="turnOverDeclarationAttachment"
                      accept="image/png, image/heic, image/jpeg, image/jpg"
                      onChange={async (event: any) => {
                        const file = event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null;

                        if (file) {
                          const base64 = await readFileAsBase64(file);
                          formikProps.setFieldValue(
                            "turnOverDeclarationAttachmentbase64",
                            base64
                          );
                        }

                        formikProps.setFieldValue(
                          "turnOverDeclarationAttachment",
                          file
                        );
                      }}
                    /> */}
                  </Col>
                </Row>

                <div className="row my-4">
                  <div className="col">
                    <DefaultButton
                      type="submit"
                      variant="outline-primary"
                      className="default-action-button w-100"
                    >
                      Submit
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </FormData>
          );
        }}
      </Formik>
    </>
  );
};

export default OrganizationEditProfile;
