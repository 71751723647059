import React, { useEffect, useState } from "react";
import Footer from "components/Footer";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
// import axios from "utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "context/AuthContext";
import Loader from "components/Loader/Loader";
import UserLayout from "layouts/UserLayout";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const VerifySuccessComponent = () => {
  const { id } = useParams();
  const { token } = useAuth();
  const [isStatus, setIsStatus] = useState();
  const [isMessage, setIsMessage] = useState();
  const [isName, setIsName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  function VerifiedEmail() {
    setIsLoading(true);
    // axios.post(
    //   "/emailVerify",
    //   {
    //     token: id,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/emailVerify",
      data: {
        token: id,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, name, message } = res.data;
        if (status) {
          setIsStatus(status);
          setIsName(name);
        } else {
          setIsMessage(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    VerifiedEmail();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <ToastContainer />
        <UserLayout>
          {isStatus ? (
            <Container className="d-flex flex-column justify-content-center py-md-5">
              <div>
                <h1 className="fs-48">
                  <span className="text-primary">Congratulations,</span>{" "}
                  {isName}!
                </h1>
              </div>
              <div className="mt-md-3">
                <h2> Welcome to Ifinish Community!</h2>
              </div>
              <div className="mt-md-3">
                <h1>What's Next?</h1>
                <h4 className="mt-md-3">
                  <span>Log In:</span> Cruise over to our
                  <span
                    onClick={() => navigate("/")}
                    className="text-primary mx-md-1"
                    style={{ cursor: "pointer" }}
                  >
                    Login Page
                  </span>
                  and get started.
                </h4>
                <h4>
                  <span className="text-primary">Explore:</span> Uncover the
                  fantastic features designed just for you.
                </h4>
                <h4 className="mt-md-5">
                  <span className="text-primary">Any Queries?</span>
                </h4>
                <h4>
                  We've got your back at{" "}
                  <a href="#" className="fw-normal">
                    support@ifinish.in{" "}
                  </a>{" "}
                  or
                  <a href="#" className="fw-normal">
                    {" "}
                    +91-9985100008{" "}
                  </a>
                </h4>
              </div>
            </Container>
          ) : (
            <Container className="d-flex flex-column justify-content-center py-md-5">
              <h1 className="text-center">{isMessage}</h1>
            </Container>
          )}
        </UserLayout>
      </div>
    </>
  );
};

export default VerifySuccessComponent;
