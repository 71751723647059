import { Event } from "types/Event";
import moment from "moment";
import CryptoJS from "crypto-js";

export function getEventByEventSlug(events: Event[], eventSlug: string): Event {
  const event = events?.find((event) => event.event_slug === eventSlug);
  if (!event) {
    throw new Error(`Event with slug ${eventSlug} not found`);
  }
  return event;
}

export function replaceUnderScoreWithSpace(fieldName: string) {
  return fieldName.replace(/_/g, " ");
}

// sort events by event_end_date

export const sortEventsByEndDate = (events: Event[]): Event[] => {
  return events?.sort((a, b) => {
    const dateA = a?.event_end_date ? new Date(a?.event_end_date).getTime() : 0;
    const dateB = b?.event_end_date ? new Date(b?.event_end_date).getTime() : 0;

    return dateA - dateB;
  });
};

// to convert image to base64 format
export const readFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result && typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to read file as base64."));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// to convert Date Format DD-MM-YYYY

export const dateFormatConvert = async (date: string) => {
  return new Promise(() => {
    moment(date).format("MM-DD-YYYY");
  });
};

// to convert Date Format DD-MM-YYYY
export const dateFormatConvertDDMMYYYY = (date: string) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getEventRemainingDays = (eventStartDate: string) => {
  const now = new Date().getTime();
  const eventDate = new Date(eventStartDate).getTime();
  const remainingDays = Math.round((eventDate - now) / (1000 * 3600 * 24));
  return remainingDays;
};

// Utility function for URL-safe Base64 encoding
const base64URLEncode = (input: string): string => {
  return btoa(input).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

// Utility function for URL-safe Base64 decoding
const base64URLDecode = (input: string): string => {
  input = input.replace(/-/g, "+").replace(/_/g, "/");
  while (input.length % 4) {
    input += "=";
  }
  return atob(input);
};

export const encryptText = (originalText: string | number) => {
  const encrypted = CryptoJS.AES.encrypt(
    originalText?.toString(),
    `${process.env.REACT_APP_ENCRYPT_SECRET}`
  )?.toString();

  return base64URLEncode(encrypted);
};

export const decryptText = (encryptedText: string) => {
  const decrypted = CryptoJS.AES.decrypt(
    base64URLDecode(encryptedText),
    `${process.env.REACT_APP_ENCRYPT_SECRET}`
  ).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

// Regular expression for digits only
export const RE_DIGIT = new RegExp(/^\d+$/);

// Name Conversion aJay kumar C to Ajay Kumar C
export const nameConversion = (orginialName: string) => {
  const nameArray = orginialName.trim().split(" ");

  const convertedName = nameArray.map((name, index) => {
    let capitalizedName = "";
    if (index === 0 || nameArray.length === 1) {
      capitalizedName = `${
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
      }`;
      return capitalizedName;
    } else {
      capitalizedName += `${
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
      }`;
      return capitalizedName;
    }
  });

  return convertedName.join(" ");
};

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

type ProfileData = {
  [key: string]: string | boolean | number | null | undefined;
};

export function hasEmptyValue(data: ProfileData): boolean {
  for (const key in data) {
    // console.log(data[key]);

    if (data[key] === null || data[key] === undefined || data[key] === "") {
      return true;
    }
  }
  return false;
}

// to find out if the Registration Date of an Race has expired or not
export const hasExpired = (registrationEndDate: string, serverTime: string) =>
  moment(registrationEndDate).isBefore(moment(serverTime));

// to find out if the Registration started or not of an Race

export const isRegistrationStarted = (
  registrationStartDate: string,
  serverTime: string
) => moment(serverTime).isAfter(registrationStartDate);

function padZero(number: number) {
  return number < 10 ? `0${number}` : number;
}

export const millisecondsToHHMMSS = (milliseconds: number) => {
  // Convert milliseconds to seconds
  let seconds = Math.floor(milliseconds / 1000);

  // Calculate hours, minutes, and remaining seconds
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  // Format the result as HH:mm:ss from milliseconds
  let formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;

  return formattedTime;
};

// Regex for simple email validation
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Open link in new tab
export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

// Regex for PAN validation
export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

// Regex for accept Number and Alphabets Upto 10
export const alphaNumbericRegex = /^[A-Za-z0-9]{1,10}$/;

// Format the result as milliseconds from HH:MM:SS
export const HHMMSSTomilliseconds = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
  return String(totalMilliseconds);
};

export const NMDCRaceList = [
  {
    id: 2,
    race_name: "10K",
  },
  {
    id: 3,
    race_name: "Half Marathon",
  },
  {
    id: 4,
    race_name: "Marathon",
  },
  {
    id: 5,
    race_name: "Others",
  },
];

// input type file types
export const fileAllowedTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "image/heic",
];

// File size of 3MB
export const fileMaxSize = 3 * 1024 * 1024;

// Regex for accept Number and Alphabets Upto 15
export const alphaNumbericRegexGST = /^[A-Za-z0-9]{1,15}$/;

// GST Regex
export const gstRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

// to find out no of participant are opting charity BIB

export function isMultipleBIBCharitySelected(state: any) {
  let selectedBIBCounter = 0;
  state?.events?.map((event: any) => {
    event?.participants?.map((participant: any) => {
      if (participant?.is_charity_bib) {
        selectedBIBCounter++;
      }
    });
  });
  return selectedBIBCounter;
}

// Regex to accepts name alphabets and space
export const alphabetsSpaceRegex = /^[a-zA-Z ]+$/;

// Regex to accept alphabets and numeric
export const alphaNumeric = /^[a-zA-Z0-9]*$/;

// Regex to accept comma, hyphen, slash, numbers, text and space.
export const alphaNumericSpaceHypenSlashCommaRegex = /^[0-9a-zA-Z,\s\-\/]*$/;

// Regex to accept comma, hyphen, numbers, text and space.
export const alphaNumericSpaceHyphenCommaRegex = /^[0-9a-zA-Z, -]*$/;

// Regex to accept alphabets, number and space
export const alphaNumericSpace = /^[a-zA-Z0-9 ]+$/;

export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingLeft: "5px",
      backgroundColor: "#ffe9e9",
      width: "160px",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      paddingLeft: "5px",
      paddingRight: "8px",
    },
  },
};

export const itemsPerPageOptions = [10, 25, 50, 100];
