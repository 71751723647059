import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Tab, Tabs, Form } from "react-bootstrap";

import { object, string, number } from "yup";
import { TextArea, TextSelect } from "utils/FormControls";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import axios, { axiosPrivate } from "utils/axios";
import { decryptText } from "utils/utils";
import { useAuth } from "context/AuthContext";
import { GenderList, StatusList } from "utils/List";
import { toast } from "react-toastify";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import DefaultTextArea from "components/Inputs/DefaultTextArea";
import DefaultButton from "components/DefaultButton/DefaultButton";
import PreviewEmail from "OrganizerPages/previewEmail/PreviewEmail";
import Loader from "components/Loader/Loader";

interface Values {
  eventId: string;
  ticketId: string;
  gender: string;
  status: string;
  subject: string;
  body: string;
}

const EmailTabScreen = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { token } = useAuth();
  const [races, setRaces] = useState<any>([]);
  const [isEditorError, setIsEditorError] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [isOpenPreviewEmailModal, setIsOpenPreviewEmailModal] = useState<boolean>(false);
 

  const inputFile = useRef(null);
  const CONTAINER_HEIGHT = 300;

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
  };

  function CouponRaceList() {
    axios
      .post(
        "/couponRaceList",
        {
          eventId: eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        const { data } = res.data;
        const racesList: any = data?.filter((data: any) => data.raceId !== "0");

        const updateRaceList = racesList.map((raceItem: any) => ({
          name: raceItem.name,
          id: raceItem.raceId,
          amount: raceItem.amount,
          ticketId: raceItem.raceId,
        }));
        setRaces([...updateRaceList, { name: "All", id: "", amount: 0 }]);
      })
      .catch((err) => {
        console.log("err", err);
        return;
      });
  }

  useEffect(() => {
    if (eventId) {
      CouponRaceList();
    }
  }, []);

  const handleSubmit = async (values: Values) => {
    if (editorContent.length === 0) {
      setIsEditorError(true);
      return;
    } else {
      setIsEditorError(false);

      const gender = GenderList.filter(
        (gender) => gender.name === values.gender
      )[0]?.id;
      const status = StatusList.filter(
        (status) => status.name === values.status
      )[0]?.id;
      const ticketId = races.filter(
        (race: any) => race.name === values.ticketId
      )[0]?.id;

      const updatedValues = {
        ...values,
        body: editorContent,
        gender: gender,
        approveStatus: status,
        ticketId: ticketId,
      };

      axiosPrivate({
        url: "/sendBibEmail",
        method: "POST",
        data: updatedValues,
      })
        .then((res) => {
          const { status, message } = res.data;
          if (status) {
            toast.success(`${message}`, { autoClose: 1000 });
            formik.resetForm();
            setEditorContent('')
          } else {
            toast.error(`${message}`, { autoClose: 1000 });
          }
        })
        .catch((err) => {
          console.error("Error submitting form:", err);
          return;
        })
        .finally(
            ()=> setIsLoading(false)
        );
    }
  };

  const validationSchema = object().shape({
    eventId: string().required(),
    ticketId: string().required("Race Name is required field"),
    gender: string().required("Gender is required field"),
    status: string().required("Application Status is required field"),
    subject: string().required("Subject is required field"),
    body: string(),
  });

  const formik = useFormik({
    initialValues: {
      eventId: eventId,
      ticketId: "",
      gender: "",
      status: "",
      subject: "",
      body: "",
    },
    validationSchema: validationSchema,
    onSubmit:(values, { resetForm }) => {
      handleSubmit(values);
      resetForm();

    },
    enableReinitialize: true,
    
  });

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const log = () => {
    console.log(editorRef);
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
const HandlePreviweEmailModal = () =>{
  setIsOpenPreviewEmailModal(true);
  setIsEditorError(false)
}
  const handleClosePreviewEmailModal = () => setIsOpenPreviewEmailModal(false);

  return (
    <>

      { isLoading && <Loader />}
       {isOpenPreviewEmailModal && (
        <PreviewEmail
          isOpenPreviewEmailModal = {isOpenPreviewEmailModal}
          handleClosePreviewEmailModal={handleClosePreviewEmailModal}
          eventId={eventId}
          emailPreview= {editorContent}
        />
      )}


<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="px-0  d-flex flex-column justify-content-center">
        <>
          <Row className="">
            <Col xs={12} md={4}>
              <DefaultDropdown
                placeholder="Select Race Name *"
                label="Select Race Name *"
                value={formik.values.ticketId}
                onChange={formik.handleChange("ticketId")}
                options={races.map((race: any) => race.name)}
                isError={formik.touched.ticketId && !!formik.errors.ticketId}
                error={formik.errors.ticketId}
                disablePlaceholder={true}
              />
            </Col>
          </Row>
          <h4 className="my-4 text-primary">
            Select the participants who should receive the Email
          </h4>
          <Row className="">
            <Col xs={12} md={4}>
              <DefaultDropdown
                placeholder="Gender *"
                label="Gender *"
                value={formik.values.gender}
                onChange={formik.handleChange("gender")}
                options={GenderList.map((gender) => gender.name)}
                isError={formik.touched.gender && !!formik.errors.gender}
                error={formik.errors.gender}
                disablePlaceholder={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <DefaultDropdown
                placeholder="Application Status *"
                label="Application Status *"
                value={formik.values.status}
                onChange={formik.handleChange("status")}
                options={StatusList.map((status) => status.name)}
                isError={formik.touched.status && !!formik.errors.status}
                error={formik.errors.status}
                disablePlaceholder={true}
              />
            </Col>
            

          </Row>
          <Row className="my-4">
            <Col xs={12}>
              <DefaultTextArea
                placeholder="Subject *"
                label="Subject *"
                value={formik.values.subject}
                onChange={formik.handleChange("subject")}
                rows={2}
                isError={formik.touched.subject && !!formik.errors.subject}
                error={formik.errors.subject}
              />
            </Col>
          </Row>
        </>
      </div>
      <>
        <div className="mt-3">
          <p className="text-primary fw-400 fs-16 my-3">
            <span className="text-primary ms-1">{`Instructions: Replacement values in email template fields : Name, Email, Event, Race, Order Number etc., Ex: Hi {{name}}, Your Event {{event}}, Your Race {{race}}, Your Order number {{order_number}}, Category Name {{category_name}}, T-shirt Size {{tshirt_size}}, Gender {{gender}}, Date Of Birth {{dob}}, Race Date {{race_date}}, Charity Name {{charity_name}}, Charity Amount {{charity_amount}}, Charity Bib Name {{charity_bib_name}}, Charity Bib Amount {{charity_bib_amount}} , Pre Order Photos {{pre_order_photos}}, Bib Number {{assigned_bibs}}`}</span>
          </p>
          <Editor
            apiKey={process.env.REACT_APP_TINY_API_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={editorContent}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

              setup: (editor: {
                on: (arg0: string, arg1: () => void) => void;
                getContent: () => any;
              }) => {
                editor.on("change", () => {
                  handleEditorChange(editor.getContent());
                });
              },
            }}
          />
          <p className="mt-3 text-danger">
            {isEditorError && "Body is required field"}
          </p>

          <Row className="my-md-3 d-flex align-items-center justify-content-end">
          
            <Col className="d-flex justify-content-end">
              <div className="d-flex justify-content-end mt-4">
                <button
                  onClick={() => HandlePreviweEmailModal()}
                  className="btn btn-outline-primary mx-3"
                  type="button"
                  disabled={editorContent.length <= 0}
                  >Preview Email
                  
                </button>

                <button type="submit" className="btn btn-outline-primary">
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
    </Form>
    </>



  
  );
};

export default EmailTabScreen;
