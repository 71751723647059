import React, { useEffect, useState } from "react";
import { Icon } from "components/Icon";
import { useEvent } from "context/EventContext";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import {
  decryptText,
  millisecondsToHHMMSS,
  tableCustomStyles,
} from "utils/utils";
import Loader from "components/Loader/Loader";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import * as XLSX from "xlsx";
import moment from "moment";
import DefaultButton from "components/DefaultButton/DefaultButton";

const ParticipantBreakUp = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const [participantData, setParticipantData] = useState<any>([]);

  function participantBreakUpData() {
    setIsLoading(true);
    axiosPrivate({
      url: "/participantBreakUp",
      data: { eventId: eventId || storeEventId },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setParticipantData(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    participantBreakUpData();
  }, []);

  const approvedTotalRows = participantData.length;

  const approvedFilterResult: any = participantData.filter((e: any) =>
    e.email?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const approvedData = [
    {
      name: "Participant Id",
      selector: (row: any) => (row.pId ? row.pId : "—"),
      sortable: true,
    },
    {
      name: "RegistrationId",
      selector: (row: any) => (row.RegistrationId ? row.RegistrationId : "—"),
      sortable: true,
    },
    {
      name: "First name",
      selector: (row: any) => (row.firstName ? row.firstName : "—"),
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row: any) => (row.lastName ? row.lastName : "—"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Assigned Bib",
      selector: (row: any) => (row.assignedBib ? row.assignedBib : "—"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Corrals",
      selector: (row: any) => (row.corals ? row.corals : "—"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Registration Date",
      selector: (row: any) =>
        row.RegistrationDate ? row.RegistrationDate : "—",
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row: any) => (row.gender ? row.gender : "—"),
      sortable: true,
    },
    {
      name: "Phone No",
      selector: (row: any) => (row.mobileNumber ? row.mobileNumber : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: true,
      width: "220px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => (row.dateOfBirth ? row.dateOfBirth : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Age",
      selector: (row: any) => (row.age ? row.age : "—"),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row: any) => (row.category ? row.category : "—"),
      sortable: true,
    },
    {
      name: "Whatsapp No",
      selector: (row: any) => (row.whatsapp_number ? row.whatsapp_number : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Blood Group",
      selector: (row: any) => (row.blood_group ? row.blood_group : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Race Name",
      selector: (row: any) => (row.raceName ? row.raceName : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Elite Runner",
      selector: (row: any) => (row.is_elite ? row.is_elite : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Offline",
      selector: (row: any) => (row.is_offline ? row.is_offline : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Race Category Order Id",
      selector: (row: any) =>
        row.raceCategoryOrderId ? row.raceCategoryOrderId : "—",
      sortable: false,
      width: "140px",
    },
    {
      name: "Ticket Category Changed",
      selector: (row: any) =>
        row.isTicketCategoryChange ? row.isTicketCategoryChange : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Previous Race Name",
      selector: (row: any) => (row.changeTicketId ? row.changeTicketId : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Extra Amount Paid",
      selector: (row: any) => (row.categoryAmount ? row.categoryAmount : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Type",
      selector: (row: any) => (row.type ? row.type : "—"),
      sortable: true,
      width: "110px",
    },
    {
      name: "Registration Fee",
      selector: (row: any) => (row.RegistrationFee ? row.RegistrationFee : "—"),
      sortable: true,
      width: "160px",
    },
    {
      name: "Amount paid",
      selector: (row: any) => (row.amountPaid ? row.amountPaid : "—"),
      sortable: false,
      width: "120px",
    },
    {
      name: "Individual paid",
      selector: (row: any) => (row.individualPaid ? row.individualPaid : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "GST amount",
      selector: (row: any) => (row.gst ? row.gst : "—"),
      sortable: false,
      width: "120px",
    },
    {
      name: "Processing fee",
      selector: (row: any) => (row.processingFee ? row.processingFee : "—"),
      sortable: false,
      width: "130px",
    },
    {
      name: "GST on processing fee",
      selector: (row: any) =>
        row.gstProcessingFee ? row.gstProcessingFee : "—",
      sortable: false,
      width: "160px",
    },
    {
      name: "Payment Order Id",
      selector: (row: any) =>
        row.paymentGateWayId ? row.paymentGateWayId : "—",
      sortable: false,
      width: "160px",
    },
    {
      name: "Coupon Name",
      selector: (row: any) => (row.couponName ? row.couponName : "—"),
      sortable: false,
      width: "160px",
    },
    {
      name: "Company Name",
      selector: (row: any) => (row.companyName ? row.companyName : "—"),
      sortable: false,
      width: "160px",
    },
    {
      name: "Offline Company Name",
      selector: (row: any) => (row.company_name ? row.company_name : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "T-shirt size",
      selector: (row: any) => (row.t_shirt_size ? row.t_shirt_size : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Pre Order Photos",
      selector: (row: any) =>
        row.pre_order_photos ? row.pre_order_photos : "—",
      sortable: false,
      width: "130px",
    },

    {
      name: "Bib Name",
      selector: (row: any) => (row.bib_name ? row.bib_name : "—"),
      sortable: false,
      width: "140px",
    },

    {
      name: "Coupon Discount",
      selector: (row: any) => (row.couponDiscount ? row.couponDiscount : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Charity Bib Name",
      selector: (row: any) => (row.charityBibName ? row.charityBibName : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Charity Bib amount",
      selector: (row: any) =>
        row.charityBibAmount ? row.charityBibAmount : "—",
      sortable: false,
      width: "160px",
    },
    {
      name: "Charity Name",
      selector: (row: any) => (row.charityName ? row.charityName : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Charity Amount",
      selector: (row: any) => (row.charityAmount ? row.charityAmount : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "GST Number",
      selector: (row: any) => (row.gstNo ? row.charityName : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row: any) => (row.status ? row.status : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Running Group Name",
      selector: (row: any) =>
        row.running_group_name ? row.running_group_name : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Other Runing Groups",
      selector: (row: any) =>
        row.other_running_groups ? row.other_running_groups : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Qualifying Event",
      selector: (row: any) =>
        row.qualifying_event ? row.qualifying_event : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Qualifying Distance",
      selector: (row: any) =>
        row.qualifying_distance ? row.qualifying_distance : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Other Qualifying Distance",
      selector: (row: any) =>
        row.other_qualifying_distance ? row.other_qualifying_distance : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Qualifying Timing",
      selector: (row: any) =>
        millisecondsToHHMMSS(row.qualifying_timing)
          ? millisecondsToHHMMSS(row.qualifying_timing)
          : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Qualifying Timing Certificate Link",
      selector: (row: any) =>
        row.qualifying_timing_certificate_link
          ? row.qualifying_timing_certificate_link
          : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Uploaded Id Proof",
      selector: (row: any) => (row.upload_id_proof ? row.upload_id_proof : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "History of Health Issues",
      selector: (row: any) =>
        row.history_of_health_issues ? row.history_of_health_issues : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Other of Health Issues",
      selector: (row: any) =>
        row.other_health_issues ? row.other_health_issues : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Emergency Contact Name",
      selector: (row: any) =>
        row.emergency_contact_name ? row.emergency_contact_name : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Emergency Contact Number",
      selector: (row: any) =>
        row.emergency_contact_number ? row.emergency_contact_number : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Area",
      selector: (row: any) => (row.area ? row.area : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Street Address",
      selector: (row: any) => (row.street_address ? row.street_address : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "City",
      selector: (row: any) => (row.city ? row.city : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "State",
      selector: (row: any) => (row.state ? row.state : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Country",
      selector: (row: any) => (row.country ? row.country : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Pincode",
      selector: (row: any) => (row.pincode ? row.pincode : "—"),
      sortable: false,
      width: "150px",
    },
  ];

  // need to change this in ifinish2.0
  const exportToExcel = () => {
    const wsData = [
      [
        "Participant Id",
        "Registration Id",
        "Assigned Bib",
        "Corrals",
        "Registration Date",
        "Registration Fee",
        "Amount paid",
        "Individual paid",
        "Pre Order Photos",
        "Race Name",
        "Elite Runner",
        "Offline",
        "Race Category Order Id",
        "Ticket Category Changed",
        "Previous Race Name",
        "Extra Amount Paid",
        "Type",
        "Category",
        "Age",
        "First Name",
        "Last Name",
        "Mobile No",
        "Email",
        "Gender",
        "Date of Birth",
        // "Uploaded Id proof",
        "T-shirt Size",
        "Bib Name",
        "Whatsapp No",
        "Country",
        "State",
        "City",
        "Street Address",
        "Area",
        "Pincode",
        "Blood Group",
        "History of Health Issues",
        "Other of Health Issues",
        "Emergency Contact Name",
        "Emergency Contact Number",
        "Qualifying Event",
        "Qualifying Distance",
        "Other Qualifying Distance",
        "Qualifying Timing",
        "Qualifying Timing Certificate Link",
        "Running Group Name",
        "Other Runing Groups",
        "Status",
        "GST amount",
        "Processing Fee",
        "GST on Processing Fee",
        "Payment Order Id",
        "Coupon Name",
        "Company Name",
        "Offline Company Name",
        "Coupon Discount",
        "GST Number",
        "Charity Bib Name",
        "Charity Bib Amount",
        "Charity Name",
        "Charity Amount",
      ],
      ...participantData.map((item: any) => [
        item["pId"],
        item["RegistrationId"],
        item["assignedBib"],
        item["corals"],
        item["RegistrationDate"],
        item["RegistrationFee"],
        item["amountPaid"],
        item["individualPaid"],
        item["pre_order_photos"],
        item["raceName"],
        item["is_elite"],
        item["is_offline"],
        item["raceCategoryOrderId"],
        item["isTicketCategoryChange"],
        item["changeTicketId"],
        item["categoryAmount"],
        item["type"],
        item["category"],
        item["age"],
        item["firstName"],
        item["lastName"],
        item["mobileNumber"],
        item["email"],
        item["gender"],
        item["dateOfBirth"],
        // item["upload_id_proof"],
        item["t_shirt_size"],
        item["bib_name"],
        item["whatsapp_number"],
        item["country"],
        item["state"],
        item["city"],
        item["street_address"],
        item["area"],
        item["pincode"],
        item["blood_group"],
        item["history_of_health_issues"],
        item["other_health_issues"],
        item["emergency_contact_name"],
        item["emergency_contact_number"],
        item["qualifying_event"],
        item["qualifying_distance"],
        item["other_qualifying_distance"],
        [millisecondsToHHMMSS(item["qualifying_timing"])],
        item["qualifying_timing_certificate_link"],
        item["running_group_name"],
        item["other_running_groups"],
        item["status"],
        item["gst"],
        item["processingFee"],
        item["gstProcessingFee"],
        item["paymentGateWayId"],
        item["couponName"],
        item["companyName"],
        item["company_name"],
        item["couponDiscount"],
        item["gstNo"],
        item["charityBibName"],
        item["charityBibAmount"],
        item["charityName"],
        item["charityAmount"],
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`participantBreakUp_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`}`
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <div className="d-flex justify-content-end">
          <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
            <input
              type="text"
              className="w-100 border-0"
              placeholder="Search by email"
              style={{ outline: "none" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Icon iconName="Search" className="text-primary" size={18} />
          </div>
        </div>
        <div className="d-flex justify-content-end my-md-4">
          <DefaultButton
            type="submit"
            variant="primary"
            className="px-md-5 bg-railBlue text-white"
            onClick={() => {
              exportToExcel();
            }}
          >
            Download Excel
          </DefaultButton>
        </div>

        <div className="mt-md-2">
          <DataTable
            pagination
            paginationTotalRows={approvedTotalRows}
            // onChangePage={handleChangePage}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={25}
            columns={approvedData}
            data={approvedFilterResult}
            customStyles={tableCustomStyles}
          />
        </div>
      </div>
    </>
  );
};

export default ParticipantBreakUp;
