import React from "react";
import { Container, Image } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
const UpdateProfile = () => {
  return (
    <Container
      fluid
      className="d-none position-relative g-0 d-md-flex justify-content-center mb-5"
      style={{ height: "234px" }}
    >
      <Image
        className="w-100"
        src="/images/update-profile-bg.png"
        alt="hexagon"
        style={{
          height: "234px",
          opacity: "0.8",
          background:
            "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
          mixBlendMode: "exclusion",
        }}
      />
      <div className="position-absolute mt-3 text-white d-flex flex-column justify-content-center align-items-center">
        <span className="fs-40 ff-saira fw-700">Update Your Profile Today</span>
        <span className="fs-16 ff-ns fw-400">
          Enhance your experience by updating your profile and interests.
        </span>
        <div className="pt-5 d-flex flex-row">
          <div className="pe-2">
            <DefaultButton
              onClick={() => console.log("Button Alert")}
              className="default-action-button "
              variant="outline-light"
              type="button"
            >
              Learn More
            </DefaultButton>
          </div>
          <div className="ps-2 pb-3">
            <DefaultButton
              onClick={() => console.log("Button Alert")}
              className="default-action-button text-white "
              variant="primary"
              type="button"
            >
              update <Icon iconName="ChevronRight" />
            </DefaultButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UpdateProfile;
