import React, { useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import { object, string, number } from "yup";
import { TextField } from "utils/FormControls";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import moment from "moment";

const EditCouponsModal = ({
  showNmdcModal,
  handleNmdcClose,
  modalId,
  setReRender,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const couponSchema = object({
    validFrom: string(),
    validTo: string(),
  });
  const isEdit = !!modalId.valid_from;
  const CouponInitialValue = {
    validFrom: isEdit ? modalId.valid_from : "",
    validTo: "",
  };

  const handleSubmitCoupon = (values: any) => {
    const { validFrom, validTo } = values;

    axiosPrivate({
      url: "/editCoupon",
      data: {
        couponId: modalId.id,
        valid_from: validFrom,
        valid_to: validTo,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          handleNmdcClose();
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      });
  };
  return (
    <Modal show={showNmdcModal} onHide={handleNmdcClose} centered>
      <Modal.Header>Edit Coupon for {modalId.coupon_prefix}</Modal.Header>
      <Modal.Body>
        <Modal.Title className="mb-md-4">
          <Formik
            validationSchema={couponSchema}
            onSubmit={handleSubmitCoupon}
            initialValues={CouponInitialValue}
            enableReinitialize={true}
          >
            {({ handleSubmit, values }) => (
              <>
                <Form noValidate onSubmit={handleSubmit} className="px-md-4">
                  <Row className="my-md-2">
                    <Col md={12}>
                      <TextField
                        type="datetime-local"
                        name="validFrom"
                        label=" Valid From *"
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        type="datetime-local"
                        name="validTo"
                        label=" Valid To *"
                        min={moment(values.validFrom).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        // min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                      />
                    </Col>
                  </Row>
                  <Row className="text-center my-md-4">
                    <Col>
                      <DefaultButton
                        type="button"
                        variant="outline-primary"
                        className="default-action-button px-md-5"
                        onClick={handleNmdcClose}
                      >
                        Close
                      </DefaultButton>
                    </Col>
                    <Col>
                      <DefaultButton
                        type="submit"
                        variant="outline-primary"
                        className="default-action-button px-md-5"
                      >
                        Submit
                      </DefaultButton>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Title>
      </Modal.Body>
    </Modal>
  );
};

export default EditCouponsModal;
