import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import Loader from "components/Loader/Loader";
import {
  decryptText,
  itemsPerPageOptions,
  tableCustomStyles,
} from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import moment from "moment";
import { Icon } from "components/Icon";
import DefaultButton from "components/DefaultButton/DefaultButton";
interface RefundList {
  race_name: string;
  name: string;
  email: string;
  mobile_number: string;
  amount_paid: number;
  refund_amount: number;
  refund_date: string;
  refund_description: string;
  refund_by: string;
}
const RefundTabScreen = () => {
  const axiosPrivate = useAxiosPrivate();
  const { storeEventId } = useEvent();
  const { id } = useParams();

  const [refundList, setRefundList] = useState<RefundList[]>([]);
  const [isSelect, setIsSelect] = useState<any>([]);
  const [SelectRaceId, setSelectRaceId] = useState<any>();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: decryptText(String(id)) || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; raceId: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; raceId: any; amount: any }[] = [];
        arrayEarlyData.push({
          name: "All",
          raceId: "",
          amount: "",
        });
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            raceId: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              raceId: items.raceId,
              amount: items.amount,
            });
          }
        });
        setIsSelect(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }

  function getrefundList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getRefundDetails",
      data: {
        eventId: decryptText(String(id)) || storeEventId,
        raceId: SelectRaceId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setRefundList(data);
        } else {
          setRefundList([]);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getrefundList();
    CouponRaceList();
  }, [SelectRaceId]);

  const handleSelectOption = (e: any) => {
    setSelectRaceId(e.target.value);
    setIsDisplay(true);
  };

  const filterParticipantList = useMemo(() => {
    if (!searchValue) {
      return refundList;
    }
    return refundList.filter(
      (e: any) =>
        e.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        e.bib?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }, [refundList, searchValue]);

  const refundData = useMemo(() => {
    if (filterParticipantList.length === 0) return [];
    return Object.keys(filterParticipantList[0])
      .filter((key) => key !== "id")
      .map((key) => ({
        name: key.replace(/_/g, " ").toUpperCase(),
        selector: (row: any) => row[key] ?? "—",
      }));
  }, [filterParticipantList]);

  function exportexcel() {
    const participantKeys = Object.keys(refundList[0]);
    const wsData = [
      participantKeys.map((key) => key.replace(/_/g, " ").toUpperCase()), // Header row with formatted keys
      ...refundList.map((item: any) =>
        participantKeys.map((key) => item[key] ?? "-")
      ),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`Refunded_List${moment(new Date()).format("DD-MM-YYYY")}.xlsx`}`
    );
  }
  return (
    <>
      {isLoading && <Loader />}

      <Row className="my-2">
        <Col xs={12} md={5}>
          <div>
            <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
              <div className="col">
                <select onChange={handleSelectOption}>
                  <option value="">Choose an option</option>
                  {isSelect?.map((option: any) => {
                    return (
                      <>
                        <option
                          key={option.raceId}
                          value={option.raceId}
                          disabled={option.status}
                          onChange={option.raceId}
                        >
                          {option.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span>Please select the race *</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isDisplay && (
        <>
          <div className="d-flex justify-content-end">
            <div className="px-md-3 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="border-0"
                placeholder="Search by email, name, mobile"
                style={{ outline: "none", width: "320px" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
            <div className="d-flex justify-content-end ms-md-3">
              <DefaultButton
                type="submit"
                variant="primary"
                className="px-md-5 bg-railBlue text-white"
                onClick={() => {
                  exportexcel();
                }}
              >
                Download Excel
              </DefaultButton>
            </div>
          </div>
          <div className="mt-md-2">
            <DataTable
              pagination
              paginationRowsPerPageOptions={itemsPerPageOptions}
              columns={refundData}
              data={filterParticipantList}
              customStyles={tableCustomStyles}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RefundTabScreen;
