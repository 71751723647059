import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProfileDragAndDrop from "./ProfileForm";
import OrgDragAndDrop from "./OrganisationForm";
import { useAuth } from "context/AuthContext";
import PhysicalDragAndDrop from "./PhysicalForm";
import AddDragAndDrop from "./AdditionalForm";
import ManageFilterModal from "./ManageFilterModal";
import CreateFormFieldModal from "./CreateFormFieldModal";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import DefaultButton from "components/DefaultButton/DefaultButton";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const ManageForms = ({ handleSubmitNextHandler }: any) => {
  const { token, isAuthenticated } = useAuth();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const isViewEvent = ViewEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  let eventId: any;
  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
    }
  }, [eventId, id, isViewEvent]);

  const { storeEventId } = useEvent();
  const [profileItems, setProfileItems] = useState([]);
  const [orgItems, setOrgItems] = useState([]);
  const [physicalItems, setPhysicalItems] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [sectionId, setIsSectionId] = useState<any>();
  const [isHeading, setIsHeading] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let keys = Object.keys(isHeading);

  const [isShowManageModal, setIsShowManageModal] = useState(false);
  const [isCreateManageFormFieldModal, setIsCreateManageFormFiledModal] =
    useState(false);
  const [modalData, setModalData] = useState();

  const handleCloseManageModal = () => setIsShowManageModal(false);

  const handleCloseCreateManageModal = () =>
    setIsCreateManageFormFiledModal(false);

  function CustomField() {
    setIsLoading(true);
    // axios.post(
    //   "/customFields",
    //   {
    //     eventId: storeEventId || Id,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/customFields",
      data: { eventId: storeEventId || Id },
      method: "POST",
    })
      .then((res) => {
        setIsHeading(res.data.data);
        const data = res.data.data || {};
        setProfileItems(data.User_Profile_Details || []);
        setOrgItems(data.User_Address_Details || []);
        setPhysicalItems(data.User_Physical_Details || []);
        setAdditionalItems(data.User_Other_Info || []);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }

  function Sections() {
    setIsLoading(true);
    // axios.get("/sections", {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/sections",
      method: "GET",
    })
      .then((res) => {
        setIsSectionId(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (Id || storeEventId) {
      CustomField();
      Sections();
    }
  }, [isAuthenticated, token, isRefresh, Id]);

  function SubmittedData() {
    const formDataBySection: any = {};
    const Profile = sectionId
      .filter((item: any) => item.section_name === "User_Profile_Details")
      .map((item: any) => item.id);
    const Organization = sectionId
      .filter((item: any) => item.section_name === "User_Address_Details")
      .map((item: any) => item.id);
    const Physical = sectionId
      .filter((item: any) => item.section_name === "User_Physical_Details")
      .map((item: any) => item.id);
    const Additional = sectionId
      .filter((item: any) => item.section_name === "User_Other_Info")
      .map((item: any) => item.id);
    const sections = [
      { id: Profile[0], items: profileItems },
      { id: Organization[0], items: orgItems },
      { id: Physical[0], items: physicalItems },
      { id: Additional[0], items: additionalItems },
    ];
    sections.forEach((section) => {
      let positionCounter = 1;
      section.items.forEach((item: any) => {
        let data: any;
        if (item.field_status !== 2) {
          data = {
            id: item.id,
            sectionId: section.id,
            fieldName: item.field_name,
            position: positionCounter,
            fieldStatus: item.field_status,
            mandatoryField: item.mandatory_field,
            labelName: item.label_name,
            inputType: item.input_type,
            options: item.options,
          };
          positionCounter++;
        } else {
          data = {
            id: item.id,
            sectionId: section.id,
            fieldName: item.field_name,
            fieldStatus: item.field_status,
            mandatoryField: item.mandatory_field,
            labelName: item.label_name,
            inputType: item.input_type,
            options: item.options,
            position: null,
          };
        }

        if (!formDataBySection[section.id]) {
          formDataBySection[section.id] = [];
        }

        formDataBySection[section.id].push(data);
      });
    });
    return formDataBySection;
  }

  const handleSave = () => {
    setIsLoading(true);
    const formDataBySection = SubmittedData();
    // const formDataBySection: any = {};
    // const Profile = sectionId
    //   .filter((item: any) => item.section_name === "User_Profile_Details")
    //   .map((item: any) => item.id);
    // const Organization = sectionId
    //   .filter((item: any) => item.section_name === "User_Address_Details")
    //   .map((item: any) => item.id);
    // const Physical = sectionId
    //   .filter((item: any) => item.section_name === "User_Physical_Details")
    //   .map((item: any) => item.id);
    // const Additional = sectionId
    //   .filter((item: any) => item.section_name === "User_Other_Info")
    //   .map((item: any) => item.id);

    // const sections = [
    //   { id: Profile[0], items: profileItems },
    //   { id: Organization[0], items: orgItems },
    //   { id: Physical[0], items: physicalItems },
    //   { id: Additional[0], items: additionalItems },
    // ];
    // sections.forEach((section) => {
    //   let positionCounter = 1;
    //   section.items.forEach((item: any) => {
    //     let data: any;
    //     if (item.field_status !== 2) {
    //       data = {
    //         id: item.id,
    //         sectionId: section.id,
    //         fieldName: item.field_name,
    //         position: positionCounter,
    //         fieldStatus: item.field_status,
    //         mandatoryField: item.mandatory_field,
    //         labelName: item.label_name,
    //         inputType: item.input_type,
    //         options: item.options,
    //       };
    //       positionCounter++;
    //     } else {
    //       data = {
    //         id: item.id,
    //         sectionId: section.id,
    //         fieldName: item.field_name,
    //         fieldStatus: item.field_status,
    //         mandatoryField: item.mandatory_field,
    //         labelName: item.label_name,
    //         inputType: item.input_type,
    //         options: item.options,
    //         position: null,
    //       };
    //     }

    //     if (!formDataBySection[section.id]) {
    //       formDataBySection[section.id] = [];
    //     }

    //     formDataBySection[section.id].push(data);
    //   });
    // });
    const postData = {
      eventId: storeEventId || Id,
      data: { ...formDataBySection },
    };
    // axios.post("/submitFormFields", postData, {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/submitFormFields",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${res.data.message}`, { autoClose: 1500 });
          setTimeout(() => {
            handleSubmitNextHandler();
          }, 2000);
        } else {
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSoftSave = () => {
    const formDataBySection = SubmittedData();
    // axios.post(
    //   "/submitFormFields",
    //   JSON.stringify({
    //     eventId: storeEventId || id,
    //     data: { ...formDataBySection },
    //   }),
    //   {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    const postData = {
      eventId: storeEventId || Id,
      data: { ...formDataBySection },
    };
    axiosPrivate({
      url: "/submitFormFields",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        return;
      })
      .catch((err) => {
        return;
      });
  };
  const handleShowCreateManageModal = () => {
    setIsCreateManageFormFiledModal(true);
    {
      !isViewEvent && handleSoftSave();
    }
  };
  const handleShowManageModal = (data: any) => {
    setModalData(data);
    setIsShowManageModal(true);
    {
      !isViewEvent && handleSoftSave();
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <CreateFormFieldModal
        showModal={isCreateManageFormFieldModal}
        handleCloseCreateManageModal={handleCloseCreateManageModal}
        setIsRefresh={setIsRefresh}
      />
      <ManageFilterModal
        showModal={isShowManageModal}
        handleClose={handleCloseManageModal}
        modalData={modalData}
        setIsRefresh={setIsRefresh}
      />
      <ToastContainer />
      <Container>
        <div className="d-flex justify-content-between align-items-center mt-md-4">
          <div>
            <h2 className="text-primary">Manage Forms Fields</h2>
          </div>
          {!isViewEvent && (
            <div>
              <button
                className="btn btn-primary px-md-5 py-md-2 text-white"
                onClick={handleShowCreateManageModal}
              >
                Create Form Field
              </button>
            </div>
          )}
        </div>

        <DndProvider backend={HTML5Backend}>
          <div>
            <div className="mx-md-2">
              <ProfileDragAndDrop
                profileItems={profileItems}
                setProfileItems={setProfileItems}
                handleShowManageModal={handleShowManageModal}
                keys={keys}
              />
            </div>
          </div>
          <div className="my-md-4">
            <div className="mx-md-2">
              <OrgDragAndDrop
                orgItems={orgItems}
                setOrgItems={setOrgItems}
                handleShowManageModal={handleShowManageModal}
                keys={keys}
              />
            </div>
          </div>
          <div>
            <div className="mx-md-2">
              <PhysicalDragAndDrop
                physicalItems={physicalItems}
                setPhysicalItems={setPhysicalItems}
                handleShowManageModal={handleShowManageModal}
                keys={keys}
              />
            </div>
          </div>
          <div className="my-md-4">
            <div className="mx-md-2">
              <AddDragAndDrop
                additionalItems={additionalItems}
                setAdditionalItems={setAdditionalItems}
                handleShowManageModal={handleShowManageModal}
                keys={keys}
              />
            </div>
          </div>
        </DndProvider>
        {!isViewEvent && (
          <div className="mt-md-3 d-flex justify-content-end">
            <DefaultButton
              type="button"
              className="px-md-5"
              variant="primary"
              onClick={() => {
                handleSave();
              }}
            >
              Submit
            </DefaultButton>
          </div>
        )}
      </Container>
    </>
  );
};

export default ManageForms;
