import { Achievement } from "../types/Achievement";

export const Achievements: Achievement[] = [
  {
    id: 1,
    title: "Mumbai Marathon - 21K",
    place: "MUMBAI",
    date: "16 AUG 2022",
    time: "25:15",
    imageUrl: "/images/achievements-image1.png",
  },
  {
    id: 2,
    title: "Green Runners - 21K",
    place: "HYDERABAD",
    date: "16 AUG 2022",
    time: "25:15",
    imageUrl: "/images/achievements-image2.png",
  },
  {
    id: 3,
    title: "Green Runners - 21K",
    place: "HYDERABAD",
    date: "30 SEP 2022",
    time: "25:15",
    imageUrl: "/images/achievements-image3.png",
  },
  {
    id: 4,
    title: "Green Runners - 21K",
    place: "HYDERABAD",
    date: "16 Aug 2022",
    time: "25:15",
    imageUrl: "/images/achievements-image3.png",
  },
  {
    id: 5,
    title: "Mumbai Marathon - 21K",
    place: "HYDERABAD",
    date: "16 Aug 2022",
    time: "25:15",
    imageUrl: "/images/achievements-image1.png",
  },
];
