import React from "react";
import { useLocation } from "react-router-dom";

const ViewEvent = () => {
  const [isViewEvent, setIsViewEvent] = React.useState(false);
  const location = useLocation();
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata") || pathName.includes("viewevent")) {
      setIsViewEvent(true);
    }
  }, []);
  return isViewEvent;
};

export default ViewEvent;
