import { Image, Row, Col } from "react-bootstrap";
import TouchImage from "assets/images/touchImage.png";
import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DefaultButton from "components/DefaultButton/DefaultButton";
import DefaultInput from "components/Inputs/DefaultInput";
import Checkbox from "components/Inputs/Checkbox";

interface Props {
  handleSubmit: () => void;
}

const validationSchema = Yup.object({
  company: Yup.string().required("Company is required"),
  email: Yup.string().required("Email is required"),
});

const GetINTouchCard: React.FC<Props> = ({ handleSubmit }) => {
  const formik = useFormik({
    initialValues: {
      company: "",
      email: "",
      message: "",
      agree: false,
    },
    validationSchema,
    onSubmit: (values) => {
      //   localStorage.setItem("isIfinishLoogedIn", "true");
      //   window.location.reload();
    },
  });
  return (
    <>
      <Row className="mx-0">
        <Col
          md={6}
          style={{
            backgroundImage: `url(/images/TouchBG.png)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "500px",
          }}
        >
          <div>
            <Image
              style={{ marginTop: "240px", height: "191px" }}
              src="/images/touchImage.png"
              alt="TouchImage"
            />
          </div>
        </Col>
        <Col
          md={6}
          className="bg-gray400 d-flex justify-content-center align-items-center pb-4"
        >
          <div className="w-75">
            <Form onSubmit={formik.handleSubmit} className="mt-4">
              <DefaultInput
                className="mt-4"
                label="Company *"
                type="text"
                placeholder="Company"
                value={formik.values.company}
                onChange={formik.handleChange("company")}
                isError={formik.touched.company && !!formik.errors.company}
                error={formik.errors.company}
              />

              <DefaultInput
                className="mt-4"
                label="Email *"
                type="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                isError={formik.touched.email && !!formik.errors.email}
                error={formik.errors.email}
              />
              <DefaultInput
                className="mt-4"
                label="Message *"
                type="text"
                placeholder="Message"
                value={formik.values.message}
                onChange={formik.handleChange("password")}
                isError={formik.touched.message && !!formik.errors.message}
                error={formik.errors.message}
              />

              <Form.Check
                className="mt-3"
                checked={formik.values.agree}
                onChange={formik.handleChange("agree")}
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="mt-1 ms-3 fs-14 ff-ns fw-400">
                  I agree to the Terms
                </Form.Check.Label>
              </Form.Check>

              {/* <Checkbox
                className="mt-3"
                label="I agree to the Terms"
                checked={formik.values.agree}
                onChange={formik.handleChange("agree")}
              /> */}

              <DefaultButton
                variant="primary"
                type="submit"
                className="default-action-button text-white w-75 mt-3 mx-5 px-2"
              >
                SUBMIT
              </DefaultButton>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GetINTouchCard;
