import { useMutation } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

export const useLogin = () => {
  const axiosPublic = useAxiosPublic();
  return useMutation(async (params: any) => {
    const { email, password, type } = params;

    try {
      const response = await axiosPublic({
        method: "POST",
        url: "/login",
        data: {
          email: email,
          password: password,
          type: type,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error Loging in");
      throw error;
    }
  });
};
