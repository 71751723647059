import {
  Breadcrumb,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./EventResultPage.module.scss";
import MarathonTableContainer from "components/EventResult/MarathonTableContainer";

const EventResultPage = () => {
  const navigation = useNavigate();
  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/myevents")}
          >
            Events
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Results</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.coverImage}`}>
          <Image
            src="/images/event-result-banner.png"
            width="100%"
            alt="MarathonBaner"
          />
        </div>
        <Card
          className="border-0 shadow h-100"
          style={{
            height: "84px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
          }}
        >
          <Row className="d-flex justify-content-center align-items-center p-3">
            <Col xs={12} md={6} className="fs-14 d-flex flex-column">
              <span className=" fw-700 ff-saira">
                Discover our latest updates
              </span>
              <span className="ff-ns">
                Stay informed with our timely information and promotions
              </span>
            </Col>
            <Col xs={12} md={6} className="d-flex flex-row justify-content-end">
              <div className="px-2">
                <Image
                  src="/images/dark-telegram.png"
                  alt="telegram icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </div>
              <div className="px-2">
                <Image
                  src="/images/dark-fb.png"
                  alt="facebook icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </div>
              <div className="px-2">
                <Image
                  src="/images/dark-insta.png"
                  alt="instagram icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </div>
              <div className="px-2">
                <Image
                  src="/images/dark-x.png"
                  alt="x logo"
                  style={{ height: "24px", width: "24px" }}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
      <Container className="g-0">
        <Row className="">
          <Col xs={12} md={8} lg={9}>
            <Card className="border-0 shadow p-2" style={{ height: "380px" }}>
              <p className="fs-16 ff-saira fw-600">Entries by Age Category</p>
            </Card>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Card className="border-0 shadow p-2" style={{ height: "380px" }}>
              <p className="fs-16 ff-saira fw-600 pt-2">
                Category winning times
              </p>
              <Table
                striped
                borderless
                hover
                variant="white"
                className="fs-12 ff-ns"
              >
                <thead className="border-bottom bg-white">
                  <tr className="bg-light">
                    <th className="fw-400">Category</th>
                    <th className="fw-400">Male</th>
                    <th className="fw-400">Female</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>17-24</td>
                    <td>02:35:41</td>
                    <td>03:15:08</td>
                  </tr>
                  <tr>
                    <td>25-29</td>
                    <td>02:40:41</td>
                    <td>03:23:08</td>
                  </tr>
                  <tr>
                    <td>30-34</td>
                    <td>02:00:41</td>
                    <td>03:43:08</td>
                  </tr>
                  <tr>
                    <td>35-39</td>
                    <td>02:35:41</td>
                    <td>03:15:08</td>
                  </tr>
                  <tr>
                    <td>40-50</td>
                    <td>02:40:41</td>
                    <td>03:23:08</td>
                  </tr>
                  <tr>
                    <td>51-60</td>
                    <td>02:40:41</td>
                    <td>03:23:08</td>
                  </tr>
                  <tr>
                    <td>61-70</td>
                    <td>02:35:41</td>
                    <td>03:15:08</td>
                  </tr>
                  <tr>
                    <td>70+</td>
                    <td>02:40:41</td>
                    <td>03:23:08</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="g-0 mt-5">
        <Row className="">
          <Col xs={12} md={4} lg={3}>
            <Card className="border-0 shadow p-2" style={{ height: "380px" }}>
              <Row className="p-2">
                <Col xs={9} className="ff-ns fs-14">
                  Average Run Distance
                </Col>
                <Col xs={3} className="fs-14 ff-saira fw-700 text-end">
                  5K
                </Col>
              </Row>
              <Row className="p-2">
                <Col xs={9} className="ff-ns fs-14">
                  Average Run pase
                </Col>
                <Col xs={3} className="fs-14 ff-saira fw-700 text-end">
                  09:33
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={8} lg={9}>
            <Card className="border-0 shadow p-2" style={{ height: "380px" }}>
              <p className="fs-16 ff-saira fw-600 pt-2">
                Average Finish (H). Entries by Age Category
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
      <MarathonTableContainer className="mt-5" />
    </>
  );
};

export default EventResultPage;
