import { useState } from "react";
import { Modal, Button, Col, Row, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "utils/FormControls";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import DefaultButton from "./DefaultButton/DefaultButton";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";

const ForgotPasswordModal = ({
  isForgotPasswordModal,
  handleCloseForgotPasswordModal,
}: any) => {
  const [isMessage, setIsMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: "",
  };
  const axiosPublic = useAxiosPublic();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const onSubmit = (values: any, { resetForm }: any) => {
    setIsLoading(true);
    // axios
    //   .post(
    //     "/forgotPassword",
    //     {
    //       email: values.email,
    //     },
    //     {
    //       headers: {
    //         Accept: "application/json",
    //       },
    //     }
    //   )
    axiosPublic({
      url: "/forgotPassword",
      data: { email: values.email },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
          setTimeout(() => {
            handleCloseForgotPasswordModal();
          }, 2000);
          resetForm();
        } else {
          toast.error(`${message}`, { autoClose: 1500 });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        show={isForgotPasswordModal}
        onHide={handleCloseForgotPasswordModal}
        animation={false}
        centered
        fullscreen="md-down"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form noValidate>
              <Modal.Header className="border-primary">
                <Modal.Title>Forgot Password</Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-md-4">
                <Row>
                  <Col>
                    <TextField
                      type="email"
                      name="email"
                      label="Email Address *"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="border border-0 px-md-4 d-flex">
                <DefaultButton
                  type="submit"
                  className="default-action-button fw-bold text-white ms-4 px-md-5"
                  variant="primary"
                >
                  Submit
                </DefaultButton>
                <DefaultButton
                  type="button"
                  variant="outline-primary"
                  className="default-action-button px-md-5"
                  onClick={handleCloseForgotPasswordModal}
                >
                  Close
                </DefaultButton>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
