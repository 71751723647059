export const runnersgroup = [
  "AIM- Academy of Indore Marathon",
  "ActivNRG Athletic Club",
  "Amateur Runners Guwahati (ARG)",
  "Amdavad Distance Runners (ADR)",
  "Aurangabad Black Bucks",
  "Badaga Runners",
  "Bangalore Runners - BR",
  "Baroda Road Runners (BRR)",
  "Bathinda Runnners - BR",
  "Belgaum Hill Racers (BHR)",
  "BHUKMP",
  "Bhopal Runners",
  "BootCamp Yellow",
  "Chandigarh Distance Runners",
  "Chennai Runners",
  "Coimbatore Runners",
  "Delhi Runners Group (DRG)",
  "Dehradun Runners Club (DRC)",
  "Dream Runners",
  "Dwarka Xpress Runners",
  "East Delhi Runners Group",
  "Erode Road Runners",
  "Faridabad Runners",
  "Greater Noida Runners",
  "Gurgaon Road Runners - GRR",
  "Guwahati Runners",
  "Hubbali Runners",
  "Hyderabad Runners",
  "Indirapuram Runners (IPR)",
  "JAIPUR RUNNERS",
  "Jagan Fitness and Running Group",
  "Jammu Runners",
  "Jayanagar Jaguars",
  "Kalinga Runners",
  "Kolkata Road Runners (KRR)",
  "Kolkata Runners",
  "LetsRun",
  "Lucknow Panthers",
  "Ludhiana Distance Runners",
  "Madurai Runners",
  "Mangalore Runners",
  "Miles N Milers",
  "Menon Fitness Systems",
  "Mumbai Road Runners - MRR",
  "Mysoorunners",
  "Nellai Runners",
  "Noida Running Group (NRG)",
  "PACE MAKERS",
  "PCMC Runners",
  "PaceMakers",
  "Pune Running",
  "Pune Runners",
  "Pink City Runners",
  "RUNNING PUNTERS",
  "Runners of Trichy",
  "Running Punters",
  "Salt Lake Runners",
  "Salem Runners India",
  "Satara Hill Runners (SHR)",
  "Savio Stars",
  "Solapur Runners Association",
  "Soles Of Perinthalmanna",
  "Soles of Bangalore",
  "Soles of Cochin",
  "Soles of Kollam",
  "Striders",
  "Surti Runners",
  "TAI ITANAGAR RUNNERS",
  "Tirupur Runners",
  "TRY Squad",
  "Tuticorin Runners",
  "Vasco Sussegado Runners",
  "VijayawadaRunners (VR)",
  "Vizag Runners",
  "Yamunanagar Xtreme Warriors (YXW)",
  "Others",
];
