import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StepProgressBar from "components/StepProgress/StepProgress";
import ManageForms from "./manageforms/Index";
import DetailsForm from "./eventsforms/DetailsForm";
import RaceIntialPage from "./eventsforms/RaceIntialPage";
import Disclaimers from "./eventsforms/Disclaimers";
import CouponIntialPage from "./eventsforms/CouponIntialPage";
import { Icon } from "components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import ViewEvent from "utils/ViewEvent";
import { decryptText, encryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import Loader from "components/Loader/Loader";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";

const CreateEvents = () => {
  const allowNextStep = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEventName, setIsEventName] = useState("");
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState<any>();
  let eventId: any;
  const { companyAccount } = useAccount();
  const isViewer = companyAccount === OrgRoleEnum.Viewer;

  const Navigate = useNavigate();
  const { token } = useAuth();
  const isViewEvent = ViewEvent();
  const [showNextStepButton, setShowNextStepButton] = useState<boolean>(false);
  const [canGoNextStep, setCanGoNextStep] = useState<boolean>(false);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    if (isEditEvent || isViewer) {
      eventId = decryptText(String(id));
      setId(eventId);
    }
  }, []);
  function onFormSubmit() {}

  function handleSubmitNextHandler() {
    allowNextStep.current = true;
    setCanGoNextStep(!canGoNextStep);
  }

  function EventData() {
    setIsLoading(true);
    const postData = { eventId: eventId };
    // axios.post("/getEventStatus", postData, {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/getEventStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          //   setEventStatus(res.data.data.status);
          setIsEventName(data.event_name);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    {
      !isViewEvent && EventData();
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Container fluid>
        {isEditEvent && (
          <Container fluid className=" ps-md-4 ps-xxl-5">
            <Row className="mt-4 ms-md-5 ps-md-4">
              <div className="d-flex align-items-center ms-md-2">
                <span
                  className="text-primary fs-12 fw-600"
                  onClick={() =>
                    Navigate(`/org/eventDashBoard/${encryptText(Id)}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Icon iconName="ChevronLeft" size={18} className="me-2" />
                  Back to {isEventName} Dashboard
                </span>
              </div>
              {/* <h6 className="fs-24  mt-md-2 ">{isEventName}</h6> */}
            </Row>
          </Container>
        )}

        <StepProgressBar
          startingStep={0}
          showNextStepButton={showNextStepButton}
          goNextStep={canGoNextStep}
          allowNextStep={allowNextStep}
          wrapperClass="progress-wrapper-custom"
          onSubmit={onFormSubmit}
          steps={[
            {
              label: "Details",
              subtitle: "",
              name: "Details",
              content: (
                <DetailsForm
                  handleSubmitNextHandler={handleSubmitNextHandler}
                />
              ),
            },
            {
              label: "Races",
              subtitle: "",
              name: "Races",
              content: (
                <RaceIntialPage
                  handleSubmitNextHandler={handleSubmitNextHandler}
                />
              ),
            },
            {
              label: "Manage Forms",
              subtitle: "",
              name: "Manage Forms",
              content: (
                <ManageForms
                  handleSubmitNextHandler={handleSubmitNextHandler}
                />
              ),
            },
            {
              label: "Coupons",
              subtitle: "",
              name: "Coupons",
              content: (
                <CouponIntialPage
                  handleSubmitNextHandler={handleSubmitNextHandler}
                />
              ),
            },
            {
              label: "Disclaimers",
              subtitle: "",
              name: "Disclaimers",
              content: (
                <Disclaimers
                  handleSubmitNextHandler={handleSubmitNextHandler}
                />
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

export default CreateEvents;
