import { Card, Image } from "react-bootstrap";
import { Payment } from "types/UserPaymentStatus";
import { dateFormatConvertDDMMYYYY } from "utils/utils";

interface PaymentStatusCardProps {
  payment: Payment;
}

const PaymentStatusCard: React.FC<PaymentStatusCardProps> = ({ payment }) => {
  const paymentStatusTheme = () => {
    let currentPaymentStatus;

    if (payment.paymentStatus === "S") {
      currentPaymentStatus = {
        bgColor: "bg-success",
        status: "Success",
      };
    } else if (payment.paymentStatus === "P") {
      currentPaymentStatus = {
        bgColor: "bg-warning",
        status: "Pending",
      };
    } else if (payment.paymentStatus === "F") {
      currentPaymentStatus = {
        bgColor: "bg-danger",
        status: "Failed",
      };
    }

    return currentPaymentStatus;
  };

  return (
    <Card
      className="mb-4 border-0"
      style={{
        width: "348px",
        height: "282px",
        flexShrink: "0",
        cursor: "pointer",
        boxShadow: "0px 0px 25px 0px rgba(13, 15, 31, 0.07)",
      }}
      position-relative
    >
      <Card.Body className="d-flex flex-column p-4">
        <div className="d-flex align-items-center pb-4">
          <Image
            height={40}
            width={40}
            src={
              payment?.organizerLogo
                ? payment?.organizerLogo
                : `/images/defaultorganiserlogo.svg`
            }
            alt="Organizer logo"
          />
          <span className="ms-3 fs-14 ff-ns text-darkGray fw-600">
            {payment?.orgName}
          </span>
        </div>
        <Card.Title>
          <h1 className="fw-600">{payment?.eventName}</h1>
        </Card.Title>
        <Card.Text className="fs-14 ff-ns fw-400 text-darkGray">
          Amount -
          <span className="fw-bold ms-1">
            {payment.amount ? (
              <>
                <span style={{ marginRight: "0.15rem" }}>&#8377;</span>
                {payment.amount + "/-"}
              </>
            ) : (
              "N/A"
            )}
          </span>
        </Card.Text>
        <div className="d-flex align-items-end justify-content-between">
          <div>
            <Card.Text className="fs-14 ff-ns fw-400 text-darkGray">
              Payment Date -{" "}
              {payment.paymentDate
                ? dateFormatConvertDDMMYYYY(payment?.paymentDate)
                : "N/A"}
            </Card.Text>
            <Card.Text className="fs-14 ff-ns fw-400 text-darkGray">
              Event Date - {dateFormatConvertDDMMYYYY(payment?.eventDate)}
            </Card.Text>
          </div>
          <div
            className={`fs-12 text-light text-uppercase fw-semi-bold px-2 py-1 rounded ${
              paymentStatusTheme()?.bgColor
            }`}
          >
            {paymentStatusTheme()?.status}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PaymentStatusCard;
