import React from "react";
import { Carousel, Container, Image } from "react-bootstrap";

interface DashBannerProps {
  className: string;
  banners: string[];
}
const DashBanner: React.FC<DashBannerProps> = ({ className, banners }) => {
  return (
    <Container className={`my-5 ${className}`}>
      <Carousel indicators={false} className="custom-carousel">
        {banners?.map((banner, idx) => (
          <Carousel.Item key={idx}>
            <Image src={banner} width="100%" alt="banner-image" />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default DashBanner;
