import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";

interface LayoutProps {
  children: ReactNode;
}
const HydRunnerLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="p-0 m-0">
      <nav className="shadow-sm px-3 bg-white rounded py-md-4 d-none d-md-block">
        <Container fluid className="d-flex align-items-center py-md-2 m-0">
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
          <div className="mx-4">
            <img src="/images/hydRunnersLogo.png" alt="Logo" />
          </div>
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
        </Container>
      </nav>
      <Container className="d-flex flex-column justify-content-center py-md-5">
        {children}
      </Container>
    </div>
  );
};

export default HydRunnerLayout;
