import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import CheckIcon from "components/Icons/CheckIcon";
import PendingIcon from "components/Icons/PendingIcon";
import FailedIcon from "components/Icons/FailedIcon";

interface Props {
  isOpenProfileModal: boolean;
  handleCloseProfileModal: () => void;
  paymentData: any;
}

const EventPaymentSuccessModal: React.FC<Props> = ({
  isOpenProfileModal,
  handleCloseProfileModal,
  paymentData,
}) => {
  const dateObject = new Date(paymentData.paymentDate * 1000);
  const userFormattedDate = dateObject.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return (
    <div>
      <Modal
        show={isOpenProfileModal}
        onHide={handleCloseProfileModal}
        centered
      >
        <Modal.Header
          className="border-white px-md-4 mt-2"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pb-5 pt-0">
          <div className="justify-content-center d-flex ">
            {paymentData.paymentStatus === "failed" ? (
              <FailedIcon className="bg-danger" />
            ) : paymentData.paymentStatus === "authorized" ||
              paymentData.paymentStatus === "captured" ||
              paymentData.paymentStatus === "created" ? (
              <CheckIcon className="bg-success3" />
            ) : (
              <PendingIcon className="bg-warning2" />
            )}
          </div>
          <div className="justify-content-center d-flex mt-2 pt-3">
            <h1 className="text-lightBlack">
              {paymentData.paymentStatus === "failed"
                ? "Payment Failed!"
                : paymentData.paymentStatus === "authorized" ||
                  paymentData.paymentStatus === "captured" ||
                  paymentData.paymentStatus === "created"
                ? "Payment Success!"
                : "Payment Pending!"}
            </h1>
          </div>
          <div className="justify-content-center d-flex mt-2">
            <h6 className="text-deepBlack noto_font_lists">
              {paymentData.paymentStatus === "failed"
                ? "Your payment has been Failed. Please try again."
                : paymentData.paymentStatus === "authorized" ||
                  paymentData.paymentStatus === "captured" ||
                  paymentData.paymentStatus === "created"
                ? "Your payment has been Successfully done."
                : "Your payment is Pending!"}
            </h6>
          </div>

          {(paymentData.paymentStatus === "authorized" ||
            paymentData.paymentStatus === "captured" ||
            paymentData.paymentStatus === "created") && (
            <div>
              <div className="mx-5 mt-3">
                <hr style={{ borderTop: "1px solid #707070", margin: "0" }} />
              </div>
              <div className="justify-content-center d-flex mt-3">
                <h6 className="text-deepBlack noto_font_lists">
                  Total Payment
                </h6>
              </div>
              <div className="justify-content-center d-flex mt-1">
                <h1 className="text-lightBlack">
                  Rs {(paymentData.paymentAmount / 100)?.toFixed(2)}
                </h1>
              </div>
            </div>
          )}
          {(paymentData.paymentStatus === "authorized" ||
            paymentData.paymentStatus === "captured" ||
            paymentData.paymentStatus === "created" ||
            paymentData.paymentStatus === "failed") && (
            <div>
              {" "}
              <div className="mx-2 rounded border border-veryLightGray border-1 px-2 my-2 py-1">
                <div className="mt-2">
                  <h6 className="text-gray noto_font_lists">Payment Time</h6>
                </div>
                <div className="mt-1">
                  <h2 className="text-lightBlack">{userFormattedDate}</h2>
                </div>
              </div>
              <div className="mx-2 rounded border border-veryLightGray border-1 px-2 my-2 py-1">
                <div className="mt-2">
                  <h6 className="text-gray noto_font_lists">Ref Number</h6>
                </div>
                <div className="mt-1">
                  <h2 className="text-lightBlack">{paymentData.paymentId}</h2>
                </div>
              </div>
              <div className="mx-2 rounded border border-veryLightGray border-1 px-2 my-2 py-1">
                <div className="mt-2">
                  <h6 className="text-gray noto_font_lists">Payment Method</h6>
                </div>
                <div className="mt-1">
                  <h2 className="text-lightBlack">
                    {paymentData.paymentMethod}
                  </h2>
                </div>
              </div>
              <div className="mx-2 rounded border border-veryLightGray border-1 px-2 my-2 py-1">
                <div className="mt-2">
                  <h6 className="text-gray noto_font_lists">Sender Email</h6>
                </div>
                <div className="mt-1">
                  <h2 className="text-lightBlack">{paymentData.senderEmail}</h2>
                </div>
              </div>
            </div>
          )}

          {/* {(paymentData.paymentStatus === "authorized" ||
            paymentData.paymentStatus === "captured" ||
            paymentData.paymentStatus === "created") && (
            <div className=" justify-content-center d-flex">
              <Button
                onClick={() => alert("Get PDF Receipt")}
                className="default-action-button  fw-bold text-white my-4 fs-14"
                variant="primary"
              >
                Get PDF Receipt
              </Button>
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default React.memo(EventPaymentSuccessModal);
