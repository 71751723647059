import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "components/Icon";
import useEventName from "utils/useEventName";
import RegistrationTabScreen from "OrganizerPages/eventDetails/registrationTabScreen";

const ApproverDashboard = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const eventName = useEventName();
  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
          </Col>
        </Row>
        <Row>
          <RegistrationTabScreen />
        </Row>
      </Container>
    </>
  );
};

export default ApproverDashboard;
