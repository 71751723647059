import { Container, Image } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import styles from "./NoPayment.module.scss";

const NoPayment = () => {
  const navigation = useNavigate();

  return (
    <Container className="d-flex flex-column align-items-center pb-5">
      <span className={`${styles.title} pt-5`}>Hey!</span>
      <span className={`${styles.title} pb-5`}>Welcome to iFinish</span>
      <Image
        style={{ height: "202px", width: "199px" }}
        src="/images/NoPayments.svg"
        alt="wishlist image"
      />
      <span className={`${styles.subTitle} pt-4`}>No payments done yet</span>
      <span className="fs-14 pt-2 pb-4">
        You have not purchased any tickets yet. Please head to the events page
        and purchase your ticket now!
      </span>
      <DefaultButton
        onClick={() => navigation("/")}
        variant="primary"
        className="text-white customBtn-width"
        type="button"
      >
        Explore Events
        <Icon iconName="ChevronRight" size={16} />
      </DefaultButton>
    </Container>
  );
};

export default NoPayment;
