import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { number, object, string } from "yup";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import { useAuth } from "context/AuthContext";
import Loader from "components/Loader/Loader";
import { TextField } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";

const RefundModel = ({
  uploadShow,
  handleUploadClose,
  selectedRowAmount,
  selectedRows,
  setRefundList,
}: any) => {
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  // const [confirmRefund, setConfirmRefund] = useState(false);

  const initialValues = {
    amount: "",
    reason: "",
  };
  const validation = object({
    amount: number()
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .required("Amount is a required field")
      .test(
        "is-valid-refund",
        "The refund amount should be less than or equal to the amount paid by the participant.",
        function (value: number) {
          // const { selectedRowAmount } = this.options.context;
          return value <= selectedRowAmount;
        }
      ),
    reason: string().required("Reason is a required field"),
  });
  const handleSubmit = (values: any) => {
    setIsLoading(true);

    // if (selectedRowAmount <= values.amount) {
    //   toast.error(
    //     "The refund amount should be less than or equal to the amount paid by the participant."
    //   );
    // } else {
    // }

    axiosPrivate({
      url: "/intiateRefund",
      data: {
        amount: values.amount,
        description: values.reason,
        supportId: userId,
        participantId: selectedRows[0],
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(message, { autoClose: 1000 });
          handleUploadClose();
          setTimeout(() => {
            setRefundList([]);
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Modal show={uploadShow} onHide={handleUploadClose} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Enter Refund Amount
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="py-md-3 px-md-0"
                >
                  <Row className="px-md-3">
                    <Col>
                      <h5>Amount paid by Participant {selectedRowAmount}</h5>
                    </Col>
                  </Row>
                  <Row className="px-md-3 mx-0 d-flex align-items-end justify-content-center">
                    <Col md={12} className=" px-md-0 mx-0 my-md-2 ">
                      <div>
                        <TextField
                          name="amount"
                          type="text"
                          label="Enter Refund Amount *"
                        />
                      </div>
                    </Col>
                    <Col md={12} className=" px-md-0 mx-0 my-md-2">
                      <div>
                        <TextField name="reason" type="text" label="Reason *" />
                      </div>
                    </Col>

                    <Col
                      md={12}
                      className=" d-flex justify-content-center my-md-3"
                    >
                      <DefaultButton
                        type="submit"
                        variant="outline-primary"
                        className=" default-action-button w-50"
                      >
                        Submit
                      </DefaultButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RefundModel;
