import React from "react";
import { Card, Table } from "react-bootstrap";

const MarathonTable = () => {
  return (
    <Card className=" shadow mt-5">
      <Table hover className="fs-14 ff-saira cenetred mb-0 ">
        <thead
          className="border-bottom bg-white fw-700"
          style={{ height: "50px" }}
        >
          <tr className="text-center ">
            <th className="border-end">PLACE</th>
            <th className="border-end">BIB ID</th>
            <th className="border-end">FINISHER</th>
            <th className="border-end">AGE</th>
            <th className="border-end">NET TIME</th>
            <th>DIFF</th>
          </tr>
        </thead>
        <tbody className="text-center fw-600">
          <tr>
            <td>01</td>
            <td>HYD002</td>
            <td>Mark Weinhoffer</td>
            <td>19</td>
            <td>02:21:08</td>
            <td>+00:00 </td>
          </tr>
          <tr>
            <td>02</td>
            <td>HYD003</td>
            <td>Peter Geithner</td>
            <td>24</td>
            <td>02:00:12</td>
            <td>+01:09 </td>
          </tr>
          <tr>
            <td>03</td>
            <td>HYD004</td>
            <td>Ellas Tousley</td>
            <td>41</td>
            <td>01:32:12</td>
            <td>+20:01 </td>
          </tr>
          <tr>
            <td>04</td>
            <td>HYD005</td>
            <td>Mark Weinhoffer</td>
            <td>19</td>
            <td>02:21:08</td>
            <td>+00:00 </td>
          </tr>
          <tr>
            <td>05</td>
            <td>HYD006</td>
            <td>Peter Geithner</td>
            <td>24</td>
            <td>02:00:12</td>
            <td>+01:09 </td>
          </tr>
          <tr>
            <td>06</td>
            <td>HYD007</td>
            <td>Ellas Tousley</td>
            <td>41</td>
            <td>01:32:12</td>
            <td>+20:01 </td>
          </tr>
          <tr>
            <td>07</td>
            <td>HYD350</td>
            <td>Mark Weinhoffer</td>
            <td>19</td>
            <td>02:21:08</td>
            <td>+00:00 </td>
          </tr>
          <tr>
            <td>08</td>
            <td>HYD009</td>
            <td>Peter Geithner</td>
            <td>24</td>
            <td>02:00:12</td>
            <td>+01:09 </td>
          </tr>
          <tr>
            <td>09</td>
            <td>HYD0012</td>
            <td>Ellas Tousley</td>
            <td>41</td>
            <td>01:32:12</td>
            <td>+20:01 </td>
          </tr>
          <tr>
            <td>10</td>
            <td>HYD0015</td>
            <td>Ellas Tousley</td>
            <td>41</td>
            <td>01:32:12</td>
            <td>+20:01 </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default MarathonTable;
