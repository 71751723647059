import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import { TextField } from "utils/FormControls";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useAuth } from "context/AuthContext";
import axios from "utils/axios";
// import { useAccount } from "context/AccountContext";
import { ToastContainer, toast } from "react-toastify";
import ForgotPasswordModal from "components/ForgotPasswordModal";
import OrganiserSignUpForm2 from "components/Forms/OrganizerForms/OrganiserSignUpForm2";
import {
  divStyle,
  formContainerStyle,
  transparentBoxStyle,
} from "utils/OrgSignupPageCss";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { useEvent } from "context/EventContext";

const OrgLoginPage = () => {
  const Navigate = useNavigate();
  const axiosPublic = useAxiosPublic();

  // const { updateAccount } = useAccount();
  const { setAccountId } = useEvent();
  const [isForgotPasswordModal, setIsForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  const initialValues = {
    Username: email,
    password: password,
  };
  const validationSchema = Yup.object({
    Username: Yup.string().required("Email is required"),
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .required("Password is required"),
  });
  const handleCloseForgotPasswordModal = () => setIsForgotPasswordModal(false);
  const handleShowForgotPasswordModal = () => setIsForgotPasswordModal(true);

  const onSubmit = (values: any) => {
    setIsLoading(true);
    const { Username, password } = values;
    setEmail(Username);
    setPassword(password);
    axiosPublic({
      url: "/login",
      data: {
        email: Username,
        password: password,
        type: "org",
      },
      method: "POST",
    }).then((response: any) => {
        const { status, message, isSignUpComplete, accountId } = response.data;

        if (status) {
          setEmail("");
          setPassword("");

          login(
            response.data.token,
            response.data.data[0]?.id,
            response.data.data[0]?.is_super_admin,
            null,
            response.data.data[0]?.email,
            response.data.data[0]?.mobile_number,
            response.data.data.profileImage
          );
          localStorage.setItem("ifinishToken", response.data.token);
          // updateAccount(response.data.data[0]?.account);
          Navigate("/org/accounts");
        } else {
          if (isSignUpComplete === undefined) {
            toast.error(`${message}`, { autoClose: 1500 });
            setPassword("");
          } else {
            if (!isSignUpComplete) {
              Navigate("/org/signup/step2");
              toast.error(`${message}`);
              setAccountId(accountId);
            }
          }
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  const LoginComponent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <>
              <Form className=" px-md-5">
                <div className="px-xxl-5">
                  <div className="row">
                    <div className="col-12 my-2 my-md-2">
                      <TextField
                        type="text"
                        name="Username"
                        label="Username *"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <TextField
                        type="password"
                        name="password"
                        label="Password *"
                      />
                    </div>
                  </div>

                  <div className="row mt-md-3">
                    <div
                      className="col-12 d-flex justify-content-end fw-600 "
                      onClick={handleShowForgotPasswordModal}
                      style={{ cursor: "pointer" }}
                    >
                      <small className=" text-purple">Forgot password ?</small>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col">
                      <button
                        className="btn btn-primary py-2 rounded-2 fw-normal text-white fw-bold w-100"
                        type="submit"
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="purple"
                              size="sm"
                              className="me-3"
                            />
                            Logging in..
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <ForgotPasswordModal
        isForgotPasswordModal={isForgotPasswordModal}
        handleCloseForgotPasswordModal={handleCloseForgotPasswordModal}
      />
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col
            md={7}
            className="d-flex justify-content-md-center align-items-md-center"
            style={{
              background: "#62261D75",
              backgroundImage:
                window.innerWidth > 1440
                  ? "url(/images/Login_big_screen.png)"
                  : "url(/images/Combine_login.png)",
            }}
          >
            <img src="/images/Login_Page_logo_org.svg" alt="Logo" />
          </Col>

          <Col
            md={5}
            xs={12}
            className=" d-flex flex-column vh-100 justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div
              style={{ flexGrow: "1" }}
              className="w-100 d-flex justify-content-center flex-column px-md-5"
            >
              <h1 className="d-flex justify-content-center my-md-3 text-uppercase">
                Organisation Login
              </h1>
              <div>
                <LoginComponent />
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <p>
                Not an event organiser?
                <button
                  className="btn text-primary px-1"
                  onClick={() => Navigate("/")}
                >
                  Login
                </button>
                as participant/user
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p>
                Are you an new event organiser? Click here to
                <button
                  className="btn text-primary px-1 pt-1"
                  onClick={() => Navigate("/org/signup")}
                >
                  Signup
                </button>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrgLoginPage;
