import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { decryptText } from "../../../../utils/utils";
import { toast } from "react-toastify";
import { useUserParticipants } from "api/GetUsersParticipants";
import DefaultButton from "components/DefaultButton/DefaultButton";
import UpdateParticipantsDetailsModal from "./UpdateParticipantDetailsModal";
import Loader from "components/Loader/Loader";
import { useCategoryContext } from "store/event/CategoryContext";
import { UpdateRace } from "types/UpdateRace";

const ChangeCategory = () => {
  const encryptedBookingId = useParams().bookingId as string;
  const eventSlug = useParams().eventSlug as string;
  const [participantsList, setParticipantsList] = useState([]);

  const [participantId, setParticipantId] = useState<number>(0);
  const [raceName, setRaceName] = useState<string>("");
  const [userBookingId, setUserBookingId] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setChangingCategoryForRace } = useCategoryContext();

  const bookingId = parseInt(decryptText(encryptedBookingId));

  const {
    data: participants,
    isLoading: participantsLoading,
    error: participantsError,
  } = useUserParticipants(bookingId);

  useEffect(() => {
    if (participants?.data) {
      const groupByRaceName = participants.data.reduce(
        (result: any, obj: any) => {
          const raceName = obj.raceName;
          if (!result[raceName]) {
            result[raceName] = { raceName, data: [] };
          }
          result[raceName].data.push(obj);
          return result;
        },
        {}
      );

      setUserBookingId(bookingId);
      setParticipantsList(Object.values(groupByRaceName));
    }
  }, [participants?.data]);

  if (participantsLoading) {
    return <Loader />;
  }

  if (participantsError) {
    toast.error(
      "Error loading fetching participants for event. Please try again later."
    );
  }

  const onHide = () => {
    setShow(false);
    setParticipantId(0);
    setRaceName("");
  };

  const onChangeCategory = (participant: UpdateRace) => {
    navigate(`/eventdetails/${eventSlug}`);
    setChangingCategoryForRace(participant);
  };

  return (
    <>
      <div>
        {participantsList.map((participant: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <h3 className="mb-2 text-capitalize">{participant?.raceName}</h3>
              {/* <h4 className="mb-1">Participants</h4> */}
              {participant?.data.map((user: UpdateRace) => {
                return (
                  <Container key={user.participantId} className="px-0 my-3">
                    <Row className="my-1 align-items-center">
                      <Col xs={7} md={8}>
                        <p className="mb-0">{user?.userName}</p>
                      </Col>
                      <Col xs={5} md={4}>
                        {/* <DefaultButton
                          onClick={() => {
                            setParticipantId(user.participantId);
                            setShow(true);
                            setRaceName(user.raceName);
                          }}
                          type="button"
                          className="default-action-button text-white px-3 py-1"
                          variant="primary"
                        >
                          Edit
                        </DefaultButton> */}
                        <DefaultButton
                          onClick={() => onChangeCategory(participant)}
                          type="button"
                          className="default-action-button text-white px-3 py-1"
                          variant="primary"
                          disabled={user?.isChanged}
                        >
                          Change Category
                        </DefaultButton>
                      </Col>
                    </Row>
                  </Container>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      {show && (
        <UpdateParticipantsDetailsModal
          bookingId={userBookingId}
          participantId={participantId}
          raceName={raceName}
          show={show}
          onHide={onHide}
        />
      )}
    </>
  );
};

export default ChangeCategory;
