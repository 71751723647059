import React, { useState, useEffect } from "react";
import { Form as Formdata, Formik } from "formik";
import { object, string, number } from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { TextField, TextSelect } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { Icon } from "components/Icon";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import { useEventDashboard } from "utils/useCreateEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useCouponDashboard } from "utils/useCoupon";

const BulkDiscount = ({ setIntialScreen, handleReloadScreen }: any) => {
  const { token } = useAuth();

  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const isEventDashboard = useEventDashboard();
  // let isAddMode: any;
  // const [isRaceList, setIsRaceList] = useState<any>([]);
  // const [isEarlyRaceList, setIsEarlyRaceList] = useState<any>([]);
  const { storeEventId } = useEvent();
  const [isBulkDiscountType2, setIsBulkDiscountType2] = useState(false);
  const [isBulkDiscountType3, setIsBulkDiscountType3] = useState(false);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isViewEvent = ViewEvent();
  const [arrayData, setArrayData] = useState<any>([]);
  const [numberOfCoupons2, setNumberOfCoupon2] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const isCouponDashboard = useCouponDashboard();
  // const [isMinNumberBulkDiscountType1, setIMinNumberBulkDiscountType1] =
  //   useState("");
  // const [isMinNumberBulkDiscountType2, setIMinNumberBulkDiscountType2] =
  //   useState("");
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isEventDashboard || isCouponDashboard) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id]);

  const bulkDiscountInitialValue = {
    couponRaceType: "",
    numberofcoupons: "",
    couponValidFrom: "",
    couponValidTo: "",
    Amount: "",
    hasAmountType: "",
    numberofcoupons2: numberOfCoupons2,
    couponValidFrom2: "",
    couponValidTo2: "",
    Amount2: "",
    hasAmountType2: "",
    numberofcoupons3: "",
    couponValidFrom3: "",
    couponValidTo3: "",
    Amount3: "",
    hasAmountType3: "",
  };

  const handleAddClick = (Addvalues: any) => {
    if (!isBulkDiscountType2) {
      // setIMinNumberBulkDiscountType1(Addvalues.numberofcoupons);
      setIsBulkDiscountType2(true);
    } else if (!isBulkDiscountType3) {
      // setIMinNumberBulkDiscountType2(Addvalues.numberofcoupons2);
      setIsBulkDiscountType3(true);
      setIsDisableAddButton(true);
    }
  };

  // function CouponRaceList() {
  //   axios
  //     .post(
  //       "/couponRaceList",
  //       {
  //         eventId: id || storeEventId,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       let arrayData: { name: any; id: any; amount: any }[] = [];
  //       let arrayEarlyData: { name: any; id: any; amount: any }[] = [];
  //       res.data.data.map((items: any) => {
  //         console.log(items);
  //         arrayData.push({
  //           name: items.name,
  //           id: items.raceId,
  //           amount: items.amount,
  //         });
  //         if (items.name !== "Event Based") {
  //           arrayEarlyData.push({
  //             name: items.name,
  //             id: items.raceId,
  //             amount: items.amount,
  //           });
  //         }
  //       });
  //       setIsRaceList(arrayData);

  //       // setIsEarlyRaceList(arrayEarlyData);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }

  // useEffect(() => {
  //   CouponRaceList();
  // }, []);

  const bulkDiscountSchema = object({
    // couponRaceType: string().required("Race name is required"),
    numberofcoupons: string().required("Minimum number is required"),
    couponValidFrom: string().required("Valid from is required"),
    couponValidTo: string().required("Valid to is required"),
    Amount: string().required("Amount is required"),
    hasAmountType: string(),
    numberofcoupons2: isBulkDiscountType2 ? string() : string(),
    couponValidFrom2: isBulkDiscountType2 ? string().required() : string(),
    couponValidTo2: isBulkDiscountType2 ? string().required() : string(),
    Amount2: isBulkDiscountType2 ? string().required() : string(),
    hasAmountType2: isBulkDiscountType2 ? string() : string(),
    numberofcoupons3: isBulkDiscountType3 ? string() : string(),
    couponValidFrom3: isBulkDiscountType3 ? string().required() : string(),
    couponValidTo3: isBulkDiscountType3 ? string().required() : string(),
    Amount3: isBulkDiscountType3 ? string().required() : string(),
    hasAmountType3: isBulkDiscountType3 ? string() : string(),
  });

  const handleBulkDiscountFormSubmit = (values: any) => {
    setIsLoading(true);

    let arrayData: {
      discountIn: any;
      discount: any;
      validFrom: any;
      validTo: any;
      minTickets: any;
    }[] = [];

    if (isBulkDiscountType3) {
      if (
        parseInt(values.numberofcoupons) < parseInt(values.numberofcoupons2) &&
        parseInt(values.numberofcoupons2) < parseInt(values.numberofcoupons3)
      ) {
        arrayData.push({
          discountIn: values.hasAmountType === "1" ? "percentage" : "amount",
          discount: values.Amount,
          validFrom: values.couponValidFrom,
          validTo: values.couponValidTo,
          minTickets: values.numberofcoupons,
        });
        arrayData.push({
          discountIn: values.hasAmountType2 === "1" ? "percentage" : "amount",
          discount: values.Amount2,
          validFrom: values.couponValidFrom2,
          validTo: values.couponValidTo2,
          minTickets: values.numberofcoupons2,
        });
        arrayData.push({
          discountIn: values.hasAmountType3 === "1" ? "percentage" : "amount",
          discount: values.Amount3,
          validFrom: values.couponValidFrom3,
          validTo: values.couponValidTo3,
          minTickets: values.numberofcoupons3,
        });
      } else {
        toast.error(`Minmum number of coupons must be in ascending order`);
      }
    } else if (isBulkDiscountType2) {
      if (
        parseInt(values.numberofcoupons) > parseInt(values.numberofcoupons2)
      ) {
        toast.error(`Minmum number of coupons must be in ascending order`);
      } else {
        arrayData.push({
          discountIn: values.hasAmountType === "1" ? "percentage" : "amount",
          discount: values.Amount,
          validFrom: values.couponValidFrom,
          validTo: values.couponValidTo,
          minTickets: values.numberofcoupons,
        });
        arrayData.push({
          discountIn: values.hasAmountType2 === "1" ? "percentage" : "amount",
          discount: values.Amount2,
          validFrom: values.couponValidFrom2,
          validTo: values.couponValidTo2,
          minTickets: values.numberofcoupons2,
        });
      }
    } else {
      arrayData.push({
        discountIn: values.hasAmountType === "1" ? "percentage" : "amount",
        discount: values.Amount,
        validFrom: values.couponValidFrom,
        validTo: values.couponValidTo,
        minTickets: values.numberofcoupons,
      });
    }
    setArrayData(arrayData);
    const postData = {
      eventId: Id || storeEventId,
      // raceId: values.couponRaceType,
      data: arrayData,
    };

    // console.log("data", JSON.stringify(postData));

    // axios.post("/createBulkCoupons", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/createBulkCoupons",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        console.log(res);
        // setIsStatus(res.data.status);
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.success(`${res.data.message},`, { autoClose: 1000 });
          setTimeout(() => {
            handleReloadScreen();
            setIntialScreen(false);
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };
  // const getRaceAmount = (id1: number): string | undefined => {
  //   const country = isRaceList.find((c: any) => c.id === id1);
  //   return country ? country.amount ?? "0" : "0";
  // };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Formik
        validationSchema={bulkDiscountSchema}
        onSubmit={handleBulkDiscountFormSubmit}
        initialValues={bulkDiscountInitialValue}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Formdata noValidate onSubmit={handleSubmit}>
            <>
              <Row className="mt-4">
                <h6 className="text-darkgray fw-400 fs-13">
                  1. Bulk discount will be applied when group of participants
                  apply from the same account during one registration cycle.
                </h6>
                <h6 className="text-darkgray fw-400 fs-13">
                  2. Bulk discount is automatically applied when the number of
                  participant's crosses the minimum number of tickets to be
                  purchased.
                </h6>

                <h6 className="text-darkgray fw-400 fs-13">
                  3. If a bulk discount is added to the complete event(selecting
                  all in the drop down), then more bulk discounts cannot be
                  created.
                </h6>
                <h6 className="text-darkgray fw-400 fs-13">
                  4. Please add all the bulk discount categories in one step as
                  once created, they cannot be edited.
                </h6>
              </Row>

              {/* <Row className="my-2 d-flex align-items-center">
                <Col xs={12} md={5}>
                  <TextSelect
                    name="couponRaceType"
                    options={isEarlyRaceList}
                    label="Please Select Race *"
                    type="select"
                  />
                </Col>
                <Col xs={12} md={7} className="d-flex justify-content-start">
                  {values.couponRaceType && (
                    <Form.Group>
                      <Form.Label>
                        Race Amount ₹{" "}
                        {getRaceAmount(Number(values.couponRaceType))}
                      </Form.Label>
                    </Form.Group>
                  )}
                </Col>
              </Row> */}

              <Row className="mt-md-4">
                <div className="mt-md-4">
                  <>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4>Number of Participants</h4>
                      </div>

                      <div className="d-flex justify-content-end">
                        {!isDisableAddButton && (
                          <DefaultButton
                            type="button"
                            variant="primary text-white"
                            className=" py-2 px-md-5"
                            onClick={() => handleAddClick(values)}
                          >
                            Add
                          </DefaultButton>
                        )}
                      </div>
                    </div>

                    <Row className="my-3 d-flex align-items-end">
                      <Col className="col-md-2 d-flex align-items-end  p-0 m-0">
                        <TextField
                          type="text"
                          label="Min Number *"
                          name="numberofcoupons"
                        />
                      </Col>

                      <Col md={4} className="  d-flex align-items-end">
                        <Row>
                          <Col className="col-12">
                            <div className="d-flex align-items-center ms-md-1">
                              <Form.Label>Amount : </Form.Label>
                              <Form.Group
                                className="ms-md-3"
                                style={{ marginTop: "-7px" }}
                              >
                                <Form.Check
                                  inline
                                  label="Percentage"
                                  name="hasAmountType"
                                  type="radio"
                                  onChange={handleChange}
                                  isInvalid={!!errors.hasAmountType}
                                  value="1"
                                  // checked={values.hasProcessingAndTax === "1"}
                                />

                                <Form.Check
                                  inline
                                  label="Amount"
                                  name="hasAmountType"
                                  type="radio"
                                  onChange={handleChange}
                                  isInvalid={!!errors.hasAmountType}
                                  value="0"
                                  // checked={values.hasProcessingAndTax === "0"}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                          <Col className="col-md-12 col-12 mt-md-2">
                            <TextField
                              type="number"
                              name="Amount"
                              label="Amount *"
                              value={values.Amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.Amount && !!errors.Amount}
                              onWheel={(event: any) =>
                                event.currentTarget.blur()
                              }
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col className="d-flex align-items-end">
                        <TextField
                          type="datetime-local"
                          name="couponValidFrom"
                          label="Valid From *"
                          min={new Date().toISOString().slice(0, -8)}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <TextField
                          type="datetime-local"
                          name="couponValidTo"
                          label=" Valid To *"
                          min={values.couponValidFrom}
                        />
                      </Col>
                    </Row>
                    {isBulkDiscountType2 && (
                      <Row className="my-2 d-flex align-items-end">
                        <Col className="d-flex align-items-end p-0 m-0 col-md-2">
                          <TextField
                            type="text"
                            label="Min Number"
                            name="numberofcoupons2"
                          />
                        </Col>

                        <Col md={4} className="d-flex align-items-end">
                          <Row>
                            <Col className="col-12">
                              <div className="d-flex align-items-center ms-md-1">
                                <Form.Label>Amount : </Form.Label>
                                <Form.Group
                                  className="ms-md-3"
                                  style={{ marginTop: "-7px" }}
                                >
                                  <Form.Check
                                    inline
                                    label="Percentage"
                                    name="hasAmountType2"
                                    type="radio"
                                    onChange={handleChange}
                                    // isInvalid={!!errors.hasProcessingAndTax}
                                    value="1"
                                    // checked={values.hasProcessingAndTax === "1"}
                                  />

                                  <Form.Check
                                    inline
                                    label="Amount"
                                    name="hasAmountType2"
                                    type="radio"
                                    onChange={handleChange}
                                    // isInvalid={!!errors.hasProcessingAndTax}
                                    value="0"
                                    // checked={values.hasProcessingAndTax === "0"}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col className="col-md-12 col-12 mt-md-2">
                              <TextField
                                type="number"
                                name="Amount2"
                                label="Amount "
                                value={values.Amount2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.Amount2 && !!errors.Amount2}
                                onWheel={(event: any) =>
                                  event.currentTarget.blur()
                                }
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col className="d-flex align-items-end">
                          <TextField
                            type="datetime-local"
                            name="couponValidFrom2"
                            label="Valid From *"
                            min={new Date().toISOString().slice(0, -8)}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TextField
                            type="datetime-local"
                            name="couponValidTo2"
                            label=" Valid To *"
                            min={values.couponValidFrom2}
                          />
                          <Icon
                            iconName="Trash"
                            className="text-primary border-5 ms-md-4"
                            style={{ cursor: "pointer" }}
                            size={24}
                            onClick={() => {
                              setIsBulkDiscountType2(false);
                              setIsDisableAddButton(false);
                              setNumberOfCoupon2("");
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                    {isBulkDiscountType3 && (
                      <Row className="my-3 d-flex align-items-end">
                        <Col className="d-flex align-items-end p-0 m-0 col-md-2">
                          <TextField
                            type="text"
                            label="Min Number"
                            name="numberofcoupons3"
                          />
                        </Col>

                        <Col md={4} className="d-flex align-items-end">
                          <Row>
                            <Col className="col-12">
                              <div className="d-flex align-items-center ms-md-1">
                                <Form.Label>Amount : </Form.Label>
                                <Form.Group
                                  className="ms-md-3"
                                  style={{ marginTop: "-7px" }}
                                >
                                  <Form.Check
                                    inline
                                    label="Percentage"
                                    name="hasAmountType3"
                                    type="radio"
                                    onChange={handleChange}
                                    // isInvalid={!!errors.hasProcessingAndTax}
                                    value="1"
                                    // checked={values.hasProcessingAndTax === "1"}
                                  />

                                  <Form.Check
                                    inline
                                    label="Amount"
                                    name="hasAmountType3"
                                    type="radio"
                                    onChange={handleChange}
                                    // isInvalid={!!errors.hasProcessingAndTax}
                                    value="0"
                                    // checked={values.hasProcessingAndTax === "0"}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col className="col-md-12 col-12 mt-md-2">
                              <TextField
                                type="number"
                                name="Amount3"
                                label="Amount"
                                value={values.Amount3}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.Amount3 && !!errors.Amount3}
                                onWheel={(event: any) =>
                                  event.currentTarget.blur()
                                }
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col className="d-flex align-items-end">
                          <TextField
                            type="datetime-local"
                            name="couponValidFrom3"
                            label="Valid From *"
                            min={new Date().toISOString().slice(0, -8)}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TextField
                            type="datetime-local"
                            name="couponValidTo3"
                            label=" Valid To *"
                            min={values.couponValidFrom3}
                          />
                          <Icon
                            iconName="Trash"
                            className="text-primary border-5 ms-md-4"
                            style={{ cursor: "pointer" }}
                            size={24}
                            onClick={(index: any) => {
                              setIsBulkDiscountType3(false);
                              setIsDisableAddButton(false);
                              const updatedArrayData = [...arrayData];
                              updatedArrayData.splice(index, 1);
                              setArrayData(updatedArrayData);
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                </div>
              </Row>
            </>
            <Row className="my-md-5 d-flex align-items-center justify-content-end">
              {!isViewEvent && (
                <Col className="d-flex justify-content-end">
                  <DefaultButton
                    type="submit"
                    variant="outline-primary"
                    className=" default-action-button"
                  >
                    Submit
                  </DefaultButton>
                </Col>
              )}
            </Row>
          </Formdata>
        )}
      </Formik>
    </>
  );
};

export default BulkDiscount;
