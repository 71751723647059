import { useCallback, useRef, useState } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styles from "./ChangeCategoryPayment.module.scss";
import { toast } from "react-toastify";
import PreviewTermsandConditions from "components/PreviewTermsandConditions/PreviewTermsandConditions";
import useRazorpay from "react-razorpay";
import EventPaymentSuccessModal from "components/EventRegistration/PaymentModal/EventPaymentSuccessModal";
import { useCreateChangeCategoryOrderId } from "api/CreateChangeCategoryOrderId";
import { useEventState } from "store/event/EventContext";
import { useCheckChangeCategoryPaymentStatus } from "api/ChangeCategoryPaymentStatus";

type PaymentProps = {
  amount: number;
  processingfee: number;
  gstprocessingfee: number;
  totalamount: number;
  raceName: string;
  participantName: string;
  disclaimer: string;
  participantId?: number;
  gst: number;
  gstType: number;
  handleClosePaymentModal: () => void;
};

const ChangeCategoryPayment: React.FC<PaymentProps> = ({
  amount,
  processingfee,
  gstprocessingfee,
  gst,
  gstType,
  totalamount,
  raceName,
  participantName,
  disclaimer,
  participantId,
  handleClosePaymentModal,
}) => {
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false);
  const [isOrgTermsChecked, setIsOrgTermsChecked] = useState(false);
  const [isIfinishTermsChecked, setIsIfinishTermsChecked] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const state = useEventState();
  const paymentMethod = useRef(null);

  const [
    showIsOrganiserTermsAndConditions,
    setShowIsOrganiserTermsAndConditions,
  ] = useState<boolean>(false);

  const createChangeCategoryOrderMutation = useCreateChangeCategoryOrderId();
  const checkChangePaymentStatusMutation =
    useCheckChangeCategoryPaymentStatus();
  const [Razorpay] = useRazorpay();

  const handleClosePreviewModal = () => {
    setIsOpenPreviewModal(false);
  };

  const handleOpenOrganiserTermAndCondition = () => {
    setShowIsOrganiserTermsAndConditions(true);
    setIsOpenPreviewModal(true);
  };

  const handleOpenIfinishTermAndCondition = () => {
    setShowIsOrganiserTermsAndConditions(false);
    setIsOpenPreviewModal(true);
  };

  const handlePayment = useCallback(
    async (totalamount: number, participantId: any, state: any) => {
      const { data: orderData, error: orderError } =
        await createChangeCategoryOrderMutation.mutateAsync({
          participantId,
        });

      if (orderError) {
        toast.error("Error creating Order Id");
      }

      const options = {
        key: `${process.env.REACT_APP_RAZORPAY_KEY_ID}`,
        amount: (totalamount * 100).toString(),
        currency: "INR",
        name: "iFinish",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: orderData,

        handler: async (res: any) => {
          if (res.razorpay_payment_id) {
            try {
              const paymentData =
                await checkChangePaymentStatusMutation.mutateAsync({
                  participantId: participantId,
                  paymentId: res.razorpay_payment_id,
                  razorpayOrderId: res.razorpay_order_id,
                  razorPaySignature: res.razorpay_signature,
                });
              if (paymentData.status) {
                setPaymentData(paymentData.data);
              }
              setIsOpenProfileModal(true);
            } catch (error) {
              toast.error("Oops!! something went wrong, please try again.");
            }
          }
        },

        prefill: {
          name: state.contactPersonDetails.name,
          email: state.contactPersonDetails.email,
          contact:
            state.contactPersonDetails.phoneCode +
            state.contactPersonDetails.phoneNumber,
        },
        notes: {
          address: "My Home Hub, Hitech City",
        },
        theme: {
          color: "#ff5050",
        },
        modal: {
          ondismiss: function () {
            setIsOpenProfileModal(true);
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.submit", (response: any) => {
        paymentMethod.current = response.method;
      });

      // To get payment id in case of failed transaction.
      rzpay.on("payment.failed", async (response: any) => {
        try {
          const paymentData =
            await checkChangePaymentStatusMutation.mutateAsync({
              participantId: participantId,
              paymentId: response.error.metadata?.payment_id,
              razorpayOrderId: response.error.metadata?.order_id,
              razorPaySignature: "",
            });

          setPaymentData(paymentData.data);
        } catch (error) {
          toast.error("Oops!! something went wrong, please try again.");
        }
      });

      rzpay.open();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Razorpay]
  );

  const onPaymentClick = async () => {
    handlePayment(totalamount, participantId, state);
  };

  return (
    <div className={styles.changeCategoryContainer}>
      <div className={styles.changeCategorySubContainer}>
        <div className="pt-4 ff-saira ">
          <h1 className="fw-600 fs-saira mb-0 px-4">Payment Summary</h1>
          <div className={`py-sm-4 py-2 my-1 `}>
            <h1 className="fw-600 fs-saira mb-0 px-4">
              Participant Information
            </h1>
            <div className={`pt-3`}>
              <div className="px-4">
                <Row
                  className={`d-flex justify-content-end align-items-center`}
                >
                  <Col xs={7} className="d-flex flex-column">
                    <span className={`${styles.detailsText}`}>
                      {participantName}
                    </span>
                    <span className="fs-15 my-1">{raceName}</span>
                  </Col>

                  <Col xs={5} className="text-md-end mt-3 mt-md-0 fs-20">
                    <div className="text-end">
                      <span className="text-primary ps-3">Rs {amount}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr className="py-1 " style={{ borderTop: "1px solid #BDBDBD" }} />
          <div className={`${styles.detailsText} text-end px-3 ff-saira`}>
            <Row>
              <Col xs={7} sm={8} lg={9}>
                Amount:
              </Col>
              <Col xs={5} sm={4} lg={3}>
                Rs {amount.toFixed(2)}
              </Col>
            </Row>
            <Row className="pt-2 fs-16">
              <Col xs={7} sm={8} lg={9}>
                GST{" "}
                <span className="fs-12">
                  ({gstType === 1 ? "Exclusive" : "Inclusive"})
                </span>
                :
              </Col>
              <Col xs={5} sm={4} lg={3}>
                Rs {gst?.toFixed(2)}
              </Col>
            </Row>
            <Row className="pt-2 fs-16">
              <Col xs={7} sm={8} lg={9}>
                <div className="d-flex justify-content-end align-items-center">
                  <span className={styles.dottedBorder}>
                    Processing Fee
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip style={{ width: "300px" }}>
                          <div className="py-1">
                            <p className="mb-1">Processing Fee Break Up</p>
                            <Container className="align-items-end">
                              <Row>
                                <Col className="px-0" xs={8}>
                                  <p className="mb-0 text-end fs-12">
                                    Processing Fee:
                                  </p>
                                </Col>
                                <Col className="px-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0 fs-12 ms-1">Rs.</p>
                                    <p className="mb-0 fs-12">
                                      {processingfee.toFixed(2)}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Container>

                            <Container className="mb-1">
                              <Row className="align-items-end">
                                <Col className="px-0" xs={8}>
                                  <p className="mb-0 text-end fs-12">
                                    GST on Processing Fee:
                                  </p>
                                </Col>
                                <Col className="px-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0 fs-12 ms-1">Rs.</p>
                                    <p className="mb-0 fs-12">
                                      {gstprocessingfee.toFixed(2)}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Tooltip>
                      }
                    >
                      <img
                        className="cursor-pointer mx-2"
                        height={14}
                        width={14}
                        alt="info tooltip"
                        src="/images/info.svg"
                      />
                    </OverlayTrigger>
                  </span>
                  :
                </div>
              </Col>
              <Col xs={5} sm={4} lg={3}>
                Rs {(processingfee + gstprocessingfee).toFixed(2)}
              </Col>
            </Row>

            <Row className="fw-600 pt-2">
              <Col xs={7} sm={8} lg={9}>
                Total Amount:
              </Col>
              <Col xs={5} sm={4} lg={3}>
                Rs {totalamount.toFixed(2)}
              </Col>
            </Row>
          </div>
          <hr
            className="mt-3 mb-2"
            style={{ borderTop: "1px solid #BDBDBD" }}
          />
        </div>
      </div>

      <div className="px-3 mt-3 mb-4">
        <div
          className={`d-flex align-items-center mb-1 ${styles.termsAdaptiveFontSize}`}
        >
          <Form.Check
            onChange={() => setIsOrgTermsChecked(!isOrgTermsChecked)}
            checked={isOrgTermsChecked}
          />
          <span className="ms-2 ff-ns fw-400">
            I agree to the{" "}
            <u
              className="cursor-pointer"
              onClick={handleOpenOrganiserTermAndCondition}
            >
              Organiser Terms & Conditions
            </u>
          </span>
        </div>

        <div
          className={`d-flex align-items-center ${styles.termsAdaptiveFontSize}`}
        >
          <Form.Check
            onChange={() => setIsIfinishTermsChecked(!isIfinishTermsChecked)}
            checked={isIfinishTermsChecked}
          />
          <span className="ms-2 ff-ns fw-400">
            I agree to the{" "}
            <u
              className="cursor-pointer"
              onClick={handleOpenIfinishTermAndCondition}
            >
              IFinish Terms & Conditions
            </u>
          </span>
        </div>
      </div>

      <div
        className={`${styles.buttonContainer} d-flex justify-content-center justify-content-sm-end`}
      >
        <Button
          type="button"
          onClick={onPaymentClick}
          className="default-action-button fw-bold text-white ms-4"
          variant="primary"
          disabled={!isOrgTermsChecked || !isIfinishTermsChecked}
        >
          PROCEED TO PAYMENT
        </Button>
      </div>

      <PreviewTermsandConditions
        isOpenPreviewModal={isOpenPreviewModal}
        handleClosePreviewModal={handleClosePreviewModal}
        showIsOrganiserTermsAndConditions={showIsOrganiserTermsAndConditions}
        organiserTermsAndConditions={disclaimer}
      />
      <EventPaymentSuccessModal
        isOpenProfileModal={isOpenProfileModal}
        handleCloseProfileModal={handleClosePaymentModal}
        paymentData={paymentData}
      />
    </div>
  );
};

export default ChangeCategoryPayment;
