import React, { useEffect, useState, useRef } from "react";
import { Form as Formdata, Formik } from "formik";
import { Col, Row, Form } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { useEvent } from "context/EventContext";
// import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { object, string, number } from "yup";
import { toast, ToastContainer } from "react-toastify";
import { TextArea, TextField, TextSelect } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import moment from "moment";
import ViewEvent from "utils/ViewEvent";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const CreateRaceForm = ({
  setActiveTab,
  isCreateButton,
  setIsCreateButton,
}: any) => {
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isViewEvent = ViewEvent();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const { setRaceId, storeEventId, storeRaceId } = useEvent();
  const [isAddMode, setIsAddMode] = useState();
  let isEditMode: any;
  let eventId: any;
  const { token } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  // const isAddMode = !eventId && !StoredId;
  const [isRaceData, setIsRaceData] = useState<any>();

  const [isStatus, setIsStatus] = useState();
  const [isEventRaceType, setIsEventRaceType] = useState();
  const [isParticipantsTicket, setisParticipantsTickets] = useState<boolean>();
  const formattedStartDate = (dateString: string) => {
    return dateString?.split(" ")[0];
  };
  const [isEventStartDate, setIsEventStartDate] = useState();
  const [isEventEndDate, setIsEventEndDate] = useState();
  const [isRegistrationStartDate, setIsRegistrationStartDate] = useState();
  const [isRegistrationEndDate, setIsRegistrationEndDate] = useState();
  const [description, setDescriptionContent] = useState();
  const [updateDescription, setUpdateDescriptionContent] = useState();
  const [raceData, setRaceData] = useState<any>([]);
  const raceName: any = raceData?.map((race: any) => race.race_name);
  const [duration, setDuration] = useState("");
  const [hmDuration, setHMDuration] = useState("");
  const [fmDuration, setFMDuration] = useState("");
  const [oDuration, setODuration] = useState("");

  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];
  const editorToolbar =
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help";

  const editorContentStyle =
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

  const editorAPIKey = process.env.REACT_APP_TINY_API_KEY;

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleGeneralEditorChange = (content: any) => {
    setUpdateDescriptionContent(content);
  };

  useEffect(() => {
    const StoredId = storeRaceId === null ? false : true;
    if (isEditEvent && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      isEditMode = !eventId && !StoredId;
      setIsAddMode(isEditMode);
    }
  }, [eventId, id]);

  const raceSchema = object({
    // description: string().required("Description is required"),
    raceName: string()
      .required("Race name is required")
      .min(2, "Minimum of 2 characters of length")
      .max(40, "Maximum of 40 characters of length"),
    // raceTypeId: string().required("Race type is required"),
    maxRunners: string()
      .required("Maximum runner is required")
      .min(3, "Minimum of 3 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .matches(/^[0-9]+$/, "Must be only digits"),
    raceStartDate: string().required("Race start date is required"),
    raceEndDate: string().required("Race end date is required"),
    distance: string()
      .required("Distance is required")
      .min(1, "Minimum of 1 characters of length")
      .max(6, "Maximum of 6 characters of length"),
    // .matches(/^[0-9]+$/, "Must be only digits"),
    amount: number().required("Race amount is requireds"),
    // .min(1, "Minimum of 1 characters of length")
    // .max(6, "Maximum of 6 characters of length"),
    reportingTiming: string().required("Reporting time is required"),
    raceStartTime: string().required("Race start time is required"),
    registrationStartDate: string().required(
      "Registration start date is required"
    ),
    registrationEndDate: string().required("Registration end date is required"),
    autoAssignBibs: string(),
    approveEachRegistration: string(),
    noofparticipantsPerTicket: string(),
    maxDiscount: number(),
    isQualify: string(),
  });

  const InitialValue = {
    raceName: isAddMode ? "" : isRaceData?.ticket_name,
    raceTypeId: isAddMode ? "" : isRaceData?.ticket_type_id,
    maxRunners: isAddMode ? "" : isRaceData?.max_tickets,
    raceStartDate: isAddMode
      ? ""
      : formattedStartDate(isRaceData?.tkt_evnt_start_date),
    raceEndDate: isAddMode
      ? ""
      : formattedStartDate(isRaceData?.tkt_evnt_end_date),
    distance: isAddMode ? "" : isRaceData?.distance,
    amount: isAddMode ? "" : isRaceData?.amount,
    reportingTiming: isAddMode ? "" : isRaceData?.report_timing,
    raceStartTime: isAddMode ? "" : isRaceData?.tkt_evnt_start_time,
    registrationStartDate: isAddMode ? "" : isRaceData?.registration_start_date,
    registrationEndDate: isAddMode ? "" : isRaceData?.registration_end_date,
    autoAssignBibs: isAddMode
      ? ""
      : isRaceData?.auto_assign_bibs === 1
      ? "1"
      : "0",
    approveEachRegistration: isAddMode
      ? ""
      : isRaceData?.approve_each_registration
      ? "1"
      : "0",
    // description: isAddMode ? "" : isRaceData?.description,
    noofparticipantsPerTicket: "",
    maxDiscount: "",
    isQualify: isAddMode ? "" : isRaceData?.is_qualify === 1 ? "1" : "0",
  };

  function eventRaceType() {
    // axios.post(
    //   "/eventRaceType",
    //   {
    //     eventId: storeEventId || Id,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/eventRaceType",
      data: { eventId: storeEventId || Id },
      method: "POST",
    })
      .then((res) => {
        const { status, data, racesData } = res.data;
        if (status) {
          setIsEventRaceType(data);
          setRaceData(racesData);
        }
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      eventRaceType();
    }
  }, [Id, storeEventId]);

  function padZero(number: number) {
    return number < 10 ? `0${number}` : number;
  }

  const millisecondsToHHMMSS = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    let formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      remainingSeconds
    )}`;
    return formattedTime;
  };

  function GetEventRace() {
    // axios.post(
    //   "/getEventRace",
    //   {
    //     raceId: storeRaceId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventRace",
      data: { raceId: storeRaceId },
      method: "POST",
    })
      .then((res) => {
        setIsStatus(res.data.status);
        if (res.data.status) {
          setIsRaceData(res.data.data);
          setDescriptionContent(res.data.data.description);
          setUpdateDescriptionContent(res.data.data.description);
          const qulifingData: any = res.data.data.qualifyTime;
          const OtherRaceId = qulifingData.filter(
            (data: any) => data.race_type_id === 4
          );
          const FullRaceId = qulifingData.filter(
            (data: any) => data.race_type_id === 3
          );
          const HalfRaceId = qulifingData.filter(
            (data: any) => data.race_type_id === 2
          );
          const RaceId10K = qulifingData.filter(
            (data: any) => data.race_type_id === 1
          );

          OtherRaceId.map((data: any) => {
            const oTime = millisecondsToHHMMSS(data.qualify_time);
            setODuration(oTime);
          });
          FullRaceId.map((data: any) => {
            const fmTime = millisecondsToHHMMSS(data.qualify_time);
            setFMDuration(fmTime);
          });
          HalfRaceId.map((data: any) => {
            const hmTime = millisecondsToHHMMSS(data.qualify_time);
            setHMDuration(hmTime);
          });
          RaceId10K.map((data: any) => {
            const time = millisecondsToHHMMSS(data.qualify_time);
            setDuration(time);
          });
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      })
      .catch((err) => {
        return;
      });
  }
  function getEventBasicDetails() {
    const EventId = Id || storeEventId;
    setIsLoading(true);
    // axios.post(
    //   "/getEventBasicDetails",
    //   {
    //     event_id: EventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/getEventBasicDetails",
      data: { event_id: EventId },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setIsEventStartDate(data.event_start_date);
          setIsEventEndDate(data.event_end_date);
          setIsRegistrationStartDate(data.registration_start);
          setIsRegistrationEndDate(data.registration_close);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (storeRaceId !== null) {
      GetEventRace();
    } else {
      getEventBasicDetails();
    }
    if (Id || storeRaceId) {
      getEventBasicDetails();
    }
  }, [storeRaceId, Id]);

  useEffect(() => {
    {
      isCreateButton && setRaceId(null);
    }
    setIsCreateButton(false);
  }, []);

  const convertTimeToMilliseconds = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  };

  const handleRaceSubmit = (values: any) => {
    if (updateDescription === null || updateDescription === "") {
      toast.error("Description is required");
      return;
    }

    let postData;
    setIsLoading(true);
    const EventId = storeEventId || Id;
    const reportingTime = `${values.reportingTiming.split(":")[0]}:${
      values.reportingTiming.split(":")[1]
    }`;
    const raceTime = `${values.raceStartTime.split(":")[0]}:${
      values.raceStartTime.split(":")[1]
    }`;
    const raceTimeData = [
      {
        raceNameId: 1,
        qualifyTime: convertTimeToMilliseconds(duration),
      },
      {
        raceNameId: 2,
        qualifyTime: convertTimeToMilliseconds(hmDuration),
      },
      {
        raceNameId: 3,
        qualifyTime: convertTimeToMilliseconds(fmDuration),
      },
      {
        raceNameId: 4,
        qualifyTime: convertTimeToMilliseconds(oDuration),
      },
    ];
    // console.log("raceTimeData", raceTimeData);
    postData = {
      raceId: null || storeRaceId,
      raceName: values.raceName,
      raceTypeId: values.raceTypeId,
      maxRunners: values.maxRunners,
      raceStartDate: values.raceStartDate,
      raceEndDate: values.raceEndDate,
      distance: values.distance,
      amount: values.amount,
      reportingTiming: reportingTime,
      raceStartTime: raceTime,
      registrationStartDate: values.registrationStartDate,
      registrationEndDate: values.registrationEndDate,
      autoAssignBibs: values.autoAssignBibs,
      approveEachRegistration: values.approveEachRegistration,
      description: updateDescription,
      eventId: EventId,
      noofparticipantsPerTicket: "",
      isQualify: values.isQualify,
      qualifyData: raceTimeData,
    };
    // console.log("postData", postData);
    // axios.post("/eventCreateRace", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/eventCreateRace",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        setIsStatus(res.data.status);
        if (res.data.status) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setRaceId(res.data.raceId);
          toast.success(`${res.data.message}`, { autoClose: 1000 });
          setTimeout(() => {
            setActiveTab(2);
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  function timeValidation(formattedValue: any) {
    const [hours, minutes, seconds] = formattedValue.split(":").map(Number);
    if (hours > 23) {
      // formattedValue = "23:";
      toast.error("hh has to less than 23");
    } else if (hours < 0 || isNaN(hours)) {
      formattedValue = "00:";
    }
    // if (minutes > 59) {
    //   // formattedValue = "23:";
    //   toast.error("mm has to less than 59");
    // } else if (minutes < 0 || isNaN(minutes)) {
    //   formattedValue = "00:";
    // }
    // if (seconds > 59) {
    //   // formattedValue = "23:";
    //   toast.error("mm has to less than 59");
    // } else if (seconds < 0 || isNaN(seconds)) {
    //   formattedValue = "00:";
    // }

    return formattedValue;
  }

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    let formattedValue = numericValue.replace(/(\d{2})(?=\d)/g, "$1:");
    const verifiedTime = timeValidation(formattedValue);
    setDuration(verifiedTime);
  };

  const FMhandleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    let formattedValue = numericValue.replace(/(\d{2})(?=\d)/g, "$1:");
    const verifiedTime = timeValidation(formattedValue);
    setHMDuration(verifiedTime);
  };
  const FullhandleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    let formattedValue = numericValue.replace(/(\d{2})(?=\d)/g, "$1:");
    const verifiedTime = timeValidation(formattedValue);
    setFMDuration(verifiedTime);
  };
  const OtherhandleDurationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    let formattedValue = numericValue.replace(/(\d{2})(?=\d)/g, "$1:");
    const verifiedTime = timeValidation(formattedValue);
    setODuration(verifiedTime);
  };

  // const seconds = convertTimeToSeconds(duration);
  // console.log("convertTimeToSeconds", seconds);

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <ToastContainer />
        <Formik
          validationSchema={raceSchema}
          onSubmit={handleRaceSubmit}
          initialValues={InitialValue}
          enableReinitialize={true}
        >
          {({ values, errors, handleChange }) => (
            <Formdata noValidate>
              <Row className="my-md-3">
                <Col md={4} sm={12}>
                  <TextField name="raceName" type="text" label="Race Name *" />
                </Col>
                <Col md={4} sm={12}>
                  <TextSelect
                    type="select"
                    label="Race Type *"
                    name="raceTypeId"
                    options={isEventRaceType}

                    // onChange={  setisParticipantsTickets(e.target.value === "2" ? true : false);}
                  />
                </Col>

                <Col md={4} sm={12}>
                  <TextField
                    name="maxRunners"
                    type="number"
                    label="Race Max Runners *"
                    onWheel={(event: any) => event.currentTarget.blur()}
                  />
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col md={4} sm={12}>
                  <TextField
                    name="raceStartDate"
                    type="date"
                    label="Race Start Date *"
                    min={isEventStartDate}
                    max={isEventEndDate}
                  />
                </Col>
                <Col md={4} sm={12}>
                  <TextField
                    name="raceEndDate"
                    type="date"
                    label="Race End Date *"
                    // min={new Date().toISOString().split("T")[0]}
                    min={values.raceStartDate}
                    max={isEventEndDate}
                  />
                </Col>
                <Col md={4} sm={12}>
                  <TextField name="distance" type="text" label="Distance *" />
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col md={4} sm={12}>
                  <TextField name="amount" type="text" label="Race Amount *" />
                </Col>
                {/* <Col md={4} sm={12}>
                  <TextField
                    name="maxDiscount"
                    type="text"
                    label="Max Discount Amount *"
                  />
                </Col> */}

                <Col md={4} sm={12}>
                  <TextField
                    name="reportingTiming"
                    type="time"
                    label="Reporting Time *"
                  />
                </Col>
                <Col md={4} sm={12}>
                  <TextField
                    name="raceStartTime"
                    type="time"
                    label="Race Gun Sound-off Time *"
                  />
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col md={4} sm={12}>
                  <TextField
                    name="registrationStartDate"
                    type="datetime-local"
                    label="Registration Start Date *"
                    min={moment(isRegistrationStartDate).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    max={moment(values.raceStartDate).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                  />
                </Col>
                <Col md={4} sm={12}>
                  <TextField
                    name="registrationEndDate"
                    type="datetime-local"
                    label="Registration End Date *"
                    min={values.registrationStartDate}
                    max={moment(isRegistrationEndDate).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                  />
                </Col>
                {/* {values.} */}
                {/* {isParticipantsTicket && (
                <Col md={4} sm={12}>
                  <TextField
                    name="noofparticipantsPerTicket"
                    type="number"
                    label="No of Participants per ticket"
                    isRequired={true}
                  />
                </Col>
              )} */}
              </Row>
              {/* <Row className="my-md-3">
                <Col md={12} sm={12}>
                  <TextArea name="description" rows={5} label="Description *" />
                </Col>
              </Row> */}
              <div>
                <Row className="">
                  <Col md={12}>
                    <p className="text-darkgray fw-400 fs-16 my-3">
                      Description
                      <span className="text-primary ms-1">*</span>
                    </p>
                  </Col>
                </Row>
                <Editor
                  apiKey={editorAPIKey}
                  disabled={isViewEvent}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={description}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: editorPlugins,
                    toolbar: editorToolbar,
                    content_style: editorContentStyle,
                    setup: (editor: {
                      on: (arg0: string, arg1: () => void) => void;
                      getContent: () => any;
                    }) => {
                      editor.on("change", () => {
                        handleGeneralEditorChange(editor.getContent());
                      });
                    },
                  }}
                />
              </div>
              <Row className="my-md-3">
                <Col xs={12}>
                  <Form.Label className="my-2">
                    Auto assign BiB? <span className="text-primary">*</span>
                  </Form.Label>

                  <Form.Group>
                    <Form.Check
                      inline
                      label="Yes"
                      name="autoAssignBibs"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.autoAssignBibs}
                      value="1"
                      checked={values.autoAssignBibs === "1"}
                      disabled={isPastEvent}
                    />

                    <Form.Check
                      inline
                      label="No"
                      name="autoAssignBibs"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.autoAssignBibs}
                      value="0"
                      checked={values.autoAssignBibs === "0"}
                      disabled={isPastEvent}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col xs={12}>
                  <Form.Label className="my-2">
                    Will registrations need approval?{" "}
                    <span className="text-primary">*</span>
                  </Form.Label>

                  <Form.Group>
                    <Form.Check
                      inline
                      label="Yes"
                      name="approveEachRegistration"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.approveEachRegistration}
                      value="1"
                      checked={values.approveEachRegistration === "1"}
                      disabled={isPastEvent}
                    />

                    <Form.Check
                      inline
                      label="No"
                      name="approveEachRegistration"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.approveEachRegistration}
                      value="0"
                      checked={values.approveEachRegistration === "0"}
                      disabled={isPastEvent}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col xs={12}>
                  <Form.Label className="my-2">
                    Will this race need qualifying time ?{" "}
                    <span className="text-primary">*</span>
                  </Form.Label>

                  <Form.Group>
                    <Form.Check
                      inline
                      label="Yes"
                      name="isQualify"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.isQualify}
                      value="1"
                      checked={values.isQualify === "1"}
                      disabled={isPastEvent}
                    />

                    <Form.Check
                      inline
                      label="No"
                      name="isQualify"
                      type="radio"
                      onChange={handleChange}
                      isInvalid={!!errors.isQualify}
                      value="0"
                      checked={values.isQualify === "0"}
                      disabled={isPastEvent}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {values.isQualify === "1" && (
                <>
                  {/* <Row className="my-md-3">
                    <Col md={2} className="d-flex align-items-center pt-2">
                      <Form.Label>{raceName[0]}</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={duration}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleDurationChange(e)
                        }
                      />
                    </Col>
                  </Row> */}
                  <Row className="my-md-3">
                    <Col md={2} className="d-flex align-items-center pt-2">
                      <Form.Label>{raceName[1]}</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={hmDuration}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          FMhandleDurationChange(e)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="my-md-3">
                    <Col md={2} className="d-flex align-items-center pt-2">
                      <Form.Label>{raceName[2]}</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={fmDuration}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          FullhandleDurationChange(e)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="my-md-3">
                    <Col md={2} className="d-flex align-items-center pt-2">
                      <Form.Label>{raceName[3]}</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={oDuration}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          OtherhandleDurationChange(e)
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row className="my-md-3 d-flex align-items-center justify-content-between">
                {!isViewEvent && (
                  <Col className="d-flex justify-content-end">
                    <DefaultButton
                      type="submit"
                      variant="outline-primary"
                      className=" default-action-button"
                    >
                      Submit
                    </DefaultButton>
                  </Col>
                )}
              </Row>
            </Formdata>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateRaceForm;
