import React, { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Icon } from "components/Icon";
import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "components/DefaultButton/DefaultButton";
import OrgHomeScreen from "OrganizerPages/orgHomeScreen";
import HomeTabScreen from "OrganizerPages/eventDetails/homeTabScreen";
import RegistrationTabScreen from "OrganizerPages/eventDetails/registrationTabScreen";
import RefundTabScreen from "OrganizerPages/eventDetails/refundTabScreen";
import { OrgDashboardType } from "types/OrgDashboardEnum";
import MessageTabScreen from "OrganizerPages/eventDetails/messageTabScreen";
import OfflineEmailTabScreen from "OrganizerPages/eventDetails/offlineEmailTabScreen";
import OfflineRegistrationTabScreen from "OrganizerPages/eventDetails/offlineRegistrationTabScreen";
import EmailTabScreen from "OrganizerPages/eventDetails/emailTabScreen";
import CouponsTabScreen from "OrganizerPages/eventDetails/couponsTabScreen";
import BibsTabScreen from "OrganizerPages/eventDetails/BibsTabScreen";
import RoleTabScreen from "OrganizerPages/eventDetails/roleTabScreen";
import CharityTabScreen from "OrganizerPages/eventDetails/NGOTabScreen";
import SponsorsTabComponent from "OrganizerPages/eventDetails/SponsorsTabComponent";
import ParticipantBreakUp from "OrganizerPages/eventDetails/ParticipantBreakUp";

const ViewerDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEventName, setIsEventName] = React.useState("");
  const [activeTab, setActiveTab] = useState<string | null>("home");
  const { id } = useParams();
  const Navigate = useNavigate();
  const { token } = useAuth();

  function EventData() {
    setIsLoading(true);
    const postData = { eventId: id };
    axios
      .post("/getEventStatus", postData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          //   setEventStatus(res.data.data.status);
          setIsEventName(data.event_name);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    EventData();
  }, []);

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-24  mt-md-2 ">{isEventName}</h6>
          </Col>
          {/* <Col>
            <div className="d-flex justify-content-end ">
              <DefaultButton
                onClick={() => Navigate(`/org/vieweventdata/${id}`)}
                className="default-action-button text-white"
                variant="primary"
                type="button"
              >
                View Event
              </DefaultButton>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Tabs
            defaultActiveKey={"home"}
            id="event-home-tabs"
            className="event-home-nav-tabs"
            onSelect={handleSelectTab}
          >
            <Tab eventKey="home" title="Home">
              {activeTab === "home" && <HomeTabScreen />}
            </Tab>
            <Tab eventKey="participantBreakup" title="Participant Break up">
              {activeTab === OrgDashboardType.ParticipantBreakUp && (
                <ParticipantBreakUp />
              )}
            </Tab>
            {/* <Tab eventKey="registartion" title="Registration">
              {activeTab === OrgDashboardType.Registartion && (
                <RegistrationTabScreen />
              )}
            </Tab> */}
            {/* <Tab eventKey="refund" title="Refund">
              {activeTab === OrgDashboardType.Refund && <RefundTabScreen />}
            </Tab> */}
            {/* <Tab eventKey="messages" title="Messages">
              {activeTab === OrgDashboardType.Messages && <MessageTabScreen />}
            </Tab>
            <Tab eventKey="email" title="Email">
              {activeTab === OrgDashboardType.Email && <EmailTabScreen />}
            </Tab>
            <Tab eventKey="offlineEmail" title="Offline email">
              {activeTab === OrgDashboardType.OfflineEmail && (
                <OfflineEmailTabScreen />
              )}
            </Tab>
            <Tab eventKey="offlineRegistartion" title="Offline Registration">
              {activeTab === OrgDashboardType.OfflineRegistartion && (
                <OfflineRegistrationTabScreen />
              )}
            </Tab> */}
            {/* <Tab eventKey="coupons" title="Coupons">
              {activeTab === OrgDashboardType.Coupons && <CouponsTabScreen />}
            </Tab>
            <Tab eventKey="bibs" title="Bibs">
              {activeTab === OrgDashboardType.Bibs && <BibsTabScreen />}
            </Tab>
            <Tab eventKey="charity" title="Charities">
              {activeTab === OrgDashboardType.Charity && <CharityTabScreen />}
            </Tab>
            <Tab eventKey="partners" title="Partners & Sponsors">
              {activeTab === OrgDashboardType.Partners && (
                <SponsorsTabComponent />
              )}
            </Tab> */}
          </Tabs>
        </Row>
      </Container>
    </>
  );
};

export default ViewerDashboard;
