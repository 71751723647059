import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface PhotoCardProps {
  photo: {
    id: number;
    title: string;
    location: string;
    date: string;
    imageUrl: string;
    organizerLogo: string;
    organizerName: string;
  };
  className: string;
}

const PhotoCard: React.FC<PhotoCardProps> = ({ photo, className }) => {
  const navigate = useNavigate();
  return (
    <Card
      className={`${className} mb-4 border-0 shadow`}
      style={{
        cursor: "pointer",
      }}
      position-relative
      //   onClick={() => navigate("/photos")}
    >
      <Image src={photo.imageUrl} className={`${className}`} />

      <Card.Body
        className="d-flex flex-column position-absolute w-100 bottom-0"
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 22.29%, #000 75.56%)",
        }}
      >
        <div className="pt-3 pb-2">
          <Image src={photo.organizerLogo} alt="Organizer logo" />
          <span className="ps-3 fs-14 ff-ns text-white fw-700">
            {photo.organizerName}
          </span>
        </div>
        <Card.Title className="text-white">
          <h1 style={{}}>{photo.title}</h1>
        </Card.Title>
        <Card.Text className="fs-14 ff-ns fw-400 text-white mb-1">
          {photo.location}
        </Card.Text>
        <Card.Text className="fs-14 ff-ns fw-700 text-white">
          {photo.date}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PhotoCard;
