import { Team } from "../types/Team";
import ourteam1 from "assets/images/ourteam1.png";
import ourteam2 from "assets/images/ourteam2.png";
import ourteam3 from "assets/images/ourteam3.png";
import ourteam4 from "assets/images/ourteam4.png";
import ourteam5 from "assets/images/ourteam5.png";

export const TeamList: Team[] = [
  {
    id: 1,
    title: "Sarah Thompson",
    designation: "Graphic Designer",
    description:
      "Sarah Thompson brings creativity and visual flair to our marketing materials.",
    imageUrl: "/images/ourteam1.png",
  },
  {
    id: 2,
    title: "Jane Smith",
    designation: "COO",
    description:
      "Jane Smith brings extensive experience in event management and logistics.",
    imageUrl: "/images/ourteam2.png",
  },
  {
    id: 3,
    title: "John Doe",
    designation: "CEO",
    description:
      "John Doe is a visionary leader with a strong passion for marathon running.",
    imageUrl: "/images/ourteam3.png",
  },
  {
    id: 4,
    title: "Emily Davis",
    designation: "Event Coordinator",
    description:
      "Emily Davis ensures smooth execution of our marathons, from start to finish.",
    imageUrl: "/images/ourteam4.png",
  },
  {
    id: 5,
    title: "Michael Johnson",
    description:
      "Michael Johnson is a creative marketer who excels in promoting endurance events.",
    designation: "Marketing Director",
    imageUrl: "/images/ourteam5.png",
  },
];
