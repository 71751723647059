import { useQuery } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

export const orgGetUserParticipantDetails = (
  bookingId: number,
  participantId: number
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const axiosPrivate = useAxiosPrivate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ["organiserUserEdit", bookingId, participantId],
    async () => {
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: "/organiserUserEdit",
          data: {
            bookingId: bookingId,
            participantId: participantId,
          },
        });
        return response.data;
      } catch (error) {
        // toast.error("Error fetching user participants");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
