import React, { useState, useEffect } from "react";
import { Form as Formdata, Formik } from "formik";
import { object, string, number } from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { TextField, TextSelect } from "utils/FormControls";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import { useEventDashboard } from "utils/useCreateEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useCouponDashboard } from "utils/useCoupon";

const EarlyBirdDiscount = ({ setIntialScreen, handleReloadScreen }: any) => {
  const { id } = useParams();
  console.log("id", id);
  const isEditEvent = IsEditEvent();
  const isEventDashboard = useEventDashboard();
  const [Id, setId] = useState();
  console.log("ID", Id);
  const axiosPrivate = useAxiosPrivate();
  const isCouponDashboard = useCouponDashboard();
  // let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isEventDashboard || isCouponDashboard) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id]);
  const { storeEventId } = useEvent();
  const [isLoading, setIsLoading] = useState(false);
  const [isEarlyRaceList, setIsEarlyRaceList] = useState<any>([]);
  const [isRaceList, setIsRaceList] = useState<any>([]);
  const isViewEvent = ViewEvent();

  function CouponRaceList() {
    // axios.post(
    //   "/couponRaceList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/couponRaceList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; id: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; id: any; amount: any }[] = [];
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            id: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              id: items.raceId,
              amount: items.amount,
            });
          }
        });
        setIsRaceList(arrayData);

        setIsEarlyRaceList(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      CouponRaceList();
    }
  }, [Id]);

  const EarlyBirdInitialValue = {
    validFrom: "",
    validTo: "",
    discount: "",
    couponRaceType: "",
  };

  const earlyBirdSchema = object({
    validFrom: string().required("Valid from is required"),
    validTo: string().required("valid to is required"),
    discount: string().required("Discount is required"),
    couponRaceType: string().required("Race name is required"),
  });

  const handleEarlyBirdSubmit = (values: any) => {
    const discount = values.discount;
    const raceAmount: any = getRaceAmount(Number(values.couponRaceType));

    if (discount >= raceAmount) {
      toast.error("Early bird amount must be less than race amount", {
        autoClose: 1500,
      });
    } else {
      setIsLoading(true);
      const postData = {
        eventId: Id || storeEventId,
        raceId: values.couponRaceType,
        discount: values.discount,
        validFrom: values.validFrom,
        validTo: values.validTo,
      };

      // axios.post("/createEarlyBirdCoupons", postData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     Accept: "application/json",
      //   },
      // });
      axiosPrivate({
        url: "/createEarlyBirdCoupons",
        data: postData,
        method: "POST",
      })
        .then((res) => {
          if (res.data.status) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            toast.success(`${res.data.message},`, { autoClose: 1000 });
            setTimeout(() => {
              handleReloadScreen();
              setIntialScreen(false);
            }, 2000);
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            Object.keys(res.data.message).forEach((key) => {
              res.data.message[key].forEach((message: any) => {
                // console.log(`${key}: ${message}`);
                toast.error(` ${message}`);
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoading(false));
    }
  };
  const getRaceAmount = (id1: number): string | undefined => {
    const country = isRaceList.find((c: any) => c.id === id1);
    return country ? country.amount ?? "0" : "0";
  };
  return (
    <>
      {isLoading && <Loader />}
      <Row className="mt-4">
        <h6 className="text-darkgray fw-400 fs-13">
          1. Early bird discount is applied for the selected dates.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          2. This is only a flat amount that will be shown instead of the actual
          race amount for the selected dates.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          3. Early Bird can be given only per race.
        </h6>
        <h6 className="text-darkgray fw-400 fs-13">
          4. Please add and verify the early bird discounts. Once created, they
          cannot be edited/deleted.
        </h6>
      </Row>
      <Formik
        validationSchema={earlyBirdSchema}
        onSubmit={handleEarlyBirdSubmit}
        initialValues={EarlyBirdInitialValue}
        enableReinitialize={true}
      >
        {({ handleSubmit, values }) => (
          <Formdata noValidate onSubmit={handleSubmit}>
            <div className="px-0  d-flex flex-column justify-content-center">
              <Row className="my-2 d-flex align-items-center">
                <Col xs={12} md={5}>
                  <TextSelect
                    name="couponRaceType"
                    options={isEarlyRaceList}
                    label="Please Select Race *"
                    type="select"
                  />
                </Col>
                <Col xs={12} md={7} className="d-flex justify-content-start">
                  {values.couponRaceType && (
                    <Form.Group>
                      <Form.Label>
                        Race Amount ₹{" "}
                        {getRaceAmount(Number(values.couponRaceType))}
                      </Form.Label>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <>
                      <Row className="mt-2">
                        <Col xs={12} md={4}>
                          <TextField
                            type="datetime-local"
                            name="validFrom"
                            label="Valid From *"
                            min={new Date().toISOString().slice(0, -8)}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextField
                            type="datetime-local"
                            name="validTo"
                            label="Valid To *"
                            min={values.validFrom}
                          />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextField
                            type="text"
                            name="discount"
                            label="Early Bird Amount *"
                          />
                        </Col>
                      </Row>
                    </>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="my-md-3 d-flex align-items-center justify-content-end">
                {!isViewEvent && (
                  <Col className="d-flex justify-content-end">
                    <DefaultButton
                      type="submit"
                      variant="outline-primary"
                      className=" default-action-button"
                    >
                      Submit
                    </DefaultButton>
                  </Col>
                )}
              </Row>
            </div>
          </Formdata>
        )}
      </Formik>
    </>
  );
};

export default EarlyBirdDiscount;
