export const GenderList = [
  { name: "Male", id: "male" },
  { name: "Female", id: "female" },
  { name: "All", id: "all" },
];

export const StatusList = [
  { name: "Apporved", id: "a" },
  { name: "Rejected", id: "r" },
];
