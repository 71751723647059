import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Form as FormData, Formik } from "formik";
import * as Yup from "yup";
import { TextField, TextSelect } from "utils/FormControls";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import { RoleBaseType } from "types/RoleBasedType";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const RoleTabScreen = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const eventId = decryptText(String(id));
  const { storeEventId, storeAccountId } = useEvent();

  const [isVerify, setIsVerify] = useState(false);
  const [isSelect, setIsSelect] = useState<any>([]);
  const [isSelectedID, setIsSelectedID] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [isRolesList, setIsRolesList] = useState<any>();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [verifiedData, setIsVerifiedData] = useState<RoleBaseType | null>(null);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [isVerifiedEmail, setIsVerifiedEmail] = useState();
  const [charityList, setCharityList] = useState<any>([]);

  const handleClose = () => {
    setShow(false);
    setIsVerify(false);
    setIsVerifiedData(null);
    setIsExistingUser(false);
  };
  const handleShow = () => setShow(true);
  const handleConfirmModalShow = () => setIsConfirmModal(true);
  const handleConfirmModalClose = () => setIsConfirmModal(false);
  const handleEditModalShow = () => setIsEditModal(true);
  const handleEditModalClose = () => setIsEditModal(false);

  function GetRoles() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getRoles",
      method: "GET",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setIsSelect(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function GetRolesList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/rolesList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setIsRolesList(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function GetCharityList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getCharities",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          const modifiedCharities = data.map((charity: any) => ({
            name: charity.charity_name,
            id: charity.id,
          }));

          setCharityList(modifiedCharities);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  const filteredRoleData = isRolesList?.filter(
    (data: any) => data.id === isSelectedID
  );

  const initialValues = {
    name: !isVerify ? "" : verifiedData?.name,
    email: !isVerify ? isVerifiedEmail : verifiedData?.email,
    password: "",
    confirmPassword: "",
    phone: !isVerify ? "" : verifiedData?.phone,
    roleId: "",
    charityId: "",
  };

  const EditinitialValues = {
    roleId: filteredRoleData && filteredRoleData[0]?.role_name_id,
    changePassword: "",
    charityId: filteredRoleData && filteredRoleData[0]?.charity_id,
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string(),
    password: Yup.string(),
    confirmPassword: Yup.string(),
    phone: Yup.string(),
    roleId: Yup.string(),
    charityId: Yup.string(),
  });

  const onSubmit = (values: any) => {
    setIsLoading(true);
    const { name, roleId, password, confirmPassword, phone, charityId } =
      values;
    const postData = {
      email: isVerifiedEmail,
      roleId: roleId,
      name: name,
      phone: phone,
      password: password,
      confirmPassword: confirmPassword,
      eventId: eventId,
      accountId: storeAccountId,
      charityId: charityId,
    };
    axiosPrivate({
      url: "/createRole",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          handleClose();
          setTimeout(() => {
            GetRolesList();
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(res.data.message).forEach((key) => {
            res.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
          handleClose();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setIsVerify(false);
        setIsVerifiedData(null);
        setIsExistingUser(false);
      });
  };

  const handleEditSubmit = (values: any) => {
    setIsLoading(true);
    const postData = {
      id: isSelectedID,
      roleId: values.roleId,
      changePassword: values.changePassword,
      charityId: values.charityId,
    };

    axiosPrivate({
      url: "/editRole",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          GetRolesList();
          handleEditModalClose();
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.error(`${message}`);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  const handleVerifyRole = (values: any) => {
    setIsLoading(true);
    const isEmail = values.email;
    const postData = {
      event_id: eventId,
      ...values,
    };
    axiosPrivate({
      url: "/verifyEmail",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          setIsVerify(true);
          setIsVerifiedEmail(isEmail);
          if (data) {
            setIsVerifiedData(data);
            setIsExistingUser(true);
          }
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          toast.error(`${message}`, { autoClose: 1000 });
          handleClose();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const CreateRoleModal = () => {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>
            {!isVerify
              ? "Event Role Allocation Email Verification"
              : "Creating Roles"}
          </Modal.Title>
        </Modal.Header>
        {!isVerify ? (
          <Formik initialValues={{ email: "" }} onSubmit={handleVerifyRole}>
            {() => {
              return (
                <FormData noValidate className="pb-md-3 px-md-4">
                  <Modal.Body>
                    <Row>
                      <Col className="col-12">
                        <TextField name="email" label="Email * " type="text" />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className="py-2 border border-0 pb-3">
                    <button
                      type="submit"
                      className="btn btn-primary text-white rounded-1 px-5 py-1"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn border-primary text-primary rounded-1 px-5 py-1 "
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </FormData>
              );
            }}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values }) => {
              return (
                <FormData noValidate className="pb-md-2 px-md-4">
                  <Modal.Body>
                    {isExistingUser ? (
                      <>
                        <Row>
                          <Col className="col-12">
                            <div className="form-floating input-bx form-row w-100 form-control">
                              <div className="col-12">
                                <input
                                  name="name"
                                  type="text"
                                  value={initialValues.name}
                                  disabled
                                />
                                <span>Name *</span>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 my-md-3">
                            <div className="form-floating input-bx form-row w-100 form-control">
                              <div className="col-12">
                                <input
                                  name="email"
                                  type="text"
                                  value={initialValues.email}
                                  disabled
                                />
                                <span>Email *</span>
                              </div>
                            </div>
                          </Col>

                          <Col className="col-12">
                            <div className="form-floating input-bx form-row w-100 form-control">
                              <div className="col-12">
                                <input
                                  name="phone"
                                  type="text"
                                  value={
                                    initialValues.phone
                                      ? initialValues.phone
                                      : "----"
                                  }
                                  disabled
                                />
                                <span>Mobile No *</span>
                              </div>
                            </div>
                          </Col>
                          <Col className="mt-md-2">
                            <TextSelect
                              name="roleId"
                              options={isSelect}
                              label="Roles *"
                              type="select"
                            />
                          </Col>
                          {values.roleId === "6" && (
                            <Col className="col-12">
                              <TextSelect
                                name="charityId"
                                options={charityList}
                                label="Charity List *"
                                type="select"
                              />
                            </Col>
                          )}
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col className="col-12">
                            <TextField
                              name="name"
                              label="Name * "
                              type="text"
                            />
                          </Col>
                          <Col className="col-12">
                            <div className="form-floating input-bx form-row w-100 form-control">
                              <div className="col-12">
                                <input
                                  name="email"
                                  type="text"
                                  value={isVerifiedEmail}
                                  disabled
                                />
                                <span>Email *</span>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <TextSelect
                              name="roleId"
                              options={isSelect}
                              label="Roles *"
                              type="select"
                            />
                          </Col>
                          {values.roleId === "6" && (
                            <Col className="col-12">
                              <TextSelect
                                name="charityId"
                                options={charityList}
                                label="Charity List *"
                                type="select"
                              />
                            </Col>
                          )}
                          <Col className="col-12">
                            <TextField
                              name="phone"
                              label="Mobile No * "
                              type="text"
                            />
                          </Col>
                          <Col className="col-12">
                            <TextField
                              name="password"
                              label="Password * "
                              type="password"
                            />
                          </Col>
                          <Col className="col-12">
                            <TextField
                              name="confirmPassword"
                              label="Confirm Password * "
                              type="password"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer className=" border border-0">
                    <button
                      type="submit"
                      className="btn btn-primary text-white rounded-1 px-5 "
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn border-primary text-primary rounded-1 px-5 py-1 "
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </FormData>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  };

  const EditRoleModal = () => {
    return (
      <Modal show={isEditModal} onHide={handleEditModalClose} centered>
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>Edit Roles</Modal.Title>
        </Modal.Header>
        <Formik initialValues={EditinitialValues} onSubmit={handleEditSubmit}>
          {({ values }) => {
            return (
              <FormData noValidate className="pb-md-3 px-md-4">
                <Modal.Body>
                  <Row>
                    <div>
                      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                        <div className="col-12">
                          <input
                            type="text"
                            className={` ${"is-invalid"}`}
                            value={
                              filteredRoleData && filteredRoleData[0]?.name
                            }
                            disabled
                          />
                          <span>Name</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                        <div className="col-12">
                          <input
                            type="text"
                            className={` ${"is-invalid"}`}
                            value={
                              filteredRoleData && filteredRoleData[0]?.email
                            }
                            disabled
                          />
                          <span>Email</span>
                        </div>
                      </div>
                    </div>
                    <Col>
                      <TextSelect
                        name="roleId"
                        options={isSelect}
                        label="Roles *"
                        type="select"
                      />
                    </Col>
                    {(values.roleId === "6" ||
                      filteredRoleData[0]?.role_name_id === 6) && (
                      <Col className="col-12">
                        <TextSelect
                          name="charityId"
                          options={charityList}
                          label="Charity List *"
                          type="select"
                        />
                      </Col>
                    )}
                    <div>
                      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                        <div className="col-12">
                          <input
                            type="text"
                            className={` ${"is-invalid"}`}
                            value={
                              filteredRoleData &&
                              filteredRoleData[0]?.mobileNumber
                            }
                            disabled
                          />
                          <span>Phone No</span>
                        </div>
                      </div>
                    </div>
                    <Col>
                      <TextField
                        name="changePassword"
                        label="Change Password"
                        type="password"
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="py-2 border border-0 pb-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-white rounded-1 px-5 py-1"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleEditModalClose}
                    className="btn border-primary text-primary rounded-1 px-5 py-1 "
                  >
                    Close
                  </button>
                </Modal.Footer>
              </FormData>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  const handleDeleteRole = () => {
    setIsLoading(true);
    axiosPrivate({
      url: "/deleteRole",
      data: {
        id: isSelectedID,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          GetRolesList();
          setIsConfirmModal(false);
          toast.success(`${message}`, { autoClose: 1000 });
        } else {
          toast.error(`${message}`);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setIsVerify(false);
        setIsVerifiedData(null);
        setIsExistingUser(false);
      });
  };

  useEffect(() => {
    GetRoles();
    GetRolesList();
    GetCharityList();
  }, []);

  const ConfirmModal = () => {
    return (
      <Modal show={isConfirmModal} onHide={handleConfirmModalClose} centered>
        <Modal.Body className="py-md-5 text-center">
          <h4>Are you sure your want to delete user role </h4>
          <div className="d-flex align-items-center justify-content-center mt-md-3">
            <DefaultButton
              variant="primary"
              className="default-action-button me-md-3 px-md-4"
              type="button"
              onClick={handleDeleteRole}
            >
              Yes
            </DefaultButton>
            <DefaultButton
              variant="outline-primary"
              className="default-action-button px-md-4"
              type="button"
              onClick={() => setIsConfirmModal(false)}
            >
              No
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <DefaultButton
          variant="primary"
          type="button"
          className="default-action-button text-white mt-3 px-2"
          onClick={handleShow}
        >
          Add User
        </DefaultButton>
        {isRolesList && isRolesList.length > 0 ? (
          <Row className="my-2">
            {isRolesList && isRolesList.length > 0 && (
              <div className="mt-md-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile No</th>
                      <th>Role</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isRolesList.map((role: any) => {
                      const Id = role.id;
                      return (
                        <tr key={role.id}>
                          <td className="align-middle">{role.name}</td>
                          <td className="align-middle">{role.email}</td>
                          <td className="align-middle">{role.mobileNumber}</td>
                          <td className="align-middle">{role.roleName}</td>
                          <td className="align-middle">
                            <DefaultButton
                              variant="primary"
                              type="button"
                              className="default-action-button text-white px-2"
                              onClick={() => {
                                handleConfirmModalShow();
                                setIsSelectedID(Id);
                              }}
                            >
                              Delete
                            </DefaultButton>

                            <DefaultButton
                              variant="primary"
                              type="button"
                              className="default-action-button text-white ms-md-3"
                              onClick={() => {
                                handleEditModalShow();
                                setIsSelectedID(Id);
                              }}
                            >
                              Edit
                            </DefaultButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </Row>
        ) : (
          <div className="mt-md-4">
            <h5>No roles assigned. Click 'Add User' to assign roles. </h5>
          </div>
        )}
      </Container>
      {show && <CreateRoleModal />}
      {isConfirmModal && <ConfirmModal />}
      {isEditModal && <EditRoleModal />}
    </>
  );
};

export default RoleTabScreen;
