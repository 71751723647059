import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import axios from "utils/axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import UserLayout from "layouts/UserLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { NGOData } from "types/NGOData";
import { CategoryTextField, TextField } from "utils/FormControls";
import { readFileAsBase64 } from "utils/utils";
import DefaultInput from "components/Inputs/DefaultInput";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import DefaultButton from "components/DefaultButton/DefaultButton";
import getCroppedImg from "./eventsforms/cropImage";
import Cropper from "react-easy-crop";
import { Icon } from "components/Icon";
import { NGOCategories } from "utils/NgoCategoriesList";
import HydRunnerLayout from "layouts/HydRunnerLayout";

const NgosForm = () => {
  const [description, setDescriptionContent] = useState();
  const [updateDescription, setUpdateDescriptionContent] = useState();
  const [project, setProjectContent] = useState();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [isSelectCategory, setIsSelectCategory] = useState<string>();
  const [bannerImage, setBannerImage] = useState({ preview: "", raw: "" });
  const [croppedBannerImage, setCroppedBannerImage] = useState("");
  const [isSelectImageName, setSelectImageName] = useState("");
  const [logoImage, setLogoImage] = useState({ preview: "", raw: "" });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedLogoImage, setCroppedLogoImage] = useState("");
  const [selectCropImage, setSelectCropImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState();
  const [raceNames, setRaceNames] = useState<any>();
  const [raceId, setRaceId] = useState<any>();
  const [bibAmountValues, setBibAmountValues] = useState(
    Array(counter).fill("")
  );
  const [isStatus, setIsStatus] = useState(false);

  // const [bibPrefixValues, setBibPrefixValues] = useState(
  //   Array(counter).fill("")
  // );

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    ngoname: "",
    ngoLogo: "",
    ngoBanner: "",
    ngoWebsiteUrl: "",
    instagramUrl: "",
    email: "",
    ngoCategory: "",
    facebookUrl: "",
    twitterUrl: "",
    youTubeUrl: "",
    fcraCertificate: null,
    eightyGCertificate: null,
    twelveACertificate: null,
    photos: "",
    accountNo: "",
    ifscCode: "",
    bankAddress: "",
    cancelChequeAttachment: null,
    lastITreturnDoc: null,
    personalName: "",
    personalMobileNo: "",
    personalEmail: "",
    minAmount: "",
    bibAmount: "",
    bibPrefix: "",
    // charityPrefix5k: "",
    // charityBibAmount10k: "",
    // charityPrefix10k: "",
    // charityBibAmountHalf: "",
    // charityPrefixHalf: "",
    // charityBibAmountFull: "",
    // charityPrefixFull: "",
    raceName: raceNames,
  };

  const validationSchema = Yup.object().shape({
    ngoname: Yup.string().required("NGO Name is required"),
    ngoLogo: Yup.string(),
    ngoBanner: Yup.string(),
    ngoWebsiteUrl: Yup.string().required("Invalid URL format for NGO Website"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    ngoCategory: Yup.string(),
    instagramUrl: Yup.string(),
    facebookUrl: Yup.string(),
    twitterUrl: Yup.string(),
    youTubeUrl: Yup.string(),
    fcraCertificate: Yup.mixed().test(
      "is-file",
      "FCRA Certificate is required",
      (value) => value !== null
    ),

    eightyGCertificate: Yup.mixed().test(
      "is-file",
      "80G Certificate is required",
      (value) => value !== null
    ),
    twelveACertificate: Yup.mixed().test(
      "is-file",
      "12A Certificate is required",
      (value) => value !== null
    ),
    cancelChequeAttachment: Yup.mixed().test(
      "is-file",
      "Cancel Cheque Attachment is required",
      (value) => value !== null
    ),
    lastITreturnDoc: Yup.mixed().test(
      "is-file",
      "Last IT Return Document is required",
      (value) => value !== null
    ),
    photos: Yup.string(),
    accountNo: Yup.string().required("Account Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
    bankAddress: Yup.string().required("Bank Address is required"),
    personalName: Yup.string().required(" Name is required"),
    personalMobileNo: Yup.string().required(" Mobile number is required"),
    personalEmail: Yup.string().required(" Email is required"),
    minAmount: Yup.string().required("Min amount is required field"),
    // charityBibAmount: Yup.string(),
    charityPrefix: Yup.string(),
    // raceName: Yup.string(),
  });

  function CouponRaceList() {
    axios
      .post(
        "/userEventRaceList",
        {
          eventId: 84, //As of now it static after Hyderbad narathon we need to convert into dynamic
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          const raceName: any =
            data && data?.tickets.map((ticket: any) => ticket.ticket_name);
          const raceId: any =
            data && data?.tickets.map((ticket: any) => ticket.id);

          setRaceId(raceId);
          setRaceNames(raceName);
          setCounter(raceName.length);
        }
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    CouponRaceList();
  }, []);
  const isDisabled = true;
  const handleSubmit = (values: NGOData) => {
    if (!selectedFiles || selectedFiles.length === 0) {
      // alert("Please select one or more files.");
      toast.info("Please select one or more files.");
      return;
    }

    const formData: any = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(`file-${i}`, selectedFiles.item(i)!);
    }

    const filesData = {
      fcra_certificate: values.fcraCertificatebase64,
      "80g_certificate": values.eightyGCertificatebase64,
      "12a_certificate": values.twelveACertificatebase64,
      cancel_cheque: values.cancelChequeAttachmentbase64,
      last_it_return_document: values.lastITreturnDocbase64,
      ngo_banner: croppedBannerImage,
      ngo_logo: croppedLogoImage,
    };

    const raceTimeData = [];

    for (let i = 0; i < raceNames.length; i++) {
      raceTimeData.push({
        raceId: raceId[i],
        raceName: raceNames[i],
        bibAmount: values.bibAmount[i],
        // bibPrefix: values.bibPrefix[i],
      });
    }

    const postData = {
      ngoName: values.ngoname,
      ngoWebsite: values.ngoWebsiteUrl,
      ngoEmail: values.email,
      ngoType: isSelectCategory,
      ngoFacebookUrl: values.facebookUrl,
      ngoTwitterUrl: values.twitterUrl,
      ngoYoutubeUrl: values.youTubeUrl,
      minAmount: values.minAmount,
      // bibAmount: values.charityBibAmount,
      ngoInstagramUrl: values.instagramUrl,
      ngoDescription: updateDescription,
      ngoProject: project,
      ngoBankAccounNumber: values.accountNo,
      ngoBankIfsc: values.ifscCode,
      ngoBankAddress: values.bankAddress,
      ngoContactName: values.personalName,
      ngoContactNumber: values.personalMobileNo,
      ngoContactEmail: values.personalEmail,
      uploads: filesData,
      qualifyData: raceTimeData,
      bibPrefix: values.charityPrefix,
    };

    setIsLoading(true);
    axios
      .post("/ngoRegistrationForm", postData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          setIsStatus(true);
          toast.success(message, { autoClose: 1000 });
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  //   email input
  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];
  const editorToolbar =
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help";

  const editorContentStyle =
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

  const editorAPIKey = process.env.REACT_APP_TINY_API_KEY;
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleGeneralEditorChange = (content: any) => {
    setUpdateDescriptionContent(content);
  };
  const handleProjectEditorChange = (content: any) => {
    setProjectContent(content);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = event.target.files;
      if (files.length > 30) {
        alert("You can upload only up to 30 images.");
        return;
      }
      setSelectedFiles(files);
    }
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        isSelectImageName === "Banner"
          ? bannerImage.preview
          : logoImage.preview,
        croppedAreaPixels
      );

      if (isSelectImageName === "Banner") {
        setCroppedBannerImage(croppedImage ?? "");
      } else {
        setCroppedLogoImage(croppedImage ?? "");
      }

      handleClose();
      // setImage({
      //   preview: croppedImage,
      //   raw: croppedImage,
      // });
    } catch (e) {
      // console.error(e);
      return;
    }
  };
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const CONTAINER_HEIGHT = 300;
  const handlefileChange = (e: any) => {
    if (e.target.files.length) {
      if (isSelectImageName === "Banner") {
        setBannerImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setSelectCropImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setLogoImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setSelectCropImage(URL.createObjectURL(e.target.files[0]));
      }

      handleOpen();
    }
  };
  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      <Modal
        show={open}
        onHide={handleClose}
        // className="modal-dialog modal-fullscreen"
        centered
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title style={{ flexGrow: 1 }}>
            <Row className="align-items-center">
              <Col className="fw-blod text-black2 pt-2">
                <h4>Add Image</h4>
              </Col>
              <Col xs="auto">
                <DefaultButton
                  type="button"
                  variant="outline-primary"
                  className=" default-action-button"
                  onClick={handleClose}
                >
                  Cancel
                </DefaultButton>
              </Col>
              <Col xs="auto" className="ps-0">
                <DefaultButton
                  type="button"
                  className="px-3 fw-normal"
                  variant="primary"
                  onClick={showCroppedImage}
                >
                  Save
                </DefaultButton>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div> */}

          <Cropper
            image={selectCropImage}
            crop={crop}
            zoom={zoom}
            cropSize={{
              width: isSelectImageName === "Banner" ? 1100 : 240,
              height: isSelectImageName === "Banner" ? 350 : 100,
            }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onMediaLoaded={(mediaSize) => {
              // Adapt zoom based on media size to fit max height
              setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
            }}
          />
          {/* </div> */}
        </Modal.Body>
      </Modal>
      <HydRunnerLayout>
        <>
          {!isStatus ? (
            <Container className="d-flex flex-column justify-content-center ">
              <div>
                <h1>Apply to be an NGO partner with us</h1>
              </div>
              <div className="mt-md-3">
                <h4>
                  On this page, you can submit your request to partner with
                  IFINISH. Whether you are a returning NGO or partnering with us
                  for the first time, please submit the following request and we
                  will get back to you shortly.
                </h4>
              </div>
              <div className="my-md-3">
                <h3 className="text-primary">NGO DETAILS</h3>
              </div>
              <div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {(formikProps) => {
                    return (
                      <Form noValidate>
                        <Row>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              name="ngoname"
                              label="Name of NGO *"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="url"
                              name="ngoWebsiteUrl"
                              label="NGO Website URL *"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="email"
                              name="email"
                              label="Email *"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                              <div className="col">
                                <select
                                  onChange={(e) =>
                                    setIsSelectCategory(e.target.value)
                                  }
                                >
                                  <option value="">Choose an option</option>
                                  {NGOCategories?.map((option: any) => {
                                    return (
                                      <>
                                        <option
                                          key={option.name}
                                          value={option.name}
                                          onChange={option.name}
                                        >
                                          {option.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                                <span>NGO category *</span>
                              </div>
                            </div>
                            {/* <TextSelect
                          name="ngoCategory"
                          options={NGOCategories}
                          label="NGO Category *"
                          type="select"
                        /> */}
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="url"
                              name="facebookUrl"
                              label="Facebook URL "
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="url"
                              name="instagramUrl"
                              label="Instagram URL "
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="url"
                              name="twitterUrl"
                              label="Twitter URL "
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="url"
                              name="youTubeUrl"
                              label="Youtube Video URL "
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <DefaultInput
                              label="FCRA Cretificate *"
                              type="file"
                              placeholder="Upload Image"
                              className="mt-md-2"
                              accept="image/png, image/heic, image/jpeg, image/jpg"
                              onChange={async (event: any) => {
                                const file = event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null;

                                if (file) {
                                  const base64 = await readFileAsBase64(file);
                                  formikProps.setFieldValue(
                                    "fcraCertificatebase64",
                                    base64
                                  );
                                }

                                formikProps.setFieldValue(
                                  "fcraCertificate",
                                  file
                                );
                              }}
                              isError={
                                formikProps.touched["fcraCertificate"] &&
                                !!formikProps.errors["fcraCertificate"]
                              }
                              error={formikProps.errors["fcraCertificate"]}
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <DefaultInput
                              label="80G Cretificate *"
                              type="file"
                              placeholder="Upload Image"
                              className="mt-md-2"
                              accept="image/png, image/heic, image/jpeg, image/jpg"
                              onChange={async (event: any) => {
                                const file = event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null;

                                if (file) {
                                  const base64 = await readFileAsBase64(file);
                                  formikProps.setFieldValue(
                                    "eightyGCertificatebase64",
                                    base64
                                  );
                                }

                                formikProps.setFieldValue(
                                  "eightyGCertificate",
                                  file
                                );
                              }}
                              isError={
                                formikProps.touched["eightyGCertificate"] &&
                                !!formikProps.errors["eightyGCertificate"]
                              }
                              error={formikProps.errors["eightyGCertificate"]}
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <DefaultInput
                              label="12A Cretificate *"
                              type="file"
                              placeholder="Upload Image"
                              className="mt-md-2"
                              accept="image/png, image/heic, image/jpeg, image/jpg"
                              onChange={async (event: any) => {
                                const file = event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null;

                                if (file) {
                                  const base64 = await readFileAsBase64(file);
                                  formikProps.setFieldValue(
                                    "twelveACertificatebase64",
                                    base64
                                  );
                                }

                                formikProps.setFieldValue(
                                  "twelveACertificate",
                                  file
                                );
                              }}
                              isError={
                                formikProps.touched["twelveACertificate"] &&
                                !!formikProps.errors["twelveACertificate"]
                              }
                              error={formikProps.errors["twelveACertificate"]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-12 col-12 mb-md-3">
                            <h5 className="my-4">
                              Banner Image (Size: 350*1100){" "}
                              <span className="text-primary">*</span>
                            </h5>
                            {bannerImage.preview ? (
                              <>
                                <label htmlFor="upload-button">
                                  <Card
                                    data-target="upload-button"
                                    style={{ height: 350, width: 1100 }}
                                    onClick={() => setSelectImageName("Banner")}
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={
                                        croppedBannerImage ??
                                        "/images/cardBackground.png"
                                      }
                                      style={{ height: 350, width: 1100 }}
                                      alt="Card image cap"
                                    />
                                  </Card>
                                </label>
                              </>
                            ) : (
                              <>
                                <label htmlFor="upload-button">
                                  <Card
                                    data-target="upload-button"
                                    style={{ height: 350, width: 1100 }}
                                    onClick={() => setSelectImageName("Banner")}
                                  >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon
                                        iconName="CloudUpload"
                                        className="text-primary"
                                        size={68}
                                      />
                                      <h5 className="pt-md-2">
                                        Upload your photo
                                      </h5>
                                    </Card.Body>
                                  </Card>
                                </label>
                              </>
                            )}
                            <input
                              type="file"
                              id="upload-button"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handlefileChange}
                            />
                          </Col>
                          <Col className="col-md-12 col-12 mb-md-3">
                            <h5 className="my-4">
                              Logo Image (Size: 40*40)
                              <span className="text-primary">*</span>
                            </h5>
                            {logoImage.preview ? (
                              <>
                                <label htmlFor="upload-logo-button">
                                  <Card
                                    data-target="upload-logo-button"
                                    style={{ height: 100, width: 240 }}
                                    onClick={() => setSelectImageName("Logo")}
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={
                                        croppedLogoImage ??
                                        "/images/cardBackground.png"
                                      }
                                      style={{ height: 100, width: 240 }}
                                      alt="Card image cap"
                                    />
                                  </Card>
                                </label>
                                <></>
                              </>
                            ) : (
                              <>
                                <label htmlFor="upload-logo-button">
                                  <Card
                                    data-target="upload-logo-button"
                                    style={{ height: 100, width: 240 }}
                                    onClick={() => setSelectImageName("Logo")}
                                  >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                      <h5 className="pb-md-2">
                                        Upload your photo
                                      </h5>
                                      <Icon
                                        iconName="CloudUpload"
                                        className="text-primary"
                                        size={22}
                                      />
                                    </Card.Body>
                                  </Card>
                                </label>
                              </>
                            )}
                            <input
                              type="file"
                              id="upload-logo-button"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handlefileChange}
                            />
                          </Col>
                        </Row>
                        <Row className="my-md-4">
                          <Col className="col-md-6 col-12">
                            <h5>
                              Description
                              <span className="text-primary ms-1">*</span>
                            </h5>
                            <Editor
                              apiKey={editorAPIKey}
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              initialValue={description}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: editorPlugins,
                                toolbar: editorToolbar,
                                content_style: editorContentStyle,
                                setup: (editor: {
                                  on: (arg0: string, arg1: () => void) => void;
                                  getContent: () => any;
                                }) => {
                                  editor.on("change", () => {
                                    handleGeneralEditorChange(
                                      editor.getContent()
                                    );
                                  });
                                },
                              }}
                            />
                          </Col>
                          <Col className="col-md-6 col-12">
                            <h5>
                              Project
                              <span className="text-primary ms-1">*</span>
                            </h5>
                            <Editor
                              apiKey={editorAPIKey}
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              initialValue={project}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: editorPlugins,
                                toolbar: editorToolbar,
                                content_style: editorContentStyle,
                                setup: (editor: {
                                  on: (arg0: string, arg1: () => void) => void;
                                  getContent: () => any;
                                }) => {
                                  editor.on("change", () => {
                                    handleProjectEditorChange(
                                      editor.getContent()
                                    );
                                  });
                                },
                              }}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-md-3">
                          <h5>
                            Upload files{" "}
                            <span className="text-primary ms-1">*</span>
                          </h5>
                          <Col className="mt-md-1">
                            <input
                              type="file"
                              accept="image/png,image/jpeg, image/jpg"
                              multiple
                              onChange={handleFileChange}
                            />
                          </Col>
                        </Row>

                        {/* charity donation start */}
                        <Row className="mt-md-3">
                          <div>
                            <h3 className="text-primary">Donation Amount</h3>
                          </div>
                          <Col className="col-md-4 col-12">
                            <TextField
                              type="text"
                              label="Min Amount *"
                              name="minAmount"
                            />
                          </Col>
                          <Col className="col-md-4 col-12">
                            <TextField
                              type="text"
                              label="Charity Prefix *"
                              name="charityPrefix"
                            />
                          </Col>
                        </Row>

                        <Row className="my-md-3">
                          {Array.from(Array(counter)).map((_, index: any) => {
                            return (
                              <div
                                className="d-flex align-items-center p-0 m-0"
                                key={index}
                              >
                                <div className="col-md-10 m-0 p-0">
                                  <Row className="m-0 p-0">
                                    <Col xs={12} md={4}>
                                      <CategoryTextField
                                        name={`raceName[${index}]`}
                                        type="text"
                                        label="Name *"
                                        disabled={isDisabled}
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <CategoryTextField
                                        name={`bibAmount[${index}]`}
                                        type="number"
                                        label="Charity Bib Amount *"
                                        min="0"
                                        onChange={(e: any) => {
                                          const bibValues = [
                                            ...bibAmountValues,
                                          ];
                                          bibValues[index] = e.target.value;
                                          setBibAmountValues(bibValues);
                                          formikProps.handleChange(e);
                                        }}
                                        onWheel={(event: any) =>
                                          event.currentTarget.blur()
                                        }
                                      />
                                    </Col>
                                    {/* <Col xs={12} md={4}>
                                  <CategoryTextField
                                    name={`bibPrefix[${index}]`}
                                    type="text"
                                    maxLength={2}
                                    label="Charity Bib Prefix "
                                    onChange={(e: any) => {
                                      const bibValues = [...bibPrefixValues];
                                      bibValues[index] = e.target.value;
                                      setBibPrefixValues(bibValues);
                                      formikProps.handleChange(e);
                                    }}
                                  />
                                </Col> */}
                                  </Row>
                                </div>
                              </div>
                            );
                          })}
                        </Row>
                        {/* <Row className="my-md-3">
                      <Col md={2} className="d-flex align-items-center pt-2">
                        <label>10k Run</label>
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Bib Amount *"
                          name="charityBibAmount10k"
                        />
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Prefix "
                          name="charityPrefix10k"
                        />
                      </Col>
                    </Row>
                    <Row className="my-md-3">
                      <Col md={2} className="d-flex align-items-center pt-2">
                        <label>Half marathon</label>
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Bib Amount *"
                          name="charityBibAmountHalf"
                        />
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Prefix "
                          name="charityPrefix"
                        />
                      </Col>
                    </Row>
                    <Row className="my-md-3">
                      <Col md={2} className="d-flex align-items-center pt-2">
                        <label>Full marathon</label>
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Bib Amount *"
                          name="charityBibAmountFull"
                        />
                      </Col>
                      <Col className="col-md-4 col-12">
                        <TextField
                          type="text"
                          label="Charity Prefix "
                          name="charityPrefix"
                        />
                      </Col>
                    </Row> */}

                        {/* charity donation end */}

                        {/* bank details start */}
                        <Row className="mt-md-4">
                          <div className="my-md-3">
                            <h3 className="text-primary">NGO BANK DETAILS</h3>
                          </div>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="Bank A/C No *"
                              name="accountNo"
                            />{" "}
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="IFSC Code *"
                              name="ifscCode"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="Bank Address *"
                              name="bankAddress"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <DefaultInput
                              label="Cancel Cheque *"
                              type="file"
                              placeholder="Upload Image"
                              className="mt-md-2"
                              accept="image/png, image/heic, image/jpeg, image/jpg"
                              onChange={async (event: any) => {
                                const file = event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null;

                                if (file) {
                                  const base64 = await readFileAsBase64(file);
                                  formikProps.setFieldValue(
                                    "cancelChequeAttachmentbase64",
                                    base64
                                  );
                                }

                                formikProps.setFieldValue(
                                  "cancelChequeAttachment",
                                  file
                                );
                              }}
                              isError={
                                formikProps.touched["cancelChequeAttachment"] &&
                                !!formikProps.errors["cancelChequeAttachment"]
                              }
                              error={
                                formikProps.errors["cancelChequeAttachment"]
                              }
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <DefaultInput
                              label="Last IT return Document"
                              type="file"
                              placeholder="Upload Image"
                              className="mt-md-2"
                              accept="image/png, image/heic, image/jpeg, image/jpg"
                              onChange={async (event: any) => {
                                const file = event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null;

                                if (file) {
                                  const base64 = await readFileAsBase64(file);
                                  formikProps.setFieldValue(
                                    "lastITreturnDocbase64",
                                    base64
                                  );
                                }

                                formikProps.setFieldValue(
                                  "lastITreturnDoc",
                                  file
                                );
                              }}
                              isError={
                                formikProps.touched["lastITreturnDoc"] &&
                                !!formikProps.errors["lastITreturnDoc"]
                              }
                              error={formikProps.errors["lastITreturnDoc"]}
                            />
                          </Col>
                        </Row>
                        {/* bank details end */}

                        {/* primary contact person details start */}
                        <Row className="mt-md-4">
                          <div className="my-md-3">
                            <h3 className="text-primary">
                              PRIMARY CONTACT PERSON DETAILS
                            </h3>
                          </div>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="Name *"
                              name="personalName"
                            />{" "}
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="Mobile Number *"
                              name="personalMobileNo"
                            />
                          </Col>
                          <Col className="col-md-6 col-12 mb-md-3">
                            <TextField
                              type="text"
                              label="Email *"
                              name="personalEmail"
                            />
                          </Col>
                        </Row>
                        {/* primary contact person details end */}

                        <div className="row my-4">
                          <div className="col d-flex justify-content-end">
                            <DefaultButton
                              type="submit"
                              variant="outline-primary"
                              className=" default-action-button px-md-5"
                            >
                              Submit
                            </DefaultButton>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Container>
          ) : (
            <Container className="d-flex flex-column justify-content-center py-5">
              <div className="text-primary">
                <h1>Thank you for registering with us.</h1>
              </div>
              <div className="mt-md-3">
                <h4>
                  We will go through the application and get back to you if we
                  have any queries.
                </h4>
              </div>
            </Container>
          )}
        </>
      </HydRunnerLayout>
    </>
  );
};

export default NgosForm;
