import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { Icon } from "components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import useEventName from "utils/useEventName";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import { decryptText } from "utils/utils";
import { useAccount } from "context/AccountContext";
import { useEvent } from "context/EventContext";
import { useAuth } from "context/AuthContext";
import Loader from "components/Loader/Loader";
import DataTable from "react-data-table-component";
import CsvConverter from "utils/CsvConverter";
import moment from "moment";

const IndividualCharityDashboard = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const eventName = useEventName();
  const axiosPrivate = useAxiosPrivate();
  const [charitiesParticipants, setCharitiesParticipants] = useState<any>([]);
  const [charitiesBibParticipants, setCharitiesBibParticipants] = useState<any>(
    []
  );
  const [searchValue, setSearchValue] = useState<any>("");
  const [searchBibValue, setSearchBibValue] = useState<any>("");
  const [charityAmounts, setCharityAmounts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { storeAccountId } = useEvent();
  const { userId } = useAuth();
  function IndividualCharity() {
    const postData = {
      eventId: decryptText(String(id)),
      userId: userId,
      accountId: storeAccountId,
    };
    axiosPrivate({
      url: "/charityRoleStatistics",
      data: postData,
      method: "POST",
    })
      .then((response: any) => {
        const { status, data, message } = response.data;
        if (status) {
          setCharityAmounts(data);
          setCharitiesParticipants(data.charityParticipants);
          setCharitiesBibParticipants(data.charityBibParticipants);
        } else {
          Object.keys(response.data.message).forEach((key) => {
            message[key].forEach((message: any) => {
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    IndividualCharity();
  }, []);
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffe9e9",
        width: "160px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const charityParticipants = [
    {
      name: "First name",
      selector: (row: any) => (row.first_name ? row.first_name : "—"),
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row: any) => (row.last_name ? row.last_name : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: false,
    },
    {
      name: "Charity Amount",
      selector: (row: any) => (row.charity_amount ? row.charity_amount : "—"),
      sortable: false,
    },
    {
      name: "Donated at",
      selector: (row: any) =>
        row.updated_date
          ? moment(row.updated_date).format("YYYY-MM-DD HH:MM:SS")
          : "—",
      sortable: false,
    },
  ];
  const charityBibParticipants = [
    {
      name: "First name",
      selector: (row: any) => (row.first_name ? row.first_name : "—"),
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row: any) => (row.last_name ? row.last_name : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: false,
    },
    {
      name: "Charity Amount",
      selector: (row: any) =>
        row.charity_bib_amount ? row.charity_bib_amount : "—",
      sortable: false,
    },
  ];
  const CardComponent = ({ title, value, Img }: any) => {
    return (
      <Card className="card info-card sales-card border-tagBlue shadow bg-white rounded my-md-4">
        <Card.Body className="card-body">
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text>
            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center my-md-2">
                <img src={Img} alt="Icon" width="44px" />
              </div>
              <div className="ps-3">
                <h6>{value}</h6>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };
  const moneyFormat = (number: any) => {
    const value = number.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return value;
  };

  const approvedFilterResult = charitiesParticipants.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const charityBibFilterResult = charitiesBibParticipants.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchBibValue?.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchBibValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchBibValue?.toLowerCase())
  );
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Events
              </span>
            </div>
            <h6 className="fs-18  mt-md-2 ">{eventName}</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className="fs-24  mt-md-2 text-primary ">
              {charityAmounts.ngo_name}
            </h6>
          </Col>
        </Row>
        <Row className="my-md-4">
          <Card className="border border-tagBlue">
            <Row className="p-0 m-0 p-md-3">
              <Col>
                <CardComponent
                  title="Charity Amount"
                  value={moneyFormat(charityAmounts.charityAmount ?? 0)}
                  Img="/images/charity.svg"
                />
              </Col>
              <Col>
                <CardComponent
                  title="Charity Bib Amount"
                  value={moneyFormat(charityAmounts.charityBibAmount ?? 0)}
                  Img="/images/charity_bib.svg"
                />
              </Col>
              <Col>
                <CardComponent
                  title="Total Amount"
                  value={moneyFormat(charityAmounts.charityTotalAmount ?? 0)}
                  Img="/images/total.svg"
                />
              </Col>
            </Row>
          </Card>
        </Row>
        <Row className="mt-md-3 p-0 m-0">
          <Col className="p-0 m-0">
            <h2>Charity Participants List</h2>
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                <input
                  type="text"
                  className="border-0"
                  placeholder="Search by email, name"
                  style={{ outline: "none", width: "320px" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div>
              <DataTable
                pagination
                paginationRowsPerPageOptions={[25]}
                paginationPerPage={25}
                columns={charityParticipants}
                data={approvedFilterResult}
                customStyles={tableCustomStyles}
                actions={
                  <CsvConverter
                    data={charitiesParticipants}
                    headingKeys={approvedFilterResult[0]}
                    fileName="Charity Participants"
                  />
                }
              />
            </div>
          </Col>
        </Row>
        <Row className="my-md-3 p-0 m-0">
          {" "}
          <Col className="p-0 m-0">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                <input
                  type="text"
                  className="border-0"
                  placeholder="Search by email, name"
                  style={{ outline: "none", width: "320px" }}
                  onChange={(e) => setSearchBibValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div>
              <h2>Charity Bib Participants List</h2>
              <DataTable
                pagination
                paginationRowsPerPageOptions={[25]}
                paginationPerPage={25}
                columns={charityBibParticipants}
                data={charityBibFilterResult}
                customStyles={tableCustomStyles}
                actions={
                  <CsvConverter
                    data={charitiesBibParticipants}
                    headingKeys={charitiesBibParticipants[0]}
                    fileName="Charity Bibs Participants List"
                  />
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default IndividualCharityDashboard;
