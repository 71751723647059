import { useMutation } from "react-query";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";

export const useCreateChangeCategoryOrderId = () => {
  const axiosPrivate = useAxiosPrivate();

  return useMutation(async (params: any) => {
    const { participantId } = params;
    try {
      const response = await axiosPrivate({
        method: "POST",
        url: "/createChangeCategoryOrder",
        data: {
          participantId: participantId,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error creating Change Category Order");
      throw error;
    }
  });
};
