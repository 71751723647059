import React, { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { decryptText } from "utils/utils";
import * as XLSX from "xlsx";
import moment from "moment";

const EliteParticipantsList = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eliteParticipants, setEliteParticipants] = useState([]);
  const [searchValue, setSearchValue] = useState<string>("");
  function EliteParticipants() {
    const postData = { eventId: decryptText(String(id)) };
    setIsLoading(true);
    axiosPrivate({
      url: "/eliteParticipants",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setEliteParticipants(data);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    EliteParticipants();
  }, []);
  //react datatable configuration Starts
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffe9e9",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const itemsPerPageOptions = [10, 25, 50, 100];

  const eliteParticipantsList = [
    {
      name: "RegistrationId",
      selector: (row: any) => (row.registration_id ? row.registration_id : "—"),
      sortable: true,
    },
    {
      name: "First name",
      selector: (row: any) => (row.first_name ? row.first_name : "—"),
      sortable: true,
    },
    {
      name: "Last name",
      selector: (row: any) => (row.last_name ? row.last_name : "—"),
      sortable: true,
      width: "120px",
    },

    {
      name: "Gender",
      selector: (row: any) => (row.gender ? row.gender : "—"),
      sortable: true,
    },
    {
      name: "Phone No",
      selector: (row: any) => (row.mobile_number ? row.mobile_number : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: true,
      width: "220px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) =>
        row.date_of_birth
          ? moment(row.date_of_birth).format("DD-MM-YYYY")
          : "—",
      sortable: false,
      width: "140px",
    },

    {
      name: "Whatsapp No",
      selector: (row: any) => (row.whatsapp_number ? row.whatsapp_number : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Blood Group",
      selector: (row: any) => (row.blood_group ? row.blood_group : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Race Name",
      selector: (row: any) => (row.raceName ? row.raceName : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Registration Type",
      selector: (row: any) => (row.is_offline === 1 ? "Offline" : "Online"),
      sortable: true,
    },
    {
      name: "T-shirt size",
      selector: (row: any) => (row.t_shirt_size ? row.t_shirt_size : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Pre Order Photos",
      selector: (row: any) =>
        row.pre_order_photos ? row.pre_order_photos : "—",
      sortable: false,
      width: "130px",
    },

    {
      name: "Bib Name",
      selector: (row: any) => (row.bib_name ? row.bib_name : "—"),
      sortable: false,
      width: "140px",
    },
    {
      name: "Qualifying Distance",
      selector: (row: any) =>
        row.qualifying_distance ? row.qualifying_distance : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Other Qualifying Distance",
      selector: (row: any) =>
        row.other_qualifying_distance ? row.other_qualifying_distance : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Qualifying Timing",
      selector: (row: any) =>
        millisecondsToHHMMSS(row.qualifying_timing)
          ? millisecondsToHHMMSS(row.qualifying_timing)
          : "—",
      sortable: false,
      width: "150px",
    },

    {
      name: "Uploaded Id Proof",
      selector: (row: any) => (row.upload_id_proof ? row.upload_id_proof : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "History of Health Issues",
      selector: (row: any) =>
        row.history_of_health_issues ? row.history_of_health_issues : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Other of Health Issues",
      selector: (row: any) =>
        row.other_health_issues ? row.other_health_issues : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Emergency Contact Name",
      selector: (row: any) =>
        row.emergency_contact_name ? row.emergency_contact_name : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Emergency Contact Number",
      selector: (row: any) =>
        row.emergency_contact_number ? row.emergency_contact_number : "—",
      sortable: false,
      width: "150px",
    },
    {
      name: "Area",
      selector: (row: any) => (row.area ? row.area : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Street Address",
      selector: (row: any) => (row.street_address ? row.street_address : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "City",
      selector: (row: any) => (row.city ? row.city : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "State",
      selector: (row: any) => (row.state ? row.state : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Country",
      selector: (row: any) => (row.country ? row.country : "—"),
      sortable: false,
      width: "150px",
    },
    {
      name: "Pincode",
      selector: (row: any) => (row.pincode ? row.pincode : "—"),
      sortable: false,
      width: "150px",
    },
  ];
  const participantsFilter = eliteParticipants.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  function padZero(number: any) {
    return number < 10 ? `0${number}` : number;
  }
  const millisecondsToHHMMSS = (milliseconds: any) => {
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    let formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      remainingSeconds
    )}`;
    return formattedTime;
  };
  const exportToExcel = () => {
    const wsData = [
      [
        "Registration Id",
        "First name",
        "Last name",
        "Gender",
        "Phone No",
        "Email",
        "Date of Birth",
        "Whatsapp No",
        "Blood Group",
        "Race Name",
        "Registration Type",
        "T-shirt Size",
        "Pre Order Photos",
        "Bib Name",
        "Qualifying Distance",
        "Other Qualifying Distance",
        "Qualifying Timing",
        "Uploaded Id Proof",
        "History of Health Issues",
        "Other of Health Issues",
        "Emergency Contact Name",
        "Emergency Contact Number",
        "Country",
        "State",
        "City",
        "Street Address",
        "Area",
        "Pincode",
      ],
      ...eliteParticipants.map((item: any) => [
        item["registration_id"],
        item["first_name"],
        item["last_name"],
        item["gender"],
        item["mobile_number"],
        item["email"],
        item["date_of_birth"],
        item["whatsapp_number"],
        item["blood_group"],
        item["raceName"],
        item["is_offline"],
        item["t_shirt_size"],
        item["pre_order_photos"],
        item["bib_name"],
        item["qualifying_distance"],
        item["other_qualifying_distance"],
        [millisecondsToHHMMSS(item["qualifying_timing"])],
        item["upload_id_proof"],
        item["history_of_health_issues"],
        item["other_health_issues"],
        item["emergency_contact_name"],
        item["emergency_contact_number"],
        item["country"],
        item["state"],
        item["city"],
        item["street_address"],
        item["area"],
        item["pincode"],
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`eliteparticipants_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`}`
    );
  };

  //react datatable configuration Ends
  return (
    <>
      {isLoading && <Loader />}
      <div className="mt-md-5">
        <div className="d-flex justify-content-end align-items-center">
          <div className="w-50 d-flex justify-content-end align-items-center">
            <div className=" py-md-2 px-md-3 border border-primary rounded d-flex align-items-center ">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by Name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
            <div className="ms-md-4">
              <DefaultButton
                type="submit"
                variant="primary"
                className="px-md-5 bg-railBlue text-white"
                onClick={() => {
                  exportToExcel();
                }}
              >
                Download Excel
              </DefaultButton>
            </div>
          </div>
        </div>
        <div className="mt-md-3">
          <DataTable
            pagination
            paginationRowsPerPageOptions={itemsPerPageOptions}
            columns={eliteParticipantsList}
            data={participantsFilter}
            customStyles={tableCustomStyles}
          />
        </div>
      </div>
    </>
  );
};

export default EliteParticipantsList;
