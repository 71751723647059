import React, { createContext, useContext, ReactNode, useState } from "react";

interface AccountContextProps {
  companyAccount: string | null;
  updateAccount: (newAccount: string | null) => void;
}

// Create the context
const AccountContext = createContext<AccountContextProps | undefined>(
  undefined
);

// Create a provider component
export const AccountProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [companyAccount, setCompanyAccount] = useState<string | null>(() => {
    // Retrieve the account details from local storage on component mount
    const storedAccount = localStorage.getItem("companyAccount");
    return storedAccount ? JSON.parse(storedAccount) : null;
  });

  const updateAccount = (newAccount: string | null) => {
    setCompanyAccount(newAccount);
    localStorage.setItem("companyAccount", JSON.stringify(newAccount));
  };

  return (
    <AccountContext.Provider value={{ companyAccount, updateAccount }}>
      {children}
    </AccountContext.Provider>
  );
};

// Create a custom hook to use the context
export const useAccount = (): AccountContextProps => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
