import React from "react";
import { Icon } from "components/Icon";

interface NextIconProps {
  className: string;
  onClick: () => void;
}
const NextIcon: React.FC<NextIconProps> = ({ className, onClick }) => {
  return (
    <div
      className={`${className} d-flex justify-content-center align-items-center`}
      style={{
        height: "48px",
        width: "48px",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Icon
        iconName="ChevronRight"
        className="text-white  border-5"
        size={24}
      />
    </div>
  );
};

export default NextIcon;
