import { useState, useEffect } from "react";
import { Breadcrumb, Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PaymentSuccess from "components/MyPayments/PaymentSuccess/PaymentSuccess";
import PaymentPending from "components/MyPayments/PaymentPending/PaymentPending";
import PaymentFailed from "components/MyPayments/PaymentFailed/PaymentFailed";
import Invoices from "components/MyPayments/Invoices/Invoices";
import { useUserPaymentStatus } from "api/UserPaymentStatus";
import { Payment } from "types/UserPaymentStatus";
import Loader from "components/Loader/Loader";

const MyPayments = () => {
  const navigation = useNavigate();

  const [successPayments, setSuccessPayments] = useState([]);
  const [failedPayments, setFailedPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const {
    data: userPaymentStatus,
    isLoading: userPaymentStatusLoading,
    isError: userPaymentStatusError,
  } = useUserPaymentStatus();

  useEffect(() => {
    setSuccessPayments(
      userPaymentStatus?.data?.filter(
        (payment: Payment) => payment.paymentStatus === "S"
      )
    );

    setFailedPayments(
      userPaymentStatus?.data?.filter(
        (payment: Payment) => payment.paymentStatus === "F"
      )
    );

    setPendingPayments(
      userPaymentStatus?.data?.filter(
        (payment: Payment) => payment.paymentStatus === "P"
      )
    );
  }, [userPaymentStatus]);

  if (userPaymentStatusLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container className="mt-4 mb-4">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Payments</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <Container>
        <span className="blockTitle"> My Payments</span>
        <Tabs
          defaultActiveKey={"successful"}
          id="event-tabs"
          className="event-nav-tabs border-bottom pt-4"
          mountOnEnter={true}
        >
          <Tab eventKey="successful" title="Successful">
            <PaymentSuccess successPayments={successPayments} />
          </Tab>
          <Tab eventKey="pending" title="Pending">
            <PaymentPending pendingPayments={pendingPayments} />
          </Tab>
          <Tab eventKey="failed" title="Failed">
            <PaymentFailed failedPayments={failedPayments} />
          </Tab>
          <Tab eventKey="invoice" title="Invoice">
            <Invoices />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default MyPayments;
