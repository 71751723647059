import { Card } from "react-bootstrap";

import { Icon } from "components/Icon";

interface TrainingProgrammeCardProps {
  programme: {
    id: number;
    title: string;
    description: string;
    registrationFee: number;
    image: string;
  };
}

const TrainingProgrammeCard: React.FC<TrainingProgrammeCardProps> = ({
  programme,
}) => {
  return (
    <Card
      className="mb-4 border-0"
      style={{
        width: "348px",
        height: "274px",
        flexShrink: "0",
        borderRadius: "5px 5px 4px 4px",
        background: "#000000",
      }}
      position-relative
    >
      <Card.Img
        className="position-relative"
        variant="top"
        src={programme.image}
        style={{
          width: "348px",
          height: "144px",
          flexShrink: "0",
          border: "0px",
          borderRadius: "4px 4px 0px 0px",
        }}
      />
      <div
        className="position-absolute"
        style={{
          width: "348px",
          height: "63px",
          zIndex: "1",
          bottom: "130px",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
        }}
      ></div>

      <Card.Body
        className="d-flex flex-column "
        style={{ marginTop: "-40px", zIndex: "100" }}
      >
        <Card.Text className=" fs-14 ff-ns fw-500 text-white">
          {programme.description}
        </Card.Text>
        <Card.Title>
          <h1 className="text-white" style={{ minHeight: "" }}>
            {programme.title}
          </h1>
        </Card.Title>

        <Card.Text className=" fs-14 ff-saira fw-600 text-white">
          REGISTER FOR ₹ {programme.registrationFee}{" "}
          <Icon iconName="ChevronRight" className="text-bold" size={14} />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TrainingProgrammeCard;
