import React, {
  useState,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};
type AuthContextType = {
  token: string | null;
  userId: number | null;
  userName: string | null;
  userRole: string | null;
  isAuthenticated: boolean;
  status: string | null;
  email: string | null;
  mobileNumber: string | null;
  profileImage: string;
  login: (
    token: string,
    userId: number,
    userRole: string,
    status: string | null,
    email: string | null,
    mobileNumber: string | null,
    profileImage: string
  ) => void;
  userLogin: (token: string, name: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<Props> = (props) => {
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem("token") || null;
  });
  const [userId, setUserId] = useState<number | null>(() => {
    return parseInt(localStorage.getItem("userId") || "") || null;
  });
  const [userRole, setUserRole] = useState<string | null>(() => {
    return localStorage.getItem("userRole") || null;
  });
  const [profileImage, setIsProfileImage] = useState<any>(() => {
    return localStorage.getItem("Image");
  });
  const [userName, setUserName] = useState<string | null>(() =>
    localStorage.getItem("name")
  );
  const [isEmail, setEmail] = useState<any>();
  const isAuthenticated = !!token;
  const location = useLocation();
  React.useLayoutEffect(function () {
    if (userRole === "0") {
      const pathName = location.pathname.split("/");
      if (pathName.includes("org")) {
        setToken(token);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userRole");
        localStorage.removeItem("Image");
        localStorage.removeItem("name");
      }
    }
  }, []);

  const login = (
    token: string,
    userId: number,
    userRole: string,
    status: string | null,
    email: string | null,
    mobileNumber: string | null,
    profileImage: string
  ) => {
    setEmail(email);
    setToken(token);
    setUserId(userId);
    setUserRole(userRole);
    setIsProfileImage(profileImage);
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId.toString());
    localStorage.setItem("userRole", userRole);
    localStorage.setItem("Image", profileImage);
    // Store other information if needed
  };

  const userLogin = (token: string, name: string) => {
    setToken(token);
    setUserName(name);
    localStorage.setItem("token", token);
    localStorage.setItem("name", name);
  };

  const logout = () => {
    setToken(null);
    setUserId(null);
    setUserRole(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    localStorage.clear();
    // Remove other stored information if needed
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        userRole,
        isAuthenticated,
        profileImage,
        status: null, // Add any default values for other properties
        email: isEmail,
        mobileNumber: null,
        userName,
        userLogin,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
