import React, { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import { useAuth } from "context/AuthContext";
import { encryptText } from "utils/utils";
import ReadMore from "utils/ReadMore";

const ExpoAdminDashboard = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userId } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [eventsList, setEventsList] = useState<any>([]);

  function userEventList() {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      data: { expoUserId: userId },
      url: "/expoAdminEvents",
    })
      .then((res) => {
        const { status, events, message } = res.data;

        if (status) {
          setEventsList(events);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    userEventList();
  }, []);

  const handleRoleBasedClick = (role: string, Card_id: string) => {
    console.log("role", role);
    if (role === "user") {
      navigate(`/support/expobibcollection/${encryptText(Card_id)}`);
    } else if (role === "admin") {
      navigate(`/support/expoadminevent/${encryptText(Card_id)}`);
    }
  };

  const EventCard = ({
    Card_id,
    imageSrc1,
    imageSrc2,
    organizer,
    eventName,
    location,
    Enddate,
    city,
    role,
  }: any) => {
    return (
      <Card
        style={{ width: "348px", cursor: "pointer" }}
        onClick={() => handleRoleBasedClick(role, Card_id)}
        // onClick={() =>
        //   navigate(`/support/expoadminevent/${encryptText(Card_id)}`)
        // }
      >
        <Card.Img
          variant="top"
          src={imageSrc1 ?? "/images/cardBackground.png"}
        />
        <span
          className="bg-primary fs-12 ff-ns text-white px-2 py-1 fw-500"
          style={{
            position: "absolute",
            borderRadius: "4px",
            right: "30px",
            top: "182px",
          }}
        >
          {role}
        </span>

        <Card.Body className="px-md-4 flex-grow-1 m-md-0 pb-md-2">
          <div className="d-flex align-items-center py-md-2">
            <div className="p-0 m-0">
              <img
                style={{ height: 40, width: 40 }}
                className="rounded-2 m-0 p-0"
                alt={imageSrc2}
                src={imageSrc2 ?? "/Images/IfiinishOrgDefaultLogo.svg"}
              />
            </div>
            <div className="d-flex align-items-center">
              <h5 className="text-uppercase fw-600 ms-3 mt-2">{organizer}</h5>
            </div>
          </div>
          <div>
            <h1
              className={`fw-600 ${
                eventName.length > 23 && eventName.length < 31 && " fs-20"
              }`}
            >
              {eventName && eventName.length > 30 ? (
                <ReadMore eventName={eventName} />
              ) : (
                `${eventName}`
              )}
            </h1>

            <h6 className="fw-600">
              {location},{city}
            </h6>
          </div>

          <div className=" d-flex flex-column align-items-start justify-content-center ">
            <div className="d-flex justify-content-between w-100">
              <div className="p-0 m-0">
                {/* <h6 className="fw-normal">
                  <span>Start Date: </span>
                  {Startdate}
                </h6> */}
                <h6 className="fw-normal">
                  <span>End Date: </span>
                  {Enddate}
                </h6>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isLoading && <Loader />}

      <Container className="mt-md-4">
        <Row>
          <Col>
            <h6 className="fs-24  mt-md-2 ">Events List</h6>
          </Col>
        </Row>

        <div className="my-md-4">
          {eventsList?.length > 0 ? (
            <Row>
              {eventsList.map((event: any) => {
                const card = event.eventDetails;
                const roleassigned = event.role;
                return (
                  <Col key={card.id} md={4} className="my-md-2">
                    <EventCard
                      Card_id={card.id}
                      imageSrc1={card.imageUrl}
                      imageSrc2={card.organizerLogo}
                      eventName={card.event_name}
                      organizer={card.organizerName}
                      location={card.location}
                      city={card.city}
                      Enddate={card.event_end_date}
                      role={roleassigned}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            "No active events for this organization yet. Start creating one by clicking 'Create Event'!"
          )}
        </div>
      </Container>
    </>
  );
};

export default ExpoAdminDashboard;
