import React, { useState, useLayoutEffect, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Profile from "./Profile/Profile";
import { useAuth } from "context/AuthContext";
import OrganizationProfile from "OrganizerPages/OrganizationProfile/OrganizationProfile";
import { useUserProfile } from "api/GetUserProfile";
import { Icon } from "components/Icon";
import Loader from "./Loader/Loader";
import IsOrganizer from "utils/IsOrganizer";

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Header = () => {
  const Navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  const [isPathName, setIsPathName] = useState<boolean>();
  const isOrganizer = IsOrganizer();
  const [isOrgVisible, setIsOrgVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isOpenOrganizationProfileModal, setIsOpenOrganizatioProfileModal] =
    useState<boolean>(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState<boolean>(false);
  const location = useLocation();
  const { data: userData, isLoading: userDataLoading } = useUserProfile();
  useLayoutEffect(() => {
    const pathName = window.location.pathname.split("/");
    const value = pathName.includes("accounts");
    if (value) {
      setIsPathName(false);
    } else {
      setIsPathName(true);
    }
  }, [window.location.pathname]);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("org")) {
      setIsOrgVisible(true);
    }
  }, []);
  const handleCloseProfileModal = () => {
    setIsOpenProfileModal(false);
    setIsOpenOrganizatioProfileModal(false);
  };

  const HandleLogout = () => {
    Navigate("/org/login");
    localStorage.removeItem("ifinishToken");
    logout();
  };

  const HandleLoginAsUser = () => {
    Navigate("/");
    logout();
  };

  if (userDataLoading) {
    <Loader />;
  }

  // let orgAccount: any;
  // useLayoutEffect(() => {
  //   const pathName = window.location.pathname.split("/");
  //   orgAccount = pathName.includes("org");
  // }, [window.location.pathname]);

  const handleNavigate = () => {
    if (isOrganizer) {
      Navigate("/org/accounts");
    } else {
      Navigate("/");
    }
  };
  return (
    <>
      <nav
        className={`${
          !isOrgVisible && "sticky-top"
        } shadow-sm px-3 bg-white rounded pt-2 d-none d-md-block`}
      >
        {!isOrgVisible && (
          <Container className="d-flex justify-content-end align-items-center pt-1">
            <div
              onClick={() => Navigate("/contactus")}
              style={{ cursor: "pointer" }}
            >
              <h6 className="text-primary mb-0">
                Create an Event
                <span className="mx-3">
                  <img src="/images/Line.svg" alt="Line" />
                </span>
              </h6>
            </div>
            <div>
              <h6 className="mb-0">
                Shop
                <span className="mx-3">
                  <img src="/images/Line.svg" alt="Line" />
                </span>
              </h6>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpenProfileModal(true)}
            >
              {isAuthenticated && !isOrgVisible ? (
                <div>
                  {!!userData?.data?.profile_image ? (
                    <img
                      src={userData?.data?.profile_image}
                      alt="Dropdown Icon"
                      className="rounded-circle"
                      style={{
                        border: "1px solid lightGray",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Icon
                      iconName="PersonCircle"
                      className="text-lightGray"
                      size={18}
                    />
                  )}
                  <img
                    src="/images/Orion_angle-down 6.png"
                    alt="Arrow Icon"
                    className="ms-2"
                  />
                </div>
              ) : (
                <div>
                  <h6 className="text-primary mb-0">Login / Register</h6>
                </div>
              )}
            </div>
          </Container>
        )}

        {isOrgVisible && isAuthenticated && (
          <Container className="d-flex justify-content-end">
            <div style={{ cursor: "pointer" }}>
              <Dropdown align="end" className="p-0 m-0 ">
                <Dropdown.Toggle
                  variant="white"
                  id="dropdown-basic"
                  className="border-0 outline-0"
                >
                  {!!userData?.data?.profile_image ? (
                    <img
                      src={userData?.data?.profile_image}
                      alt="Dropdown Icon"
                      className="rounded-circle"
                      style={{
                        border: "1px solid lightGray",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Icon
                      iconName="PersonCircle"
                      className="text-lightGray"
                      size={18}
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className="m-0 p-2">
                  {isPathName && (
                    <Dropdown.Item
                      className="dropdown dropdown-item-hover text-purple"
                      onClick={() => {
                        setIsOpenOrganizatioProfileModal(true);
                      }}
                    >
                      <i className="bi bi-box-arrow-left me-2" />
                      Edit Profile
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className="dropdown dropdown-item-hover text-purple"
                    onClick={HandleLogout}
                  >
                    <i className="bi bi-box-arrow-left me-2" />
                    Logout
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown dropdown-item-hover text-purple"
                    onClick={HandleLoginAsUser}
                  >
                    <i className="bi bi-box-arrow-left me-2" />
                    Login in as user
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        )}

        <Container fluid className="d-flex align-items-center p-0 m-0">
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
          <div className="mx-4">
            <img
              className="cursor-pointer"
              onClick={handleNavigate}
              src="/images/ifinish-logo.png"
              alt="Logo"
            />
          </div>
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
        </Container>
        <Container
          className={`d-flex align-items-center ${!isPathName && "pb-4"}`}
        >
          {isPathName && (
            <NavLink to={isOrgVisible ? "/org" : "/"} className="nav-link">
              <h6>EVENTS</h6>
            </NavLink>
          )}
        </Container>
      </nav>
      <nav
        className={`${
          !isOrgVisible && "sticky-top"
        } d-block d-md-none mobile_nav py-4 bg-light`}
      >
        <div className="d-flex justify-content-between align-items-center px-4">
          <div className="d-flex align-items-center">
            <img
              className="cursor-pointer"
              src="/images/Mobile_nav.png"
              alt="mobile toggle"
              onClick={() => setIsOpenProfileModal(true)}
            />
            <img
              src="/images/ifinish-logo.png"
              alt="Logo"
              className="ms-3 cursor-pointer"
              onClick={() => Navigate("/")}
            />
          </div>
        </div>
      </nav>
      <Profile
        isOpenProfileModal={isOpenProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
      />
      <OrganizationProfile
        isOpenProfileModal={isOpenOrganizationProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
      />
    </>
  );
};

export default Header;
