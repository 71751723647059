import { useQuery } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

export const useUserEventSponsors = (eventId: number) => {
  const axiosPublic = useAxiosPublic();

  return useQuery(
    ["useEventSponsors", eventId],
    async () => {
      if (eventId) {
        try {
          const response = await axiosPublic({
            method: "POST",
            url: "/getUserEventSponser",
            data: {
              eventId: eventId,
            },
          });
          return response.data;
        } catch (error) {
          // toast.error("Error fetching event sponsors");
          throw error;
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
