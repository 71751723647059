import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router";

interface LayoutProps {
  children: ReactNode;
}
const UserLayout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const handlePhotos = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/photos");
  };
  const handleJoinPhotoGraphy = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/joinourphotography");
  };
  const handleAbout = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/aboutus");
  };

  return (
    <div className="p-0 m-0">
      <nav className="shadow-sm px-3 bg-white rounded py-md-4 d-none d-md-block">
        <Container fluid className="d-flex align-items-center py-md-2 m-0">
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
          <div className="mx-4">
            <img src="/images/ifinish-logo.png" alt="Logo" />
          </div>
          <div className="w-100">
            <hr style={{ borderTop: "1px solid #E0E0E0", margin: "0" }} />
          </div>
        </Container>
      </nav>
      <Container className="d-flex flex-column justify-content-center py-md-5">
        {children}
      </Container>
      <Container fluid className="m-0 p-0 overflow-x-hidden">
        <div className="m-0">
          <img
            src="/images/FooterBanner.svg"
            alt="footer_banner"
            className="w-100"
          />
        </div>
      </Container>
      <div className="m-0 bg-primary py-md-2">
        <div className="row border-top mx-md-5 mx-2 py-md-5 py-4">
          <div className="col-md-5 d-flex flex-column">
            <div>
              <img src="/images/Ifinish_white.png" alt="logo_white" />
            </div>
            <div className="py-md-3 py-3">
              <h5 className="text-white about_ifinish">
                iFinish is part of Timing Technologies India Private Limited, a
                leading provider of timing services for Active sports. For more
                information about Timing Technologies, please visit Timing
                Technologies.
              </h5>
            </div>
          </div>
          <div className="col-md-3 d-flex flex-column">
            <div>
              <p className="noto_font text-white">Quick Links</p>
            </div>
            <div>
              <p className="noto_font_lists " style={{ cursor: "pointer" }}>
                Home
              </p>
              <p className="mt-2">
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={handlePhotos}
                  style={{ cursor: "pointer" }}
                >
                  Photos
                </div>
              </p>

              <p className="noto_font_lists">Shop</p>
              <div
                className="noto_font_lists text-decoration-none"
                onClick={handleJoinPhotoGraphy}
                style={{ cursor: "pointer" }}
              >
                Join our Photographers Team
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex flex-column">
            <div>
              <h5 className="noto_font text-white mt-4 mt-md-0">About</h5>
            </div>
            <div>
              <p className="mt-2">
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={handleAbout}
                  style={{ cursor: "pointer" }}
                >
                  About Us
                </div>
              </p>

              <p className="mt-2">
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </div>
              </p>
              <p className="mt-2">
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={() => navigate("/support")}
                  style={{ cursor: "pointer" }}
                >
                  Support
                </div>
              </p>

              <p className="mt-2">
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={() => navigate("/termsandcondition")}
                  style={{ cursor: "pointer" }}
                >
                  Terms & Conditions
                </div>
              </p>

              <p className="noto_font_lists" style={{ cursor: "pointer" }}>
                Privacy Policy
              </p>
            </div>
          </div>
          <div className="col-md-1 d-md-flex flex-md-column d-flex">
            <p className="noto_font_lists me-3 me-md-0">
              <img
                src="/images/icon-park-outline_telegram.png"
                alt="telegram"
              />
            </p>
            <p className="noto_font_lists me-3 me-md-0">
              <img
                src="/images/icon-park-outline_facebook.png"
                alt="Facebook"
              />
            </p>
            <p className="noto_font_lists me-3 me-md-0">
              <img
                src="/images/icon-park-outline_instagram.png"
                alt="Instagram"
              />
            </p>
            <p className="noto_font_lists">
              <img src="/images/Frame 1707478769.png" alt="twitter" />
            </p>
          </div>
        </div>
      </div>
      <Container fluid className="bg-darkred text-center m-0">
        <p className="text-white py-3 mb-0">
          © {new Date().getFullYear()} iFinish - All Rights Reserved.
        </p>
      </Container>
    </div>
  );
};

export default UserLayout;
