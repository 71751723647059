import React from "react";
import { Form, Col } from "react-bootstrap";

interface CheckboxProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  isError?: boolean;
  error?: string;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  checked,
  isError,
  error,
  className,
}) => {
  return (
    <Form.Group as={Col} className="mb-3" controlId="rememberMe">
      <Form.Check
        className={`fs-12 ff-ns fw-400 ${className} ${
          isError ? "text-primary" : ""
        }`}
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
        feedback={error}
        isInvalid={isError}
      />
    </Form.Group>
  );
};

export default Checkbox;
