import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Icon } from "components/Icon";
import DataTable from "react-data-table-component";
import DefaultButton from "components/DefaultButton/DefaultButton";
import NgoRegistrationModal from "OrganizerPages/NgoRegistrationModal";
import { NGOData } from "types/NGOData";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import Loader from "components/Loader/Loader";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { ToastContainer, toast } from "react-toastify";
import { decryptText, tableCustomStyles } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { useAccount } from "context/AccountContext";

const CharityTabScreen = () => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();
  const { companyAccount } = useAccount();

  const [isShowManageModal, setIsShowManageModal] = useState(false);
  const [reRender, setReRender] = useState<boolean>(false);
  const [modalData, setModalData] = useState<NGOData[]>();
  const [searchValue, setSearchValue] = useState<any>("");
  const [isNgoDetails, setIsNgoDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [charityId, setCharityId] = useState();
  const isViewer = companyAccount === OrgRoleEnum.Viewer;

  const handleAcceptButton = (data: any) => {
    setModalData(data);
    setIsShowManageModal(true);
  };

  function getNgoDetails() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getNgoRegistration",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setIsNgoDetails(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  const approvedData = [
    {
      name: "Name",
      selector: (row: any) => row.ngo_name,
      sortable: true,
    },
    {
      name: "URL",
      cell: (row: any) => (
        <a
          href={`${row.ngoWebsiteUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.ngo_website}
        </a>
      ),
      sortable: false,
    },
    {
      name: "Email",
      selector: (row: any) => row.ngo_email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Name",
      selector: (row: any) => row.ngo_contact_name,
      sortable: true,
      // width: "130px",
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.ngo_contact_number,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Bib Amount",
    //   selector: (row: any) => (row.bib_amount ? row.bib_amount : "—"),
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Min Amount",
      selector: (row: any) => (row.min_amount ? row.min_amount : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row: any) => (
        <div>
          {isViewer ? (
            <div>
              <Icon
                iconName={row.status === "I" ? "ToggleOff" : "ToggleOn"}
                className={row.status === "I" ? "text-gray" : "text-success"}
                size={36}
              />
            </div>
          ) : (
            <div onClick={(newState: any) => handleSwitchToggle(newState, row)}>
              <Icon
                iconName={row.status === "I" ? "ToggleOff" : "ToggleOn"}
                className={row.status === "I" ? "text-gray" : "text-success"}
                size={36}
              />
            </div>
          )}
        </div>
      ),
      width: "150px",
    },
    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];
  const pedingData = [
    {
      name: "Name",
      selector: (row: any) => row.ngo_name,
      sortable: true,
    },
    {
      name: "URL",
      cell: (row: any) => (
        <a
          href={`${row.ngoWebsiteUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.ngo_website}
        </a>
      ),
      sortable: false,
      width: "300px",
    },
    {
      name: "Email",
      selector: (row: any) => row.ngo_email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Name",
      selector: (row: any) => row.ngo_contact_name,
      sortable: true,
      // width: "130px",
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.ngo_contact_number,
      sortable: false,
      width: "200px",
    },

    {
      name: "Min Amount",
      selector: (row: any) => (row.min_amount ? row.min_amount : "—"),
      sortable: true,
      width: "150px",
    },

    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];
  const rejectedData = [
    {
      name: "Name",
      selector: (row: any) => row.ngo_name,
      sortable: true,
    },
    {
      name: "URL",
      cell: (row: any) => (
        <a
          href={`${row.ngoWebsiteUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.ngo_website}
        </a>
      ),
      sortable: false,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row: any) => row.ngo_email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Name",
      selector: (row: any) => row.ngo_contact_name,
      sortable: true,
      // width: "130px",
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.ngo_contact_number,
      sortable: true,
      width: "200px",
    },
    {
      name: "Min Amount",
      selector: (row: any) => (row.min_amount ? row.min_amount : "—"),
      sortable: true,
      width: "150px",
    },

    {
      name: "More",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];

  const successParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "A" || data.status === "I"
  );

  const pendingParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "P"
  );

  const rejectParticipant: any = isNgoDetails?.filter(
    (data: any) => data.status === "R"
  );
  const approvedTotalRows = successParticipant.length;
  const pendingTotalRows = pendingParticipant.length;
  const rejectedTotalRows = rejectParticipant.length;

  const pendingFilterResult = pendingParticipant.filter(
    (e: any) =>
      e.ngo_name &&
      e.ngo_name.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const approvedFilterResult = successParticipant.filter(
    (e: any) =>
      e.ngo_name &&
      e.ngo_name.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const rejectedFilterResult = rejectParticipant.filter(
    (e: any) =>
      e.ngo_name &&
      e.ngo_name.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleModalClose = () => {
    setShowConfirmModal(false);
    getNgoDetails();
  };

  useEffect(() => {
    getNgoDetails();
  }, [reRender, isEnabled]);

  const handleSwitchToggle = (newState: any, row: any) => {
    setIsEnabled(row.status === "I" ? true : false);
    setCharityId(row.id);
    setShowConfirmModal(true);
  };

  function handleActiveStatus() {
    setIsLoading(true);
    let userStatus;
    if (isEnabled) {
      userStatus = "A";
    } else {
      userStatus = "I";
    }
    const postData = {
      charityBibId: charityId,
      status: userStatus,
    };
    axiosPrivate({
      url: "/ngoRegistrationStatusUpdate",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          handleModalClose();
          setIsEnabled(false);
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            getNgoDetails();
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        handleModalClose();
        setIsEnabled(false);
      });
  }

  return (
    <>
      {isLoading && <Loader />}

      <div>
        <Tabs
          defaultActiveKey="success"
          id="event-registartion-tabs"
          className="event-registartion-nav-tabs border-0"
        >
          <Tab eventKey="success" title="Approved">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by name"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={approvedTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={approvedData}
                data={approvedFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
          <Tab eventKey="inComplete" title="Pending">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by email, phone no"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={pendingTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={pedingData}
                data={pendingFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
          <Tab eventKey="rejected" title="Rejected">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by email, phone no"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                paginationTotalRows={rejectedTotalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={[10]}
                paginationPerPage={10}
                columns={rejectedData}
                data={rejectedFilterResult}
                customStyles={tableCustomStyles}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      {isShowManageModal && (
        <NgoRegistrationModal
          showModal={isShowManageModal}
          handleClose={() => setIsShowManageModal(false)}
          setReRender={setReRender}
          modalData={modalData}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          title={`Are you sure you want to ${
            isEnabled ? "activate" : "deactivate"
          } the NGO?`}
          showNote={false}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={handleActiveStatus}
        />
      )}
    </>
  );
};

export default CharityTabScreen;
