import { useQuery } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

export const usePreviewEventRaceList = (eventId: string) => {
  const axiosPublic = useAxiosPublic();
  return useQuery(
    ["usePreviewEventRaceList", eventId],
    async () => {
      try {
        const response = await axiosPublic({
          method: "POST",
          url: "/userEventRaceList",
          data: {
            eventId: eventId,
          },
        });
        return response.data;
      } catch (error) {
        toast.error("Error fetching preview event race list");
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
