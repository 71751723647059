import React, { useEffect, useState } from "react";
import { useEvent } from "context/EventContext";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { decryptText } from "utils/utils";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "components/Icon";
import { toast } from "react-toastify";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import DataTable from "react-data-table-component";
import moment from "moment";
import EliteRunnerModel from "./EliteRunnerModel";
const EliteRunner = () => {
  const { id } = useParams();
  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();
  const eventId = decryptText(String(id));
  const [raceList, setRaceList] = useState<any>([]);
  const [raceType, setRaceType] = useState("-1");
  const [duration, setDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [eliteData, setEliteData] = useState([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [eliteManageModal, setEliteManageModal] = useState(false);
  const [isEliteRunner, setIsEliteRunner] = useState(false);
  const [modalId, setModalId] = useState(null);
  const itemsPerPageOptions = [10, 25, 50, 100];

  const convertTimeToMilliseconds = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  };

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: { eventId: eventId || storeEventId },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; id: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; id: any; amount: any }[] = [];
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            id: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              id: items.raceId,
              amount: items.amount,
            });
          }
        });
        setRaceList(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }
  useEffect(() => {
    CouponRaceList();
  }, []);
  function timeValidation(formattedValue: any) {
    const [hours, minutes, seconds] = formattedValue.split(":").map(Number);
    if (hours > 23) {
      // formattedValue = "23:";
      toast.error("hh has to less than 23");
    } else if (hours < 0 || isNaN(hours)) {
      formattedValue = "00:";
    }
    return formattedValue;
  }
  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    let formattedValue = numericValue.replace(/(\d{2})(?=\d)/g, "$1:");
    const verifiedTime = timeValidation(formattedValue);
    setDuration(verifiedTime);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axiosPrivate({
      url: "/eliteRunnerDetails",
      data: {
        eventId: eventId,
        ticketId: raceType,
        qualifyTiming: convertTimeToMilliseconds(duration),
      },
      method: "POST",
    })
      .then((res) => {
        const { data, status, message } = res.data;
        if (status) {
          setEliteData(data);
          setIsEliteRunner(true);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => setIsLoading(false));
  };
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        paddingLeft: "5px",
        backgroundColor: "#ffe9e9",
        width: "160px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        paddingLeft: "5px",
        paddingRight: "8px",
      },
    },
  };
  const handleEliteCloseManageModal = () => setEliteManageModal(false);
  const handleAcceptButton = (data: any) => {
    setModalId(data);

    setEliteManageModal(true);
    const updatedSpareParts = eliteData.map((part: any) => {
      const {
        approved_by,
        assigned_to,
        assigned_by,
        approvedName,
        assignedToName,
        assignedByName,
        first_name,
        last_name,
        email,
        gender,
        mobile_number,
        date_of_birth,
        ticket_id,
        user_id,
        age_id,
        approve_status,
        totalAmount,
        totalAmountPaid,
        totalDiscount,
        ...rest
      } = part;
      return rest;
    });
    const filteredData: any = updatedSpareParts?.filter(
      (items: any) => items.id === data.id
    );
    // setSortedData(filteredData);
  };
  const eliteDataDisplay = [
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Registration Type",
      selector: (row: any) => (row.is_offline === 1 ? "Offline" : "Online"),
      sortable: true,
      width: "180px",
    },
    {
      name: "Race Name",
      selector: (row: any) => row.raceName,
      sortable: true,
      width: "120px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      sortable: false,
      width: "130px",
    },
    {
      name: "Gender",
      selector: (row: any) => row.gender,
      sortable: true,
      width: "100px",
    },
    {
      name: "Elite Runner",
      selector: (row: any) => (row.is_elite === 1 ? "Elite" : ""),

      sortable: true,
      width: "120px",
    },

    {
      name: "More",
      width: "100px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
  ];
  const elitFilterResult = eliteData.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.mobile_number?.includes(searchValue) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Container>
          <Row className="my-2">
            <Col xs={12} md={5}>
              <div>
                <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                  <div className="col">
                    <select
                      required
                      onChange={(e) => {
                        setRaceType(e.target.value);
                        setIsEliteRunner(false);
                        setEliteData([]);
                      }}
                      defaultValue={"-1"}
                    >
                      <option value=""></option>
                      {raceList?.map((option: any) => {
                        return (
                          <>
                            <option
                              key={option.id}
                              value={option.id}
                              disabled={option.status}
                              onChange={option.id}
                            >
                              {option.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <span>Please select race*</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                <div className="col">
                  <input
                    type="text"
                    placeholder=""
                    maxLength={8}
                    value={duration}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleDurationChange(e)
                    }
                  />
                  <span>Qualify Time(hh:mm:ss)*</span>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="my-md-2">
                <DefaultButton
                  type="submit"
                  variant="outline-primary"
                  className=" default-action-button w-75 mt-1"
                  onClick={handleSubmit}
                >
                  Submit
                </DefaultButton>
              </div>
            </Col>
          </Row>
          {isEliteRunner && (
            <div>
              <div className="d-flex justify-content-end">
                <div className="px-md-3 py-md-2 my-md-2 border border-primary rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by email, name, mobile"
                    style={{ outline: "none", width: "320px" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <Icon iconName="Search" className="text-primary" size={18} />
                </div>
              </div>
              <div className="mt-md-2">
                <DataTable
                  pagination
                  paginationRowsPerPageOptions={itemsPerPageOptions}
                  columns={eliteDataDisplay}
                  data={elitFilterResult}
                  customStyles={tableCustomStyles}
                />
              </div>
            </div>
          )}
        </Container>
      </div>

      {eliteManageModal && (
        <EliteRunnerModel
          showNmdcModal={eliteManageModal}
          handleNmdcClose={handleEliteCloseManageModal}
          modalId={modalId}
          setIsEliteRunner={setIsEliteRunner}
        />
      )}
    </>
  );
};

export default EliteRunner;
