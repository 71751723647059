import React, { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import { useAccount } from "context/AccountContext";
import moment from "moment";
import UpdateMoreInfoParticipantDetailsModal from "./updateMoreInfoParticipantDetailsModal";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { OrgRoleEnum } from "types/orgRoleEnum";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import useOrganizer from "utils/useOrganizer";
import { useEvent } from "context/EventContext";
import { useParams } from "react-router-dom";
import { decryptText } from "utils/utils";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

const NmdcRegistrationMoreModal = ({
  showNmdcModal,
  handleNmdcClose,
  modalId,
  setReRender,
}: any) => {
  const { id } = useParams();
  const { storeEventId } = useEvent();
  const [raceName, setRaceName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setdob] = useState("");
  const [eventName, setEventName] = useState("");
  const [idProof, setIdProof] = useState<string>("");
  const urlParts = idProof.split("?")[0].split(".");
  const extension = urlParts[urlParts.length - 1];
  const [certificate, setCertificate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [distance, setDistance] = useState("");
  const [time, setTime] = useState<any>("");
  const [status, setStatus] = useState("");
  const [show, setShow] = useState<boolean>(false);
  const [isFirstLoadshow, setIsFirstLoadShow] = useState<boolean>(false);
  const [reRenderParticipantDetails, setReRenderParticipantDetails] =
    useState<boolean>(false);

  const [selectedIdProofOption, setSelectedIdProofOption] = useState("");
  const [selectedFirstNameOption, setSelectedFirstNameOption] = useState("");
  const [selectedLastNameOption, setSelectedLastNameOption] = useState("");
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [selectedGenderOption, setSelectedGenderOption] = useState("");
  const [selectedCertificateOption, setSelectedCertificateOption] =
    useState("");
  const [selectedEventNameOption, setSelectedEventNameOption] = useState("");
  const [selectedEventDistanceNameOption, setSelectedEventDistanceNameOption] =
    useState("");
  const [selectedCompletedTimingOption, setSelectedCompletedTimingOption] =
    useState("");
  const [remarksdata, setRemarks] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [changeRaceCategory, setChangeRaceCategory] = useState(false);
  const [isSelect, setIsSelect] = useState<any>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [SelectRaceId, setSelectRaceId] = useState("");
  // const { isSuperAdmin } = useOrganizer();
  const { companyAccount } = useAccount();
  const account = companyAccount === OrgRoleEnum.Admin;
  const Approver = companyAccount === OrgRoleEnum.Approver;
  const isSuperAdmin = companyAccount === OrgRoleEnum.Superadmin;
  const isViewer = companyAccount === OrgRoleEnum.Viewer;
  const handleOptionIdProofChange = (event: any) => {
    setSelectedIdProofOption(event.target.value);
  };
  const handleOptionFirstNameChange = (event: any) => {
    setSelectedFirstNameOption(event.target.value);
  };
  const handleOptionLastNameChange = (event: any) => {
    setSelectedLastNameOption(event.target.value);
  };
  const handleOptionDateChange = (event: any) => {
    setSelectedDateOption(event.target.value);
  };
  const handleOptionGenderChange = (event: any) => {
    setSelectedGenderOption(event.target.value);
  };
  const handleOptionCertificateChange = (event: any) => {
    setSelectedCertificateOption(event.target.value);
  };
  const handleOptionEventChange = (event: any) => {
    setSelectedEventNameOption(event.target.value);
  };
  const handleOptionEventDistanceChange = (event: any) => {
    setSelectedEventDistanceNameOption(event.target.value);
  };
  const handleOptionCompletedTimingChange = (event: any) => {
    setSelectedCompletedTimingOption(event.target.value);
  };
  const handleRemark = (event: any) => {
    setRemarks(event.target.value);
  };
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();

  function GetParticipantData() {
    setIsLoading(true);
    axiosPrivate({
      url: "/participentMoreInfo",
      data: {
        id: modalId?.id,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setRaceName(data.raceName);
          setCategoryName(data.categoryName);
          setGender(data.gender);
          setdob(data.dob);
          setEventName(data.qualifyEvent);
          modalId.is_offline === 1
            ? setIdProof(`${data.idProof}`)
            : setIdProof(`${data.idProof + `?timestamp=${Date.now()}`}`);
          // setIdProof(`${data.idProof + `?timestamp=${Date.now()}`}`);
          setCertificate(data.certificateLink);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setDistance(data.eventDistance);
          setTime(data.eventTiming);
          setStatus(data.status);
          setRemarks(data.remarks);
          setSelectedFirstNameOption("");
          setIsFirstLoadShow(true);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; raceId: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; raceId: any; amount: any }[] = [];
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            raceId: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              raceId: items.raceId,
              amount: items.amount,
            });
          }
        });
        setIsSelect(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    GetParticipantData();
    if (changeRaceCategory) {
      CouponRaceList();
    }
  }, [modalId, reRenderParticipantDetails, changeRaceCategory]);

  function padZero(number: number) {
    return number < 10 ? `0${number}` : number;
  }
  const millisecondsToHHMMSS = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    let formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      remainingSeconds
    )}`;
    return formattedTime;
  };

  const handleSubmit = (e: any) => {
    let userStatus;
    setIsLoading(true);
    if (e.target.innerText === "Approve") {
      userStatus = "A";
    } else if (e.target.innerText === "Reject") {
      userStatus = "R";
    } else {
      userStatus = "M";
    }

    const postData = {
      participantId: modalId?.id,
      status: userStatus,
    };

    axiosPrivate({
      url: "/approveRegistrations",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;

        if (status) {
          handleButtonNmdc();
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRejectSubmit = () => {
    setIsLoading(true);
    const postData = {
      date_of_birth: selectedDateOption,
      upload_id_proof: selectedIdProofOption,
      qualifying_timing_certificate_link: selectedCertificateOption,
      remarks: remarksdata,
      registration_id: modalId?.id,
      status_type: "rejected",
    };
    axiosPrivate({
      url: "/verifyParticipantStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          handleButtonNmdc();
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          toast.error(message);
          // Object.keys(message).forEach((key) => {
          //   message[key].forEach((message: any) => {
          //     // console.log(`${key}: ${message}`);
          //     toast.error(` ${message}`);
          //   });
          // });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMorInfoSubmit = () => {
    setIsLoading(true);
    const postData = {
      date_of_birth: selectedDateOption,
      upload_id_proof: selectedIdProofOption,
      qualifying_timing_certificate_link: selectedCertificateOption,
      remarks: remarksdata,
      registration_id: modalId?.id,
      status_type: "moreinfo",
    };
    axiosPrivate({
      url: "/verifyParticipantStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          handleButtonNmdc();
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            setReRender((prevState: boolean) => !prevState);
          }, 2000);
        } else {
          toast.error(message);
          // Object.keys(message).forEach((key) => {
          //   message[key].forEach((message: any) => {
          //     // console.log(`${key}: ${message}`);
          //     toast.error(` ${message}`);
          //   });
          // });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onHide = () => {
    // setIsSelectedImage(false);
    // setSelectedImage(null);
    // setParticipantId(0);
    // setRaceName("");
    setShow(false);
    // setIsFirstLoadShow(false);
  };
  const handleButtonNmdc = () => {
    handleNmdcClose();
    setSelectedIdProofOption("");
    setSelectedFirstNameOption("");
    setSelectedLastNameOption("");
    setSelectedDateOption("");
    setSelectedGenderOption("");
    setSelectedCertificateOption("");
    setSelectedEventNameOption("");
    setSelectedEventDistanceNameOption("");
    setSelectedCompletedTimingOption("");
    setRaceName("");
    setCategoryName("");
    setGender("");
    setdob("");
    setEventName("");
    setIdProof("");
    setCertificate("");
    setFirstName("");
    setLastName("");
    setDistance("");
    setTime("");
    setStatus("");
    setSelectedFirstNameOption("");
    setIsFirstLoadShow(true);
    setRemarks("");
    setShow(false);
    setChangeRaceCategory(false);
    setSelectRaceId("");
  };
  const handleModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleSelectOption = (e: any) => {
    const raceId = e.target.value;
    if (!!raceId) {
      setSelectRaceId(raceId);
      setShowConfirmModal(true);
    }
  };

  let toMarathon: any | undefined = isSelect
    ?.filter((data: any) => {
      const raceId = parseInt(SelectRaceId);
      return data.raceId === raceId;
    })
    .map((filteredData: any) => {
      return filteredData.name;
    });

  function updateRaceCategory() {
    if (toMarathon[0].toLowerCase() === raceName.toLowerCase()) {
      toast.error("You cannot update to the same race category.");
    } else {
      setIsLoading(true);
      axiosPrivate({
        url: "/changeRaceCategory",
        data: {
          raceId: SelectRaceId,
          participantId: modalId?.id,
        },
        method: "POST",
      })
        .then((res) => {
          const { status, message } = res.data;
          if (status) {
            handleModalClose();
            toast.success(`${message}`, { autoClose: 1000 });
            setTimeout(() => {
              setReRender((prevState: boolean) => !prevState);
              handleNmdcClose();
            }, 2000);
          } else {
            Object.keys(message).forEach((key) => {
              message[key].forEach((message: any) => {
                toast.error(` ${message}`);
              });
            });
          }
          setChangeRaceCategory(false);
          setSelectRaceId("");
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setIsLoading(false);
          handleModalClose();
        });
    }
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title={`Are you sure you want to update from ${raceName} to ${toMarathon} ?`}
          // title={`Are you sure you want to update to ${toMarathon} ?`}
          showNote={false}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={updateRaceCategory}
        />
      )}
      {show && (
        <UpdateMoreInfoParticipantDetailsModal
          bookingId={modalId?.registration_id}
          participantId={modalId?.id}
          raceName={modalId?.raceName}
          status={status ?? ""}
          show={show}
          onHide={onHide}
          setReRender={setReRender}
          setReRenderParticipantDetails={setReRenderParticipantDetails}
        />
      )}
      {isLoading && <Loader />}
      <Modal
        show={showNmdcModal}
        onHide={handleNmdcClose}
        centered
        className="modal-xl"
      >
        <Modal.Header className="border-white px-md-4"></Modal.Header>
        <Modal.Body className="mx-5 scrollbar">
          <Modal.Title className="mb-md-4">
            <Row className="d-flex align-items-center">
              <Col md={2}>
                <h3>More Details</h3>
              </Col>
              <Col md={10} className="d-flex justify-content-end">
                {isSuperAdmin && (
                  <>
                    {changeRaceCategory ? (
                      <div className="me-md-3">
                        <div className="form-floating input-bx form-row form-control">
                          <div className="col-12">
                            <select onChange={handleSelectOption}>
                              <option value="">Choose an option</option>
                              {isSelect?.map((option: any) => {
                                return (
                                  <>
                                    <option
                                      key={option.raceId}
                                      value={option.raceId}
                                      disabled={option.status}
                                      onChange={option.raceId}
                                    >
                                      {option.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            <span>Please select the race *</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <DefaultButton
                        type="button"
                        variant="outline-primary"
                        className="default-action-button px-md-5 me-md-2"
                        onClick={() => setChangeRaceCategory(true)}
                      >
                        Change Race Category
                      </DefaultButton>
                    )}
                  </>
                )}
                <DefaultButton
                  type="button"
                  variant="outline-primary"
                  className="default-action-button px-md-5"
                  onClick={handleButtonNmdc}
                >
                  Close
                </DefaultButton>

                {(isSuperAdmin || account || Approver) && (
                  <button
                    className="submit_button_outline border-0 mx-3 py-2"
                    style={{ backgroundColor: "#FF5050", color: "#ffffff" }}
                    onClick={(e: any) => {
                      // setUserBookingId(modalData?.registration_id);
                      // setParticipantId(modalData?.user_id);
                      setShow(true);
                    }}
                  >
                    Edit Details
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Title>

          {/* race name */}
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-center ">
            <div className="col-md-2 h6 my-2 fw-600">Race Name</div>
            <div className="col-md-4 d-flex align-items-center">{raceName}</div>
            <div className="col-md-6 d-flex justify-content-end"></div>
          </div>
          {/* Race category */}
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-center ">
            <div className="col-md-2 h6 my-2 fw-600">Category</div>
            <div className="col-md-4 d-flex align-items-center">
              {categoryName}
            </div>
            <div className="col-md-6 d-flex justify-content-end"></div>
          </div>
          {/* first name */}
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
            <div className="col-md-2 h6 my-2 fw-600">First Name</div>
            <div className="col-md-4 d-flex align-items-center">
              {firstName}
            </div>
          </div>

          {/* last name*/}
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start ">
            <div className="col-md-2 h6 my-2 fw-600">Last Name</div>
            <div className="col-md-4 d-flex  align-items-center">
              {lastName}
            </div>
          </div>

          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
            <div className="col-md-2 h6 my-2 fw-600">Date of Birth</div>
            <div className="col-md-4 d-flex  align-items-center">
              {moment(dob).format("DD-MM-YYYY")}
            </div>
            {(status === "I" || status === "M") && (
              <div className="col-md-6 d-flex justify-content-end">
                <div>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="0"
                      className="me-md-1 radio-input"
                      checked={selectedDateOption === "0"}
                      onChange={handleOptionDateChange}
                    />
                    <span className="custom-radio" />
                    Correct
                  </label>
                </div>
                <div className="mx-md-3">
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="1"
                      className="me-md-1 radio-input"
                      checked={selectedDateOption === "1"}
                      onChange={handleOptionDateChange}
                    />
                    <span className="custom-radio" />
                    Incorrect
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
            <div className="col-md-2 h6 my-2 fw-600">Gender</div>
            <div className="col-md-4 d-flex align-items-center">{gender}</div>
          </div>
          <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
            <div className="col-md-2 h6 my-2 fw-600">Id Proof</div>

            {idProof === "" || idProof === "0 " ? (
              <div className="col-md-6 d-flex align-items-center">
                <h4>Id proof not uploaded</h4>
              </div>
            ) : (
              <>
                {extension === "pdf" ? (
                  <div className="col-md-6 d-flex align-items-center">
                    <embed
                      src={idProof}
                      type="application/pdf"
                      width="100%"
                      height="350"
                    />
                  </div>
                ) : (
                  <div
                    className="col-md-6 d-flex align-items-center"
                    onClick={() => window.open(idProof, "_blank", "noreferrer")}
                  >
                    <img
                      src={idProof}
                      alt="Id_Proof"
                      style={{ width: "600px", height: "350px" }}
                    />
                  </div>
                )}
              </>
            )}
            {(status === "I" || status === "M") && (
              <div className="col-md-4 d-flex justify-content-end">
                <div>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="0"
                      className="me-md-1 radio-input"
                      checked={selectedIdProofOption === "0"}
                      onChange={handleOptionIdProofChange}
                    />
                    <span className="custom-radio" />
                    Correct
                  </label>
                </div>
                <div className="mx-md-3">
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="1"
                      className="me-md-1 radio-input"
                      checked={selectedIdProofOption === "1"}
                      onChange={handleOptionIdProofChange}
                    />
                    <span className="custom-radio" />
                    Incorrect
                  </label>
                </div>
              </div>
            )}
          </div>
          {raceName !== "5K" && (
            <>
              <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
                <div className="col-md-2 h6 my-2 fw-600">Certificate Link</div>
                <div
                  className="col-md-6 d-flex  align-items-center"
                  onClick={() =>
                    window.open(certificate, "_blank", "noreferrer")
                  }
                >
                  <iframe
                    src={certificate}
                    title="Embedded Content"
                    width="600" // Set the width here
                    height="350"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                {(status === "I" || status === "M") && (
                  <div className="col-md-4 d-flex justify-content-end">
                    <div>
                      <label className="radio-label">
                        <input
                          type="radio"
                          value="0"
                          className="me-md-1 radio-input"
                          checked={selectedCertificateOption === "0"}
                          onChange={handleOptionCertificateChange}
                        />
                        <span className="custom-radio" />
                        Correct
                      </label>
                    </div>
                    <div className="mx-md-3">
                      <label className="radio-label">
                        <input
                          type="radio"
                          value="1"
                          className="me-md-1 radio-input"
                          checked={selectedCertificateOption === "1"}
                          onChange={handleOptionCertificateChange}
                        />
                        <span className="custom-radio" />
                        Incorrect
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
                <div className="col-md-2 h6 my-2 fw-600">
                  Completed Event Name
                </div>
                <div className="col-md-4 d-flex  align-items-center">
                  {eventName}
                </div>
              </div>
              <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
                <div className="col-md-2 h6 my-2 fw-600">Event Distance</div>
                <div className="col-md-4 d-flex align-items-center">
                  {distance}
                </div>
              </div>
              <div className="row manage_form_table  mb-md-2 px-md-3 py-2 rounded d-flex align-items-center justify-content-start">
                <div className="col-md-2 h6 my-2 fw-600">Complete Timing</div>
                <div className="col-md-4 d-flex  align-items-center">
                  {millisecondsToHHMMSS(time)}
                  {/* {time} */}
                </div>
              </div>
            </>
          )}
          {(status === "I" || status === "M") && (
            <div className="row  mb-md-2  py-2 rounded d-flex align-items-center justify-content-center ">
              <label>Reg.Remarks</label>
              <textarea
                rows={4}
                onChange={handleRemark}
                value={remarksdata}
              ></textarea>
            </div>
          )}
          {/* {(!!selectedIdProofOption ||
            !!selectedFirstNameOption ||
            !!selectedLastNameOption ||
            !!selectedDateOption ||
            !!selectedGenderOption ||
            !!selectedCertificateOption ||
            !!selectedEventNameOption ||
            !!selectedEventDistanceNameOption ||
            !!selectedCompletedTimingOption ||
            !!remarksdata) && (
            <> */}
          {(status === "I" || status === "M") && !isViewer && (
            <Row>
              <Col className="justify-content-center d-flex my-5">
                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{ backgroundColor: "#A1C95B", color: "#ffffff" }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Approve
                </button>
                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{ backgroundColor: "#FF5050", color: "#ffffff" }}
                  onClick={handleRejectSubmit}
                >
                  Reject
                </button>

                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{
                    backgroundColor: "#EBC642",
                    color: "#ffffff",
                  }}
                  onClick={handleMorInfoSubmit}
                >
                  More Info
                </button>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(NmdcRegistrationMoreModal);
