/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import RadioInput from "components/Inputs/RadioInput";
import { orgGetUserParticipantDetails } from "api/GetOrgUserParticipantDetails";
import { UpdateOrgMoreUserUpdateDetails } from "api/UpdateOrgMoreUserUpdateDetails";
import { orgGetExpoParticipantDetails } from "api/GetExpoParticipantDetails";
import { UpdateExpoParticipantDetails } from "api/UpdateExpoParticipantDetails";
import { toast } from "react-toastify";
import {
  CustomFieldOption,
  CustomFieldSection,
  DynamicParticipant,
} from "types/CustomTypesUpdateParticipant";
import {
  readFileAsBase64,
  replaceUnderScoreWithSpace,
  nameConversion,
  fileAllowedTypes,
  fileMaxSize,
  alphabetsSpaceRegex,
} from "utils/utils";
import Loader from "components/Loader/Loader";
import { useAccount } from "context/AccountContext";
import { OrgRoleEnum } from "types/orgRoleEnum";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { decryptText } from "utils/utils";
import { states } from "utils/address/states";
import { countries } from "utils/address/countries";
import { cities } from "utils/address/cities";

interface updateMoreInfoParticipantDetailsModalProps {
  show: boolean;
  onHide: () => void;
  // setReRenderParticipantDetails: React.Dispatch<React.SetStateAction<boolean>>;
  // setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  raceName: string;
  participantId: string;
  status: string;
}

const buildValidationSchema = (
  sections: CustomFieldOption[] | undefined
): Record<string, any> => {
  if (!sections || !Array.isArray(sections)) {
    return {};
  }

  const schema: Record<string, any> = {};

  sections.forEach((section) => {
    if (typeof section.field_name === "string") {
      const fieldName = section.field_name;
      const isRequired = section.field_status === 0;

      switch (section.input_type) {
        case "textbox":
          schema[fieldName] = isRequired
            ? Yup.string().required(`${section.label_name} is required`)
            : Yup.string().nullable();
          break;
        case "email":
          schema[fieldName] = isRequired
            ? Yup.string()
                .email("Invalid email format")
                .required(`${section.label_name} is required`)
            : Yup.string().email("Invalid email format");
          break;
        case "number":
          schema[fieldName] = isRequired
            ? Yup.number().required(`${section.label_name} is required`)
            : Yup.number();
          break;
        case "dropdown":
          schema[fieldName] = isRequired
            ? Yup.string().required(`${section.label_name} is required`)
            : Yup.string().nullable();
          break;
        case "date":
          schema[fieldName] = isRequired
            ? Yup.date().required(`${section.label_name} is required`)
            : Yup.date();
          break;
        case "radio":
          schema[fieldName] = isRequired
            ? Yup.string()
                .oneOf(
                  section.options || [],
                  `Please select a valid ${section.label_name}`
                )
                .required(`${section.label_name} is required`)
            : Yup.string().oneOf(section.options || []);
          break;
        case "file":
          schema[fieldName] = isRequired
            ? Yup.string().required(`${section.label_name} is required`)
            : Yup.string();
          break;
        default:
          break;
      }
    }
  });

  return schema;
};

const ExpoParticipantEditDetailsModal: React.FC<
  updateMoreInfoParticipantDetailsModalProps
> = ({
  show,
  onHide,
  raceName,
  participantId,
  status,
  // setReRenderParticipantDetails,
  // setReRender,
}) => {
  const [dynamicFields, setDynamicFields] = useState({});
  const [filteredStates, setFilteredStates] = useState<any>([]);
  const [filteredCities, setFilteredCities] = useState<any>([]);
  const axioPrivate = useAxiosPrivate();
  const { id } = useParams();
  const {
    data: customFields,
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = orgGetExpoParticipantDetails(decryptText(String(id)), participantId);

  const { companyAccount } = useAccount();
  const [UserData, setUserData] = useState<any>(null);
  const isApprover = companyAccount === OrgRoleEnum.Approver;

  // useEffect(() => {
  //   setUserData(customFields && customFields?.data);
  // }, [customFields, UserData]);

  const updateParticipationDetailsMutation = UpdateExpoParticipantDetails();
  const [isFileError, setIsFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [isEnableReadOnlyAttributes, setIsEnableReadOnlyAttributes] =
    useState<boolean>(false);
  function filterStates(selectedCountry: any) {
    const newStates = states.filter(
      (state) => state.country_name === selectedCountry
    );
    setFilteredStates(newStates);
  }

  function filterCities(selectedCity: any) {
    const newCities = cities.filter((city) => city.state_name === selectedCity);
    setFilteredCities(newCities);
  }

  useEffect(() => {
    if (Array.isArray(customFields?.data)) {
      const fields: Record<string, string> = {};
      customFields?.data.forEach((section: CustomFieldOption) => {
        fields[section.field_name] = section.default_value;
      });
      setDynamicFields(fields);
      if (!!fields.country) {
        filterStates(fields.country);
      }
      if (!!fields.state) {
        filterCities(fields.state);
      }
    }
  }, [customFields?.data]);

  useEffect(() => {
    setUserData(customFields && customFields?.data);
  }, [isApprover, customFields]);

  const handleSubmit = async (
    values: DynamicParticipant<CustomFieldOption>
  ) => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      const updatedParticipantDetails = {
        event_id: decryptText(String(id)),
        participant_id: participantId,
        ...values,
        first_name: nameConversion(values.first_name),
        last_name: nameConversion(values.last_name),
      };
      console.log(updatedParticipantDetails);
      const response = await updateParticipationDetailsMutation.mutateAsync({
        updatedParticipantDetails,
      });

      if (response?.status) {
        toast.success(response?.message);
        formik.resetForm();
        onHide();
        // setReRenderParticipantDetails((prevState: boolean) => !prevState);
        // setReRender((prevState: boolean) => !prevState);
      } else {
        toast.error(response?.message);
      }
    }
  };

  const formik = useFormik<DynamicParticipant<CustomFieldOption>>({
    enableReinitialize: true,
    initialValues: dynamicFields,
    validationSchema: Yup.object(buildValidationSchema(customFields)),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // getExpoParticipant();
    formik.setValues(formik.initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    formik.resetForm();
    onHide();
  };

  if (customFieldsError) {
    toast.error("Error loading event details. Please try again later.");
  }

  if (customFieldsLoading) {
    return <Loader />;
  }
  if (!Array.isArray(customFields?.data) || customFields?.data.length === 0) {
    return <div>Error: customFields is not an array or is empty</div>;
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Form
        onSubmit={formik.handleSubmit}
        className=""
        encType="multipart/form-data"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="formTitleInEventRegisteration mb-0">{raceName}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="row">
            {UserData &&
              UserData?.map((field: CustomFieldOption) => (
                <div key={field.id} className="col-sm-6 mb-0 mb-sm-4">
                  {field.input_type === "textbox" && (
                    <div className="mb-3 mb-sm-0 position-relative">
                      <DefaultInput
                        label={
                          field.field_status === 0
                            ? field.label_name + " *"
                            : field.label_name
                        }
                        type="text"
                        disabled={field.disable}
                        placeholder={field.label_name}
                        value={formik.values[field.field_name]}
                        onChange={formik.handleChange(field.field_name)}
                        isError={
                          formik.touched[field.field_name as string] &&
                          !!formik.errors[field.field_name as string]
                        }
                        error={formik.errors[field.field_name as string]}
                      />
                      {field?.help_content !== null && (
                        <span
                          style={{
                            top: 16.8,
                            right: formik.errors[field.field_name] ? 30 : 12,
                          }}
                          className="position-absolute"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{field?.help_content}</Tooltip>}
                          >
                            <img
                              className="cursor-pointer"
                              height={14.5}
                              width={15}
                              alt="info tooltip"
                              src="/images/info.svg"
                            />
                          </OverlayTrigger>
                        </span>
                      )}
                    </div>
                  )}
                  {field.input_type === "number" && (
                    <div className="mb-3 mb-sm-0 position-relative">
                      <DefaultInput
                        label={
                          field.field_status === 0
                            ? field.label_name + " *"
                            : field.field_name
                        }
                        type="number"
                        placeholder={field.label_name}
                        value={formik.values[field.field_name]}
                        onChange={formik.handleChange(field.field_name)}
                        isError={
                          formik.touched[field.field_name] &&
                          !!formik.errors[field.field_name]
                        }
                        error={formik.errors[field.field_name]}
                      />
                      {field?.help_content !== null && (
                        <span
                          style={{
                            top: 16.8,
                            right: formik.errors[field.field_name] ? 30 : 12,
                          }}
                          className="position-absolute"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{field?.help_content}</Tooltip>}
                          >
                            <img
                              className="cursor-pointer"
                              height={14.5}
                              width={15}
                              alt="info tooltip"
                              src="/images/info.svg"
                            />
                          </OverlayTrigger>
                        </span>
                      )}
                    </div>
                  )}
                  {field.input_type === "email" && (
                    <div className="mb-3 mb-sm-0 position-relative">
                      <DefaultInput
                        label={
                          field.field_status === 0
                            ? field.label_name + " *"
                            : field.field_name
                        }
                        type="email"
                        placeholder={field.label_name}
                        value={formik.values[field.field_name]}
                        onChange={formik.handleChange(field.field_name)}
                        isError={
                          formik.touched[field.field_name] &&
                          !!formik.errors[field.field_name]
                        }
                        error={formik.errors[field.field_name]}
                      />
                      {field?.help_content !== null && (
                        <span
                          style={{
                            top: 16.8,
                            right: formik.errors[field.field_name] ? 32 : 12,
                          }}
                          className="position-absolute"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{field?.help_content}</Tooltip>}
                          >
                            <img
                              className="cursor-pointer"
                              height={14.5}
                              width={15}
                              alt="info tooltip"
                              src="/images/info.svg"
                            />
                          </OverlayTrigger>
                        </span>
                      )}
                    </div>
                  )}
                  {field.input_type === "date" && (
                    <div className="mb-3 mb-sm-0 position-relative">
                      <DefaultInput
                        label={
                          field.field_status === 0
                            ? field.label_name + " *"
                            : field.field_name
                        }
                        type="date"
                        placeholder={field.label_name}
                        value={formik.values[field.field_name]}
                        onChange={formik.handleChange(field.field_name)}
                        isError={
                          formik.touched[field.field_name] &&
                          !!formik.errors[field.field_name]
                        }
                        error={formik.errors[field.field_name]}
                      />
                      {field?.help_content !== null && (
                        <span
                          style={{
                            top: 16.8,
                            right: formik.errors[field.field_name] ? 62 : 12,
                          }}
                          className="position-absolute"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{field?.help_content}</Tooltip>}
                          >
                            <img
                              className="cursor-pointer"
                              height={14.5}
                              width={15}
                              alt="info tooltip"
                              src="/images/info.svg"
                            />
                          </OverlayTrigger>
                        </span>
                      )}
                    </div>
                  )}

                  {field.input_type === "dropdown" &&
                    field.field_name === "country" && (
                      <div className="mb-4 mb-sm-0 position-relative">
                        <DefaultDropdown
                          className={field.field_name}
                          label={
                            field.field_status === 0
                              ? field.label_name + " *"
                              : field.label_name
                          }
                          options={countries?.map(
                            (country: any) => country.name
                          )}
                          name={field.field_name}
                          placeholder={field.label_name}
                          disablePlaceholder={true}
                          value={formik.values[field.field_name]}
                          onChange={(event) => {
                            if (
                              event.target.value !==
                              formik.values[field.field_name]
                            ) {
                              formik.setFieldValue("state", "");
                              formik.setFieldValue("city", "");
                            }
                            formik.setFieldValue(
                              field.field_name,
                              event.target.value
                            );
                            filterStates(event.target.value);
                          }}
                          isError={
                            formik.touched[field.field_name] &&
                            !!formik.errors[field.field_name]
                          }
                          error={formik.errors[field.field_name]}
                        />
                        {field?.help_content !== null && (
                          <span
                            style={{
                              top: 16.8,
                              right: formik.errors[field.field_name] ? 34 : 34,
                            }}
                            className="position-absolute"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{field?.help_content}</Tooltip>}
                            >
                              <img
                                className="cursor-pointer"
                                height={14.5}
                                width={15}
                                alt="info tooltip"
                                src="/images/info.svg"
                              />
                            </OverlayTrigger>
                          </span>
                        )}
                      </div>
                    )}

                  {field.input_type === "dropdown" &&
                    field.field_name === "state" && (
                      <div className="mb-4 mb-sm-0 position-relative">
                        {filteredStates.length > 0 ? (
                          <DefaultDropdown
                            className={field.field_name}
                            label={
                              field.field_status === 0
                                ? field.label_name + " *"
                                : field.label_name
                            }
                            options={filteredStates?.map(
                              (state: any) => state.name
                            )}
                            name={field.field_name}
                            placeholder={field.label_name}
                            disablePlaceholder={true}
                            value={formik.values[field.field_name]}
                            onChange={(event) => {
                              if (
                                event.target.value !==
                                formik.values[field.field_name]
                              ) {
                                formik.setFieldValue("city", "");
                              }
                              formik.setFieldValue(
                                field.field_name,
                                event.target.value
                              );
                              filterCities(event.target.value);
                            }}
                            isError={
                              formik.touched[field.field_name] &&
                              !!formik.errors[field.field_name]
                            }
                            error={formik.errors[field.field_name]}
                          />
                        ) : (
                          <DefaultInput
                            label={
                              field.field_status === 0
                                ? field.label_name + " *"
                                : field.label_name
                            }
                            type="text"
                            name={field.field_name}
                            maxLength={50}
                            placeholder={field.label_name}
                            value={formik.values[field.field_name]}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (alphabetsSpaceRegex.test(inputValue)) {
                                formik.handleChange(field.field_name)(e);
                              } else if (inputValue.length === 0) {
                                formik.handleChange(field.field_name)(e);
                              }
                            }}
                            isError={
                              formik.touched[field.field_name as string] &&
                              !!formik.errors[field.field_name as string]
                            }
                            error={formik.errors[field.field_name as string]}
                          />
                        )}
                        {field?.help_content !== null && (
                          <span
                            style={{
                              top: 16.8,
                              right: formik.errors[field.field_name] ? 34 : 34,
                            }}
                            className="position-absolute"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{field?.help_content}</Tooltip>}
                            >
                              <img
                                className="cursor-pointer"
                                height={14.5}
                                width={15}
                                alt="info tooltip"
                                src="/images/info.svg"
                              />
                            </OverlayTrigger>
                          </span>
                        )}
                      </div>
                    )}

                  {field.input_type === "dropdown" &&
                    field.field_name === "city" && (
                      <div className="mb-4 mb-sm-0 position-relative">
                        {formik.values["country"] === "India" ? (
                          <DefaultDropdown
                            className={field.field_name}
                            label={
                              field.field_status === 0
                                ? field.label_name + " *"
                                : field.label_name
                            }
                            options={filteredCities?.map(
                              (state: any) => state.name
                            )}
                            placeholder={field.label_name}
                            disablePlaceholder={true}
                            value={formik.values[field.field_name]}
                            name={field.field_name}
                            onChange={(event) => {
                              formik.setFieldValue(
                                field.field_name,
                                event.target.value
                              );
                            }}
                            isError={
                              formik.touched[field.field_name] &&
                              !!formik.errors[field.field_name]
                            }
                            error={formik.errors[field.field_name]}
                          />
                        ) : (
                          <DefaultInput
                            label={
                              field.field_status === 0
                                ? field.label_name + " *"
                                : field.label_name
                            }
                            type="text"
                            maxLength={50}
                            placeholder={field.label_name}
                            name={field.field_name}
                            value={formik.values[field.field_name]}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (alphabetsSpaceRegex.test(inputValue)) {
                                formik.handleChange(field.field_name)(e);
                              } else if (inputValue.length === 0) {
                                formik.handleChange(field.field_name)(e);
                              }
                            }}
                            isError={
                              formik.touched[field.field_name as string] &&
                              !!formik.errors[field.field_name as string]
                            }
                            error={formik.errors[field.field_name as string]}
                          />
                        )}

                        {field?.help_content !== null && (
                          <span
                            style={{
                              top: 16.8,
                              right: formik.errors[field.field_name] ? 34 : 34,
                            }}
                            className="position-absolute"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{field?.help_content}</Tooltip>}
                            >
                              <img
                                className="cursor-pointer"
                                height={14.5}
                                width={15}
                                alt="info tooltip"
                                src="/images/info.svg"
                              />
                            </OverlayTrigger>
                          </span>
                        )}
                      </div>
                    )}

                  {field.input_type === "dropdown" &&
                    ![
                      "country",
                      "state",
                      "city",
                      "qualifying_distance",
                      "running_group_name",
                    ].includes(field.field_name) && (
                      <div className="mb-4 mb-sm-0 position-relative">
                        <DefaultDropdown
                          className={field.field_name}
                          label={
                            field.field_status === 0
                              ? field.label_name + " *"
                              : field.label_name
                          }
                          name={field.field_name}
                          options={field?.options}
                          placeholder={field.label_name}
                          disablePlaceholder={
                            field.field_status === 0 ? true : false
                          }
                          value={formik.values[field.field_name]}
                          onChange={formik.handleChange(field.field_name)}
                          isError={
                            formik.touched[field.field_name] &&
                            !!formik.errors[field.field_name]
                          }
                          error={formik.errors[field.field_name]}
                        />
                        {field?.help_content !== null && (
                          <span
                            style={{
                              top: 16.8,
                              right: formik.errors[field.field_name] ? 34 : 34,
                            }}
                            className="position-absolute"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{field?.help_content}</Tooltip>}
                            >
                              <img
                                className="cursor-pointer"
                                height={14.5}
                                width={15}
                                alt="info tooltip"
                                src="/images/info.svg"
                              />
                            </OverlayTrigger>
                          </span>
                        )}
                      </div>
                    )}
                  {field.input_type === "radio" && (
                    <div className="mb-3 mb-sm-0">
                      <RadioInput
                        label={
                          field.field_status === 0
                            ? field.label_name + " *"
                            : field.label_name
                        }
                        labelClassName="position-relative fs-14"
                        options={field.options || []}
                        selectedOption={formik.values[field.field_name]}
                        onChange={(e) =>
                          formik.setFieldValue(field.field_name, e.target.value)
                        }
                        isError={
                          formik.touched[field.field_name] &&
                          !!formik.errors[field.field_name]
                        }
                        helperContent={field?.help_content}
                        error={formik.errors[field.field_name]}
                      />
                    </div>
                  )}

                  {field.input_type === "file" && (
                    <>
                      {!isApprover && (
                        <>
                          <div className={`mb-4 mb-sm-0`}>
                            <div className="position-relative">
                              <DefaultInput
                                className={`${
                                  !!formik.values.upload_id_proof && "me-2"
                                }`}
                                label={
                                  field.field_status === 0
                                    ? field.label_name + " *"
                                    : field.label_name
                                }
                                name={field.field_name}
                                type="file"
                                // disabled={status === "A" ? true : false}
                                placeholder={field.label_name}
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                onChange={async (event: any) => {
                                  const file = event.target.files[0];
                                  console.log("event", file);
                                  if (!fileAllowedTypes?.includes(file?.type)) {
                                    setIsFileError(true);
                                    setFileErrorMessage(
                                      "Please select an image (PNG, JPEG, JPG) or a PDF."
                                    );
                                    return;
                                  } else if (file?.size > fileMaxSize) {
                                    setIsFileError(true);
                                    setFileErrorMessage(
                                      "File size exceeds the allowed limit of 3 MB."
                                    );
                                    return;
                                  } else {
                                    setIsFileError(false);
                                    setFileErrorMessage("");
                                    const base64 = await readFileAsBase64(file);
                                    console.log("base", base64);
                                    formik.setFieldValue(
                                      field.field_name,
                                      base64
                                    );
                                  }
                                }}
                                isError={
                                  (formik.touched[field.field_name] &&
                                    !!formik.errors[field.field_name]) ||
                                  isFileError
                                }
                                error={
                                  isFileError
                                    ? fileErrorMessage
                                    : formik.errors[field.field_name]
                                }
                              />
                              {field?.help_content !== null && (
                                <span
                                  style={{
                                    top: 16.8,
                                    right: formik.errors[field.field_name]
                                      ? 34
                                      : !!formik.values.upload_id_proof
                                      ? 20
                                      : 12,
                                  }}
                                  className="position-absolute"
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>{field?.help_content}</Tooltip>
                                    }
                                  >
                                    <img
                                      className="cursor-pointer"
                                      height={14.5}
                                      width={15}
                                      alt="info tooltip"
                                      src="/images/info.svg"
                                    />
                                  </OverlayTrigger>
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="mt-2 mb-1 text-center">
                            {!!formik.values.upload_id_proof && (
                              <div>
                                {formik.values.upload_id_proof.endsWith(
                                  "pdf"
                                ) ? (
                                  <iframe
                                    src={formik.values.upload_id_proof}
                                    title="PDF Preview"
                                    style={{
                                      width: "180px",
                                      height: "140px",
                                      border: "1px solid #000000",
                                    }}
                                  />
                                ) : formik.values.upload_id_proof.startsWith(
                                    "data:image"
                                  ) ||
                                  formik.values.upload_id_proof.endsWith(
                                    ".png"
                                  ) ||
                                  formik.values.upload_id_proof.endsWith(
                                    ".jpeg"
                                  ) ||
                                  formik.values.upload_id_proof.endsWith(
                                    ".jpg"
                                  ) ? (
                                  <img
                                    className="border border-1 border-dark"
                                    src={formik.values.upload_id_proof}
                                    alt="Preview"
                                    style={{
                                      border: "1px solid #000000",
                                      borderRadius: "1rem",
                                      width: "180px",
                                      height: "140px",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : (
                                  <iframe
                                    src={formik.values.upload_id_proof}
                                    title="PDF Preview"
                                    style={{
                                      width: "180px",
                                      height: "140px",
                                      border: "1px solid #000000",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {/* {field.input_type === "url" && (
                  <div className="mb-4 mb-sm-0 position-relative">
                    <DefaultInput
                      label={
                        field.field_status === 0 && raceName !== "10K"
                          ? field.label_name + " *"
                          : field.label_name
                      }
                      type="url"
                      placeholder={field.label_name}
                      value={formik.values[field.field_name]}
                      onChange={formik.handleChange(field.field_name)}
                      name={field.field_name}
                      isError={
                        formik.touched[field.field_name] &&
                        !!formik.errors[field.field_name]
                      }
                      error={formik.errors[field.field_name]}
                      readOnly={isEnableReadOnlyAttributes}
                    />
                    {field?.help_content !== null && (
                      <span
                        style={{
                          top: 16.8,
                          right: formik.errors[field.field_name] ? 30 : 12,
                        }}
                        className="position-absolute"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{field?.help_content}</Tooltip>}
                        >
                          <img
                            className="cursor-pointer"
                            height={14.5}
                            width={15}
                            alt="info tooltip"
                            src="/images/info.svg"
                          />
                        </OverlayTrigger>
                      </span>
                    )}
                  </div>
                )} */}
                </div>
              ))}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            type="button"
            className="default-action-button  fw-bold text-white ms-4"
            variant="primary"
            onClick={handleCancel}
          >
            Close
          </DefaultButton>
          <DefaultButton
            type="submit"
            className="default-action-button fw-bold text-white ms-4"
            variant="primary"
          >
            Update Details
          </DefaultButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(ExpoParticipantEditDetailsModal);
