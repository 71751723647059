import React, { useEffect, useState } from "react";
import { Icon } from "components/Icon";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useRefund } from "context/SupportContext";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import Loader from "components/Loader/Loader";

const SupportDashboard = () => {
  const navigate = useNavigate();
  const { setName, setEventId } = useRefund();
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [eventList, setEventList] = useState([]);
  const [searchValue, setSearchValue] = useState<any>("");

  function getAllEventList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/supportEventLists",
      method: "GET",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setEventList(data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getAllEventList();
  }, []);
  const handleRefundClick = (data: any) => {
    setName(data.event_name);
    setEventId(data.id);
    navigate("/refunds");
  };
  const eventData = [
    {
      name: "Event name",
      selector: (row: any) => (row.event_name ? row.event_name : "—"),
      sortable: true,
      width: "250px",
    },
    {
      name: "Event Short Code",
      selector: (row: any) => (row.event_slug ? row.event_slug : "—"),
      sortable: true,
    },
    {
      name: "Event Start Date",
      selector: (row: any) =>
        row.event_start_date ? row.event_start_date : "—",
      sortable: false,
    },
    {
      name: "Event End Date",
      selector: (row: any) => (row.event_end_date ? row.event_end_date : "—"),
      sortable: false,
    },

    {
      name: "View",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleRefundClick(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Refunds
        </DefaultButton>
      ),
    },
  ];
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",

        backgroundColor: "#ffe9e9",
        width: "190px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const filterEventsResult =
    eventList &&
    eventList?.filter(
      (e: any) =>
        e.event_name &&
        e.event_name.toLowerCase().includes(searchValue?.toLowerCase() || "")
    );
  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <Row className="mt-4">
          <Col>
            <h6 className="fs-24  mt-md-2 ">Events</h6>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-end">
            <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by Event Name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
          <div className="my-md-3">
            <DataTable
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={25}
              columns={eventData}
              data={filterEventsResult}
              customStyles={tableCustomStyles}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SupportDashboard;
