import React from "react";
import { ReferAFriendSocialMedia } from "api/ReferAFriendSocialMedia";
import { SocialMedia } from "types/SocialMedia";
import DefaultButton from "components/DefaultButton/DefaultButton";

import styles from "./ReferAFriend.module.scss";

const ReferAFriend = () => {
  return (
    <div className="my-5 d-none d-sm-block">
      <div
        className={`${styles.refer} d-flex justify-content-center position-relative`}
      >
        <img
          style={{
            opacity: "0.8",
            background:
              "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
            mixBlendMode: "exclusion",
          }}
          className="w-100 d-block"
          src="/images/hexagonrefer.svg"
          alt="refer"
        />
        <div className="position-absolute top-0 py-4 px-1">
          <div className="text-center">
            <p className="text-light ff-saira fs-40 my-2 fw-bold">
              Refer a Friend Give ₹100, Get ₹100.
            </p>
            <p className="text-light ff-ns mb-2">
              Spread the Summersalt Love! You’ll get ₹100 for each friend who
              orders. Only new customers are valid for the referral.
            </p>
            <div className="d-flex justify-content-center">
              <div className={`${styles.emailContainer} input-group my-3`}>
                <input
                  type="text"
                  className="form-control border border-1 border-light ff-ns"
                  placeholder="Your's Friends Email"
                  aria-label="Email"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <DefaultButton
                    className={`${styles.sendBtn} text-white`}
                    onClick={() => alert("Btn Clicked")}
                    type="button"
                    variant="primary"
                  >
                    SEND
                  </DefaultButton>
                </div>
              </div>
            </div>
            <div style={{ width: "60%" }} className="mx-auto my-3">
              <div className="d-flex justify-content-around">
                {ReferAFriendSocialMedia.map(
                  (media: SocialMedia, index: number) => {
                    return (
                      <div
                        key={index}
                        className="d-flex flex-column align-items-center justify-content-center"
                      >
                        <div
                          className={`${styles.socialBg} d-flex flex-column justify-content-center align-items-center mb-1`}
                        >
                          <img src={media.icon} alt="Media icon" />
                        </div>
                        <p className="mb-0 ff-ns text-white fs-14">
                          {media.name}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferAFriend;
