import React, { useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { useEvent } from "context/EventContext";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import { Form as Formdata, Formik } from "formik";
import { TextField } from "utils/FormControls";
import { toast } from "react-toastify";
import MultiSelect from "components/Inputs/MultiSelect";
import Loader from "components/Loader/Loader";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const ManageFilterModal = ({
  showModal,
  handleClose,
  modalData,
  setIsRefresh,
}: any) => {
  const [isRaceList, setIsRaceList] = useState<any>([]);
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const { token } = useAuth();
  const { storeEventId } = useEvent();
  const [optionSelected, setSelected] = useState<any>(null);
  const axiosPrivate = useAxiosPrivate();
  let eventId: any;
  useEffect(() => {
    if (isEditEvent && id) {
      eventId = decryptText(String(id));
      setId(eventId);
    }
  }, [eventId, id]);

  const [isLoading, setIsLoading] = useState(false);

  function eventRacesList() {
    // axios
    //   .post(
    //     "/eventRacesList",
    //     {
    //       eventId: Id || storeEventId,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         Accept: "application/json",
    //       },
    //     }
    //   )
    axiosPrivate({
      url: "/eventRacesList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          const updatedSpareParts = res.data.data.map((part: any) => {
            const {
              eventId,
              startDate,
              endDate,
              age,
              distance,
              amount,
              ...rest
            } = part;
            return rest;
          });

          const transformedData = updatedSpareParts.map((item: any) => ({
            value: item.id,
            label: item.raceName,
          }));

          // const optionsWithNone: any = [
          //   { value: null, label: "None" },
          //   ...transformedData,
          // ];
          setIsRaceList(transformedData);
        }
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      eventRacesList();
    }
  }, [Id, storeEventId]);
  useEffect(() => {
    if (modalData?.ticket_id !== null) {
      if (modalData && modalData?.ticket_id !== "undefined") {
        const hiddenData = modalData?.ticket_id;
        const mappedOptions = JSON.parse(hiddenData).map((size: string) => {
          const index = isRaceList.findIndex(
            (option: any) => option.value === size
          );
          return { ...isRaceList[index] };
        });
        setSelected(mappedOptions);
      }
    } else {
      setSelected(null);
    }
  }, [modalData?.ticket_id]);

  const IntialValues = {
    isStatus: modalData?.field_status.toString(),
    raceIds: [""],
    helpContent: modalData?.help_content,
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const field: any =
      optionSelected && optionSelected?.map((item: any) => item.value);
    const postData = {
      id: modalData.id,
      raceIds: field,
      fieldStatus: values.isStatus,
      helpContent: values.helpContent,
    };
    // console.log("postData", postData);

    // axios.post("/hideRaceField", postData, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // });
    axiosPrivate({
      url: "/hideRaceField",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${res.data.message}`, { autoClose: 1000 });
          handleClose();
          setTimeout(() => {
            setSelected(null);
            setIsRefresh(true);
          }, 1500);
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setSelected(null);
      });
  };

  // const handleOptionChange = (e: any) => {
  //   const value = e.target.value;
  //   console.log("value", value);
  //   // const updatedData = { ...data, field_status: parseInt(value) };
  // };

  const handleMultiChange = (selected: any) => {
    setSelected(selected);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>Edit {modalData?.label_name}</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={IntialValues}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange }) => (
            <Formdata
              noValidate
              onSubmit={handleSubmit}
              className="pb-md-3 px-md-4"
            >
              <Modal.Body>
                <Form.Group>
                  <Form.Label className="me-2">Field Status</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      name="isStatus"
                      id="isStatus"
                      label="Required"
                      defaultChecked={modalData?.field_status === 0}
                      value="0"
                      onChange={handleChange}
                    />

                    <Form.Check
                      type="radio"
                      className="ms-md-3"
                      name="isStatus"
                      id="isStatus"
                      label="Optional"
                      defaultChecked={modalData?.field_status === 1}
                      value="1"
                      onChange={handleChange}
                    />
                    <Form.Check
                      type="radio"
                      className="ms-md-3"
                      name="isStatus"
                      defaultChecked={modalData?.field_status === 2}
                      id="isStatus"
                      label="Don't Show"
                      value="2"
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
                {/* <div className="py-md-3">
                <Form.Group as={Col}>
                  <Form.Label>Races</Form.Label>
                  <Form.Control
                    as="select"
                    multiple
                    name="raceIds"
                    value={field}
                    onChange={(e: any) => {
                      setField(
                        [].slice
                          .call(e.target.selectedOptions)
                          .map((item: any) => item.value)
                      );
                    }}
                  >
                    {isRaceList?.map((option: any) => (
                      <option key={option.id} value={option.id}>
                        {option.raceName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div> */}
                <div className="py-md-3">
                  <Form.Label>
                    {" "}
                    Hide this field for the following races
                  </Form.Label>
                  <MultiSelect
                    options={isRaceList}
                    onChange={handleMultiChange}
                    value={optionSelected}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </div>
                <div>
                  <TextField type="text" name="helpContent" label="Info *" />
                </div>
              </Modal.Body>
              <Modal.Footer className="py-2 border border-0 pb-3 d-flex">
                <button
                  type="submit"
                  className="btn btn-primary text-white rounded-1 px-5 py-1"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn border-primary text-primary rounded-1 px-5 py-1 "
                >
                  Close
                </button>
              </Modal.Footer>
            </Formdata>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ManageFilterModal;
