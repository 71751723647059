/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import DefaultDropdown from "components/Inputs/DefaultDropdown";
import DefaultInput from "components/Inputs/DefaultInput";
import DefaultButton from "components/DefaultButton/DefaultButton";
import RadioInput from "components/Inputs/RadioInput";
import { useEventDispatch } from "store/event/EventContext";
import { useUserCustomFields } from "api/CustomField";
import { useCharities } from "api/GetCharity";
import { toast } from "react-toastify";
import {
  CustomFieldOption,
  CustomFieldSection,
  DynamicParticipant,
} from "types/CustomTypes";
import { nameConversion } from "utils/utils";
import moment from "moment";
import { readFileAsBase64, replaceUnderScoreWithSpace } from "utils/utils";
import Loader from "components/Loader/Loader";
import { useUserProfile } from "api/GetUserProfile";

interface FormModalProps {
  show: boolean;
  onHide: () => void;
  eventName: string;
  eventId: number;
  raceId: number;
  isEditing: boolean;
  participant?: DynamicParticipant<CustomFieldOption>;
  participantIndex: number;
  raceAmount: number;
  ageData: any;
  eventStartDate: string;
}

const buildValidationSchema = (
  sections: CustomFieldSection[] | undefined
): Record<string, any> => {
  if (!sections || !Array.isArray(sections)) {
    return {};
  }

  const schema: Record<string, any> = {};

  sections.forEach((section) => {
    if (Array.isArray(section.re_fields)) {
      section.re_fields.forEach((field) => {
        if (typeof field.field_name === "string") {
          const fieldName = field.field_name;

          const isRequired = field.field_status === 0;

          switch (field.input_type) {
            case "textbox":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string();
              break;
            case "email":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .email("Invalid email format")
                    .required(`${field.label_name} is required`)
                : Yup.string().email("Invalid email format");
              break;
            case "number":
              schema[fieldName] = isRequired
                ? Yup.number().required(`${field.label_name} is required`)
                : Yup.number();
              break;
            case "dropdown":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string().nullable();
              break;
            case "date":
              schema[fieldName] = isRequired
                ? Yup.date().required(`${field.label_name} is required`)
                : Yup.date();
              break;
            case "radio":
              schema[fieldName] = isRequired
                ? Yup.string()
                    .oneOf(
                      field.options || [],
                      `Please select a valid ${field.label_name}`
                    )
                    .required(`${field.label_name} is required`)
                : Yup.string().oneOf(field.options || []);
              break;
            case "file":
              schema[fieldName] = isRequired
                ? Yup.string().required(`${field.label_name} is required`)
                : Yup.string();
              break;
            default:
              break;
          }
        }
      });
    }
  });

  return schema;
};

const FormModal: React.FC<FormModalProps> = ({
  show,
  onHide,
  eventName,
  eventId,
  raceId,
  raceAmount,
  isEditing,
  participant,
  participantIndex,
  ageData,
  eventStartDate,
}) => {
  const [dynamicFields, setDynamicFields] = useState({});
  const [dynamicMinAge, setDynamicMinAge] = useState(0);
  const [dynamicMaxAge, setDynamicMaxAge] = useState(0);
  const dispatch = useEventDispatch();
  const [isFillingForSelf, setIsFillingForSelf] = useState("No");

  // Charity Bib & Tee Shirt
  const [isAvailCharityBib, setIsAvailCharityBib] = useState("No");
  const [showChartity, setShowCharity] = useState<boolean>(false);
  const [charityBibAmount, setCharityBibAmount] = useState(0);
  const [charityBibId, setCharityBibId] = useState(0);
  const [selectedCharityBib, setSelectedCharityBib] = useState("");
  const [isAvailTeeShirt, setIsAvailTeeShirt] = useState("No");
  const [selectedTeeShirt, setSelectedTeeShirt] = useState("");

  const {
    data: customFields,
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useUserCustomFields({ eventId, raceId });

  const { data: charityData, isLoading: charityDataLoading } = useCharities({
    eventId,
  });

  const {
    data: userData,
    isLoading: userDataLoading,
    error: userDataError,
  } = useUserProfile();

  useEffect(() => {
    if (Array.isArray(customFields?.data)) {
      const fields: Record<string, string> = {};
      customFields?.data.forEach((section: CustomFieldSection) => {
        if (section && Array.isArray(section.re_fields)) {
          section.re_fields.forEach((field: CustomFieldOption) => {
            fields[field.field_name] = "";
          });
        }
      });
      setDynamicFields(fields);
    }
  }, [customFields?.data]);

  useEffect(() => {
    if (isEditing) {
      if (participant?.is_filling_for === "myself") {
        setIsFillingForSelf("Yes");
      } else {
        setIsFillingForSelf("No");
      }
      setIsAvailTeeShirt(participant?.is_tshirt === "Y" ? "Yes" : "No");
      setSelectedTeeShirt(
        participant?.is_tshirt === "Y" ? participant?.tshirt : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleSubmit = async (
    values: DynamicParticipant<CustomFieldOption>
  ) => {
    const updatedParticipant = {
      ...values,
      first_name: nameConversion(values.first_name),
      last_name: nameConversion(values.last_name),
      is_filling_for: isFillingForSelf === "Yes" ? "myself" : "others",
      is_tshirt: isAvailTeeShirt === "Yes" ? "Y" : "N",
      tshirt: isAvailTeeShirt === "Yes" ? selectedTeeShirt : null,
    };

    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      if (isEditing && participant) {
        dispatch({
          type: "UPDATE_PARTICIPANT",
          payload: {
            eventNameToUpdate: eventName,
            participantIndexToUpdate: participantIndex,
            updatedParticipant:
              updatedParticipant as DynamicParticipant<CustomFieldOption>,
          },
        });
      } else {
        dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            eventName,
            raceId,
            raceAmount,
            participant:
              updatedParticipant as DynamicParticipant<CustomFieldOption>,
          },
        });
      }

      formik.resetForm();
      setIsFillingForSelf("No");
      onHide();
    }
  };

  const formik = useFormik<DynamicParticipant<CustomFieldOption>>({
    enableReinitialize: true,
    initialValues: isEditing
      ? mergeObjects(dynamicFields, participant as Record<string, string>)
      : dynamicFields,
    validationSchema: Yup.object(buildValidationSchema(customFields?.data)),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!isEditing) {
      if (isFillingForSelf === "Yes") {
        const updatedValues = { ...formik.values };
        Object.keys(userData?.data).forEach((key) => {
          if (updatedValues.hasOwnProperty(key)) {
            updatedValues[key] = userData?.data[key];
          }
        });
        formik.setValues(updatedValues);
      } else {
        formik.setValues(formik.initialValues);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFillingForSelf]);

  useEffect(() => {
    if (charityData?.status) {
      setShowCharity(true);
    } else {
      setShowCharity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charityData?.status]);

  useEffect(() => {
    if (isAvailCharityBib === "Yes") {
      setSelectedCharityBib(charityData?.data[0]?.charity_name);
      setCharityBibAmount(charityData?.data[0]?.amount);
      setCharityBibId(charityData?.data[0]?.id);
    } else {
      setCharityBibAmount(0);
      setCharityBibId(0);
      setSelectedCharityBib("");
    }
  }, [isAvailCharityBib]);

  useEffect(() => {
    if (!isEditing) {
      if (isAvailTeeShirt === "Yes") {
        setSelectedTeeShirt(customFields?.tData.size[0]);
      } else if (isAvailTeeShirt === "No") {
        setSelectedTeeShirt("");
      }
    }
  }, [isAvailTeeShirt]);

  // useEffect(() => {
  //   setDynamicMinAge(0);
  //   setDynamicMaxAge(0);
  //   if (formik?.values?.gender) {
  //     ageData?.map((tag: any, idx: any) => {
  //       if (tag.gender === formik?.values?.gender?.toLowerCase()) {
  //         if (dynamicMinAge < tag.min_age) {
  //           setDynamicMinAge(tag.min_age);
  //         }

  //         if (dynamicMaxAge < tag.max_age) {
  //           setDynamicMaxAge(tag.max_age);
  //         }
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik?.values?.gender]);

  const handleCancel = () => {
    formik.resetForm();
    onHide();
  };

  function mergeObjects<T extends Record<string, any>>(...objects: T[]): T {
    return Object.assign({}, ...objects);
  }

  if (customFieldsError) {
    toast.error("Error loading event details. Please try again later.");
  }
  if (userDataError) {
    toast.error("Error loading user details");
  }

  if (customFieldsLoading || userDataLoading || charityDataLoading) {
    return <Loader />;
  }
  if (!Array.isArray(customFields?.data) || customFields?.data.length === 0) {
    return <div>Error: customFields is not an array or is empty</div>;
  }

  const onCharityChange = (e: any) => {
    charityData?.data?.forEach((charity: any) => {
      if (charity.charity_name === e.target.value) {
        setCharityBibAmount(charity.amount);
        setCharityBibId(charity.id);
      }
    });
    setSelectedCharityBib(e.target.value);
  };

  const onSelectTeeshirtChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeeShirt(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Form
        onSubmit={formik.handleSubmit}
        className=""
        encType="multipart/form-data"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="mb-0 formTitleInEventRegisteration">{eventName}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-6 mb-0 mb-sm-4">
            <RadioInput
              label="Are You filling for self?"
              labelClassName="fs-14"
              options={["Yes", "No"]}
              selectedOption={isFillingForSelf}
              onChange={(e) => {
                setIsFillingForSelf(e.target.value);
              }}
              className="fs-14"
            />
          </div>

          {showChartity && (
            <Container className="row align-items-center">
              <div className="col-sm-6 mb-0 mb-sm-4 px-0">
                <RadioInput
                  label="Are you availing Charity Bib?"
                  labelClassName="fs-14"
                  options={["Yes", "No"]}
                  selectedOption={isAvailCharityBib}
                  onChange={(e) => {
                    setIsAvailCharityBib(e.target.value);
                  }}
                  className="fs-14"
                />
              </div>
              {isAvailCharityBib === "Yes" && (
                <div className="col-sm-6 mb-0 mb-sm-4 pe-0">
                  <Container className="row align-items-center justify-content-between">
                    <div className="col-7 px-0">
                      <DefaultDropdown
                        label="Select Charity Bib"
                        options={charityData?.data?.map(
                          (charity: any) => charity.charity_name
                        )}
                        onChange={onCharityChange}
                        value={selectedCharityBib}
                      />
                    </div>
                    <div className="col ms-2">
                      <h6 className="mb-0 text-primary fs-18">
                        {charityBibAmount}/-
                      </h6>
                    </div>
                  </Container>
                </div>
              )}
            </Container>
          )}

          {customFields?.data.map(
            (section: CustomFieldSection) =>
              section.re_fields.length > 0 && (
                <div key={section.id} className="py-2">
                  <p className={`fw-600 ff-saira fs-20`}>
                    {replaceUnderScoreWithSpace(section.section_name)}
                  </p>
                  <Container className="row">
                    {section.re_fields.map((field: CustomFieldOption) => (
                      <div key={field.id} className="col-sm-6 mb-0 mb-sm-4">
                        {field.input_type === "textbox" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.label_name
                              }
                              type="text"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name as string] &&
                                !!formik.errors[field.field_name as string]
                              }
                              error={formik.errors[field.field_name as string]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 30
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "number" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="number"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 30
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "email" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="email"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 32
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "date" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="date"
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              // maxDate={moment(eventStartDate)
                              //   .subtract(dynamicMinAge, "y")
                              //   .format("yyyy-MM-DD")
                              //   .toString()}
                              // minDate={moment(eventStartDate)
                              //   .subtract(dynamicMaxAge, "y")
                              //   .format("yyyy-MM-DD")}
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 62
                                    : 12,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "dropdown" && (
                          <div className="mb-3 mb-sm-0 position-relative">
                            <DefaultDropdown
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              options={field?.options}
                              placeholder={field.label_name}
                              value={formik.values[field.field_name]}
                              onChange={formik.handleChange(field.field_name)}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                            {field?.help_content !== null && (
                              <span
                                style={{
                                  top: 16.8,
                                  right: formik.errors[field.field_name]
                                    ? 34
                                    : 34,
                                }}
                                className="position-absolute"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>{field?.help_content}</Tooltip>
                                  }
                                >
                                  <img
                                    className="cursor-pointer"
                                    height={14.5}
                                    width={15}
                                    alt="info tooltip"
                                    src="/images/info.svg"
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                        )}
                        {field.input_type === "radio" && (
                          <div className="mb-3 mb-sm-0">
                            <RadioInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.label_name
                              }
                              labelClassName="position-relative fs-14"
                              options={field.options || []}
                              selectedOption={formik.values[field.field_name]}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  field.field_name,
                                  e.target.value
                                )
                              }
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              helperContent={field?.help_content}
                              error={formik.errors[field.field_name]}
                            />
                          </div>
                        )}
                        {field.input_type === "file" && (
                          <div className="mb-3 mb-sm-0">
                            <DefaultInput
                              label={
                                field.field_status === 0
                                  ? field.label_name + " *"
                                  : field.field_name
                              }
                              type="file"
                              placeholder={field.label_name}
                              accept="image/png, image/heic, image/jpeg, image/jpg, application/pdf"
                              onChange={async (event: any) => {
                                const file = event.target.files[0];
                                const base64 = await readFileAsBase64(file);
                                formik.setFieldValue(field.field_name, base64);
                              }}
                              isError={
                                formik.touched[field.field_name] &&
                                !!formik.errors[field.field_name]
                              }
                              error={formik.errors[field.field_name]}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </Container>
                </div>
              )
          )}

          {customFields?.isTshirt && (
            <Container className="row align-items-center">
              <div className="col-sm-6 mb-0 mb-sm-4 px-0">
                <RadioInput
                  label="Are you availing T-Shirt?"
                  labelClassName="fs-14"
                  options={["Yes", "No"]}
                  selectedOption={isAvailTeeShirt}
                  onChange={(e) => {
                    setIsAvailTeeShirt(e.target.value);
                  }}
                  className="fs-14"
                />
              </div>
              {isAvailTeeShirt === "Yes" && (
                <div className="col-sm-6 mb-0 mb-sm-4 pe-0">
                  <Container className="row align-items-center justify-content-between">
                    <div className="col-7 px-0">
                      <DefaultDropdown
                        label="Select T-Shirt Size"
                        options={customFields?.tData.size}
                        onChange={onSelectTeeshirtChange}
                        value={selectedTeeShirt}
                      />
                    </div>
                    <div className="col ms-2">
                      <h6 className="mb-0 text-primary fs-18">
                        {customFields?.tData.tshirt_amount}/-
                      </h6>
                    </div>
                  </Container>
                  <div>
                    <span>
                      Size Chart
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            className="tshirt-tooltip"
                            style={{
                              width: "300px",
                            }}
                          >
                            <Image
                              src="/images/size-chart.jpeg"
                              alt=""
                              style={{
                                width: "300px",
                              }}
                            />
                          </Tooltip>
                        }
                      >
                        <img
                          className="cursor-pointer mx-2"
                          height={14}
                          width={14}
                          alt="info tooltip"
                          src="/images/info.svg"
                        />
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>
              )}
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton
            type="button"
            className="default-action-button  fw-bold text-white ms-4"
            variant="primary"
            onClick={handleCancel}
          >
            Close
          </DefaultButton>
          <DefaultButton
            type="submit"
            className="default-action-button fw-bold text-white ms-4"
            variant="primary"
          >
            {isEditing ? "Update Details" : "Add Participant"}
          </DefaultButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormModal;
