import MyAchievements from "components/MyEvents/MyAchievements/MyAchievements";
import MyBooking from "components/MyEvents/MyBooking/MyBooking";
import MyPhotos from "components/MyEvents/MyPhotos/MyPhotos";
import MyWishlist from "components/MyEvents/MyWishlist/MyWishlist";
import { Breadcrumb, Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MyEvents = () => {
  const navigation = useNavigate();
  return (
    <>
      <Container className="mt-4 mb-4">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigation("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Events</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <Container>
        <span className="blockTitle"> My Events</span>
        <Tabs
          defaultActiveKey={"myBooking"}
          id="event-tabs"
          className="event-nav-tabs border-bottom pt-4"
        >
          <Tab eventKey="myWishlist" title="My Wishlist">
            <MyWishlist />
          </Tab>
          <Tab eventKey="myBooking" title="My Booking">
            <MyBooking />
          </Tab>
          <Tab eventKey="myAchievements" title="My Achievements">
            <MyAchievements />
          </Tab>
          <Tab eventKey="myPhotos" title="My Photos">
            <MyPhotos />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default MyEvents;
