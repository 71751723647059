import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import TermsAndCondition from "components/FooterComponents/TermsandCondition/TermsAndCondition";

interface PreviewTermsandConditionProps {
  isOpenPreviewModal: boolean;
  showIsOrganiserTermsAndConditions: boolean;
  organiserTermsAndConditions: string;
  handleClosePreviewModal: () => void;
}

const PreviewTermsandCondition: React.FC<PreviewTermsandConditionProps> = ({
  isOpenPreviewModal,
  handleClosePreviewModal,
  showIsOrganiserTermsAndConditions,
  organiserTermsAndConditions,
}) => {
  return (
    <Modal
      size="xl"
      show={isOpenPreviewModal}
      centered
      onHide={handleClosePreviewModal}
    >
      <Modal.Header className="ps-4 pb-3 border-0" closeButton></Modal.Header>
      <div className="px-3">
        <TermsAndCondition
          showIsOrganiserTermsAndConditions={showIsOrganiserTermsAndConditions}
          organiserTermsAndConditions={organiserTermsAndConditions}
        />
      </div>
    </Modal>
  );
};

export default memo(PreviewTermsandCondition);
