import { useState, useEffect } from "react";
import { Container, Dropdown, DropdownButton, Image } from "react-bootstrap";
import styles from "./Dashboard.module.scss";
import { Icon } from "components/Icon";
import Gallry from "components/Dashboard/Gallery";
import Partners from "components/Partners";
import { recommendedVirtualEvents } from "../api/RecommendedVirtualEvents";
import { trainingProgrammes } from "../api/TrainingProgrammes";
import DashboardCTA from "components/Dashboard/DashboardCTA";
import EventCategories from "components/Dashboard/EventCategories";
import RecommendedEvents from "components/Dashboard/RecommendedEvents";
import TraingProgram from "components/Dashboard/TraingProgram";
import VirtualRace from "components/Dashboard/VirtualRace";
import { useNavigate } from "react-router-dom";
import useBootstrapSize from "utils/useBootstrapSize";
import MobileGallery from "components/Dashboard/MobileGallery";
import DefaultButton from "components/DefaultButton/DefaultButton";
import UpdateProfile from "components/Dashboard/UpdateProfile";
import DashBanner from "components/Dashboard/DashBanner";
import { useAuth } from "context/AuthContext";
import { useEventList } from "api/EventApi";
import { toast } from "react-toastify";
import { Event } from "types/Event";
import { GetBanners } from "api/GetBanners";
import { hasEmptyValue, sortEventsByEndDate } from "utils/utils";
import Loader from "components/Loader/Loader";
import { useUserProfile } from "api/GetUserProfile";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const screenSize = useBootstrapSize();
  const isLoggedIn = localStorage.getItem("isIfinishLoogedIn");
  const [startIndex, setStartIndex] = useState(0);
  const [startVirtualIndex, setStartVirtualIndex] = useState(0);
  const [startProgrammeIndex, setStartProgrammeIndex] = useState(0);

  const onEventClick = () => {
    navigate("/events");
  };
  const {
    data: recommendedEvents,
    isLoading: eventsLoading,
    error: eventsError,
  } = useEventList();

  const sortedEvents = sortEventsByEndDate(recommendedEvents?.data);

  const {
    data: DashboardBanners,
    isLoading: DashboardBannersLoading,
    error: DashboardBannersError,
  } = GetBanners();

  const { data: userData, isLoading: userDataLoading } = useUserProfile();

  // Display 3 cards at a time

  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs" || screenSize === "md"
      ? 1
      : screenSize === "lg"
      ? 2
      : 3;

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };
  const handleVirtualPrev = () => {
    if (startVirtualIndex > 0) {
      setStartVirtualIndex(startVirtualIndex - CHUNK_SIZE);
    }
  };
  const handleProgramPrev = () => {
    if (startProgrammeIndex > 0) {
      setStartProgrammeIndex(startProgrammeIndex - CHUNK_SIZE);
    }
  };
  const handleNext = () => {
    if (startIndex + CHUNK_SIZE < sortedEvents?.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };

  const handleVirtualNext = () => {
    if (startVirtualIndex + CHUNK_SIZE < recommendedVirtualEvents?.length) {
      setStartVirtualIndex(startVirtualIndex + CHUNK_SIZE);
    }
  };
  const handleProgramNext = () => {
    if (startProgrammeIndex + CHUNK_SIZE < trainingProgrammes?.length) {
      setStartProgrammeIndex(startProgrammeIndex + CHUNK_SIZE);
    }
  };
  // Check if there are items to show for PrevIcon
  const showPrevItems = startIndex > 0;

  // Check if there are items to show for NextIcon
  const showNextItems = startIndex + CHUNK_SIZE < sortedEvents?.length;

  const currentEvents: Event[] = Array.isArray(sortedEvents)
    ? sortedEvents?.slice(startIndex, startIndex + CHUNK_SIZE)
    : [];

  const currentVirtualEvents = recommendedVirtualEvents.slice(
    startVirtualIndex,
    startVirtualIndex + CHUNK_SIZE
  );
  const currentTrainningPrograms = trainingProgrammes.slice(
    startProgrammeIndex,
    startProgrammeIndex + CHUNK_SIZE
  );

  if (eventsLoading || DashboardBannersLoading || userDataLoading) {
    return <Loader />;
  }

  if (eventsError) {
    toast.error("Error fetching data. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  if (DashboardBannersError) {
    toast.error("Error fetching Dashboard Images");
  }

  if (!recommendedEvents) {
    return <div>No recommended events available.</div>;
  }
  return (
    <>
      <div className={`${styles.backgroundGray}`}>
        <Container
          fluid
          className="d-none d-md-block"
          style={{ background: "#f1f1f3" }}
        >
          <div className={`${styles.searchBar} d-flex align-items-center`}>
            <span className="fw-600 fs-12 text-darkGray">
              Choose your city or state to see content specific to you
              location...
            </span>
            <div className={`bg-white d-flex ${styles.filterBlock}`}>
              <div className="d-flex align-items-center px-1">
                <Icon
                  iconName="GeoAlt"
                  size={20}
                  className="text-gray2 bg-white"
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="vr" />
              </div>

              <div
                className={`w-100 d-flex align-items-center ${styles.customDropdown}`}
              >
                <DropdownButton
                  id="dropdown-item-button"
                  className="w-100 ff-saira border-0"
                  variant="white"
                  title="India"
                >
                  <Dropdown.Item as="button">India</Dropdown.Item>
                  <Dropdown.Item as="button">Australia</Dropdown.Item>
                  <Dropdown.Item as="button">USA</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <DefaultButton
              variant="dark"
              className="py-1"
              type="button"
              onClick={() => console.log("Continued")}
            >
              Continue
            </DefaultButton>
            <div>
              <Icon iconName="XLg" size={20} />
            </div>
          </div>
        </Container>

        <Container
          fluid
          className={`${styles.sale} bg-primary d-flex justify-content-center align-items-center`}
        >
          <span>
            Azadi Mahotsav Sale - Get upto 40% off on your favourite sports
            brands
          </span>
          <div className=" ps-1 ps-md-2">
            <Icon iconName="XCircle" size={20} className="" />
          </div>
        </Container>
        <Container className="px-0 d-md-none">
          <div className="position-relative  pb-5">
            <div className={`${styles.coverImage} pt-3`}>
              <Image
                src="/images/run-bhopal.png"
                width="100%"
                alt="RunBhopal"
              />
            </div>
          </div>
        </Container>
        <DashBanner
          className="d-none d-md-block"
          banners={DashboardBanners?.data}
        />

        {isAuthenticated && !hasEmptyValue(userData?.data) && <UpdateProfile />}

        <EventCategories onEventClick={onEventClick} />
        {currentEvents.length > 0 && (
          <RecommendedEvents
            currentEvents={currentEvents}
            handlePrev={handlePrev}
            handleNext={handleNext}
            title="Recommended Events"
            showPrevItems={showPrevItems}
            showNextItems={showNextItems}
          />
        )}

        <VirtualRace
          currentVirtualEvents={currentVirtualEvents}
          handleVirtualPrev={handleVirtualPrev}
          handleVirtualNext={handleVirtualNext}
        />

        <TraingProgram
          currentTrainningPrograms={currentTrainningPrograms}
          handleProgramPrev={handleProgramPrev}
          handleProgramNext={handleProgramNext}
        />
        <div
          className={`${styles.backgroundGray}`}
          style={{ height: "100px" }}
        ></div>

        <Container
          fluid
          className="d-none  bg-primary position-relative d-lg-flex justify-content-center align-items-center"
          style={{ height: "352px" }}
        >
          <div className="position-absolute" style={{ marginTop: "-155px" }}>
            <Gallry />
          </div>
        </Container>

        <Container
          fluid
          className="bg-primary position-relative d-flex d-lg-none justify-content-center align-items-center"
          style={{ height: "260px" }}
        >
          <div style={{ marginBottom: "170px" }}>
            <MobileGallery />
          </div>
        </Container>

        <DashboardCTA />
      </div>
      <Container className="bg-white">
        <Partners />
      </Container>
    </>
  );
};

export default Dashboard;
