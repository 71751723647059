import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handlePhotos = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/photos");
  };
  const handleJoinPhotoGraphy = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/joinourphotography");
  };
  const handleAbout = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/aboutus");
  };
  return (
    <>
      <Container fluid className="m-0 p-0 overflow-x-hidden">
        <div className="m-0">
          <img
            src="/images/FooterBanner.svg"
            alt="footer_banner"
            className="w-100"
          />
        </div>

        <div className="m-0 bg-primary py-4 px-4 py-md-0 mx-md-0">
          <div className="row">
            <div className="col-md-6 col-12 d-flex justify-content-md-between flex-column pt-md-4 ps-2 ps-md-5">
              <span className="footer_stay_connected text-white">
                Stay Connected
              </span>
              <h5 className="text-uppercase text-white footer_signup_text my-3 my-md-0">
                sign up for our newsletter to receive update
              </h5>
            </div>
            <div className="col-md-6 col-12 text-end pt-md-4 pe-2 pe-md-5">
              <div className="d-flex justify-content-md-end align-items-md-center">
                <div className="border-bottom">
                  <input
                    type="text"
                    placeholder="YOUR E-MAIL"
                    style={{ outline: "none" }}
                    className="border-0 bg-primary custom-placeholder"
                  />
                </div>
                <div className="border-bottom">
                  <h5 className="text-white">
                    <img src="/images/Arrow.png" alt="arrow" className="mx-2" />
                    subscribe
                  </h5>
                </div>
              </div>
              <div className="my-md-2 my-2">
                <p className="text-white sign_up_color">
                  By subscribing, you agree to our Terms & Conditions,
                  <br />
                  Privacy Policy
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="m-0 bg-primary py-md-2">
          <div className="row border-top mx-md-5 mx-2 py-md-5 py-4">
            <div className="col-md-5 d-flex flex-column">
              <div>
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    navigate("/");
                  }}
                  src="/images/Ifinish_white.png"
                  alt="logo_white"
                />
              </div>
              <div className="py-md-3 py-3">
                <h5 className="text-white about_ifinish">
                  iFinish is part of Timing Technologies India Private Limited,
                  a leading provider of timing services for Active sports. For
                  more information about Timing Technologies, please visit
                  Timing Technologies.
                </h5>
              </div>
            </div>
            <div className="col-md-3 d-flex flex-column">
              <div>
                <p className="noto_font text-white">Quick Links</p>
              </div>
              <div>
                <p className="noto_font_lists " style={{ cursor: "pointer" }}>
                  Home
                </p>
                <p className="mt-2">
                  <div
                    className="noto_font_lists text-decoration-none"
                    onClick={handlePhotos}
                    style={{ cursor: "pointer" }}
                  >
                    Photos
                  </div>
                </p>

                <p className="noto_font_lists">Shop</p>
                <div
                  className="noto_font_lists text-decoration-none"
                  onClick={handleJoinPhotoGraphy}
                  style={{ cursor: "pointer" }}
                >
                  Join our Photographers Team
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex flex-column">
              <div>
                <h5 className="noto_font text-white mt-4 mt-md-0">About</h5>
              </div>
              <div>
                <p className="mt-2">
                  <div
                    className="noto_font_lists text-decoration-none"
                    onClick={handleAbout}
                    style={{ cursor: "pointer" }}
                  >
                    About Us
                  </div>
                </p>

                <p className="mt-2">
                  <div
                    className="noto_font_lists text-decoration-none"
                    onClick={() => navigate("/contactus")}
                  >
                    Contact Us
                  </div>
                </p>
                <p className="mt-2">
                  <div
                    className="noto_font_lists text-decoration-none"
                    onClick={() => navigate("/support")}
                    style={{ cursor: "pointer" }}
                  >
                    Support
                  </div>
                </p>

                <p className="mt-2">
                  <div
                    className="noto_font_lists text-decoration-none"
                    onClick={() => navigate("/termsandcondition")}
                    style={{ cursor: "pointer" }}
                  >
                    Terms & Conditions
                  </div>
                </p>

                <p className="noto_font_lists" style={{ cursor: "pointer" }}>
                  Privacy Policy
                </p>
              </div>
            </div>
            <div className="col-md-1 d-md-flex flex-md-column d-flex">
              <p className="noto_font_lists me-3 me-md-0">
                <img
                  src="/images/icon-park-outline_telegram.png"
                  alt="telegram"
                />
              </p>
              <p className="noto_font_lists me-3 me-md-0">
                <img
                  src="/images/icon-park-outline_facebook.png"
                  alt="Facebook"
                />
              </p>
              <p className="noto_font_lists me-3 me-md-0">
                <img
                  src="/images/icon-park-outline_instagram.png"
                  alt="Instagram"
                />
              </p>
              <p className="noto_font_lists">
                <img src="/images/Frame 1707478769.png" alt="twitter" />
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="bg-darkred text-center m-0">
        <p className="text-white py-3 mb-0">
          © {new Date().getFullYear()} iFinish - All Rights Reserved.
        </p>
      </Container>
    </>
  );
};

export default Footer;
