import { Form, FloatingLabel } from "react-bootstrap";

interface DefaultDropdownProps {
  label: string;
  options: string[] | null;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  className?: string;
  isError?: boolean;
  error?: string;
  disablePlaceholder?: boolean;
  isDisabled?: boolean;
  name?: string;
}

const DefaultDropdown: React.FC<DefaultDropdownProps> = ({
  label,
  options,
  placeholder,
  onChange,
  value,
  className,
  isError,
  error,
  isDisabled = false,
  disablePlaceholder = false,
  name,
}) => {
  return (
    <>
      <FloatingLabel
        controlId={label}
        label={label}
        className={`mb-1 custom-floating-label ${className || ""} ${
          isError ? "error-border" : ""
        }`}
      >
        <Form.Select
          aria-label={placeholder}
          value={value}
          onChange={onChange}
          className={`form-control ${isError ? "is-invalid" : ""}`}
          isInvalid={!!isError}
          disabled={isDisabled}
          name={name}
        >
          {placeholder && (
            <option value="" disabled={disablePlaceholder}>
              {placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
      {isError && <div className="text-danger fs-14">{error}</div>}
    </>
  );
};

export default DefaultDropdown;
