import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "context/AuthContext";
const PublicRoutes = () => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/org/accounts" replace />
  );
};

export default PublicRoutes;
