import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TextField, TextSelect } from "utils/FormControls";
import { Form as FormData, Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useEvent } from "context/EventContext";
import { ToastContainer, toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import MultiSelect from "components/Inputs/MultiSelect";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const CreateFormFieldModal = ({
  showModal,
  handleCloseCreateManageModal,
  setIsRefresh,
}: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  let eventId: any;
  useEffect(() => {
    if (isEditEvent && id) {
      eventId = decryptText(String(id));
      setId(eventId);
    }
  }, [eventId, id]);
  const { token } = useAuth();
  const { storeEventId } = useEvent();
  const [isRaceList, setIsRaceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelected, setSelected] = useState<any>(null);

  const initialValues = {
    labelName: "",
    fieldStatus: 1,
    sectionId: "",
    inputType: "",
    options: [],
    hideThisField: "",
    position: null,
  };

  const validationSchema = Yup.object({
    labelName: Yup.string().required(),
    fieldStatus: Yup.string().required(),
    sectionId: Yup.string().required(),
    inputType: Yup.string().required(),
    hideThisField: Yup.string(),
  });

  const onSubmit = (values: any) => {
    setIsLoading(true);
    let postData;
    let newDataArray;
    if (typeof values.options === "string") {
      newDataArray = values.options.split(",");
    } else {
      newDataArray = null;
    }
    const field: any = optionSelected?.map((item: any) => item.value);

    postData = {
      eventId: Id || storeEventId,
      labelName: values.labelName,
      fieldStatus: values.fieldStatus,
      options: newDataArray,
      inputType: values.inputType,
      sectionId: values.sectionId,
      hideThisField: field || null,
      position: null,
    };

    // axios
    //   .post("/createEventCustomField", postData, {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    axiosPrivate({
      url: "/createEventCustomField",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          toast.success(`${res.data.message}`, { autoClose: 1500 });
          setTimeout(() => {
            handleCloseCreateManageModal();
            setIsRefresh(true);
          }, 2000);
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setSelected(null);
      });
  };
  const selectQuestionType = [
    { id: "textbox", name: "textbox" },
    { id: "dropdown", name: "dropdown" },
    { id: "radio", name: "radio" },
    { id: "checkbox", name: "checkbox" },
    { id: "file", name: "file" },
    { id: "data", name: "date" },
    { id: "textarea", name: "textarea" },
    { id: "number", name: "number" },
  ];
  const selectionName = [
    { name: "Profile", id: "1" },
    { name: "Address", id: "2" },
    { name: "Physical", id: "3" },
    { name: "Additional", id: "4" },
  ];
  function eventRacesList() {
    // axios.post(
    //   "/eventRacesList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/eventRacesList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          const updatedSpareParts = res.data.data.map((part: any) => {
            const {
              eventId,
              startDate,
              endDate,
              age,
              distance,
              amount,
              ...rest
            } = part;
            return rest;
          });

          const transformedData = updatedSpareParts.map((item: any) => ({
            value: item.id,
            label: item.raceName,
          }));
          // const optionsWithNone: any = [
          //   { value: null, label: "None" },
          //   ...transformedData,
          // ];
          setIsRaceList(transformedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (Id || storeEventId) {
      eventRacesList();
    }
  }, [Id, storeEventId]);
  const handleMultiChange = (selected: any) => {
    setSelected(selected);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Modal show={showModal} onHide={handleCloseCreateManageModal} centered>
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>Create Form Field</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => {
            return (
              <FormData noValidate className="pb-md-3 px-md-4">
                <Modal.Body>
                  <div>
                    <TextField
                      name="labelName"
                      type="text"
                      label="Field Name *"
                    />
                  </div>
                  <div>
                    <TextSelect
                      type="text"
                      name="inputType"
                      options={selectQuestionType}
                      label="Question Type *"
                    />
                  </div>
                  <div>
                    {(values.inputType === "dropdown" ||
                      values.inputType === "radio") && (
                      <TextField
                        type="text"
                        name="options"
                        label="Options List *"
                      />
                    )}
                  </div>
                  <div>
                    <TextSelect
                      name="sectionId"
                      options={selectionName}
                      label="Section Name *"
                    />
                  </div>
                  <div className="py-md-3">
                    <Form.Label>
                      Hide this field for the following races
                    </Form.Label>
                    <MultiSelect
                      options={isRaceList}
                      onChange={handleMultiChange}
                      value={optionSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                  {/* <div>
                    <label className="text-darkgray fw-400 my-3">
                      Hide this field for the following races
                    </label>
                    <select
                      className="form-control"
                      name="hideThisField"
                      onChange={handleChange}
                    >
                      <option value="">Choose option</option>
                      {isRaceList?.map((option: any) => (
                        <option key={option.raceId} value={option.raceId}>
                          {option.raceName}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className=" mt-3">
                    <Form.Group>
                      <Form.Label className="me-2">Question Status</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          name="fieldStatus"
                          className="d-flex align-items-center"
                          id="fieldStatus"
                          value="0"
                          label="Required"
                          checked={values.fieldStatus === 0}
                          onChange={() => setFieldValue("fieldStatus", 0)}
                        />

                        <Form.Check
                          type="radio"
                          // className="ms-md-3"
                          name="fieldStatus"
                          className="d-flex align-items-center ms-md-3"
                          id="fieldStatus"
                          value="1"
                          label="Optional"
                          checked={values.fieldStatus === 1}
                          onChange={() => setFieldValue("fieldStatus", 1)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Modal.Body>
                <Modal.Footer className="py-2 border border-0 pb-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-white rounded-1 px-5 py-1"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseCreateManageModal}
                    className="btn border-primary text-primary rounded-1 px-5 py-1 "
                  >
                    Close
                  </button>
                </Modal.Footer>
              </FormData>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default CreateFormFieldModal;
