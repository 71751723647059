import React, { useEffect, useState } from "react";
import { Form as FormData, Formik } from "formik";
import { Col, Row, Form } from "react-bootstrap";
import { TextField } from "utils/FormControls";
import * as Yup from "yup";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { toast, ToastContainer } from "react-toastify";
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useEvent } from "context/EventContext";
import DefaultInput from "components/Inputs/DefaultInput";
import styles from "./OrgniserSignup.module.scss";
import Loader from "components/Loader/Loader";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";

const OrganiserSignUpForm2 = ({ scrollbar }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const Navigate = useNavigate();
  const axiosPublic = useAxiosPublic();
  const { storeAccountId, setAccountId } = useEvent();
  const validationSchema = Yup.object().shape({
    isYourTurnOver20c: Yup.bool().required("Is your Turn over is required"),
    gstNo: Yup.string()
      .required("GST Number is required")
      .matches(
        /^(?:[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})|(?:^[A-Za-z0-9]{1,15}$)/,
        "Invalid GST number format or must be alphanumeric and up to 15 characters"
      )
      .min(15, "Minimum of 15 aplhanumeric  characters of length")
      .max(15, "Maximum of 15 aplhanumeric  characters of length"),
    bankName: Yup.string()
      .required("Bank Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Bank Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    accountNo: Yup.string()
      .required("Account No is Required")
      .matches(/^\d{9,18}$/, "Invalid account number"),
    panNo: Yup.string()
      .required("PAN Number is required")
      .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, "Invalid PAN Number"),
    accountName: Yup.string()
      .required("Account Name is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    accountType: Yup.string()
      .required("Account Type is Required")
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      ),
    micrCode: Yup.string()
      .required("micrCode is Required")
      .matches(/^[0-9]{9}$/, "Invalid MICR code"),
    ifscCode: Yup.string()
      .required("ifscCode is Required")
      .matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, "Invalid IFSC code"),
    gstAttachment: Yup.mixed()
      .required("GST Attachment is required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 5 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),
    cancelChequeAttachment: Yup.mixed()
      .required("Cancel Cheque Attachment is required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 5 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),
    workOrderAttachment: Yup.mixed()
      .required("Work Order Attachment is required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 5 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),

    turnOverDeclarationAttachment: Yup.mixed()
      .required("Turn Over Declaration Attachment is required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 5 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),
    panAttachment: Yup.mixed()
      .required("Pan Attachment is required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 5 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),
  });

  const OrgBankInitialValues = {
    isYourTurnOver20c: 0,
    bankName: "",
    accountNo: "",
    accountName: "",
    accountType: "",
    micrCode: "",
    ifscCode: "",
    cancelChequeAttachment: null,
    workOrderAttachment: null,
    turnOverDeclarationAttachment: null,
    gstNo: "",
    gstAttachment: null,
    panNo: "",
    panAttachment: null,
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const postData = {
      accountId: storeAccountId,
      isYourTurnOver20c: values.isYourTurnOver20c,
      bankName: values.bankName,
      accountNo: values.accountNo,
      accountName: values.accountName,
      accountType: values.accountType,
      micrCode: values.micrCode,
      ifscCode: values.ifscCode,
      cancelChequeAttachment: values.cancelChequeAttachmentbase64,
      workOrderAttachment: values.workOrderAttachmentbase64,
      turnOverDeclarationAttachment: values.turnOverDeclarationAttachmentbase64,
      gstNo: values.bankName,
      gstAttachment: values.gstAttachmentbase64,
      panNo: values.bankName,
      panAttachment: values.panAttachmentbase64,
    };
    // axios
    //   .post("/organiserBankDetails", postData, {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   })
    axiosPublic({
      url: "/organiserBankDetails",
      data: postData,
      method: "POST",
    })
      .then((response: any) => {
        if (response.data.status) {
          toast.success(`${response.data.message}`, { autoClose: 1000 });
          setTimeout(() => {
            Navigate("/org/login");
            setAccountId("null");
          }, 2000);
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(response.data.message).forEach((key) => {
            response.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        console.log("Organiser Signup form-2", err);
      })
      .finally(() => setIsLoading(false));
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as base64."));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className={`${styles.OrganiserSignup}`}>
        <Formik
          initialValues={OrgBankInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => {
            return (
              <FormData
                noValidate
                className={`${styles.scrollContainer} px-md-4 py-md-4 `}
              >
                <div className="border px-4 rounded form-control py-4">
                  <Row>
                    <Col className="col-12 d-flex align-items-center">
                      <Form.Group className="d-flex  align-items-center justify-content-center">
                        <Form.Label className="me-2">
                          Is your turnover above 20 cr?
                        </Form.Label>

                        <Form.Check
                          style={{ marginTop: "-10px" }}
                          type="radio"
                          className="d-flex align-items-center"
                          name="isYourTurnOver20c"
                          id="isYourTurnOver20c"
                          value={1}
                          checked={formikProps.values.isYourTurnOver20c === 1}
                          label="Yes"
                          onChange={() =>
                            formikProps.setFieldValue("isYourTurnOver20c", 1)
                          }
                        />

                        <Form.Check
                          style={{ marginTop: "-10px" }}
                          type="radio"
                          className="d-flex align-items-center ms-md-2"
                          name="isYourTurnOver20c"
                          id="isYourTurnOver20c"
                          value={0}
                          checked={formikProps.values.isYourTurnOver20c === 0}
                          label="No"
                          onChange={() =>
                            formikProps.setFieldValue("isYourTurnOver20c", 0)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6 col-12">
                      <TextField type="text" label="GST No *" name="gstNo" />
                    </Col>
                    <Col className="col-md-6 col-12">
                      <div className="d-flex flex-column w-100 py-0 mt-md-2">
                        <DefaultInput
                          label="GST Attachment(jpeg,jpg and png) *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          isError={
                            formikProps.touched.gstAttachment &&
                            !!formikProps.errors.gstAttachment
                          }
                          error={formikProps.errors.gstAttachment}
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "gstAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue("gstAttachment", file);
                          }}
                        />
                      </div>

                      {/* <label
                  htmlFor="gstAttachment"
                  className="text-darkgray fw-400 my-3"
                >
                  GST Attachment<span className="text-primary ms-1">*</span>
                </label>
                <input
                  className={`form-control py-1`}
                  id="fileUpload"
                  type="file"
                  name="gstAttachment"
                  accept="image/png, image/heic, image/jpeg, image/jpg"
                  onChange={async (event: any) => {
                    const file = event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : null;

                    if (file) {
                      const base64 = await readFileAsBase64(file);
                      formikProps.setFieldValue(
                        "gstAttachmentbase64",
                        base64
                      );
                    }

                    formikProps.setFieldValue("gstAttachment", file);
                  }} */}
                      {/* /> */}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-md-6 col-12 py-1">
                      <TextField type="text" label="PAN No *" name="panNo" />
                    </Col>
                    <Col className="col-md-6 col-12">
                      <div className="d-flex flex-column w-100 py-0  mt-md-2">
                        <DefaultInput
                          label="Pan Attachment *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          isError={
                            formikProps.touched.panAttachment &&
                            !!formikProps.errors.panAttachment
                          }
                          error={formikProps.errors.panAttachment}
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "panAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue("panAttachment", file);
                          }}
                        />
                      </div>
                      {/* <label
                  htmlFor="panAttachment"
                  className="text-darkgray fw-400 my-3"
                >
                  PAN Attachment<span className="text-primary ms-1">*</span>
                </label>
                <input
                  className={`form-control py-1`}
                  id="fileUpload"
                  type="file"
                  name="panAttachment"
                  accept="image/png, image/heic, image/jpeg, image/jpg"
                  onChange={async (event: any) => {
                    const file = event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : null;

                    if (file) {
                      const base64 = await readFileAsBase64(file);
                      formikProps.setFieldValue(
                        "panAttachmentbase64",
                        base64
                      );
                    }

                    formikProps.setFieldValue("panAttachment", file);
                  }}
                /> */}
                    </Col>
                  </Row>
                  <h4 className="text-primary mt-md-4">BANK DETAILS</h4>
                  <Row>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Bank Name *"
                        name="bankName"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Bank A/C No *"
                        name="accountNo"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Account holder name *"
                        name="accountName"
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="Account Type *"
                        name="accountType"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="MICR Code *"
                        name="micrCode"
                      />{" "}
                    </Col>
                    <Col className="col-md-4 col-12">
                      {" "}
                      <TextField
                        type="text"
                        label="IFSC Code *"
                        name="ifscCode"
                      />{" "}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-md-6 col-12">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Cancel Cheque *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          isError={
                            formikProps.touched.cancelChequeAttachment &&
                            !!formikProps.errors.cancelChequeAttachment
                          }
                          error={formikProps.errors.cancelChequeAttachment}
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "cancelChequeAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "cancelChequeAttachment",
                              file
                            );
                          }}
                        />
                      </div>

                      {/* <label
                  htmlFor="cancelChequeAttachment"
                  className="text-darkgray fw-400 my-3"
                >
                  Cancel Cheque<span className="text-primary ms-1">*</span>
                </label>
                <input
                  className={`form-control py-1`}
                  id="fileUpload"
                  type="file"
                  name="cancelChequeAttachment"
                  accept="image/png, image/heic, image/jpeg, image/jpg"
                  onChange={async (event: any) => {
                    const file = event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : null;

                    if (file) {
                      const base64 = await readFileAsBase64(file);
                      formikProps.setFieldValue(
                        "cancelChequeAttachmentbase64",
                        base64
                      );
                    }

                    formikProps.setFieldValue(
                      "cancelChequeAttachment",
                      file
                    );
                  }}
                /> */}
                    </Col>
                    <Col className="col-md-6 col-12">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Work Order Attachment *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          isError={
                            formikProps.touched.workOrderAttachment &&
                            !!formikProps.errors.workOrderAttachment
                          }
                          error={formikProps.errors.workOrderAttachment}
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "workOrderAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "workOrderAttachment",
                              file
                            );
                          }}
                        />
                      </div>
                      {/* <label
                  htmlFor="workOrderAttachment"
                  className="text-darkgray fw-400 my-3"
                >
                  Work Order<span className="text-primary ms-1">*</span>
                </label>
                <input
                  className={`form-control py-1`}
                  id="fileUpload"
                  type="file"
                  name="workOrderAttachment"
                  accept="image/png, image/heic, image/jpeg, image/jpg"
                  onChange={async (event: any) => {
                    const file = event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : null;

                    if (file) {
                      const base64 = await readFileAsBase64(file);
                      formikProps.setFieldValue(
                        "workOrderAttachmentbase64",
                        base64
                      );
                    }

                    formikProps.setFieldValue("workOrderAttachment", file);
                  }}
                /> */}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-md-12">
                      <div className="d-flex flex-column w-100 py-0">
                        <DefaultInput
                          label="Turnover Declartion for E_Invoice as per the GST Act *"
                          type="file"
                          placeholder="Upload Image"
                          accept="image/png, image/heic, image/jpeg, image/jpg"
                          isError={
                            formikProps.touched.turnOverDeclarationAttachment &&
                            !!formikProps.errors.turnOverDeclarationAttachment
                          }
                          error={
                            formikProps.errors.turnOverDeclarationAttachment
                          }
                          onChange={async (event: any) => {
                            const file = event.currentTarget.files
                              ? event.currentTarget.files[0]
                              : null;

                            if (file) {
                              const base64 = await readFileAsBase64(file);
                              formikProps.setFieldValue(
                                "turnOverDeclarationAttachmentbase64",
                                base64
                              );
                            }

                            formikProps.setFieldValue(
                              "turnOverDeclarationAttachment",
                              file
                            );
                          }}
                        />
                      </div>
                      {/* <label
                  htmlFor="turnOverDeclarationAttachment"
                  className="text-darkgray fw-400 my-3"
                >
                  Turnover Declartion for E_Invoice as per the GST Act
                  <span className="text-primary ms-1">*</span>
                </label>
                <input
                  className={`form-control py-1`}
                  id="fileUpload"
                  type="file"
                  name="turnOverDeclarationAttachment"
                  accept="image/png, image/heic, image/jpeg, image/jpg"
                  onChange={async (event: any) => {
                    const file = event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : null;

                    if (file) {
                      const base64 = await readFileAsBase64(file);
                      formikProps.setFieldValue(
                        "turnOverDeclarationAttachmentbase64",
                        base64
                      );
                    }

                    formikProps.setFieldValue(
                      "turnOverDeclarationAttachment",
                      file
                    );
                  }}
                /> */}
                    </Col>
                  </Row>
                  <div className="row mt-4">
                    <div className="col">
                      <DefaultButton
                        type="submit"
                        variant="outline-primary"
                        className=" default-action-button w-100"
                      >
                        Submit
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </FormData>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default OrganiserSignUpForm2;
