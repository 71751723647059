import { ReactNode } from "react";

type BadgeProps = {
  onClick: () => void;
  className: string;
  children: ReactNode;
};

const Badge = ({ onClick, className, children }: BadgeProps) => {
  return (
    <button
      onClick={onClick}
      className={className}
      style={{ minWidth: "110px" }}
    >
      {children}
    </button>
  );
};

export default Badge;
