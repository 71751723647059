import React, { useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import moment from "moment";

import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";

const EliteRunnerModel = ({
  showNmdcModal,
  handleNmdcClose,
  modalId,
  setIsEliteRunner,
}: any) => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [eliteRunner, setEliteRunner] = useState();
  function padZero(number: number) {
    return number < 10 ? `0${number}` : number;
  }
  const millisecondsToHHMMSS = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    let formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      remainingSeconds
    )}`;
    return formattedTime;
  };
  const handleButtonNmdc = () => {
    handleNmdcClose();
  };
  const handleUpdateEliteRunner = () => {
    setIsLoading(true);

    axiosPrivate({
      url: "/updateEliteRunner",
      data: {
        participantId: modalId.id,
        status: eliteRunner,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          setTimeout(() => {
            setIsEliteRunner((prevState: boolean) => !prevState);
            handleNmdcClose();
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        return;
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        show={showNmdcModal}
        onHide={handleNmdcClose}
        centered
        className="modal-xl"
      >
        <Modal.Header className="border-white px-md-4"></Modal.Header>
        <Modal.Body className="mx-5 scrollbar">
          <Modal.Title className="mb-md-4">
            <Row className="d-flex align-items-center ">
              <Col md={2}>
                <h3> Participant Data</h3>
              </Col>
              <Col className="text-end">
                <DefaultButton
                  type="button"
                  variant="outline-primary"
                  className="default-action-button px-md-5"
                  onClick={handleButtonNmdc}
                >
                  Close
                </DefaultButton>
              </Col>
            </Row>
          </Modal.Title>
          <div className="bg-pink">
            <Row className="d-flex justify-content-between">
              <Col className="col-md-7">
                <Row className="mt-3 d-flex justify-content-between">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">First Name </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalId?.first_name}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7}>
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Last Name </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalId?.last_name}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Email </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalId?.email}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Gender </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">{modalId?.gender}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Mobile No </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {modalId?.mobile_number}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={7} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0">Date of Birth </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0">
                            {moment(modalId?.date_of_birth).format(
                              "DD-MM-YYYY"
                            )}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Payment Details Start */}
            <Row>
              <Col md={5}>
                <h4 className="justify-content-start d-flex mt-3 text-primary">
                  Qualifying Details :
                </h4>
              </Col>
              <Row className="mt-2">
                <Col md={5} className="">
                  <Container className="p-0 m-0">
                    <Row className="d-flex justify-content-start align-items-center mx-0">
                      <Col className="col-md-5 px-0">
                        <Row className="d-flex justify-content-start align-items-center">
                          <Col md="auto">
                            <h6 className="mb-0">Qualifying Event </h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-5 d-flex justify-content-start align-items-center">
                        <span className="fw-bold me-md-3">:</span>
                        <h6 className="ms-2 mb-0">
                          {modalId?.qualifying_event}
                        </h6>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={5} className="">
                  <Container className="p-0 m-0">
                    <Row className="d-flex justify-content-start align-items-center mx-0">
                      <Col className="col-md-5 px-0">
                        <Row className="d-flex justify-content-start align-items-center">
                          <Col md="auto">
                            <h6 className="mb-0">Qualifying Distance </h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-5 d-flex justify-content-start align-items-center">
                        <span className="fw-bold me-md-3">:</span>
                        <h6 className="ms-2 mb-0">
                          {modalId?.qualifying_distance}
                        </h6>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={5} className="">
                  <Container className="p-0 m-0">
                    <Row className="d-flex justify-content-start align-items-center mx-0">
                      <Col className="col-md-5 px-0">
                        <Row className="d-flex justify-content-start align-items-center">
                          <Col md="auto">
                            <h6 className="mb-0">Qualifying Time </h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-5 d-flex justify-content-start align-items-center">
                        <span className="fw-bold me-md-3">:</span>
                        <h6 className="ms-2 mb-0">
                          {millisecondsToHHMMSS(modalId?.qualifying_timing)}
                        </h6>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12}>
                  <h6 className="mb-0">
                    Certificate Link <span className="fw-bold me-md-3">:</span>
                  </h6>
                </Col>
                <Col md={12} className="mt-2">
                  <div
                    className="col-md-12 d-flex  align-items-center"
                    onClick={() =>
                      window.open(
                        modalId?.qualifying_timing_certificate_link,
                        "_blank",
                        "noreferrer"
                      )
                    }
                  >
                    <iframe
                      src={modalId?.qualifying_timing_certificate_link}
                      title="Embedded Content"
                      width="600" // Set the width here
                      height="350"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
          <Row className="my-md-3">
            <Col sm={12}>
              <Form.Label className="my-2 fw-600">
                Mark this participant as an elite runner?{" "}
                <span className="text-primary">*</span>
              </Form.Label>
              <Form.Group className="fw-600">
                <Form.Check
                  inline
                  label="No"
                  name="isGstApplicable"
                  type="radio"
                  defaultChecked={modalId?.is_elite === 0}
                  onChange={(e: any) => setEliteRunner(e.target.value)}
                  value="0"
                />

                <Form.Check
                  inline
                  label="Yes"
                  name="isGstApplicable"
                  type="radio"
                  onChange={(e: any) => setEliteRunner(e.target.value)}
                  defaultChecked={modalId?.is_elite === 1}
                  value="1"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <DefaultButton
              type="button"
              variant="outline-primary"
              className="default-action-button px-md-5"
              onClick={handleUpdateEliteRunner}
            >
              Submit
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(EliteRunnerModel);
