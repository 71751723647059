import React, { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import {
  decryptText,
  itemsPerPageOptions,
  tableCustomStyles,
} from "utils/utils";
import { Col, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Form as FormData, Formik } from "formik";
import { TextField } from "utils/FormControls";
import * as Yup from "yup";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

const ExpoDashboard = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const [expoAdmins, setExpoAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createExpoAdminRoleModel, setCreateExpoAdminRoleModel] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [supportId, setSupportId] = useState(null);
  const [supportEmail, setSupportEmail] = useState(null);
  const [expoAdmin, setExpoAdmin] = useState<any>();
  const [isExpoAdmin, setIsExpoAdmin] = useState<boolean>();
  const [isVerifyEmail, setIsVerifyEmail] = useState<boolean>(true);
  const [verifiedemail, setVerifiedEmail] = useState();
  const [verifiedData, setVerifiedData] = useState<any>();
  const [isEditModel, setIsEditModel] = useState(false);

  function getExpoAdmins() {
    axiosPrivate({
      method: "GET",
      url: "/getSupportExpoRole",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setExpoAdmins(data);
        } else {
          setExpoAdmins([]);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function deleteExpoRole() {
    setIsLoading(true);
    setCreateExpoAdminRoleModel(false);
    axiosPrivate({
      method: "POST",
      data: { expoRoleId: supportId },
      url: "/deleteSupportExpoRole",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
          setTimeout(() => {
            getExpoAdmins();
            handleModalClose();
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setExpoAdmin(null);
      });
  }

  useEffect(() => {
    getExpoAdmins();
  }, []);

  const initialValuesVerify = {
    email: "",
  };

  const validationSchemaVerify = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValuesExistingAdmin = {
    email: verifiedData && verifiedData.email,
    name: verifiedData && verifiedData.name,
  };

  const validationSchemaExistingAdmin = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    name: !expoAdmin ? "" : expoAdmin.name,
    email: !expoAdmin ? verifiedemail : expoAdmin.email,
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
  });
  const handleConfirmButton = (row: any) => {
    setSupportId(row.expo_role_id);
    setShowConfirmModal(true);
    setSupportEmail(row.email);
  };

  const handleEditModelButton = (row: any) => {
    setExpoAdmin(row);
    setCreateExpoAdminRoleModel(true);
    setIsVerifyEmail(false);
    setIsExpoAdmin(false);
    setIsEditModel(true);
  };

  const handleModalClose = () => {
    setShowConfirmModal(false);
  };

  const expoAdminList = [
    {
      name: "Name",
      selector: (row: any) => (row.name ? row.name : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: true,
    },
    {
      name: "Edit",
      width: "150px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleEditModelButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Edit
        </DefaultButton>
      ),
    },
    {
      name: "Delete",
      width: "150px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleConfirmButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Delete
        </DefaultButton>
      ),
    },
  ];

  const handleCloseExpoAdminModel = () => {
    setCreateExpoAdminRoleModel(false);
  };

  const handleCreateExpoAdminRoleSubmit = (values: any) => {
    setIsLoading(true);
    const { name, password, email } = values;
    let postData;
    if (isEditModel) {
      postData = {
        expoUserId: expoAdmin.expo_user_id,
        email: email,
        name: name,
        password: password,
      };
    } else {
      if (isExpoAdmin) {
        postData = {
          eventId: decryptText(String(id)),
          email: email,
          name: name,
          is_expo_admin: "1",
        };
      } else {
        postData = {
          eventId: decryptText(String(id)),
          email: email,
          name: name,
          password: password,
          is_expo_admin: "1",
        };
      }
    }

    axiosPrivate({
      url: `${
        isEditModel ? "/updateSupportExpoRole" : "/createSupportExpoRole"
      }`,
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message, isvalidated } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1000 });
          handleCloseExpoAdminModel();
          setTimeout(() => {
            getExpoAdmins();
          }, 2000);
        } else {
          {
            !expoAdmin
              ? isvalidated
                ? toast.error(`${message}`)
                : Object.keys(res.data.message).forEach((key) => {
                    res.data.message[key].forEach((message: any) => {
                      toast.error(` ${message}`);
                    });
                  })
              : toast.error(`${message}`);
          }

          handleCloseExpoAdminModel();
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setSupportEmail(null);
        setSupportId(null);
        setExpoAdmin(null);
        setIsVerifyEmail(true);
        setIsEditModel(false);
      });
  };

  const verifyemail = (values: any) => {
    setVerifiedEmail(values.email);
    axiosPrivate({
      method: "POST",
      data: { email: values.email },
      url: "/verifyExpoUser",
    })
      .then((res) => {
        const { status, data, isVerified } = res.data;
        if (status) {
          setVerifiedData(data);
          setIsVerifyEmail(false);
          setIsExpoAdmin(isVerified);
        } else {
          setIsVerifyEmail(false);
          setIsExpoAdmin(isVerified);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const CreateExpoAdminModel = () => {
    return (
      <Modal
        show={createExpoAdminRoleModel}
        onHide={handleCloseExpoAdminModel}
        centered
      >
        <Modal.Header className="border-primary px-md-4">
          <Modal.Title>
            {!isVerifyEmail
              ? expoAdmin
                ? "Edit Expo Admin Role"
                : "Create Expo Admin Role"
              : "Verify Email"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" px-md-4 text-center">
          {isVerifyEmail && (
            <Formik
              onSubmit={verifyemail}
              initialValues={initialValuesVerify}
              validationSchema={validationSchemaVerify}
            >
              {({ handleSubmit, handleChange }) => {
                return (
                  <FormData noValidate onSubmit={handleSubmit}>
                    <Col className="col-12 py-md-1">
                      <TextField
                        name="email"
                        type="text"
                        label="Email *"
                        onChange={handleChange}
                      />
                    </Col>
                    <div className="d-flex align-items-center justify-content-end my-md-4">
                      <DefaultButton
                        type="submit"
                        variant="primary"
                        className="default-action-button me-md-3 px-md-5"
                      >
                        Submit
                      </DefaultButton>
                      <DefaultButton
                        variant="outline-primary"
                        className="default-action-button px-md-5"
                        type="button"
                        onClick={() => setCreateExpoAdminRoleModel(false)}
                      >
                        Close
                      </DefaultButton>
                    </div>
                  </FormData>
                );
              }}
            </Formik>
          )}
          {!isVerifyEmail && (
            <>
              {isExpoAdmin ? (
                <Formik
                  onSubmit={handleCreateExpoAdminRoleSubmit}
                  initialValues={initialValuesExistingAdmin}
                  validationSchema={validationSchemaExistingAdmin}
                >
                  {({ handleSubmit, handleChange }) => {
                    return (
                      <FormData noValidate onSubmit={handleSubmit}>
                        {isExpoAdmin && (
                          <div>
                            <p className="mb-4 text-danger fs-14">
                              The email already exists. Please click submit to
                              continue.
                            </p>
                          </div>
                        )}
                        <Col className="col-12 py-md-1">
                          <TextField
                            name="name"
                            type="text"
                            label="Name *"
                            onChange={handleChange}
                            onWheel={(event: any) => event.currentTarget.blur()}
                          />
                        </Col>
                        <Col className="col-12 py-md-1">
                          <TextField
                            name="email"
                            type="text"
                            label="Email *"
                            onChange={handleChange}
                          />
                        </Col>
                        <div className="d-flex align-items-center justify-content-end my-md-4">
                          <DefaultButton
                            type="submit"
                            variant="primary"
                            className="default-action-button me-md-3 px-md-5"
                          >
                            Submit
                          </DefaultButton>
                          <DefaultButton
                            variant="outline-primary"
                            className="default-action-button px-md-5"
                            type="button"
                            onClick={() => setCreateExpoAdminRoleModel(false)}
                          >
                            Close
                          </DefaultButton>
                        </div>
                      </FormData>
                    );
                  }}
                </Formik>
              ) : (
                <Formik
                  onSubmit={handleCreateExpoAdminRoleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, handleChange }) => {
                    return (
                      <FormData noValidate onSubmit={handleSubmit}>
                        <div className="px-0 d-flex flex-column justify-content-center">
                          <Row>
                            <Col className="col-12 py-md-1">
                              <TextField
                                name="name"
                                type="text"
                                label="Name *"
                                onChange={handleChange}
                                onWheel={(event: any) =>
                                  event.currentTarget.blur()
                                }
                              />
                            </Col>
                            <Col className="col-12 py-md-1">
                              <TextField
                                name="email"
                                type="text"
                                label="Email *"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col className="col-12 py-md-1">
                              <TextField
                                name="password"
                                label="Password * "
                                type="password"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col>
                              <TextField
                                name="confirmPassword"
                                label="Confirm Password * "
                                type="password"
                              />
                            </Col>

                            <div className="d-flex align-items-center justify-content-end my-md-4">
                              <DefaultButton
                                type="submit"
                                variant="primary"
                                className="default-action-button me-md-3 px-md-5"
                              >
                                Submit
                              </DefaultButton>
                              <DefaultButton
                                variant="outline-primary"
                                className="default-action-button px-md-5"
                                type="button"
                                onClick={() =>
                                  setCreateExpoAdminRoleModel(false)
                                }
                              >
                                Close
                              </DefaultButton>
                            </div>
                          </Row>
                        </div>
                      </FormData>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  };
  const handleOpenCreateAdminModel = () => {
    setCreateExpoAdminRoleModel(true);
    setIsVerifyEmail(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Row className="mt-4">
          <Col>
            <div className="d-flex justify-content-end">
              <DefaultButton
                onClick={handleOpenCreateAdminModel}
                className="default-action-button text-white ms-3"
                variant="primary"
                type="button"
              >
                Create Expo Admin
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <div className="my-md-4 d-flex justify-content-center align-items-center">
          <div className="mt-md-3 w-75">
            <DataTable
              pagination
              paginationRowsPerPageOptions={itemsPerPageOptions}
              columns={expoAdminList}
              data={expoAdmins}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>
      </div>
      {createExpoAdminRoleModel && <CreateExpoAdminModel />}
      {showConfirmModal && (
        <ConfirmModal
          title="Confirm Role Deletion"
          note={`Are you certain you want to delete the role associated with ${supportEmail}?`}
          showNote={true}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={deleteExpoRole}
        />
      )}
    </>
  );
};

export default ExpoDashboard;
