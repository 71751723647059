import { useEffect } from "react";
import styles from "./Loader.module.scss";

function Loader() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className={`${styles.loader}`}>
      <div
        style={{ height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          className="rounded"
          height={140}
          alt="loader"
          src="/images/Loader.gif"
        />
      </div>
    </div>
  );
}

export default Loader;
