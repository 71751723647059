import React, { memo, useState, useEffect } from "react";
import { Button, Tabs, Tab, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { usePreviewEventRaceList } from "api/PreviewEvent";
import { useUserEventSponsors } from "api/GetEventSponsers";
import { getEventRemainingDays, dateFormatConvertDDMMYYYY } from "utils/utils";
import PriceOptions from "pages/ViewOnlyEventDetailsPage/PriceOptions/PriceOptions";
import { EventDetails } from "components/EventRegistration/EventDetails";
import Disclaimer from "components/EventRegistration/Disclaimer";
import styles from "./PreviewEvent.module.scss";
import Loader from "components/Loader/Loader";
import OfficialSponsors from "components/EventRegistration/OfficialSponsors";
import EventGallery from "components/EventRegistration/EventGallery";

interface PreviewEventProps {
  isOpenPreviewModal: boolean;
  handleClosePreviewModal: () => void;
  eventId: any;
}

const PreviewEvent: React.FC<PreviewEventProps> = ({
  isOpenPreviewModal,
  handleClosePreviewModal,
  eventId,
}) => {
  const [eventRemainingDays, setEventRemainingDays] = useState<number>(0);
  const {
    data: eventRaceList,
    isLoading: eventRaceListLoading,
    error: eventRaceListError,
  } = usePreviewEventRaceList(eventId);

  if (eventRaceListError) {
    toast.error("Error loading event race details. Please try again later.");
  }

  const {
    data: sponsorsList,
    isLoading: sponsorsListLoading,
    error: sponsorsListError,
  } = useUserEventSponsors(eventId);

  const eventDetails = eventRaceList?.data;

  console.log(eventDetails);

  useEffect(() => {
    if (eventRaceList?.data?.event_start_date) {
      setEventRemainingDays(
        +getEventRemainingDays(eventRaceList?.data?.event_start_date)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventRaceList?.data?.event_start_date]);

  if (eventRaceListLoading || sponsorsListLoading) {
    return <Loader />;
  }

  return (
    <Modal
      show={isOpenPreviewModal}
      dialogClassName="customModalWidth"
      centered
      onHide={handleClosePreviewModal}
    >
      <div className={`position-relative`}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "24px" }}>Event Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden">
          <div className="container-fluid position-relative px-0">
            <div className="container-fluid position-relative px-0">
              <div className={`${styles.imageContainer}`}>
                <img
                  className="px-0"
                  src={`${eventDetails?.banner}`}
                  width="100%"
                  alt="Event Banner"
                />
              </div>

              <div className={`${styles.eventsList} mt-4`}>
                <div className={`${styles.organiserPaddingY} px-4`}>
                  <div className="d-none d-sm-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <img
                        height={64}
                        width={64}
                        src={`${
                          eventDetails?.logo ??
                          "/images/defaultorganiserlogo.svg"
                        }`}
                        alt="event organiser"
                      />
                      <div className="ms-3 d-flex flex-column justify-content-between align-items-start">
                        <h1 className="mb-0 fw-bold fs-32 font-saira">
                          {eventDetails?.organiserName}
                        </h1>
                        {/* <p className="fw-600 font-size-base font-saira mb-0">
                          Thane, Maharashtra
                        </p> */}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="message">
                        <img
                          className="d-inline-block"
                          width={22}
                          height={20}
                          src="/images/message.svg"
                          alt="message"
                        />
                      </div>
                      <Button
                        className={`${styles.followButton} ms-4`}
                        variant="outline-primary"
                      >
                        FOLLOW
                      </Button>
                    </div>
                  </div>
                  <div className="d-block d-sm-none">
                    <div className="d-flex align-items-center">
                      <img
                        height={36}
                        width={38}
                        src={`${eventDetails?.logo}`}
                        alt="event organiser"
                      />
                      <div className="ms-3 d-flex flex-column justify-content-between align-items-start">
                        <p className="mb-0 fs-16 fw-600 font-saira">
                          {eventDetails?.organiserName}
                        </p>
                        <p className="fw-400 fs-14 font-noto mb-0">
                          Thane, Maharashtra
                        </p>
                      </div>
                    </div>
                    <Button
                      style={{
                        fontFamily: "saira",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="mt-4 w-100 btn-block"
                      variant="outline-primary"
                    >
                      FOLLOW
                    </Button>
                  </div>
                </div>

                {/* {eventRemainingDays > 0 && (
                  <div className={`${styles.infoBanner} py-2 mb-3`}>
                    <p
                      className={`font-saira text-light fs-16 fw-600 mb-0 text-center glow-spot px-3`}
                    >
                      🎉 Countdown Alert! Join Us in {eventRemainingDays} Day
                      {`${eventRemainingDays > 1 ? "s" : ""}`} (
                      {dateFormatConvertDDMMYYYY(
                        eventDetails?.event_start_date
                      )}
                      ) for an Unforgettable Experience! 🎉
                    </p>
                  </div>
                )} */}

                {eventDetails?.earlyBirdRemaingdatesStatus && (
                  <div className="event-announcement text-center py-1">
                    <h4 className="mb-0 d-inline">
                      Early bird promo ends in...{" "}
                    </h4>
                    <h1 className="mb-0 fw-bold ms-1 d-inline">
                      {eventDetails?.earlyBirdRemaingdates} days
                    </h1>
                  </div>
                )}

                <div className={`${styles.paddingX} mt-4 mb-3 pb-5`}>
                  <Tabs
                    defaultActiveKey={"priceOptions"}
                    id="event-tabs"
                    className="event-nav-tabs border-bottom"
                  >
                    <Tab eventKey="priceOptions" title="Price Options">
                      <PriceOptions
                        events={eventDetails?.tickets}
                        serverTime={eventDetails?.currentDate}
                      />
                    </Tab>
                    <Tab eventKey="eventDetails" title="Event Details">
                      <EventDetails description={eventDetails?.description} />
                    </Tab>
                    <Tab eventKey="gallery" title="Gallery">
                      <EventGallery officalData={eventDetails?.gallery} />
                    </Tab>
                    <Tab eventKey="officialSponsors" title="Official Sponsors">
                      {sponsorsList.status ? (
                        <OfficialSponsors officalData={sponsorsList?.data} />
                      ) : (
                        <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                          No Officical Sponsors
                        </p>
                      )}
                    </Tab>
                    <Tab eventKey="disclaimer" title="Disclaimer">
                      <Disclaimer disclaimer={eventDetails?.disclaimer} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default memo(PreviewEvent);
