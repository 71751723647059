import React, { useEffect, useState } from "react";
import { Row, CardBody, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
// import { AgChartsReact } from "ag-charts-react";
import { useAuth } from "context/AuthContext";
import moment from "moment";
import { useEvent } from "context/EventContext";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useAccount } from "context/AccountContext";
import { OrgRoleEnum } from "types/orgRoleEnum";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const HomeTabScreen = () => {
  const { companyAccount } = useAccount();
  const adminAccount = companyAccount === OrgRoleEnum.Admin;
  // const Approver = companyAccount === OrgRoleEnum.Approver;
  const isSuperAdmin = companyAccount === OrgRoleEnum.Superadmin;
  // const isViewer = companyAccount === OrgRoleEnum.Viewer;

  const { storeEventId } = useEvent();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [isTotalLoading, setIsTotalLoading] = useState(false);
  const [isSelect, setIsSelect] = useState<any>([]);
  const { id } = useParams();
  const eventId = decryptText(String(id));

  const [isCount, setIsCount] = useState<any>([]);
  const [genderPieData, setGenderPieData] = useState<any>([]);
  // const [genderBarData, setGenderBarData] = useState<any>();
  const [isParticipantsLists, setIsParticipantsLists] = useState([]);
  const [raceRegistrationData, setRaceRegistrationData] = useState<any>([]);
  const [raceRegistrationLabelData, setRaceRegistrationLabelData] =
    useState<any>([]);
  const [raceRegistrationMaleData, setRaceRegistrationMaleData] = useState<any>(
    []
  );
  const [raceRegistrationGenderLabelData, setRaceRegistrationGenderLabelData] =
    useState<any>([]);
  const [raceRegistrationFemaleData, setRaceRegistrationFemaleData] =
    useState<any>([]);
  const [raceRegistrationOtherData, setRaceRegistrationOtherData] =
    useState<any>([]);
  const [totalRaceRegistrationData, setTotalRaceRegistrationData] =
    useState<any>([]);
  const [participantCount, setParticipantCounts] = useState<any>();
  const [participantData, setParticipantData] = useState<any>([]);
  const [isRaceType, setIsRaceType] = useState(-1);
  const [participantPhotoCount, setParticipantPhotoCounts] = useState<any>();
  const [participantAgeCount, setParticipantAgeCounts] = useState<any>();

  // const [participantMonthlyCount, setParticipantMonthlyCounts] =
  //   useState<any>();
  const [
    raceRegistrationMonthlyLabelData,
    setRaceRegistrationMonthlyLabelData,
  ] = useState<any>([]);
  const [raceRegistration5kData, setRaceRegistration5KData] = useState<any>([]);
  const [raceRegistration10KData, setRaceRegistration10KData] = useState<any>(
    []
  );
  const [
    raceRegistrationHalfMarathonData,
    setRaceRegistrationHalfMarathonData,
  ] = useState<any>([]);
  const [raceRegistrationMarathonData, setRaceRegistrationMarathonData] =
    useState<any>([]);
  const [tshirtData, setTshirtData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [corporateCoupons, setCorporateCoupons] = useState<any>([]);
  const [dataCount, setDataCount] = useState([]);
  //logic based on No of registartion based on date

  const dates = isParticipantsLists.map((data: any) => {
    const { created_at } = data;
    const utcMoment = moment.utc(created_at);
    const istMoment = utcMoment.utcOffset("+05:30");
    const dateInIST = istMoment.format("YYYY-MM-DD");
    return dateInIST;
  });

  const countOccurrences: any = {};
  dates.forEach((date: any) => {
    countOccurrences[date] = (countOccurrences[date] || 0) + 1;
  });

  const RaceName = isParticipantsLists.map((data: any) => {
    const { raceName } = data;
    return raceName;
  });

  const raceOccurrences: any = {};
  RaceName.forEach((data: any) => {
    raceOccurrences[data] = (raceOccurrences[data] || 0) + 1;
  });

  function EventCounts() {
    const postData = { eventId: eventId };
    axiosPrivate({
      url: "/eventCounts",
      data: postData,
      method: "POST",
    })
      .then((res: any) => {
        const { status, data } = res.data;
        if (status) {
          setIsCount(data);
          setGenderPieData([data.online?.Count, data.offline?.Count]);
          // setGenderBarData([data.online.maleCount, data.online.femaleCount]);
          setIsLoading(true);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function eventRaceCountsList() {
    const postData = { eventId: eventId };
    axiosPrivate({
      url: "/eventRaceCounts",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        let arrayLabelData: any[] = [];
        let arrayData: any[] = [];
        if (status) {
          // eslint-disable-next-line array-callback-return
          data.map((items: any) => {
            arrayLabelData.push(items.raceName);
            arrayData.push(items.raceCount);
          });

          setRaceRegistrationLabelData(arrayLabelData);
          setRaceRegistrationData(arrayData);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function eventRaceGenderCountsList() {
    const postData = { eventId: eventId };
    axiosPrivate({
      url: "/eventRaceGenderCounts",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        let arrayLabelData: any[] = [];
        let arrayMaleData: any[] = [];
        let arrayFeMaleData: any[] = [];
        let arrayOtherData: any[] = [];
        if (status) {
          setTotalRaceRegistrationData(data);

          // eslint-disable-next-line array-callback-return
          data.map((items: any) => {
            arrayLabelData.push(items.ticketName);
            arrayMaleData.push(items.male);
            arrayFeMaleData.push(items.female);
            arrayOtherData.push(items.other);
          });
          setIsTotalLoading(true);
          setRaceRegistrationGenderLabelData(arrayLabelData);
          setRaceRegistrationMaleData(arrayMaleData);
          setRaceRegistrationFemaleData(arrayFeMaleData);
          setRaceRegistrationOtherData(arrayOtherData);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardParticipantsCount() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/getDashboardParticipantsCount",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setParticipantCounts(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardPhotoCount() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/photosCount",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setParticipantPhotoCounts(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardAgeCount() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/ageCategoryCount",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setParticipantAgeCounts(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardMonthlyRegistrationCount() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/monthlyRegistrationCount",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        let arrayLabelData: any[] = [];
        let array5KData: any[] = [];
        let array10KData: any[] = [];
        let arrayHalfMarathonData: any[] = [];
        let arrayMarathonData: any[] = [];
        if (status) {
          // eslint-disable-next-line array-callback-return
          data.map((items: any) => {
            arrayLabelData.push(items.month);
            array5KData.push(items.data["5K"]);
            array10KData.push(items.data["10K"]);
            arrayHalfMarathonData.push(items.data["HALF MARATHON"]);
            arrayMarathonData.push(items.data["MARATHON"]);
          });

          setIsTotalLoading(true);
          setRaceRegistrationMonthlyLabelData(arrayLabelData);
          setRaceRegistration5KData(array5KData);
          setRaceRegistration10KData(array10KData);
          setRaceRegistrationHalfMarathonData(arrayHalfMarathonData);
          setRaceRegistrationMarathonData(arrayMarathonData);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardTshirtCount() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/tshirtCount",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setTshirtData(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getDashboardPayment() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/paymentStatistics",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setPaymentData(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function getCorporateCoupons() {
    const postData = {
      eventId: eventId || storeEventId,
    };
    axiosPrivate({
      url: "/getCorporateCoupons",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;

        if (status) {
          setCorporateCoupons(data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { data } = res.data;
        const racesList: any = data?.filter((data: any) => data.raceId !== "0");
        setIsSelect(racesList);
      })
      .catch((err) => {
        console.log("err", err);
        return;
      });
  }

  function expoCollectedTshirtsBibs() {
    axiosPrivate({
      url: "/viewExpoReports",
      data: {
        eventId: decryptText(String(id)),
      },
      method: "POST",
    })
      .then((response: any) => {
        const { status, message, data, token } = response.data;
        if (status) {
          setDataCount(data);
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    EventCounts();
    eventRaceCountsList();
    eventRaceGenderCountsList();
    CouponRaceList();
    getDashboardParticipantsCount();
    getDashboardPhotoCount();
    getDashboardAgeCount();
    getDashboardMonthlyRegistrationCount();
    getDashboardTshirtCount();
    getDashboardPayment();
    getCorporateCoupons();
    expoCollectedTshirtsBibs();
  }, [eventId]);

  const dataRech = {
    labels: ["Online", "Offline"],
    datasets: [
      {
        label: "",
        data: genderPieData,
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 0,
      },
    ],
  };

  const pieoptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
        font: {
          size: 16,
          family: "Saira",
        },
      },
    },
  };

  const barChartOptions = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "No of Registrations per race",
        font: {
          size: 16,
          family: "Saira",
        },
      },
    },
    indexAxis: "x" as const,
    elements: {
      bar: {
        borderWidth: 0,
        barPercentage: 0.2,
      },
    },
  };

  const barChartGenderOptions = {
    responsive: true,

    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "Gender Breakdown Per Race",
        font: {
          size: 16,
          family: "Saira",
        },
      },
    },
    indexAxis: "x" as const,
    elements: {
      bar: {
        borderWidth: 0,
        barPercentage: 0.2,
      },
    },
  };

  const barChartGenderData = {
    labels: raceRegistrationGenderLabelData,
    datasets: [
      {
        data: raceRegistrationMaleData,
        backgroundColor: ["rgb(255, 99, 132)"],
        barThickness: 40,
        label: "Male",
      },
      {
        data: raceRegistrationFemaleData,
        backgroundColor: ["rgb(54, 162, 235)"],
        barThickness: 40,
        label: "Female",
      },
      {
        data: raceRegistrationOtherData,
        backgroundColor: ["rgb(255, 94, 5)"],
        barThickness: 40,
        label: "Other",
      },
    ],
  };

  const barChartMonthlyOptions = {
    responsive: true,

    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "No of Registrations per month",
        font: {
          size: 16,
          family: "Saira",
        },
      },
    },
    indexAxis: "x" as const,
    elements: {
      bar: {
        borderWidth: 0,
        barPercentage: 0.5,
      },
    },
  };

  const barChartMonthlyData = {
    labels: raceRegistrationMonthlyLabelData,
    datasets: [
      {
        data: raceRegistration5kData,
        backgroundColor: ["rgb(255, 99, 132)"],
        barThickness: 40,
        label: "5K",
      },
      {
        data: raceRegistration10KData,
        backgroundColor: ["rgb(54, 162, 235)"],
        barThickness: 40,
        label: "10K",
      },
      {
        data: raceRegistrationHalfMarathonData,
        backgroundColor: ["rgb(255, 94, 5)"],
        barThickness: 40,
        label: "Half Marathon",
      },
      {
        data: raceRegistrationMarathonData,
        backgroundColor: ["rgb(106, 161, 33)"],
        barThickness: 40,
        label: "Marathon",
      },
    ],
  };

  const barChartDataNoofRegistrationsperrace = {
    labels: raceRegistrationLabelData,
    datasets: [
      {
        data: raceRegistrationData,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 94, 5)",
          "rgb(106, 161, 33)",
        ],
        barThickness: 40,
      },
    ],
  };

  const initalParticipantCount: any =
    participantCount &&
    participantCount?.filter((data: any) => data.raceName === "All");

  const handleSelectOption = (e: any) => {
    const selectedRaceId: number = parseInt(e.target.value);
    setIsRaceType(selectedRaceId);

    if (selectedRaceId === -1) {
      const allParticipantCount = participantCount.filter(
        (data: any) => data.raceName === "All"
      );
      setParticipantData(allParticipantCount);
    } else {
      const IndividualParticipantCount = participantCount.filter(
        (data: any) => data.raceId === selectedRaceId
      );
      setParticipantData(IndividualParticipantCount);
    }
  };

  const CardComponent = ({ title, value, Img }: any) => {
    return (
      <Card className="card info-card sales-card border-tagBlue">
        <Card.Body className="card-body">
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text>
            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center my-md-2">
                <img src={Img} alt="Icon" width="44px" />
              </div>
              <div className="ps-3">
                <h6>{value}</h6>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  const moneyFormat = (number: any) => {
    const value = number.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return value;
  };
  const totals = {
    tshirt: 0,
    bibs: 0,
    both: 0,
    none: 0,
    count: 0,
  };

  // Calculate totals
  dataCount.forEach((item: any) => {
    totals.tshirt += item.tshirt;
    totals.bibs += item.bibs;
    totals.both += item.both;
    totals.none += item.none;
    totals.count += item.count;
  });
  return (
    <>
      <Row>
        {/* total Registration count both offline and online Start*/}
        <Col md={4}>
          <div className="p-md-5 border border-tagBlue">
            <>
              <Doughnut data={dataRech} options={pieoptions} />

              <Row className="mt-2">
                <Col>
                  <h4>Online</h4>
                  <h4>{isCount.online?.Count || 0}</h4>
                </Col>
                <Col>
                  <h4>Offline</h4>
                  <h4>{isCount.offline?.Count || 0}</h4>
                </Col>
                <Col>
                  <h4>Total</h4>
                  <h4>
                    {(isCount.online?.Count || 0) +
                      (isCount.offline?.Count || 0)}
                  </h4>
                </Col>
              </Row>
            </>
          </div>
        </Col>
        {/* total Registration count both offline and online end*/}
        {/* total Registration per race count both offline and online along  with status Start*/}
        <Col md={8} className="border border-tagBlue rounded">
          <Card className="border border-white">
            <CardBody className="px-md-3">
              <Row className="d-flex justify-content-end my-md-2">
                <Col className="col-md-6">
                  <div>
                    <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                      <div className="col">
                        <select
                          onChange={handleSelectOption}
                          value={isRaceType}
                        >
                          <option value="-1">All</option>
                          {isSelect?.map((option: any) => {
                            return (
                              <>
                                <option
                                  key={option.raceId}
                                  value={option.raceId}
                                  disabled={option.status}
                                  onChange={option.raceId}
                                >
                                  {option.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <span>Please select the race *</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {isRaceType === -1 ? (
                <>
                  {initalParticipantCount &&
                    initalParticipantCount?.map((race: any) => (
                      <>
                        <Row className="mt-md-4">
                          <Col>
                            <CardComponent
                              title="Approved"
                              value={race.approve}
                              Img="/images/approved.svg"
                            />
                          </Col>
                          <Col>
                            <CardComponent
                              title="Pending"
                              value={race.pending}
                              Img="/images/pending.svg"
                            />
                          </Col>
                        </Row>
                        <Row className="my-md-3">
                          <Col>
                            <CardComponent
                              title="Rejected"
                              value={race.rejected}
                              Img="/images/rejected.svg"
                            />
                          </Col>
                          <Col>
                            <CardComponent
                              title="More Information"
                              value={race.moreinfo}
                              Img="/images/moreinfo.svg"
                            />
                          </Col>
                        </Row>
                      </>
                    ))}
                </>
              ) : (
                <>
                  {participantData &&
                    participantData?.map((race: any) => (
                      <>
                        <Row className="mt-md-4">
                          <Col>
                            <CardComponent
                              title="Approved"
                              value={race.approve}
                              Img="/images/approved.svg"
                            />
                          </Col>
                          <Col>
                            <CardComponent
                              title="Pending"
                              value={race.pending}
                              Img="/images/pending.svg"
                            />
                          </Col>
                        </Row>
                        <Row className="my-md-3">
                          <Col>
                            <CardComponent
                              title="Rejected"
                              value={race.rejected}
                              Img="/images/rejected.svg"
                            />
                          </Col>
                          <Col>
                            <CardComponent
                              title="More Information"
                              value={race.moreinfo}
                              Img="/images/moreinfo.svg"
                            />
                          </Col>
                        </Row>
                      </>
                    ))}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* total Registration per race count both offline and online along with status end*/}
      </Row>

      {/* Payment Details  start*/}
      {isTotalLoading && paymentData && (isSuperAdmin || adminAccount) && (
        <>
          <Row className="my-4 border border-tagBlue rounded">
            <h4 className="justify-content-center d-flex my-4">
              Payment Details
            </h4>
            <Col>
              <Row className="mt-md-4">
                <Col>
                  <CardComponent
                    title="Total Amount"
                    value={moneyFormat(paymentData.totalAmount ?? 0)}
                    Img="/images/total.svg"
                  />
                </Col>
                <Col>
                  <CardComponent
                    title="Individual Amount"
                    value={moneyFormat(paymentData.individualAmount ?? 0)}
                    Img="/images/individual.svg"
                  />
                </Col>
                <Col>
                  <CardComponent
                    title="Bulk Amount"
                    value={moneyFormat(paymentData.bulkAmount ?? 0)}
                    Img="/images/bulkamount.svg"
                  />
                </Col>
              </Row>
              <Row className="my-md-3">
                <Col>
                  <CardComponent
                    title="Charity Amount"
                    value={moneyFormat(paymentData.charityAmount ?? 0)}
                    Img="/images/charity.svg"
                  />
                </Col>
                <Col>
                  <CardComponent
                    title="Photos Amount"
                    value={moneyFormat(paymentData.photosAmount ?? 0)}
                    Img="/images/photography_amount.svg"
                  />
                </Col>
                <Col>
                  <CardComponent
                    title="Charity Bib Amount"
                    value={moneyFormat(paymentData.charityBibAmount ?? 0)}
                    Img="/images/charity_bib.svg"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {/* Payment Details  end*/}

      {/* Pre Order Photos according to race start */}
      {isTotalLoading && participantPhotoCount && (
        <Row className="my-4 border border-tagBlue rounded p-2">
          <h4 className="justify-content-center d-flex my-4">
            Pre Order Photos
          </h4>
          <table className="table table-borderless header-fixed">
            <thead className="table-primary">
              <tr>
                <th className="text-start">Race Name</th>
                <th className="text-center">5K</th>
                <th className="text-center">10K</th>
                <th className="text-center">HALF MARATHON</th>
                <th className="text-center">MARATHON</th>
                <th className="text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">Registered</td>
                <td className="text-center">{participantPhotoCount.count5K}</td>
                <td className="text-center">
                  {participantPhotoCount.count10K}
                </td>
                <td className="text-center">
                  {participantPhotoCount["countHALF MARATHON"]}
                </td>
                <td className="text-center">
                  {participantPhotoCount.countMARATHON}
                </td>
                <td className="text-center">
                  {participantPhotoCount.countMARATHON +
                    participantPhotoCount.count5K +
                    participantPhotoCount.count10K +
                    participantPhotoCount["countHALF MARATHON"]}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      )}
      {/* Pre Order Photos according to race  end */}

      {/* total registration according to race  start */}
      {isTotalLoading && totalRaceRegistrationData && (
        <Row className="my-4 me-1 border border-tagBlue rounded p-2">
          <h4 className="justify-content-center d-flex my-4">
            Total Registrations
          </h4>
          <table className="table table-borderless header-fixed">
            <thead className="table-primary">
              <tr>
                <th>Race Name</th>
                <th>Male</th>
                <th>Female</th>
                <th>Other</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {totalRaceRegistrationData.map((item: any) => {
                return (
                  <tr>
                    <td>{item.ticketName}</td>
                    <td>{item.male}</td>
                    <td>{item.female}</td>
                    <td>{item.other}</td>
                    <td>{item.other + item.male + item.female}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      )}
      {/* total registration according to race  end */}
      {/* Breakup Category  according race  to their age category start*/}
      {isTotalLoading && participantAgeCount && (
        <Row className="my-4 me-1 border border-tagBlue rounded p-2">
          <h4 className="justify-content-center d-flex my-4">
            Breakup Category
          </h4>
          <table className="table table-borderless header-fixed">
            <thead className="table-primary">
              <tr>
                <th>Race Name</th>
                <th>below 34 Years</th>
                <th>35-44 Years</th>
                <th>45-54 Years</th>
                <th>55-64 Years</th>
                <th>65+ Years</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {participantAgeCount.map((item: any, key: string) => {
                return (
                  <tr>
                    <td>{item.raceName}</td>
                    <td>{item[Object.keys(item)[0]]}</td>
                    <td>{item["35-44 Years"]}</td>
                    <td>{item["45-54 Years"]}</td>
                    <td>{item["55-64 Years"]}</td>
                    <td>{item["65+ Years"]}</td>
                    <td>
                      {item[Object.keys(item)[0]] +
                        item["35-44 Years"] +
                        item["45-54 Years"] +
                        item["55-64 Years"] +
                        item["65+ Years"]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      )}
      {/* Breakup Category  according race   to their age category end*/}

      {/* tshirts count according to size and race start  */}
      {isTotalLoading && tshirtData && (
        <Row className="my-4 me-1 border border-tagBlue rounded p-2">
          <h4 className="justify-content-center d-flex my-4">T Shirt</h4>
          <table className="table table-borderless header-fixed">
            <thead className="table-primary">
              <tr>
                <th>Race Name</th>
                <th colSpan={2} className="text-center">
                  XS
                </th>
                <th colSpan={2} className="text-center">
                  S
                </th>
                <th colSpan={2} className="text-center">
                  M
                </th>
                <th colSpan={2} className="text-center">
                  L
                </th>
                <th colSpan={2} className="text-center">
                  XL
                </th>
                <th colSpan={2} className="text-center">
                  XXL
                </th>
                <th colSpan={2} className="text-center">
                  XXXL
                </th>
                <th colSpan={2}>Total</th>
              </tr>

              <tr>
                <th></th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray text-center">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray text-center">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray text-center">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray text-center">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray text-center">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray border-end">
                  Female
                </th>
                <th className="border-start border-lightgray text-center">
                  Male
                </th>
                <th className="border-start border-lightgray border-end">
                  Female
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tshirtData.map((item: any) => {
                return (
                  <tr>
                    <td>{item.raceName}</td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XS.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XS.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.S.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.S.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.M.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.M.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.L.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.L.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XL.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XL.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XXL.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XXL.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XXXL.Male}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XXXL.Female}
                    </td>
                    <td className="border-start border-lightgray text-center">
                      {item.data.XS.Male +
                        item.data.XS.Female +
                        item.data.S.Male +
                        item.data.S.Female +
                        item.data.M.Male +
                        item.data.M.Female +
                        item.data.L.Male +
                        item.data.L.Female +
                        item.data.XL.Male +
                        item.data.XL.Female +
                        item.data.XXL.Male +
                        item.data.XXL.Female +
                        item.data.XXXL.Male +
                        item.data.XXXL.Female}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      )}
      {/* tshirts count according to size and race end  */}

      {/* tshirts and Bibs count start */}
      <Row className="my-4 me-1 border border-tagBlue rounded p-2">
        <h4 className="justify-content-center d-flex my-4">
          Expo T-Shirt and Bibs Collection Count
        </h4>
        <table className="table table-bordered">
          <thead className="thead-dark ">
            <tr>
              <th scope="col">Race Name</th>
              <th scope="col" className="text-center">
                Only T-shirt
              </th>
              <th scope="col" className="text-center">
                Only Bibs
              </th>
              <th scope="col" className="text-center">
                Both Collected
              </th>
              <th scope="col" className="text-center">
                None
              </th>
              <th scope="col" className="text-center">
                Total Count
              </th>
            </tr>
          </thead>
          <tbody>
            {dataCount.map((item: any, index: number) => {
              return (
                <>
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td className="text-center">{item.tshirt}</td>
                    <td className="text-center">{item.bibs}</td>
                    <td className="text-center">{item.both}</td>
                    <td className="text-center">{item.none}</td>
                    <td className="text-center">{item.count}</td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td className="text-center">
                <strong>{totals.tshirt}</strong>
              </td>
              <td className="text-center">
                <strong>{totals.bibs}</strong>
              </td>
              <td className="text-center">
                <strong>{totals.both}</strong>
              </td>
              <td className="text-center">
                <strong>{totals.none}</strong>
              </td>
              <td className="text-center">
                <strong>{totals.count}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      {/* tshirts and Bibs count end */}

      {/* no of registration per month start */}
      <Row className="my-4 me-1">
        <Col md={12} className="border border-tagBlue rounded">
          <div className="p-5">
            <div>
              <Bar
                height={90}
                options={barChartMonthlyOptions}
                data={barChartMonthlyData}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* no of registration per month end */}
      {/* no of registration per race start */}
      <Row className="my-4 me-1">
        <Col md={12} className="border border-tagBlue rounded">
          <div className="p-5">
            <div>
              <Bar
                height={90}
                options={barChartOptions}
                data={barChartDataNoofRegistrationsperrace}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* no of registration per race end */}
      {/* gender breakdown registration per race start */}
      <Row className="my-4">
        <Col md={8} className="border border-tagBlue rounded">
          <div className="p-5" style={{ height: 480 }}>
            {/* <AgChartsReact options={barOptions} /> */}
            {/* <Bar options={barChartOptions} data={barChartData} /> */}
            <Bar options={barChartGenderOptions} data={barChartGenderData} />
          </div>
        </Col>
        <Col md={4} className="">
          {isLoading && (
            <div
              className="p-5 border border-tagBlue rounded "
              style={{ height: 480 }}
            >
              <div className="align-items-end justify-content-center d-flex mb-5 h-50">
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="justify-content-center d-flex">
                      {isCount.online.maleCount}
                    </h4>
                    <h4 className="justify-content-center d-flex">MALE</h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="justify-content-center d-flex">
                      {isCount.online.femaleCount}
                    </h4>
                    <h4 className="justify-content-center d-flex">FEMALE</h4>
                  </Col>
                </Row>
              </div>
              <div className="h-50 align-items-start justify-content-center d-flex">
                <Row className="">
                  <h3 className="justify-content-center d-flex">
                    {isCount.online.Count}
                  </h3>
                  <h4 className="justify-content-center d-flex">TOTAL</h4>
                </Row>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {/* gender breakdown registration per race end */}

      {/* corporateCoupons start */}
      <Row className="my-4 me-1 border border-tagBlue rounded p-2">
        <h4 className="justify-content-center d-flex my-4">
          Corporate Coupon Report
        </h4>
        <table className="table table-borderless header-fixed">
          <thead className="table-primary">
            <tr>
              <th>Contact Name</th>
              <th>Short Code</th>
              <th>Coupons Issued</th>
              <th>Coupons Used</th>
              <th>Coupons Remaining</th>
              <th>Valid From</th>
              <th>Valid To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bold">Total</td>
              <td>—</td>
              <td className="fw-bold">{corporateCoupons.totalCoupons}</td>
              <td className="fw-bold">{corporateCoupons.totalCouponsUsed}</td>
              <td className="fw-bold">
                {corporateCoupons.totalCouponsRemaining}
              </td>
              <td>—</td>
              <td>—</td>
            </tr>
            {corporateCoupons.coupons_data?.map((item: any) => {
              return (
                <tr>
                  <td style={{ width: "250px" }}>
                    {item.company_contact_person}
                  </td>
                  <td>{item.coupon_prefix}</td>
                  <td>{item.no_of_coupons}</td>
                  <td>{item.no_of_coupons_used}</td>
                  <td>{item.couponsRemaining}</td>
                  <td>{item.valid_from}</td>
                  <td>{item.valid_to}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Row>
      {/* corporateCoupons end */}
    </>
  );
};

export default HomeTabScreen;
