import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container, Tabs, Tab, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useEvent } from "context/EventContext";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import ReadMore from "utils/ReadMore";
import useOrganizer from "utils/useOrganizer";
import { OrgRoleEnum } from "types/orgRoleEnum";
import { encryptText } from "utils/utils";
import { useAccount } from "context/AccountContext";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const EventsPage = () => {
  let Navigate = useNavigate();
  const [isCardData, setIsCardData] = useState<any>([]);
  const [isPastCardData, setIsPastCardData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string | null>("activeEvents");
  const [isLoading, setIsLoading] = useState(false);
  const [organisationName, setOrganisationName] = useState("");
  const { storeAccountId, setEventId, setRaceId } = useEvent();
  const { isSuperAdmin } = useOrganizer();
  const { updateAccount } = useAccount();
  const axiosPrivate = useAxiosPrivate();
  function eventList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/eventList",
      data: {
        accountId: storeAccountId,
      },
      method: "POST",
    })
      .then((res) => {
        setIsCardData(res.data.data);

        if (res.data.data) {
          setOrganisationName(res.data.data[0].orgName);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  function PastEvent() {
    setIsLoading(true);
    axiosPrivate({
      url: "/pastEventList",
      data: {
        accountId: storeAccountId,
      },
      method: "POST",
    })
      .then((res) => {
        setIsPastCardData(res.data.data);
        if (res.data.data) {
          setOrganisationName(res.data.data[0].orgName);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (activeTab === "activeEvents") {
      eventList();
    } else if (activeTab === "pastEvents") {
      PastEvent();
    }
  }, [activeTab]);

  const handleRoleBasedClick = (
    role: string,
    past: boolean,
    Card_id: string
  ) => {
    if (!past) {
      updateAccount(role);
      if (role === OrgRoleEnum.Admin) {
        Navigate(
          `/org/${!past ? "eventDashBoard" : "viewevent"}/${encryptText(
            Card_id
          )}`
        );
      } else if (role === OrgRoleEnum.CouponAdmin) {
        Navigate(
          `/org/${!past ? "coupondashboard" : "viewevent"}/${encryptText(
            Card_id
          )}`
        );
      } else if (role === OrgRoleEnum.Viewer) {
        Navigate(
          `/org/${!past ? "viewevent" : "viewevent"}/${encryptText(Card_id)}`
        );
      } else if (role === OrgRoleEnum.Superadmin) {
        Navigate(
          `/org/${!past ? "eventDashBoard" : "viewevent"}/${encryptText(
            Card_id
          )}`
        );
      } else if (role === OrgRoleEnum.Approver) {
        Navigate(
          `/org/${!past ? "registration" : "viewevent"}/${encryptText(Card_id)}`
        );
      } else if (role === OrgRoleEnum.Editor) {
        Navigate(
          `/org/${!past ? "eventDashBoard" : "viewevent"}/${encryptText(
            Card_id
          )}`
        );
      } else if (role === OrgRoleEnum.CharityAdmin) {
        Navigate(
          `/org/${!past ? "charitydashboard" : "viewevent"}/${encryptText(
            Card_id
          )}`
        );
      } else if (role === OrgRoleEnum.CouponUser) {
        Navigate(
          `/org/${!past ? "couponuser" : "viewevent"}/${encryptText(Card_id)}`
        );
      } else if (role === OrgRoleEnum.CharityDashboard) {
        Navigate(
          `/org/${!past ? "charity" : "viewevent"}/${encryptText(Card_id)}`
        );
      } else if (role === OrgRoleEnum.EliteRunnerAdmin) {
        Navigate(`/org/eliterunner/${encryptText(Card_id)}`);
      }
    } else {
      role = "";
      updateAccount(role);
      Navigate(`/org/viewevent/${encryptText(Card_id)}`);
    }
  };

  const EventCard = ({
    role,
    Card_id,
    imageSrc1,
    imageSrc2,
    organizer,
    eventName,
    location,
    Startdate,
    Enddate,
    past,
  }: any) => {
    return (
      <Card
        style={{ width: "348px", cursor: "pointer" }}
        onClick={() => handleRoleBasedClick(role, past, Card_id)}
        // onClick={() =>
        //   Navigate(
        //     `/org/${!past ? "eventDashBoard" : "pastEventDashBoard"}/${Card_id}`
        //   )
        // }
      >
        <Card.Img
          variant="top"
          src={imageSrc1 ?? "/images/cardBackground.png"}
        />
        {!past && (
          <span
            className="bg-primary fs-12 ff-ns text-white px-2 py-1 fw-500"
            style={{
              position: "absolute",
              borderRadius: "4px",
              right: "30px",
              top: "182px",
            }}
          >
            {role}
          </span>
        )}
        <Card.Body className="px-md-4 flex-grow-1 m-md-0 pb-md-2">
          <div className="d-flex align-items-center py-md-2">
            <div className="p-0 m-0">
              <img
                style={{ height: 40, width: 40 }}
                className="rounded-2 m-0 p-0"
                alt={imageSrc2}
                src={imageSrc2 ?? "/Images/IfiinishOrgDefaultLogo.svg"}
              />
            </div>
            <div className="d-flex align-items-center">
              <h5 className="text-uppercase fw-600 ms-3 mt-2">{organizer}</h5>
            </div>
          </div>
          <div>
            <h1
              className={`fw-600 ${
                eventName.length > 23 && eventName.length < 31 && " fs-20"
              }`}
            >
              {eventName && eventName.length > 30 ? (
                <ReadMore eventName={eventName} />
              ) : (
                `${eventName}`
              )}
            </h1>
            {/* <h1 className={`fw-600 ${eventName.length > 23 && " fs-20"}`}>
              {eventName}
            </h1> */}
            <h6 className="fw-600">{location}</h6>
          </div>

          <div className=" d-flex flex-column align-items-start justify-content-center ">
            <div className="d-flex justify-content-between w-100">
              <div className="p-0 m-0">
                <h6 className="fw-normal">
                  <span>Start Date: </span>
                  {Startdate}
                </h6>
                <h6 className="fw-normal">
                  <span>End Date: </span>
                  {Enddate}
                </h6>
              </div>
              {/* <div className="d-flex align-items-center mt-md-2">
                <div
                  className="bg-primary text-white p-2 rounded-1 d-flex align-items-center text-uppercase"
                  style={{ height: "24px" }}
                >
                  <span>Clone Event</span>
                </div>
              </div> */}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container className="mt-md-4">
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => Navigate("/org/accounts")}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Organisations
              </span>
            </div>
            <h6 className="fs-24 mt-md-2 ms-2 text-uppercase">
              {organisationName}
            </h6>
          </Col>

          <Col>
            {isSuperAdmin && (
              <div className="d-flex justify-content-end ">
                <button
                  className="px-md-5 py-md-2 btn btn-primary text-white"
                  onClick={() => {
                    Navigate("/org/createEvents");
                    setEventId(null);
                    setRaceId(null);
                  }}
                >
                  Create Event
                </button>
              </div>
            )}
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={"activeEvents"}
          id="event-tabs"
          className="event-nav-tabs"
          onSelect={handleSelectTab}
        >
          <Tab eventKey="activeEvents" title="Active Events">
            {isCardData?.length > 0 ? (
              <Row>
                {isCardData?.map((card: any) => {
                  const startparsedDate = new Date(card.event_start_date);
                  const startday = String(startparsedDate.getDate()).padStart(
                    2,
                    "0"
                  );
                  const startmonth = String(
                    startparsedDate.getMonth() + 1
                  ).padStart(2, "0");
                  const startyear = startparsedDate.getFullYear();
                  const EndparsedDate = new Date(card.event_end_date);
                  const endday = String(EndparsedDate.getDate()).padStart(
                    2,
                    "0"
                  );
                  const endmonth = String(
                    EndparsedDate.getMonth() + 1
                  ).padStart(2, "0");
                  const endyear = EndparsedDate.getFullYear();
                  return (
                    <Col key={card.id} md={4} className="my-md-2">
                      <EventCard
                        role={card.roleName}
                        Card_id={card.id}
                        imageSrc1={card.image}
                        imageSrc2={card.logo}
                        eventName={card.event_name}
                        organizer={card.orgName}
                        location={card.location}
                        Startdate={`${startday}-${startmonth}-${startyear}`}
                        Enddate={`${endday}-${endmonth}-${endyear}`}
                      />
                    </Col>
                  );
                })}
              </Row>
            ) : (
              "No active events for this organization yet. Start creating one by clicking 'Create Event'!"
            )}
          </Tab>
          <Tab eventKey="pastEvents" title="Past Events">
            {isPastCardData.length > 0 ? (
              <Row>
                {isPastCardData?.map((card: any) => {
                  const startparsedDate = new Date(card.event_start_date);
                  const startday = String(startparsedDate.getDate()).padStart(
                    2,
                    "0"
                  );
                  const startmonth = String(
                    startparsedDate.getMonth() + 1
                  ).padStart(2, "0");
                  const startyear = startparsedDate.getFullYear();
                  const EndparsedDate = new Date(card.event_end_date);
                  const endday = String(EndparsedDate.getDate()).padStart(
                    2,
                    "0"
                  );
                  const endmonth = String(
                    EndparsedDate.getMonth() + 1
                  ).padStart(2, "0");
                  const endyear = EndparsedDate.getFullYear();
                  return (
                    <Col key={card.id} md={4} className="my-md-2">
                      <EventCard
                        role={card.roleName}
                        Card_id={card.id}
                        imageSrc1={card.image}
                        imageSrc2={card.logo}
                        eventName={card.event_name}
                        organizer={card.orgName}
                        location={card.location}
                        Startdate={`${startday}-${startmonth}-${startyear}`}
                        Enddate={`${endday}-${endmonth}-${endyear}`}
                        past={card?.isPast}
                      />
                    </Col>
                  );
                })}
              </Row>
            ) : (
              "No past events for this organization."
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default EventsPage;
