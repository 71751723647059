import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import CreateRaceForm from "components/Forms/OrganizerForms/CreateRaceForm";
// import EarlyBirdForm from "components/Forms/OrganizerForms/EarlyBirdForm";
import CategoryForm from "components/Forms/OrganizerForms/CategoryForm";
import BenefitsFrom from "components/Forms/OrganizerForms/BenefitsFrom";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";

const RaceForm = ({
  setIntialScreen,
  handleReloadScreen,
  isCreateButton,
  setIsCreateButton,
  handleSubmitNextHandler,
}: any) => {
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  // let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if (isEditEvent && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id]);
  const { storeRaceId, setRaceId, storeEventId } = useEvent();
  const [activeTab, setActiveTab] = useState(1);

  // const [isEarlyBirdData, setIsEarlyBirdData] = useState<any>();

  // useEffect(() => {
  //   axios
  //     .post(
  //       "/getEarlyBirdDiscount",
  //       {
  //         raceId: storeRaceId,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.status) {
  //         setIsEarlyBirdData(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       return;
  //     });
  // }, []);

  const handleClick = () => {
    handleReloadScreen();
    setIntialScreen(false);
  };

  return (
    <>
      <Container>
        <div className="mt-md-4">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-primary">
              {!storeRaceId ? "Create Race" : "Edit Race"}
            </h2>
            <div>
              <DefaultButton
                type="submit"
                variant="outline-primary"
                className=" default-action-button"
                onClick={handleClick}
              >
                Back
              </DefaultButton>
            </div>
          </div>
        </div>
        <Tabs
          mountOnEnter
          unmountOnExit
          defaultActiveKey={"races"}
          activeKey={activeTab}
          id="event-race-tabs"
          onSelect={(key) => setActiveTab(Number(key))}
          className="event-nav-tabs"
        >
          {/* race data */}
          <Tab eventKey={1} title="RACES">
            {activeTab === 1 && (
              <CreateRaceForm
                setActiveTab={setActiveTab}
                isCreateButton={isCreateButton}
                setIsCreateButton={setIsCreateButton}
              />
            )}
          </Tab>

          {/* EarlyBird */}
          {/* <Tab eventKey={2} title="EARLY BIRD">
            <EarlyBirdForm setActiveTab={setActiveTab} />
          </Tab> */}

          {/* category */}
          <Tab eventKey={2} title="CATEGORY">
            {activeTab === 2 && <CategoryForm setActiveTab={setActiveTab} />}
          </Tab>

          {/* benefits */}
          <Tab eventKey={3} title="BENEFITS">
            {activeTab === 3 && (
              <BenefitsFrom
                handleSubmitNextHandler={handleSubmitNextHandler}
                setIntialScreen={setIntialScreen}
              />
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default RaceForm;
