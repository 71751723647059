import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

// Define the shape of the context state
interface RefundContextType {
  name: string;
  setName: (name: string) => void;
  eventId: string;
  setEventId: (eventId: string) => void;
}

// Create the context with an initial value
const RefundContext = createContext<RefundContextType | undefined>(undefined);
const LOCAL_STORAGE_KEY = "app_name";
const LOCAL_STORAGE_KEY_EVENTID = "event_Id";
// Create a provider component
const RefundProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [name, setName] = useState<string>(() => {
    // Retrieve the name from local storage if available
    if (typeof window !== "undefined") {
      const storedName = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      return storedName ? storedName : "";
    }
    return "";
  });
  const [eventId, setEventId] = useState<string>(() => {
    // Retrieve the name from local storage if available
    if (typeof window !== "undefined") {
      const storedEventId = window.localStorage.getItem(
        LOCAL_STORAGE_KEY_EVENTID
      );
      return storedEventId ? storedEventId : "";
    }
    return "";
  });
  useEffect(() => {
    // Store the name in local storage whenever it changes
    window.localStorage.setItem(LOCAL_STORAGE_KEY, name);
    window.localStorage.setItem(LOCAL_STORAGE_KEY_EVENTID, eventId);
  }, [name, eventId]);

  return (
    <RefundContext.Provider value={{ name, setName, setEventId, eventId }}>
      {children}
    </RefundContext.Provider>
  );
};

// Create a custom hook to use the NameContext
const useRefund = (): RefundContextType => {
  const context = useContext(RefundContext);
  if (!context) {
    throw new Error("useName must be used within a RefundProvider");
  }
  return context;
};

export { RefundProvider, useRefund };
