import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import React, { useState } from "react";

const CsvConverter = ({ headingKeys, data, fileName }: any) => {
  function convertArrayOfObjectsToCSV(array: any) {
    let result: any;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys: any = Object.keys(headingKeys);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      keys.forEach((key: any) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(array: any) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = `${fileName}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <>
      <DefaultButton
        type="button"
        className="default-action-button fw-bold text-white ms-4 px-md-5"
        variant="primary"
        onClick={() => downloadCSV(data)}
      >
        Export
      </DefaultButton>
    </>
  );
};

export default CsvConverter;
