import React, { useEffect, useState } from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEvent } from "context/EventContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import DataTable from "react-data-table-component";
import useRegister from "utils/useRegister";
import { Icon } from "components/Icon";
import { decryptText, tableCustomStyles } from "utils/utils";
import NmdcRegistrationMoreModal from "./nmdcRegistrationMoreModal";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useAccount } from "context/AccountContext";
import { OrgRoleEnum } from "types/orgRoleEnum";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

const RegistrationTabScreen = () => {
  const { storeEventId } = useEvent();
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const { companyAccount } = useAccount();
  const isRegister = useRegister();
  const axiosPrivate = useAxiosPrivate();

  const [modalId, setModalId] = useState(null);
  const [nmdcManageModal, setNmdcManageModal] = useState(false);
  const [isParticipantsLists, setIsParticipantsLists] = useState([]);
  const [reRender, setReRender] = useState<boolean>(false);
  const [isSelect, setIsSelect] = useState<any>([]);
  const [SelectRaceId, setSelectRaceId] = useState<any>(null);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState<any>({});
  const [isRolesList, setIsRolesList] = useState<any>();
  const [SelectRoleId, setSelectRoleId] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<string>("success");
  const [totalRows, setTotalRows] = useState<any>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [participantId, setParticipantIs] = useState<any>([]);
  // const [selectCheckList, setSelectCheckList] = useState<any>([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [sortedData, setSortedData] = useState(null);
  // const handleCloseManageModal = () => setIsShowManageModal(false);
  // const handleCloseManageModal = () => setIsShowManageModal(false);
  // const [isShowManageModal, setIsShowManageModal] = useState(false);
  // const [modalData, setModalData] = useState(null);
  // const [modalData, setModalData] = useState(null);

  function GetRolesList() {
    axiosPrivate({
      url: "/rolesList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          const roleList: any = data?.filter(
            (data: any) => data.roleName === "Approver"
          );
          setIsRolesList(roleList);
        }
      })
      .catch((err) => {
        return;
      });
  }

  function CouponRaceList() {
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; raceId: any; amount: any }[] = [];
        let arrayEarlyData: { name: any; raceId: any; amount: any }[] = [];
        arrayEarlyData.push({
          name: "All",
          raceId: "",
          amount: "",
        });
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.name,
            raceId: items.raceId,
            amount: items.amount,
          });
          if (items.name !== "Event Based") {
            arrayEarlyData.push({
              name: items.name,
              raceId: items.raceId,
              amount: items.amount,
            });
          }
        });
        setIsSelect(arrayEarlyData);
      })
      .catch((err) => {
        return;
      });
  }

  function participantsList() {
    setIsLoading(true);
    const isRole = !isRegister ? " " : "approver";
    let userStatus = "A";
    if (activeTab === "success") {
      userStatus = "A";
    } else if (activeTab === "inComplete") {
      userStatus = "I";
    } else if (activeTab === "rejected") {
      userStatus = "R";
    } else if (activeTab === "moreinfo") {
      userStatus = "M";
    }
    // const PerPage = rowsPerPage;
    // const current = currentPage;
    const postData = {
      eventId: eventId || storeEventId,
      raceId: SelectRaceId,
      type: isRole,
      status: userStatus,
      // rowsPerPage: PerPage,
      // currentPage: current,
    };

    axiosPrivate({
      url: "/eventParticipants",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data, isApprove, totalRows, message, isEmpty } =
          res.data;
        if (status) {
          setIsParticipantsLists(data);
          if (data.length > 0) {
            setIsApproved(isApprove);
          }
          setIsDisplay(true);
          setTotalRows(totalRows);
        } else {
          if (isEmpty) {
            // toast.error(message, { autoClose: 1500 });
            setIsParticipantsLists(data);
            setIsApproved(isApprove);
            setIsDisplay(true);
          } else {
            Object.keys(message).forEach((key) => {
              message[key].forEach((message: any) => {
                toast.error(` ${message}`);
              });
            });
            setIsDisplay(true);
          }
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function AssignTheAppproverCall() {
    setIsLoading(true);
    if (
      SelectRoleId === "" ||
      SelectRoleId === null ||
      SelectRoleId === undefined
    ) {
      toast.error("Please select the Approver");
      return;
    }

    axiosPrivate({
      url: "/assignParticipants",
      data: {
        assignedId: SelectRoleId,
        participants: selectedRows,
      },
      method: "POST",
    })
      .then((res) => {
        const { message, status } = res.data;
        if (status) {
          toast.success(message, { autoClose: 1500 });
          participantsList();
          CouponRaceList();
          GetRolesList();
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setSelectedRows([]);
      });
  }

  function handleResendEmail() {
    setIsLoading(true);
    axiosPrivate({
      url: "/resendConfirmationMail",
      data: {
        participantId: participantId && participantId?.id,
      },
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          handleModalClose();
          toast.success(message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        handleModalClose();
      });
  }

  useEffect(() => {
    if (SelectRaceId !== null) {
      participantsList();
    }
    GetRolesList();
    CouponRaceList();
  }, [reRender, SelectRaceId, activeTab]);

  const handleAcceptButton = (data: any) => {
    setModalId(data);
    // setIsShowManageModal(true);
    setNmdcManageModal(true);
    const updatedSpareParts = isParticipantsLists.map((part: any) => {
      const {
        approved_by,
        assigned_to,
        assigned_by,
        approvedName,
        assignedToName,
        assignedByName,
        first_name,
        last_name,
        email,
        gender,
        mobile_number,
        date_of_birth,
        ticket_id,
        user_id,
        age_id,
        approve_status,
        totalAmount,
        totalAmountPaid,
        totalDiscount,
        ...rest
      } = part;
      return rest;
    });
    const filteredData: any = updatedSpareParts?.filter(
      (items: any) => items.id === data.id
    );
    // setSortedData(filteredData);
  };

  const handleSelectOption = (e: any) => {
    setSelectedRows([]);
    setSelectRaceId(e.target.value);
    setActiveTab("success");
    setIsParticipantsLists([]);
  };

  const handleRoleSelectOption = (e: any) => {
    setSelectRoleId(e.target.value);
  };
  const arrayOfObjects = Object.entries(isApproved).map(([key, value]) => ({
    key,
    value,
  }));
  const handleNMDCCloseManageModal = () => setNmdcManageModal(false);
  const Viewer = companyAccount === OrgRoleEnum.Viewer;
  const selectableRows = !isRegister ? true : false;

  // const handleChangePage = (newPage: any) => {
  //   setCurrentPage(newPage);
  // };
  const pendignFilterResult = isParticipantsLists.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.mobile_number?.includes(searchValue) ||
      e.assignedToName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const approvedFilterResult = isParticipantsLists.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.mobile_number?.includes(searchValue) ||
      e.assignedToName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const rejectedFilterResult = isParticipantsLists.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.mobile_number?.includes(searchValue) ||
      e.assignedToName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const moreFilterResult = isParticipantsLists.filter(
    (e: any) =>
      e.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.mobile_number?.includes(searchValue) ||
      e.assignedToName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      e.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const pendingData = [
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Registration Type",
      // selector: (row: any) => (row.is_offline === 1 ? "Offline" : "Online"),
      selector: (row: any) => {
        if(row.is_offline === 0) return "Online";
        if(row.is_offline === 1) return "Offline";
        if(row.is_offline === 2) return "Spot";
      },
      sortable: true,
    },
    {
      name: "Race Name",
      selector: (row: any) => row.raceName,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      sortable: false,
      width: "130px",
    },
    {
      name: "Gender",
      selector: (row: any) => row.gender,
      sortable: true,
      width: "100px",
    },

    {
      name: "Assigned To",
      selector: (row: any) =>
        row.assignedToName ? row.assignedToName : "Unassigned",
      sortable: true,
      width: "120px",
    },
    {
      name: "Days since purchase",
      selector: (row: any) => row.daysSincePayment,
      sortable: true,
    },
    {
      name: "More",
      width: "150px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
    {
      name: "Resend Email",
      width: "200px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => {
            setParticipantIs(row);
            setShowConfirmModal(true);
          }}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Resend Email
        </DefaultButton>
      ),
    },
  ];

  const moreData = [
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Registration Type",
      selector: (row: any) => {
        if(row.is_offline === 0) return "Online";
        if(row.is_offline === 1) return "Offline";
        if(row.is_offline === 2) return "Spot";
      },
      sortable: true,
    },
    {
      name: "Race Name",
      selector: (row: any) => row.raceName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      sortable: false,
      width: "130px",
    },
    {
      name: "Gender",
      selector: (row: any) => row.gender,
      sortable: true,
      width: "100px",
    },
    {
      name: "Assigned To",
      selector: (row: any) =>
        row.assignedToName ? row.assignedToName : "Unassigned",
      sortable: true,
      width: "120px",
    },
    {
      name: "Days since purchase",
      selector: (row: any) => row.daysSincePayment,
      sortable: true,
    },
    {
      name: "More",
      width: "100px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
    {
      name: "Resend Email",
      width: "150px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => {
            setParticipantIs(row);
            setShowConfirmModal(true);
          }}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Resend Email
        </DefaultButton>
      ),
    },
  ];

  const approvedData = [
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Registration Type",
      selector: (row: any) => {
        if(row.is_offline === 0) return "Online";
        if(row.is_offline === 1) return "Offline";
        if(row.is_offline === 2) return "Spot";
      },
      sortable: true,
    },
    {
      name: "Race Name",
      selector: (row: any) => row.raceName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      sortable: false,
      width: "130px",
    },
    {
      name: "Gender",
      selector: (row: any) => row.gender,
      sortable: true,
      width: "100px",
    },
    {
      name: "Approved By",
      selector: (row: any) => (row.approvedName ? row.approvedName : "—"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Assigned To",
      selector: (row: any) =>
        row.assignedToName ? row.assignedToName : "Unassigned",
      sortable: true,
      width: "120px",
    },
    {
      name: "Days since purchase",
      selector: (row: any) => row.daysSincePayment,
      sortable: true,
    },
    {
      name: "More",
      width: "100px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
    {
      name: "Resend Email",
      width: "180px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => {
            setParticipantIs(row);
            setShowConfirmModal(true);
          }}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Resend Email
        </DefaultButton>
      ),
    },
  ];

  const rejectedData = [
    {
      name: "Name",
      selector: (row: any) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Registration Type",
      selector: (row: any) => {
        if(row.is_offline === 0) return "Online";
        if(row.is_offline === 1) return "Offline";
        if(row.is_offline === 2) return "Spot";
      },
      sortable: true,
    },
    {
      name: "Race Name",
      selector: (row: any) => row.raceName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date of Birth",
      selector: (row: any) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      sortable: false,
      width: "130px",
    },
    {
      name: "Gender",
      selector: (row: any) => row.gender,
      sortable: true,
      width: "100px",
    },
    {
      name: "Assigned To",
      selector: (row: any) =>
        row.assignedToName ? row.assignedToName : "Unassigned",
      sortable: true,
      width: "120px",
    },

    {
      name: "Days since purchase",
      selector: (row: any) => row.daysSincePayment,
      sortable: true,
    },
    {
      name: "More",
      width: "100px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          More
        </DefaultButton>
      ),
    },
    {
      name: "Resend Email",
      width: "180px",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => {
            setParticipantIs(row);
            setShowConfirmModal(true);
          }}
          type="submit"
          className="default-action-button fw-bold text-white px-md-4 py-md-2"
          variant="primary"
        >
          Resend Email
        </DefaultButton>
      ),
    },
  ];

  const handleChange = (state: any) => {
    const selectedData = state.selectedRows.map((data: any) => data.id);
    setSelectedRows(selectedData);
  };

  const handleSelectTab = (selectedTab: any) => {
    setActiveTab(selectedTab);
  };

  const itemsPerPageOptions = [10, 25, 50, 100];
  const matchingObject = arrayOfObjects.find((obj) => obj.key === SelectRaceId);
  const handleModalClose = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      {isLoading && <Loader />}

      <Row className="my-2">
        <Col xs={12} md={5}>
          <div>
            <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
              <div className="col">
                <select onChange={handleSelectOption}>
                  <option value="">Choose an option</option>
                  {isSelect?.map((option: any) => {
                    return (
                      <>
                        <option
                          key={option.raceId}
                          value={option.raceId}
                          disabled={option.status}
                          onChange={option.raceId}
                        >
                          {option.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span>Please select the race *</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isDisplay && (
        <Tabs
          id="event-registartion-tabs"
          className="event-registartion-nav-tabs border-0"
          onSelect={handleSelectTab}
          activeKey={activeTab}
        >
          <Tab eventKey="success" title="Approved">
            <div className="d-flex justify-content-end">
              <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                <input
                  type="text"
                  className="border-0"
                  placeholder="Search by email, name, mobile, assigned"
                  style={{ outline: "none", width: "320px" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
            </div>
            <div className="mt-md-2">
              <DataTable
                pagination
                // paginationServer
                paginationTotalRows={totalRows}
                // onChangePage={handleChangePage}
                paginationRowsPerPageOptions={itemsPerPageOptions}
                // paginationPerPage={rowsPerPage}
                columns={approvedData}
                data={approvedFilterResult}
                customStyles={tableCustomStyles}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // actions={
                //   <CsvConverter
                //     data={sortedData}
                //     headingKeys={approvedFilterResult[0]}
                //     fileName="approvedParticipantsList"
                //   />
                // }
                // onSort={handleSort}
              />
            </div>
          </Tab>
          {(matchingObject?.value === 1 || SelectRaceId === "") && (
            <Tab eventKey="inComplete" title="Pending">
              <div className="d-flex justify-content-end">
                <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by email, name, mobile, assigned"
                    style={{ outline: "none", width: "320px" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <Icon iconName="Search" className="text-primary" size={18} />
                </div>
              </div>
              <div className="mt-md-2">
                {Viewer ? (
                  <DataTable
                    pagination
                    // paginationServer
                    paginationTotalRows={totalRows}
                    // selectableRows={selectableRows}
                    paginationRowsPerPageOptions={itemsPerPageOptions}
                    // paginationPerPage={rowsPerPage}
                    selectableRowsVisibleOnly
                    onSelectedRowsChange={handleChange}
                    // onChangePage={handleChangePage}
                    columns={pendingData}
                    data={pendignFilterResult}
                    customStyles={tableCustomStyles}
                  />
                ) : (
                  <DataTable
                    pagination
                    // paginationServer
                    paginationTotalRows={totalRows}
                    selectableRows={selectableRows}
                    paginationRowsPerPageOptions={itemsPerPageOptions}
                    // paginationPerPage={rowsPerPage}
                    selectableRowsVisibleOnly
                    onSelectedRowsChange={handleChange}
                    // onChangePage={handleChangePage}
                    columns={pendingData}
                    data={pendignFilterResult}
                    customStyles={tableCustomStyles}
                  />
                )}
              </div>
            </Tab>
          )}
          {(matchingObject?.value === 1 || SelectRaceId === "") && (
            <Tab eventKey="rejected" title="Rejected">
              <div className="d-flex justify-content-end">
                <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by email, name, mobile , assigned"
                    style={{ outline: "none", width: "320px" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <Icon iconName="Search" className="text-primary" size={18} />
                </div>
              </div>
              <div className="mt-md-2">
                <DataTable
                  pagination
                  // paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={itemsPerPageOptions}
                  // paginationPerPage={rowsPerPage}
                  // onChangePage={handleChangePage}
                  columns={rejectedData}
                  data={rejectedFilterResult}
                  customStyles={tableCustomStyles}
                />
              </div>
            </Tab>
          )}
          {(matchingObject?.value === 1 || SelectRaceId === "") && (
            <Tab eventKey="moreinfo" title="More Info">
              <div className="d-flex justify-content-end">
                <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by email, name, mobile , assigned"
                    style={{ outline: "none", width: "320px" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <Icon iconName="Search" className="text-primary" size={18} />
                </div>
              </div>
              <div className="mt-md-2">
                <DataTable
                  pagination
                  // paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={itemsPerPageOptions}
                  // paginationPerPage={rowsPerPage}
                  // onChangePage={handleChangePage}
                  columns={moreData}
                  data={moreFilterResult}
                  customStyles={tableCustomStyles}
                />
              </div>
            </Tab>
          )}
        </Tabs>
      )}
      {selectedRows.length > 0 && !Viewer && (
        <>
          {!isRegister && (
            <>
              <Row className="my-2">
                <Col xs={12} md={5}>
                  <div>
                    <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                      <div className="col">
                        <select onChange={handleRoleSelectOption}>
                          <option value="">Choose an option</option>
                          {isRolesList?.map((option: any) => {
                            return (
                              <>
                                <option
                                  key={option.id}
                                  value={option.id}
                                  onChange={option.raceId}
                                >
                                  {option.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <span>Please select the Approver *</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <DefaultButton
                type="submit"
                variant="primary text-white mt-md-1"
                className="px-md-5"
                onClick={() => AssignTheAppproverCall()}
              >
                Submit
              </DefaultButton>
            </>
          )}
        </>
      )}
      {nmdcManageModal && (
        <NmdcRegistrationMoreModal
          showNmdcModal={nmdcManageModal}
          handleNmdcClose={handleNMDCCloseManageModal}
          modalId={modalId}
          setReRender={setReRender}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          title={`Are you sure you want to resend email to ${participantId?.email} ?`}
          note=""
          showNote={false}
          show={showConfirmModal}
          handleClose={handleModalClose}
          handleConfirmation={handleResendEmail}
        />
      )}
    </>
  );
};

export default RegistrationTabScreen;
