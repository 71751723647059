import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Icon } from "components/Icon";
import useEventName from "utils/useEventName";
import CouponsTabScreen from "OrganizerPages/eventDetails/couponsTabScreen";
import HomeTabScreen from "OrganizerPages/eventDetails/homeTabScreen";
import CouponHomeTabScreen from "./CouponHomeTab";
const CouponDashboard = () => {
  const Navigate = useNavigate();
  const eventName = useEventName();
  const [activeTab, setActiveTab] = useState<string | null>("home");
  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <div className="d-flex align-items-center">
            <span
              className="text-primary fs-12 fw-600"
              onClick={() => Navigate("/org")}
              style={{ cursor: "pointer" }}
            >
              <Icon iconName="ChevronLeft" size={18} className="me-2" />
              Back to Events
            </span>
          </div>
          <h6 className="fs-24  mt-md-2 ">{eventName}</h6>
        </Col>
      </Row>
      <Row>
        <Tabs
          defaultActiveKey={"home"}
          id="event-home-tabs"
          className="event-home-nav-tabs"
          onSelect={handleSelectTab}
        >
          <Tab eventKey="home" title="Home">
            {activeTab === "home" && <CouponHomeTabScreen />}
          </Tab>
          <Tab eventKey="coupon" title="coupon">
            <CouponsTabScreen />
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};

export default CouponDashboard;
