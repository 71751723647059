import { useQuery } from "react-query";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { toast } from "react-toastify";

interface EventRaceListParams {
  eventId: number;
}

export const useEventRaceList = (params: EventRaceListParams) => {
  const axiosPublic = useAxiosPublic();
  const { eventId } = params;

  return useQuery(
    ["useEventRaceList", eventId],
    async () => {
      if (eventId) {
        try {
          const response = await axiosPublic({
            method: "POST",
            url: "/userEventRaceList",
            data: {
              eventId: eventId,
            },
          });
          return response.data;
        } catch (error) {
          // toast.error("Error fetching event race list");
          throw error;
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
