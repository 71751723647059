import { useState, useContext, useEffect } from "react";
import { Button, Tabs, Tab, Container, Breadcrumb } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { useAuth } from "context/AuthContext";
import CartContext from "store/cart/cartContext";
import RegisterationContext from "store/cart/registerationContext";
import PriceOptions from "./PriceOptions/PriceOptions";
import Testimonial from "components/Testimonial/Testimonial";
import OffCanvas from "components/OffCanvas/OffCanvas";
import ReferAFriend from "components/ReferAFriend/ReferAFriend";
import EventRegistration from "components/EventRegistration/EventRegistration";
import styles from "./EventDetailsPage.module.scss";
import Profile from "components/Profile/Profile";
import { useEventRaceList } from "api/EventRaceApi";
import { useNavigate, useParams } from "react-router-dom";
import { useEventList } from "api/EventApi";
import { toast } from "react-toastify";
import { getEventByEventSlug, getEventRemainingDays } from "utils/utils";
import { EventDetails } from "components/EventRegistration/EventDetails";
import Disclaimer from "components/EventRegistration/Disclaimer";
import Loader from "components/Loader/Loader";
import { useUserEventSponsors } from "api/GetEventSponsers";
import { useCategoryContext } from "store/event/CategoryContext";
import OfficialSponsors from "components/EventRegistration/OfficialSponsors";
import EventGallery from "components/EventRegistration/EventGallery";

const EventDetailsPage = () => {
  const [currentEvent, setCurrentEvent] = useState<any | null>(null);
  const [eventRemainingDays, setEventRemainingDays] = useState<number>(0);
  const { items, totalAmount, newEvent } = useContext(CartContext);
  const [upgradingRaceInfo, setUpgradingRaceInfo] = useState<any | null>(null);
  const [isFromUpdateDetails, setIsFromUpdateDetails] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigation = useNavigate();
  const { changingCategoryForRace, setChangingCategoryForRace } =
    useCategoryContext();
  const {
    isOpenRegisterationUI,
    setIsOpenRegisterationUI,
    closeRegisterationUI,
  } = useContext(RegisterationContext);

  const [isOpenProfileModal, setIsOpenProfileModal] = useState<boolean>(false);
  const handleCloseProfileModal = () => {
    setIsOpenProfileModal(false);
  };
  const [originalHeights, setOriginalHeights] = useState<any>([]);

  useEffect(() => {
    if (changingCategoryForRace) {
      setIsFromUpdateDetails(true);
      setUpgradingRaceInfo(changingCategoryForRace);
    }

    return () => {
      setChangingCategoryForRace(null);
    };
  }, [changingCategoryForRace]);

  const hasItems = items.length > 0;

  const numberOfCartItems = items.reduce((curNumber, item: any) => {
    return curNumber + item?.itemAmount;
  }, 0);

  const handleProceedClick = () => {
    if (isAuthenticated && !!numberOfCartItems) {
      setIsOpenRegisterationUI(true);
    } else {
      setIsOpenProfileModal(true);
    }
  };

  const {
    data: eventList,
    isLoading: eventListLoading,
    error: eventListError,
  } = useEventList();
  const slug = useParams()?.eventSlug;

  useEffect(() => {
    if (eventList?.data) {
      if (slug) {
        const currentEvent = getEventByEventSlug(eventList.data, slug);
        setCurrentEvent(currentEvent);
        setEventRemainingDays(+getEventRemainingDays(currentEvent?.date));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList?.data]);

  const eventId = currentEvent?.id;

  useEffect(() => {
    if (eventId) {
      newEvent(eventId);
    }
  }, [eventId]);

  // if (!slug || !slug || !currentEvent) {
  //   console.error("eventSlug is undefined.");
  // }

  const {
    data: eventRaceList,
    isLoading: eventRaceListLoading,
    error: eventRaceListError,
  } = useEventRaceList({ eventId });

  const {
    data: sponsorsList,
    isLoading: sponsorsListLoading,
    error: sponsorsListError,
  } = useUserEventSponsors(eventId);

  if (eventListError) {
    toast.error("Error loading event details. Please try again later.");
  }

  if (eventRaceListError) {
    toast.error("Error loading event race details. Please try again later.");
  }

  const eventDetails = eventRaceList?.data;

  if (eventListLoading || eventRaceListLoading || sponsorsListLoading) {
    return <Loader />;
  }

  const handleImageLoad = (id: number, event: any) => {
    const { naturalHeight } = event.target;
    setOriginalHeights((prevHeights: any) => {
      const newHeights = [...prevHeights];
      newHeights[id] = naturalHeight;
      return newHeights;
    });
  };

  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb className="fs-14 fs-saira">
          <Breadcrumb.Item href="#" onClick={() => navigation("/")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigation("/events")}>
            Marathon
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Event Details</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <div className="container-fluid position-relative px-0">
        <div className="container px-0">
          <div className={`${styles.imageContainer}`}>
            <img
              className="px-0"
              src={`${eventDetails?.banner}`}
              width="100%"
              alt="Event Banner"
            />
          </div>
          <div className={`${styles.eventsList} mt-4`}>
            <div className={`${styles.organiserPaddingY} px-4`}>
              <div className="d-none d-sm-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    height={64}
                    width={64}
                    src={`${
                      eventDetails?.logo ?? "/images/defaultorganiserlogo.svg"
                    }`}
                    alt="event organiser"
                  />
                  <div className="ms-3">
                    <h1 className="mb-0 fw-bold fs-32 font-saira">
                      {eventDetails?.organiserName}
                    </h1>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="message">
                    <img
                      className="d-inline-block"
                      width={22}
                      height={20}
                      src="/images/message.svg"
                      alt="message"
                    />
                  </div>
                  <Button
                    className={`${styles.followButton} ms-4`}
                    variant="outline-primary"
                  >
                    FOLLOW
                  </Button>
                </div>
              </div>
              <div className="d-block d-sm-none">
                <div className="d-flex align-items-center">
                  <img
                    height={36}
                    width={38}
                    src={`${
                      eventDetails?.logo ?? "/images/defaultorganiserlogo.svg"
                    }`}
                    alt="event organiser"
                  />
                  <div className="ms-3 d-flex flex-column justify-content-between align-items-center">
                    <p className="mb-0 fs-16 fw-600 font-saira">
                      {eventDetails?.organiserName}
                    </p>
                  </div>
                </div>
                <Button
                  style={{
                    fontFamily: "saira",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  className="mt-4 w-100 btn-block"
                  variant="outline-primary"
                >
                  FOLLOW
                </Button>
              </div>
            </div>
            

            {sponsorsList.status && (
              <div className="my-2">
                <Marquee speed={80} gradient={true} gradientWidth="100px">
                  {sponsorsList?.data?.map((sponsor: any) => {
                    const originalHeight = originalHeights[sponsor.id];
                    let heightStyle = {};

                    if (originalHeight && originalHeight > 120) {
                      heightStyle = { height: 120 };
                    }

                    return (
                      <img
                        key={sponsor.id}
                        className="mx-5"
                        src={sponsor.sponser_image}
                        alt={sponsor.name}
                        onLoad={(event) => handleImageLoad(sponsor.id, event)}
                        style={heightStyle}
                      />
                    );
                  })}
                </Marquee>
              </div>
            )}

            {/* {eventRemainingDays > 0 && (
              <div className={`${styles.infoBanner} py-2 mb-3`}>
                <p
                  className={`font-saira text-light fs-16 fw-600 mb-0 text-center glow-spot px-3`}
                >
                  🎉 Countdown Alert! Join Us in {eventRemainingDays} Day
                  {`${eventRemainingDays > 1 ? "s" : ""}`} (
                  {dateFormatConvertDDMMYYYY(eventDetails?.event_start_date)})
                  for an Unforgettable Experience! 🎉
                </p>
              </div>
            )} */}

            {eventDetails?.earlyBirdRemaingdatesStatus &&
              eventDetails?.earlyBirdRemaingdates > 0 && (
                <div className="event-announcement text-center py-1">
                  <h4 className="mb-0 d-inline">
                    Early bird promo ends in...{" "}
                  </h4>
                  <h1
                    className={`mb-0 fw-bold ms-1 d-inline ${
                      eventDetails?.earlyBirdRemaingdates <= 7 && "text-primary"
                    }`}
                  >
                    {eventDetails?.earlyBirdRemaingdates} days
                  </h1>
                </div>
              )}

            <div className={`${styles.paddingX} mt-4 mb-3 pb-5`}>
              <Tabs
                defaultActiveKey={"priceOptions"}
                id="event-tabs"
                className="event-nav-tabs border-bottom"
              >
                <Tab eventKey="priceOptions" title="Price Options">
                  <PriceOptions
                    serverTime={eventDetails?.currentDate}
                    events={eventDetails?.tickets}
                    upgradingRaceInfo={upgradingRaceInfo?.data[0]}
                    isFromUpdateDetails={isFromUpdateDetails}
                  />
                </Tab>
                <Tab eventKey="eventDetails" title="Event Details">
                  <EventDetails description={currentEvent?.description} />
                </Tab>

                <Tab eventKey="gallery" title="Gallery">
                  {" "}
                  <EventGallery officalData={currentEvent?.gallery} />{" "}
                </Tab>
                <Tab eventKey="officialSponsors" title="Official Sponsors">
                  {sponsorsList.status ? (
                    <OfficialSponsors officalData={sponsorsList?.data} />
                  ) : (
                    <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                      No Officical Sponsors
                    </p>
                  )}
                </Tab>
                <Tab eventKey="disclaimer" title="Disclaimer">
                  <Disclaimer disclaimer={currentEvent?.disclaimer} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div
          className={`${styles.stickyTotalCard} ${
            hasItems && styles.stickyPosition
          }`}
        >
          <div className="container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
            <div className="ms-sm-2 mb-3 mb-sm-0">
              {hasItems && (
                <div className="d-flex flex-row flex-sm-column align-items-center align-items-sm-start  justify-content-center">
                  <p className={`${styles.textDark} fs-16 mb-0`}>
                    {numberOfCartItems}
                    <span
                      className={`${styles.textGrey} d-inline-block mb-0 ms-1`}
                    >
                      {numberOfCartItems > 1 ? "Tickets" : "Ticket"}
                    </span>
                  </p>
                </div>
              )}
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-sm-end">
              <p
                className={`${styles.total} font-saira mb-0 mb-3 mb-sm-0 me-0 me-sm-5`}
              >
                <span className="me-2"> Total</span>
                <span className={`${styles.rupeeSymbol}`}>&#8377;</span>
                <span className={`${styles.darkColor}`}>{totalAmount}</span>
              </p>
              <Button
                className="default-action-button d-none d-sm-block fw-bold text-white ms-4"
                variant="primary"
                onClick={handleProceedClick}
                disabled={numberOfCartItems === 0}
              >
                PROCEED
              </Button>
            </div>
            <Button
              className="default-action-button d-sm-none fw-bold text-white ms-0 ms-sm-4 w-100 btn-block"
              variant="primary"
              onClick={handleProceedClick}
              disabled={numberOfCartItems === 0}
            >
              PROCEED
            </Button>
          </div>
        </div>
      </div>
      <ReferAFriend />
      <Testimonial
        name="Jack and Jones"
        designation="Senior Developer"
        testimonialDescription="
        Engaging, insightful, and inspiring – the testimonial marathon was a powerful journey of shared experiences that left a lasting impact on everyone involved.
        "
      />
      <Profile
        isOpenProfileModal={isOpenProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
      />
      <OffCanvas
        showOffCanvasModal={isOpenRegisterationUI}
        handleCloseOffCanvasModal={closeRegisterationUI}
      >
        <EventRegistration eventId={eventRaceList?.data?.id} />
      </OffCanvas>
    </>
  );
};

export default EventDetailsPage;
