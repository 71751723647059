import OrganizationCard from "components/Cards/OrganizationCard";
import { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Organization } from "types/Organization";
import { organizations } from "api/Organizations";
import useBootstrapSize from "utils/useBootstrapSize";

const MobileOrganizationComp = () => {
  const screenSize = useBootstrapSize();
  const [startIndex, setStartIndex] = useState(0);

  const CHUNK_SIZE =
    screenSize === "sm" || screenSize === "xs"
      ? 1
      : screenSize === "md" || screenSize === "lg"
      ? 2
      : 3;

  const handleOrgPrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - CHUNK_SIZE);
    }
  };
  const handleOrgNext = () => {
    if (startIndex + CHUNK_SIZE < organizations.length) {
      setStartIndex(startIndex + CHUNK_SIZE);
    }
  };
  const currentOrganizations = organizations.slice(
    startIndex,
    startIndex + CHUNK_SIZE
  );

  return (
    <Row>
      <Row className="position-relative d-flex justify-content-between">
        {currentOrganizations.map((org) => {
          return (
            <Col
              md={4}
              key={org.id}
              className={`position-relative d-flex align-items-center justify-content-center`}
            >
              <OrganizationCard organization={org} />

              <div
                className="position-absolute  w-100 d-flex justify-content-between"
                style={{ top: "40%", transform: "translateY(-50%)" }}
              >
                <Button variant="" onClick={handleOrgPrev}>
                  <Image src="/images/prev.png" alt="prev" />
                </Button>
                <Button variant="" onClick={handleOrgNext}>
                  <Image src="/images/next.png" alt="next" />
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
};

export default MobileOrganizationComp;
