import { useAuth } from "context/AuthContext";
import React from "react";
import { useParams } from "react-router-dom";
// import axios from "utils/axios";
import { decryptText } from "./utils";
import useAxiosPrivate from "./axiosusermodule/useAxiosPrivate";

const useEventName: () => string = () => {
  const [isEventName, setIsEventName] = React.useState("");
  const { id } = useParams();
  const { token } = useAuth();
  const eventId = decryptText(String(id));
  const axiosPrivate = useAxiosPrivate();
  function EventData() {
    const postData = { eventId: eventId };
    // axios.post("/getEventStatus", postData, {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    axiosPrivate({
      url: "/getEventStatus",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setIsEventName(data.event_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    EventData();
  }, []);
  return isEventName;
};

export default useEventName;
