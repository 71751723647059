import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CharityTabScreen from "./NGOTabScreen";
import FundRaiserTabScreen from "./fundRaiserTabScreen";

const NGOMainTabScreen = () => {
  const [activeTab, setActiveTab] = useState<string | null>("ngo");
  useEffect(() => {}, []);

  const handleSelectTab = (selectedTab: string | null): void => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      <div>
        <Tabs
          defaultActiveKey="ngo"
          id="event-ngo-tabs"
          className="event-registartion-nav-tabs border-0"
          onSelect={handleSelectTab}
        >
          <Tab eventKey="ngo" title="NGO's">
            {activeTab === "ngo" && <CharityTabScreen />}
          </Tab>

          <Tab eventKey="fundRaiser" title="Fundraiser">
            {activeTab === "fundRaiser" && <FundRaiserTabScreen />}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default NGOMainTabScreen;
