import React, { useEffect, useState } from "react";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import DefaultButton from "components/DefaultButton/DefaultButton";
import moment from "moment";
import * as XLSX from "xlsx";

const GetAllCharity = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const [searchExcelValue, setSearchExcelValue] = useState<any>("");
  const [getCharity, setGetCharity] = useState([]);
  const [getDownloadCharity, setGetDownloadCharity] = useState([]);
  const itemsPerPageOptions = [10, 25, 50, 100];
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffe9e9",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const CardComponent = ({ title, value, Img }: any) => {
    return (
      <Card className="card info-card sales-card border-tagBlue shadow bg-white rounded my-md-4">
        <Card.Body className="card-body">
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text>
            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center my-md-2">
                <img src={Img} alt="Icon" width="44px" />
              </div>
              <div className="ps-3">
                <h6>{value}</h6>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  const moneyFormat = (number: any) => {
    const value = number.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return value;
  };

  function getAllCharity() {
    setIsLoading(true);
    axiosPrivate({
      url: "/completeCharityInfo",
      data: {},
      method: "POST",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setGetCharity(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function getAllCharityExcel() {
    setIsLoading(true);
    axiosPrivate({
      url: "/completeCharityExcel",
      data: {},
      method: "GET",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setGetDownloadCharity(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getAllCharity();
    getAllCharityExcel();
  }, []);

  const charityData = getCharity.filter(
    (e: any) =>
      e.ngoName && e.ngoName.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const GetData = [
    {
      name: "Ngo Name",
      selector: (row: any) => row.ngoName,
      sortable: true,
    },

    {
      name: "Charity Amount",
      selector: (row: any) => row.charityAmount,
      sortable: true,
      width: "180px",
    },

    {
      name: "Charity Bib Amount",
      selector: (row: any) => row.charityBibAmount,
      sortable: true,
      width: "180px",
    },

    {
      name: "Charity Total Amount",
      selector: (row: any) =>
        row.charityTotalAmount ? row.charityTotalAmount : "—",
      sortable: true,
      width: "200px",
    },
    {
      name: "Participants",
      selector: (row: any) => row.participants,
      sortable: true,
    },

    {
      name: "Contact Name",
      selector: (row: any) => row.contactName,
      sortable: true,
      width: "200px",
    },

    {
      name: "Contact Number",
      selector: (row: any) => (row.contactNumber ? row.contactNumber : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Email",
      selector: (row: any) => (row.contactEmail ? row.contactEmail : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "No of Fundraisers",
      selector: (row: any) => (row.count ? row.count : "—"),
      sortable: true,
      width: "180px",
    },
  ];

  const getallcharityAmounts = getCharity.reduce(
    (sum, charity: any) => sum + charity.charityAmount,
    0
  );

  const getallcharityBibsAmounts = getCharity.reduce(
    (sum, charity: any) => sum + charity.charityBibAmount,
    0
  );
  const getallTotalcharityAmounts = getCharity.reduce(
    (sum, charity: any) => sum + charity.charityTotalAmount,
    0
  );

  const charityParticipants = [
    {
      name: "Name",
      selector: (row: any) => (row.name ? row.name : "—"),
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row: any) => (row.mobile_number ? row.mobile_number : "—"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => (row.email ? row.email : "—"),
      sortable: false,
    },
    {
      name: "Charity Amount",
      selector: (row: any) => (row.charity_amount ? row.charity_amount : "—"),
      sortable: false,
    },
    {
      name: "Charity Name",
      selector: (row: any) => (row.charity ? row.charity : "—"),
      sortable: false,
    },
    {
      name: "Charity Type",
      selector: (row: any) => (row.charity_type ? row.charity_type : "—"),
      sortable: false,
    },
    {
      name: "Pan Number",
      selector: (row: any) => (row.pan_number ? row.pan_number : "—"),
      sortable: false,
    },
    {
      name: "Order Number",
      selector: (row: any) => (row.order_number ? row.order_number : "—"),
      sortable: false,
    },
    {
      name: "Order ID",
      selector: (row: any) => (row.order_id ? row.order_id : "—"),
      sortable: false,
    },
    {
      name: "Transaction Date",
      selector: (row: any) =>
        row.transaction_date ? row.transaction_date : "—",
      sortable: false,
    },
    {
      name: "Is Anonymous",
      selector: (row: any) =>
        row.is_anonymous ? (row.is_anonymous === 1 ? "YES" : "NO") : "—",
      sortable: false,
    },
  ];
  const charityParticipantsResult = getDownloadCharity.filter(
    (e: any) =>
      e.name?.toLowerCase().includes(searchExcelValue?.toLowerCase()) ||
      e.mobile_number
        ?.toLowerCase()
        .includes(searchExcelValue?.toLowerCase()) ||
      e.email?.toLowerCase().includes(searchExcelValue?.toLowerCase())
  );

  const exportToExcel = () => {
    const wsData = [
      [
        "Name",
        "Mobile Number",
        "Email",
        "Charity Name",
        "Charity Amount",
        "Charity Type",
        "Pan Number",
        "Order Number",
        "Order ID",
        "Transaction Date",
        "Is Anonymous",
      ],
      ...getDownloadCharity.map((item: any) => [
        item["name"],
        item["mobile_number"],
        item["email"],
        item["charity"],
        item["charity_amount"],
        item["charity_type"],
        item["pan_number"],
        item["order_number"],
        item["order_id"],
        item["transaction_date"],
        item["is_anonymous"] === 1 ? "YES" : "NO",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${`Charity_Download_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`}`
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Row className="my-md-4">
          <Card className="border border-tagBlue">
            <Row className="p-0 m-0 p-md-3">
              <Col>
                <CardComponent
                  title="Charity Amount"
                  value={moneyFormat(getallcharityAmounts ?? 0)}
                  Img="/images/charity.svg"
                />
              </Col>
              <Col>
                <CardComponent
                  title="Charity Bib Amount"
                  value={moneyFormat(getallcharityBibsAmounts ?? 0)}
                  Img="/images/charity_bib.svg"
                />
              </Col>
              <Col>
                <CardComponent
                  title="Total Amount"
                  value={moneyFormat(getallTotalcharityAmounts ?? 0)}
                  Img="/images/total.svg"
                />
              </Col>
            </Row>
          </Card>
        </Row>
        <div className="my-md-5">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3>Charities</h3>
            </div>
            <div className=" py-md-2 px-md-3 border border-primary rounded d-flex align-items-center w-25">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by Ngo Name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Icon iconName="Search" className="text-primary" size={18} />
            </div>
          </div>
          <div className="mt-md-3">
            <DataTable
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              columns={GetData}
              data={charityData}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>

        {/* once backend done we need to integrate with front end */}
        <div className="mt-md-5">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3>Donations Lists</h3>
            </div>
            <div className="w-50 d-flex justify-content-end align-items-center">
              <div className=" py-md-2 px-md-3 border border-primary rounded d-flex align-items-center ">
                <input
                  type="text"
                  className="w-100 border-0"
                  placeholder="Search by Name , Email , Number"
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchExcelValue(e.target.value)}
                />
                <Icon iconName="Search" className="text-primary" size={18} />
              </div>
              <div className="ms-md-4">
                <DefaultButton
                  type="submit"
                  variant="primary"
                  className="px-md-5 bg-railBlue text-white"
                  onClick={() => {
                    exportToExcel();
                  }}
                >
                  Download Excel
                </DefaultButton>
              </div>
            </div>
          </div>
          <div className="mt-md-3">
            <DataTable
              pagination
              paginationRowsPerPageOptions={itemsPerPageOptions}
              columns={charityParticipants}
              data={charityParticipantsResult}
              customStyles={tableCustomStyles}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetAllCharity;
