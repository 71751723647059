import React from "react";
import { Icon } from "components/Icon";

interface CheckIconProps {
  className: string;
}
const CheckIcon: React.FC<CheckIconProps> = ({ className }) => {
  return (
    <div
      className={`${className} d-flex justify-content-center align-items-center`}
      style={{
        height: "48px",
        width: "48px",
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      <Icon iconName="Check" className="text-white   border-5" size={40} />
    </div>
  );
};

export default CheckIcon;
