/* eslint-disable array-callback-return */
import { useState } from "react";
import parse from "html-react-parser";
import { Col, Row } from "react-bootstrap";

interface OfficialSponsorsProps {
  officalData: any;
}

export const OfficialSponsors: React.FC<OfficialSponsorsProps> = ({
  officalData,
}) => {
  const [originalHeights, setOriginalHeights] = useState<any>([]);
  const handleImageLoad = (id: number, event: any) => {
    const { naturalHeight } = event.target;
    setOriginalHeights((prevHeights: any) => {
      const newHeights = [...prevHeights];
      newHeights[id] = naturalHeight;
      return newHeights;
    });
  };
  return (
    <div>
      <Row className="px-4 my-2">
        {officalData?.map((sponsor: any) => {
          const originalHeight = originalHeights[sponsor.id];
          let heightStyle = {};

          if (originalHeight && originalHeight > 120) {
            heightStyle = { height: 120 };
          }

          //
          return (
            <Col key={sponsor.id} md={4}>
              <Row style={{ height: 140 }}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    key={sponsor.id}
                    className="mx-5"
                    src={sponsor.sponser_image}
                    alt={sponsor.name}
                    onLoad={(event) => handleImageLoad(sponsor.id, event)}
                    style={heightStyle}
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <p className="fs-16 ff-ns fw-500 text-TBlack my-2 d-flex justify-content-center">
                    {sponsor.name}
                  </p>
                </div>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

// {
//   events?.map((race: Race) => {
//     return (
//       <div className={`${styles.eventPriceCard} mb-2 p-0`}>
//         <EventPriceCard
//           key={race?.id}
//           race={race}
//           upgradingRaceInfo={upgradingRaceInfo}
//           isFromUpdateDetails={isFromUpdateDetails}
//         />
//       </div>
//     );
//   });
// }

export default OfficialSponsors;
