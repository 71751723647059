import React from "react";
import { Modal } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
const ViewOfflineModel = ({
  showOfflineModal,
  handleClose,
  modalData,
}: any) => {
  return (
    <>
      <Modal show={showOfflineModal} onHide={handleClose} centered>
        <Modal.Header className="border-white px-md-4"></Modal.Header>
        <Modal.Body className="mx-3 mb-md-3">
          <Modal.Title>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3>View Charity</h3>
              </div>
              <div>
                <DefaultButton
                  type="button"
                  variant="outline-primary"
                  className="default-action-button px-md-5"
                  onClick={handleClose}
                >
                  Close
                </DefaultButton>
              </div>
            </div>
          </Modal.Title>
          <div className="my-md-3">
            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  First Name <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.first_name}</h5>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  Last Name <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.last_name}</h5>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  Email <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.email}</h5>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  PAN Number <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.pan_no}</h5>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  Mobile Number <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.mobile_number}</h5>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  Amount <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.amount}</h5>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <h6 className="fw-normal fs-18 ">
                  Charity Name <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div>
                <h5 className="fw-600">{modalData.charity_bibs_id}</h5>
              </div>
            </div>

            <div>
              <div>
                <h6 className="fw-normal fs-18 ">
                  Donation Proof <span className="mx-md-3 fw-bold"> : </span>
                </h6>
              </div>
              <div className=" text-center">
                <img
                  src={modalData.donation_proof}
                  alt="donation_proof"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewOfflineModel;
