import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { useAuth } from "context/AuthContext";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export const useUserProfile = () => {
  const { token } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["useUserProfile", token],
    async () => {
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: "/getUserProfile",
        });
        return response.data;
      } catch (error) {
        // toast.error("Error fetching user profile");
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      enabled: !!token,
    }
  );
};
