import { Organization } from "../types/Organization";

export const organizations: Organization[] = [
  {
    id: 1,
    name: "Kaambhari Org",
    logo: "/images/Kambhari-logo.png",
    followers: 200,
  },
  {
    id: 2,
    name: "Kaambhari Org",
    logo: "/images/ACEM-logo.png",
    followers: 126,
  },
  {
    id: 3,
    name: "Bhopal Runners",
    logo: "/images/Bhopal-logo.png",
    followers: 172,
  },
  {
    id: 4,
    name: "Kaambhari Org",
    logo: "/images/Kambhari-logo.png",
    followers: 340,
  },
];
