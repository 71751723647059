import React, { useEffect, useState } from "react";
import { Form as FormData, Formik } from "formik";
import { Col, Row, Form } from "react-bootstrap";
import { useEvent } from "context/EventContext";
import axios from "utils/axios";
import { TextField } from "utils/FormControls";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DefaultButton from "components/DefaultButton/DefaultButton";
// import { useNavigate } from "react-router";
import DefaultInput from "components/Inputs/DefaultInput";
import styles from "./OrgniserSignup.module.scss";
import Loader from "components/Loader/Loader";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";

const OrganiserSignUpForm1 = ({ scrollbar, updateSignupStep2Handler }: any) => {
  // const Navigate = useNavigate();
  const axiosPublic = useAxiosPublic();
  const { setAccountId } = useEvent();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const SingupinitialValues = {
    companyName: "",
    name: "",
    countryCode: +91,
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    companyAddress: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    landmark: "",
    sameAsAddress: 0,
    correspondenceAddress: "",
    profileImage: null,
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company Name is Required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Company Name should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    name: Yup.string()
      .matches(
        /^[a-zA-Z\s]+$/,
        "Name should only contain alphabetic characters"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(30, "Maximum of 30 characters of length"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    countryCode: Yup.string(),
    profileImage: Yup.mixed()
      .required("Upload Profile is Required")
      .test(
        "fileSize",
        "Image size exceeds the maximum limit of 5MB.",
        (value) => {
          return (
            value &&
            (value instanceof File || value instanceof Blob) &&
            value.size <= 10 * 1024 * 1024
          ); // 5MB
        }
      )
      .test("fileType", "Only JPEG and PNG images are allowed.", (value) => {
        return (
          value &&
          (value instanceof File || value instanceof Blob) &&
          ["image/jpeg", "image/png", "image/heic", "image/jpg"].includes(
            (value as File).type
          )
        );
      }),
    password: Yup.string()
      .min(8, "Minimum of 8 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Confirm Password is required"),
    pincode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(6, "Must be exactly 5 digits"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Minimum of 10 characters of length")
      .max(10, "Maximum of 10 characters of length")
      .required("Mobile Number is required"),
    companyAddress: Yup.string()
      .nullable()
      .required("Company Address is required")
      .min(1, "Minimum of 1 characters of length")
      .max(80, "Maximum of 80 characters of length"),
    correspondenceAddress: Yup.string()
      .nullable()
      .min(1, "Minimum of 1 characters of length")
      .max(80, "Maximum of 80 characters of length"),
    landmark: Yup.string()
      .nullable()
      .required("Landmark is required")
      .min(1, "Minimum of 1 characters of length")
      .max(40, "Maximum of 40 characters of length"),
    city: Yup.string()
      .required("City is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "City should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    state: Yup.string()
      .required("State is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "State should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    country: Yup.string()
      .required("Country is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Country should only contain alphabetic characters and spaces"
      )
      .min(1, "Minimum of 1 characters of length")
      .max(25, "Maximum of 25 characters of length"),
    sameAsAddress: Yup.bool().required(
      "Billing address is same as mail address? is required"
    ),
  });

  useEffect(() => {
    setAccountId(null);
  }, []);

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const postData = {
      companyName: values.companyName,
      name: values.name,
      countryCode: +91,
      email: values.email,
      phone: values.phone,
      password: values.password,
      confirmPassword: values.confirmPassword,
      companyAddress: values.companyAddress,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      country: values.country,
      landmark: values.landmark,
      sameAsAddress: values.sameAsAddress,
      correspondenceAddress: values.correspondenceAddress,
      profileImage: values.profileImagebase64,
    };
    // axios
    //   .post("/organiserSignup", postData, {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   })
    axiosPublic({
      url: "/organiserSignup",
      data: postData,
      method: "POST",
    })
      .then((response: any) => {
        if (response.data.status) {
          toast.success(`${response.data.message}`);
          setAccountId(response.data.accountId);
          updateSignupStep2Handler();
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          Object.keys(response.data.message).forEach((key) => {
            response.data.message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err: any) => {
        console.log("Sign Up", err);
      })
      .finally(() => setIsLoading(false));
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as base64."));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={`${styles.OrganiserSignup}`}>
        <Formik
          initialValues={SingupinitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            return (
              <FormData
                noValidate
                className={`${styles.scrollContainer} px-md-4 py-md-4 `}
              >
                <div className="border px-4 rounded form-control py-4">
                  <Row className="mt-2">
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="companyName"
                        label="Company Name *"
                      />
                    </Col>

                    <Col md={4}>
                      <TextField
                        type="text"
                        name="name"
                        label="Short Vendor Name *"
                      />
                    </Col>
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="phone"
                        label="Mobile Number *"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={4}>
                      <TextField type="email" name="email" label="Email ID *" />
                    </Col>
                    <Col md={4}>
                      <TextField
                        type="password"
                        name="password"
                        label="Password *"
                      />
                    </Col>

                    <Col md={4}>
                      <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password *"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <DefaultInput
                            label="Upload Image *"
                            type="file"
                            placeholder="Upload Image"
                            accept="image/png, image/heic, image/jpeg, image/jpg"
                            isError={
                              formikProps.touched.profileImage &&
                              !!formikProps.errors.profileImage
                            }
                            error={formikProps.errors.profileImage}
                            // onChange={async (event: any) => {
                            //   const file = event.currentTarget.files
                            //     ? event.currentTarget.files[0]
                            //     : null;

                            //   if (file) {
                            //     const base64 = await readFileAsBase64(file);
                            //     formikProps.setFieldValue(
                            //       "profileImagebase64",
                            //       base64
                            //     );
                            //     formikProps.setFieldValue(
                            //       "profileImage",
                            //       base64
                            //     );
                            //   } else {
                            //     formikProps.setFieldValue(
                            //       "profileImage",
                            //       formikProps.values.profileImage
                            //     );
                            //   }
                            // }}
                            onChange={async (event: any) => {
                              const file = event.currentTarget.files
                                ? event.currentTarget.files[0]
                                : null;

                              if (file) {
                                const base64 = await readFileAsBase64(file);
                                formikProps.setFieldValue(
                                  "profileImagebase64",
                                  base64
                                );
                                formikProps.setFieldValue(
                                  "profileImage",
                                  base64
                                );
                              }
                              formikProps.setFieldValue("profileImage", file);
                            }}
                          />
                        </div>
                        {/* <div className="d-flex flex-column ps-2 ">
                          {formikProps.values.profileImage && (
                            <img
                              className="bordered"
                              src={formikProps.values.profileImage}
                              alt="Preview"
                              style={{
                                border: "1px solid #000000",
                                borderRadius: "50%",
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <TextField
                        type="text"
                        name="companyAddress"
                        label="Company Address *"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={4}>
                      <TextField
                        type="text"
                        name="landmark"
                        label="Landmark *"
                      />
                    </Col>
                    <Col md={4}>
                      <TextField type="text" name="pincode" label="Pincode *" />
                    </Col>
                    <Col md={4}>
                      <TextField type="text" name="city" label="City *" />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6}>
                      <TextField type="text" name="state" label="State *" />
                    </Col>
                    <Col md={6}>
                      <TextField type="text" name="country" label="Country *" />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="col-12 my-md-3 d-flex align-items-center">
                      <Form.Group className="d-flex">
                        <Form.Label className="me-2">
                          Is the correspondence address same as company address?
                        </Form.Label>

                        <Form.Check
                          type="radio"
                          name="sameAsAddress"
                          id="sameAsAddress"
                          value="0"
                          label="Yes"
                          checked={formikProps.values.sameAsAddress === 0}
                          onChange={() =>
                            formikProps.setFieldValue("sameAsAddress", 0)
                          }
                        />

                        <Form.Check
                          type="radio"
                          className="ms-md-3"
                          name="sameAsAddress"
                          id="sameAsAddress"
                          value="1"
                          label="No"
                          checked={formikProps.values.sameAsAddress === 1}
                          onChange={() =>
                            formikProps.setFieldValue("sameAsAddress", 1)
                          }
                        />
                      </Form.Group>
                    </Col>
                    {formikProps.values.sameAsAddress === 1 && (
                      <Col className="col-12 mb-3">
                        <TextField
                          type="text"
                          name="correspondenceAddress"
                          label="Correspondence Address *"
                        />
                      </Col>
                    )}
                  </Row>
                  <div className="row my-4">
                    <div className="col">
                      <DefaultButton
                        type="submit"
                        variant="outline-primary"
                        className="default-action-button w-100"
                      >
                        Submit
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </FormData>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default OrganiserSignUpForm1;
