import React from "react";
import { SocialMedia } from "types/SocialMedia";
import DefaultButton from "components/DefaultButton/DefaultButton";
import styles from "./NewLetter.module.scss";

const NewsLetter = () => {
  return (
    <div className="my-5 d-none d-sm-block">
      <div
        className={`${styles.refer} d-flex justify-content-center position-relative`}
      >
        <img
          style={{
            opacity: "0.8",
            background:
              "lightgray -0.007px -143.453px / 100.001% 267.093% no-repeat",
            mixBlendMode: "exclusion",
          }}
          className="w-100 d-block"
          src="/images/hexagonrefer.svg"
          alt="refer"
        />
        <div className="position-absolute top-0 py-4 px-1 mt-md-4">
          <div className="text-center">
            <p className="text-light ff-saira fs-40 my-2 fw-bold">
              Stay Updated with iFinish Newsletter
            </p>
            <p className="text-light ff-ns mb-2">
              Sign up for partnership updates and more
            </p>
            <div className="d-flex justify-content-center">
              <div className={`${styles.emailContainer} input-group my-3`}>
                <input
                  type="text"
                  className="form-control border border-1 border-light ff-ns"
                  placeholder="Enter your email"
                  aria-label="Email"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <DefaultButton
                    className={`${styles.sendBtn} text-white`}
                    onClick={() => alert("Btn Clicked")}
                    type="button"
                    variant="primary"
                  >
                    Sign Up
                  </DefaultButton>
                </div>
              </div>
            </div>
            <p className="text-light ff-ns fs-14 mb-2">
              By signing up, you agree to our Terms and Conditions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
