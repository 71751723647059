import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useVerifyLoginThroughMobile = () => {
  const axiosPublic = useAxiosPublic();
  return useMutation(async (params: any) => {
    const { mobileNumber } = params;

    try {
      const response = await axiosPublic({
        method: "POST",
        url: "/mobileLogin",
        data: {
          mobileNo: mobileNumber,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Error while verifying mobile");
    }
  });
};
