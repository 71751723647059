import React from "react";
import { Container, Accordion } from "react-bootstrap";
import styles from "./Faqs.module.scss";
import DefaultButton from "components/DefaultButton/DefaultButton";

const FaqsComponent = () => {
  return (
    <Container className="my-md-5 px-md-5">
      <h4 className="ff-saira fw-700 fs-40 text-center">FAQs</h4>
      <p className="ff-saira fw-600 fs-16 text-center my-md-3">
        Find answers to commonly asked questions about submitting photos, photo
        usage rights, and more.
      </p>
      <div>
        <Accordion
          className={`${styles.faqscustomAccordian}`}
          defaultActiveKey="0"
          alwaysOpen
        >
          <Accordion.Item eventKey={`0`} bsPrefix={`${styles.accordianItem}`}>
            <Accordion.Header
              className="px-0 py-3"
              bsPrefix={`${styles.accordianHeader}`}
            >
              <p className={`mb-0 fw-400 fs-16 ff-ns`}>
                Can I request photo removal?
              </p>
            </Accordion.Header>
            <Accordion.Body className="px-0">need to Update</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`1`} bsPrefix={`${styles.accordianItem}`}>
            <Accordion.Header
              className="px-0 py-3"
              bsPrefix={`${styles.accordianHeader}`}
            >
              <p className={`mb-0 fw-400 fs-16 ff-ns`}>
                How long does it take to review the submitted photos?
              </p>
            </Accordion.Header>
            <Accordion.Body className="px-0">need to Update</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`2`} bsPrefix={`${styles.accordianItem}`}>
            <Accordion.Header
              className="px-0 py-3"
              bsPrefix={`${styles.accordianHeader}`}
            >
              <p className={`mb-0 fw-400 fs-16 ff-ns`}>
                How do I submit photos?
              </p>
            </Accordion.Header>
            <Accordion.Body className="px-0">need to Update</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`2`} bsPrefix={`${styles.accordianItem}`}>
            <Accordion.Header
              className="px-0 py-3"
              bsPrefix={`${styles.accordianHeader}`}
            >
              <p className={`mb-0 fw-400 fs-16 ff-ns`}>
                Can I submit multiple photos?
              </p>
            </Accordion.Header>
            <Accordion.Body className="px-0">need to Update</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`2`} bsPrefix={`${styles.accordianItem}`}>
            <Accordion.Header
              className="px-0 py-3"
              bsPrefix={`${styles.accordianHeader}`}
            >
              <p className={`mb-0 fw-400 fs-16 ff-ns`}>
                What are the photo usage rights?
              </p>
            </Accordion.Header>
            <Accordion.Body className="px-0">need to Update</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="text-center my-md-5">
        <h5 className="ff-saira fw-700 fs-32">Still have questions?</h5>
        <p className="ff-ns fw-400 fs-16">Contact us for more information.</p>
        <DefaultButton
          onClick={() => alert("Button Alert")}
          className="default-action-button mt-md-3"
          variant="outline-primary"
          type="button"
        >
          Contact
        </DefaultButton>
      </div>
    </Container>
  );
};

export default FaqsComponent;
