import React, { useState } from "react";
import { useNavigate } from "react-router";
import OrganiserSignUpForm2 from "components/Forms/OrganizerForms/OrganiserSignUpForm2";
import OrganiserSignUpForm1 from "components/Forms/OrganizerForms/OrganiserSignUpForm1";
import {
  divStyle,
  formContainerStyle,
  transparentBoxStyle,
} from "utils/OrgSignupPageCss";

const OrgSingupPage = () => {
  // const [maxHeight, setMaxHeight] = useState<number | string>("400px");
  const Navigate = useNavigate();
  const [showSignupStep2, setShowSignupStep2] = useState<boolean>(false);

  const updateSignupStep2Handler = () => {
    setShowSignupStep2(true);
  };

  return (
    <>
      <div style={divStyle}>
        <div style={transparentBoxStyle}>
          <div style={formContainerStyle}>
            <h1 className="d-flex justify-content-center mt-md-3 text-uppercase">
              Organisation Sign Up {showSignupStep2 ? "Step-2" : "Step-1"}
            </h1>

            <div>
              {showSignupStep2 ? (
                <OrganiserSignUpForm2 scrollbar="scroll" />
              ) : (
                <OrganiserSignUpForm1
                  updateSignupStep2Handler={updateSignupStep2Handler}
                  scrollbar="scroll"
                />
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p>
                Already a event organiser? click here to
                <button
                  className="btn text-primary"
                  onClick={() => Navigate("/org/login")}
                >
                  Login
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrgSingupPage;
