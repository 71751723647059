import React, { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEvent } from "context/EventContext";
import { Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TextField } from "utils/FormControls";
import { ErrorMessage, Form as Formdata, Formik } from "formik";
import { toast } from "react-toastify";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText } from "utils/utils";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const PreRegistrationComponents = ({ activeTab }: any) => {
  const { id } = useParams();
  const eventId = decryptText(String(id));
  const isViewEvent = ViewEvent();
  const axiosPrivate = useAxiosPrivate();
  const { storeEventId } = useEvent();
  const [isRaceList, setIsRaceList] = useState<any>();
  const [isRaceType, setIsRaceType] = useState("");
  const [isAssignBibs, setIsAssignBibs] = useState(false);
  const [corporateBibs, setCorporateBibs] = useState("");
  const [isbibassigned, setIsBibAssigned] = useState<boolean>();
  const [isBibBasedOnPrefix, setIsBibBasedOnPrefix] = useState("");
  const [isCorporateBibBasedOnPrefix, setIsCorporateBibBasedOnPrefix] =
    useState("");
  const [isCorporateBibassigned, setIsCorporateBibAssigned] =
    useState<boolean>();
  const [isSelect, setIsSelect] = useState<any>([]);
  const [SelectRaceId, setSelectRaceId] = useState("");
  const [getBibsDetails, setGetBibsDetails] = useState<any>();
  const [isStatus, setIsStatus] = useState<boolean>(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [raceCount, setRaceCount] = useState("");

  function CouponRaceList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/couponRaceList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        const { data } = res.data;
        const racesList: any = data?.filter((data: any) => data.raceId !== "0");
        setIsSelect(racesList);
      })
      .catch((err) => {
        // console.log("err", err);
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function EventRaceList() {
    setIsLoading(true);
    axiosPrivate({
      url: "/eventRacesList",
      data: {
        eventId: eventId || storeEventId,
      },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          setIsRaceList(res.data.data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function GetBibDetasil() {
    setIsLoading(true);
    axiosPrivate({
      url: "/getBibs",
      data: {
        raceId: SelectRaceId,
      },
      method: "POST",
    })
      .then((res) => {
        setIsStatus(res.data.status);
        if (res.data.status) {
          setGetBibsDetails(res.data.data);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function ParticipantCountMethod() {
    axiosPrivate({
      url: "/getparticipantCount",
      data: {
        raceId: SelectRaceId,
      },
      method: "POST",
    })
      .then((res) => {
        if (res.data.status) {
          setRaceCount(res.data.data);
        }
      })
      .catch((err) => {
        return;
      });
  }

  useEffect(() => {
    EventRaceList();
    CouponRaceList();
    if (activeTab === "postRegistrationBibs") {
      ParticipantCountMethod();
    }

    if (SelectRaceId === null) {
      return;
    } else {
      GetBibDetasil();
    }
  }, [SelectRaceId]);

  // const handlechange = (e: any) => {
  //   const values = e.target.value;
  //   setAssignBibsGender(values);
  // };
  // const handlecorporatebibschange = (e: any) => {
  //   const values = e.target.value;
  //   setCorporateBibs(values);
  // };

  const filteredData: any = isRaceList?.filter(
    (data: any) => data.id === parseInt(SelectRaceId)
  );

  const handleAssignedBibschange = (e: any) => {
    const values = e.target.value;
    setIsBibBasedOnPrefix(values);
    const assign = values === "1" ? true : false;
    setIsBibAssigned(assign);
  };

  const handleCorporateBibs = (e: any) => {
    const Data = e.target.value;
    setIsCorporateBibBasedOnPrefix(Data);
    const assign = Data === "1" ? true : false;
    setIsCorporateBibAssigned(assign);
  };

  const IntialValues = {
    isAge: "",
    isPrefix: "",
    noofDigitsBib: "",
    isCorporate: "",
    isCorporatePrefix: 0,
    categoryNames: [""],
    prefixes: [""],
    endingBibNos: [""],
    startingBibNos: [""],
    femlaePrefixes: [""],
    femaleEndingBibNos: [""],
    femaleStartingBibNos: [""],
    otherPrefixes: [""],
    otherEndingBibNos: [""],
    otherStartingBibNos: [""],
    corporateMinNum: "",
    corporateMaxNum: "",
    corporatePrefixes: "",
    noAgePrefix: "",
    noAgeStartingBibNos: "",
    noAgeEndingBibNos: "",
    minAge: [""],
    maxAge: [""],
  };

  const captureData = () => {
    const dataArray: any = [];
    const rowData: { [key: number]: { [key: string]: string | any[] } } = {};
    const inputs = document.querySelectorAll<HTMLInputElement>(
      'input[name^="prefixes"], input[name^="startingBibNos"], input[name^="endingBibNos"]'
    );

    const categoryElements =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="category_name"]'
      );

    const categoryArray = Array.from(categoryElements);

    const minAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="min_age"]');
    const minArray = Array.from(minAgeElement);

    const maxAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="max_age"]');
    const maxArray = Array.from(maxAgeElement);
    const GenderElement =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="gedner"]');

    const genderArray = Array.from(GenderElement);

    const IDElements =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="ID"]');

    const IDArray = Array.from(IDElements);

    inputs.forEach((input) => {
      const match = input.name.match(/\[(\d+)\]/);
      if (!match) return; // Skip if no match found

      const currentIndex = parseInt(match[1], 10);
      const arrayName = input.name.includes("prefixes")
        ? "prefixes"
        : input.name.includes("startingBibNos")
        ? "startingBibNos"
        : "endingBibNos";

      if (!rowData[currentIndex]) {
        rowData[currentIndex] = {};
      }

      rowData[currentIndex][arrayName] = input.value;

      if (Object.keys(rowData[currentIndex]).length === 3) {
        const categoryName =
          categoryArray[currentIndex]?.textContent?.trim() || "N/A";
        const IDName = IDArray[currentIndex]?.textContent?.trim() || "N/A";

        const minAgeArray =
          minArray[currentIndex]?.textContent?.trim() || "N/A";

        const maxAgeArray =
          maxArray[currentIndex]?.textContent?.trim() || "N/A";
        const genderArrayData =
          genderArray[currentIndex]?.textContent?.trim() || "N/A";

        const dataObject = {
          id: IDName,
          prefixes: rowData[currentIndex]["prefixes"] || "",
          startingBibNos: rowData[currentIndex]["startingBibNos"] || "",
          endingBibNos: rowData[currentIndex]["endingBibNos"] || "",
          category_name: categoryName,
          min_age: minAgeArray,
          max_age: maxAgeArray,
          gender: genderArrayData,
        };

        dataArray.push(dataObject);
      }
    });

    return dataArray;
  };

  const FemaleData = () => {
    const FemaledataArray: any = [];
    const rowData: { [key: number]: { [key: string]: string | any[] } } = {};
    const Femaleinputs = document.querySelectorAll<HTMLInputElement>(
      'input[name^="femlaePrefixes"], input[name^="femaleStartingBibNos"], input[name^="femaleEndingBibNos"]'
    );
    const FemalecategoryElements =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="female_category_name"]'
      );
    const FemaleCategoryArray = Array.from(FemalecategoryElements);
    const FemaleMinAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="female_min_age"]'
      );
    const FemaleMinArray = Array.from(FemaleMinAgeElement);

    const FemaleMaxAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="female_max_age"]'
      );
    const FemaleMaxArray = Array.from(FemaleMaxAgeElement);
    const FemaleGenderElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="female_gedner"]'
      );

    const FemalegenderArray = Array.from(FemaleGenderElement);
    const FemaleIDElements =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="female_ID"]');

    const FemaleIDArray = Array.from(FemaleIDElements);
    Femaleinputs.forEach((input) => {
      const match = input.name.match(/\[(\d+)\]/);
      if (!match) return; // Skip if no match found

      const currentIndex = parseInt(match[1], 10);
      const arrayName = input.name.includes("femlaePrefixes")
        ? "femlaePrefixes"
        : input.name.includes("femaleStartingBibNos")
        ? "femaleStartingBibNos"
        : "femaleEndingBibNos";

      if (!rowData[currentIndex]) {
        rowData[currentIndex] = {};
      }

      rowData[currentIndex][arrayName] = input.value;

      if (Object.keys(rowData[currentIndex]).length === 3) {
        const categoryName =
          FemaleCategoryArray[currentIndex]?.textContent?.trim() || "N/A";
        const IDName =
          FemaleIDArray[currentIndex]?.textContent?.trim() || "N/A";

        const minAgeArray =
          FemaleMinArray[currentIndex]?.textContent?.trim() || "N/A";

        const maxAgeArray =
          FemaleMaxArray[currentIndex]?.textContent?.trim() || "N/A";
        const genderArrayData =
          FemalegenderArray[currentIndex]?.textContent?.trim() || "N/A";

        const dataObject = {
          id: IDName,
          prefixes: rowData[currentIndex]["femlaePrefixes"] || "",
          startingBibNos: rowData[currentIndex]["femaleStartingBibNos"] || "",
          endingBibNos: rowData[currentIndex]["femaleEndingBibNos"] || "",
          category_name: categoryName,
          min_age: minAgeArray,
          max_age: maxAgeArray,
          gender: genderArrayData,
        };

        FemaledataArray.push(dataObject);
      }
    });
    return FemaledataArray;
  };

  const OtherData = () => {
    const OtherdataArray: any = [];
    const rowData: { [key: number]: { [key: string]: string | any[] } } = {};

    const Othersinputs = document.querySelectorAll<HTMLInputElement>(
      'input[name^="otherPrefixes"], input[name^="otherStartingBibNos"], input[name^="otherEndingBibNos"]'
    );

    const OthercategoryElements =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="other_category_name"]'
      );
    const OtherCategoryArray = Array.from(OthercategoryElements);

    const OtherMinAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="other_min_age"]'
      );
    const OtherMinArray = Array.from(OtherMinAgeElement);

    const OtherMaxAgeElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="other_max_age"]'
      );
    const OtherMaxArray = Array.from(OtherMaxAgeElement);

    const OtherGenderElement =
      document.querySelectorAll<HTMLTableDataCellElement>(
        '[id^="other_gedner"]'
      );
    const OthergenderArray = Array.from(OtherGenderElement);
    const OtherIDElements =
      document.querySelectorAll<HTMLTableDataCellElement>('[id^="other_ID"]');

    const OtherIDArray = Array.from(OtherIDElements);
    Othersinputs.forEach((input) => {
      const match = input.name.match(/\[(\d+)\]/);
      if (!match) return;

      const currentIndex = parseInt(match[1], 10);
      const arrayName = input.name.includes("otherPrefixes")
        ? "otherPrefixes"
        : input.name.includes("otherStartingBibNos")
        ? "otherStartingBibNos"
        : "otherEndingBibNos";

      if (!rowData[currentIndex]) {
        rowData[currentIndex] = {};
      }

      rowData[currentIndex][arrayName] = input.value;

      if (Object.keys(rowData[currentIndex]).length === 3) {
        const categoryName =
          OtherCategoryArray[currentIndex]?.textContent?.trim() || "N/A";
        const minAgeArray =
          OtherMinArray[currentIndex]?.textContent?.trim() || "N/A";
        const maxAgeArray =
          OtherMaxArray[currentIndex]?.textContent?.trim() || "N/A";
        const genderArrayData =
          OthergenderArray[currentIndex]?.textContent?.trim() || "N/A";
        const IDName = OtherIDArray[currentIndex]?.textContent?.trim() || "N/A";

        const dataObject = {
          id: IDName,
          prefixes: rowData[currentIndex]["otherPrefixes"] || "",
          startingBibNos: rowData[currentIndex]["otherStartingBibNos"] || "",
          endingBibNos: rowData[currentIndex]["otherEndingBibNos"] || "",
          category_name: categoryName,
          min_age: minAgeArray,
          max_age: maxAgeArray,
          gender: genderArrayData,
        };

        OtherdataArray.push(dataObject);
      }
    });

    return OtherdataArray;
  };

  function validateBibPairs(
    startingNumbers: any,
    endingNumbers: any,
    arr: string[]
  ): boolean {
    if (startingNumbers.length !== endingNumbers.length) {
      toast.error("Error: Arrays must have the same length!");
      return false;
    }

    const pairs: any = [];

    for (let i = 0; i < startingNumbers.length; i++) {
      const currentStartingBib = startingNumbers[i];
      const currentEndingBib = endingNumbers[i];
      const currentPrefix = arr[i];
      const startingBib = parseInt(startingNumbers[i], 10);
      const endingBib = parseInt(endingNumbers[i], 10);

      if (!(startingBib < endingBib)) {
        toast.error(
          `Starting Bib no ${startingBib} should be less than the Ending bib no ${endingBib} of its own pair!`
        );
        return false;
      }

      for (let j = 0; j < pairs.length; j++) {
        const existingPair = pairs[j];
        if (currentPrefix === existingPair.prefix) {
          if (
            (currentStartingBib >= existingPair.startingBib &&
              currentStartingBib <= existingPair.endingBib) ||
            (currentEndingBib >= existingPair.startingBib &&
              currentEndingBib <= existingPair.endingBib)
          ) {
            toast.error(
              `Error: Bib range (${currentStartingBib}-${currentEndingBib}) overlaps with range (${existingPair.startingBib}-${existingPair.endingBib}) for prefix ${existingPair.prefix}`
            );
            return false;
          }
        }
      }

      pairs.push({
        startingBib: currentStartingBib,
        endingBib: currentEndingBib,
        prefix: currentPrefix,
      });
    }

    return pairs;
  }

  function validateBibWithOurPrefix(
    startingNumbers: any[],
    endingNumbers: any[]
  ): boolean {
    if (startingNumbers.length !== endingNumbers.length) {
      toast.error("Error: Arrays must have the same length!");
      return false;
    }

    const pairs: any = [];

    for (let i = 0; i < startingNumbers.length; i++) {
      const currentStartingBib = parseInt(startingNumbers[i], 10);
      const currentEndingBib = parseInt(endingNumbers[i], 10);

      if (!(currentStartingBib < currentEndingBib)) {
        toast.error(
          `Starting Bib no ${currentStartingBib} should be less than the Ending bib no ${currentEndingBib} of its own pair!`
        );
        return false;
      }

      for (let j = 0; j < pairs.length; j++) {
        const existingPair = pairs[j];
        if (
          (currentStartingBib >= existingPair.startingBib &&
            currentStartingBib <= existingPair.endingBib) ||
          (currentEndingBib >= existingPair.startingBib &&
            currentEndingBib <= existingPair.endingBib)
        ) {
          toast.error(
            `Error: Bib range (${currentStartingBib}-${currentEndingBib}) overlaps with range (${existingPair.startingBib}-${existingPair.endingBib})`
          );
          return false;
        }
      }

      pairs.push({
        startingBib: currentStartingBib,
        endingBib: currentEndingBib,
      });
    }

    return pairs;
  }

  function hasEmptyStrings(arr: string[]): boolean {
    return arr.some((item) => item === "");
  }

  function SubmitCall(postData: any) {
    setIsLoading(true);
    axiosPrivate({
      url: "/createBibs",
      data: postData,
      method: "POST",
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
        } else {
          Object.keys(message).forEach((key) => {
            message[key].forEach((message: any) => {
              // console.log(`${key}: ${message}`);
              toast.error(` ${message}`);
            });
          });
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
        setIsStatus(false);
      });
  }

  const handleAssignSubmit = (values: any) => {
    const CombinedPrefixes = [
      ...values.prefixes,
      ...values.femlaePrefixes,
      ...values.otherPrefixes,
    ];

    const combinedStartingBibNos = [
      ...values.startingBibNos,
      ...values.femaleStartingBibNos,
      ...values.otherStartingBibNos,
    ];
    const combinedEndingBibNos = [
      ...values.endingBibNos,
      ...values.femaleEndingBibNos,
      ...values.otherEndingBibNos,
    ];

    const inputDataAgeCategoryNo = {
      raceId: SelectRaceId,
      isAge: values.isAge,
      bibsDigits: values.noofDigitsBib,
      isPrefix: isBibBasedOnPrefix,
      isCorporate: values.isCorporate,
      isCorporatePrefix:
        values.isCorporate === "0" ? "" : isCorporateBibBasedOnPrefix,
      corporatePrefixes:
        values.isCorporate === "0" || isCorporateBibBasedOnPrefix === "0"
          ? ""
          : values.corporatePrefixes,
      corporateMinNum: values.isCorporate === "0" ? "" : values.corporateMinNum,
      corporateMaxNum: values.isCorporate === "0" ? "" : values.corporateMaxNum,
      data: values.isAge === "0" && [
        {
          prefix: isBibBasedOnPrefix === "0" ? "" : values.noAgePrefix,
          startingBibNos: values.noAgeStartingBibNos,
          endingBibNos: values.noAgeEndingBibNos,
        },
      ],
    };

    const inputDataCategoryYes = {
      raceId: SelectRaceId,
      isAge: values.isAge,
      bibsDigits: values.noofDigitsBib,
      isPrefix: isBibBasedOnPrefix,
      isCorporate: values.isCorporate,
      isCorporatePrefix:
        values.isCorporate === "0" ? "" : isCorporateBibBasedOnPrefix,
      corporatePrefixes:
        values.isCorporate === "0" || isCorporateBibBasedOnPrefix === "0"
          ? ""
          : values.corporatePrefixes,
      corporateMinNum: values.isCorporate === "0" ? "" : values.corporateMinNum,
      corporateMaxNum: values.isCorporate === "0" ? "" : values.corporateMaxNum,
      data: values.isAge === "1" && [
        {
          Male: captureData(),
          Female: FemaleData(),
          Other: OtherData(),
        },
      ],
    };

    const postDataAgeCategoryYes = JSON.stringify(inputDataCategoryYes);
    const postDataAgeCategoryNo = JSON.stringify(inputDataAgeCategoryNo);

    if (values.isAge === "") {
      toast.error("please select age category");
    } else {
      if (values.isAge === "0") {
        if (isBibBasedOnPrefix === "" ? true : false) {
          toast.error("please enter Prefix");
        } else if (isBibBasedOnPrefix === "1") {
          if (values.noAgePrefix.length === 0) {
            toast.error("Please enter the bib prefix");
          } else if (values.noAgeStartingBibNos.length === 0) {
            toast.error("Enter starting Bib number");
          } else if (values.noAgeEndingBibNos.length === 0) {
            toast.error("Enter ending Bib number");
          } else if (
            parseInt(values.noAgeEndingBibNos) <
            parseInt(values.noAgeStartingBibNos)
          ) {
            toast.error(
              "Starting bib number must be less than the ending bib number"
            );
          } else if (values.isCorporate === "" ? true : false) {
            toast.error("please select corporate");
          } else if (values.isCorporate === "0") {
            SubmitCall(postDataAgeCategoryNo);
          } else if (values.isCorporate === "1") {
            if (isCorporateBibBasedOnPrefix === "" ? true : false) {
              toast.error("please select corporate prefix");
            } else if (isCorporateBibBasedOnPrefix === "0") {
              if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMaxNum.length === 0) {
                toast.error("Enter corporate Ending Bib number");
              } else if (values.corporateMaxNum < values.corporateMinNum) {
                toast.error(
                  "corporate starting bib number must be less then the corporate ending bib number"
                );
              } else {
                if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
                  SubmitCall(postDataAgeCategoryNo);
                } else {
                  toast.error(
                    "Number of digits for bib number should be greater than 0 and less than 6"
                  );
                }
              }
            } else if (isCorporateBibBasedOnPrefix === "1") {
              if (values.corporatePrefixes.length === 0) {
                toast.error("please enter corporate prefix");
              } else if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMaxNum.length === 0) {
                toast.error("Enter corporate Ending Bib number");
              } else if (values.corporateMaxNum < values.corporateMinNum) {
                toast.error(
                  "corporate starting bib number must be less then the corporate ending bib number"
                );
              } else {
                if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
                  SubmitCall(postDataAgeCategoryNo);
                } else {
                  toast.error(
                    "Number of digits for bib number should be greater than 0 and less than 6"
                  );
                }
              }
            } else {
              if (values.corporatePrefixes.length === 0) {
                toast.error("please enter corporate prefix");
              } else {
                if (isCorporateBibBasedOnPrefix === "1") {
                  toast.error("please enter corporate prefix");
                } else if (values.corporateMinNum.length === 0) {
                  toast.error("Enter corporate  starting Bib number");
                } else if (values.corporateMaxNum.length === 0) {
                  toast.error("Enter corporate Ending Bib number");
                } else if (values.corporateMaxNum < values.corporateMinNum) {
                  toast.error(
                    "corporate starting bib number must be less then the corporate ending bib number"
                  );
                } else {
                  if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
                    SubmitCall(postDataAgeCategoryNo);
                  } else {
                    toast.error(
                      "Number of digits for bib number should be greater than 0 and less than 6"
                    );
                  }
                }
              }
            }
          }
        } else if (isBibBasedOnPrefix === "0") {
          if (values.noAgeStartingBibNos.length === 0) {
            toast.error("Enter starting Bib number");
          } else if (values.noAgeEndingBibNos.length === 0) {
            toast.error("Enter ending Bib number");
          } else if (
            parseInt(values.noAgeEndingBibNos) <
            parseInt(values.noAgeStartingBibNos)
          ) {
            toast.error(
              "Starting bib number must be less than the ending bib number"
            );
          } else if (values.isCorporate === "" ? true : false) {
            toast.error("please select corporate");
          } else if (values.isCorporate === "0") {
            if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
              SubmitCall(postDataAgeCategoryNo);
            } else {
              toast.error(
                "Number of digits for bib number should be greater than 0 and less than 6"
              );
            }
          } else if (values.isCorporate === "1") {
            if (isCorporateBibBasedOnPrefix === "0") {
              if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMaxNum.length === 0) {
                toast.error("Enter corporate Ending Bib number");
              } else if (values.corporateMaxNum < values.corporateMinNum) {
                toast.error(
                  "corporate starting bib number must be less then the corporate ending bib number"
                );
              } else {
                if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
                  SubmitCall(postDataAgeCategoryNo);
                } else {
                  toast.error(
                    "Number of digits for bib number should be greater than 0 and less than 6"
                  );
                }
              }
            } else if (isCorporateBibBasedOnPrefix === "1") {
              if (values.corporatePrefixes.length === 0) {
                toast.error("Enter corporate bib prefix");
              } else if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMinNum.length === 0) {
                toast.error("Enter corporate  starting Bib number");
              } else if (values.corporateMaxNum.length === 0) {
                toast.error("Enter corporate Ending Bib number");
              } else if (values.corporateMaxNum < values.corporateMinNum) {
                toast.error(
                  "corporate starting bib number must be less then the corporate ending bib number"
                );
              } else {
                if (values.noofDigitsBib > 0 && values.noofDigitsBib < 6) {
                  SubmitCall(postDataAgeCategoryNo);
                } else {
                  toast.error(
                    "Number of digits for bib number should be greater than 0 and less than 6"
                  );
                }
              }
            }
          }
        }
      } else if (values.isAge === "1") {
        if (values.isAge === "" ? true : false) {
          toast.error("please select age category");
        } else if (isBibBasedOnPrefix === "" ? true : false) {
          toast.error("please select Prefix");
        } else if (values.isCorporate === "" ? true : false) {
          toast.error("please select corporate");
        } else if (values.isCorporate === "0") {
          if (
            hasEmptyStrings(combinedStartingBibNos) ||
            hasEmptyStrings(combinedEndingBibNos)
          ) {
            toast.error(
              "Error: You cannot Submit empty Data. Please fill in the bib data."
            );
          } else {
            let validationResult;

            if (isbibassigned) {
              validationResult = validateBibPairs(
                combinedStartingBibNos,
                combinedEndingBibNos,
                CombinedPrefixes
              );
            } else {
              validationResult = validateBibWithOurPrefix(
                combinedStartingBibNos,
                combinedEndingBibNos
              );
            }

            // Proceed only if both checks pass
            if (Array.isArray(validationResult)) {
              // console.log("Valid pairs:", JSON.stringify(validationResult));

              // console.log("postData123", postDataAgeCategoryYes);
              SubmitCall(postDataAgeCategoryYes);
            } else {
              // console.log("Invalid bib pairs detected.");
              toast.error("Invalid bib pairs detected.");
            }
          }
        } else if (values.isCorporate === "1") {
          if (isCorporateBibBasedOnPrefix === "" ? true : false) {
            toast.error("Please select corporate prefix");
          } else if (isCorporateBibBasedOnPrefix === "0") {
            if (values.corporateMinNum.length === 0) {
              toast.error("Enter corporate  starting Bib number");
            } else if (values.corporateMinNum.length === 0) {
              toast.error("Enter corporate  starting Bib number");
            } else if (values.corporateMaxNum.length === 0) {
              toast.error("Enter corporate Ending Bib number");
            } else if (values.corporateMaxNum < values.corporateMinNum) {
              toast.error(
                "corporate starting bib number must be less then the corporate ending bib number"
              );
            } else {
              if (
                hasEmptyStrings(combinedStartingBibNos) ||
                hasEmptyStrings(combinedEndingBibNos)
              ) {
                toast.error(
                  "Error: You cannot Submit empty Data. Please fill in the bib data."
                );
              } else {
                let validationResult;

                if (isbibassigned) {
                  validationResult = validateBibPairs(
                    combinedStartingBibNos,
                    combinedEndingBibNos,
                    CombinedPrefixes
                  );
                } else {
                  validationResult = validateBibWithOurPrefix(
                    combinedStartingBibNos,
                    combinedEndingBibNos
                  );
                }

                // Proceed only if both checks pass
                if (Array.isArray(validationResult)) {
                  // console.log("Valid pairs:", JSON.stringify(validationResult));

                  // console.log("postData123", postDataAgeCategoryYes);
                  SubmitCall(postDataAgeCategoryYes);
                } else {
                  // console.log("Invalid bib pairs detected.");
                  toast.error("Invalid bib pairs detected.");
                }
              }
            }
          } else if (isCorporateBibBasedOnPrefix === "1") {
            if (values.corporatePrefixes.length === 0) {
              toast.error("please enter corporate prefix");
            } else if (values.corporateMinNum.length === 0) {
              toast.error("Enter corporate  starting Bib number");
            } else if (values.corporateMaxNum.length === 0) {
              toast.error("Enter corporate Ending Bib number");
            } else if (values.corporateMaxNum < values.corporateMinNum) {
              toast.error(
                "corporate starting bib number must be less then the corporate ending bib number"
              );
            } else {
              if (
                hasEmptyStrings(combinedStartingBibNos) ||
                hasEmptyStrings(combinedEndingBibNos)
              ) {
                toast.error(
                  "Error: You cannot Submit empty Data. Please fill in the bib data."
                );
              } else {
                let validationResult;

                if (isbibassigned) {
                  validationResult = validateBibPairs(
                    combinedStartingBibNos,
                    combinedEndingBibNos,
                    CombinedPrefixes
                  );
                } else {
                  validationResult = validateBibWithOurPrefix(
                    combinedStartingBibNos,
                    combinedEndingBibNos
                  );
                }

                // Proceed only if both checks pass
                if (Array.isArray(validationResult)) {
                  // console.log("Valid pairs:", JSON.stringify(validationResult));

                  // console.log("postData123", postDataAgeCategoryYes);
                  SubmitCall(postDataAgeCategoryYes);
                } else {
                  // console.log("Invalid bib pairs detected.");
                  toast.error("Invalid bib pairs detected.");
                }
              }
            }
          }
        }
      }
    }
  };

  const handleSelectOption = (e: any) => {
    setSelectRaceId(e.target.value);
    setIsDisplay(true);
    setIsAssignBibs(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Row className="my-2">
          <Col xs={12} md={5}>
            <div>
              <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                <div className="col">
                  <select onChange={handleSelectOption}>
                    <option value="">Choose an option</option>
                    {isSelect?.map((option: any) => {
                      return (
                        <>
                          <option
                            key={option.raceId}
                            value={option.raceId}
                            disabled={option.status}
                            onChange={option.raceId}
                          >
                            {option.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span>Please select the race *</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {isStatus ? (
          <>
            <Form.Group className=" mt-md-3 py-md-2">
              <Form.Label className="me-2">
                Do you want to assign Bibs based on age category?
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  disabled
                  type="radio"
                  name="isAge"
                  id="isAge"
                  label="Yes"
                  defaultChecked={
                    getBibsDetails && getBibsDetails?.isAge === 1 ? true : false
                  }
                />

                <Form.Check
                  disabled
                  type="radio"
                  className="ms-md-3"
                  name="isAge"
                  id="isAge"
                  label="No"
                  value="0"
                  defaultChecked={
                    getBibsDetails && getBibsDetails?.isAge === 0 ? true : false
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className=" mt-md-3">
              <Form.Label className="me-2">
                Do you want to assign Prefix for Bibs ?
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  disabled
                  type="radio"
                  name="isPrefix"
                  id="isPrefix"
                  label="Yes"
                  value="1"
                  defaultChecked={
                    getBibsDetails && getBibsDetails?.isPrefix === 1
                      ? true
                      : false
                  }
                />

                <Form.Check
                  disabled
                  type="radio"
                  className="ms-md-3"
                  name="isPrefix"
                  id="isPrefix"
                  label="No"
                  value="0"
                  defaultChecked={
                    getBibsDetails && getBibsDetails?.isPrefix === 0
                      ? true
                      : false
                  }
                />
              </div>
            </Form.Group>
            {getBibsDetails && getBibsDetails?.isAge === 1 && (
              <div className="mt-md-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name of the Category</th>
                      <th>Gender</th>
                      <th>Min Age</th>
                      <th>Max Age</th>
                      <th>Prefix</th>
                      <th>Starting Bib No</th>
                      <th>Ending Bib No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getBibsDetails &&
                      getBibsDetails?.bibsdetails.map((item: any) => (
                        <tr key={item.id}>
                          <td>{item.category_name}</td>
                          <td>{item.gender}</td>
                          <td>{item.min_age}</td>
                          <td>{item.max_age}</td>
                          <td>{item.prefix}</td>
                          <td>{item.min_num}</td>
                          <td>{item.max_num}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {getBibsDetails && getBibsDetails?.isAge === 0 && (
              <div>
                <div className="row g-3 align-items-center my-md-2">
                  <div className="col-2">
                    <label className="col-form-label">Prefix</label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      placeholder="Prefix"
                      className="form-control"
                      name="noAgePrefix"
                      autoComplete="off"
                      disabled={true}
                      value={
                        getBibsDetails && getBibsDetails?.bibsdetails[0].prefix
                      }
                    />
                  </div>
                </div>
                <div className="row g-3 align-items-center my-md-2">
                  <div className="col-2">
                    <label className="col-form-label">Starting Bib No</label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      className="form-control"
                      name={`noAgeStartingBibNos`}
                      disabled={true}
                      autoComplete="off"
                      value={
                        getBibsDetails && getBibsDetails?.bibsdetails[0].min_num
                      }
                    />
                  </div>
                </div>
                <div className="row g-3 align-items-center my-md-2">
                  <div className="col-2">
                    <label className="col-form-label">Ending Bib No</label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      disabled={true}
                      className="form-control"
                      name={`noAgeEndingBibNos`}
                      autoComplete="off"
                      value={
                        getBibsDetails && getBibsDetails?.bibsdetails[0].max_num
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="mt-md-4">
                <Form.Group className=" mt-md-3">
                  <Form.Label className="me-2">
                    Do you want to assign corporate Bibs ?
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      id="isPrefix"
                      label="Yes"
                      value="1"
                      disabled
                      defaultChecked={
                        getBibsDetails && getBibsDetails?.isCorporate === 1
                          ? true
                          : false
                      }
                    />

                    <Form.Check
                      type="radio"
                      className="ms-md-3"
                      id="isPrefix"
                      label="No"
                      value="0"
                      disabled
                      defaultChecked={
                        getBibsDetails && getBibsDetails?.isCorporate === 0
                          ? true
                          : false
                      }
                    />
                  </div>
                </Form.Group>
              </div>
              <Form.Group className=" mt-md-3">
                <Form.Label className="me-2">
                  Do you want to assign prefix for corporate Bibs ?
                </Form.Label>
                <div className="d-flex">
                  <Form.Check
                    disabled
                    type="radio"
                    name="isCorporatePrefix"
                    id="isCorporatePrefix"
                    label="Yes"
                    value="1"
                    defaultChecked={
                      getBibsDetails && getBibsDetails?.isCorporatePrefix === 1
                        ? true
                        : false
                    }
                  />

                  <Form.Check
                    disabled
                    type="radio"
                    className="ms-md-3"
                    name="isCorporatePrefix"
                    id="isCorporatePrefix"
                    label="No"
                    value="0"
                    defaultChecked={
                      getBibsDetails && getBibsDetails?.isCorporatePrefix === 0
                        ? true
                        : false
                    }
                  />
                </div>
              </Form.Group>
              <div className="row g-3 align-items-center my-md-2">
                <div className="col-2">
                  <label className="col-form-label">Prefix</label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    placeholder="Prefix"
                    className="form-control"
                    name="noAgePrefix"
                    autoComplete="off"
                    disabled={true}
                    value={getBibsDetails && getBibsDetails?.prefix}
                  />
                </div>
              </div>
              <div className="row g-3 align-items-center my-md-2">
                <div className="col-2">
                  <label className="col-form-label">Starting Bib No</label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control"
                    name={`noAgeStartingBibNos`}
                    disabled={true}
                    autoComplete="off"
                    value={getBibsDetails && getBibsDetails?.min_num}
                  />
                </div>
              </div>
              <div className="row g-3 align-items-center my-md-2">
                <div className="col-2">
                  <label className="col-form-label">Ending Bib No</label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    disabled={true}
                    className="form-control"
                    name={`noAgeEndingBibNos`}
                    autoComplete="off"
                    value={getBibsDetails && getBibsDetails?.max_num}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {isDisplay && activeTab === "postRegistrationBibs" && (
              <div>
                <h6 className="my-md-4">
                  Number of participants without bib numbers assigned:
                  <span className="text-primary"> {raceCount}</span>
                </h6>
              </div>
            )}
            {!isViewEvent && (
              <>
                <h6>
                  Click the "Assign Bibs" button to initiate the bib assignment
                  process.
                </h6>
                {isDisplay && (
                  <DefaultButton
                    variant="primary"
                    type="button"
                    className="default-action-button text-white mt-3 px-2"
                    onClick={() => setIsAssignBibs(true)}
                    disabled={isStatus}
                  >
                    Assign Bibs
                  </DefaultButton>
                )}
              </>
            )}
            {isAssignBibs && (
              <>
                <Formik
                  onSubmit={handleAssignSubmit}
                  initialValues={IntialValues}
                  enableReinitialize={true}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    setValues,
                    handleBlur,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <h6 className="mt-md-4">
                        Please note that Bibs created cannot be edited/deleted.
                      </h6>
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <TextField
                            type="number"
                            name="noofDigitsBib"
                            label="No of digits bib number *"
                            min="0"
                            max="6"
                            onWheel={(event: any) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>
                      {/* <Row className="my-2 mt-md-4">
                            <h5 className="mt-md-3">
                              Please select the race to create Bibs.
                            </h5>
                            <Col xs={12} md={5}>
                              <div>
                                <select
                                  className="form-select"
                                  aria-label="Disabled select example"
                                  onChange={(e: any) => {
                                    setIsRaceType(e.target.value);
                                  }}
                                >
                                  <option value="">Choose an option</option>
                                  {isRaceList?.map((option: any) => {
                                    return (
                                      <>
                                        <option
                                          key={option.id}
                                          value={option.id}
                                          onChange={option.id}
                                        >
                                          {option.raceName}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                            </Col>
                          </Row> */}
                      <div></div>
                      <div>
                        <Form.Group className=" mt-md-3 py-md-2">
                          <Form.Label className="me-2">
                            Do you want to assign Bibs based on age category?
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Check
                              type="radio"
                              name="isAge"
                              id="isAge"
                              label="Yes"
                              value="1"
                              onChange={handleChange}
                            />

                            <Form.Check
                              type="radio"
                              className="ms-md-3"
                              name="isAge"
                              id="isAge"
                              label="No"
                              value="0"
                              onChange={handleChange}
                            />
                          </div>
                        </Form.Group>

                        <ErrorMessage
                          name="isAge"
                          component="div"
                          className="error text-danger"
                        />
                      </div>

                      <Form.Group className=" mt-md-3 py-md-2">
                        <Form.Label className="me-2">
                          Do you want to assign Prefix for Bibs ?
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Check
                            type="radio"
                            name="isPrefix"
                            id="isPrefix"
                            label="Yes"
                            value="1"
                            onChange={handleAssignedBibschange}
                          />

                          <Form.Check
                            type="radio"
                            className="ms-md-3"
                            name="isPrefix"
                            id="isPrefix"
                            label="No"
                            value="0"
                            onChange={handleAssignedBibschange}
                          />
                        </div>
                      </Form.Group>
                      <ErrorMessage
                        name="isPrefix"
                        component="div"
                        className="error text-danger"
                      />
                      {values.isAge === "1" && (
                        <Row className="mt-md-3">
                          <div>
                            {filteredData.length > 0 ? (
                              <>
                                <div>
                                  <h3 className="my-md-3">Male Participants</h3>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Name of the Category</th>
                                        <th>Gender</th>
                                        <th>Age ID</th>
                                        <th>Min Age</th>
                                        <th>Max Age</th>
                                        <th>Prefix</th>
                                        <th>Starting Bib No</th>
                                        <th>Ending Bib No</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredData
                                        .flatMap((data: any) =>
                                          data.age.filter(
                                            (item: any) =>
                                              item.gender.toLowerCase() ===
                                              "male"
                                          )
                                        )
                                        .map((item: any, index: any) => (
                                          <tr key={item.id}>
                                            <td id="category_name">
                                              {item.category_name}
                                            </td>
                                            <td id="gedner">{item.gender}</td>
                                            <td id="ID">{item.id}</td>
                                            <td id="min_age">{item.min_age}</td>
                                            <td id="max_age">{item.max_age}</td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Prefix"
                                                maxLength={2}
                                                className="form-control"
                                                name={`prefixes[${index}]`}
                                                autoComplete="off"
                                                disabled={!isbibassigned}
                                                onChange={(e) => {
                                                  const updatedPrefixes = [
                                                    ...values.prefixes,
                                                  ];
                                                  updatedPrefixes[index] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    prefixes: updatedPrefixes,
                                                  });
                                                }}
                                                onBlur={handleBlur}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Starting Bib No"
                                                className="form-control"
                                                name={`startingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedstartingBibNos =
                                                    [...values.startingBibNos];
                                                  updatedstartingBibNos[index] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    startingBibNos:
                                                      updatedstartingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Ending Bib No"
                                                className="form-control"
                                                name={`endingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedendingBibNos = [
                                                    ...values.endingBibNos,
                                                  ];
                                                  updatedendingBibNos[index] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    endingBibNos:
                                                      updatedendingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>

                                <div>
                                  <h4>Female Participants</h4>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Name of the Category</th>
                                        <th>Gender</th>
                                        <th>Age ID</th>
                                        <th>Min Age</th>
                                        <th>Max Age</th>
                                        <th>Prefix</th>
                                        <th>Starting Bib No</th>
                                        <th>Ending Bib No</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredData
                                        .flatMap((data: any) =>
                                          data.age.filter(
                                            (item: any) =>
                                              item.gender.toLowerCase() ===
                                              "female"
                                          )
                                        )
                                        .map((item: any, index: any) => (
                                          <tr key={item.id}>
                                            <td id="female_category_name">
                                              {item.category_name}
                                            </td>
                                            <td id="female_gedner">
                                              {item.gender}
                                            </td>
                                            <td id="female_ID">{item.id}</td>

                                            <td id="female_min_age">
                                              {item.min_age}
                                            </td>
                                            <td id="female_max_age">
                                              {item.max_age}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Prefix"
                                                className="form-control"
                                                name={`femlaePrefixes[${index}]`}
                                                maxLength={2}
                                                autoComplete="off"
                                                disabled={!isbibassigned}
                                                onChange={(e) => {
                                                  const updatedFemalePrefixes =
                                                    [...values.femlaePrefixes];
                                                  updatedFemalePrefixes[index] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    femlaePrefixes:
                                                      updatedFemalePrefixes,
                                                  });
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Starting Bib No"
                                                className="form-control"
                                                name={`femaleStartingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedFemaleStartingBibNos =
                                                    [
                                                      ...values.femaleStartingBibNos,
                                                    ];
                                                  updatedFemaleStartingBibNos[
                                                    index
                                                  ] = e.target.value;
                                                  setValues({
                                                    ...values,
                                                    femaleStartingBibNos:
                                                      updatedFemaleStartingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Ending Bib No"
                                                className="form-control"
                                                name={`femaleEndingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedFemaleEndingBibNos =
                                                    [
                                                      ...values.femaleEndingBibNos,
                                                    ];
                                                  updatedFemaleEndingBibNos[
                                                    index
                                                  ] = e.target.value;
                                                  setValues({
                                                    ...values,
                                                    femaleEndingBibNos:
                                                      updatedFemaleEndingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>

                                <div>
                                  <h4>Other Participants</h4>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Name of the Category</th>
                                        <th>Gender</th>
                                        <th>Age ID</th>
                                        <th>Min Age</th>
                                        <th>Max Age</th>
                                        <th>Prefix</th>
                                        <th>Starting Bib No</th>
                                        <th>Ending Bib No</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredData
                                        .flatMap((data: any) =>
                                          data.age.filter(
                                            (item: any) =>
                                              item.gender.toLowerCase() ===
                                              "other"
                                          )
                                        )
                                        .map((item: any, index: any) => (
                                          <tr key={item.id}>
                                            <td id="other_category_name">
                                              {item.category_name}
                                            </td>
                                            <td id="other_gedner">
                                              {item.gender}
                                            </td>
                                            <td id="other_ID">{item.id}</td>

                                            <td id="other_min_age">
                                              {item.min_age}
                                            </td>
                                            <td id="other_max_age">
                                              {item.max_age}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Prefix"
                                                className="form-control"
                                                name={`otherPrefixes[${index}]`}
                                                maxLength={2}
                                                autoComplete="off"
                                                disabled={!isbibassigned}
                                                onChange={(e) => {
                                                  const updatedOtherPrefixes = [
                                                    ...values.otherPrefixes,
                                                  ];
                                                  updatedOtherPrefixes[index] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    otherPrefixes:
                                                      updatedOtherPrefixes,
                                                  });
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Starting Bib No"
                                                className="form-control"
                                                name={`otherStartingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedOtherStartingBibNos =
                                                    [
                                                      ...values.otherStartingBibNos,
                                                    ];
                                                  updatedOtherStartingBibNos[
                                                    index
                                                  ] = e.target.value;
                                                  setValues({
                                                    ...values,
                                                    otherStartingBibNos:
                                                      updatedOtherStartingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Ending Bib No"
                                                className="form-control"
                                                name={`otherEndingBibNos[${index}]`}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                  const updatedOtherEndingBibNos =
                                                    [
                                                      ...values.otherEndingBibNos,
                                                    ];
                                                  updatedOtherEndingBibNos[
                                                    index
                                                  ] = e.target.value;
                                                  setValues({
                                                    ...values,
                                                    otherEndingBibNos:
                                                      updatedOtherEndingBibNos,
                                                  });
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ) : (
                              <p>
                                No race category found with the given ID:{" "}
                                {isRaceType}
                              </p>
                            )}
                          </div>
                        </Row>
                      )}
                      {values.isAge === "0" && (
                        <>
                          <div>
                            {isbibassigned && (
                              <div className="row g-3 align-items-center my-md-2">
                                <div className="col-2">
                                  <label className="col-form-label">
                                    Prefix
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <input
                                    type="text"
                                    placeholder="Prefix"
                                    className="form-control"
                                    name="noAgePrefix"
                                    autoComplete="off"
                                    disabled={!isbibassigned}
                                    value={values.noAgePrefix}
                                    onChange={handleChange}
                                  />
                                </div>
                                <ErrorMessage
                                  name="noAgePrefix"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            )}

                            <div className="row g-3 align-items-center my-md-2">
                              <div className="col-2">
                                <label className="col-form-label">
                                  Starting Bib No
                                </label>
                              </div>
                              <div className="col-auto">
                                <input
                                  type="text"
                                  placeholder="Starting Bib No"
                                  className="form-control"
                                  name={`noAgeStartingBibNos`}
                                  autoComplete="off"
                                  value={values.noAgeStartingBibNos}
                                  onChange={handleChange}
                                />
                              </div>
                              <ErrorMessage
                                name="noAgeStartingBibNos"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                            <div className="row g-3 align-items-center my-md-2">
                              <div className="col-2">
                                <label className="col-form-label">
                                  Ending Bib No
                                </label>
                              </div>
                              <div className="col-auto">
                                <input
                                  type="text"
                                  placeholder="Ending Bib No"
                                  className="form-control"
                                  name={`noAgeEndingBibNos`}
                                  autoComplete="off"
                                  value={values.noAgeEndingBibNos}
                                  onChange={handleChange}
                                />
                              </div>
                              <ErrorMessage
                                name="noAgeEndingBibNos"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div>
                        <Form.Group className=" mt-md-3">
                          <Form.Label className="me-2">
                            Does the event have corporate Bibs assignment?
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Check
                              type="radio"
                              name="isCorporate"
                              id="isCorporate"
                              label="Yes"
                              value="1"
                              onChange={handleChange}
                            />

                            <Form.Check
                              type="radio"
                              className="ms-md-3"
                              name="isCorporate"
                              id="isCorporate"
                              label="No"
                              value="0"
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            name="isCorporate"
                            component="div"
                            className="error text-danger"
                          />
                        </Form.Group>
                      </div>
                      {values.isCorporate === "1" && (
                        <>
                          <div>
                            <Form.Group className=" mt-md-3">
                              <Form.Label className="me-2">
                                Do you want to assign prefix for corporate Bibs
                                ?
                              </Form.Label>
                              <div className="d-flex">
                                <Form.Check
                                  type="radio"
                                  name="isCorporatePrefix"
                                  id="isCorporatePrefix"
                                  label="Yes"
                                  value="1"
                                  onChange={handleCorporateBibs}
                                />

                                <Form.Check
                                  type="radio"
                                  className="ms-md-3"
                                  name="isCorporatePrefix"
                                  id="isCorporatePrefix"
                                  label="No"
                                  value="0"
                                  onChange={handleCorporateBibs}
                                />
                              </div>
                              <ErrorMessage
                                name="isCorporatePrefix"
                                component="div"
                                className="error text-danger"
                              />
                            </Form.Group>
                          </div>
                          <div>
                            {isCorporateBibassigned && (
                              <div className="row g-3 align-items-center my-md-2">
                                <div className="col-2">
                                  <label className="col-form-label">
                                    Prefix
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <input
                                    type="text"
                                    className={`form-control `}
                                    placeholder="prefix"
                                    name="corporatePrefixes"
                                    value={values.corporatePrefixes}
                                    disabled={!isCorporateBibassigned}
                                    onChange={handleChange}
                                  />
                                </div>
                                <ErrorMessage
                                  name="corporatePrefixes"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            )}

                            <div className="row g-3 align-items-center my-md-2">
                              <div className="col-2">
                                <label className="col-form-label">
                                  Strating Bib No
                                </label>
                              </div>
                              <div className="col-auto">
                                <input
                                  type="number"
                                  placeholder="Strating Bib No"
                                  className={`form-control `}
                                  name="corporateMinNum"
                                  value={values.corporateMinNum}
                                  onChange={handleChange}
                                  onWheel={(event: any) =>
                                    event.currentTarget.blur()
                                  }
                                />
                              </div>
                              <ErrorMessage
                                name="corporateMinNum"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                            <div className="row g-3 align-items-center my-md-2">
                              <div className="col-2">
                                <label className="col-form-label">
                                  Ending Bib No
                                </label>
                              </div>
                              <div className="col-auto">
                                <input
                                  type="number"
                                  placeholder="Ending Bib No"
                                  className={`form-control `}
                                  name="corporateMaxNum"
                                  value={values.corporateMaxNum}
                                  onChange={handleChange}
                                  onWheel={(event: any) =>
                                    event.currentTarget.blur()
                                  }
                                />
                              </div>
                              <ErrorMessage
                                name="corporateMaxNum"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex justify-content-end">
                        <DefaultButton
                          variant="primary"
                          type="submit"
                          className="default-action-button text-white mt-3 px-5"
                        >
                          Submit
                        </DefaultButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PreRegistrationComponents;
