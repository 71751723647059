import { Card, Image } from "react-bootstrap";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";

interface ContactCardProps {
  contact: {
    id: number;
    title: string;
    description: string;
    data: string;
    contactLogo: string;
    type?: string;
    phrase: string;
    footerTitle: string;
  };
}

const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="mb-2 mb-sm-4 border-0"
      style={{
        flexShrink: "0",
        cursor: "pointer",
      }}
      position-relative
      //   onClick={() => navigate("/eventdetails")}
    >
      <Card.Body className="d-flex flex-column">
        <div className="d-flex align-items-center mb-1">
          <div style={{ marginLeft: "-8px" }}>
            <Image src={contact.contactLogo} alt="Organizer logo" />
          </div>
          <div className="ms-3">
            <h5 className="mb-0 fs-16 ff-ns">{contact.phrase}</h5>
          </div>
        </div>

        <Card.Text className="mt-2 mb-1 fs-14 ff-ns fw-400 text-TBlack contactCardDescription">
          {contact.description}
        </Card.Text>

        <h6 className="mb-0 mt-2 fs-15 ff-ns fw-bold">{contact.footerTitle}</h6>
        {contact.type === "address" ? (
          <>
            <p className="fs-14 ff-ns mt-2 mb-1">{contact.data}</p>
            <Card.Text className="fs-14 ff-ns fw-400 text-TBlack">
              My Home Hub, Hitech City Rd, Patrika Nagar, HITEC City, Hyderabad,
              Telangana 500081.
            </Card.Text>
          </>
        ) : (
          <Card.Text
            className="mt-1 ff-ns fs-14 fw-400 text-bliue"
            style={{ textDecoration: "underline" }}
          >
            {contact.data}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default ContactCard;
