export const NGOCategories = [
    { id: 1, name: "Human Rights" },
    { id: 2, name: "Environment" },
    { id: 3, name: "Healthcare" },
    { id: 4, name: "Education" },
    { id: 5, name: "Community and Rural Development" },
    { id: 6, name: "Women Empowerment" },
    { id: 7, name: "Children" },
    { id: 8, name: "Disaster Relief" },
    { id: 9, name: "Animal Welfare" },
    { id: 10, name: "Livelihood" },
    { id: 11, name: "Arts, Culture" },
    { id: 12, name: "Sports" },
    { id: 13, name: "Differently Abled" },
    { id: 14, name: "Elederly Care" },
  ];