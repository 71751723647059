import React from "react";
import { Col, Container, Row, Breadcrumb, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./JoinPhotoGraphy.module.scss";
import GetINTouchCard from "components/Cards/GetINTouchCard";
import FaqsComponent from "../Photos/FaqsComponent";
import { ContactData } from "api/ContactData";
import ContactCard from "components/Cards/ContactCard";

const JoinPhotoGraphy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb>
          <Breadcrumb.Item
            className="fs-14 fs-saira"
            href="#"
            onClick={() => navigate("/")}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active> Photography</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <Container className="position-relative px-0 pb-5">
        <div className={`${styles.JoincoverImage}`}>
          <Image
            src="/images/joinRunning.svg"
            width="100%"
            alt="MarathonBaner"
          />
        </div>
        <div className="d-flex justify-content-between py-md-3 px-md-3">
          <div className="d-flex flex-column">
            <p className="font-saira fw-700 fs-14 p-0 m-0 my-md-2">
              Discover our latest offers and updates
            </p>
            <p className="ff-ns fw-400 fs-14 p-0 m-0">
              Stay informed with our timely information and promotions
            </p>
          </div>
          <div></div>
        </div>
      </Container>
      <Container className="my-md-5">
        <Row>
          <Col md={7}>
            <div>
              <h1 className="ff-saira fw-700 fs-40">
                Capture the moments that tell a thousand stories with our
                photography team
              </h1>
              <p className="ff-ns fw-400 fs-14">
                Join our photography team and enjoy the benefits of capturing
                unforgettable moments and being part of a creative community.
              </p>
            </div>
          </Col>
          <Col md={5}>
            <Image
              src="/images/joiningRunningGroup.svg"
              width="100%"
              style={{ height: "283px" }}
              alt="PhotoBanner"
            />
          </Col>
        </Row>
      </Container>
      <Container className="mb-md-5">
        <GetINTouchCard
          handleSubmit={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Container>
      <Container className="my-md-5 py-md-4">
        <Row>
          <Col md={8}>
            <div className="d-flex flex-column justify-content-center">
              <h4 className="ff-saira fw-700 fs-40">
                Join our Photography Team and Capture Unforgettable Moments
              </h4>
              <p className="ff-ns fw-400 fs-14">
                As a photographer on the iFinish team, you will be responsible
                for capturing the excitement and emotion of our events. Your
                photos will help athletes relive their accomplishments and
                inspire others to join in the fun.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <Image
              src="/images/Join.svg"
              width="100%"
              style={{ height: "283px" }}
              alt="PhotoBanner"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <FaqsComponent />
      </Container>
      <Container className="my-5 pb-5">
        <Row className="mt-5">
          {ContactData.map((contact) => (
            <Col xs={12} md={4} key={contact.id}>
              <ContactCard contact={contact} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default JoinPhotoGraphy;
