import React from "react";
import { useDrag, useDrop } from "react-dnd";
import ViewEvent from "utils/ViewEvent";

const ItemType = "ADDITIONAL_ITEM";

const DraggableOrgItem = ({
  index,
  data,
  moveItem,
  handleStatusChange,
  handleShowManageModal,
}: any) => {
  const isViewEvent = ViewEvent();
  const [{ isDragging }, ref]: any = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (dragged: any) => {
      if (dragged.index !== index) {
        moveItem(dragged.index, index);
        dragged.index = index;
      }
    },
  });

  const handleOptionChange = (e: any) => {
    const value = e.target.value;
    const updatedData = { ...data, field_status: parseInt(value) };
    handleStatusChange(updatedData, index);
  };

  return (
    <div>
      <div
        ref={(node) => ref(drop(node))}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className="row manage_form_table py-2 mb-md-2 px-md-3 rounded d-flex align-items-center"
      >
        <div className="col-md-3 h6 my-2">{data.label_name}</div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <div>
            <label className="radio-label">
              <input
                type="radio"
                value="0"
                className="me-md-1 radio-input"
                checked={data.field_status === 0}
                onChange={handleOptionChange}
              />
              <span className="custom-radio" />
              Required
            </label>
          </div>
          <div className="mx-md-3">
            <label className="radio-label">
              <input
                type="radio"
                value="1"
                className="me-md-1 radio-input"
                checked={data.field_status === 1}
                disabled={data.mandatory_field === 1}
                onChange={handleOptionChange}
              />
              <span className="custom-radio" />
              Optional
            </label>
          </div>
          <div>
            <label className="radio-label">
              <input
                type="radio"
                value="2"
                className="me-md-1 radio-input"
                checked={data.field_status === 2}
                disabled={data.mandatory_field === 1}
                onChange={handleOptionChange}
              />
              <span className="custom-radio" />
              Don't Show
            </label>
          </div>
        </div>
        <div className="col-md-3 d-flex justify-content-end">
          {!isViewEvent && (
            <button
              className="btn text-primary border-0"
              onClick={() => handleShowManageModal(data)}
              // disabled={data.field_status === 0}
              disabled={data.mandatory_field === 1}
            >
              Manage
              <span>
                <img src="/images/Filter_alt.png" alt="filter" />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const AddDragAndDrop = ({
  additionalItems,
  setAdditionalItems,
  handleShowManageModal,
  keys,
}: any) => {
  const moveItem = (fromIndex: any, toIndex: any) => {
    const updatedItems = [...additionalItems];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setAdditionalItems(updatedItems);
  };
  const handleStatusChange = (updatedData: any, index: any) => {
    const updatedItems = [...additionalItems];
    updatedItems[index] = updatedData;
    setAdditionalItems(updatedItems);
  };
  // const handleSave = () => {
  //   console.log("Organization Section - Final Positions:");
  //   orgItems.forEach((item, index) => {
  //     console.log(`${item[0]}: Position ${index + 1}, Option: ${item[1]}`);
  //   });
  // };
  return (
    <div>
      <h3 className="my-md-2">{keys[3]?.replace(/_/g, " ")}</h3>
      <div>
        {additionalItems?.map((item: any, index: any) => (
          <DraggableOrgItem
            key={index}
            index={index}
            data={item}
            moveItem={moveItem}
            handleStatusChange={handleStatusChange}
            handleShowManageModal={handleShowManageModal}
          />
        ))}
      </div>
    </div>
  );
};

export default AddDragAndDrop;
