import DefaultButton from "components/DefaultButton/DefaultButton";

const ForbiddenPage: React.FC = () => {
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <h4 className="text-primary d-flex align-items-center">
        <span style={{ fontSize: "102px" }}>4</span>
        <img className="mx-2" height={88} src="/images/403.svg" alt="403" />
        <span style={{ fontSize: "102px" }}>3</span>
      </h4>
      <div className="my-1 text-center">
        <p className="text-center my-3 fs-18 px-2 ff-saira">
          You don't have access to this page. Please contact support if this
          wasn't supposed to happen.
        </p>
        <DefaultButton
          type="button"
          className="px-4 rounded-pill text-white"
          variant="primary"
          onClick={() => (window.location.href = "/")}
        >
          Go to Home
        </DefaultButton>
      </div>
    </div>
  );
};

export default ForbiddenPage;
