/* eslint-disable array-callback-return */
import { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface GalleryProps {
  officalData: any;
}

export const EventGallery: React.FC<GalleryProps> = ({ officalData }) => {
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState("");
  const handleCloseProfileModal = async () => {
    setIsOpenProfileModal(false);
  };

  return (
    <>
      <Modal
        show={isOpenProfileModal}
        onHide={handleCloseProfileModal}
        centered
        className="modal-xl"
      >
        <Modal.Header
          className="border-white px-md-4 mt-2"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="justify-content-center d-flex ">
            <TransformWrapper>
              <TransformComponent>
                <img
                  className="Gallery-Image"
                  key={isOpenImage}
                  src={
                    `${process.env.REACT_APP_URL}${isOpenImage}` ??
                    "/images/cardBackground.png"
                  }
                  alt={isOpenImage}
                  // onLoad={(event) => handleImageLoad(sponsor.id, event)}
                  // style={{ height: 400, width: 400 }}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <Row className="px-4 my-2">
          {officalData?.map((sponsor: any) => {
            return (
              <Col key={sponsor.id} md={4}>
                <Row className="my-2">
                  <div
                    className="d-flex justify-content-center align-items-center px-5"
                    onClick={() => {
                      setIsOpenImage(sponsor?.url);
                      setIsOpenProfileModal(true);
                    }}
                  >
                    <img
                      key={sponsor.id}
                      className="mx-5 shadow"
                      src={
                        `${process.env.REACT_APP_URL}${sponsor?.url}` ??
                        "/images/cardBackground.png"
                      }
                      alt={sponsor.id}
                      // onLoad={(event) => handleImageLoad(sponsor.id, event)}
                      style={{ height: 200 }}
                    />
                  </div>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

// {
//   events?.map((race: Race) => {
//     return (
//       <div className={`${styles.eventPriceCard} mb-2 p-0`}>
//         <EventPriceCard
//           key={race?.id}
//           race={race}
//           upgradingRaceInfo={upgradingRaceInfo}
//           isFromUpdateDetails={isFromUpdateDetails}
//         />
//       </div>
//     );
//   });
// }

export default EventGallery;
