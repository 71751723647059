import { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DefaultInput from "components/Inputs/DefaultInput";
import CartContext from "store/cart/cartContext";
import RegistrationContext from "store/cart/registerationContext";
import styles from "./EventRegistration.module.scss";
import DefaultButton from "components/DefaultButton/DefaultButton";
import FormModal from "./FormModal";
import {
  useEventState,
  useEventDispatch,
  useClearEvents,
} from "store/event/EventContext";
import { Race } from "types/Race";
import { useCreateRegistration } from "api/CreateRaceRegistrations";
import EventPaymentSuccessModal from "./PaymentModal/EventPaymentSuccessModal";
import { toast } from "react-toastify";
import { CustomFieldOption, DynamicParticipant } from "types/CustomTypes";
import Payment from "components/PaymentGateway/Payment";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import { useCreateOrder } from "api/CreateOrderId";
import { useCheckPaymentStatus } from "api/PaymentStatus";
import { useUserProfile } from "api/GetUserProfile";
import Loader from "components/Loader/Loader";

interface EventRegistrationProps {
  eventId: number;
}
const EventRegistration: React.FC<EventRegistrationProps> = ({ eventId }) => {
  const [activeEventName, setActiveEventName] = useState("");
  const [raceId, setRaceId] = useState(0);
  const [raceAmount, setRaceAmount] = useState(0);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editingParticipant, setEditingParticipant] = useState<
    DynamicParticipant<CustomFieldOption> | undefined
  >(undefined);
  const [participantIndexToUpdate, setParticipantIndexToUpdate] = useState(0);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [registeredParticipants, setRegisteredParticipants] = useState<any>();

  const { items, clearCart, currentEventId } = useContext(CartContext);
  const { setIsOpenRegisterationUI } = useContext(RegistrationContext);
  const state = useEventState();
  const dispatch = useEventDispatch();
  const clearEvents = useClearEvents();
  const createRegistrationMutation = useCreateRegistration();
  const createOrderMutation = useCreateOrder();
  const paymentMethod = useRef(null);
  const [paymentData, setPaymentData] = useState<any>({});
  const checkPaymentStatusMutation = useCheckPaymentStatus();
  const [isOrgTermsChecked, setIsOrgTermsChecked] = useState(false);
  const [isIfinishTermsChecked, setIsIfinishTermsChecked] = useState(false);
  const [ageData, setAgeData] = useState([]);
  const [eventStartDate, setEventStartDate] = useState("");
  const [organiserTermsAndConditions, setOrganiserTermsAndConditions] =
    useState<string>("");
  const [isCharityAdded, setIsCharityAdded] = useState(false);
  const [finalPaymentData, setFinalPaymentData] = useState({
    totalAmount: 0,
    totalEventAmount: 0,
    totalTshirtAmount: 0,
    totalCurrentAmount: 0,
    totalRaceAmountAfterDiscount: 0,
    registrationId: 0,
    gstType: 1,
    gst: 0,
    processingFee: 0,
    gstProcessingAmount: 0,
    totalPaymentAmount: 0,
    charityAmount: 0,
    charityBibAmount: 0,
  });

  const {
    data: userData,
    isLoading: userDataLoading,
    error: userDataError,
  } = useUserProfile();

  const updateRegisteredParticipants = (
    participantId: number,
    raceId: number,
    updatedIndividualParticipantDetails: any,
    updatedTotalPaymentDetails: any
  ) => {
    setRegisteredParticipants((prevParticipants: any) => {
      const updatedRegisteredParticipants = prevParticipants.map(
        (race: any) => {
          if (race.raceId === raceId) {
            race["participants"] = race["participants"].map(
              (participant: any) => {
                if (participant.id === participantId) {
                  return {
                    ...participant,
                    ...updatedIndividualParticipantDetails,
                  };
                } else {
                  return participant;
                }
              }
            );
            return race;
          } else {
            return race;
          }
        }
      );

      return updatedRegisteredParticipants;
    });

    setFinalPaymentData((prevFinalData) => {
      return {
        ...prevFinalData,
        ...updatedTotalPaymentDetails,
      };
    });
  };

  const numberOfCartItems = items.reduce((curNumber, item: any) => {
    return curNumber + item?.itemAmount;
  }, 0);
  const [Razorpay] = useRazorpay();
  const userDetails = userData?.data;

  const userName = `${userData?.data?.first_name} ${userData?.data?.last_name}`;

  const formik = useFormik({
    initialValues: {
      name: !!userName ? userName : "",
      email: !!userData?.data?.email ? userData?.data?.email : "",
      phoneCode: !!userDetails?.country_code ? userDetails?.country_code : "",
      phoneNumber: !!userDetails?.mobile_number
        ? userDetails?.mobile_number
        : "",
      country: !!userDetails?.country ? userDetails?.country : "",
      eventId: eventId,
      count: numberOfCartItems,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phoneCode: Yup.string().required("Code is required"),
      phoneNumber: Yup.string().required("Mobile Number is required"),
      country: Yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      // dispatch({ type: "UPDATE_CONTACT_PERSON_DETAILS", payload: values });
    },
  });

  // useEffect(() => {
  //   if (!!state.contactPersonDetails.name) {
  //     const updatedValues = { ...state.contactPersonDetails, eventId: eventId };
  //     formik.setValues(updatedValues);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.contactPersonDetails]);

  useEffect(() => {
    if (currentEventId !== state.contactPersonDetails.eventId) {
      clearEvents();
    }
    dispatch({
      type: "UPDATE_CONTACT_PERSON_DETAILS",
      payload: { eventId: eventId, count: numberOfCartItems },
    });
  }, []);

  const handleEditParticipant = (
    participant: DynamicParticipant<CustomFieldOption>,
    index: number
  ) => {
    setShowModal(true);
    setEditingParticipant(participant);
    setParticipantIndexToUpdate(index);
  };

  const handleAddNew = (race: Race) => {
    setRaceAmount(race?.itemTotal / race?.itemAmount);
    setActiveEventName(race?.ticket_name);
    setRaceId(race?.id);
    setAgeData(race?.age);
    setEditingParticipant(undefined);
    setEventStartDate(race?.tkt_evnt_start_date);
    setShowModal(true);
  };

  const handleDeleteParticipant = (
    eventName: string,
    participantIndex: number
  ) => {
    dispatch({
      type: "DELETE_PARTICIPANT",
      payload: {
        eventNameToDelete: eventName,
        participantIndex: participantIndex,
      },
    });
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingParticipant(undefined);
  };

  const handlePayment = useCallback(
    async (
      registeredParticipants: any,
      state: any,
      finalPaymentData: any,
      isCharityAdded: boolean
    ) => {
      // if (isCharityAdded) {
      //   registeredParticipants.gst = finalPaymentData?.gstProcessingAmount;
      //   registeredParticipants.processingFee =
      //     finalPaymentData?.processingAmount;
      //   registeredParticipants.totalAmount = finalPaymentData?.totalAmount;
      //   registeredParticipants.totalCurrentAmount =
      //     finalPaymentData?.totalCurrentAmount;
      // }
      const { data: orderData, error: orderError } =
        await createOrderMutation.mutateAsync({
          registrationId: finalPaymentData?.registrationId,
        });

      if (orderError) {
        toast.error("Error creating Order Id");
      }

      // const payableAmount = isCharityAdded
      //   ? (finalPaymentData?.charityAmount +
      //       finalPaymentData?.gstProcessingAmount +
      //       finalPaymentData?.processingAmount +
      //       finalPaymentData?.totalAmount) *
      //     100
      //   : finalPaymentData?.totalPaymentAmount * 100;

      const payableAmount = finalPaymentData?.totalPaymentAmount * 100;

      const options = {
        key: `${process.env.REACT_APP_RAZORPAY_KEY_ID}`,
        amount: payableAmount.toString(),
        currency: "INR",
        name: "iFinish",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: orderData,

        handler: async (res: any) => {
          if (res.razorpay_payment_id) {
            const paymentData = await checkPaymentStatusMutation.mutateAsync({
              paymentId: res.razorpay_payment_id,
              razorpayOrderId: res.razorpay_order_id,
              razorPaySignature: res.razorpay_signature,
            });
            if (paymentData.status) {
              setPaymentData(paymentData.data);
            }
            setIsOpenProfileModal(true);
            await clearCart();
            await clearEvents();
          }
        },

        prefill: {
          name: state.contactPersonDetails.name,
          email: state.contactPersonDetails.email,
          contact:
            state.contactPersonDetails.phoneCode +
            state.contactPersonDetails.phoneNumber,
        },
        notes: {
          address: "My Home Hub, Hitech City",
        },
        theme: {
          color: "#ff5050",
        },
        modal: {
          ondismiss: function () {
            setIsOpenProfileModal(true);
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.submit", (response: any) => {
        paymentMethod.current = response.method;
      });

      // To get payment id in case of failed transaction.
      rzpay.on("payment.failed", async (response: any) => {
        const paymentData = await checkPaymentStatusMutation.mutateAsync({
          paymentId: response.error.metadata?.payment_id,
          razorpayOrderId: response.error.metadata?.order_id,
          razorPaySignature: "",
        });
        setPaymentData(paymentData.data);
      });

      rzpay.open();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Razorpay]
  );

  const handleCreateTicketSubmit = async () => {
    handlePayment(
      registeredParticipants,
      state,
      finalPaymentData,
      isCharityAdded
    );
  };

  const handleRegister = async () => {
    try {
      // if (state.contactPersonDetails?.email) {
      const response = await createRegistrationMutation.mutateAsync({
        participantData: state,
      });

      setOrganiserTermsAndConditions(response?.disclaimer);

      if (response.status) {
        setFinalPaymentData(response.data.totalPaymentDetails);
        setRegisteredParticipants(response.data.participantPayment);

        setIsOpenPayment(true);
      } else {
        toast.error(`${response.message}`);
      }
      // } else {
      //   toast.error(
      //     "Please add Contact Person Details before submitting the form."
      //   );
      // }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleCloseProfileModal = () => {
    if (
      paymentData?.paymentStatus === "authorized" ||
      paymentData?.paymentStatus === "captured" ||
      paymentData?.paymentStatus === "created"
    ) {
      setIsOpenRegisterationUI(false);
    } else {
      setIsOpenProfileModal(false);
    }
  };

  const noOfParticipantsAdded = (race: Race) => {
    const numberOfParticipantsAdded = state.events
      .filter((event) => event.eventName === race?.ticket_name)
      .reduce((count, event) => count + event.participants.length, 0);

    return numberOfParticipantsAdded;
  };

  const getTotalAddedParticipants = () => {
    return state.events.reduce(
      (count, event) => count + event.participants.length,
      0
    );
  };
  if (userDataLoading) {
    return <Loader />;
  }

  if (userDataError) {
    toast.error("Error fetching users data!!");
  }

  return (
    <>
      <div className={styles.canvasContainer}>
        <div className={styles.canvasSubContainer}>
          {/* <Form onSubmit={formik.handleSubmit}> */}
          {!isOpenPayment ? (
            <div className={`${styles.canvasContent} pb-4 px-3`}>
              <div>
                <div className="mb-4">
                  <h1 className="fw-600 fs-saira mb-0">Participants</h1>
                  {/* <div
                    className={`py-sm-4 py-md-2 my-1 ${styles.contactPersonDetails}`}
                  >
                    <p className={`${styles.title} fs-saira fw-600`}>
                      Emergency Contact Person Details
                    </p>
                    <Container>
                      <Row className="flex-column flex-sm-row mb-0 mb-sm-4">
                        <Col className="mb-3 mb-sm-0" sm={6}>
                          <DefaultInput
                            label="Name *"
                            type="text"
                            placeholder="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange("name")}
                            isError={
                              formik.touched.name && !!formik.errors.name
                            }
                            error={formik.errors.name}
                          />
                        </Col>
                        <Col sm={6} className="mb-3 mb-sm-0">
                          <DefaultInput
                            label="Email *"
                            type="email"
                            placeholder="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange("email")}
                            isError={
                              formik.touched.email && !!formik.errors.email
                            }
                            error={formik.errors.email}
                          />
                        </Col>
                      </Row>
                      <Row className="flex-column flex-sm-row">
                        <Col sm={6} className="mb-3 mb-sm-0">
                          <div className="container px-0">
                            <div className="row flex-row">
                              <div className="col-3">
                                <DefaultInput
                                  label="Code *"
                                  type="text"
                                  placeholder="+91"
                                  value={formik.values.phoneCode}
                                  onChange={formik.handleChange("phoneCode")}
                                  isError={
                                    formik.touched.phoneCode &&
                                    !!formik.errors.phoneCode
                                  }
                                  error={formik.errors.phoneCode}
                                />
                              </div>
                              <div className="col">
                                <DefaultInput
                                  label="Mobile Number *"
                                  type="text"
                                  placeholder="Mobile Number"
                                  value={formik.values.phoneNumber}
                                  onChange={formik.handleChange("phoneNumber")}
                                  isError={
                                    formik.touched.phoneNumber &&
                                    !!formik.errors.phoneNumber
                                  }
                                  error={formik.errors.phoneNumber}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <div className="col-sm-6 mb-1 mb-sm-0">
                          <DefaultInput
                            label="Country *"
                            type="text"
                            placeholder="Country"
                            value={formik.values.country}
                            onChange={formik.handleChange("country")}
                            isError={
                              formik.touched.country && !!formik.errors.country
                            }
                            error={formik.errors.country}
                          />
                        </div>
                      </Row>
                      <div className="d-flex w-100 justify-content-end py-3">
                        <Button
                          className="default-action-button d-none d-sm-block fw-bold text-white ms-4"
                          variant="primary"
                          type="submit"
                        >
                          SAVE
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          className="default-action-button d-sm-none fw-bold text-white ms-0 ms-sm-4 w-100 btn-block"
                          variant="primary"
                        >
                          SAVE
                        </Button>
                      </div>
                    </Container>
                  </div> */}
                </div>

                <Container
                  className={`${styles.accordianContainer} gx-0 gx-sm-4`}
                >
                  <Accordion className={`${styles.customAccordian} py-2`}>
                    {items.map((race: Race, idx) => (
                      <Accordion.Item
                        eventKey={`${idx}`}
                        bsPrefix={`${styles.accordianItem}`}
                        key={idx}
                        className="px-2 mb-2 border"
                      >
                        <Accordion.Header
                          className="px-0"
                          bsPrefix={`${styles.accordianHeader}`}
                        >
                          <div
                            className={`w-100 me-3 d-flex justify-content-between align-items-center ${styles.headerContent}`}
                          >
                            <div className="d-flex flex-column justify-content-center mt-4">
                              <p
                                className={`mb-0 fw-600 ff-saira formTitleInEventRegisteration`}
                              >
                                {race?.ticket_name} {" - "}
                                {race.itemAmount}{" "}
                                {race.itemAmount > 1
                                  ? "Participants"
                                  : "Participant"}
                              </p>
                              <p
                                className={`pt-2 ps-1 ${
                                  noOfParticipantsAdded(race) ===
                                  race.itemAmount
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {noOfParticipantsAdded(race)} out of{" "}
                                {race.itemAmount}{" "}
                                {race.itemAmount > 1
                                  ? "participants"
                                  : "participant"}{" "}
                                added
                              </p>
                            </div>

                            <DefaultButton
                              className="default-action-button"
                              variant="outline-primary"
                              type="button"
                              onClick={() => handleAddNew(race)}
                              disabled={
                                noOfParticipantsAdded(race) === race.itemAmount
                                  ? true
                                  : false
                              }
                            >
                              Add New
                            </DefaultButton>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                          {state.events
                            .filter(
                              (event) => event.eventName === race?.ticket_name
                            )
                            .map((event, index) => (
                              <div key={index}>
                                {event.participants.map(
                                  (participant, pIndex) => (
                                    <div
                                      key={pIndex}
                                      className="ms-1 ms-md-4 py-2 d-flex justify-content-between align-items-center"
                                    >
                                      <p className="fs-20 ff-saira fw-500 mb-0">
                                        {pIndex + 1}.{" "}
                                        {`${participant.first_name} ${participant.last_name}`}
                                      </p>
                                      <div className="d-flex">
                                        <DefaultButton
                                          className="default-action-button fw-bold ms-2 ms-sm-4"
                                          variant="outline-primary"
                                          type="button"
                                          onClick={() =>
                                            handleEditParticipant(
                                              participant,
                                              pIndex
                                            )
                                          }
                                        >
                                          Edit
                                        </DefaultButton>
                                        <DefaultButton
                                          className="default-action-button fw-bold ms-2 ms-sm-4"
                                          variant="outline-primary"
                                          type="button"
                                          onClick={() =>
                                            handleDeleteParticipant(
                                              race?.ticket_name,
                                              pIndex
                                            )
                                          }
                                        >
                                          Delete
                                        </DefaultButton>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Container>
              </div>
            </div>
          ) : (
            <Payment
              registeredParticipants={registeredParticipants}
              updateRegisteredParticipants={updateRegisteredParticipants}
              isOrgTermsChecked={isOrgTermsChecked}
              setIsOrgTermsChecked={setIsOrgTermsChecked}
              isIfinishTermsChecked={isIfinishTermsChecked}
              setIsIfinishTermsChecked={setIsIfinishTermsChecked}
              organiserTermsAndConditions={organiserTermsAndConditions}
              eventId={eventId}
              finalPaymentData={finalPaymentData}
              setFinalPaymentData={setFinalPaymentData}
              isCharityAdded={isCharityAdded}
              setIsCharityAdded={setIsCharityAdded}
              setIsOpenPayment={setIsOpenPayment}
            />
          )}
        </div>
        <div className={`${styles.registerContainer}`}>
          <div className="container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
            <div className="ms-sm-2 mb-3 mb-sm-0">
              <div className="d-flex flex-row flex-sm-column align-items-center align-items-sm-start  justify-content-center me-0 me-sm-4 me-md-0">
                <p
                  className={`${styles.textDark} d-flex align-items-center fs-14 fw-bold mb-0`}
                >
                  {numberOfCartItems}
                  <span
                    className={`${styles.textGrey} d-inline-block ff-saira fs-14 fw-normal mb-0 ms-1`}
                  >
                    Tickets
                  </span>
                </p>
                {/* <p
                    className="text-primary fs-14 ff-ns mb-0 ms-2 ms-sm-0 cursor-pointer"
                    onClick={() => setIsOpenPayment(false)}
                  >
                    View Details
                  </p> */}
              </div>
            </div>

            <div className="d-none d-md-flex flex-column flex-sm-row justify-content-center align-items-sm-end">
              {isOpenPayment ? (
                <>
                  <Button
                    type="button"
                    onClick={() => setIsOpenPayment(false)}
                    className="default-action-button d-none d-md-block fw-bold text-white ms-4"
                    variant="primary"
                  >
                    PREVIOUS
                  </Button>
                  <Button
                    type="button"
                    onClick={handleCreateTicketSubmit}
                    className="default-action-button d-none d-md-block fw-bold text-white ms-4"
                    variant="primary"
                    disabled={!isOrgTermsChecked || !isIfinishTermsChecked}
                  >
                    PROCEED TO PAYMENT
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  onClick={handleRegister}
                  className="default-action-button d-none d-md-block fw-bold text-white ms-4"
                  variant="primary"
                  disabled={numberOfCartItems !== getTotalAddedParticipants()}
                >
                  REGISTER
                </Button>
              )}
            </div>
            <div className="d-flex d-md-none flex-column w-100">
              {isOpenPayment ? (
                <>
                  <Button
                    type="button"
                    onClick={() => setIsOpenPayment(false)}
                    className="default-action-button fw-bold text-white"
                    variant="primary"
                  >
                    PREVIOUS
                  </Button>
                  <Button
                    type="button"
                    onClick={handleCreateTicketSubmit}
                    className="default-action-button fw-bold text-white mt-2"
                    variant="primary"
                    disabled={!isOrgTermsChecked || !isIfinishTermsChecked}
                  >
                    PROCEED TO PAYMENT
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  onClick={handleRegister}
                  className="default-action-button fw-bold text-white mt-2"
                  variant="primary"
                  disabled={numberOfCartItems !== getTotalAddedParticipants()}
                >
                  REGISTER
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* </Form> */}
      </div>

      {showModal && (
        <FormModal
          show={showModal}
          onHide={handleCloseModal}
          eventName={activeEventName}
          eventId={eventId}
          raceId={raceId}
          raceAmount={raceAmount}
          isEditing={!!editingParticipant}
          participant={editingParticipant}
          participantIndex={participantIndexToUpdate}
          ageData={ageData}
          eventStartDate={eventStartDate}
        />
      )}
      <EventPaymentSuccessModal
        isOpenProfileModal={isOpenProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
        paymentData={paymentData}
      />
    </>
  );
};

export default EventRegistration;
