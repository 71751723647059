import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Tabs, Tab, Container, Breadcrumb } from "react-bootstrap";
import RegisterationContext from "store/cart/registerationContext";
import PriceOptions from "./PriceOptions/PriceOptions";
import Testimonial from "components/Testimonial/Testimonial";
import OffCanvas from "components/OffCanvas/OffCanvas";
import ReferAFriend from "components/ReferAFriend/ReferAFriend";
import EventRegistration from "components/EventRegistration/EventRegistration";
import styles from "./ViewOnlyEventDetailsPage.module.scss";
import Profile from "components/Profile/Profile";
import { useEventRaceList } from "api/EventRaceApi";
import { useUserEventSponsors } from "api/GetEventSponsers";
import { useNavigate, useParams } from "react-router-dom";
import { useEventIdFromSlug } from "api/EventIdFromSlug";
import { toast } from "react-toastify";
import { getEventRemainingDays, dateFormatConvertDDMMYYYY } from "utils/utils";
import { EventDetails } from "components/EventRegistration/EventDetails";
import Disclaimer from "components/EventRegistration/Disclaimer";
import ChangeCategory from "./ViewOnlyEventsTabs/ModifyBookings/ChangeCategory";
import UserRefunds from "./ViewOnlyEventsTabs/UserRefunds/UserRefunds";
import Loader from "components/Loader/Loader";
import OfficialSponsors from "components/EventRegistration/OfficialSponsors";
import EventGallery from "components/EventRegistration/EventGallery";

const ViewOnlyEventDetailsPage = () => {
  const [eventRemainingDays, setEventRemainingDays] = useState<number>(0);
  const [showModifyBookings, setShowModifyBookings] = useState<boolean>(false);

  const location = useLocation();

  const navigation = useNavigate();
  const { isOpenRegisterationUI, closeRegisterationUI } =
    useContext(RegisterationContext);

  const [isOpenProfileModal, setIsOpenProfileModal] = useState<boolean>(false);
  const handleCloseProfileModal = () => {
    setIsOpenProfileModal(false);
  };

  const slug = useParams()?.eventSlug;

  useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("mybookings")) {
      setShowModifyBookings(true);
    }
  }, []);

  const {
    data: eventInfo,
    isLoading: eventInfoLoading,
    error: eventInfoError,
  } = useEventIdFromSlug(slug);

  useEffect(() => {
    if (eventInfo && eventInfo.data) {
      setEventRemainingDays(
        getEventRemainingDays(eventInfo?.data?.event_start_date)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo?.data]);

  const eventId = eventInfo?.data?.eventId;
  const {
    data: eventRaceList,
    isLoading: eventRaceListLoading,
    error: eventRaceListError,
  } = useEventRaceList({ eventId });

  if (eventInfoError) {
    toast.error("Error loading event details. Please try again later.");
  }

  if (eventRaceListError) {
    toast.error("Error loading event race details. Please try again later.");
  }

  const {
    data: sponsorsList,
    isLoading: sponsorsListLoading,
    error: sponsorsListError,
  } = useUserEventSponsors(eventId);

  if (eventInfoLoading || eventRaceListLoading || sponsorsListLoading) {
    return <Loader />;
  }

  const eventDetails = eventRaceList?.data;

  return (
    <>
      <Container className="mt-4 mb-2">
        <Breadcrumb className="fs-14 fs-saira">
          <Breadcrumb.Item href="#" onClick={() => navigation("/")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigation("/myevents")}>
            Events
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Event Details</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <div className="container-fluid position-relative px-0">
        <div className="container px-0">
          <div className={`${styles.imageContainer}`}>
            <img
              className="px-0"
              src={`${eventDetails?.banner}`}
              width="100%"
              alt="Event Banner"
            />
          </div>

          <div className={`${styles.eventsList} mt-4`}>
            {/* <div
              className={`${styles.certifiedBy} mt-2 py-4 d-none d-sm-flex justify-content-center align-items-center`}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    height={96}
                    width={124}
                    src="/images/certified.svg"
                    alt="certified"
                  />
                </div>
                <div className="text-start ms-4">
                  <p className="mb-1 font-saira fs-12 fw-600">Certified By</p>
                  <p className="mb-0 font-weight-normal font-saira">
                    Association of International Marathons and Distance Races
                  </p>
                </div>
              </div>
            </div> */}
            <div className={`${styles.organiserPaddingY} px-4`}>
              <div className="d-none d-sm-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    height={64}
                    width={64}
                    src={`${
                      eventDetails?.logo ?? "/images/defaultorganiser.svg"
                    }`}
                    alt="event organiser"
                  />
                  <div className="ms-3">
                    <h1 className="mb-0 fw-bold fs-32 font-saira">
                      {eventDetails?.organiserName}
                    </h1>
                    {/* <p className="fw-600 font-size-base font-saira mb-0">
                      Thane, Maharashtra
                    </p> */}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="message">
                    <img
                      className="d-inline-block"
                      width={22}
                      height={20}
                      src="/images/message.svg"
                      alt="message"
                    />
                  </div>
                  <Button
                    className={`${styles.followButton} ms-4`}
                    variant="outline-primary"
                  >
                    FOLLOW
                  </Button>
                </div>
              </div>
              <div className="d-block d-sm-none">
                <div className="d-flex align-items-center">
                  <img
                    height={36}
                    width={38}
                    src={`${
                      eventDetails?.logo ?? "/images/defaultorganiserlogo.svg"
                    }`}
                    alt="event organiser"
                  />
                  <div className="ms-3 d-flex flex-column justify-content-between align-items-center">
                    <p className="mb-0 fs-16 fw-600 font-saira">
                      {eventDetails?.organiserName}
                    </p>
                  </div>
                </div>
                <Button
                  style={{
                    fontFamily: "saira",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  className="mt-4 w-100 btn-block"
                  variant="outline-primary"
                >
                  FOLLOW
                </Button>
              </div>
            </div>

            {/* {eventRemainingDays > 0 && (
              <div className={`${styles.infoBanner} py-2 mb-3`}>
                <p
                  className={`font-saira text-light fs-16 fw-600 mb-0 text-center glow-spot px-3`}
                >
                  🎉 Countdown Alert! Join Us in {eventRemainingDays} Day
                  {`${eventRemainingDays > 1 ? "s" : ""}`} (
                  {dateFormatConvertDDMMYYYY(eventDetails?.event_start_date)})
                  for an Unforgettable Experience! 🎉
                </p>
              </div>
            )} */}

            {eventDetails?.earlyBirdRemaingdatesStatus &&
              eventDetails?.earlyBirdRemaingdates > 0 && (
                <div className="event-announcement text-center py-1">
                  <h4 className="mb-0 d-inline">
                    Early bird promo ends in...{" "}
                  </h4>
                  <h1
                    className={`mb-0 fw-bold ms-1 d-inline ${
                      eventDetails?.earlyBirdRemaingdates <= 7 && "text-primary"
                    }`}
                  >
                    {eventDetails?.earlyBirdRemaingdates} days
                  </h1>
                </div>
              )}

            <div className={`${styles.paddingX} mt-4 mb-3 pb-5`}>
              <Tabs
                defaultActiveKey={`${
                  showModifyBookings ? "eventDetails" : "eventDetails"
                }`}
                id="event-tabs"
                className="event-nav-tabs border-bottom"
              >
                <Tab eventKey="eventDetails" title="Event Details">
                  <EventDetails description={eventDetails?.description} />
                </Tab>
                {showModifyBookings && (
                  <Tab
                    mountOnEnter
                    eventKey="changeCategory"
                    title="Change Category"
                  >
                    <ChangeCategory />
                  </Tab>
                )}
                {/* {showModifyBookings && (
                  <Tab mountOnEnter eventKey="userRefunds" title="Refunds">
                    <UserRefunds />
                  </Tab>
                )} */}
                <Tab eventKey="priceOptions" title="Price Options">
                  <PriceOptions
                    events={eventDetails?.tickets}
                    serverTime={eventDetails?.currentDate}
                  />
                </Tab>
                <Tab eventKey="gallery" title="Gallery">
                  {" "}
                  <EventGallery officalData={eventDetails?.gallery} />
                </Tab>
                <Tab eventKey="officialSponsors" title="Official Sponsors">
                  {sponsorsList.status ? (
                    <OfficialSponsors officalData={sponsorsList?.data} />
                  ) : (
                    <p className="fs-14 ff-ns fw-400 text-TBlack my-2">
                      No Officical Sponsors
                    </p>
                  )}
                </Tab>
                <Tab eventKey="disclaimer" title="Disclaimer">
                  <Disclaimer disclaimer={eventDetails?.disclaimer} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <ReferAFriend />
      <Testimonial
        name="Aisha"
        designation="Senior Developer"
        testimonialDescription="
        Engaging, insightful, and inspiring – the testimonial marathon was a powerful journey of shared experiences that left a lasting impact on everyone involved.
        "
      />
      <Profile
        isOpenProfileModal={isOpenProfileModal}
        handleCloseProfileModal={handleCloseProfileModal}
      />
      <OffCanvas
        showOffCanvasModal={isOpenRegisterationUI}
        handleCloseOffCanvasModal={closeRegisterationUI}
      >
        <EventRegistration eventId={eventRaceList?.data?.id} />
      </OffCanvas>
    </>
  );
};

export default ViewOnlyEventDetailsPage;
