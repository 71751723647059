import DefaultButton from "components/DefaultButton/DefaultButton";
import { Icon } from "components/Icon";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import ViewOfflineModel from "./ViewOfflineModel";
import AddOfflineCharity from "./AddOfflineCharity";

const OfflineCharity = () => {
  const axiosPrivate = useAxiosPrivate();
  const [getOfflineData, setGetOfflineData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [modalData, setModalData] = useState<any[]>();
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);
  const [addOfflineCharity, setAddOfflineCharity] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(false);
  function getOfflineCharity() {
    axiosPrivate({
      url: "/getOfflineCharityDonation",
      method: "GET",
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status) {
          setGetOfflineData(data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getOfflineCharity();
  }, [reRender]);
  const itemsPerPageOptions = [10, 25, 50, 100];
  const approvedTotalRows = getOfflineData.length;
  const offlineFilterResult = getOfflineData.filter(
    (e: any) =>
      e.first_name && e.first_name.includes(searchValue?.toLowerCase())
  );
  const handleAcceptButton = (data: any) => {
    setModalData(data);
    setShowOfflineModal(true);
  };
  const handleCloseModal = () => setShowOfflineModal(false);
  const handleCloseAddOfflineCharity = () => setAddOfflineCharity(false);
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        paddingLeft: "5px",
        backgroundColor: "#ffe9e9",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        paddingLeft: "5px",
        paddingRight: "8px",
      },
    },
  };
  const offlineData = [
    {
      name: "First Name",
      selector: (row: any) => row.first_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Last Name",
      selector: (row: any) => row.last_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
      width: "150px",
    },
    {
      name: "Mobile Number",
      selector: (row: any) => row.mobile_number,
      sortable: true,
      width: "150px",
    },
    {
      name: "PAN Number",
      selector: (row: any) => row.pan_no,
      sortable: true,
      width: "130px",
    },
    {
      name: "Charity Name",
      selector: (row: any) => (row.charity_bibs_id ? row.charity_bibs_id : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row: any) => (row.amount ? row.amount : "—"),
      sortable: true,
      width: "150px",
    },
    {
      name: "View",
      cell: (row: any) => (
        <DefaultButton
          onClick={() => handleAcceptButton(row)}
          type="button"
          className="fw-bold text-white"
          variant="primary"
        >
          View
        </DefaultButton>
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-end my-md-3">
        <DefaultButton
          type="submit"
          className="default-action-button fw-bold text-white px-md-5"
          variant="primary"
          onClick={() => setAddOfflineCharity(true)}
        >
          Add Offline Donation
        </DefaultButton>
      </div>
      <div>
        <div className="d-flex justify-content-end">
          <div className="px-md-3 py-md-2 border border-primary rounded d-flex align-items-center w-25">
            <input
              type="text"
              className="w-100 border-0"
              placeholder="Search by name"
              style={{ outline: "none" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Icon iconName="Search" className="text-primary" size={18} />
          </div>
        </div>
        <div className="mt-md-2">
          <DataTable
            pagination
            paginationTotalRows={approvedTotalRows}
            paginationRowsPerPageOptions={itemsPerPageOptions}
            paginationPerPage={10}
            columns={offlineData}
            data={offlineFilterResult}
            customStyles={tableCustomStyles}
          />
        </div>
      </div>
      {showOfflineModal && (
        <ViewOfflineModel
          showOfflineModal={showOfflineModal}
          handleClose={handleCloseModal}
          modalData={modalData}
        />
      )}
      {addOfflineCharity && (
        <AddOfflineCharity
          setReRender={setReRender}
          addOfflineCharity={addOfflineCharity}
          handleCloseAddOfflineCharity={handleCloseAddOfflineCharity}
        />
      )}
    </>
  );
};

export default OfflineCharity;
