import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import RaceForm from "./RaceForm";
import { useNavigate, useParams, useLocation } from "react-router";
// import axios from "utils/axios";
import { useAuth } from "context/AuthContext";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useEvent } from "context/EventContext";
import { Icon } from "components/Icon";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Loader from "components/Loader/Loader";
import ViewEvent from "utils/ViewEvent";
import { decryptText, encryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const RaceIntialPage = ({ handleSubmitNextHandler }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [intialScreen, setIntialScreen] = useState(false);
  const [isEventId, setIsEventId] = useState<number>();
  const [isRaceId, setIsRaceId] = useState<number>();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  const axiosPrivate = useAxiosPrivate();
  const isViewEvent = ViewEvent();
  let isAddMode: any;
  let eventId: any;

  useEffect(() => {
    if ((isEditEvent || isViewEvent) && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id, isViewEvent]);
  const { token } = useAuth();
  const { storeEventId, storeRaceId, setEventId, setRaceId } = useEvent();
  const Navigate = useNavigate();
  const [isRaceList, setIsRaceList] = useState<any>();
  const [isViewMoreDataRaceList, setIsViewMoreDataRaceList] = useState<any>();
  const [isCreateButton, setIsCreateButton] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleRaceTab = (eventId: number, raceId: number) => {
    setIsEventId(eventId);
    setRaceId(String(raceId));
    setIsRaceId(raceId);
    setIntialScreen(true);
    if (!isViewEvent) {
      Navigate(`/org/editEvents/${encryptText(eventId)}`);
    } else {
      Navigate(`/org/vieweventdata/${encryptText(eventId)}`);
    }
  };

  const handleReloadRaceScreen = () => {
    // axios.post(
    //   "/eventRacesList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/eventRacesList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        setIsRaceList(res.data.data);
      })
      .catch((err) => {
        return;
      });
  };
  function EventRaceList() {
    setIsLoading(true);
    // axios.post(
    //   "/eventRacesList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/eventRacesList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        setIsRaceList(res.data.data);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (Id || storeRaceId) {
      EventRaceList();
      handleReloadRaceScreen();
    }
  }, [Id, storeRaceId, intialScreen]);
  const handleShow = (raceId: number) => {
    setShow(true);

    const filteredModalData: any = isRaceList.filter(
      (data: any) => data.id === raceId
    );

    setIsViewMoreDataRaceList(filteredModalData);
  };
  const handleClose = () => setShow(false);

  const ViewMoreModal = () => {
    return (
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div className="d-flex justify-content-between px-md-4">
            <div>
              <h3>Age Categories</h3>
            </div>
            <div>
              <Icon
                iconName="X"
                size={30}
                color="primary"
                className="text-gray2 bg-white"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <div className="px-md-5">
            {isViewMoreDataRaceList &&
            isViewMoreDataRaceList[0].age.length > 0 ? (
              <>
                {isViewMoreDataRaceList &&
                  isViewMoreDataRaceList.map((data: any) =>
                    data.age.filter(
                      (item: any) => item.gender.toLowerCase() === "male"
                    )
                  )[0].length > 0 && (
                    <div>
                      <h5 className="my-md-3">Male Participants</h5>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name of the Category</th>
                            {/* <th>Age ID</th> */}
                            <th>Min Age</th>
                            <th>Max Age</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isViewMoreDataRaceList
                            .flatMap((data: any) =>
                              data.age.filter(
                                (item: any) =>
                                  item.gender.toLowerCase() === "male"
                              )
                            )
                            .map((item: any, index: any) => (
                              <tr key={item.id}>
                                <td id="category_name">{item.category_name}</td>
                                {/* <td id="ID">{item.id}</td> */}
                                <td id="min_age">{item.min_age}</td>
                                <td id="max_age">{item.max_age}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {isViewMoreDataRaceList &&
                  isViewMoreDataRaceList.map((data: any) =>
                    data.age.filter(
                      (item: any) => item.gender.toLowerCase() === "female"
                    )
                  )[0].length > 0 && (
                    <div>
                      <h5>Female Participants</h5>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name of the Category</th>
                            <th>Min Age</th>
                            <th>Max Age</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isViewMoreDataRaceList
                            .flatMap((data: any) =>
                              data.age.filter(
                                (item: any) =>
                                  item.gender.toLowerCase() === "female"
                              )
                            )
                            .map((item: any, index: any) => (
                              <tr key={item.id}>
                                <td id="female_category_name">
                                  {item.category_name}
                                </td>
                                {/* <td id="female_ID">{item.id}</td> */}
                                <td id="female_min_age">{item.min_age}</td>
                                <td id="female_max_age">{item.max_age}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {isViewMoreDataRaceList &&
                  isViewMoreDataRaceList.map((data: any) =>
                    data.age.filter(
                      (item: any) => item.gender.toLowerCase() === "other"
                    )
                  )[0].length > 0 && (
                    <div>
                      <h5>Other Participants</h5>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name of the Category</th>
                            {/* <th>Age ID</th> */}
                            <th>Min Age</th>
                            <th>Max Age</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isViewMoreDataRaceList
                            .flatMap((data: any) =>
                              data.age.filter(
                                (item: any) =>
                                  item.gender.toLowerCase() === "other"
                              )
                            )
                            .map((item: any) => (
                              <tr key={item.id}>
                                <td id="other_category_name">
                                  {item.category_name}
                                </td>
                                {/* <td id="other_ID">{item.id}</td> */}
                                <td id="other_min_age">{item.min_age}</td>
                                <td id="other_max_age">{item.max_age}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </>
            ) : (
              <div className="text-center my-md-5">
                <h5>There are no race categories specified for any gender.</h5>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const RaceCard = ({
    raceId,
    eventId,
    raceName,
    distance,
    amount,
    startDate,
    endDate,
    age,
  }: any) => {
    return (
      <Row>
        <Col className="col-md-4">
          <Card style={{ width: "18rem" }} className="p-md-3">
            <div className="d-flex justify-content-between align-items-center py-md-3">
              <div>
                <h4>
                  Race Name : <span className="text-primary">{raceName}</span>
                </h4>
              </div>
            </div>

            {/* race details */}
            <div>
              <div>
                <h5>
                  <span className="fw-500">Start Date : </span>
                  <span className="fw-600 text-primary">
                    {moment(startDate).format("DD-MM-YYYY")}
                  </span>
                </h5>
              </div>
            </div>
            <div className="my-md-3">
              <div>
                <h5>
                  <span className="fw-500">End Date : </span>
                  <span className="fw-600 text-primary">
                    {moment(endDate).format("DD-MM-YYYY")}
                  </span>
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-md-1">
              <div>
                <h5>
                  <span className="fw-500">Distance : </span>
                  <span className="fw-600 text-primary">{distance}</span>
                </h5>
              </div>
              <div>
                <h5>
                  <span className="fw-500">Amount : </span>
                  <span className="fw-600 text-primary"> {amount}</span>
                </h5>
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-between py-md-2">
              <div
                onClick={() =>
                  handleRaceTab(eventId || storeEventId, raceId || storeRaceId)
                }
                style={{ cursor: "pointer" }}
              >
                <Icon
                  iconName="PencilSquare"
                  size={20}
                  color="primary"
                  className="text-gray2 bg-white"
                />
              </div>

              {/* {!isViewEvent && (
                <div>
                  <Icon
                    iconName="Trash"
                    size={20}
                    color="red"
                    className="text-gray2 bg-white mx-md-3"
                  />
                </div>
              )} */}
              <div>
                <Button
                  variant="outline-primary"
                  className="default-action-button"
                  type="button"
                  onClick={() => handleShow(raceId || storeRaceId)}
                >
                  View Categories
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  const handleCreate = () => {
    setIntialScreen(true);
    setIsCreateButton(true);
    setRaceId(null);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ViewMoreModal />
      <Container>
        {!intialScreen && (
          <>
            <div className="d-flex justify-content-between align-items-center my-md-3">
              <div>
                <h2 className="text-primary">Races</h2>
              </div>
              {!isViewEvent && (
                <div>
                  {(!isRaceList || isRaceList.length === 0) && (
                    <DefaultButton
                      type="button"
                      variant="outline-primary"
                      className=" default-action-button"
                      onClick={handleCreate}
                    >
                      Create Race
                    </DefaultButton>
                  )}
                  {!intialScreen && isRaceList && isRaceList.length > 0 && (
                    <DefaultButton
                      type="button"
                      variant="outline-primary"
                      className=" default-action-button"
                      onClick={handleCreate}
                    >
                      Create Race
                    </DefaultButton>
                  )}
                </div>
              )}
            </div>
            {(!isRaceList || isRaceList.length === 0) && (
              <p>No Races to Display. Click on Create Race to add.</p>
            )}
          </>
        )}

        {!intialScreen && isRaceList && isRaceList.length > 0 && (
          <>
            <div className="row">
              {isRaceList.map((items: any, index: any) => {
                const StartdateObject = moment(
                  items.startDate,
                  "YYYY-MM-DD HH:mm:ss"
                );
                const formattedStartDate = StartdateObject.format("YYYY-MM-DD");
                const EnddateObject = moment(
                  items.endDate,
                  "YYYY-MM-DD HH:mm:ss"
                );
                const formattedEndDate = EnddateObject.format("YYYY-MM-DD");

                return (
                  <div key={items.id} className="col-md-3 col-12 my-4 mx-md-3">
                    <RaceCard
                      raceId={items.id}
                      eventId={items.eventId}
                      raceName={items.raceName}
                      distance={items.distance}
                      amount={items.amount}
                      startDate={formattedStartDate}
                      endDate={formattedEndDate}
                    />
                  </div>
                );
              })}
            </div>
            {!isViewEvent && (
              <div className="d-flex justify-content-end">
                <DefaultButton
                  onClick={() => handleSubmitNextHandler()}
                  type="submit"
                  variant="outline-primary"
                  className="default-action-button"
                >
                  Next
                </DefaultButton>
              </div>
            )}
          </>
        )}

        {intialScreen && (
          <RaceForm
            isEventId={isEventId}
            isRaceId={isRaceId}
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadRaceScreen}
            isCreateButton={isCreateButton}
            setIsCreateButton={setIsCreateButton}
          />
        )}
      </Container>
    </>
  );
};

export default RaceIntialPage;
