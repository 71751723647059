import React, { useState } from "react";

interface Props {
  children: React.ReactNode;
}

interface RegisterationContextType {
  isOpenRegisterationUI: boolean;
  setIsOpenRegisterationUI: React.Dispatch<React.SetStateAction<boolean>>;
  closeRegisterationUI: () => void;
}

const RegisterationContext = React.createContext<RegisterationContextType>({
  isOpenRegisterationUI: false,
  setIsOpenRegisterationUI: () => {},
  closeRegisterationUI: () => {},
});

export const RegisterationContextProvider: React.FC<Props> = (props) => {
  const [isOpenRegisterationUI, setIsOpenRegisterationUI] =
    useState<boolean>(false);

  const closeRegisterationUI = () => {
    setIsOpenRegisterationUI(false);
  };

  const registerationContext = {
    isOpenRegisterationUI: isOpenRegisterationUI,
    setIsOpenRegisterationUI: setIsOpenRegisterationUI,
    closeRegisterationUI: closeRegisterationUI,
  };

  return (
    <RegisterationContext.Provider value={registerationContext}>
      {props.children}
    </RegisterationContext.Provider>
  );
};

export default RegisterationContext;
