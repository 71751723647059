/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";
import DefaultButton from "components/DefaultButton/DefaultButton";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import { useEvent } from "context/EventContext";
import { ToastContainer } from "react-toastify";
import Loader from "components/Loader/Loader";
import EarlyBirdDiscount from "OrganizerPages/coupons/EarlyBirdDiscount";
import BulkDiscount from "OrganizerPages/coupons/BulkDiscount";
import ClientSpecificDisCount from "OrganizerPages/coupons/ClientSpecificDisCount";
import PromotionCode from "OrganizerPages/coupons/PromotionCode";
import { decryptText } from "utils/utils";
import IsEditEvent from "utils/IsEditEvent";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";

const CouponForm = ({
  isEventId,
  isRaceId,
  setIntialScreen,
  handleReloadScreen,
  handleSubmitNextHandler,
}: any) => {
  const { token } = useAuth();
  const { id } = useParams();
  const isEditEvent = IsEditEvent();
  const [Id, setId] = useState();
  // let isAddMode: any;
  let eventId: any;
  const { storeEventId } = useEvent();
  const [isCouponTypeData, setIsCouponTypeData] = useState<any>();
  const [isCouponType, setIsCouponType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  function discountList() {
    setIsLoading(true);
    // axios.post(
    //   "/discountList",
    //   {
    //     eventId: Id || storeEventId,
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //     },
    //   }
    // );
    axiosPrivate({
      url: "/discountList",
      data: { eventId: Id || storeEventId },
      method: "POST",
    })
      .then((res) => {
        let arrayData: { name: any; status: boolean }[] = [];
        res.data.data.map((items: any) => {
          arrayData.push({
            name: items.discount_name,
            status: !items.status,
          });
        });
        setIsCouponTypeData(arrayData);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (isEditEvent && id) {
      eventId = decryptText(String(id));
      setId(eventId);
      // isAddMode = !eventId && !StoredId;
    }
  }, [eventId, id]);
  useEffect(() => {
    if (Id || storeEventId) {
      discountList();
      handleReloadScreen();
    }
  }, [Id]);

  const handleClick = () => {
    handleReloadScreen();
    setIntialScreen(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <ToastContainer />
        <div className="mt-md-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="text-primary">Create Coupon</h2>
            </div>

            <div>
              <DefaultButton
                type="submit"
                variant="outline-primary"
                className=" default-action-button"
                onClick={handleClick}
              >
                Back
              </DefaultButton>
            </div>
          </div>
        </div>
        <>
          {/* <p className="text-darkgray fw-400 my-3 fs-16">
            Please select the type of dicount/coupon:{" "}
            <span className="text-primary ms-1">*</span>
          </p> */}

          <Row className="my-2">
            <Col xs={12} md={5}>
              <div>
                <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
                  <div className="col">
                    <select
                      onChange={(e) => {
                        // setType(e.target.value);
                        setIsCouponType(e.target.value);
                      }}
                      required
                    >
                      <option value=""></option>
                      {isCouponTypeData?.map((option: any) => {
                        return (
                          <>
                            <option
                              key={option.id}
                              value={option.id}
                              disabled={option.status}
                              onChange={option.id}
                            >
                              {option.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <span>Please select the type of dicount/coupon *</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>

        {isCouponType === "Early Bird Discount" && (
          <EarlyBirdDiscount
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadScreen}
          />
        )}

        {isCouponType === "Promotion Codes" && (
          <PromotionCode
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadScreen}
          />
        )}

        {isCouponType === "Client Specific Discount" && (
          <ClientSpecificDisCount
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadScreen}
          />
        )}

        {isCouponType === "Bulk Discount" && (
          <BulkDiscount
            setIntialScreen={setIntialScreen}
            handleReloadScreen={handleReloadScreen}
          />
        )}
      </Container>
    </>
  );
};

export default CouponForm;
