import React, { useCallback, useState } from "react";
import { Form as FormData, Formik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultButton from "components/DefaultButton/DefaultButton";
import SpotRegistrationForm from "components/EventRegistration/SpotRegistrationForm";
import { Icon } from "components/Icon";
import useAxiosPrivate from "utils/axiosusermodule/useAxiosPrivate";
import { decryptText } from "utils/utils";
import ExpoParticipantEditDetailsModal from "./ExpoParticipantEditDetailsModal";
import { TextArea } from "utils/FormControls";

const NmdcExpoBibCollection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const axioPrivate = useAxiosPrivate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    assigned_bibs: "",
    mobile_number: "",
    email: "",
    order_number: "",
  });
  const [activeField, setActiveField] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [participantId, setParticipantId] = useState<any>(null);
  const [suggestedData, setSuggestedData] = useState<any>([]);
  const [isParticipantTable, setIsParticipantTable] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [participantData, setParticipantData] = useState<any>(null);
  const [bibAndPhotosCollected, setBibAndPhotosCollected] = useState(false);

  const resetField = useCallback(() => {
    setFormData({
      assigned_bibs: "",
      mobile_number: "",
      email: "",
      order_number: "",
    });
    setActiveField(null);
    setSuggestions([]);
    setParticipantId(null);
    setIsParticipantTable(false);
    setSuggestedData([]);
    setParticipantData(null);
    setBibAndPhotosCollected(false);
  }, []);

  const handleOpenSpotRegistrationModal = () => setShowModal(true);
  const handleCloseSpotRegistrationModal = () => setShowModal(false);

  const searchFilter = (field: string, value: string) => {
    axioPrivate({
      method: "POST",
      url: "/expoRegistrationSearch",
      data: {
        eventId: decryptText(String(id)),
        type: field,
        value: value,
      },
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setSuggestions(data);
        } else {
          toast.error(message);
        }
      })
      .catch(() => {});
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value;
    setParticipantId(null);
    setIsParticipantTable(false);
    setFormData((prev) => ({ ...prev, [activeField!]: "" }));
    setFormData((prev) => ({ ...prev, [field]: value }));
    setActiveField(field);
    if (value.length > 3) {
      searchFilter(field, value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any, value: string) => {
    setFormData((prev) => ({ ...prev, [activeField!]: value }));
    setParticipantId(suggestion.id);
    setSuggestions([]);
    handleSearch(suggestion.id);
  };

  function getBibAndPhoto(selectParticipantID: any) {
    const postData = {
      participantId: selectParticipantID,
    };
    axioPrivate({
      method: "POST",
      url: "/getExpoCollection",
      data: postData,
    })
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          setParticipantData(data);
          setBibAndPhotosCollected(true);
        } else {
          setBibAndPhotosCollected(false);
        }
      })
      .catch((err) => {
        return;
      });
  }

  const handleSearch = (selectParticipantID: any) => {
    axioPrivate({
      method: "POST",
      url: "/expoRegistrationSearchResult",
      data: {
        eventId: decryptText(String(id)),
        type: activeField,
        value: selectParticipantID,
      },
    })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          setIsParticipantTable(true);
          setSuggestedData(data[0]);
          getBibAndPhoto(selectParticipantID);
        } else {
          toast.error(message);
        }
      })
      .catch(() => {});
  };

  const fields = [
    { id: "assigned_bibs", name: "Bib Number" },
    { id: "mobile_number", name: "Mobile Number" },
    { id: "email", name: "Email" },
    { id: "order_number", name: "Order Number" },
    // { id: "first_name", name: "First Name" },
  ];

  const {
    first_name,
    last_name,
    email,
    mobile_number,
    order_number,
    approve_status,
    bib_number,
    is_elite,
    ticket_name,
    tshirt,
    remarks,
    field_names,
  } = suggestedData;

  const initialValues = {
    isTshirtCollected: bibAndPhotosCollected
      ? participantData && participantData?.is_tshirt_collected === 0
        ? "0"
        : "1"
      : "",
    isBibCollected: bibAndPhotosCollected
      ? participantData && participantData?.is_bib_collected === 0
        ? "0"
        : "1"
      : "",
    remarks: bibAndPhotosCollected
      ? participantData && participantData?.remarks
      : "",
  };

  const validationSchema = Yup.object().shape({
    isTshirtCollected: Yup.string().required("TShirt is required"),
    isBibCollected: Yup.string().required("bib is required"),
    remarks: Yup.string(),
  });
  const handleBibSubmit = (values: any) => {
    const postData = {
      eventId: decryptText(String(id)),
      participantId: participantId,
      isTshirtCollected: values.isTshirtCollected,
      isBibCollected: values.isBibCollected,
      remarks: values.remarks ?? "",
    };
    axioPrivate({
      method: "POST",
      url: "/expoCollection",
      data: postData,
    })
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(`${message}`, { autoClose: 1500 });
          setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            const selectField: any = activeField;
            setFormData((prev) => ({ ...prev, [selectField]: "" }));
            resetField();
          }, 2000);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        return;
      });
  };

  const onHide = () => {
    setShow(false);
  };
  return (
    <>
      <Container fluid className="mt-4 px-md-5">
        <Row className="mt-4">
          <Col>
            <div className="d-flex align-items-center ">
              <span
                className="text-primary fs-12 fw-600"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="ChevronLeft" size={18} className="me-2" />
                Back to Dashboard
              </span>
            </div>
            <h6 className="fs-24 mt-md-2">Participant Details</h6>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <DefaultButton
                className="default-action-button text-white me-md-3"
                variant="primary"
                type="button"
                onClick={handleOpenSpotRegistrationModal}
              >
                Spot Registration
              </DefaultButton>
            </div>
          </Col>
        </Row>
        <Row className="px-md-5">
          <Col md={11}>
            <Row>
              {fields.map((field) => (
                <Col key={field.id}>
                  <div className="form-floating input-bx mt-md-2 form-row w-100 form-control">
                    <div className="col">
                      <input
                        type="text"
                        placeholder=""
                        value={formData[field.id as keyof typeof formData]}
                        onChange={(e) => handleInputChange(e, field.id)}
                      />
                      <span>{field.name}</span>
                    </div>
                  </div>
                  {activeField === field.id && suggestions.length > 0 && (
                    <ul className="suggestions-dropdown list-group">
                      {suggestions.map((suggestion, index) => (
                        <li
                          className="list-group-item"
                          key={index}
                          onClick={() => {
                            handleSuggestionClick(
                              suggestion,
                              suggestion[field.id]
                            );
                          }}
                        >
                          <span>
                            {suggestion[field.id] +
                              "   (" +
                              suggestion.first_name +
                              ")"}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={1}>
            <div className="my-md-2 pt-md-1">
              <DefaultButton
                className="default-action-button text-white"
                variant="primary"
                type="button"
                onClick={() => handleSearch(participantId)}
              >
                Search
              </DefaultButton>
            </div>
          </Col>
        </Row>

        {isParticipantTable && (
          <>
            <Row className="mt-4">
              <Col>
                <h6 className="mb-0 fs-16 ff-saira fw-600">Details </h6>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <button
                  className="submit_button_outline border-0 mx-3 py-2"
                  style={{ backgroundColor: "#FF5050", color: "#ffffff" }}
                  onClick={(e: any) => {
                    setShow(true);
                  }}
                >
                  Edit Details
                </button>
              </Col>
            </Row>

            <div className="bg-pink mt-2">
              <Row className="d-flex justify-content-between">
                <Row className="mt-2 d-flex justify-content-between">
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                First Name{" "}
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {first_name}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Last Name{" "}
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600 ">
                            {last_name}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Email
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {email}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <Row className="d-flex justify-content-between">
                <Row className="mt-2 d-flex justify-content-between">
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Mobile Number
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {mobile_number}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Field Names
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {field_names}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Approver Remarks
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {remarks}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <Row className="d-flex justify-content-between">
                <Row className="mt-2 d-flex justify-content-between">
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Order Number
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {order_number}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Approve Status
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {approve_status === "A"
                              ? "Approved"
                              : approve_status === "M"
                              ? "More Info"
                              : "Pending"}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Bib Number
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {bib_number}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <Row className="d-flex justify-content-between">
                <Row className="mt-2 d-flex justify-content-between">
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Is Elite
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {is_elite === 1 ? "Yes" : "No"}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                Ticket Name
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {ticket_name}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={4} className="">
                    <Container className="p-0 m-0">
                      <Row className="d-flex justify-content-start align-items-center mx-0">
                        <Col className="col-md-5 px-0">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col md="auto">
                              <h6 className="mb-0 fs-16 ff-saira fw-400">
                                TShirt
                              </h6>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="col-md-5 d-flex justify-content-start align-items-center">
                          <span className="fw-bold me-md-3">:</span>
                          <h6 className="ms-2 mb-0 fs-16 ff-saira fw-600">
                            {tshirt}
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Row>

              <div className="mt-md-3">
                <Formik
                  onSubmit={handleBibSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >
                  {({ errors, handleSubmit, handleChange, values }) => {
                    return (
                      <FormData noValidate onSubmit={handleSubmit}>
                        <Row className="my-md-3">
                          <Col
                            md={3}
                            className="d-flex flex-column align-items-center justify-content-center"
                          >
                            <Form.Label className="">
                              T-Shirt Collected{" "}
                              <span className="text-primary">*</span>
                            </Form.Label>
                            <Form.Group>
                              <Form.Group>
                                <Form.Check
                                  inline
                                  label="No "
                                  name="isTshirtCollected"
                                  type="radio"
                                  isInvalid={!!errors.isTshirtCollected}
                                  onChange={handleChange}
                                  checked={values.isTshirtCollected === "0"}
                                  value="0"
                                />

                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="isTshirtCollected"
                                  type="radio"
                                  isInvalid={!!errors.isTshirtCollected}
                                  onChange={handleChange}
                                  checked={values.isTshirtCollected === "1"}
                                  value="1"
                                />
                              </Form.Group>
                            </Form.Group>
                          </Col>
                          <Col
                            md={3}
                            className="d-flex flex-column align-items-center justify-content-center"
                          >
                            <Form.Label>
                              Bib Collected{" "}
                              <span className="text-primary">*</span>
                            </Form.Label>
                            <Form.Group>
                              <Form.Group>
                                <Form.Check
                                  inline
                                  label="No "
                                  name="isBibCollected"
                                  type="radio"
                                  isInvalid={!!errors.isBibCollected}
                                  onChange={handleChange}
                                  checked={values.isBibCollected === "0"}
                                  value="0"
                                />

                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="isBibCollected"
                                  type="radio"
                                  isInvalid={!!errors.isBibCollected}
                                  onChange={handleChange}
                                  checked={values.isBibCollected === "1"}
                                  value="1"
                                />
                              </Form.Group>
                            </Form.Group>
                          </Col>

                          <Col md={6} className="d-flex flex-column">
                            <TextArea name="remarks" rows={5} label="Remarks" />
                            {/* <div className="row rounded">
                              <label>Reg.Remarks</label>
                              <textarea
                                className="border-gray rounded my-2"
                                rows={4}
                                value={remarks}
                              ></textarea>
                            </div> */}
                          </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-end my-md-4">
                          <DefaultButton
                            type="submit"
                            variant="primary"
                            className="default-action-button me-md-3 px-md-5 text-white"
                          >
                            Submit
                          </DefaultButton>
                        </div>
                      </FormData>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </>
        )}
      </Container>
      {showModal && (
        <SpotRegistrationForm
          show={showModal}
          onHide={handleCloseSpotRegistrationModal}
        />
      )}
      {show && (
        <ExpoParticipantEditDetailsModal
          participantId={participantId}
          raceName={ticket_name}
          status={""}
          show={show}
          onHide={onHide}
          // setReRender={setReRender}
          // setReRenderParticipantDetails={setReRenderParticipantDetails}
        />
      )}
    </>
  );
};

export default NmdcExpoBibCollection;
