import React, { useState } from "react";
import { ErrorMessage, useField, useFormikContext, Field } from "formik";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { readFileAsBase64 } from "./utils";
import { Icon } from "components/Icon";

export const TextField = ({
  isRequired,
  type,
  label,
  disable,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
        <div className="col">
          <input
            type={type}
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
            value={field.value}
            onChange={(e) => field.onChange(e)}
            placeholder=" "
            disabled={isPastEvent || disable}
          />
          <span>
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </span>
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const CategoryTextField = ({
  isRequired,
  type,
  label,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100">
        <div className="col">
          <input
            type={type}
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
            value={field.value}
            placeholder=" "
            disabled={isPastEvent}
          />
          <span>
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ExcelFileUpload = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <div>
      <Row>
        <Col>
          <label htmlFor={field.name} className="form-label">
            {label}
          </label>
        </Col>
      </Row>
      <Field
        className={`form-control shadow-none bg-lightgray ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        onChange={(event: any) => {
          setFieldValue(field.name, event.currentTarget.files[0]);
        }}
      />
    </div>
  );
};

export const TextSelect = ({
  isRequired,
  type,
  label,
  options,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);
  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
        <div className="col">
          <select
            {...field}
            {...props}
            type={type}
            className={`${meta.touched && meta.error && "is-invalid"}`}
            required
            // disabled={isPastEvent}
            placeholder=" "
          >
            <option value=""></option>
            {options?.map((option: any) => {
              return (
                <>
                  <option
                    key={option.id}
                    value={option.id}
                    // disabled={option.status ?? false}
                    onChange={option.id}
                  >
                    {option.name}
                  </option>
                </>
              );
            })}
          </select>
          <span>{label}</span>
        </div>
      </div>
      {/* <Row>
        <Col xs={8}>
          <label htmlFor={field.name} className="text-darkgray fw-400 my-3">
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </label>
        </Col>
      </Row>

      <select
        {...field}
        {...props}
        type={type}
        className={`form-control  ${
          meta.touched && meta.error && "is-invalid"
        }`}
        // required
        disabled={isPastEvent}
      >
        <option value="">Choose an option</option>
        {options?.map((option: any) => {
          return (
            <>
              <option
                key={option.id}
                value={option.id}
                // disabled={option.status ?? false}
                onChange={option.id}
              >
                {option.name}
              </option>
            </>
          );
        })}
      </select> */}
      <Row>
        <Col>
          <ErrorMessage
            component="small"
            name={field.name}
            className="error text-danger ms-2"
          />
        </Col>
      </Row>
    </div>
  );
};

export const TextArea = ({
  isRequired,
  label,
  rows,
  options,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);

  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);
  return (
    <div>
      <Row>
        <Col>
          <label htmlFor={field.name} className="text-darkgray fw-400 my-3">
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </label>
        </Col>
        <Col>
          <ErrorMessage
            component="div"
            name={field.name}
            className="error text-danger float-end"
          />
        </Col>
      </Row>
      <div>
        <textarea
          className={`form-control  ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          rows={rows}
          disabled={isPastEvent}
        ></textarea>
      </div>
    </div>
  );
};

export const TextRadio = ({
  label,
  label1,
  label2,
  isRequired,
  type,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Row>
        <Col>
          <label htmlFor={field.name} className="text-darkgray fw-400">
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </label>
        </Col>
        <Col>
          <ErrorMessage
            component="div"
            name={field.name}
            className="error text-danger float-end text-capitalize"
          />
        </Col>
      </Row>
      <div>
        <div className="form-check form-check-inline">
          <input
            className={`form-check-input  ${
              meta.touched && meta.error && "is-invalid"
            }`}
            type={type}
            id={field.name + "-1"}
            {...field}
            {...props}
          />
          <label className="form-check-label" htmlFor={field.name + "-1"}>
            {label1}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className={`form-check-input shadow-none ${
              meta.touched && meta.error && "is-invalid"
            }`}
            type={type}
            id={field.name + "-2"}
            {...field}
            {...props}
          />
          <label className="form-check-label" htmlFor={field.name + "-2"}>
            {label2}
          </label>
        </div>
      </div>
    </>
  );
};

export const ImageTextField = ({
  isRequired,
  type,
  label,
  handleSetProfileImage,
  ...props
}: any) => {
  const [previewImage, setPreviewImage] = useState("");
  const [field, meta] = useField(props);
  const location = useLocation();
  const [isPastEvent, setIsPastEvent] = React.useState(false);
  const { setFieldValue } = useFormikContext();
  React.useLayoutEffect(function () {
    const pathName = location.pathname.split("/");
    if (pathName.includes("vieweventdata")) {
      setIsPastEvent(true);
    }
  }, []);
  return (
    <div className="position-relative">
      <div
        style={{
          boxShadow:
            "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(222, 224, 250, 0.6)",
        }}
        className="form-floating input-bx my-md-2 form-row w-100"
      >
        <div className="col">
          <input
            type={type}
            className={` ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
            onChange={async (e) => {
              const file = e.currentTarget.files
                ? e.currentTarget.files[0]
                : null;
              if (file) {
                const base64 = await readFileAsBase64(file);
                setFieldValue(field.name, file);
                handleSetProfileImage(base64);
                setPreviewImage(base64);
              }
            }}
            placeholder=" "
            disabled={isPastEvent}
          />
          <span>
            {label}
            {isRequired && <span className="text-primary ms-1">*</span>}
          </span>
        </div>
        {previewImage && (
          <span
            style={{ left: "95%" }}
            className="position-absolute top-50 translate-middle"
          >
            <img src={previewImage} alt="profileimg" width={38} height={38} />
          </span>
        )}
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};

export const PasswordField = ({
  label,
  showPassword,
  togglePasswordVisibility,
  ...props
}: any) => {
  const [field] = useField(props);

  return (
    <div>
      <div className="form-floating input-bx my-md-2 form-row w-100 form-control">
        <div className="col">
          <input
            type={showPassword ? "text" : "password"}
            {...field}
            {...props}
            placeholder=""
          />
          <span>{label}</span>
          <Icon
            iconName={showPassword ? "Eye" : "EyeSlash"}
            className="
             position-absolute bottom-0 end-0 mb-3 pb-1 me-3 cursor-pointer"
            size={18}
            onClick={togglePasswordVisibility}
          />
        </div>
      </div>
      <ErrorMessage
        component="small"
        name={field.name}
        className="error text-danger ms-2"
      />
    </div>
  );
};
