import { useEffect } from "react";
import CryptoJS from "crypto-js";
import axiosPublic from "../axios";
import { useNavigate } from "react-router-dom";

const useAxiosPublic = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPublic.interceptors.request.use(
      (config) => {
        if (config.url) {
          const apiToken = CryptoJS.HmacSHA256(
            config.url.split("/")[1],
            `${process.env.REACT_APP_ENDPOINT_ENCRYPT}`
          ).toString(CryptoJS.enc.Hex);

          if (!config.headers["apiKey"]) {
            config.headers["apiKey"] = apiToken;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPublic.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        if (error.response?.status === 403) {
          navigate("/forbidden");
        } else if (error?.response?.code === "ERR_NETWORK") {
          navigate("/connectiontimeout");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPublic.interceptors.request.eject(requestIntercept);
      axiosPublic.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return axiosPublic;
};

export default useAxiosPublic;
