import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import useAxiosPublic from "utils/axiosusermodule/useAxiosPublic";
import { TextField } from "utils/FormControls";

const SupportLogin = () => {
  const axiosPublic = useAxiosPublic();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const initialValues = {
    Username: email,
    password: password,
  };
  const validationSchema = Yup.object({
    Username: Yup.string().required("Email is required"),
    password: Yup.string()
      .min(6, "Minimum of 6 characters of length")
      .max(15, "Maximum of 15 characters of length")
      .required("Password is required"),
  });
  const onSubmit = (values: any) => {
    setIsLoading(true);
    const { Username, password } = values;
    setEmail(Username);
    setPassword(password);
    axiosPublic({
      url: "/supportLogin",
      data: {
        email: Username,
        password: password,
      },
      method: "POST",
    })
      .then((response: any) => {
        const { status, message, data, token } = response.data;
        if (status) {
          setEmail("");
          setPassword("");
          login(token, data?.id, "", null, data?.email, null, "");
          navigate("/support/dashboard");
        } else {
          toast.error(message);
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };
  const SupportLoginComponent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <>
              <Form className=" px-md-5">
                <div className="px-xxl-5">
                  <div className="row">
                    <div className="col-12 my-2 my-md-2">
                      <TextField
                        type="text"
                        name="Username"
                        label="Username *"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <TextField
                        type="password"
                        name="password"
                        label="Password *"
                      />
                    </div>
                  </div>

                  {/* <div className="row mt-md-3">
                    <div
                      className="col-12 d-flex justify-content-end fw-600 "
                      onClick={handleShowForgotPasswordModal}
                      style={{ cursor: "pointer" }}
                    >
                      <small className=" text-purple">Forgot password ?</small>
                    </div>
                  </div> */}

                  <div className="row mt-4">
                    <div className="col">
                      <button
                        className="btn btn-primary py-2 rounded-2 fw-normal text-white fw-bold w-100"
                        type="submit"
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="purple"
                              size="sm"
                              className="me-3"
                            />
                            Logging in..
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            md={7}
            className="d-flex justify-content-md-center align-items-md-center"
            style={{
              background: "#62261D75",
              backgroundImage:
                window.innerWidth > 1440
                  ? "url(/images/Login_big_screen.png)"
                  : "url(/images/Combine_login.png)",
            }}
          >
            <img src="/images/Login_Page_logo_org.svg" alt="Logo" />
          </Col>

          <Col
            md={5}
            xs={12}
            className=" d-flex flex-column vh-100 justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div
              style={{ flexGrow: "1" }}
              className="w-100 d-flex justify-content-center flex-column px-md-5"
            >
              <h1 className="d-flex justify-content-center my-md-3 text-uppercase">
                Support Login
              </h1>
              <div>
                <SupportLoginComponent />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SupportLogin;
